/**
 * @author      Claude Chen
 * @company     Imbue Capital Pty Ltd
 * @created     2018
 */

import React, { Component } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { layoutConfig } from "../config";

// Sidebar
const SideBarLayout = styled.div`
    grid-area: sidebar;
    background: var(--color-main);
    display: grid;
    width: ${(props) => (props.isCollapsed ? layoutConfig.sidebarWidthCollapsed + "px" : layoutConfig.sidebarWidth + "px")};
    grid-template-rows: ${layoutConfig.navbarHeight + "px calc(100vh - " + (layoutConfig.navbarHeight + layoutConfig.textStripHeight) + "px) " + layoutConfig.textStripHeight + "px"};
    grid-template-columns: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
    padding-bottom: 200px;

    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
`;

// ============================================
// App -> Sidebar -> SideBarGroup -> SideBarBtn
// ============================================

class SideBarBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: true,
        };
        this.onSwitchCollapse = this.onSwitchCollapse.bind(this);
    }

    componentDidMount() {
        if (this.props.hasChildren) {
            if (this.props.children.some((ele) => this.props.currentPath === ele.path.replace("/", ""))) {
                this.setState({ isCollapsed: false });
            }
        }
    }

    onSwitchCollapse() {
        this.setState({ isCollapsed: !this.state.isCollapsed });
    }

    render() {
        if (this.props.hasChildren) {
            const renderSubMenus = () => {
                if (!this.state.isCollapsed) {
                    return this.props.children.map((ele, idx) => {
                        return (
                            <NavLink key={idx} title={ele.name} className={this.props.currentPath === ele.path.replace("/", "") ? "side_bar_button side_bar_button_selected" : "side_bar_button"} exact to={ele.path}>
                                <div className="side_bar_button_icon">
                                    <i className="fas fa-analytics" />
                                </div>
                                <div className="side_bar_button_text">{ele.name}</div>
                            </NavLink>
                        );
                    });
                }
            };

            return (
                <div key={this.state.isCollapsed} className="sub_level_group">
                    <div className={!this.state.isCollapsed ? "side_bar_button side_bar_button_selected side_bar_button_selected_group" : "side_bar_button"} onClick={this.onSwitchCollapse}>
                        <div className="side_bar_button_icon">
                            <i className={!this.state.isCollapsed ? "fas fa-angle-up" : "fas fa-angle-down"} />
                        </div>
                        <div className="side_bar_button_text">{this.props.name}</div>
                        {this.props.showCount ? <div className="side_bar_button_count">{this.props.children.length}</div> : ""}
                    </div>
                    <div className="sub_level">{renderSubMenus()}</div>
                </div>
            );
        } else {
            return (
                <NavLink title={this.props.name} className={this.props.currentPath === this.props.path.replace("/", "") ? "side_bar_button side_bar_button_selected" : "side_bar_button"} exact to={this.props.path}>
                    <div className="side_bar_button_icon">
                        <i className={this.props.icon} />
                    </div>
                    <div className="side_bar_button_text">
                        {this.props.name} {this.props.isbeta ? <span className="BetaLabel">beta</span> : ""}
                    </div>
                </NavLink>
            );
        }
    }
}

// ============================================
// App -> Sidebar -> SideBarGroup
// ============================================
class SideBarGroup extends Component {
    render() {
        return (
            <div>
                <div className="section">
                    {this.props.data.section ? (
                        <div className="section-label">
                            <b>{this.props.data.section}</b>{" "}
                        </div>
                    ) : (
                        ""
                    )}
                    {this.props.data.subtitle ? (
                        <div className="section-subtitle">
                            <small>{this.props.data.subtitle}</small>{" "}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                {this.props.data.apps.map((element, idx) => {
                    return <SideBarBtn key={idx} {...element} currentPath={this.props.data.currentPath} />;
                })}
            </div>
        );
    }
}

// App -> Sidebar
class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: localStorage.getItem("isSidebarCollapsed") === "true" || false,
        };
        this.onCollapseSidebar = this.onCollapseSidebar.bind(this);
    }

    componentDidMount() {}

    onCollapseSidebar() {
        const nextState = !this.state.isCollapsed;
        this.setState({
            isCollapsed: nextState,
        });
        localStorage.setItem("isSidebarCollapsed", nextState);
    }

    render() {
        const { isCollapsed } = this.state;
        const { data } = this.props;
        const currentRootPath = window.location.pathname.split("/").filter((ele) => ele !== "")[0] || "";

        return (
            <SideBarLayout isCollapsed={isCollapsed} className={"boxShadow" + (isCollapsed ? " collapsed" : "")}>
                {/* Sidebar -> Header */}
                <div onClick={this.onCollapseSidebar} className="header_wrapper">
                    <div className="header_button">
                        <i className="fas fa-bars" />
                    </div>
                    <div className="header_logo">PochtecayotlCloud</div>
                </div>

                {/* Sidebar -> Buttons */}
                <div className="side_bar_button_wrapper">
                    {data.map((element, idx) => {
                        return (
                            <div key={idx}>
                                <SideBarGroup
                                    onClickSidebar={this.onClickSidebar}
                                    data={{
                                        ...element,
                                        currentPath: currentRootPath,
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            </SideBarLayout>
        );
    }
}

export default SideBar;
