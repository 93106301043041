import React, { Component } from "react";

export default class SM_CardWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false
        };
        this.onSwitchCollapse = this.onSwitchCollapse.bind(this);
    }

    onSwitchCollapse() {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        });
    }

    render() {
        const comp = this.props.component;
        const title = this.props.title;
        return (
            <div className="ImbueCard">
                <div className="ImbueCard_title" onClick={this.onSwitchCollapse}>
                    <div>{title}</div>
                    <div className="ImbueCard_btn">
                        <i className={this.state.isCollapsed ? "fal fa-plus" : "fal fa-minus"}></i>
                    </div>
                </div>
                <div className={"ImbueCard_inner_wrapper" + (this.state.isCollapsed ? " collapsed" : "")}>{comp}</div>
            </div>
        );
    }
}
