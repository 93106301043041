import React, { Component } from "react";
import RcHighchart from "react-highcharts";
import { highchartBasicConfig, COLORMAP } from "../../config";

export default class LimeExplainerChart extends Component {
    render() {
        const type = this.props.type; // 1. proba 2. featdist 3. featval
        const data = this.props.data;
        const signalLabel = this.props.signalLabel;
        const themeColor = {
            Short: COLORMAP.red,
            Long: COLORMAP.green,
        }[signalLabel];
        const themeColorOpposite = {
            Short: COLORMAP.green,
            Long: COLORMAP.red,
        }[signalLabel];

        if (type === "proba") {
            const chartConfig = {
                ...highchartBasicConfig,
                chart: {
                    ...highchartBasicConfig.chart,
                    height: this.props.height,
                },
                legend: { enabled: false },
                xAxis: {
                    title: { text: "Signals" },
                    categories: data.pred_proba.map((ele) => ele.class),
                },
                yAxis: {
                    labels: {
                        format: "{value}%",
                    },
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.y.toFixed(3) + "%";
                            },
                        },
                    },
                },
                series: [
                    {
                        type: "bar",
                        name: "Prediction Probabilities",
                        data: data.pred_proba.map((ele) => ({
                            color: {
                                Short: COLORMAP.red,
                                Long: COLORMAP.green,
                            }[ele.class],
                            y: ele.proba * 100,
                        })),
                    },
                ],
            };
            return <RcHighchart config={chartConfig} />;
        } else if (type === "featdist") {
            const chartConfig = {
                ...highchartBasicConfig,
                chart: {
                    ...highchartBasicConfig.chart,
                    height: this.props.height,
                },
                legend: { enabled: false },
                xAxis: {
                    title: { text: "Feature Importance" },
                    categories: data.df_list.map((ele) => ele[0]),
                },
                yAxis: {
                    text: "Weight on signal " + signalLabel,
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.y.toFixed(4);
                            },
                        },
                    },
                },
                series: [
                    {
                        type: "bar",
                        name: "Total Weight",
                        data: data.df_list.map((ele) => ({
                            color: ele[1] >= 0 ? COLORMAP.green : COLORMAP.red,
                            y: ele[1],
                        })),
                    },
                ],
            };
            return <RcHighchart config={chartConfig} />;
        } else {
        }

        return <div></div>;
    }
}
