import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';

class CSVDownloader extends Component {
    render() {
        return (
            <div>
                <CSVLink
                    data={this.props.data}
                    headers={this.props.headers}
                    target="_blank"
                    filename={(this.props.name || "data") + "_" + moment().format("YYYYMMDDhhmmss") + ".csv"} >
                    <i className="fas fa-download fontColor__imbue_green" style={{ margin: "5px" }}></i>
                </CSVLink>
            </div>
        );
    }
}

export default CSVDownloader;