import React, { Component } from "react";
import MainBoard from "../../layout/MainBoard";
import GlossaryAndLinks from "./GlossaryAndLinks";

export default class Feeds__mainboard extends Component {


    componentDidMount() {
        document.title = "Glossary & Links";
    }
    
    render() {
        const list = [
            {
                name: "Glossary & Links",
                path: this.props.match.path + "/glossaryandlinks",
                component: GlossaryAndLinks,
            },
        ];

        return (
            <div>
                <MainBoard title="Glossary/Links" list={list} />
            </div>
        );
    }
}
