import React from 'react';
import { useHistory } from "react-router-dom";
// import './Quotes.css';
import styles from "./Quotes.module.css"
import { by_exchange } from "../universalSymbolMap";

const grain_category = ["ZC", "ZW", "ZS", "KE", "MWE", "ZO"];
// const rate_cat = ["ZT", "Z3", "ZF", "ZN", "TN", "ZB"];
// const fx_cat = ["DX", "6E", "6J", "RMB", "6B", "6C", "6A", "6L", "6M"];
const trim4_category = ["ZT", "Z3", "ZF", "ZN", "TN", "ZB",
    "DX", "6E", "6J", "RMB", "6B", "6C", "6A", "6L", "6M",]

function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
}


function trimVal(sym, val) {
    let remainder =  val - Math.floor(val);
    if (grain_category.includes(sym)) {
        let tick;
        if (remainder === .25) {
            tick = '2';
        } else if (remainder === .5) {
            tick = '4';
        } else if (remainder === .75) {
            tick = '6';
        } else {
            tick ='0';
        }
        return parseInt(Math.floor(val).toString() + tick);
    } else if (trim4_category.includes(sym)) {
        return toFixed(val, 4);
    } else {
        return val;
    }
}


export default function Quote(props) {

    const history = useHistory();

    const commonRoot = by_exchange(props.root).common;
    
    // function getClassName() {
    //     if (props.className.length > 0) {
    //         return props.className;
    //     } else {
    //         return "quoteDefault";
    //     }
    // }

    const onClick = () => {
        props.blockCallback(props);
        // console.table(this.props);
        // let symbolTitle = by_exchange(this.props.root).title;
        // console.log(symbolTitle)
    }


    

    return (
    // <div className={this.getClassName()}>
        <div className={styles.quote} style={props.style} onClick={onClick}>
            <div>
                <h4 className={styles.title}>
                    <abbr title={by_exchange(props.root).title} 
                        onClick={()=>{
                            console.log("switching to /dashboard/" + commonRoot)
                            history.push(`/dashboard/${commonRoot}`);
                        }}> 
                        {props.symbol}
                    </abbr>
                </h4>
                <span className={styles.innerData}>
                    <pre>{trimVal(props.root, props.open)}</pre></span>
                <span className={styles.innerData}>
                    <pre>{trimVal(props.root, props.high)}</pre></span>
                <span className={styles.innerData}>
                    <pre>{trimVal(props.root, props.low)}</pre></span>
                <span className={styles.innerData}>
                    <b>{trimVal(props.root, props.last)}</b></span>
                <span className={styles.innerData}>
                    <pre><span style={props.pxColor}>{
                        props.delta > 0 ? 
                            "+"+trimVal(props.root, props.delta.toString()) : 
                            trimVal(props.root, props.delta.toString())}</span></pre>
                </span>
            </div>
        </div>
    );      
}
    