import React, { Component } from "react";
import styled from "styled-components";
import { getTickerName, SECURITY_POOL } from "../../../index/TickerDictonary";
import ImbueSelect from "../../../components/input/ImbueSelect";
import { api_position_risk } from "../../../helpers/API";
import { hasItem } from "../../../helpers/Utils";
import { parseNumber, formatNumber, fullAssetsOptions } from "./Common";
import HighChartWrapper from "../../../components/chart/HighChartWrapper";
import { highchartBasicConfig, COLORMAP } from "../../../config";

const Layout = styled.div``;

const DEFAULTVALUE = 1;

export default class HRP extends Component {
    state = {
        assets: this.props.func.get_retained_options("hrp", "assets") || fullAssetsOptions.slice(0, 6),
        datefrom: "2010-01-01",
        portf: "5,000,000",
        prec_long: "0.5",
        positions_map: {},
        data: null,
        isLoading: 0,
    };

    onUpdate = () => {
        let selectedAssets = this.state.assets.map((ele) => ele.value);

        if (hasItem("*all", selectedAssets)) {
            selectedAssets = fullAssetsOptions.map((ele) => ele.value);
        }

        let positions_map = {};
        for (let item of selectedAssets) {
            positions_map[item] = this.state.positions_map[item]
                ? parseInt(this.state.positions_map[item])
                : DEFAULTVALUE;
        }

        const paras = {
            query: "hrp",
            positions_map: JSON.stringify(positions_map),
            datefrom: this.state.datefrom,
            portf: parseNumber(this.state.portf),
            long_bias: this.state.prec_long,
        };

        this.setState({ isLoading: 1 }, () => {
            api_position_risk(paras).then((res) => {
                this.setState({ data: res.data.result, isLoading: 0 });
            });
        });
    };

    render() {
        const assetsOptions = [
            { label: "ALL", value: "*all" },
            ...fullAssetsOptions,
        ];

        const renderMultiInputs = () => {
            const selectedAssets = hasItem(
                "*all",
                this.state.assets.map((ele) => ele.value)
            )
                ? fullAssetsOptions
                : this.state.assets;
            return (
                <div className="gridBox gridBox_8" style={{ gridGap: "10px" }}>
                    {selectedAssets.map((ele) => {
                        return (
                            <div
                                className="ImbueCardRounded"
                                // className="DigitCard DigitCardSmall DigitCardRounded DigitCardAlt"
                            >
                                <div className="title">
                                    {getTickerName(ele.label)}
                                </div>
                                <div className="content">
                                    <input
                                        defaultValue={"1"}
                                        value={
                                            this.state.positions_map[ele.value]
                                        }
                                        onChange={(e) => {
                                            let current = this.state
                                                .positions_map;
                                            current[ele.value] = e.target.value;
                                            this.setState({
                                                positions_map: current,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        };

        const renderChart = () => {
            if (this.state.isLoading) {
                return <div className="LoadingText">loading...</div>;
            } else if (this.state.data) {
                const {
                    longonly,
                    portfpos,
                    longonly_contract,
                    portfpos_contract,
                    dendrogram_fig,
                } = this.state.data;
                const chartConfig = {
                    longonly: {
                        ...highchartBasicConfig,
                        tooltip: {
                            shared: 1,
                        },
                        xAxis: {
                            categories: Object.keys(
                                longonly_contract
                            ).map((ele) => getTickerName(ele)),
                            crosshair: true,
                        },
                        yAxis: [
                            {
                                title: {
                                    text: "Weight",
                                },
                                height: "50%",
                            },
                            {
                                title: {
                                    text: "Contract",
                                },
                                height: "50%",
                                top: "50%",
                                opposite: 1,
                            },
                        ],
                        series: [
                            {
                                color: COLORMAP.blue,
                                type: "column",
                                name: "Contract",
                                data: Object.values(longonly_contract),
                                dataLabels: {
                                    enabled: 1,
                                },
                                yAxis: 1,
                            },
                            {
                                color: COLORMAP.green,
                                type: "column",
                                name: "Weight",
                                data: Object.keys(longonly_contract).map(
                                    (k) => longonly[k]
                                ),
                                dataLabels: {
                                    enabled: 1,
                                    format: "{point.y:.2f}",
                                },
                            },
                        ],
                    },
                    portfpos: {
                        ...highchartBasicConfig,
                        tooltip: {
                            shared: 1,
                        },
                        xAxis: {
                            categories: Object.keys(
                                portfpos_contract
                            ).map((ele) => getTickerName(ele)),
                            crosshair: true,
                        },
                        yAxis: [
                            {
                                title: {
                                    text: "Weight",
                                },
                                height: "50%",
                            },
                            {
                                title: {
                                    text: "Contract",
                                },
                                height: "50%",
                                top: "50%",
                                opposite: 1,
                            },
                        ],
                        series: [
                            {
                                color: COLORMAP.blue,
                                type: "column",
                                name: "Contract",
                                data: Object.values(portfpos_contract),
                                dataLabels: {
                                    enabled: 1,
                                },
                                yAxis: 1,
                            },
                            {
                                color: COLORMAP.green,
                                negativeColor: COLORMAP.red,
                                type: "column",
                                name: "Weight",
                                data: Object.keys(portfpos_contract).map(
                                    (k) => portfpos[k]
                                ),
                                dataLabels: {
                                    enabled: 1,
                                    format: "{point.y:.2f}",
                                },
                            },
                        ],
                    },
                };

                const img_src = "data:image/png;base64," + dendrogram_fig;

                return (
                    <div>
                        <div
                            className="flexBox flexBox_center"
                            style={{ padding: "30px" }}
                        >
                            <img
                                width="70%"
                                style={{ maxWidth: "800px" }}
                                src={img_src}
                                alt="DENDROGRAM"
                            />
                        </div>
                        <div className="gridBox gridBox_2">
                            <div>
                                <h3>Long Only</h3>
                                <HighChartWrapper
                                    config={chartConfig.longonly}
                                />
                            </div>
                            <div>
                                <h3>Portfolio Positioning</h3>
                                <HighChartWrapper
                                    config={chartConfig.portfpos}
                                />
                            </div>
                        </div>
                    </div>
                );
            } else {
                return "";
            }
        };

        return (
            <Layout>
                <div class="ImbueSelectsWrapper">
                    <ImbueSelect
                        isMulti
                        width="400px"
                        title="Assets"
                        value={this.state.assets}
                        options={assetsOptions}
                        closeMenuOnSelect={false}
                        onChange={(e) => this.setState({ assets: e || [] }, () => {
                            this.props.func.register_options("hrp", "assets", e)
                        })}
                    />
                    <div className="ImbueSelectItem">
                        <div className="label">portf:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={formatNumber(this.state.portf)}
                            onChange={(e) =>
                                this.setState({ portf: e.target.value })
                            }
                        />
                    </div>
                    <div className="ImbueSelectItem">
                        <div className="label">datefrom:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={this.state.datefrom}
                            onChange={(e) =>
                                this.setState({ datefrom: e.target.value })
                            }
                        />
                    </div>
                    <div className="ImbueSelectItem">
                        <div className="label">Percent Long:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={this.state.prec_long}
                            onChange={(e) =>
                                this.setState({ prec_long: e.target.value })
                            }
                        />
                    </div>
                    <div
                        className="CircleBtn CircleBtnLg"
                        onClick={this.onUpdate}
                    >
                        <i className="fas fa-play"></i>
                    </div>
                </div>
                <div>{renderMultiInputs()}</div>
                <div>{renderChart()}</div>
            </Layout>
        );
    }
}
