import React, { Component } from "react";
import { api_couchdb } from "../../helpers/API";
import styled from "styled-components";
import ImbueRcTable from "../../components/table/ImbueRcTable";
import { GLOBALSTYLED } from "../../config";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;

export default class NewsReference extends Component {
    state = {
        isLoading: 0,
        indextable: [],
    };

    componentDidMount() {
        this.setState({ isLoading: 1 }, () => {
            api_couchdb("imbuecloud_index_tables", "news_keyword_lookup").then((res) => {
                this.setState({
                    indextable: res.data.content.index,
                    isLoading: 0,
                });
            });
        });
    }

    render() {
        const tabledata = this.state.indextable;
        return (
            <Layout>
                {this.state.isLoading ? (
                    <div className="LoadingText">Loading...</div>
                ) : (
                    <ImbueRcTable defaultPageSize={30} filterable={1} list={tabledata} />
                )}
            </Layout>
        );
    }
}
