var escape = require("../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "* {\n    box-sizing: border-box;\n  }\n  \n  body {\n    color: #383f4d;\n    line-height: 1.5;\n    font-size: 14px;\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\",\n      sans-serif;\n  }\n  \n  /* \n  =================\n  Table\n  =====================\n  */\n  \n  .TopStrikes_table_container__wdw_9 {\n    /* max-width: 750px; */\n    /* max-height: 500px; */\n    margin: 0 auto;\n    overflow: auto;\n  }\n  \n  .TopStrikes_table__3sTii {\n    width: 100%;\n    border-spacing: 0;\n  }\n  \n  caption {\n    font-style: italic;\n    font-size: 90%;\n    margin-bottom: 10px;\n  }\n  \n  .TopStrikes_table__3sTii th {\n    background: #fff;\n    padding: 8px 10px;\n    border-bottom: 1px solid #1a1a1a;\n    font-weight: bold;\n    text-align: left;\n  }\n  \n  .TopStrikes_table__3sTii th.TopStrikes_up__22eLM {\n    background-image: url(" + escape(require("../img/up_arrow.png")) + ");\n  }\n  .TopStrikes_table__3sTii th.TopStrikes_down__3VdXU {\n    background-image: url(" + escape(require("../img/down_arrow.png")) + ");\n  }\n  .TopStrikes_table__3sTii th.TopStrikes_default__sj6mB {\n    background-image: url(" + escape(require("../img/default.png")) + ");\n  }\n  th.TopStrikes_up__22eLM,\n  th.TopStrikes_default__sj6mB,\n  th.TopStrikes_down__3VdXU {\n    cursor: pointer;\n    background-repeat: no-repeat;\n    background-position: center right;\n  }\n  \n  .TopStrikes_table__3sTii td {\n    border-top: 1px solid #ddd;\n    padding: 4px 8px;\n    white-space: nowrap;\n    text-align: right;\n  }\n  \n  .TopStrikes_table__3sTii tbody tr:first-child td {\n    border-top: none;\n  }\n  \n  .TopStrikes_table__3sTii tbody tr:nth-child(n) td {\n    background: #eff0f0;\n  }\n  \n  .TopStrikes_table__3sTii tbody tr:nth-child(2n) td {\n    background: #fff;\n  }\n\n  .TopStrikes_table__3sTii tbody tr:hover td {\n    background-color: #1D3C34; /* Imbue green */\n    color: #fff\n  } \n\n", ""]);

// exports
exports.locals = {
	"table_container": "TopStrikes_table_container__wdw_9",
	"table": "TopStrikes_table__3sTii",
	"up": "TopStrikes_up__22eLM",
	"down": "TopStrikes_down__3VdXU",
	"default": "TopStrikes_default__sj6mB"
};