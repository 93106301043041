import React, { Component } from "react";
import { TickerDict, getTickerName } from "../../../index/TickerDictonary";
import PositionCardTimeseries from "./PositionCardTimeseries";

export default class PositionCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPosId: "",
        };
    }

    render() {
        const paras = this.props.paras;
        const direction = paras.long_short > 0 ? "LONG" : "SHORT";
        const themecolor = direction === "LONG" ? "var(--color-green)" : "var(--color-red)";
        const comdty = getTickerName(paras.ticker);

        return (
            <div className="ImbuePositionCard">
                <div className="flexBox flexBox_between">
                    <div>
                        <div className="datetime">{paras.datetime}</div>
                        <div className="comdty">{comdty}</div>
                        <div className="ticker">{paras.ticker}</div>

                        <div className="size">
                            <span>SIZE:</span> {paras.size}
                        </div>
                        <div className="target">
                            <span>TARGET:</span> {paras.target} ({paras.target_pct}%)
                        </div>
                        <div className="stop">
                            <span>STOP:</span> {paras.stop} ({paras.stop_pct})%
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <div className="price">
                            <small>PRICE:</small> {paras.price}
                        </div>
                        <div className="direction" style={{ color: themecolor }}>
                            {direction}
                        </div>
                    </div>
                </div>

                <div>
                    <PositionCardTimeseries key={paras.ticker + paras.strategy_name} security={paras.ticker} strategy_name={paras.strategy_name} />
                </div>

                <div className="btnWrapper">
                    <div
                        className="SmallBtn"
                        style={{ marginTop: "6px" }}
                        onClick={() => {
                            this.props.onSelectPosition(paras);
                        }}
                    >
                        <span>View More</span>
                    </div>
                </div>
            </div>
        );
    }
}
