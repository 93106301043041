import React, { Component } from "react";
import styled from "styled-components";
import { getTickerName, SECURITY_POOL } from "../../../index/TickerDictonary";
import ImbueSelect from "../../../components/input/ImbueSelect";
import { api_position_risk } from "../../../helpers/API";
import { hasItem } from "../../../helpers/Utils";
import { parseNumber, formatNumber, fullAssetsOptions } from "./Common";

const Layout = styled.div``;

export default class PercToContract extends Component {
    state = {
        assets: this.props.func.get_retained_options("perc_to_contract", "assets") || [{ label: "ALL", value: "*all" }],
        portf: "5,000,000", 
        weights: {},
        data: [],
        isLoading: 0,
    };

    onUpdate = () => {
        let selectedAssets = this.state.assets.map((ele) => ele.value);

        if (hasItem("*all", selectedAssets)) {
            selectedAssets = fullAssetsOptions.map((ele) => ele.value);
        }

        let weights = {};
        for (let item of selectedAssets) {
            weights[item] = this.state.weights[item]
                ? parseFloat(this.state.weights[item])
                : 0.1;
        }

        const paras = {
            query: "percent_to_contract",
            weights: JSON.stringify(weights),
            portf: parseNumber(this.state.portf),
        };

        this.setState({ isLoading: 1 }, () => {
            api_position_risk(paras).then((res) => {
                console.log(res.data);
                this.setState({
                    data: Object.keys(res.data.result).map((k) => [
                        k,
                        res.data.result[k],
                    ]),
                    isLoading: 0,
                });
            });
        });
    };

    render() {
        const assetsOptions = [
            { label: "ALL", value: "*all" },
            ...fullAssetsOptions,
        ];

        const renderMultiInputs = () => {
            const selectedAssets = hasItem(
                "*all",
                this.state.assets.map((ele) => ele.value)
            )
                ? fullAssetsOptions
                : this.state.assets;
            return (
                <section>
                    <div
                        className="gridBox gridBox_8"
                        style={{ gridGap: "10px" }}
                    >
                        {selectedAssets.map((ele) => {
                            return (
                                <div className="ImbueCardRounded">
                                    <div className="title">
                                        {getTickerName(ele.label)}
                                    </div>
                                    <div className="content">
                                        <input
                                            value={
                                                this.state.weights[ele.value] ||
                                                "0.1"
                                            }
                                            onChange={(e) => {
                                                let currentWeights = this.state
                                                    .weights;
                                                currentWeights[ele.value] =
                                                    e.target.value;
                                                this.setState({
                                                    weights: currentWeights,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </section>
            );
        };

        const renderChart = () => {
            if (this.state.isLoading) {
                return <div className="LoadingText">loading...</div>;
            } else if (this.state.data.length > 0) {
                return (
                    <div className="flexBox">
                        {this.state.data.map((ele) => {
                            return (
                                <div className="DigitCard">
                                    <div className="title">
                                        {getTickerName(ele[0])}
                                    </div>
                                    <div className="digit">
                                        {formatNumber(`${ele[1]}`)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            }
        };

        return (
            <Layout>
                <div class="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="Assets"
                        value={this.state.assets}
                        options={assetsOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(e) => this.setState({ assets: e || [] }, () => {
                            this.props.func.register_options("perc_to_contract", "assets", this.state.assets)
                        })}
                    />
                    <div className="ImbueSelectItem">
                        <div className="label">portf:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={formatNumber(this.state.portf)}
                            onChange={(e) =>
                                this.setState({ portf: e.target.value })
                            }
                        />
                    </div>
                    <div
                        className="CircleBtn CircleBtnLg"
                        onClick={this.onUpdate}
                    >
                        <i className="fas fa-play"></i>
                    </div>
                </div>
                <div>{renderMultiInputs()}</div>
                <div>{renderChart()}</div>
            </Layout>
        );
    }
}
