import React, { Component } from "react";
import styled from "styled-components";
import HighChart from "react-highcharts";
import { simChartCommonConfig } from "../Config";
import { highchartBasicConfig } from "../../../config";
import ImbueTable from "../../table/ImbueTable";
import StateBox from "../components/StateBox";
const Layout = styled.div`
    font-family: var(--font-main);

    .label {
    }

    .section_title {
        margin: 10px 0;
        text-decoration: underline;
        line-height: 20px;
    }

    .section-signal {
        /* position: relative;
        display: grid;
        grid-template-columns: 50% 50%;
        height: 300px; */
    }

    .section-fimp {
    }
`;

function dict_to_list(dict) {
    let temp_ = [];
    for (let k of Object.keys(dict)) {
        let val = dict[k];
        temp_.push({
            label: k,
            value: val
        });
    }
    return temp_;
}

export default class TabFeatureImportance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            features: []
        };
    }

    componentDidMount() {
        // extract data
        const features = this.props.data.features;
        if (features) {
            this.setState({ features });
        }
    }

    render() {
        // === state ====
        const features = this.state.features;

        console.log(features);

        return (
            <Layout>
                {Object.keys(features).map((label, idx) => {
                    const data = features[label];
                    var meta = Object.values(data.perf)[0];
                    meta = dict_to_list(meta).map(ele => ({
                        ...ele,
                        value: parseFloat(ele.value)
                    }));
                    const timeseries_vote = data.y.filter(ele => ele.close !== 0);
                    var bar_impRank = Object.values(data.importance)[0];
                    bar_impRank = dict_to_list(bar_impRank)
                        .filter(ele => ele.label !== "weight")
                        .map(ele => ({
                            ...ele,
                            value: parseFloat(parseFloat(ele.value).toFixed(4))
                        }))
                        .sort((a, b) => b.value - a.value)
                        .slice(0, 30);
                    console.log(bar_impRank);
                    return (
                        <div key={idx}>
                            <div className="label">LABEL: {label}</div>
                            <div className="section_title">Features Insights</div>
                            <div>
                                <StateBox payload={meta} />
                            </div>
                            <div className="section_title">Feature Importance</div>
                            <div className="section-fimp">
                                <HighChart
                                    config={{
                                        ...highchartBasicConfig,
                                        chart: {
                                            ...highchartBasicConfig.chart,
                                            type: "column"
                                        },
                                        xAxis: {
                                            categories: bar_impRank.map(ele => ele.label),
                                            labels: {
                                                style: {
                                                    fontSize: "10px"
                                                }
                                            }
                                        },
                                        series: [
                                            {
                                                name: "signal",
                                                data: bar_impRank.map(ele => ele.value)
                                            }
                                        ]
                                    }}
                                />
                            </div>
                            <div className="section_title">Signals</div>
                            <div className="section-signal">
                                <HighChart
                                    config={{
                                        ...highchartBasicConfig,
                                        chart: {
                                            ...highchartBasicConfig.chart,
                                            type: "column"
                                        },
                                        series: [
                                            {
                                                name: "signal",
                                                data: timeseries_vote.map(ele => [Date.parse(ele.date), ele.close])
                                            }
                                        ]
                                    }}
                                />
                                {/* <ImbueTable
                                    config={{
                                        data: timeseries_vote,
                                        sorted: ["date", "close"],
                                        keynamemap: { close: "signal" },
                                        height: "300px"
                                    }}
                                /> */}
                            </div>
                        </div>
                    );
                })}
            </Layout>
        );
    }
}
