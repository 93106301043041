/**
 * TradesChart.js
 * Claude Chen | claude.chen@imbuecapital.com
 */

import React, { Component, Fragment } from "react";
import ReactHighstock from "react-highcharts/ReactHighstock";
import { highchartBasicConfig, COLORMAP } from "../../config";
import { generateTradesSeries, tradeChartTooltipFormatter } from "../../helpers/ChartFunctions";

class TradesChart extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render() {
        const { height, chartType, title, data } = this.props;

        const tradesSeries = generateTradesSeries(data);

        const probaSeries = [
            {
                type: "column",
                fillOpacity: 0.1,
                grouping: false,
                name: "Proba",
                data: data.trades.map(ele => [Date.parse(ele.open_date), ele.proba]),
                yAxis: 2,
                pointPadding: 0,
                color: COLORMAP.green,
                negativeColor: COLORMAP.red
            },
            {
                type: "line",
                color: "#999",
                dashStyle: "Dash",
                lineWidth: 1,
                yAxis: 2,
                data: data.trades.map(ele => [Date.parse(ele.open_date), 0])
            }
        ];

        const pnlSeries = data.pnl
            ? [
                  {
                      type: "areaspline",
                      fillOpacity: 0.1,
                      name: "Cumulative P&L",
                      color: COLORMAP.green,
                      negativeColor: COLORMAP.red,
                      data: data.pnl.map(ele => [Date.parse(ele[0]), ele[1]]),
                      softThreshold: true,
                      lineWidth: 1,
                      yAxis: 1
                  }
              ]
            : null;

        const chartSeries = [...tradesSeries, ...(pnlSeries || []), ...probaSeries];

        const configForTradesChart = {
            chart: {
                type: chartType || "line",
                height: height || undefined,
                backgroundColor: "transparent",
                style: {
                    fontFamily: "var(--font-main)"
                },
                animation: false
            },
            title: { text: title || "" },
            yAxis: [
                {
                    height: "78%",
                    title: { text: "Price" },
                    labels: { align: "right" },
                    opposite: false,
                    tickAmount: 8,
                    crosshair: { color: "#777", dashStyle: "dot" }
                },
                {
                    height: "78%",
                    title: { text: "Cumulative P%L" },
                    labels: {
                        align: "left",
                        formatter: function() {
                            return (this.value * 100).toFixed(0) + "%";
                        }
                    },
                    tickAmount: 8,
                    crosshair: { color: "#777", dashStyle: "dot" }
                },
                {
                    tickAmount: 6,
                    top: "80%",
                    height: "20%",
                    offset: 0,
                    gridLineWidth: 0,
                    title: { text: "Proba" },
                    labels: { align: "left" },
                    tickPixelInterval: "1px",
                    max: 1.2,
                    min: -1,
                    crosshair: { width: 1, color: "#aaa", dashStyle: "shortDash" },
                    resize: {
                        enabled: true
                    }
                }
            ],
            credits: { enabled: false },
            legend: { enabled: true },
            rangeSelector: highchartBasicConfig.rangeSelector,
            scrollbar: { enabled: 0 },
            navigator: highchartBasicConfig.navigator,
            tooltip: {
                useHTML: true,
                formatter: tradeChartTooltipFormatter
            },
            plotOptions: {
                series: {
                    dataGrouping: {
                        enabled: 0
                    },
                    animation: {
                        duration: 0
                    }
                },
                scatter: {
                    animation: {
                        duration: 0
                    }
                }
            },
            series: chartSeries
        };

        return (
            <Fragment>
                <ReactHighstock
                    config={{
                        ...configForTradesChart
                    }}
                />
            </Fragment>
        );
    }
}

export default TradesChart;
