import { highchartBasicConfig } from "../../config";

export const simChartCommonConfig = {
    // Common Chart Configuration
    chart: { style: { fontFamily: "arial" }, animation: false },
    credits: { enabled: false },
    tooltip: { outside: true },
    // yAxis: { labels: { format: '{value}%', align: 'left', x: 0 } },
    legend: { enabled: 1, navigation: { animation: false } },
    plotOptions: {
        series: { color: "rgb(68,117,99)", animation: false },
        line: { dataGrouping: { enabled: false } },
        area: { softThreshold: true },
        column: { grouping: false },
    },
    rangeSelector: {
        ...highchartBasicConfig.rangeSelector,
        selected: 5,
    },
    navigator: highchartBasicConfig.navigator,
    scrollbar: highchartBasicConfig.scrollbar,
};
