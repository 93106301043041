

// check that datetime string is of valid YYYY-MM-DD format
// return true/false
// (modified from: https://jymden.com/javasscript-validate-date-in-format-yyyy-mm-dd/ )
export function verifyDateFormat(dateString) {
    if (dateString === null) {
        return false;
    }
   // Date format: YYYY-MM-DD
   var datePattern = /^([12]\d{3}-([1-9]|0[1-9]|1[0-2])-([1-9]|0[1-9]|[12]\d|3[01])$)/;
 
   // Check if the date string format is a match
   var matchArray = dateString.match(datePattern);
   if (matchArray == null) {
       return false;
   }

   // Remove any non digit characters
   var cleanDateString = dateString.replace(/\D/g, ''); 

   // Parse integer values from date string
   var year = parseInt(cleanDateString.substr(0, 4));
   var month = parseInt(cleanDateString.substr(4, 2));
   var day = parseInt(cleanDateString.substr(6, 2));
  
   // Define number of days per month
   var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

   // Adjust for leap years
   if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
       daysInMonth[1] = 29;
   }

   // check year, month, and day range
   if (year < 1970 || month < 1 || month > 12 || day < 1 || day > daysInMonth[month - 1]) {
       return false;
   }

   return true; // is valid date format
}

// let testDates = [
//     "2022-05-23",
//     "2022/05/23",
//     "20201-01",
//     "20221-05-01",
//     "asdf-gh-jk",
//     "2020-01-1912334",
//     "1111-11-11",
//     "2023-01-01",
//     "2020-02-30",
//     "2020-02-29",
//     "2021-02-29",
//     "2020a-01-01",
//     "2020-1-1",
//     "1969-01-01",
//     "2022-01-01----",
//     "---2022-01-01",
//     "20222--01-01",
//     "",
//     null
// ]


// testDates.forEach((test) => {
//     console.log(`${test} :  ${verifyDateFormat(test)}`)
// })
