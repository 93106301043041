/**
 * @author      Claude Chen
 * @company     Imbue Capital Pty Ltd
 * @created     2018
 */

import React, { Component } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { signout } from "../auth/UserAuth";
import MediaQuery from "react-responsive";
import Clock from "react-live-clock";
import NationFlag from "react-country-flag";

// -- Logo Images
// import logoImg from "../img/Black.png";
import logoImg from "../img/BlankLogo.png";
import { COLORMAP, EMAIL, FNAME } from "../config";

// CSS
const NavBarWrapper = styled.div`
    a {
        color: inherit;
    }

    font-family: var(--font-main);
    display: flex;
    justify-content: space-between;

    align-items: center;
    background: #fff;
    z-index: 1;

    .NavBarLHS {
        height: 100%;
        display: flex;
    }

    .NavBarHeader {
        font-size: 1.4rem;
        color: #555;
        padding: 20px;
    }

    .navBar-logo-area {
        display: flex;
        align-items: center;
        position: absolute;
        right: 40px;
    }

    .NavBarRHS {
        margin: 0 10px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .user-btn {
        font-family: var(--font-main);
        color: #333;
        font-size: 1.1rem;
        transition: all 0.1s;
        padding: 4px 4px;
        border-radius: 3px;
    }

    .user-btn .fa {
        font-size: 1.3rem;
    }

    .user-btn:hover {
        cursor: pointer;
        background: #eee;
    }

    .user-btn:active {
        background: #ddd;
    }

    .user-profile {
        padding: 10px 0;
        background: #fff;
        min-width: 200px;
        position: absolute;
        top: 40px;
        right: 4px;
        border-radius: 5px;
        animation: textFloatToLeft 0.3s;
        z-index: 10000;
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.16), 0 0px 9px rgba(0, 0, 0, 0.1);
    }

    .user-profile-header {
        padding: 20px 15px;
    }

    .user-profile-btn {
        font-family: "Roboto";
        padding: 12px 15px;
        color: #555;
        font-size: 1.2rem;
    }

    .user-profile-btn:hover {
        cursor: pointer;
        background: #eee;
    }

    .user-profile-btn:active {
        background: #ddd;
    }

    .user-profile-btn i {
        margin-right: 5px;
    }
`;

const NavButton = styled.div`
    font-family: var(--font-main);
    height: 100%;
    transition: all 0.2s;
    border-bottom: 2px solid transparent;
    box-sizing: border-box;

    & > a {
        font-size: 1.2rem;
        padding: 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #777;
    }

    & > a:active {
    }

    & > a:hover {
        cursor: pointer;
        color: #000;
        /* border-bottom: 3px solid #33685a; */
    }

    & > .selected {
        color: var(--color-main);
        border-bottom: 2px solid #1d3c3400;
        background: rgb(245, 245, 245);
    }
`;

const NavBarButton = (props) => {
    return (
        <NavButton>
            <NavLink activeClassName="selected" to={props.link}>
                {props.name}
            </NavLink>
        </NavButton>
    );
};

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: 0,
        };
        this.onToggle = this.onToggle.bind(this);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    onToggle() {
        const { isShow } = this.state;
        this.setState({ isShow: !isShow });
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.onToggle();
        }
    }

    render() {
        const userEmail = EMAIL;
        const userName = FNAME;
        const { isShow } = this.state;
        const { buttons } = this.props;
        return (
            <div className="NavBarRHS">
                <div className="navBar-logo-area">
                    <div
                        style={{
                            fontSize: "1rem",
                            width: "103px",
                            padding: "0 20px",
                            fontFamily: "open sans",
                            color: "#444",
                        }}
                    >
                        <div className="flexBox flexBox_between">
                            <NationFlag code="US" svg />
                            <Clock format={"ddd, MMM DD HH:mm"} timezone={"EST"} ticking={true} />
                        </div>
                        <div className="flexBox flexBox_between">
                            <NationFlag code="GB" svg />
                            <Clock format={"ddd, MMM DD HH:mm"} timezone={"Europe/London"} ticking={true} />
                        </div>
                    </div>
                    <div style={{ paddingTop: "4px" }}>
                        <img src={logoImg} alt="Logo" width="80" />
                    </div>
                </div>
                <div className="user-btn" onClick={this.onToggle}>
                    <i className="fas fa-bars" style={{ fontSize: "2rem" }} />
                </div>
                {isShow ? (
                    <div className="user-profile noselect" ref={this.setWrapperRef}>
                        <div className="user-profile-header">
                            <div
                                style={{
                                    fontSize: "1.4rem",
                                    color: COLORMAP.main,
                                }}
                            >
                                Hi, {userName}
                            </div>
                            <div
                                style={{
                                    fontSize: "1.1rem",
                                    color: "var(--color-darkgold)",
                                }}
                            >
                                {userEmail}
                            </div>
                        </div>
                        <div style={{ maxHeight: "400px", overflow: "auto" }}>
                            {(buttons || []).map((ele, idx) => {
                                return (
                                    <NavLink
                                        key={"sub-link-" + idx}
                                        // activeClassName="selected"
                                        to={ele.link}
                                    >
                                        <div className="user-profile-btn">
                                            <i className="fas fa-window" />
                                            {" " + ele.name}
                                        </div>
                                    </NavLink>
                                );
                            })}
                            {/* <div className="user-profile-btn"><i className="fas fa-cog"></i>Preferences</div> */}
                            <div onClick={signout} className="user-profile-btn">
                                <i className="fas fa-sign-out-alt" />
                                Sign Out
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

// ============================================================================
// Props:
// title <string>
// buttons: [{name: <string>, linkto: <string> }]

class NavBar extends Component {
    render() {
        const buttons =
            this.props.buttons.length > 0
                ? this.props.isHomePage
                    ? []
                    : [
                          {
                              name: (
                                  <span>
                                      <i className="fas fa-chevron-left"></i> Back
                                  </span>
                              ),
                              link: this.props.homepath,
                          },
                      ]
                : [];
        return (
            <NavBarWrapper className="boxShadow">
                <div className="NavBarLHS" style={{ height: "100%" }}>
                    <div className="NavBarHeader flexBox_horizon">
                        {this.props.title}{" "}
                        {this.props.subtitle ? (
                            <span
                                style={{
                                    color: "#000",
                                    margin: "0 10px",
                                    background: "#ddd",
                                    padding: "4px 10px",
                                    borderRadius: "20px",
                                }}
                            >{`${this.props.subtitle}`}</span>
                        ) : (
                            ""
                        )}
                    </div>
                    <MediaQuery query="(min-width: 700px)">
                        {buttons
                            ? buttons.map((element, index) => {
                                  return <NavBarButton key={"btn-" + index} {...element} />;
                              })
                            : ""}
                    </MediaQuery>
                </div>
                <UserProfile buttons={this.props.buttons} />
            </NavBarWrapper>
        );
    }
}

export default NavBar;
