import React, { Component } from "react";
import { getToken } from "../../../auth/UserAuth";
import { WS, EMAIL, highchartBasicConfig, COLORMAP, layoutConfig } from "../../../config";
import Highchart from "react-highcharts";
import moment from "moment";
import styled from "styled-components";
import { titleWords, deg_to_dms, getFixed } from "../../../helpers/Utils";

const Layout = styled.div`
    position: relative;
    height: 100%;

    // -- GRID SYSTEM
    display: grid;
    grid-template-rows: 100px calc(100vh - ${layoutConfig.navPlusTextHeight + 100}px);
    grid-template-columns: 100%;
    grid-template-areas:
        "topbar"
        "charts";

    .grid__topbar {
        grid-area: topbar;
        background: #fff;
        height: 100%;
        padding: 8px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .grid__charts {
        grid-area: charts;

        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;

        background: #fff;
        position: relative;
    }

    .grid__chart_inner {
        position: relative;
    }

    /* .section {
    background: #fff;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.section-grid {
    display: grid;
    grid-template-columns: 50% 50%;
} */

    .chart_wrapper {
        margin-bottom: 10px;
        width: 100%;
    }

    .chart_inner_wrapper {
        position: relative;
        padding: 20px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .chart_inner_wrapper div {
    }

    .label {
        color: #888;
        font-size: 1rem;
    }

    .value {
        font-family: "Fjalla One";
        color: var(--color-main);
        font-size: 1.3rem;
    }

    .legend_wrapper {
        position: absolute;
        padding: 8px;
        font-family: "Roboto";
        font-size: 1.1rem;
        color: #666;
        border-radius: 10px;
        margin-left: 60px;
        margin-top: 0;
        background: rgba(250, 250, 250, 0.5);
        z-index: 999;
        border: 1px solid #ccc;
    }
    .legend_item {
        margin: 2px 0;
    }
    .legend_item_icon {
        font-size: 1.1rem;
        margin-right: 4px;
    }

    .highchart-wrapper,
    .highchart-wrapper > div {
        position: relative;
        height: 100% !important;
        width: 100% !important;
    }
`;

class LineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ts: moment()
        };
        this.INTV_UPDATE = 300;
    }

    ifChanged(series, newSeries) {
        const compareList = series.map((ele, idx) => ele.data.length === newSeries[idx].data.length);
        if (compareList.indexOf(false) >= 0) {
            return true;
        } else {
            return false;
        }
    }

    shouldComponentUpdate(nextProps) {
        const { isHotReload } = this.props;
        const newSeries = nextProps.series;
        const { series } = this.props;
        const ts = moment();
        const prevTs = this.state.ts;
        if (!newSeries) {
            return true;
        } else {
            if (ts - prevTs > this.INTV_UPDATE) {
                // minimum interval for updating the chart
                this.setState({ ts });
                if (isHotReload) {
                    return true;
                } else {
                    return this.ifChanged(series, newSeries);
                }
            } else {
                return false;
            }
        }
    }

    render() {
        const { type, series, title, height } = this.props;
        const { xAxisname, yAxisname } = this.props;
        const { isLog } = this.props;
        return (
            <div className="highchart-wrapper">
                <Highchart
                    config={{
                        chart: {
                            type: type || undefined,
                            style: {
                                fontFamily: "roboto"
                            },
                            height: height || undefined,
                            zoomType: type === "scatter" ? "xy" : "x",
                            panning: true,
                            panKey: "shift"
                            // animation: false,
                        },
                        title: { text: title },
                        // subtitle: { text: subtitle },
                        xAxis: {
                            title: { text: xAxisname || "" },
                            tickInterval: type === "scatter" ? undefined : 1,
                            crosshair: { width: 1, color: "#888", dashStyle: "shortDash" }
                        },
                        yAxis: [
                            {
                                title: { text: yAxisname || "" },
                                type: isLog ? "logarithmic" : undefined,
                                minorTickInterval: 0.1,
                                crosshair: { width: 1, color: "#888", dashStyle: "shortDash" }
                            },
                            { opposite: true }
                        ],
                        tooltip: {
                            animation: false
                        },
                        credits: highchartBasicConfig.credits,
                        series,
                        plotOptions: {
                            series: {
                                animation: false
                            },
                            areaspline: {
                                // animation: false
                                fillOpacity: 0.2
                            },
                            line: {
                                marker: {
                                    enabled: false
                                }
                            },
                            scatter: {
                                marker: {
                                    radius: 2,
                                    fillOpacity: 0.4
                                },
                                tooltip: {
                                    pointFormat: "yHat: {point.x}<br>y: {point.y}"
                                }
                            }
                        }
                    }}
                />
            </div>
        );
    }
}

export default class DLDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesData: {}
        };
        // this.startGame = this.startGame.bind(this)
    }

    componentDidMount() {
        this.startGame();
    }

    startGame() {
        const token = getToken();
        const WS_ENDPOINT = `${WS}dl?token=${token}`;
        // const WS_ENDPOINT = ``
        let ws = new WebSocket(WS_ENDPOINT);
        // --+-- on open --+--
        ws.onopen = () => {
            // CODE HERE
            // ON OPEN
            const { gameinput } = this.props;
            const initMsg = {
                body: gameinput
            };
            ws.send(JSON.stringify(initMsg));
        };

        // --+-- on message --+--
        ws.onmessage = event => {
            // CODE HERE
            let { socketData, seriesData } = this.state;
            let { epochSeries, r2Series, expVar, pearsonr } = seriesData;

            epochSeries = epochSeries || [];

            r2Series = r2Series || [];
            expVar = expVar || [];
            pearsonr = pearsonr || [];

            socketData = socketData || {};

            // handle socket data
            let data = {};
            try {
                data = JSON.parse(event.data);
            } catch (e) {
                console.log(e);
                console.log(data);
            }

            // console.log(data)
            if ("label" in data) {
                socketData[data.label] = data.content;
                switch (data.label) {
                    case "on_epoch_end":
                        epochSeries.push(data.content.logs);
                        r2Series.push(data.content["r2"]);
                        expVar.push(data.content["explained_var"]);
                        pearsonr.push(data.content["pearsonr"]);
                        break;
                    default:
                        break;
                }
            }

            const newSeriesData = {
                ...seriesData,
                epochSeries,
                r2Series,
                expVar,
                pearsonr
            };

            this.setState(
                {
                    socketData,
                    seriesData: newSeriesData
                },
                () => {
                    if (data.label === "on_train_end") {
                        console.log("on_train_end >>> ", data.content);
                        this.props.stopRunning();
                        this.closeSocketGame();
                    }
                }
            );
        };

        ws.onerror = event => {
            console.log(event);
        };

        // set state for WebSocket connection
        this.setState({ ws });
    }

    closeSocketGame() {
        const { ws } = this.state;
        if (ws) {
            ws.close();
        }
    }

    terminateGame() {
        console.log("USER FORCE TERMINATE!");
        const { ws } = this.state;
        const msg = {
            body: { cancel: true, user: EMAIL },
            token: getToken()
        };
        ws.send(JSON.stringify(msg));
        this.closeSocketGame();
    }

    render() {
        let { socketData, seriesData } = this.state;

        // Prepare Input
        socketData = socketData ? JSON.parse(JSON.stringify(socketData)) : {};
        seriesData = seriesData ? JSON.parse(JSON.stringify(seriesData)) : {};

        // --+-- Format Input --+--
        // 1. y distribution chart
        const y = socketData["on_train_start"] ? socketData["on_train_start"]["y"] : [];

        // 2. Actual and Prediction residual plot
        const y_test = socketData["on_train_start"] ? socketData["on_train_start"]["y_test"] : [];
        const yhat_test = socketData["on_epoch_end"] ? socketData["on_epoch_end"]["yhat_test"].map(ele => ele[0]) : [];
        const y_scatter = y_test
            .map((a, i) => [yhat_test[i], a])
            .map(ele => ({
                x: ele[0],
                y: ele[1],
                color: ele[0] * ele[1] >= 0 ? "rgba(29, 60, 52, .9)" : "rgba(235, 191, 129, .9)"
            }));
        const y_scatter_stats = {
            pos: y_scatter.filter(ele => ele.x * ele.y >= 0).length,
            neg: y_scatter.filter(ele => ele.x * ele.y < 0).length,
            p_n: y_scatter.filter(ele => ele.x * ele.y >= 0).length / y_scatter.length
        };

        // 3. Series Data
        const loss_series = (seriesData["epochSeries"] || []).map(ele => ele.loss);
        const val_loss_series = (seriesData["epochSeries"] || []).map(ele => ele.val_loss);
        const r_2_series = (seriesData["r2Series"] || []).map(ele => (ele > 1 ? 1 : ele < -1 ? -1 : ele));
        const exp_var = (seriesData["expVar"] || []).map(ele => (ele > 1 ? 1 : ele < -1 ? -1 : ele));
        const pearsonr = (seriesData["pearsonr"] || []).map(ele => (ele > 1 ? 1 : ele < -1 ? -1 : ele));

        const metrics_stats = {
            r_2: getFixed(r_2_series[r_2_series.length - 1]),
            exp_var: getFixed(exp_var[exp_var.length - 1]),
            pearsonr: getFixed(pearsonr[pearsonr.length - 1])
        };

        // 4. Game Meta
        const meta = socketData["meta"] || {};

        // 5. Progress
        const no_epoch = socketData["on_epoch_end"] ? socketData["on_epoch_end"]["epoch"] + 1 : 0;
        const progress = {
            epochs_finished: no_epoch + "/" + meta["epochs"],
            progress: ((no_epoch / meta["epochs"]) * 100).toFixed(0) + "%",
            progressValue: (no_epoch / meta["epochs"]) * 100
        };

        // 6. Time
        const start_ts = socketData["on_train_start"] ? moment(socketData["on_train_start"]["ts"] * 1000) : null;
        const ts = socketData["on_epoch_end"] ? moment(socketData["on_epoch_end"]["ts"] * 1000) : null;
        const end_ts = socketData["on_train_end"] ? moment(socketData["on_train_end"]["ts"] * 1000) : null;
        const ts_info = {
            start_at: start_ts ? start_ts.format("YYYY-MM-DD, h:mm:ss a") + " (" + start_ts.fromNow() + ")" : "",
            time_elapsed: ts ? deg_to_dms((ts - start_ts) / 1000) : "",
            speed: ts ? ((ts - start_ts) / 1000 / no_epoch).toFixed(2) + " sec/epoch" : ""
        };

        return (
            <Layout>
                <div className="grid__topbar">
                    <div style={{ margin: "5px 0", padding: "0 18px" }}>
                        <div className="flexBox_horizon">
                            <div
                                style={{
                                    position: "relative",
                                    height: "8px",
                                    padding: "2px",
                                    width: "100%",
                                    border: "1px solid #ccc",
                                    // background: "#ccc",
                                    borderRadius: "40px"
                                }}
                            >
                                <div
                                    style={{
                                        background: COLORMAP.main,
                                        width: `${progress.progressValue || 0}%`,
                                        height: "100%",
                                        transition: "all .2s ease-in",
                                        borderRadius: "inherit"
                                    }}
                                ></div>
                            </div>
                            <div className="value" style={{ marginLeft: "4px" }}>
                                {(progress.progressValue || 0).toFixed(0) + "%"}
                            </div>
                        </div>
                    </div>

                    <div className="flexBox_horizon" style={{ justifyContent: "space-between", padding: "10px 20px", flexWrap: "wrap" }}>
                        {meta
                            ? ["agent_id", "security", "architecture", "batch_size", "epochs", "learning_rate", "look_ahead", "time_steps", "test_start"].map(key => (
                                  <div>
                                      <div className="label">{titleWords(key)}</div>
                                      <div className="value">{meta[key]}</div>
                                  </div>
                              ))
                            : ""}
                    </div>

                    <div className="flexBox_horizon" style={{ justifyContent: "space-between", padding: "5px 20px", flexWrap: "wrap" }}>
                        {meta
                            ? ["start_at", "time_elapsed", "speed"].map(key => (
                                  <div>
                                      <div className="label">{titleWords(key)}</div>
                                      <div className="value" style={{ color: "#444" }}>
                                          {ts_info[key]}
                                      </div>
                                  </div>
                              ))
                            : ""}
                    </div>
                </div>
                <div className="grid__charts">
                    <div className="grid__chart_inner">
                        <div className="chart_inner_wrapper" style={{ position: "relative" }}>
                            <LineChart
                                title="Loss"
                                // height={400}
                                xAxisname="Epochs"
                                isLog={true}
                                series={[
                                    {
                                        type: "line",
                                        color: "var(--color-darkgold)",
                                        name: "Loss",
                                        data: loss_series.map((ele, idx) => [idx + 1, ele])
                                    },
                                    {
                                        type: "line",
                                        color: COLORMAP.main,
                                        name: "Val Loss",
                                        data: val_loss_series.map((ele, idx) => [idx + 1, ele])
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <div className="grid__chart_inner">
                        <div className="chart_inner_wrapper">
                            <div className="legend_wrapper">
                                {[
                                    ["r_2", "R2", COLORMAP.main],
                                    ["exp_var", "Explained Var", "var(--color-darkgold)"],
                                    ["pearsonr", "Pearsonr", COLORMAP.blue]
                                ].map(item => (
                                    <div className="legend_item">
                                        {" "}
                                        <i className="fas fa-circle legend_item_icon" style={{ color: item[2] }}></i>
                                        {item[1]}: <span style={{ color: metrics_stats[item[0]] >= 0 ? "green" : "red" }}>{metrics_stats[item[0]]}</span>
                                    </div>
                                ))}
                            </div>
                            <LineChart
                                title="Metrics"
                                xAxisname="Epochs"
                                series={[
                                    {
                                        type: "line",
                                        color: COLORMAP.main,
                                        name: "R2",
                                        data: r_2_series.map((ele, idx) => [idx + 1, ele])
                                        // yAxis: 0
                                    },
                                    {
                                        type: "line",
                                        color: "var(--color-darkgold)",
                                        name: "Exp Var",
                                        data: exp_var.map((ele, idx) => [idx + 1, ele])
                                        // yAxis: 1
                                    },
                                    {
                                        type: "line",
                                        color: COLORMAP.blue,
                                        name: "Pearsonr",
                                        data: pearsonr.map((ele, idx) => [idx + 1, ele])
                                    }
                                    // {
                                    //     type: "line",
                                    //     dashStyle: "shortDash",
                                    //     color: "grey",
                                    //     name: "0 line",
                                    //     legendIndex: -1,
                                    //     data: r_2_series.map(ele => [ele[0], 0]).map((ele, idx) => [idx + 1, ele]),
                                    //     showInLegend: false,
                                    // }
                                ]}
                            />
                        </div>
                    </div>

                    <div key="chart-3" className="grid__chart_inner">
                        <div className="chart_inner_wrapper" style={{}}>
                            <LineChart
                                title="Y"
                                series={[
                                    {
                                        type: "column",
                                        color: COLORMAP.main,
                                        name: "Y",
                                        animation: false,
                                        data: y.map((ele, idx) => [idx, ele])
                                    }
                                ]}
                            />
                        </div>
                    </div>

                    <div className="grid__chart_inner">
                        <div className="chart_inner_wrapper" style={{ position: "relative" }}>
                            <div className="legend_wrapper" style={{}}>
                                <div className="legend_item">
                                    {" "}
                                    <i className="fas fa-circle legend_item_icon" style={{ color: "rgba(29, 60, 52, .9)" }}></i> {y_scatter_stats.pos}
                                </div>
                                <div className="legend_item">
                                    {" "}
                                    <i className="fas fa-circle legend_item_icon" style={{ color: "rgba(235, 191, 129, .9)" }}></i> {y_scatter_stats.neg}
                                </div>
                                <div className="legend_item"> Ratio: {(y_scatter_stats.p_n * 100).toFixed(1)}%</div>
                            </div>
                            <LineChart
                                type="scatter"
                                isHotReload={true}
                                title="Prediction & Actual"
                                subtitle={y_scatter_stats.pos ? `pos=${y_scatter_stats.pos} ; neg=${y_scatter_stats.neg} ; pos%=${(y_scatter_stats.p_n * 100).toFixed(1)}%` : ""}
                                xAxisname="Prediction"
                                yAxisname="Actual"
                                series={[
                                    {
                                        name: " ",
                                        animation: false,
                                        data: y_scatter,
                                        turboThreshold: 0
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );

        // return (
        //     <Layout>

        //         <div className="section" style={{
        //             margin: "10px 0",
        //             color: "#888",
        //             fontFamily: "open sans"
        //         }} >

        // <div className="flexBox_horizon" style={{ justifyContent: "space-between", padding: "10px 20px", flexWrap: "wrap" }}>
        //     {
        //         meta
        //             ?
        //             ['agent_id', 'security', 'architecture', 'batch_size', 'epochs', 'learning_rate', 'look_ahead', 'time_steps', "test_start"].map(key => (
        //                 <div>
        //                     <div className="label">{titleWords(key)}</div>
        //                     <div className="value">{meta[key]}</div>
        //                 </div>
        //             ))
        //             : ""
        //     }
        // </div>

        // <div className="flexBox_horizon" style={{ justifyContent: "space-between", padding: "5px 20px", flexWrap: "wrap" }}>
        //     {
        //         meta
        //             ?
        //             ['start_at', 'time_elapsed', 'speed'].map(key => (
        //                 <div>
        //                     <div className="label">{titleWords(key)}</div>
        //                     <div className="value">{ts_info[key]}</div>
        //                 </div>
        //             ))
        //             : ""
        //     }
        // </div>

        // <div style={{ margin: "5px 0", padding: "0 18px" }}>
        // <div className="flexBox_horizon">
        //     <div style={{
        //         position: "relative",
        //         height: "8px",
        //         padding: "2px",
        //         width: "100%",
        //         border: "1px solid #ccc",
        //         // background: "#ccc",
        //         borderRadius: "40px",
        //     }}>
        //         <div
        //             style={{
        //                 background: COLORMAP.main,
        //                 width: `${progress.progressValue || 0}%`,
        //                 height: "100%",
        //                 transition: "all .2s ease-in",
        //                 borderRadius: "inherit",
        //             }}
        //         ></div>
        //     </div>
        //     <div className="value" style={{ marginLeft: "4px" }}>{(progress.progressValue || 0).toFixed(0) + "%"}</div>
        // </div>
        // </div>
        //         </div>

        //         <div className='section section-grid' style={{ marginBottom: "20px", width: '100%', position: "relative" }}>
        // <div className="chart_inner_wrapper" style={{ position: "relative" }}>
        //     <LineChart
        //         title="Loss"
        //         height={400}
        //         xAxisname="Epochs"
        //         isLog={true}
        //         series={[
        //             {
        //                 type: "line",
        //                 color: "var(--color-darkgold)",
        //                 name: "Loss",
        //                 data: loss_series.map((ele, idx) => [idx + 1, ele]),
        //             },
        //             {
        //                 type: "line",
        //                 color: COLORMAP.main,
        //                 name: "Val Loss",
        //                 data: val_loss_series.map((ele, idx) => [idx + 1, ele]),
        //             },
        //         ]} />
        // </div>
        // <div className="chart_inner_wrapper" style={{ position: "relative" }}>
        //     <div className="legend_wrapper">
        //         {
        //             [
        //                 ['r_2', 'R2', COLORMAP.main],
        //                 ['exp_var', 'Explained Var', "var(--color-darkgold)"],
        //                 ['pearsonr', 'Pearsonr', COLORMAP.blue]
        //             ].map(item => (
        //                 <div className="legend_item" > <i className="fas fa-circle legend_item_icon" style={{ color: item[2] }}></i>{item[1]}: <span style={{ color: metrics_stats[item[0]] >= 0 ? "green" : "red" }}>{metrics_stats[item[0]]}</span></div>
        //             ))
        //         }
        //     </div>
        //     <LineChart
        //         title="Metrics"
        //         height={400}
        //         xAxisname="Epochs"
        //         // isLog={true}
        //         series={[
        //             // {
        //             //     type: "line",
        //             //     color: COLORMAP.main,
        //             //     name: "R2",
        //             //     data: r_2_series.map((ele, idx) => [idx + 1, ele]),
        //             //     // yAxis: 0
        //             // },
        //             // {
        //             //     type: "line",
        //             //     color: "var(--color-darkgold)",
        //             //     name: "Exp Var",
        //             //     data: exp_var.map((ele, idx) => [idx + 1, ele]),
        //             //     // yAxis: 1
        //             // },
        //             {
        //                 type: "line",
        //                 color: COLORMAP.blue,
        //                 name: "Pearsonr",
        //                 data: pearsonr.map((ele, idx) => [idx + 1, ele]),
        //             },
        //             // {
        //             //     type: "line",
        //             //     dashStyle: "shortDash",
        //             //     color: "grey",
        //             //     name: "0 line",
        //             //     legendIndex: -1,
        //             //     data: r_2_series.map(ele => [ele[0], 0]).map((ele, idx) => [idx + 1, ele]),
        //             //     showInLegend: false,
        //             // }
        //         ]} />
        // </div>
        //         </div>

        //         <div className='section section-grid' style={{ marginBottom: "20px", width: '100%', position: "relative" }}>
        // <div className="chart_inner_wrapper" style={{}}>
        //     <LineChart
        //         title="Y"
        //         height={400}
        //         series={[
        //             {
        //                 type: "column",
        //                 color: COLORMAP.main,
        //                 name: "Y",
        //                 animation: false,
        //                 data: y.map((ele, idx) => [idx, ele]),
        //             },
        //         ]} />
        // </div>
        // <div className="chart_inner_wrapper" style={{ position: "relative" }}>
        //     <div className="legend_wrapper" style={{}}>
        //         <div className="legend_item" > <i className="fas fa-circle legend_item_icon" style={{ color: "rgba(29, 60, 52, .9)" }}></i> {y_scatter_stats.pos}</div>
        //         <div className="legend_item" > <i className="fas fa-circle legend_item_icon" style={{ color: "rgba(235, 191, 129, .9)" }}></i> {y_scatter_stats.neg}</div>
        //         <div className="legend_item" > Ratio: {(y_scatter_stats.p_n * 100).toFixed(1)}%</div>
        //     </div>
        //     <LineChart
        //         type='scatter'
        //         isHotReload={true}
        //         title="Prediction & Actual"
        //         subtitle={y_scatter_stats.pos ? `pos=${y_scatter_stats.pos} ; neg=${y_scatter_stats.neg} ; pos%=${(y_scatter_stats.p_n * 100).toFixed(1)}%` : ""}
        //         height={400}
        //         xAxisname="Prediction"
        //         yAxisname="Actual"
        //         series={[
        //             {
        //                 name: " ",
        //                 animation: false,
        //                 data: y_scatter,
        //                 turboThreshold: 0,
        //             }
        //         ]} />
        // </div>
        //         </div>

        //     </Layout>
        // )
    }
}
