import React, { Component } from "react";
import styled from "styled-components";
import ImbueRcTable from "../../components/table/ImbueRcTable";
import { COLORMAP } from "../../config";
import { toPercentage } from "../../helpers/Utils";
import ImbueSelect from "../../components/input/ImbueSelect";

const Layout = styled.div`
    padding: 20px;
`;

const demoList = [
    {
        ticker: "RIO AU Equity",
        group1: "Equity",
        group2: "Australia",
        group3: "Aust WPL v RIO",
        exp_short: -8372.42,
        exp_long: 3294.131,
        exp: 432,
        beta: 1.332,
        beta$: -1234.21,
        tcr_total: 1214123,
        tcr_sys: 2124.121,
        tcr_rsd: 231.241,
        nav_pct: 0.123,
        exp_rule: 0.234,
        qty_bod: 234.132,
        open_int: 0,
        open_int_pct: 0,
    },
    {
        ticker: "WPL AU Equity",
        group1: "Equity",
        group2: "Australia",
        group3: "Aust WPL v RIO",
        exp_short: -8372.42,
        exp_long: 3294.131,
        exp: 432,
        beta: -1.332,
        beta$: 1234.21,
        tcr_total: -1214123,
        tcr_sys: -2124.121,
        tcr_rsd: 231.241,
        nav_pct: 0.123,
        exp_rule: 0.234,
        qty_bod: 234.132,
        open_int: 0,
        open_int_pct: 0,
    },
    {
        ticker: "CL1 Comdty",
        group1: "Commodity",
        group2: "US",
        group3: "Energy",
        exp_short: -8372.42,
        exp_long: 3294.131,
        exp: 432,
        beta: -1.332,
        beta$: 1234.21,
        tcr_total: -1214123,
        tcr_sys: -2124.121,
        tcr_rsd: 231.241,
        nav_pct: 0.123,
        exp_rule: 0.234,
        qty_bod: 234.132,
        open_int: 0,
        open_int_pct: 0,
    },
    {
        ticker: "NG1 Comdty",
        group1: "Commodity",
        group2: "World",
        group3: "Energy",
        exp_short: -8372.42,
        exp_long: 3294.131,
        exp: 432,
        beta: -1.332,
        beta$: 1234.21,
        tcr_total: -1214123,
        tcr_sys: -2124.121,
        tcr_rsd: 231.241,
        nav_pct: 0.123,
        exp_rule: 0.234,
        qty_bod: 234.132,
        open_int: 0,
        open_int_pct: 0,
    },
    {
        ticker: "CO1 Comdty",
        group1: "Commodity",
        group2: "UK",
        group3: "Energy",
        exp_short: -8372.42,
        exp_long: 3294.131,
        exp: 432,
        beta: -1.332,
        beta$: 1234.21,
        tcr_total: -1214123,
        tcr_sys: -2124.121,
        tcr_rsd: 231.241,
        nav_pct: 0.123,
        exp_rule: 0.234,
        qty_bod: 234.132,
        open_int: 0,
        open_int_pct: 0,
    },
    {
        ticker: "W 1 Comdty",
        group1: "Commodity",
        group2: "World",
        group3: "Agriculture",
        exp_short: -8372.42,
        exp_long: 3294.131,
        exp: 432,
        beta: -1.332,
        beta$: 1234.21,
        tcr_total: -1214123,
        tcr_sys: -2124.121,
        tcr_rsd: 231.241,
        nav_pct: 0.123,
        exp_rule: 0.234,
        qty_bod: 234.132,
        open_int: 0,
        open_int_pct: 0,
    },
    {
        ticker: "C 1 Comdty",
        group1: "Commodity",
        group2: "World",
        group3: "Agriculture",
        exp_short: -8372.42,
        exp_long: 3294.131,
        exp: 432,
        beta: -1.332,
        beta$: 1234.21,
        tcr_total: -1214123,
        tcr_sys: -2124.121,
        tcr_rsd: 231.241,
        nav_pct: 0.123,
        exp_rule: 0.234,
        qty_bod: 234.132,
        open_int: 0,
        open_int_pct: 0,
    },
];

const commonConfig = (value_type) => ({
    Cell: (row) => formatRules[value_type](row),
    aggregate: (values) => values.reduce((a, b) => a + b),
    Aggregated: (row) => formatRules[value_type](row),
});

const demoColumns = [
    { Header: "Asset", accessor: "group1", Aggregated: (row) => {} },
    { Header: "Region", accessor: "group2", Aggregated: (row) => {} },
    { Header: "Sector", accessor: "group3", Aggregated: (row) => {} },
    { Header: "Security", accessor: "ticker", Aggregated: (row) => {} },
    { Header: "Exp (short)", accessor: "exp_short", ...commonConfig("plainNum") },
    { Header: "Exp (long)", accessor: "exp_long", ...commonConfig("plainNum") },
    { Header: "Exposure", accessor: "exp", ...commonConfig("plainNum") },
    { Header: "Beta", accessor: "beta", ...commonConfig("plainNum") },
    { Header: "Beta $", accessor: "beta$", ...commonConfig("plainNum") },
    { Header: "TCR total", accessor: "tcr_total", ...commonConfig("plainNum") },
    { Header: "TCR systematic", accessor: "tcr_sys", ...commonConfig("plainNum") },
    { Header: "TCR residual", accessor: "tcr_rsd", ...commonConfig("plainNum") },
    { Header: "NAV (%)", accessor: "nav_pct", ...commonConfig("pctNum") },
    { Header: "Exp rule", accessor: "exp_rule", ...commonConfig("pctNum") },
    { Header: "Qty (BOD)", accessor: "qty_bod", ...commonConfig("plainNum") },
    { Header: "Open Interest", accessor: "open_int", ...commonConfig("plainNum") },
    { Header: "Open Interest (%)", accessor: "open_int_pct", ...commonConfig("pctNum") },
];

const formatRules = {
    plainNum: (props) => <span style={{ color: props.value > 0 ? COLORMAP.green : COLORMAP.red }}>{props.value.toFixed(2)}</span>,
    pctNum: (props) => <span style={{ color: props.value > 0 ? COLORMAP.green : COLORMAP.red }}>{toPercentage(props.value, 2)}</span>,
};

class RiskManager extends Component {
    render() {
        const pivotBy = ["group1", "group2", "group3"];

        return (
            <Layout>
                <h1>Imbue Portfolio Manager</h1>
                <div className="ImbueSelectsWrapper">
                    <ImbueSelect options={[{ label: "Imbue Capital Fund", value: "imbuecapitalfund" }]} title="Fund" />
                    <ImbueSelect options={[{ label: "Imbue Capital Portfolio", value: "pf1" }]} title="Portfolio" />
                    <ImbueSelect options={[{ label: "View1", value: "vw1" }]} title="View" />
                    <ImbueSelect options={[{ label: "Calculation1", value: "cc1" }]} title="Calculation" />
                </div>
                <div className="StatsWrapper"></div>
                <div className="tableWrapper">
                    <ImbueRcTable list={demoList} columns={demoColumns} pivotBy={pivotBy} expandedRows={true} />
                </div>
            </Layout>
        );
    }
}

export default RiskManager;
