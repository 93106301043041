import { getToken } from "../auth/UserAuth";
import { EMAIL, HOST, CLIENTIP } from "../config";
import axios from "axios";

export const AuthGet = (url) => {
    if (url[url.length - 1] !== "&") {
        url += "&";
    }
    const token = getToken();
    return fetch(url + "_u=" + (EMAIL || "").split(".")[0] + "ip=" + CLIENTIP, {
        headers: new Headers({
            Authorization: "Bearer " + token,
        }),
    });
};

export const AuthPost = (url, data) => {
    const token = getToken();
    return fetch(url + "_u=" + (EMAIL || "").split(".")[0] + "ip=" + CLIENTIP, {
        method: "POST",
        headers: new Headers({
            Authorization: "Bearer " + token,
        }),
        body: JSON.stringify(data),
    });
};

export const AxiosGet = (url) => {
    if (url.indexOf("?") >= 0) {
        url += "&ip=" + CLIENTIP;
    } else {
        url += "?ip=" + CLIENTIP;
    }
    const token = getToken();
    return axios
        .create({
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        .get(url);
};

export const AxiosGetFile = (url) => {
    if (url.indexOf("?") >= 0) {
        url += "&ip=" + CLIENTIP;
    } else {
        url += "?ip=" + CLIENTIP;
    }
    const token = getToken();
    return axios
        .create({
            responseType: "arraybuffer",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        .get(url);
};

export const AxiosPost = (url, data, timeout) => {
    const token = getToken();
    let options = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    if (timeout) {
        options.timeout = timeout;
    }
    return axios.create(options).post(url, data);
};

export const AxiosPut = (url, data) => {
    const token = getToken();
    return axios
        .create({
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        .put(url, data);
};

export const BackToLogin = (url, data) => {
    console.log("*Token Expired!");
    setTimeout(() => {
        window.location.replace(`${HOST}/login`);
    }, 1000);
};
