import React, { Component } from "react";
import logo from "../img/Black.png";
import "../css/fonts.css";
import "../css/login.css";
import { UserLogin, UserSignup, ChangePassword, setToken, getToken } from "../auth/UserAuth";
import { emailToName } from "../helpers/Utils";
import { EMAIL } from "../config";
import { api_validate_token } from "../helpers/API";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            pwd: "",
            form: {},
            status: "normal",
            mode: "login",
            button: "Log In",
            buttonEnabled: true,
            error: "",
            isTokenValid: false,
            submitCount: 0
        };

        this.colorMap = {
            login: "rgb(33, 89, 74)",
            signup: "rgb(132, 82, 9)",
            changepwd: "rgb(19, 77, 142)"
        };

        this.onChangeValue = this.onChangeValue.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onError = this.onError.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onChangeMode = this.onChangeMode.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    componentDidMount() {
        this.authToken();
    }

    authToken() {
        const token = getToken();
        if (token) {
            api_validate_token()
                .then(res => {
                    const status = res.status;
                    if (status === 200) {
                        this.setState({ isTokenValid: true });
                    }
                    // FAIL: token is invalid
                })
                .catch(err => console.log(err)); // FAIL: network issues
        }
        // FAIL: no token was found
    }

    onChangeValue(key, value) {
        let { form } = this.state;
        form[key] = value;
        this.setState({ form });
    }

    onKeyPress(e) {
        if (e.key === "Enter") {
            this.onSubmit();
        }
    }

    onError(msg) {
        this.setState({
            ...this.state,
            status: "fail",
            error: msg
        });
    }

    onSubmit(e) {
        const { mode, form, submitCount } = this.state;

        this.setState({ submitCount: submitCount + 1 }, () => {
            switch (mode) {
                case "login": {
                    // -- Login Logic Handling
                    if (form.email !== undefined && form.pwd !== undefined) {
                        this.setState({ status: "loading" });
                        const { email, pwd } = form;

                        UserLogin(email, pwd)
                            .then(rsp => {
                                return {
                                    status_code: rsp.status,
                                    content: rsp
                                };
                            })
                            .then(res => {
                                const status = res.status_code;
                                if (status !== 200) {
                                    this.setState(
                                        {
                                            ...this.state,
                                            status: "normal"
                                        },
                                        () => {
                                            this.onError("Username or Password is Wrong!");
                                        }
                                    );
                                } else {
                                    res.content.json().then(result => {
                                        if (result.response === "login_successful") {
                                            const { access_token, username } = result.content;
                                            setToken(access_token);
                                            localStorage.setItem("_email", email);
                                            localStorage.setItem("_fname", username[0]);
                                            this.setState(
                                                {
                                                    status: "ok",
                                                    button: "Welcome Back! " + username[0],
                                                    buttonEnabled: false
                                                },
                                                () => {
                                                    setTimeout(() => {
                                                        window.location.replace("/");
                                                    }, 800);
                                                }
                                            );
                                        } else {
                                            const errorMsg = result.response;
                                            this.setState(
                                                {
                                                    ...this.state,
                                                    status: "normal"
                                                },
                                                () => {
                                                    this.onError(errorMsg);
                                                }
                                            );
                                        }
                                    });
                                }
                            });
                    }
                    break;
                }

                case "signup": {
                    let { email, pwd } = form;
                    if (email === "" || pwd === "") {
                        alert("Incomplete Input!");
                    } else {
                        this.setState({ status: "loading" });
                        UserSignup(email, pwd)
                            .then(response => {
                                return response.json();
                            })
                            .then(result => {
                                if (result.response === "signup_successful") {
                                    this.setState(
                                        {
                                            ...this.state,
                                            status: "ok",
                                            button: "You have successfully registered, please log in!",
                                            buttonEnabled: false
                                        },
                                        () => {
                                            setTimeout(function() {
                                                window.location.reload();
                                            }, 1000);
                                        }
                                    );
                                } else {
                                    let errorMsg = result.response
                                        .split("_")
                                        .map(ele =>
                                            ele.replace(/\w\S*/g, function(txt) {
                                                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                                            })
                                        )
                                        .join(" ");
                                    this.onError(errorMsg);
                                }
                            });
                    }
                    break;
                }

                case "changepwd": {
                    let { email, pwd, npwd, nnpwd } = form;
                    if (email && pwd && npwd && nnpwd) {
                        if (npwd !== nnpwd) {
                            this.onError("New Password Unmatched!");
                        } else {
                            this.setState({ status: "loading" });
                            ChangePassword(email, pwd, npwd)
                                .then(rsp => ({
                                    status_code: rsp.status,
                                    ok: rsp.ok,
                                    content: rsp
                                }))
                                .then(res => {
                                    const ok = res.ok;

                                    if (!ok) {
                                        // 403: User Auth Failed
                                        //

                                        this.onError("Invalid New Password");
                                    } else {
                                        this.setState(
                                            {
                                                ...this.state,
                                                status: "ok",
                                                button: "Password has been changed!",
                                                buttonEnabled: false
                                            },
                                            () => {
                                                setTimeout(() => {
                                                    window.location.reload();
                                                }, 800);
                                            }
                                        );
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                    break;
                }
                default: {
                    // code here
                }
            }
        });
    }

    onChangeMode(newMode) {
        switch (newMode) {
            case "login": {
                this.setState({ ...this.state, mode: newMode, button: "Log In" });
                break;
            }
            case "signup": {
                this.setState({ ...this.state, mode: newMode, button: "Sign Up" });
                break;
            }
            case "changepwd": {
                this.setState({ ...this.state, mode: newMode, button: "Confirm" });
                break;
            }
            default:
                break;
        }
    }

    renderForm(mode) {
        switch (mode) {
            case "login": {
                return (
                    <div key="input-login">
                        <div className="title_sub" style={{ color: this.colorMap[mode] }}>
                            Log in
                        </div>
                        <input
                            type="text"
                            onChange={e => {
                                this.onChangeValue("email", e.target.value.toLowerCase());
                            }}
                            onKeyPress={this.onKeyPress}
                            placeholder="Email"
                            autoFocus
                        />
                        <input
                            type="password"
                            onChange={e => {
                                this.onChangeValue("pwd", e.target.value);
                            }}
                            onKeyPress={this.onKeyPress}
                            placeholder="Password"
                        />
                    </div>
                );
            }
            case "signup": {
                return (
                    <div key="input-signup">
                        <div className="title_sub" style={{ color: this.colorMap[mode] }}>
                            Sign Up
                        </div>
                        <input
                            key="input-email-signup"
                            type="text"
                            onChange={e => {
                                this.onChangeValue("email", e.target.value.toLowerCase());
                            }}
                            onKeyPress={this.onKeyPress}
                            placeholder="Email"
                            autoFocus
                        />
                        <input
                            key="input-pwd-signup"
                            type="password"
                            onChange={e => {
                                this.onChangeValue("pwd", e.target.value);
                            }}
                            onKeyPress={this.onKeyPress}
                            placeholder="Password"
                        />
                    </div>
                );
            }
            case "changepwd": {
                return (
                    <div key="input-changepwd">
                        <div className="title_sub" style={{ color: this.colorMap[mode] }}>
                            Change Password
                        </div>
                        <input
                            key="input-email-changepwd"
                            type="text"
                            onChange={e => {
                                this.onChangeValue("email", e.target.value.toLowerCase());
                            }}
                            onKeyPress={this.onKeyPress}
                            placeholder="Email"
                            autoFocus
                        />
                        <input
                            key="input-pwd-changepwd"
                            type="password"
                            onChange={e => {
                                this.onChangeValue("pwd", e.target.value);
                            }}
                            onKeyPress={this.onKeyPress}
                            placeholder="Old Password"
                        />
                        <input
                            key="input-pwd-changepwd"
                            type="password"
                            onChange={e => {
                                this.onChangeValue("npwd", e.target.value);
                            }}
                            onKeyPress={this.onKeyPress}
                            placeholder="New Password"
                        />
                        <input
                            key="input-pwd-changepwd"
                            type="password"
                            onChange={e => {
                                this.onChangeValue("nnpwd", e.target.value);
                            }}
                            onKeyPress={this.onKeyPress}
                            placeholder="Comfirm New Password"
                        />
                    </div>
                );
            }
            default: {
                return <div></div>;
            }
        }
    }

    renderGoToIndex() {
        return (
            <div className="fontFamily__imbue" style={{ animation: "textFloat 1s" }}>
                <div style={{ textAlign: "center", color: "#777", fontSize: "1.3rem", margin: "10px 0" }}>Welcome back {emailToName(EMAIL)}!</div>
                <div className="flexBox_horizon">
                    <div
                        className="onHover noselect flexBox_vertical"
                        style={{
                            fontSize: "1.2rem",
                            margin: "20px 0",
                            padding: "10px 20px",
                            background: "#eee",
                            borderRadius: "5px",
                            color: "#555"
                        }}
                        onClick={() => {
                            window.location.replace("/");
                        }}
                    >
                        Return to Home Page
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { mode, status, buttonEnabled, isTokenValid, submitCount } = this.state;

        return (
            <div className="login_wrapper">
                <div className="login_error_bar" key={submitCount} ref="error_bar" style={{ display: status === "fail" ? "flex" : "none" }}>
                    <i className="fas fa-info-circle" style={{ marginRight: "5px" }}></i> {this.state.error !== "" ? this.state.error : "Unkonw Error!"}
                </div>

                <div className="inner_wrapper">
                    <div
                        className={(() => {
                            switch (status) {
                                case "loading":
                                    return "login loading";
                                case "ok":
                                    return "login ok";
                                default:
                                    return "login";
                            }
                        })()}
                        key={mode}
                        style={{ animation: "scaleUp .3s" }}
                    >
                        <div className="imbue_logo">
                            <img src={logo} alt="" width={150} />
                        </div>

                        {isTokenValid ? (
                            this.renderGoToIndex()
                        ) : (
                            <div>
                                {this.renderForm(mode)}

                                {
                                    <div>
                                        <div className="sub_btn_group">
                                            <div
                                                className="small_btn noselect"
                                                onClick={() => {
                                                    this.onChangeMode(mode === "login" ? "signup" : "login");
                                                    // this.onChangeMode("login")
                                                }}
                                            >
                                                {mode === "login" ? "Sign Up" : "Log In"}
                                            </div>
                                            <div
                                                className="small_btn"
                                                onClick={() => {
                                                    this.onChangeMode("changepwd");
                                                }}
                                            >
                                                Change Password
                                            </div>
                                        </div>

                                        <button
                                            onClick={() => {
                                                if (buttonEnabled) {
                                                    this.onSubmit();
                                                }
                                            }}
                                            style={{
                                                background: [this.colorMap[mode], "white", this.colorMap[mode], this.colorMap[mode]][["loading", "ok", "fail", "normal"].indexOf(status)],
                                                color: ["#fff", "#333", "#fff", "#fff"][["loading", "ok", "fail", "normal"].indexOf(status)]
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: status === "normal" || status === "fail" ? "none" : undefined,
                                                    margin: "15px"
                                                }}
                                            >
                                                <i className={["spinner fal fa-spinner", "spinner fal fa-check-circle", "spinner fal fa-exclamation-circle", ""][["loading", "ok", "fail", "normal"].indexOf(status)]}></i>
                                            </div>

                                            <span className="state">{this.state.button}</span>
                                        </button>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
