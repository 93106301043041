import React, { useState, useEffect } from 'react';
import { LoadingPage } from "../../helpers/SimpleComponents";
import HighStockWrapper from '../../components/chart/HighStockWrapper';
import "./style.css";

var wsUrl = ( window.location.protocol === 'https:'
    ? "wss://static.cbot.comatl.com:49534/"
    : "ws://static.cbot.comatl.com:49533/"
);

/*
    - Chart displaying Delta or Delta_Pct_Combined as columns with continuous contract's price line overlayed
    - pos/neg Delta change is shown below
*/
const DashboardDeltaICE = (props) => {

    const [data, setData] = useState(null);
    const [root, setRoot] = useState(props.root);


    // fetch data on symbol change, either from user input or props change (below)
    useEffect(() => {
        // console.log(tempJson)
        const socket = new WebSocket(wsUrl);

        setData(null);

        socket.onopen = () => {
            if (root) {
                socket.send(JSON.stringify({
                    "type": "start", 
                    "source": "delta", 
                    "authorization": "Bearer ".concat(localStorage.getItem("_token")), 
                    "args": {"root": root}
                }));
            }
        };

        socket.onmessage = (event) => {
            const json = JSON.parse(event.data);
            try {
                console.log(json);
                if (json['type'] && json['type'] === 'data') {
                    setData(json['data']);
                }
            } catch (err) {
                console.error(err);
            }
        };

        return () => {
            socket.close();
        };

    }, [root, props.root, props.commonRoot]);


    // props change
    useEffect(() => {
        setRoot(props.root);
    }, [props.root]);


    const getChartConfig = () => {
        const res = {
            chart: {
                animation: false,
		height: "45%",
            },
            title: {
                text: `Delta: ${props.commonRoot}`,
            },
            legend: {
                enabled: true,
            },
            scrollbar: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            rangeSelector: {
                // enabled: false,
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%m/%d}'
                },
            },
            yAxis: [
                {
                    title: {
                        text: 'front_px',
                    },
                    height: '60%',
                    opposite: false,
                    // offset: 30,
                },
                {
                    title: {
                        // text: 'delta'
                    },
                    plotLines: [{
                        color: "gray",
                        width: 0.5,
                        value: 0
                    }],
                    height: '60%',
                    // opposite: false,
                },
                {
                    title: {
                        // text: 'delta_pct_combined',
                        text: 'delta'
                    },
                    labels: {
                        // enabled: false,
                    },
                    plotLines: [{
                        color: "gray",
                        width: 0.5,
                        value: 0
                    }],
                    height: '60%',
                    // opposite: false,
                },
                {
                    title: {
                        text: 'delta_change',
                    },
                    top: '55%',
                    height: '60%',
                    offset: 30,
                    labels: {
                        // enabled: false,
                    },
                    plotLines: [{
                        color: "gray",
                        width: 0.5,
                        value: 0
                    }],
                    gridLineWidth: 0,
                    // opposite: false,
                },
            ],
            series: [
                {
                    name: 'Price',
                    type: 'line',
                    yAxis: 0,
                    data: data.map((ele) => [
                        Date.parse("US/Chicago "+ele['trade_date']), 
                        ele['front_px']
                    ]),
                    color: 'black',
                    zIndex: 0,
                },
                {
                    name: 'Delta',
                    type: 'column',
                    yAxis: 1,
                    data: data.map((ele) => [
                        Date.parse("US/Chicago "+ele['trade_date']), 
                        ele['delta']
                    ]),
                    color: 'rgb(50, 200, 200, 0.5)', // light blue
                    zIndex: 1,
                },
                {
                    name: 'Delta_Pct_Combined',
                    type: 'column',
                    yAxis: 2,
                    data: data.map((ele) => [
                        Date.parse("US/Chicago "+ele['trade_date']), 
                        ele['delta_pct_combined']
                    ]),
                    color: 'rgb(200, 0, 150, 0.5)', // purple
                    visible: false,
                    zIndex: 1,
                },
                {
                    name: 'Delta_Change',
                    type: 'column',
                    yAxis: 3,
                    data: data.map((ele) => [
                        Date.parse("US/Chicago "+ele['trade_date']), 
                        ele['delta_change']
                    ]),
                    color: 'green',
                    negativeColor: 'red',
                    pointPadding: 0.1,
                    groupPadding: 0.2,
                    opacity: 0.75,
                    zIndex: 0,
                },
            ],
            plotOptions: {
                series: {
                    animation: false,
                    pointPadding: 0.1,
                    // groupPadding: 0.05,
                    connectNulls: true,
                    dataGrouping: {
                        enabled: false,
                    },
                    events: {
                        legendItemClick: function () {
                            const clickedSeriesIndex = this.index;
                            if (clickedSeriesIndex === 1 || clickedSeriesIndex === 2) {
                                const yAxisIndex = this.yAxis.options.index;
                                // const axis = this.chart.yAxis[yAxisIndex];
                                const otherSeriesIndex = clickedSeriesIndex === 1 ? 2 : 1;
                                // const yAxisTitle = clickedSeriesIndex === 1 ?  "Delta" : "Delta % Combined";
                                // axis.setTitle({ text: yAxisTitle, useHTML: true });
                                this.chart.series[otherSeriesIndex].setVisible(this.visible);
                                this.chart.redraw();
                            }
                            
                        }
                    }
                }
            },
            tooltip: {
                split: true,
                shared: false,
            },
        };
        return res;
    };


    return (
        <div>
            {/* <SymbolSelector symbol={symbol}/> */}
            { data === null && LoadingPage() }
            { data !== null &&
                <div style={{margintTop: "-12px"}}>
                    <HighStockWrapper config={getChartConfig()} />
                </div>
            }
        </div>
    );

};

export default DashboardDeltaICE;
