/**
 * @name ImbueAILab_Optimizer
 * @description Optimise Display interface for Imbue AI Lab Game
 * @author Claude Chen <claude.chen@imbuecapital> 6 Spet 2018
 * @copyright Imbue Capital 2018
 */

import React, { Component } from "react";
import { layoutConfig, HOST, COLORMAP, EMAIL } from "../../../config";
import styled from "styled-components";

import CircularProgressbar from "react-circular-progressbar";
import HistChart from "./BatchGameComponents/HistChart";
import "react-circular-progressbar/dist/styles.css";
import { getToken } from "../../../auth/UserAuth";
import { get_stat, getAvg, getStd, getPositiveRatio, deg_to_dms } from "../../../helpers/Utils";
import moment from "moment";
import { test_jobs } from "./test_object";
import MiniPnlChart from "../../../components/chart/MiniPnlChart";
import MiniTradesViewer from "./BatchGameComponents/MiniDocViewer";
import { api_ailab_optimiser_query_user, api_ailab_factoranalysis } from "../../../helpers/API";
import { ailab_optimizer_ws } from "../../../helpers/WEBSOCKET";
import FactorAnalysisModule from "./BatchGameComponents/FactorAnalysisModule";

const TEST = 0;

const Layout = styled.div`
    font-family: var(--font-main);
    position: relative;
    height: calc(100vh - ${layoutConfig.navbarHeight + layoutConfig.textStripHeight}px);
    overflow-y: scroll;

    display: grid;
    grid-template-rows: 200px calc(100% - 200px);
    grid-template-columns: 100%;

    a {
        color: #333;
    }

    .panel_wrapper {
        background: #fff;
        border-bottom: 1px solid #ddd;
        position: relative;
        box-sizing: border-box;
    }

    .panel_item {
        /* background: #ddd; */
    }

    /* Row 1/2 */
    .workingpanel_wrapper {
        padding: 10px;
        display: grid;
        grid-template-columns: 37.5% 25% 37.5%;
        grid-template-rows: 100%;
        box-sizing: border-box;
    }

    /* Row 2/2 */

    .section_title {
        height: 100%;
    }
    /* result_table_layout */

    .resulttable_wrapper {
        height: 100%;
        position: relative;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 25px calc(100% - 25px);
    }

    .radius_progress_bar {
        display: flex;
        justify-content: center;
        align-items: center;
        /* padding: 10px; */
    }

    .CircularProgressbar .CircularProgressbar-path {
        animation: ${props => (props.progress === 100 ? null : "progressFlash .6s linear infinite")};
    }

    /* -- Data -- */
    .label {
        color: #888;
        font-size: 0.9rem;
    }
    .value {
        font-family: "Fjalla One";
        color: #555;
        font-size: 1.4rem;
    }

    .section_title {
        color: #555;
        font-size: 1.2rem;
        margin-bottom: 10px;
    }

    .section_title i {
        margin-right: 7px;
        font-size: 1.6rem;
    }

    .section_title span {
        /* color: #333; */
        font-size: 1.4rem;
        margin: 0 4px;
    }

    .section_title .section_title_item {
        margin-right: 10px;
        font-size: 1.1rem;
        display: flex;
        align-items: center;
    }

    .section_title .section_title_item i {
        font-size: 1.1rem;
    }

    /* --+-- table --+-- */
    .tb_layout {
        /* border: 1px solid #ddd; */
        /* border-radius: 4px; */
    }

    .tb_row {
        display: flex;
        justify-content: space-around;
        font-family: var(--font-main);
        font-size: 1.2rem;
        color: #888;
    }

    .tb_row:not(:last-child) {
        border-bottom: 1px solid rgb(240, 240, 240);
    }

    .tb_row:hover:not(.tb_header) {
        background: #eee;
    }

    .tb_header {
        padding: 5px 0;
        font-weight: 800;
        color: #666;
        /* background: rgb(240, 240, 240); */
        /* border-bottom: 1px solid #aaa; */
    }

    .tb_cell {
        flex: 1 1 1px;
        width: 100%;
        overflow: auto;
        padding: 3px 10px;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        ::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        /* overflow-x: auto; */
    }

    .tb_cell_sml {
        width: 20%;
    }

    .tb_body:not(.tb_id_completed) {
        max-height: 200px;
        overflow-y: auto;
    }

    /* --+-- Result Table View --+-- */
    .result_table_layout {
        display: grid;
        grid-template-columns: 360px calc(100% - 360px);
        grid-template-rows: 100%;
        height: 100%;
        font-family: var(--font-main);
    }

    .result_table_sidebar {
        border-right: 1px solid #ccc;
        overflow: auto;
        height: 100%;
        position: relative;
    }

    .rt_sidebar_row {
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .result_table_viewer {
        height: 100%;
        position: relative;
        padding: 20px 10px;
        box-sizing: border-box;
    }
`;

class WorkingPanel extends Component {
    /**
     * Display the overall progress of the Optimiser Game
     */

    constructor(props) {
        super(props);
        this.state = {
            groupByAgent: null
        };
        this.onChangeVisible = this.onChangeVisible.bind(this);
    }

    componentDidMount() {}

    onChangeGroupByAgent(newAgent) {
        this.setState({ groupByAgent: newAgent === "All" ? null : newAgent });
    }

    onChangeVisible(newVisibles) {
        this.setState({ visible: newVisibles });
    }

    getGameMeta() {
        api_ailab_optimiser_query_user(EMAIL)
            .then(res => {
                const meta = res.data.meta;
                this.setState({
                    gameinput: meta.body,
                    ts: meta.start_ts,
                    end_ts: meta.end_ts
                });
            })
            .catch(err => console.log(err));
    }

    formatStats() {
        const { gamedata } = this.props;
        const { groupByAgent } = this.state;

        const completed = (gamedata ? gamedata.completed : []).filter(ele => Object.keys(ele.result).length > 0).filter(ele => (groupByAgent ? ele.agent === groupByAgent : true));

        const sharpe_and_pnl = completed.map(ele => [parseFloat(get_stat(ele.result["out_of_sample"], "Sharpe")), parseFloat(get_stat(ele.result["out_of_sample"], "Total Pnl")), ele.agent]);

        const sharpe_and_annul_pnl = completed.map(ele => [parseFloat(get_stat(ele.result["out_of_sample"], "Sharpe")), parseFloat(get_stat(ele.result["out_of_sample"], "Annualised Pnl").replace("%", ""))]);

        const sharpe_and_annul_vol = completed.map(ele => [parseFloat(get_stat(ele.result["out_of_sample"], "Sharpe")), parseFloat(get_stat(ele.result["out_of_sample"], "Annualised Vol").replace("%", ""))]);

        const stat__avg_sharpe = [getAvg(sharpe_and_pnl.map(ele => ele[0])).toFixed(2), "Avg Sharpe"];
        const stat__avg_pnl = [getAvg(sharpe_and_annul_pnl.map(ele => ele[1])).toFixed(2), "Avg Annual P&L"];
        const stat__avg_vol = [getAvg(sharpe_and_annul_vol.map(ele => ele[1])).toFixed(2), "Avg Annual Volatility"];
        const stat__std_sharpe = [getStd(sharpe_and_pnl.map(ele => ele[0])).toFixed(2), "Sharpe Std"];
        const stat__sharpe_pos_ratio = [getPositiveRatio(sharpe_and_pnl.map(ele => ele[0])).toFixed(2) + "%", "+Sharpe %"];
        const stat__max_sharpe = [Math.max(...sharpe_and_pnl.map(ele => ele[0])).toFixed(2), "Max Sharpe"];
        const stat__min_sharpe = [Math.min(...sharpe_and_pnl.map(ele => ele[0])).toFixed(2), "Min Sharpe"];
        const stat__cnt_sharpe = [sharpe_and_pnl.length, "Count"];

        const stats = {
            stat__avg_sharpe,
            stat__min_sharpe,
            stat__max_sharpe,
            stat__std_sharpe,
            stat__sharpe_pos_ratio,
            stat__avg_pnl,
            stat__avg_vol,
            stat__cnt_sharpe
        };

        return {
            stats
        };
    }

    // API
    get_factoranalysis(docids) {
        api_ailab_factoranalysis(docids).then(res => {
            this.setState({
                factoranalysis_data: res.data
            });
        });
    }

    updateFam() {
        this.refs["fam"].getData();
    }

    render() {
        const { progress, gamedata } = this.props;
        const { ts, end_ts } = this.state;
        const { stats } = this.formatStats();

        // Component :: Left Stats
        const component__left = () => {
            if (gamedata) {
                let completed = gamedata.completed;
                if (completed.length > 0) {
                    completed = completed.filter(ele => ele.status === "finished");

                    const list_of_docid = completed.map(ele => ele.doc);

                    let finishedJobStats = completed
                        .map(ele => ele.result.out_of_sample.filter(item => item.label === "Sharpe by Year")[0])
                        .filter(ele => ele)
                        .map(ele => ele.value);
                    finishedJobStats = finishedJobStats.length > 0 ? finishedJobStats.reduce((a, b) => (a ? a.map((ele, idx) => [ele[0], ele[1] + b[idx][1]]) : a)).map(ele => [ele[0], ele[1] / finishedJobStats.length]) : finishedJobStats;
                    // console.log(finishedJobStats)
                    const finishedJobStatsText = (
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                            <div>
                                <div style={{ fontSize: "1.2rem", fontFamily: "var(--font-main)", color: "#888", marginBottom: "4px" }}>Average Sharpe</div>
                                {finishedJobStats.map(ele => (
                                    <div>
                                        {ele[0]}: <span style={{ color: ele[1] >= 0 ? COLORMAP.green : COLORMAP.red }}>{ele[1].toFixed(3)}</span>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <div style={{ fontSize: "1.2rem", fontFamily: "var(--font-main)", color: "#888", marginBottom: "4px" }}>Factor Importance</div>
                                <FactorAnalysisModule key="fam" ref="fam" docids={list_of_docid} />
                            </div>
                        </div>
                    );
                    return finishedJobStatsText;
                } else {
                    return "";
                }
            } else {
                return "";
            }
        };

        return (
            <div className="panel_wrapper workingpanel_wrapper" style={{ animation: "fadeIn .5s" }}>
                <div className="panel_item">
                    <div style={{ animation: "fadeIn .4s", borderRadius: "10px", height: "100%", overflow: "auto", fontSize: "1.1rem", color: "#111", fontWeight: "800", padding: "5px", boxSizing: "border-box" }}>{component__left()}</div>
                </div>

                {/* MID: Progress DashBoard */}
                <div className="panel_item radius_progress_bar" style={{ flexDirection: "column" }}>
                    <div style={{ position: "relative", margin: "5px 0", width: "100%" }}>
                        {[1, 0].map(n => (
                            <div className="flexBox_horizon" style={{ width: "100%" }}>
                                {Object.keys(stats)
                                    .slice(0, 5)
                                    .map(key => (
                                        <div style={{ margin: "0px", flex: "1 1 1px" }}>
                                            <div className={["value", "label"][n]} style={{ textAlign: "center" }}>
                                                {stats[key][n]}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        ))}
                    </div>

                    <div style={{ position: "relative", maxWidth: "80px", minWidth: "80px", zIndex: "999" }}>
                        <CircularProgressbar
                            strokeWidth={5}
                            backgroundPadding={0}
                            percentage={progress}
                            background={1}
                            styles={{
                                background: {
                                    fill: "rgba(29, 60, 52, .03)"
                                },
                                path: {
                                    stroke: "rgb(68,117,99)"
                                }
                            }}
                        />
                        <div className="flexBox_horizon" style={{ position: "absolute", width: "100%", height: "100%", left: "0", top: "0" }}>
                            <span style={{ fontSize: "1.4rem", color: "var(--color-main)", fontFamily: "Fjalla One" }}>{progress.toFixed(0)}</span>
                            <span style={{ fontSize: ".8rem", color: "#888", marginBottom: "16px" }}>%</span>
                        </div>
                    </div>

                    <div className="flexBox_horizon" style={{ color: "#666", fontSize: "1rem", fontFamily: "Fjalla One", margin: "4px 0" }}>
                        <i className="fas fa-clock" style={{ marginRight: "5px" }}></i>
                        <div style={{ textAlign: "center" }}>{deg_to_dms(((end_ts ? moment(end_ts * 1000) : moment()) - moment(ts * 1000)) / 1000)}</div>
                    </div>

                    <div style={{ position: "relative", margin: "5px 0", width: "100%" }}>
                        {[1, 0].map(n => (
                            <div className="flexBox_horizon" style={{ width: "100%" }}>
                                {Object.keys(stats)
                                    .slice(5, 9)
                                    .map(key => (
                                        <div style={{ margin: "0px", flex: "1 1 1px" }}>
                                            <div className={["value", "label"][n]} style={{ textAlign: "center" }}>
                                                {stats[key][n]}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        ))}
                    </div>
                </div>

                {/* RIGHT: Chart */}
                <div className="panel_item flexBox_vertical" style={{ position: "relative", alignItems: "center" }}>
                    {/* Chart */}
                    <HistChart gamedata={gamedata} />
                </div>
            </div>
        );
    }
}

class ResultTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDocId: null
        };
    }

    render() {
        const { jobs } = this.props;
        const { selectedDocId } = this.state;

        const { completed, processing, pending } = jobs;
        const processingCnt = processing.length;
        const pendingCnt = pending.length;

        const list = completed.filter(ele => ele.status === "finished");
        const finishedCnt = completed.filter(ele => ele.status === "finished").length;
        const canceledCnt = completed.filter(ele => ele.status === "cancelled").length;
        const errorCnt = completed.filter(ele => ele.status === "error").length;

        const selectedRow = list.filter(ele => ele.doc === selectedDocId)[0];

        const progressBarList = [
            { color: COLORMAP.main, icon: "fas fa-check-circle", text: "Completed", data: finishedCnt },
            { color: "var(--color-darkgold)", icon: "fas fa-sync-alt", text: "Processing", data: processingCnt, isAnimated: processingCnt > 0 },
            { color: COLORMAP.grey, icon: "fas fa-ellipsis-h", text: "Pending", data: pendingCnt },
            { color: COLORMAP.blue, icon: "fas fa-ban", text: "Cancelled", data: canceledCnt },
            { color: COLORMAP.red, icon: "fas fa-exclamation-circle", text: "Error", data: errorCnt }
        ];

        // -- Component: Progress Bar
        const component__progressbar = () => (
            <div className="section_title flexBox" style={{ padding: "0 10px", borderBottom: "1px solid #ddd", background: "#eee" }}>
                {progressBarList.map((ele, idx) => (
                    <div key={idx} style={{ color: ele.color }} className="section_title_item">
                        <i className={ele.icon} style={{ color: ele.color, animation: ele.isAnimated ? "rotate 1s infinite linear" : undefined }}></i>
                        {ele.text}: <span className="fontFamily__imbue_digit_condensed">{ele.data}</span>
                    </div>
                ))}
            </div>
        );

        // -- Component: Game results
        const component__gamelist = () => {
            if (list.length) {
                return (
                    <div className="result_table_layout">
                        <div className="result_table_sidebar">
                            {list
                                .filter(ele => get_stat(ele.result.out_of_sample, "Sharpe") > 0)
                                .map((row, idx) => {
                                    const sharpe = get_stat(row.result.out_of_sample, "Sharpe");
                                    const winrate = get_stat(row.result.out_of_sample, "Win Rate");
                                    const totalpnl = get_stat(row.result.out_of_sample, "Total Pnl");
                                    const annualpnl = get_stat(row.result.out_of_sample, "Annualised Pnl");
                                    const notrades = get_stat(row.result.out_of_sample, "No. Trades");
                                    return (
                                        <div
                                            key={"job" + row.job_id}
                                            className="rt_sidebar_row"
                                            style={{
                                                backgroundColor: row.doc === selectedDocId ? "rgb(235, 235, 235)" : undefined
                                            }}
                                            onClick={e => {
                                                this.setState({ selectedDocId: row.doc });
                                            }}
                                        >
                                            <div>
                                                <div style={{ color: "#999" }}>
                                                    <small># {idx + 1}</small>
                                                </div>
                                                <div>
                                                    {" "}
                                                    <i style={{ color: "#888", fontSize: "1.2rem" }} className="fas fa-rocket"></i> {row.agent}
                                                </div>
                                                <div>
                                                    <small>job_id: {row.job_id}</small>
                                                </div>
                                                <div className="flexBox" style={{ color: "#777", margin: "2px 0" }}>
                                                    <a target="_blank" rel="noopener noreferrer" href={`${HOST}/imbue_ailab/result_viewer?docid=${row.doc}`}>
                                                        <i style={{ marginRight: "5px", fontSize: "1.2rem" }} className="fas fa-external-link-alt"></i>
                                                    </a>
                                                    <a target="_blank" rel="noopener noreferrer" href={`${HOST}/imbue_ailab/ai_lab_interface?docid=${row.doc}`}>
                                                        <i style={{ marginRight: "5px", fontSize: "1.2rem" }} className="fas fa-redo-alt"></i>
                                                    </a>
                                                </div>
                                            </div>

                                            <div>
                                                <div style={{ width: "190px" }}>
                                                    <MiniPnlChart ref={"miniChart-" + idx} docid={row.doc} initText="Hover to View P&L" ifHover={0} />
                                                </div>
                                            </div>

                                            <div>
                                                <div
                                                    style={{
                                                        padding: "2px 5px",
                                                        borderRadius: "5px",
                                                        // background: sharpe >= 0 ? COLORMAP.green : COLORMAP.red,
                                                        color: sharpe >= 0 ? COLORMAP.green : COLORMAP.red,
                                                        border: "2px solid " + (sharpe >= 0 ? COLORMAP.green : COLORMAP.red)
                                                    }}
                                                >
                                                    <small>SR:</small> {sharpe}
                                                </div>
                                                <div style={{ fontSize: "1.1rem", color: "#999", margin: "10px 0" }}>
                                                    <div className="flexBox flexBox_between">
                                                        <div>WR</div>
                                                        <div style={{ color: "#333" }}>{winrate}</div>{" "}
                                                    </div>
                                                    <div className="flexBox flexBox_between">
                                                        <div>PL</div>
                                                        <div style={{ color: "#333" }}>{totalpnl}</div>{" "}
                                                    </div>
                                                    <div className="flexBox flexBox_between">
                                                        <div>APL</div>
                                                        <div style={{ color: "#333" }}>{annualpnl}</div>
                                                    </div>
                                                    <div className="flexBox flexBox_between">
                                                        <div>TR</div>
                                                        <div style={{ color: "#333" }}>{notrades}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="result_table_viewer">
                            {selectedRow ? (
                                <MiniTradesViewer key={"minidocview" + selectedRow.doc} docid={selectedRow.doc} factors={selectedRow.factors} />
                            ) : (
                                <div style={{ padding: "20px" }}>
                                    <div className="flexBox loading-text" style={{ fontSize: "2rem" }}>
                                        <i className="fal fa-hand-point-left" style={{ marginRight: "8px", animation: "swing 1s infinite" }}></i>
                                        <span>Select one result!</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }
        };

        return (
            <div className="panel_wrapper resulttable_wrapper">
                {component__progressbar()}
                {component__gamelist()}
            </div>
        );
    }
}

export default class BatchGameDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
            wsMsg: []
        };
        this.startGame = this.startGame.bind(this);
        this.retriveGameInfo = this.retriveGameInfo.bind(this);
        this.updateGameState = this.updateGameState.bind(this);
    }

    componentDidMount() {
        this.startGame();
    }

    componentWillUnmount() {
        this.closeSocketGame();
    }

    retriveGameInfo() {
        api_ailab_optimiser_query_user(EMAIL)
            .then(res => {
                const jobs = {
                    pending: res.data.filter(ele => ele.status === "pending"),
                    processing: res.data.filter(ele => ele.status === "processing"),
                    completed: res.data.filter(ele => ele.status === "finished")
                };
                this.setState({ jobs });
            })
            .catch(err => console.log(err));
    }

    updateGameState(data) {
        const jobs = {
            pending: data.filter(ele => ele.status === "pending"),
            processing: data.filter(ele => ele.status === "processing"),
            completed: data
                .filter(
                    ele =>
                        ele.status in
                        {
                            finished: null,
                            error: null,
                            cancelled: null
                        }
                )
                .map(ele => ({ ...ele, sharpe: get_stat(ele["result"]["out_of_sample"], "Sharpe") }))
                .sort((a, b) => b.sharpe - a.sharpe)
        };
        this.setState({
            lastUpdated: moment(),
            jobs,
            progress: (jobs.completed.length / data.length) * 100
        });
    }

    // API: AIGame WebSocket
    startGame() {
        /* 
        ===============
        Real Production
        ===============
        */

        const addWsMsg = content => {
            const { wsMsg } = this.state;
            this.setState({ wsMsg: [...wsMsg, { ...content, ts: moment() }] });
        };

        if (!TEST) {
            let ws = ailab_optimizer_ws();

            // --+-- ON OPEN --+--
            ws.onopen = () => {
                const { gameinput } = this.props;
                const initMsg = {
                    body: gameinput
                    // token: getToken()
                };
                ws.send(JSON.stringify(initMsg));
                this.refs.workingpanel.getGameMeta();
            };

            // --+-- ON MESSAGE --+--
            ws.onmessage = event => {
                const data = JSON.parse(event.data);
                const label = data.label;
                const content = data.content;
                addWsMsg(data);
                switch (label) {
                    case "jobs":
                        // console.log(content)
                        return this.updateGameState(content);
                    case "status":
                        if (content === "finished") {
                            this.closeSocketGame();
                            this.props.stopRunning();
                            break;
                        } else {
                            break;
                        }
                    default:
                        break;
                }
            };

            // --+-- ON CLOSE --+--
            ws.onclose = event => {
                addWsMsg(event);
                console.log(event);
                console.log(this.state.wsMsg);
                this.refs["workingpanel"].updateFam();
                // window.alert(`Socket Has Been Closed!`)
            };

            // --+-- Preserve state for WebSocket connection --+--
            this.setState({ ws });
        } else {
            // TEST ONLY
            const data = test_jobs;
            this.updateGameState(data);
        }
    }

    closeSocketGame() {
        console.log("GAME IS FINISHED! SOCKET IS GOING TO BE CLOSED!");
        const { ws } = this.state;
        if (ws) {
            ws.close();
        }
    }

    terminateGame() {
        const { ws } = this.state;
        const msg = {
            body: { cancel: true, user: EMAIL },
            token: getToken()
        };
        ws.send(JSON.stringify(msg));
    }

    render() {
        const { progress, jobs, wsMsg } = this.state;
        const { gameinput } = this.props;
        return (
            <Layout progress={progress}>
                {/* Row 1 */}
                <WorkingPanel ref="workingpanel" wsMsg={wsMsg} progress={progress} gameinput={gameinput} gamedata={jobs} />

                {/* Row 2 */}
                {!jobs ? "" : <ResultTable jobs={jobs} id={"tb-completed"} />}
            </Layout>
        );
    }
}
