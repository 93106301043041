import React, { Component } from "react";
import styled from "styled-components";
import { EMAIL, layoutConfig } from "../../config";
// :: API
import { api_portfolio_meta, api_simulation_portfolio } from "../../helpers/API";
import { cumulativeToDaily } from "../../helpers/ChartFunctions";
import { generateMultiCumPnl } from "./helpers";
// :: SUB Components
import SimulationMain from "./SimulationMain";
import SimulationSideBar from "./SimulationSidebar";
import { LoadingPage, PlaceholderPage } from "../../helpers/SimpleComponents";

import "./style.css";

/**
 * Styled
 */
const Layout = styled.div`
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: calc(100vh - ${layoutConfig.navbarHeight + layoutConfig.textStripHeight}px);
`;

/**
 * Main Class
 */
export default class SimulationLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
            isInit: true,
            contentIndex: [],
            data: {},
        };
        this.onChangeIndex = this.onChangeIndex.bind(this);
        this.getSimulationData = this.getSimulationData.bind(this);
        this.render_SimulationMain = this.render_SimulationMain.bind(this);
        this.onChangePageIndexBySecurity = this.onChangePageIndexBySecurity.bind(this);
    }

    componentDidMount() {
        this.getSimulationData();
    }

    // API: getSimulationData --- Portfolio Page
    getSimulationData() {
        const strategy_id = this.props.strategyMeta.strategyId;
        const strategyMeta = this.props.strategyMeta;

        const { portfolioMeta } = this.state;

        const APIENDPOINT = api_simulation_portfolio(strategy_id, true);

        if (strategy_id) {
            APIENDPOINT.then((res) => {
                let portfolio = res.data.content.data;
                console.log(portfolio);

                // 1. Reprocessing

                // 1.1 + allTrades
                // portfolio["allTrades"] = portfolio.securities.map(ele => ele.trades).reduce((a, b) => [...a, ...b], []);
                portfolio["allTransactions"] = portfolio.securities.map((ele) => ele.trades).reduce((a, b) => [...a, ...b], []);

                // 1.2 + daily_pnl
                portfolio["daily_pnl"] = cumulativeToDaily(portfolio.pnl.slice());

                // 1.3 calc navDict
                let navDict = {};
                if (!portfolioMeta) {
                    for (let sec of portfolio.securities) {
                        navDict[sec.ticker] = 100;
                    }
                } else {
                    for (let sec of portfolio.securities) {
                        navDict[sec.ticker] = (portfolioMeta.find((ele) => ele.ticker === sec.ticker) || { navweigh: 100 }).navweigh || 0;
                    }
                }

                // 1.4 multiPnls
                const multiPnls = generateMultiCumPnl(portfolio, navDict);

                // 1.4 weightedTrades
                // const weightedTrades = generateAllTrades(portfolio.allTrades, navDict);
                const weightedTrades = [];

                const contentIndex = [
                    {
                        label: "Portfolio",
                        type: "simPage",
                        data: {
                            title: (
                                <div className="flexBox">
                                    <span className="tag_rounded" style={{ background: "var(--color-main)", marginRight: "10px" }}>
                                        Portfolio
                                    </span>
                                    {strategy_id}
                                </div>
                            ),
                            pageType: "simPage",
                            ...portfolio,
                            exposure: portfolio.gross_exposure,
                            trades: portfolio.allTrades,
                            transactions: portfolio.allTransactions,
                            multicumpnl: multiPnls,
                            weightedTrades: weightedTrades,
                        },
                        sidebar_config: {
                            isPrime: true,
                        },
                    },
                    ...(portfolio.securities || []).map((ele, idx) => {
                        return {
                            label: ele.ticker,
                            type: "secPage",
                            exclude: [],
                            data: {
                                title: (
                                    <div className="flexBox">
                                        <span className="tag_rounded" style={{ background: "var(--color-darkgold)", marginRight: "10px" }}>
                                            security
                                        </span>
                                        {ele.ticker}
                                    </div>
                                ),
                                pageType: "secPage",
                                secIndex: idx,
                                ...ele,
                            },
                            sidebar_config: {},
                        };
                    }),
                ];
                this.setState({
                    data: portfolio,
                    selectedIndex: 0,
                    contentIndex,
                    isInit: false,
                });
            }).catch((err) => {
                this.setState({
                    errorInfo: JSON.stringify(err),
                });
            });
        } else {
            this.setState({ data: this.props.data });
        }
    }

    // Call when user click any button in the sidebar
    onChangeIndex(sidebarIndex) {
        this.setState({
            selectedIndex: sidebarIndex,
        });
    }

    // Function passed to Summary Page
    // Call when user tap security text in the summary
    onChangePageIndexBySecurity(sec) {
        const { contentIndex } = this.state;
        const foundPage = contentIndex.find((item) => item.label === sec);
        if (foundPage) {
            const newSelectedIndex = contentIndex.map((ele) => ele.label).indexOf(sec);
            this.onChangeIndex(newSelectedIndex);
        }
    }

    // RENDER: Main Renderer
    render_SimulationMain() {
        const { data, contentIndex, selectedIndex } = this.state;

        const page__type = contentIndex[selectedIndex].type;
        const page__data = contentIndex[selectedIndex].data;
        const page__title = contentIndex[selectedIndex].data.title;

        let props = {};
        switch (page__type) {
            case "simPage":
                props = {
                    data: page__data,
                    type: page__type,
                    title: page__title,
                    description: data.description,
                    created: data.timestamp,
                };
                return <SimulationMain {...props} />;
            case "secPage":
                let pageData = page__data;
                pageData = {
                    ...pageData,
                    daily_pnl: cumulativeToDaily((pageData.pnl || []).slice()),
                };
                props = {
                    data: pageData,
                    type: contentIndex[selectedIndex].type,
                    title: contentIndex[selectedIndex].data.title,
                    description: data.description,
                    created: data.timestamp,
                };
                return <SimulationMain {...props} />;
            default:
                return <div></div>;
        }
    }

    render() {
        // ==== props ====
        const strategyMeta = this.props.strategyMeta;
        const strategyName = strategyMeta.strategyName;

        // === states ====
        const contentIndex = this.state.contentIndex;
        const selectedIndex = this.state.selectedIndex;
        const errorInfo = this.state.errorInfo;
        const hasNoContent = contentIndex.length === 0;

        if (hasNoContent) {
            if (errorInfo) {
                return PlaceholderPage("There are some errors!");
            } else {
                return LoadingPage("Loading...");
            }
        } else {
            return (
                <Layout>
                    <SimulationSideBar contentIndex={contentIndex} selectedIndex={selectedIndex} onChangeIndex={this.onChangeIndex} simId={strategyName} backBtn={this.props.backBtn} />
                    {this.render_SimulationMain()}
                </Layout>
            );
        }
    }
}
