import React, { Component } from "react";
import ScriptResultsHtml from "./components/ScriptResultsHtml.dev";
import styled from "styled-components";

const Layout = styled.div`
    padding: 20px;
`;

class DailyTrades extends Component {
    render() {
        return (
            <Layout>
                <ScriptResultsHtml />
            </Layout>
        );
    }
}

export default DailyTrades;
