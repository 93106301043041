export const symbol_url_map = {
    "6A": {
        "quotes": "https://www.cmegroup.com/markets/fx/g10/australian-dollar.quotes.html",
        "specs": "https://www.cmegroup.com/markets/fx/g10/australian-dollar.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/250/255/255.pdf"
    },
    "6B": {
        "quotes": "https://www.cmegroup.com/markets/fx/g10/british-pound.quotes.html",
        "specs": "https://www.cmegroup.com/markets/fx/g10/british-pound.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/250/251/251.pdf"
    },
    "6C": {
        "quotes": "https://www.cmegroup.com/markets/fx/g10/canadian-dollar.quotes.html",
        "specs": "https://www.cmegroup.com/markets/fx/g10/canadian-dollar.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/250/252/252.pdf"
    },
    "6E": {
        "quotes": "https://www.cmegroup.com/markets/fx/g10/euro-fx.quotes.html",
        "specs": "https://www.cmegroup.com/markets/fx/g10/euro-fx.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/250/261/261.pdf"
    },
    "6J": {
        "quotes": "https://www.cmegroup.com/markets/fx/g10/japanese-yen.quotes.html",
        "specs": "https://www.cmegroup.com/markets/fx/g10/japanese-yen.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/250/253/253.pdf"
    },
    "6L": {
        "quotes": "https://www.cmegroup.com/markets/fx/emerging-market/brazilian-real.quotes.html",
        "specs": "https://www.cmegroup.com/markets/fx/emerging-market/brazilian-real.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/250/257/257.pdf"
    },
    "6M": {
        "quotes": "https://www.cmegroup.com/markets/fx/emerging-market/mexican-peso.quotes.html",
        "specs": "https://www.cmegroup.com/markets/fx/emerging-market/mexican-peso.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/250/256/256.pdf"
    },
    "6N": {
        "quotes": "https://www.cmegroup.com/markets/fx/g10/new-zealand-dollar.quotes.html",
        "specs": "https://www.cmegroup.com/markets/fx/g10/new-zealand-dollar.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/250/258/258.pdf"
    },
    "6S": {
        "quotes": "https://www.cmegroup.com/markets/fx/g10/swiss-franc.quotes.html",
        "specs": "https://www.cmegroup.com/markets/fx/g10/swiss-franc.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/250/254/254.pdf"
    },
    "BRN": {
        "quotes": "https://www.theice.com/products/219/Brent-Crude-Futures/data",
        "specs": "https://www.theice.com/products/219/Brent-Crude-Futures",
        "rulebook" : "https://www.theice.com/publicdocs/contractregs/18_SECTION_L.pdf"
    },
    "BTC": {
        "quotes": "https://www.cmegroup.com/markets/cryptocurrencies/bitcoin/bitcoin.quotes.html",
        "specs": "https://www.cmegroup.com/markets/cryptocurrencies/bitcoin/bitcoin.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/IV/350/350.pdf"
    },
    "CC": {
        "quotes": "https://www.theice.com/products/7/Cocoa-Futures/data",
        "specs": "https://www.theice.com/products/7/Cocoa-Futures",
        "rulebook" : "https://www.theice.com/publicdocs/rulebooks/futures_us/9_Cocoa.pdf"
    },
    "CL": {
        "quotes": "https://www.cmegroup.com/markets/energy/crude-oil/light-sweet-crude.quotes.html",
        "specs": "https://www.cmegroup.com/markets/energy/crude-oil/light-sweet-crude.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/NYMEX/2/200.pdf"
    },
    "CNH": {
        "quotes": "https://www.cmegroup.com/markets/fx/cross-rates/usd-cnh.quotes.html",
        "specs": "https://www.cmegroup.com/markets/fx/cross-rates/usd-cnh.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/250/284L.pdf"
    },
    "CT": {
        "quotes": "https://www.theice.com/products/254/Cotton-No-2-Futures/data",
        "specs": "https://www.theice.com/products/254/Cotton-No-2-Futures",
        "rulebook" : "https://www.theice.com/publicdocs/rulebooks/futures_us/10_Cotton.pdf"
    },
    "DC": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/dairy/class-iii-milk.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/dairy/class-iii-milk.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/II/50/52/52.pdf"
    },
    "DX": {
        "quotes": "https://www.theice.com/products/194/US-Dollar-Index-Futures/data",
        "specs": "https://www.theice.com/products/194/US-Dollar-Index-Futures",
        "rulebook" : "https://www.theice.com/publicdocs/rulebooks/futures_us/15_USDX.pdf"
    },
    "ES": {
        "quotes": "https://www.cmegroup.com/markets/equities/sp/e-mini-sandp500.quotes.html",
        "specs": "https://www.cmegroup.com/markets/equities/sp/e-mini-sandp500.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/IV/350/358/358.pdf"
    },
    "ETH": {
        "quotes": "https://www.cmegroup.com/markets/cryptocurrencies/ether/ether.quotes.html",
        "specs": "https://www.cmegroup.com/markets/cryptocurrencies/ether/ether.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/300/349.pdf"
    },
    "GC": {
        "quotes": "https://www.cmegroup.com/markets/metals/precious/gold.quotes.html",
        "specs": "https://www.cmegroup.com/markets/metals/precious/gold.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/COMEX/1a/113.pdf"
    },
    "GE": {
        "quotes": "https://www.cmegroup.com/markets/interest-rates/stirs/eurodollar.quotes.html",
        "specs": "https://www.cmegroup.com/markets/interest-rates/stirs/eurodollar.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/V/450/452/452.pdf"
    },
    "GF": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/livestock/feeder-cattle.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/livestock/feeder-cattle.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/II/100/102/102.pdf"
    },
    "HE": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/livestock/lean-hogs.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/livestock/lean-hogs.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/II/150/152/152.pdf"
    },
    "HG": {
        "quotes": "https://www.cmegroup.com/markets/metals/base/copper.quotes.html",
        "specs": "https://www.cmegroup.com/markets/metals/base/copper.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/COMEX/1a/111.pdf"
    },
    "HO": {
        "quotes": "https://www.cmegroup.com/markets/energy/refined-products/heating-oil.quotes.html",
        "specs": "https://www.cmegroup.com/markets/energy/refined-products/heating-oil.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/NYMEX/1a/150.pdf"
    },
    "KC": {
        "quotes": "https://www.theice.com/products/15/Coffee-C-Futures/data",
        "specs": "https://www.theice.com/products/15/Coffee-C-Futures",
        "rulebook" : "https://www.theice.com/publicdocs/rulebooks/futures_us/8_Coffee.pdf"
    },
    "KE": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/grains/kc-wheat.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/grains/kc-wheat.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/II/14h.pdf"
    },
    "LBR": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/lumber-and-softs/lumber.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/lumber-and-softs/lumber.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/II/50/63.pdf"
    },
    "LE": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/livestock/live-cattle.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/livestock/live-cattle.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/II/100/101/101.pdf"
    },
    "MWE": {
        "quotes": "https://www.mgex.com/quotes.html?j1_module=futureDetail&j1_symbol=MW%2A1&j1_region=US&j1_selected=futureOverview",
        "specs": "https://www.mgex.com/contract_specs.html",
        "rulebook" : "https://www.mgex.com/documents/20220302-Rulebook.pdf"
    },
    "NG": {
        "quotes": "https://www.cmegroup.com/markets/energy/natural-gas/natural-gas.quotes.html",
        "specs": "https://www.cmegroup.com/markets/energy/natural-gas/natural-gas.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/NYMEX/2/220.pdf"
    },
    "NQ": {
        "quotes": "https://www.cmegroup.com/markets/equities/nasdaq/e-mini-nasdaq-100.quotes.html",
        "specs": "https://www.cmegroup.com/markets/equities/nasdaq/e-mini-nasdaq-100.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/IV/350/359/359.pdf"
    },
    "PA": {
        "quotes": "https://www.cmegroup.com/markets/metals/precious/palladium.quotes.html",
        "specs": "https://www.cmegroup.com/markets/metals/precious/palladium.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/NYMEX/1a/106.pdf"
    },
    "PL": {
        "quotes": "https://www.cmegroup.com/markets/metals/precious/platinum.quotes.html",
        "specs": "https://www.cmegroup.com/markets/metals/precious/platinum.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/NYMEX/1a/105.pdf"
    },
    "RB": {
        "quotes": "https://www.cmegroup.com/markets/energy/refined-products/rbob-gasoline.quotes.html",
        "specs": "https://www.cmegroup.com/markets/energy/refined-products/rbob-gasoline.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/NYMEX/1a/191.pdf"
    },
    "RTY": {
        "quotes": "https://www.cmegroup.com/markets/equities/russell/e-mini-russell-2000.quotes.html",
        "specs": "https://www.cmegroup.com/markets/equities/russell/e-mini-russell-2000.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CME/III/300/393.pdf"
    },
    "SB": {
        "quotes": "https://www.theice.com/products/23/Sugar-No-11-Futures/data",
        "specs": "https://www.theice.com/products/23/Sugar-No-11-Futures",
        "rulebook" : "https://www.theice.com/publicdocs/rulebooks/futures_us/11_Sugar_11.pdf"
    },
    "SI": {
        "quotes": "https://www.cmegroup.com/markets/metals/precious/silver.quotes.html",
        "specs": "https://www.cmegroup.com/markets/metals/precious/silver.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/COMEX/1a/112.pdf"
    },
    "UB": {
        "quotes": "https://www.cmegroup.com/markets/interest-rates/us-treasury/ultra-t-bond.quotes.html",
        "specs": "https://www.cmegroup.com/markets/interest-rates/us-treasury/ultra-t-bond.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/education/files/ultra-10-overview.pdf"
    },
    "YM": {
        "quotes": "https://www.cmegroup.com/markets/equities/dow-jones/e-mini-dow.quotes.html",
        "specs": "https://www.cmegroup.com/markets/equities/dow-jones/e-mini-dow.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/III/27.pdf"
    },
    "ZB": {
        "quotes": "https://www.cmegroup.com/markets/interest-rates/us-treasury/30-year-us-treasury-bond.quotes.html",
        "specs": "https://www.cmegroup.com/markets/interest-rates/us-treasury/30-year-us-treasury-bond.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/II/18.pdf"
    },
    "ZC": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/grains/corn.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/grains/corn.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/I/10.pdf"
    },
    "ZF": {
        "quotes": "https://www.cmegroup.com/markets/interest-rates/us-treasury/5-year-us-treasury-note.quotes.html",
        "specs": "https://www.cmegroup.com/markets/interest-rates/us-treasury/5-year-us-treasury-note.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/II/20.pdf"
    },
    "ZL": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/oilseeds/soybean-oil.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/oilseeds/soybean-oil.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/II/12/12.pdf"
    },
    "ZM": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/oilseeds/soybean-meal.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/oilseeds/soybean-meal.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/II/13/13.pdf"
    },
    "ZN": {
        "quotes": "https://www.cmegroup.com/markets/interest-rates/us-treasury/10-year-us-treasury-note.quotes.html",
        "specs": "https://www.cmegroup.com/markets/interest-rates/us-treasury/10-year-us-treasury-note.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/III/26.pdf"
    },
    "ZO": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/grains/oats.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/grains/oats.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/II/15/15.pdf"
    },
    "ZQ": {
        "quotes": "https://www.cmegroup.com/markets/interest-rates/stirs/30-day-federal-fund.quotes.html",
        "specs": "https://www.cmegroup.com/markets/interest-rates/stirs/30-day-federal-fund.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/III/22.pdf"
    },
    "ZR": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/grains/rough-rice.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/grains/rough-rice.contractSpecs.html",
        "rulebook": "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/II/17/17.pdf"
    },
    "ZS": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/oilseeds/soybean.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/oilseeds/soybean.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/II/11/11.pdf"
    },
    "ZT": {
        "quotes": "https://www.cmegroup.com/markets/interest-rates/us-treasury/2-year-us-treasury-note.quotes.html",
        "specs": "https://www.cmegroup.com/markets/interest-rates/us-treasury/2-year-us-treasury-note.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/II/21.pdf"
    },
    "ZW": {
        "quotes": "https://www.cmegroup.com/markets/agriculture/grains/wheat.quotes.html",
        "specs": "https://www.cmegroup.com/markets/agriculture/grains/wheat.contractSpecs.html",
        "rulebook" : "https://www.cmegroup.com/content/dam/cmegroup/rulebook/CBOT/II/14/14.pdf"
    }
}