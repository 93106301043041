import React from 'react';


const percentCols = ['iv', 'delta', 'gamma_pct'];
const truncate2Cols = ['mark_px'];


const TopStrikesTableBody = ({ tableData, columns }) => {
    let prevRow = null;
    return (
        <tbody>
            {tableData.map((data) => {

                let rowBorder = {};

                if (prevRow) {
                    if (data['under'] !== prevRow['under']) {
                        rowBorder = "1px solid black";
                    } else if (data['month_yr'] !== prevRow['month_yr'])
                        rowBorder = "1px solid gray";
                }
                prevRow = data;
                return (
                    <tr key={data.id} >
                        {columns.map(({ accessor }) => {
                            let curVal = data[accessor];
                            
                            let cellColor = "";
                            let cellTextDec = "none";
                            let title = "";

                            const curRank = data[accessor + '_max']
                            if (curRank !== undefined) {
                                if (curRank === 2) {
                                    cellColor = '#FFA726'; // orange
                                } else if (curRank == 1) {
                                    cellColor = '#FDD835'; // yellow
                                }
                            }

                            if (accessor === 'strike') {
                                curVal += ` ${data['flag']}`;

                                if ((data['under_px'] - data['strike']) * (data['flag'] == 'P' ? -1 : 1) > 0) {
                                    cellTextDec = 'underline';
                                }

                                if (data['under_px'] < data['strike']) {
                                    cellColor = "#90CAF9"; // blue
                                } else {
                                    cellColor = "#EF9A9A"; // red
                                }
                            }
                            else if (curVal !== null && truncate2Cols.includes(accessor)) {
                                curVal = curVal.toFixed(2);
                            }
                            else if (curVal !== null && percentCols.includes(accessor)) {
                                curVal = (curVal * 100).toFixed(1) + '%';
                            }
                            else if (accessor === 'dte') {
                                if (data['expiry']) {
                                    title = data['expiry'];
                                }
                            }

                            const cellStyle = {background: cellColor, textDecoration: cellTextDec, 
                                // borderStyle: 'none groove groove none ', borderWidth: "2px"
                                borderTop: rowBorder,
                            }
                            
                            const tData = data[accessor] ? curVal : "—";
                            return (
                                <td key={accessor} style={cellStyle} title={title}>
                                    {tData}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

export default TopStrikesTableBody;