import React, { useState, useEffect } from "react";
import {api_calendar_spreads, api_spreads_seasonality, api_spreads_series} from "../../helpers/API";
import { LoadingPage } from "../../helpers/SimpleComponents";
import ImbueSelect from "../../components/input/ImbueSelect";
import HighStockWrapper from "../../components/chart/HighStockWrapper";
import "./style.css";
// import { useParams } from "react-router-dom";


const allRoots = [
    'C', 'S', 'KE', 'W', 'BO', 'SM', 'NG', 'CL', 'XB', 'HO', 
    'LH',
    // Spreads
    'W-C', 'KE-W', 'KE-C',
    'W-KE','C-W', 'C-KE'
];

// inter-comdty spreads do not currently support candlesticks data
const noLoadRoots = ['W-KE', 'C-W', 'C-KE',];

const globexMap = {
    'C_' : 'ZC',
    'W_' : 'ZW',
    'S_' : 'ZS',
    'BO' : 'ZL',
    'SM' : 'ZM',
    'NG' : 'NG',
    'CL' : 'CL',
    'XB' : 'RB',
    'HO' : 'HO',
    'KE' : 'KE',
    'LH' : 'HE',
}

const rootOptions = allRoots.map((ele) => ({label: ele, value: ele}));

let initialSpreadsMap = {}
for (const root of allRoots) initialSpreadsMap[root] = [];

// setup year options for seasonality charts
const firstYear = 2010;
const curYear = new Date().getFullYear();
let years = [];
for (let i = firstYear; i <= curYear + 1; i++) years.push(i); 
const yearOptions = years.map((ele) => ({label: String(ele), value: ele}));
const defaultATR = 10;

let spreadTitle;


// convert "S_Q2S_X2 Comdty" -> "SQ2_SX2"
function formatSpread(spreadSymbol) {
    spreadSymbol = spreadSymbol.split(' ')[0].replaceAll('_', '')
    let firstNumIndex = spreadSymbol.search(/\d/);
    return spreadSymbol.slice(0, firstNumIndex+1) + '_' + spreadSymbol.slice(firstNumIndex+1);
}

// return the root value from a spread's string. TODO: use when getParams is reimplemented
// function parseRoot(spread) {
//     let root_i = spread.search(/\d/) - 1;
//     let subStr = spread.substring(0, root_i).replaceAll('_', '');
//     return subStr;
// }

// get each leg's contract strings from the spreadSymbol string
function getLegContracts(spreadSymbol) {
    if (spreadSymbol && spreadSymbol.length >= 8) {
        let leg1 = spreadSymbol.substring(0,4);
        let leg2 = spreadSymbol.substring(4,8);
        let root1 = globexMap[leg1.substring(0,2)];
        let root2 = globexMap[leg2.substring(0,2)];
        let month1 = leg1.charAt(2);
        let month2 = leg2.charAt(2);
        let year1 = leg1.charAt(3);
        let year2 = leg2.charAt(3);
        return [`${root1}_202${year1}${month1}`, `${root2}_202${year2}${month2}`];
    } else {
        return [undefined, undefined]; 
    }
}

//TODO: this is will break eventually... find smarter approach
function getEndYear(curSpread) {
    // console.log(curSpread);
    const yearRes = 2020 + Number(curSpread[3]);
    return {label: String(yearRes), value: yearRes};
}

// Child Component for spreads' Fiddle Chart and Seasonality Lines Chart
function SeasonalityCharts(props) {
    const [startYear, setStartYear] = useState(yearOptions[0]);
    const [endYear, setEndYear] = useState(getEndYear(props.spread.value));
    const [figImg, setFigImg] = useState();
    const [seriesData, setSeriesData] = useState();
    const [stocksData, setStocksData] = useState();
    const [showSequential, setShowSequential] = useState(false);
    const [imgLoading, setImgLoading] = useState(false);

    const yearsError = endYear.value <= startYear.value; // make sure endYear after startYear

    const legContracts = getLegContracts(props.spread.value);
    const leg1contract = legContracts[0];
    const leg2contract = legContracts[1];
    const leg1comdty = leg1contract.split('_')[0];
    const leg2comdty = leg2contract.split('_')[0];
    const leg1month = leg1contract.slice(-1);
    const leg2month = leg2contract.slice(-1);


    // fetch data on new spreads (triggered when user clicks play button, not on new spread selection)
    useEffect(() => {
        getSpreadsSeasonality();
    }, [props.spread]);


    function getSpreadsSeasonality() {
        if (!yearsError) {
            setImgLoading(true);
            setSeriesData();
            const params = {
                root: props.root.value, 
                comdty0: leg1comdty,
                comdty1: leg2comdty,
                month0: leg1month, 
                month1: leg2month, 
                start_year: startYear.value, 
                end_year: endYear.value
            }
            api_spreads_seasonality(params).then(res => {
                console.log(res);
                setFigImg(res.data.fig);
                setSeriesData(res.data.map);
                setStocksData(res.data.stocks)
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setImgLoading(false);
            })
        }
    }


    // ----  SETUP SEQUENTIAL HISTORIC SPREADS CHART  ----
    // take in spreads data {year: [['date' : px_close] ...forEach day] ...forEach year}
    // output array of highcharts-formatted series arrays for each year [{} ...forEach year-series]
    function setupSeries(data, stocksData, isSequential) {
        const colorGradients = [
            // "#2e3e50", // black
            "#8f44ad", // purple
            "#2b80b9", // blue
            "#11a186", // green
            "#f1c40f", // yellow
            "#e67d23", // dark yellow
            "#e84d3d", // orange
            "#c03a2b", // red
        ];
        let seriesRes = [];
        let colorIndex = 0;
        let i = 0;
        let N = Object.entries(data).length;
        let maxSeriesLength = 0;
        
        for (let [year_key, data_val] of Object.entries(data)) {
            if (data_val.length >= maxSeriesLength) {
                maxSeriesLength = data_val.length;
            }
            const isLast = i === N-1; // the most-recent year
            const curColor = colorGradients[colorIndex++];
            if (colorIndex >= colorGradients.length) colorIndex = 0; // wrap back around color array

            if (!isSequential) {
                // match all series based on current series timerange
                let yearAdjustment = endYear.value - Number(year_key);

                for (let i = 0; i < data_val.length; i++) {
                    const row = data_val[i];
                    const yearVal = row[0];
                    const curDateAr = yearVal.split(/-(.*)/s); // split 'yyyy' from 'mm-dd'
                    const adjYr = Number(curDateAr[0]) + yearAdjustment;
                    const adjDate = String(adjYr) + '-' + curDateAr[1];
                    data_val[i].push(adjDate);
                }

                seriesRes.push({
                    name: year_key,
                    type: isLast ? "area" : "line", // shade in area for most recent year
                    color: isLast ? "black" : curColor, // most recent color black
                    visible: i >= N - 5 ? true : false, // only show last 5 years
                    fillOpacity: 0.1,
                    lineWidth: isLast ? 3 : 1.5, // most-recent year thicker than rest
                    dashStyle: isLast ? "Solid" : "ShortDash",
                    data: data_val.map((ele) => ({
                        x: Date.parse(ele[2]), // adjusted date
                        y: ele[1], 
                        date: ele[0] // original date 
                    })),
                });
                i++;
            } else {
                seriesRes.push({
                    name: year_key,
                    type: isLast ? "area" : "line", // shade in area for most recent year
                    color: isLast ? "black" : curColor, // most recent color black
                    // visible: i >= N - 5 ? true : false, // only show last 5 years
                    visible: true,
                    fillOpacity: 0.1,
                    lineWidth: isLast ? 3 : 1.5, // most-recent year thicker than rest
                    dashStyle: isLast ? "Solid" : "ShortDash",
                    yAxis: 0,
                    data: data_val.map((ele) => ({
                        x: Date.parse(ele[0]), // adjusted date
                        y: ele[1], 
                        // date: ele[0] // original date 
                    })),
                });
                i++;
            }
        }
        if (isSequential && stocksData) {
            console.log(stocksData)
            if (stocksData['us']) {
                seriesRes.push({
                    name: 'US Stocks',
                    // type: 'scatter',
                    step: true,
                    color: '#a3c2c2',
                    visible: true,
                    dashStyle: 'Dot',
                    yAxis: 1,
                    data: stocksData['us'].map((ele) => [Date.parse(ele['date']), ele['px_last']]),
                    tooltip: {
                        valueSuffix: ' MBU' // Million Bushels 
                    }
                })
            }
            if (stocksData['world']) {
                seriesRes.push({
                    name: 'World Stocks',
                    // type: 'scatter',
                    step: true,
                    color: '#476b6b',
                    visible: true,
                    dashStyle: 'Dot',
                    yAxis: 1,
                    data: stocksData['world'].map((ele) => [Date.parse(ele['date']), ele['px_last']]),
                    tooltip: {
                        valueSuffix: ' MMT' // Million Metric Tons
                    }
                })
            }
            
        }
        console.log(stocksData['us'])
        console.table(seriesRes)
        return seriesRes;
    }


    function configSeriesChart(seriesData, stocksData, isSequential) {
        console.log(seriesData)
        const firstDayFirstSeries = seriesData[Object.keys(seriesData)[0]][0][0];
        // const lastSeriesLastDay = seriesData[Object.keys(seriesData).slice(-1)].slice(-1)[0][0];
        const lastSeriesFirstDay = seriesData[Object.keys(seriesData).slice(-1)][0][0];
        const seriesObj = setupSeries(seriesData, stocksData, isSequential);
        const xMinDateStr = showSequential ? firstDayFirstSeries : lastSeriesFirstDay;
        // const xMaxDateStr = lastSeriesLastDay
        let res = {
            chart: {
                // height: (9 / 16 * 95) + '%' // 16:9 ratio 95%
                height: 800
            },
            title: {
                text: spreadTitle + " Historical Series"
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: true
            },
            tooltip: {
                valueDecimals: 2,
                useHTML: true,
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/> {point.date}',
                split: true
            },
            plotOptions: { 
                series: {
                    animation: false,
                    // connectNulls: true
                },
            },
            xAxis: {
                labels: {
                    format: "{value:%b '%y}"
                },
                tooltip: {
                    format: "{value:%b %d, '%y}"
                },
                ordinal: false,
                min: Date.parse("US/Chicago " + xMinDateStr),
                // max: Date.parse("US/Chicago " + xMaxDateStr)
            },
            yAxis: [
                { 
                    title: { text: "Spread Price" },
                    offset: 20,
                    opposite: false,
                    plotLines: [{
                        color: "black",
                        width: 2,
                        value: 0
                    }]
                    // height: '60%'
                },
                {
                    title: { text: "Ending Stocks"},
                    // opposite: true,
                }
            ],
            credits: {
                enabled: false
            },
            series: seriesObj,
        };
        // console.log(res.series)
        return res;
    }

    function YearSelection() {
        return (
            <div style={{marginLeft: "20px", marginTop: "-20px", marginRight: "10px"}}>
                <h3 style={{marginBottom: "-5px"}}>Seasonality Range: </h3>
                <div className="row">
                    <ImbueSelect 
                        title="start year"
                        options={yearOptions}
                        defaultValue={startYear}
                        onChange={(e) => {
                            setStartYear(e);
                        }}
                    />
                    <ImbueSelect 
                        title="end year"
                        options={yearOptions}
                        defaultValue={endYear}
                        onChange={(e) => {
                            setEndYear(e);
                        }}
                    />
                    {
                    (yearsError) && 
                        <p style={{color: "red"}}>
                        {`End Year (${endYear.label}) must be after Start Year (${startYear.label})`}
                        </p>
                    }
                </div>
            </div>
        );
    }


    return (
        <div>
            { imgLoading &&
                <div style={{marginLeft: "20px"}}>
                    <p>loading spreads history...</p>
                    <div>{LoadingPage()}</div>
                </div>
            }
            { !imgLoading &&
                <div style={{marginTop: "-20px"}} >
                    <div className="row">
                        <YearSelection/>
                        { !yearsError &&
                            <div className="CircleBtn CircleBtnLg" 
                                style={{marginTop: "20px"}}
                                onClick={() => {getSpreadsSeasonality()}}>
                                <i className="fas fa-play"></i>
                            </div> 
                        }
                        <button style={{backgroundColor: showSequential ? "#36c9ff" : "#d1d1d1", 
                        marginLeft: "21%", marginTop: "30px", width: "80px",borderRadius: "20px", height: "30px"}} 
                            onClick={() => setShowSequential(!showSequential)}>
                            Sequential
                        </button>
                    </div>
                    <div className="row" style={{marginTop: "10px"}} >
                        { figImg && // Fiddle Chart
                            <div style={{width: "40%",}}>
                                <div style={{border: "2px solid #447463", borderRadius: "10px"}}>
                                    <img
                                        width="100%"
                                        height="800px"
                                        src={"data:image/png;base64," + figImg}
                                        alt=""
                                    />
                                </div>
                            </div>
                        }
                        { seriesData && // Spread Seasonality Lines Chart
                            <div style={{width: "60%"}}>
                                <div style={{border: "2px solid #447463", borderRadius: "10px"}}>
                                    <HighStockWrapper config={configSeriesChart(seriesData, stocksData, showSequential)}/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );

}


export default function CalendarSpreads() {

    // const {spreadParam} = useParams(); // TODO: refix how spread params work. temporarily disabled
    const [state, setState] = useState({});
    // TODO: accept spread params...
    const [root, setRoot] = useState(rootOptions[0]);
    const [spreadsMap, setSpreadsMap] = useState(initialSpreadsMap);
    const [atr, setAtr] = useState(defaultATR);
    const [spreadsLoading, setSpreadsLoading] = useState(false);
    // check if spreads / options have already been fetched before (when component remounts)
    const [spreadOptions, setSpreadOptions] = useState(spreadsMap[root.value].length > 0 ? spreadsMap[root.value] : {});
    const [spread, setSpread] = useState(spreadsMap[root.value].length > 0 ? spreadsMap[root.value][0] : {label: "?", value: undefined});
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [childSpread, setChildSpread] = useState(); // state obj used to manage renders of series chart child component

    document.title = root.value + " Spreads";


    // load new spread options for each root, but don't reload if already fetched
    useEffect(() => {
        if (spreadsMap[root.value].length === 0) {
            setSpreadsLoading(true);
            api_spreads_series({root: root.value}).then((res) => {
                console.log(res.data);
                const curSpreads = res.data.results[root.value];
                spreadsMap[root.value] = curSpreads.map((ele) => (
                    {
                    label: formatSpread(ele.slice(0, -7).toUpperCase()), 
                    value: ele.slice(0, -7).toUpperCase()
                    }
                ));
                setSpreadsMap(spreadsMap);
                setSpreadOptions(spreadsMap[root.value]);
                setSpread(spreadsMap[root.value][0]);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setSpreadsLoading(false);
            });
        } else {
            console.log(`already have spreads for ${root.label}`);
            // spreads and options already stored, no need to fetch names
            setSpread(spreadsMap[root.value][0]);
            setSpreadOptions(spreadsMap[root.value]);
        }
    }, [root]);


    // handle resetting data when components unmounts
    useEffect(() => {
        return () => {
            setState({});
        };
    }, []);


    function getData() {
        if (!noLoadRoots.includes(root.value)) {
            setIsLoading(true);
            // console.log(spreadsMap)
            console.log(`loading data for ${spread.value} ...`)
            setChildSpread(spread); // trigger re-render for series child-component
            setState({});
            api_calendar_spreads({root: root.label, spread: spread.value, atrRange: atr}).then(res => {
                console.log(res.data.results);
                spreadTitle = spread.label;
                // check if spread's volume is insufficient (<1000)
                if (res.data.status === 200) {
                    setState(res.data.results);
                    setHasError(false);
                } else {
                    setState(`${root.label} | ${spread.value} : insufficient trading volume`);
                    setHasError(true);
                }
            }).catch((e) => {
                console.log(e);
                setHasError(true);
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            setChildSpread(spread);
            spreadTitle = spread.label;
            setState({});
        }

    }


    // candle-stick price chart for single spread, contains ATR and Volume below
    function configCandlestickChart() {
        let res = {
            chart: {
                height: (9 / 16 * 70) + '%' // 16:9 ratio 70%
            },
            title: {
                text: spreadTitle + " Spread"
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                enabled: true
            },
            scrollbar: {
                enabled: true
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: { 
                series: {
                    animation: false,
                    // connectNulls: true
                },
            },
            xAxis: {
                type: "datetime"
            },
            yAxis: [
                { 
                    title: { text: "Price" },
                    offset: 20,
                    // opposite: false,
                    height: '60%',
                    plotLines: [{
                        color: "black",
                        width: 2,
                        value: 0
                    }]
                },
                {
                    title: { text: "ATR"},
                    offset: 20,
                    top: '60%',
                    height: '20%'
                },
                {
                    title: { text: "Volume"},
                    offset: 20,
                    top: '80%',
                    height: '20%'
                }
            ],
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "price",
                    type: "candlestick",
                    yAxis: 0,
                    data: state.map((ele) => ({
                        x: Date.parse("US/Chicago "+ele['DATE'].split(' ')[0]),
                        open: ele['PX_OPEN'] !== null ? ele['PX_OPEN'] : ele['PX_LAST'],
                        high: ele['high'],
                        low: ele['low'],
                        close: ele['PX_LAST'],
                        color: ele['PX_LAST'] < ele['PX_OPEN'] ? "#ce5042" : "#39ace7" // red (-) : blue (+)
                    })),
                },
                {
                    name: `ATR (${atr})`,
                    yAxis: 1,
                    lineWidth: 3,
                    data: state.map((ele) => ([Date.parse("US/Chicago "+ele['DATE'].split(' ')[0]), ele['ATR']]))
                },
                {
                    name: "True Range",
                    yAxis: 1,
                    color: "rgba(248,171,106,200)",
                    data: state.map((ele) => ([Date.parse("US/Chicago "+ele['DATE'].split(' ')[0]), ele['TR']]))
                },
                {
                    name: "Volume",
                    type: "column",
                    color: "rgba(144,237,125,255)",
                    yAxis: 2,
                    data: state.map((ele) => ([Date.parse("US/Chicago "+ele['DATE'].split(' ')[0]), ele['PX_VOLUME']]))

                },
                
            ],
        };
        // console.log(res.series)
        return res;
    }


    const AtrSelector = (props) => {

        const [atrVal, setAtrVal] = useState(props.atr);

        const handleAtrChange = (e) => {
            const inputVal = e.target.value;
            // only allow user to type numbers [1,50]
            if (/^\d*$/.test(inputVal)) {
                if (inputVal === '' || (parseInt(inputVal, 10) >= 1 && parseInt(inputVal, 10) <= 50)) {
                  setAtrVal(inputVal);
                }
            }
        }

        const handleKeyDown = (e) => {
            if (e.key == "Enter") {
                if (atrVal === '') {
                    setAtr(props.atr);
                } else {
                    setAtr(parseInt(atrVal, 10));
                }
            }
        }

        return (
            <div>
                <p>ATR:</p>
                <div style={
                    {
                    border: "2px solid #447463", borderRadius: "5px", 
                    width: "30px", height: "20px",
                    marginTop: "-15px", marginRight: "10px"
                    }
                }>
                    <input type="text" value={atrVal} onChange={handleAtrChange} onKeyDown={handleKeyDown} maxLength={2}/>
                </div>
                <p style={{fontSize: "10px", marginTop: "-2px", marginLeft: "-10px"}}>
                    {"(press ENTER)"}
                </p>
            </div>
            
        )
    }


    if (isLoading) {
        return (<div>{LoadingPage()}</div>);
    } else {
        return (
            <div>
                <div className="row">

                    <div className="ImbueSelectsWrapper" style={{marginLeft: "20px"}}>
                        <ImbueSelect
                            title="root" 
                            options={rootOptions}
                            value={root}
                            onChange={(e) => {
                                setRoot(e);
                            }}
                        />
                        { spreadsLoading &&
                            <div>{LoadingPage()}</div>
                        }
                        { !spreadsLoading &&
                            <div className="row">
                                <ImbueSelect 
                                    title={`${root.label}: Spreads`}
                                    options={spreadOptions}
                                    value={spread}
                                    onChange={(e) => {
                                        setSpread(e);
                                        // setEndYear(getEndYear(e.value));
                                    }}
                                />

                                <AtrSelector atr={atr}/>

                                <div className="CircleBtn CircleBtnLg" 
                                    style={{marginTop:"8px"}}
                                    onClick={() => {getData()}}>
                                    <i className="fas fa-play"></i>
                                </div>  
                            </div>
                        } 
                    </div>  
                </div>
                { !hasError &&
                    <div>
                        { !noLoadRoots.includes(root.value) && state && state.length > 0 &&
                            <div>
                                <HighStockWrapper config={configCandlestickChart()} />
                                <SeasonalityCharts root={root} spread={childSpread} />
                            </div>

                        }
                        { noLoadRoots.includes(root.value) && childSpread &&
                            <div>
                                <p style={{marginLeft: "30px", marginBottom: "20px"}}>
                                    No open,high,low,close data for inter-comdty spreads
                                </p>
                                <SeasonalityCharts root={root} spread={childSpread} />
                            </div>
                        }
                    </div>
                }
                { hasError &&
                    <div>
                        <h2>Error retrieving data</h2>
                    </div>
                }
            </div>
        );
    }
}
