import "./css/fonts.css";
import "./css/fav5.css";
import "./css/App.css";
import "./css/Widgets.css";

import { AuthGet } from "./helpers/AuthFetch";
import { getToken } from "./auth/UserAuth";
import { URL, EMAIL } from "./config";
import Interface from "./layout/Interface";
import Login from "./screens/Login";
import React, { Component ,useReducer,useContext} from "react";
import jwt from "jsonwebtoken";
import moment from "moment";
import { Switch, Route, Redirect, BrowserRouter as Router} from "react-router-dom";


import {useStateValue, StateContext, MainContext } from './state';


class App extends Component {
	
	
    componentDidMount() {
        this.authToken();
		
        this.authToken = this.authToken.bind(this);
        this.handleAuthToken = this.handleAuthToken.bind(this);
    }
	
	
    constructor(props) {
        super(props);
        
        this.state = {
			isAuth: true,
			level1: 'Mid Term',
			level2: 'SupplyDemand',
			commodity: "C ",
			setLevels: this.setLevels,
			setCommodity: this.setCommodity
        };
		
        this.authToken = this.authToken.bind(this);
        this.handleAuthToken = this.handleAuthToken.bind(this);
    }
	
	
	
	
  //This is the method to set the context data.
    setLevels = (level1,level2) => {
		this.setState({ level1 : level1, level2 : level2 });
      
    };
	
    setCommodity = (commodity) => {
		this.setState({ commodity : commodity });
      
    };
	
    
	
	
    componentDidUpdate() {
        this.checkTokenExp();
    }

    checkTokenExp() {
        // Validate token just by checking it expiration date
        const localToken = getToken();
        if (localToken) {
            try {
                const decoded = jwt.decode(localToken);
                const expired = decoded.exp * 1000;
                const email = decoded.sub;
                const isValid = moment(expired) - moment() > 0;
                const isUser = EMAIL === email;
                if (isValid && isUser) {
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        } else {
            return false;
        }
    }

    authToken() {
        const isTokenValid = this.checkTokenExp();
        if (isTokenValid) {
            // Get the token from localstorage and check if token is expired
            this.handleAuthToken(true);
        } else {
            // Authenticate Totken Through the API
            const authAPI = URL + "/?";
            AuthGet(authAPI)
                .then((rsp) => rsp.json())
                .then((res) => {
                    if (res.status_code === "500") {
                        // [ERROR] The token has been expired
                        // console.log("[AUTH ERROR] 1")
                        this.handleAuthToken(false);
                    } else {
                        // [SUCCESS] User Has Been Authenticated
                        // console.log("[AUTH SUCESS]")
                        this.handleAuthToken(true);
                    }
                })
                .catch((error) => {
                    // [ERROR] Fetch Failed
                    // console.log("[AUTH ERROR] 2")
                    this.handleAuthToken(false);
                });
        }
    }

    handleAuthToken(isAuthed) {
        const { authed } = this.state;
        if (isAuthed) {
            if (authed !== true) {
                this.setState({ authed: true });
            }
        } else {
            if (authed !== false) {
                this.setState({ authed: false });
            }
        }
    }

    render() {
		
		console.log(this.state);
		
        const { authed } = this.state;
        // console.log("authed::", authed);
        return (
			<MainContext.Provider value={this.state}>
            <div className="app-routes">
                {/* <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route
                        path="/"
                        component={() => {
                            return authed === true ? <Interface /> : authed === false ? <Redirect to="/login" /> : "";
                        }}
                    />
                    <Route path="/commodityview/:root">
                        <TestRoute/>
                    </Route>
                </Switch> */}
                <Router>
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route
                            path="/"
                            component={() => {
                                return authed === true ? <Interface /> : authed === false ? <Redirect to="/login" /> : "";
                            }}
                        />
                        
                    </Switch>
                </Router>
            </div>
			</MainContext.Provider>
        );
    }
}

export default App;
