import React, { Component } from "react";
import DEMODATA from "./DEMODATA.json";
import styled from "styled-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsHeatmap from "highcharts/modules/heatmap";

import { api_corr_analysis } from "../../../helpers/API";
import ImbueSelect from "../../../components/input/ImbueSelect";
import { PlaceholderPage, LoadingPage } from "../../../helpers/SimpleComponents";
import { heatmapConfig } from "./helpers";
import CA_RollingCompare from "./components/CA_RollingCompare";
import CA_Treemap from "./components/CA_Treemap";
import moment from "moment";
import { getTickerName, getTicker } from "../../../index/TickerDictonary";
import {verifyDateFormat} from "../../../HelperFuncs.js";

highchartsHeatmap(Highcharts);

const Layout = styled.div`
    padding: 30px;

    .grid_top {
        display: flex;
        align-items: center;
    }

    .grid_main {
        box-sizing: border-box;
        overflow: auto;
    }
`;

class CorrelationAnalytics extends Component {
    state = {
        pageIndex: 0, // 0. heatmap 1. corrlation and treemap
        data: DEMODATA,
        selectedWindow: "30D",
        pivotSecurity_x: "",
        pivotSecurity_y: "",
        treemapdata: [],
    }

    componentDidMount() {}

    onChangeWindow = (window)  => {
        if (this.state.selectedWindow !== window) {
            this.setState({ selectedWindow: window });
        }
    }

    onSelectSecurity = (pivotSecurity_x, pivotSecurity_y, treemapdata) => {
        this.setState({
            pageIndex: 1,
            pivotSecurity_x,
            pivotSecurity_y,
            treemapdata,
        });
    }

    render() {
        const callback = (x, y, treemapdata) => {
            this.onSelectSecurity(x, y, treemapdata);
        };

        if (this.state.pageIndex === 0) {
            if (this.props.data) {
                const selectedWindow = this.state.selectedWindow;
                let chartList = [];

                if (selectedWindow === "ALL") {
                    chartList = ["1D", "30D", "90D", "180D", "365D"];
                } else {
                    chartList = [selectedWindow];
                }

                const chartOptionsList = chartList.map((ele) => ({
                    label: ele,
                    data: this.props.data.result.corr[ele].data,
                    x: this.props.data.result.corr[ele].columns,
                    y: this.props.data.result.corr[ele].index,
                }));

                const options_window = [
                    { label: "ALL", value: "ALL" },
                    { label: "1 Day", value: "1D" },
                    { label: "1 Month", value: "30D" },
                    { label: "3 Months", value: "90D" },
                    { label: "6 Months", value: "180D" },
                    { label: "1 Year", value: "365D" },
                ];
                return (
                    <div>
                        <div className="ImbueSelectWrapper">
                            <div className="ImbueSelectItem">
                                <ImbueSelect title="time window (smoothing)" defaultValue={options_window[2]} options={options_window} onChange={(e) => this.onChangeWindow(e.value)} />
                            </div>
                        </div>
                        {chartOptionsList.map((ele, idx) => {
                            ele.x = ele.x.map((t) => getTickerName(t));
                            ele.y = ele.y.map((t) => getTickerName(t));
                            const options = heatmapConfig(ele, callback);
                            return (
                                <div key={idx}>
                                    <h3>{ele.label}</h3>
                                    <HighchartsReact highcharts={Highcharts} options={options} />
                                </div>
                            );
                        })}
                    </div>
                );
            } else {
                return <div style={{ padding: "50px" }}>{PlaceholderPage("")}</div>;
            }
        } else {
            return (
                <div>
                    <h3>
                        {this.state.pivotSecurity_x} ({this.state.selectedWindow})
                    </h3>
                    <div className="flexBox">
                        <div className="SmallBtnAlt" onClick={() => this.setState({ pageIndex: 0 })}>
                            <i className="fal fa-angle-left"></i>Back
                        </div>
                    </div>
                    {/* #### TREEMAP #### */}
                    <h3>Correlation with {this.state.pivotSecurity_x}</h3>
                    <CA_Treemap
                        {...{
                            data: this.state.treemapdata,
                        }}
                    />
                    {/* #### Rolling Comparison #### */}
                    <h3>Select ticker to compare</h3>
                    <CA_RollingCompare
                        {...{
                            ticker1: getTicker(this.state.pivotSecurity_x, "1 A:00_0_R Comdty"),
                            ticker2: getTicker(this.state.pivotSecurity_y, "1 A:00_0_R Comdty"),
                            tickerlist: this.props.data.tickers,
                            datefrom: this.props.paras.datefrom,
                            window: this.state.selectedWindow,
                        }}
                    />
                </div>
            );
        }
    }
}

export default class CorrelationAnalyticsWrapper extends Component {
    state = {
        selectedSector: "All",
        datefrom: "2015-01-01",
        dateto: moment().format("YYYY-MM-DD"),
        corr_data: null,
        isLoading: false,
        isValidDate: true,
        warning: "",
        lastDate: "",
    }

    onChangeSector = (sector) => {
        if (this.state.selectedSector !== sector) {
            this.setState({ selectedSector: sector });
        }
    }

    onUpdate = () => {
        if (!this.state.isValidDate) {
            this.setState({ warning: "invalid date format" });
        } else {
            if (this.state.warning !== "") {
                this.setState({ warning: "" });
            }
            const paras = {
                sector: this.state.selectedSector,
                datefrom: this.state.datefrom,
                dateto: this.state.dateto,
            };
            this.setState({ isLoading: true }, () => {
                api_corr_analysis(paras).then((res) => {
                    this.setState({
                        corr_data: res.data,
                        lastDate: "Data: " + res.data.first_date + " - " + res.data.last_date,
                        isLoading: false,
                        
                    });
                });
            });
        }
    }

    render() {
        const options_sector = [
            { label: "All Commodities", value: "All" },
            { label: "Grains & Oilseeds", value: "AgricultureGO" },
            { label: "Livestock", value: "AgricultureLS" },
            { label: "Softs", value: "Softs" },
            { label: "Energy", value: "Energy" },
            { label: "Metal", value: "Metal" },
        ];
        return (
            <Layout>
                <div className="grid_top">
                    <div className="ImbueSelectsWrapper">
                        <ImbueSelect title="Sector" options={options_sector} defaultValue={options_sector[0]} onChange={(e) => this.onChangeSector(e.value)} />
                        <div className="ImbueSelectItem">
                            <div className="label">From:</div>
                            <input
                                className="ImbueInput"
                                type="text"
                                defaultValue={this.state.datefrom}
                                onChange={(e) => {
                                    // ensure proper date format and datefrom is not a future date
                                    if (verifyDateFormat(e.target.value) && moment(e.target.value).isBefore(moment().format("YYYY-MM-DD"))) {
                                        this.setState({ datefrom: e.target.value, isValidDate: true });
                                    } else {
                                        this.setState({ isValidDate: false})
                                    }
                                }}
                            />
                        </div>
                        <div className="ImbueSelectItem">
                            <div className="label">To:</div>
                            <input
                                className="ImbueInput"
                                type="text"
                                defaultValue={this.state.dateto}
                                onChange={(e) => {
                                    // ensure proper date format and dateto is not before datefrom
                                    if (verifyDateFormat(e.target.value) && moment(this.state.datefrom).isBefore(moment(e.target.value))) {
                                        this.setState({ dateto: e.target.value, isValidDate: true });
                                    } else {
                                        this.setState({ isValidDate: false})
                                    }
                                }}
                            />
                        </div>
                        <div className="CircleBtn CircleBtnLg" onClick={this.onUpdate}>
                            {this.state.isLoading ? <i style={{ animation: "rotate 1s infinite" }} className="fas fa-spinner"></i> : <i style={{ marginLeft: "3px" }} className="fas fa-play"></i>}
                        </div>
                        <p style={{marginLeft: "10px"}}>
                            {this.state.lastDate}
                        </p>
                        <h3 style={{marginLeft: "10px", color: "red"}}>
                            {this.state.warning}
                        </h3>
                    </div>
                </div>
                <div className="grid_main">
                    {this.state.isLoading ? (
                        LoadingPage()
                    ) : (
                        <CorrelationAnalytics
                            data={this.state.corr_data}
                            paras={{
                                datefrom: this.state.datefrom,
                                dateto: this.state.dateto,
                            }}
                        />
                    )}
                </div>
            </Layout>
        );
    }
}
