import React, { Component } from "react";
import styled from "styled-components";
import AtrSize from "./PositionRisk/AtrSize";
import EqualWeight from "./PositionRisk/EqualWeight";
import AtrBounds from "./PositionRisk/AtrBounds";
import PercToContract from "./PositionRisk/PercToContract";
import ImbueTabs from "../../components/tabs/ImbueTabs";
import HRP from "./PositionRisk/HRP";
import StopLossRisk from "./PositionRisk/StopLossRisk";
import PortfolioVolatility from "./PositionRisk/PortfolioVolatility";
import StrangleSizing from "./PositionRisk/StrangleSizing";

const Layout = styled.div`
    padding: 30px;
`;

export default class PositionRisk extends Component {
    state = {
        selectedIndex: 0,
        retained_options: JSON.parse(localStorage.getItem("position_risk__retained_options") || '{}')
    };

    register_options = (type, key, options) => {
        let retained_options = this.state.retained_options
        if (!retained_options[type]){
            retained_options[type] = {}
        }
        retained_options[type][key] = options
        this.setState({
            retained_options: retained_options
        })
        localStorage.setItem("position_risk__retained_options", JSON.stringify(retained_options))
    }

    get_retained_options = (type, key) => {
        let retained_options = this.state.retained_options
        if(retained_options[type]) {
            if(retained_options[type][key]){
                return retained_options[type][key]
            }
        }
        return null
    }

    render() {
        const func = {
            get_retained_options: this.get_retained_options,
            register_options: this.register_options
        }
        console.log(this.state.retained_options);
        // console.log(this.state.retained_options);
        const funcs = [
            ["HRP", <HRP func={func} />],
            ["ATR Size", <AtrSize />],
            ["ATR Bounds", <AtrBounds />],
            ["Equal Weight", <EqualWeight />],
            ["Stop Loss Risk", <StopLossRisk />],
            ["Percent to Contract", <PercToContract func={func}/>],
            ["Portfolio Volatility", <PortfolioVolatility func={func} />],
            ["Strangle Sizing", <StrangleSizing func={func} />]
        ];

        const funcsTabsConfig = {
            options: funcs.map((ele, idx) => ({ label: ele[0], value: idx })),
            selectedValue: this.state.selectedIndex,
            onClick: (value) => this.setState({ selectedIndex: value }),
        };

        return (
            <Layout>
                <ImbueTabs {...funcsTabsConfig} />
                <h3>{funcs[this.state.selectedIndex][0]}</h3>
                {funcs[this.state.selectedIndex][1]}
            </Layout>
        );
    }
}
