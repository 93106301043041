import React, { Component } from "react";
import styled from "styled-components";
import ImbueRcTable from "../../components/table/ImbueRcTable";
import { api_s3 } from "../../helpers/API";
import { GLOBALSTYLED } from "../../config";
import S3PdfViewer from "../../components/pdfViewer/S3PdfViewer"

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)`
    .glossary_row {
        font-family: var(--font-main);
        font-size: 1.2rem;
        color: #555;
        padding: 4px 0;
    }

    .glossary_row:hover {
        color: #111;
    }

    .glossary_row:not(:last-child) {
        border-bottom: 1px solid #ccc;
    }
`;

const glossary = [
    "Straddle Approx = ATM IV * (t/365) * (4/5)",
    "IV Skew = (Put IV - Call IV) / ATM IV",
    "Absolute IV Skew = |IV Skew|",
    "Skew Z-Score = (IV Skew - rolling_5_day_mean(IV Skew)) / rolling_5_day_std(IV Skew)",
    "Absolute Skew Z-Score = (Absolute IV Skew - rolling_5_day_mean(Absolute IV Skew)) / rolling_5_day_std(Absolute IV Skew)",
    "ATM IV Skew = (ATM IV - rolling_5_day_mean(ATM IV)) / rolling_5_day_std(ATM IV)",
    "Straddle Z-Score = (Straddle Approx - rolling_5_day_mean(Straddle Approx)) / rolling_5_day_std(Straddle Approx)",
    "Positioning Short Term = (Non-Commercial Net Position - rolling_5_week_mean(Non-Commercial Net Position)) / rolling_5_week_std(Non-Commercial Net Position)",
    "Positioning Long Term = (rolling_3_week_mean(Non-Commercial Net Position) - rolling_15_week_mean(Non-Commercial Net Position)) / rolling_15_week_std(Non-Commercial Net Position)",
    "Term Structure = (rolling_10_day_mean(Contract3/Contract1 - 1) - rolling_50_day_mean(Contract3/Contract1 - 1)) / rolling_50_day_std(Contract3/Contract1 - 1)",
];

export default class OptionStrangles extends Component {
    state = {
        csvcontent: {
            master_trade_specs: [],
        },
    };

    componentDidMount() {
        api_s3("imbuereportbucket", "master_trade_specs.csv").then((res) => {
            // parse the CSV file
            const datacontent = res.data
                .split("\n")
                .slice(1, -1)
                .map((ele) => ele.split(","))
                .map((ele) => ({
                    date: ele[0],
                    option: ele[1],
                    "call k": parseFloat(ele[2]),
                    "call p": parseFloat(ele[3]),
                    delta: parseFloat(ele[4]),
                    week: ele[5],
                }));
            this.setState({
                csvcontent: {
                    master_trade_specs: datacontent,
                },
            });
        });
    }

    render() {
        return (
            <Layout>
                <h1>Strangle Live Summary v3 </h1>
                <S3PdfViewer bucketname="imbuereportbucket" id="strangle_live_summary_contracts_v3.pdf" />
                <h1>Strangle Analysis v3</h1>
                <S3PdfViewer bucketname="imbuereportbucket" id="strangle_analysis_v3.pdf" />
                <h1>Strangle Live Summary v2 </h1>
                <S3PdfViewer bucketname="imbuereportbucket" id="strangle_live_summary_contracts_v2.pdf" />
                <h1>Strangle Analysis v2</h1>
                <S3PdfViewer bucketname="imbuereportbucket" id="strangle_analysis_v2.pdf" />
                <h1>Master Trade Specs</h1>
                {this.state.csvcontent.master_trade_specs.length > 0 ? (
                    <ImbueRcTable list={this.state.csvcontent.master_trade_specs} filterable={1} />
                ) : (
                    <div className="LoadingText">loading...</div>
                )}
                <h1>Strangle Live Summary</h1>
                <S3PdfViewer bucketname="imbuereportbucket" id="strangle_live_summary_contracts.pdf" />
                <h1>Strangle Feature Summary</h1>
                <S3PdfViewer bucketname="imbuereportbucket" id="strangle_feature_summary.pdf" />
                <h1>Strangle Analysis</h1>
                <S3PdfViewer bucketname="imbuereportbucket" id="strangle_analysis.pdf" />
                <h1>General Features</h1>
                <S3PdfViewer bucketname="imbuereportbucket" id="strangle_general_commodity_features.pdf" />
                <h1>Vol Skews</h1>
                <S3PdfViewer bucketname="imbuereportbucket" id="strangle_specific_vol_skews.pdf" />
                <h1>Glossary</h1>
                {glossary.map((ele, i) => (
                    <div key={i} className="glossary_row">
                        {ele}
                    </div>
                ))}
            </Layout>
        );
    }
}
