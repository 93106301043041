import React, { Component } from "react";
import ReportContainer from "./components/ReportContainer";

import { api_couchdb } from "../../helpers/API";
import styled from "styled-components";

const Layout = styled.div`
    font-family: var(--font-main);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
`;

export default class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: null
        };
    }

    componentDidMount() {
        const index_name = this.props.index_name;
        api_couchdb("imbuecloud_index_tables", index_name).then(res => {
            const index = res.data.content.index;
            const meta = res.data.content.meta;
            this.setState({
                index,
                meta
            });
        });
    }

    render() {
        const index = this.state.index;
        const meta = this.state.meta;
        return (
            <Layout>
                <ReportContainer index={index} meta={meta} />
            </Layout>
        );
    }
}
