import React, { Component } from "react";

import { api_blp, api_robintrack_realtime } from "../../helpers/API";
import styled from "styled-components";
import PopularityChart from "./RobinTrack/PopularityChart";
import ImbueTable from "../../components/table/ImbueTable";
import ImbueSelect from "../../components/input/ImbueSelect";

const Layout = styled.div`
    padding: 30px;
    height: 100%;
    box-sizing: border-box;

    .grid_layout {
        display: grid;
        grid-template-columns: 600px calc(100% - 650px);
        grid-template-rows: 100%;
        grid-gap: 50px;
    }
`;

const SYMBOLLIST = [
    ["SLV", "iShares Silver Trust"],
    ["GLD", "SPDR Gold Trust"],
    ["JNUG", "Direxion Daily Junior Gold Miners Index Bull 2X Shares"],
];

export default class RobinTrack extends Component {
    state = {
        page: 0, // 0.rank 1.chart
        selectedSymbol: "",
        isintraday: 0,
        data: {
            LAST_TRADE_PRICE: [],
            POPULARITY: [],
        },
        rankParameters: {
            group: "all",
            relative: 0,
            limit: 50,
            lookback_days: 1,
        },
        rank: [],
        isLoading: 0,
    };

    componentDidMount() {
        this.getRank();
    }

    getData = (symbol) => {
        if (this.state.selectedSymbol !== symbol) {
            this.setState({ selectedSymbol: symbol, isLoading: 1 }, () => {
                api_blp(symbol, "popularity,last_trade_price", "2018-01-01", { schema: "robintrack" }).then((res) => {
                    const data = res.data;
                    this.setState({
                        data: data.content.data,
                        isLoading: 0,
                    });
                });
            });
        }
    };

    getRank = () => {
        const query = this.state.rankParameters.group === "all" ? "rank" : "rank_comdty";
        api_robintrack_realtime({
            query: query,
            relative: this.state.rankParameters.relative,
            limit: this.state.rankParameters.limit,
            hoursago: this.state.rankParameters.lookback_days * 24,
        }).then((res) => {
            console.log(res.data);
            const rank = res.data.data.map((ele, idx) => ({
                ...ele,
                rank: `#${idx + 1}`,
                symbol: (
                    <span className="LinkBtn" onClick={() => this.onSelectSymbol(ele.symbol)}>
                        {ele.symbol}
                    </span>
                ),
                popularity_difference:
                    this.state.rankParameters.relative === "true" ? ele.popularity_difference.toFixed(2) + "%" : ele.popularity_difference,
            }));
            this.setState({
                rank,
            });
        });

        // ### debug ###
        // this.setState({
        //     rank: DEMORANK.map((ele) => ({
        //         ...ele,
        //         symbol: (
        //             <span className="LinkBtn" onClick={() => this.onSelectSymbol(ele.symbol)}>
        //                 {ele.symbol}
        //             </span>
        //         ),
        //     })),
        // });
    };

    updateRank = () => {
        this.setState(
            {
                rank: [],
                selectedSymbol: "",
            },
            () => {
                this.getRank();
            }
        );
    };

    onSelectSymbol = (symbol) => {
        this.setState({
            selectedSymbol: symbol,
        });
    };

    onChangeRankParameters = (key, newVal) => {
        if (newVal !== this.state.rankParameters[key]) {
            let rankParameters = this.state.rankParameters;
            rankParameters[key] = newVal;
            this.setState({ rankParameters });
        }
    };

    render() {
        const groupOptions = [
            { label: "All ETFs", value: "all" },
            { label: "Commodity ETFS", value: "comdty" },
        ];
        const relativeOptions = [
            { label: "False", value: "false" },
            { label: "True", value: "true" },
        ];
        const intradayOptions = [
            { label: "False", value: 0 },
            { label: "True", value: 1 },
        ];

        return (
            <Layout>
                <div className="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="group"
                        defaultValue={groupOptions[0]}
                        options={groupOptions}
                        onChange={(e) => this.onChangeRankParameters("group", e.value)}
                    />
                    <ImbueSelect
                        isDisabled={this.state.rankParameters.group !== "all"}
                        title="relative"
                        defaultValue={relativeOptions[0]}
                        options={relativeOptions}
                        onChange={(e) => this.onChangeRankParameters("relative", e.value)}
                    />
                    <ImbueSelect
                        title="Intraday"
                        defaultValue={intradayOptions[0]}
                        options={intradayOptions}
                        onChange={(e) => this.setState({ isintraday: e.value })}
                    />
                    <div className="ImbueSelectItem">
                        <div className="label">Lookback Days:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={this.state.rankParameters.lookback_days}
                            onChange={(e) => this.onChangeRankParameters("lookback_days", e.target.value)}
                        />
                    </div>
                    <div className="ImbueSelectItem">
                        <div className="label">Limit:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={this.state.rankParameters.limit}
                            onChange={(e) => this.onChangeRankParameters("limit", e.target.value)}
                        />
                    </div>
                    <div className="CircleBtn CircleBtnLg" onClick={() => this.updateRank()}>
                        <i className="fas fa-sync"></i>
                    </div>
                </div>
                <div className="grid_layout">
                    <div style={{ height: "100%" }}>
                        {this.state.rank.length < 1 ? (
                            <div className="LoadingText">initializing... (first-time query may take a while)</div>
                        ) : (
                            <ImbueTable
                                config={{
                                    data: this.state.rank,
                                    height: "75vh",
                                    sorted: ["rank", "popularity_difference", "popularity_difference_pct", "start_popularity", "end_popularity"],
                                    density: "tight",
                                    keynamemap: {
                                        start_popularity: "start",
                                        end_popularity: "end",
                                        popularity_difference: "change",
                                        popularity_difference_pct: "pct_change",
                                    },
                                }}
                            />
                        )}
                    </div>
                    <div>
                        {this.state.selectedSymbol ? (
                            <PopularityChart key={this.state.selectedSymbol} symbol={this.state.selectedSymbol} isintraday={this.state.isintraday} />
                        ) : (
                            <div className="LoadingText">Select a symbol</div>
                        )}
                    </div>
                </div>
            </Layout>
        );
    }
}
