import React, { Component } from "react";

import Instruction from "./ImbueAILab_Instruction";
import SideBar from "./ImbueAILab_SideBar";

import GameDisplay from "./ImbueAILab_SingleGameDisplay";
import BatchGameDisplay from "./ImbueAILab_Optimizer";
import DLDisplay from "./ImbueAILab_DlDisplay";

import { PageStyle, MainPanelLayout } from "../ImbueAILabStyle";

// ============================================================================
// --+-- MainPanel: Wrapper for AI Lab instruction and Game Interface --+--
// ============================================================================

class MainPanel extends Component {
    terminateGame() {
        if (this.refs.gamedisplay) {
            this.refs.gamedisplay.terminateGame();
        }
    }

    renderGameDisplay(isInit, gamemode) {
        // 0 - single
        // 1 - optimiser (batch)

        const gamestatus = {
            isRunning: this.props.isRunning,
            isInit: this.props.isInit
        };

        const { stopRunning, gameinput, onSubmit } = this.props;

        if (isInit) {
            // If the game is initialized
            // PROPS:
            // gamemode -> The general gamemode
            // gameinput -> User's selected parameters for games, Dictionary Like
            // onSubmit -> Game Controller
            // stopRunning -> Change genral isRuuning status to "false"
            switch (gamemode) {
                case 0: {
                    return <GameDisplay ref="gamedisplay" gamemode={gamemode} gameinput={gameinput} onSubmit={onSubmit} stopRunning={stopRunning} />;
                }
                case 1: {
                    return <BatchGameDisplay ref="gamedisplay" gamemode={gamemode} gameinput={gameinput} gamestatus={gamestatus} onSubmit={onSubmit} stopRunning={stopRunning} />;
                }
                case 2: {
                    return <DLDisplay ref="gamedisplay" gamemode={gamemode} gameinput={gameinput} gamestatus={gamestatus} onSubmit={onSubmit} stopRunning={stopRunning} />;
                }
                default:
                    return <div></div>;
            }
        } else {
            return <Instruction />;
        }
    }

    /* Wrapping all component on the right side of the page */
    render() {
        const { isInit, gamemode } = this.props;
        return <MainPanelLayout>{this.renderGameDisplay(isInit, gamemode)}</MainPanelLayout>;
    }
}

// ============================================================================
// --+-- ImbueAILab: The main wrapper for ImbueAILab --+--
// ============================================================================
export default class ImbueAILab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameinput: {},
            isInit: false,
            isRunning: false,
            gamemode: null
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.stopRunning = this.stopRunning.bind(this);
    }

    onFormSubmit(stateFromSidebar) {
        const { isInit, isRunning } = this.state;
        if (!isInit && !isRunning) {
            // Current: Page is just initialised
            // Next:    Run the First Game
            const { gameinput, gamemode } = stateFromSidebar;
            this.setState({
                gamemode,
                gameinput,
                isInit: true,
                isRunning: true
            });
        } else if (isInit && isRunning) {
            // Current: The Agent Game is running
            // Next:    Terminate the Game

            const { gamemode } = stateFromSidebar;

            this.stopRunning();
            // if (this.refs.gamedisplay) {
            //     this.refs.gamedisplay.terminateGame()
            // }
            switch (gamemode) {
                case 0:
                    break;
                case 1:
                case 2:
                    console.log("MASTER IS TERMINATING THE GAME...");
                    this.refs.gamedisplay.terminateGame();
                    break;
                default:
                    break;
            }
        } else {
            // Current: After the first game has been terminated
            // Next:    Play next Game
            const { gameinput, gamemode } = stateFromSidebar;
            this.setState(
                {
                    isInit: false,
                    isRunning: false
                },
                () => {
                    this.setState({
                        gamemode,
                        gameinput,
                        isInit: true,
                        isRunning: true
                    });
                }
            );
        }
    }

    stopRunning() {
        this.setState({ isRunning: false });
    }

    onChangeState(key, value) {
        let state = this.state;
        state[key] = value;
        this.setState(state);
    }

    render() {
        const { version } = this.props; // "comdty"/"water"

        const { gamemode, gameinput } = this.state;
        return (
            <PageStyle>
                <SideBar
                    version={version}
                    onChangeState={this.onChangeState}
                    parentState={this.state}
                    args={this.props.query}
                    onSubmit={this.onFormSubmit}
                    isInit={this.state.isInit}
                    isRunning={this.state.isRunning}
                />
                <MainPanel
                    ref="gamedisplay"
                    gamemode={gamemode}
                    gameinput={gameinput}
                    onSubmit={this.onFormSubmit}
                    stopRunning={this.stopRunning}
                    isInit={this.state.isInit}
                    isRunning={this.state.isRunning}
                />
            </PageStyle>
        );
    }
}
