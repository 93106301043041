import React, { Component } from "react";
import styled from "styled-components";

import SM_StatusCharts from "./components/SM_StatusCharts";
import SM_InstanceStatus from "./components/SM_InstanceStatus";
import SM_CardWrapper from "./components/SM_CardWrapper";

const Layout = styled.div`
    font-size: 1.1rem;
    padding: 20px;
    box-sizing: border-box;

    .grid-layout {
        height: 100%;
        position: relative;
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 20px;
    }

    .layout_col {
        display: flex;
        flex-direction: column;
    }

    .layout_col > div {
        margin-bottom: 20px;
    }

    .label {
        font-size: 1rem;
        color: #888;
    }

    .value {
        font-size: 1.2rem;
        color: #333;
    }

    .col_title {
        font-family: var(--font-condensed);
        margin-bottom: 20px;
        font-size: 2rem;
    }

    .radius_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .radius_inner_wrapper {
        padding: 20px 10px;
        max-width: 150px;
        min-width: 100px;
    }
`;

export default class SysMonitor extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const CardWrapper = (comp, title) => {
            return <SM_CardWrapper component={comp} title={title} />;
        };

        return (
            <Layout>
                <div className="grid-layout">
                    {/* --+-- Infra Monitor --+-- */}
                    <div className="layout_col">
                        {CardWrapper(<SM_StatusCharts />, "Status")}
                        {/* {CardWrapper(<SM_EventTable />, "Events")} */}
                        {CardWrapper(<SM_InstanceStatus />, "Cloud Instance")}
                    </div>
                </div>
            </Layout>
        );
    }
}
