import React, { Component } from "react";
import { api_option_indicators } from "../../helpers/API";
import { COLORMAP, GLOBALCOLOR } from "../../config";
import HighChartWrapper from "./HighChartWrapper";
import HighStockWrapper from "./HighStockWrapper";
import styled from "styled-components";
import { hasItem } from "../../helpers/Utils";
import { generateSeasonal } from "../../helpers/ChartFunctions";
import ImbueTabs from "../tabs/ImbueTabs";
import {by_common} from "../../universalSymbolMap"

const SeriesColorGenerator = GLOBALCOLOR.seriesColorGenerator;

const highchartBasicConfig = {
    chart: {
        style: {
            fontFamily: "roboto",
        },
        backgroundColor: "rgba(0,0,0,0)",
        animation: false,
    },
    title: { text: "" },
    credits: { enabled: false },
    navigator: {
        maskFill: "rgba(78, 78, 78, 0.15)",
        height: 35,
        animation: false,
    },
    scrollbar: {
        height: 0,
        buttonArrowColor: "rgba(0,0,0,0)",
    },
    legend: { enabled: 1 },
    rangeSelector: {
	selected: 3,
        buttons: [
            { type: "all", text: "ALL" },
            { type: "year", count: 30, text: "30Y" },
            { type: "year", count: 20, text: "20Y" },
            { type: "year", count: 10, text: "10Y" },
            { type: "year", count: 5, text: "5Y" },
            { type: "year", count: 3, text: "3Y" },
            { type: "year", count: 1, text: "1Y" },
            { type: "ytd", text: "YTD" },
            { type: "month", count: 6, text: "6M" },
            { type: "month", count: 3, text: "3M" },
            { type: "month", count: 1, text: "1M" },
        ],
        buttonTheme: {
            fill: "#fff0",
            style: {
                fontSize: "1.1rem",
                fontFamily: "var(--font-main)",
                color: "#aaa",
            },
            floating: true,
            states: {
                hover: {
                    fill: "#fff0",
                    style: { color: "#000" },
                },
                select: {
                    fill: "#eee",
                    style: { color: "#111" },
                },
            },
        },
        labelStyle: { display: "none" },
        inputEnabled: false,
    },
    tooltip: { animation: false, outside: true, valueDecimals: 2 },
    xAxis: { type: "datetime", crosshair: true },
    yAxis: { gridLineColor: "#eee" },
    plotOptions: {
        series: {
            // color: COLORMAP.minor,
            dataGrouping: { approximation: "close" },
            animation: false,
            connectNulls: true
        },
    },
};

function generateChartConfig(data, charttype) {
    switch (charttype) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 8:
        case 11:
        case 14:
            const naming = {
                1: "call_oi",
                2: "put_oi",
                3: "options_oi",
                4: "options_dwoi",
                5: "call_delta",
                8: "put_delta",
                11: "net_delta",
                14: "delta_pct_combined_oi",
            };
            const ts1 = naming[charttype];
            const ts2 = ts1 + "_1d_chg";
            const ts3 = "reload_" + ts1;
            const ts4 = "tapered_" + ts1;

            // data = data.filter((ele) => ele[ts1] && ele[ts2]);
            return {
                ...highchartBasicConfig,
                chart: {
                    ...highchartBasicConfig.chart,
                    zoomType: "x",
                },
                xAxis: { ...highchartBasicConfig.xAxis, title: { text: "date" } },
                yAxis: [
                    { height: "60%", title: { text: ts1 } },
                    { height: "60%", title: { text: "Front Price" }, opposite: true },
                    { top: "60%", height: "40%", title: { text: ts2 } },
                ],
                tooltip: {
                    shared: true,
                },
                series: [
                    // {
                    //     name: "Adjusted R",
                    //     type: "line",
                    //     yAxis: 1,
                    //     data: data.map((ele) => [Date.parse(ele._date), ele["adjusted_price_r"]]),
                    //     color: COLORMAP.blue_o,
                    //     opacity: 0.4,
                    //     lineWidth: 1,
                    // },
                    // {
                    //     name: "Adjusted D",
                    //     type: "line",
                    //     yAxis: 1,
                    //     data: data.map((ele) => [Date.parse(ele._date), ele["adjusted_price_d"]]),
                    //     color: COLORMAP.red_o,
                    //     opacity: 0.4,
                    //     lineWidth: 1,
                    // },
                    {
                        name: "Price",
                        type: "line",
                        yAxis: 1,
                        zIndex: 2,
                        data: data.map((ele) => [Date.parse(ele._date), ele["front"]]),
                        color: "black",
                        opacity: 0.4,
                        lineWidth: 1.3,
                    },
                    {
                        name: ts1,
                        type: "line",
                        yAxis: 0,
                        data: data.map((ele) => [Date.parse(ele._date), ele[ts1]]),
                        color: "rgb(200, 0, 150)", //  purple
                        // lineWidth: 1,
                    },
                    {
                        name: ts3,
                        type: "column",
                        yAxis: 0,
                        zIndex: 1,
                        data: data.filter((ele) => ele[ts3]).map((ele) => [Date.parse(ele._date), ele[ts3]]),
                        color: 'rgba(255, 160, 0, 0.5)', // faint orange
                    },
                    {
                        name: ts4,
                        type: "line",
                        yAxis: 0,
                        data: data.filter((ele) => ele[ts4]).map((ele) => [Date.parse(ele._date), ele[ts4]]),
                        color: "rgb(50, 200, 200, 0.7)", // light blue
                        // lineWidth: 1,
                    },
                    {
                        name: ts2,
                        type: "column",
                        yAxis: 2,
                        data: data.map((ele) => [Date.parse(ele._date), ele[ts2]]),
                        color: "green",
                        negativeColor: "red",
                    },
                ],
                plotOptions: {
                    series: {
                        animation: false,
                        dataGrouping: {
                            enabled: false,
                        },
                    },
                },
            };
        case 6:
        case 9:
        case 12:
            const naming_ = {
                6: "call_delta_notional",
                9: "put_delta_notional",
                12: "net_delta_notional",
            };
            const ts_ = naming_[charttype];
            const ts_1 = "reload_" + ts_;
            const ts_2 = "tapered_" + ts_;

            data = data.filter((ele) => ele[ts_]);
            return {
                ...highchartBasicConfig,
                chart: {
                    ...highchartBasicConfig.chart,
                    zoomType: "x",
                },
                xAxis: { ...highchartBasicConfig.xAxis, title: { text: "date" } },
                yAxis: [{ title: { text: "Adjusted Price" } }, { title: { text: ts_ } }],
                series: [
                    // {
                    //     name: "Adjusted R",
                    //     type: "line",
                    //     yAxis: 0,
                    //     data: data.map((ele) => [Date.parse(ele.date), ele["adjusted_price_r"]]),
                    //     color: COLORMAP.blue,
                    //     opacity: 0.4,
                    //     lineWidth: 1,
                    // },
                    // {
                    //     name: "Adjusted D",
                    //     type: "line",
                    //     yAxis: 0,
                    //     data: data.map((ele) => [Date.parse(ele.date), ele["adjusted_price_d"]]),
                    //     color: COLORMAP.red_o,
                    //     opacity: 0.4,
                    //     lineWidth: 1,
                    // },
                    {
                        name: "Price",
                        type: "line",
                        yAxis: 0,
                        data: data.map((ele) => [Date.parse(ele._date), ele["front"]]),
                        color: "black",
                        opacity: 0.4,
                        lineWidth: 1,
                    },
                    {
                        name: ts_,
                        type: "line",
                        yAxis: 1,
                        data: data.map((ele) => [Date.parse(ele._date), ele[ts_]]),
                        color: COLORMAP.main,
                    },
                    {
                        name: ts_1,
                        type: "line",
                        yAxis: 1,
                        data: data.filter((ele) => ele[ts_1]).map((ele) => [Date.parse(ele._date), ele[ts_1]]),
                        color: "orange",
                    },
                    {
                        name: ts_2,
                        type: "line",
                        yAxis: 1,
                        data: data.filter((ele) => ele[ts_2]).map((ele) => [Date.parse(ele._date), ele[ts_2]]),
                        color: "purple",
                    },
                ],
            };
        case 7:
        case 10:
        case 13:
            const naming__ = {
                7: "call_delta_notional",
                10: "put_delta_notional",
                13: "net_delta_notional",
            };
            const ts__ = naming__[charttype];
            data = data.filter((ele) => ele[ts__] && ele.total_traders);
            return {
                ...highchartBasicConfig,
                chart: {
                    ...highchartBasicConfig.chart,
                    zoomType: "xy",
                },
                tooltip: {
                    pointFormatter: function () {
                        let ret = `<b>${this.name}</b><br> x:<b>${this.x}</b> <br>y:<b>${this.y}</b> `;
                        return ret;
                    },
                },
                xAxis: { title: { text: "#trader" } },
                yAxis: { title: { text: ts__ } },
                series: [
                    {
                        type: "scatter",
                        name: "historical",
                        color: "rgba(100, 100, 100, 0.3)",
                        marker: {
                            radius: 4,
                        },
                        data: data.slice(0, data.length - 3).map((ele) => ({
                            name: ele._date,
                            x: ele["total_traders"],
                            y: ele[ts__],
                        })),
                    },
                    {
                        type: "scatter",
                        name: "2 weeks",
                        color: SeriesColorGenerator(1),
                        marker: {
                            radius: 4,
                        },
                        data: data.slice(data.length - 3, data.length - 2).map((ele) => ({
                            name: ele._date,
                            x: ele["total_traders"],
                            y: ele[ts__],
                        })),
                    },
                    {
                        type: "scatter",
                        name: "1 week",
                        color: SeriesColorGenerator(5),
                        marker: {
                            radius: 4,
                        },
                        data: data.slice(data.length - 2, data.length - 1).map((ele) => ({
                            name: ele._date,
                            x: ele["total_traders"],
                            y: ele[ts__],
                        })),
                    },
                    {
                        type: "scatter",
                        name: "most recent",
                        color: SeriesColorGenerator(3),
                        marker: {
                            radius: 8,
                            symbol: "diamond",
                        },
                        data: data.slice(data.length - 1, data.length).map((ele) => ({
                            name: ele._date,
                            x: ele["total_traders"],
                            y: ele[ts__],
                        })),
                    },
                ],
            };
        case 15:
            const columns = [
                "delta_2_net_delta",
                "delta_2_net_delta_inverse_gamma_weighted",
                "delta_2_net_delta_gamma_weighted",
                "delta_2_net_inverse_gamma",
                "delta_2_net_gamma",
                "delta_2_abs_inverse_gamma",
                "delta_2_abs_gamma",
                "delta_2_net_delta_inverse_theta_weighted",
                "delta_2_net_delta_theta_weighted",
                "delta_2_net_inverse_theta",
                "delta_2_net_theta",
                "delta_2_abs_inverse_theta",
                "delta_2_abs_theta",
            ];
            const columns2 = ["adjusted_price_r", "adjusted_price_d", "price"];
            return {
                ...highchartBasicConfig,
                yAxis: [{}, { opposite: false }],
                series: [
                    ...columns.map((col) => ({
                        name: col,
                        data: data.filter((ele) => ele[col]).map((ele) => [Date.parse(ele._date), ele[col]]),
                        yAxis: 0,
                    })),
                    ...columns2.map((col) => ({
                        name: col,
                        lineWidth: 1,
                        data: data.filter((ele) => ele[col]).map((ele) => [Date.parse(ele._date), ele[col]]),
                        yAxis: 1,
                    })),
                ],
            };
        default:
            return highchartBasicConfig;
    }
}

function generateChartSeasonalConfig(data, charttype) {
    switch (charttype) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 8:
        case 11:
        case 14:
        case 6:
        case 9:
        case 12:
            const naming = {
                1: "call_oi",
                2: "put_oi",
                3: "options_oi",
                4: "options_dwoi",
                5: "call_delta",
                8: "put_delta",
                11: "net_delta",
                14: "delta_pct_combined_oi",
                6: "call_delta_notional",
                9: "put_delta_notional",
                12: "net_delta_notional",
            };
            const ts = naming[charttype];
            data = data.filter((ele) => ele[ts]);
            data = data.map((ele) => [ele._date, ele[ts]]);
            const config = {
                ...highchartBasicConfig,
                tooltip: {
                    shared: true,
                },
                series: generateSeasonal(data),
            };
            return config;
        default:
            return null;
    }
}

const Layout = styled.div`
    .ChartLayout {
        display: grid;
        grid-template-columns: 250px calc(100% - 250px);
        grid-template-rows: 100%;
    }
`;

export default class PositioningAnalysisDeltaChart extends Component {
    state = {
        data: [],
        meta: {},
        isLoading: 0,
        loadingText: "Loading...",
        charttype: 0,
        subchartview: 0, // 0.normal 1.seasonal
    };

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ isLoading: 1 }, () => {
            const comdty = this.props.comdty || "W 1 Comdty";
            const datefrom = this.props.date || "2000-01-01";
            const datatype = 4;
            const charttype = 1;
            // get root from bloomberg ticker, make uppercase, remove spaces
            const commonSymbol = comdty.split("1")[0].toUpperCase().replace(/\s+/g, '');;
            const deltaSymbol = by_common(commonSymbol).deltas
            // TODO: fix single letter symbols (W, S, C)
            api_option_indicators({"root" : deltaSymbol})
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    this.setState({
                        ...data,
                        isLoading: 0,
                        charttype: 1,
                    });
                })
                .catch((e) => {
                    this.setState({
                        loadingText: "ERROR!",
                    });
                });
        });
    };

    onChangeSubView = (e) => {
        this.setState({
            subchartview: e,
        });
    };

    render() {
        const charttypeOptions = [
            { label: "Call Open Interest", value: 1 },
            { label: "Put Open Interest", value: 2 },
            { label: "Options Open Interest", value: 3 },
            { label: "Delta Weighted Options OI", value: 4 },

            { label: "Call Delta", value: 5 },
            { label: "Call Delta Notional", value: 6 },
            { label: "Call Delta Notional vs #Traders", value: 7 },

            { label: "Put Delta", value: 8 },
            { label: "Put Delta Notional", value: 9 },
            { label: "Put Delta Notional vs #Traders", value: 10 },

            { label: "Net Delta", value: 11 },
            { label: "Net Delta Notional", value: 12 },
            { label: "Net Delta Notional vs #Traders", value: 13 },

            { label: "Delta as % of OI", value: 14 },

            { label: "Experimental", value: 15 },
        ];
        const viewmodeOptions = [
            { label: "General", value: 0 },
            { label: "Seasonal", value: 1 },
        ];

        if (this.state.isLoading) {
            return <div className="LoadingText">{this.state.loadingText}</div>;
        } else {
            const ChartEngine = () => {
                let chartConfig = {};
                if (this.state.subchartview === 0) {
                    chartConfig = generateChartConfig(this.state.data, this.state.charttype);
                } else {
                    chartConfig = generateChartSeasonalConfig(this.state.data, this.state.charttype);
                }

                if (hasItem(this.state.charttype, [7, 10, 13])) {
                    return (
                        <HighChartWrapper key={this.state.charttype + this.state.subchartview} config={chartConfig} />
                    );
                } else {
                    return (
                        <HighStockWrapper key={this.state.charttype + this.state.subchartview} config={chartConfig} />
                    );
                }
            };
            return (
                <Layout>
                    <div className="ChartLayout">
                        <div className="SideBar_layout">
                            <div className="SideBar_title">Category</div>
                            <div className="SideBar_scrollable">
                                {charttypeOptions.map((ele) => {
                                    return (
                                        <div
                                            className={
                                                "SideBarBtn" +
                                                (ele.value === this.state.charttype ? " SideBarBtnSelected" : "")
                                            }
                                            onClick={() => {
                                                this.setState({ charttype: ele.value });
                                            }}
                                            key={ele.value}
                                        >
                                            {ele.label}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <ImbueTabs
                                options={viewmodeOptions}
                                selectedValue={this.state.subchartview}
                                onClick={this.onChangeSubView}
                            />
                            <div className="highchartResponsiveWrapper" style={{ height: "70vh", padding: "20px" }}>
                                {ChartEngine()}
                            </div>
                        </div>
                    </div>
                </Layout>
            );
        }
    }
}
