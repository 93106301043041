import React, { Component } from "react";
import { AuthGet } from "../../../helpers/AuthFetch";

import { URL } from "../../../config";
import { ScaleLoader } from "react-spinners";
import { formatSelection, hasItem } from "../../../helpers/Utils";
import ImbueSelect from "../../../components/input/ImbueSelect";

import { getGlobalGameMode, setGlobalGameMode } from "../../../helpers/StoragController";
import ImbueGroupSelect from "../../../components/input/ImbueGroupSelect";
import { api_ailab_doc, api_ailab_form } from "../../../helpers/API";
import styled from "styled-components";

const EMAIL = localStorage.getItem("_email");

const SideBarLayout = styled.div`
    border-right: 1px solid #ddd;

    .gamemode_select {
        display: flex;
        font-family: var(--font-main);
        font-size: 1.3rem;
        color: #555;
        justify-content: space-between;
        align-items: center;
        border-radius: 3px;
    }
`;

const AgentFormLayout = styled.div`
    padding: 15px;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 150px calc(100% - 150px);
    height: 100%;

    .select-item {
        margin: 10px 0;
        font-family: "Open Sans";
    }

    .select-wrapper {
        display: flex;
    }

    .select-label {
        display: block;
        font-weight: 800;
        margin-bottom: 5px;
        font-size: 1.1rem;
        color: #888;
        transition: all 0.1s;
    }

    .select-dropdown {
        -webkit-appearance: none;
        font-size: 1.2rem;
        width: 100%;
        border: none;
        border-bottom: 1px solid #ddd;
        border-radius: 0;
        background-color: transparent;
        padding: 3px 0;
        transition: all 0.2s;
    }

    .select-dropdown:focus {
        border-bottom: 1px solid #222;
    }

    .select-dropdown:hover {
        border-bottom: 1px solid #444;
    }

    /* --- */

    .select-checkbox {
        padding: 5px;
        transition: all 0.2s;
        border: 1px solid transparent;
    }

    .select-checkbox:hover {
        cursor: pointer;
        border: 1px solid #ccc;
    }

    .select-icon {
        margin-left: -15px;
        margin-top: 5px;
        color: #777;
    }

    .agent-button {
        width: 70px;
        height: 70px;
        display: block;
        padding: 15px;
        margin: 10px auto;
        font-family: var(--font-main);
        /* transition: all .2s; */
        border: none;
        border-radius: 100%;
        color: white;
        background: var(--color-main);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .agent-button:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    .agent-button:active {
        transform: scale(0.98, 0.98);
    }

    .agent-button-sml {
        font-family: var(--font-main);
        font-size: 1.3rem;
        color: #777;
        width: 28px;
        height: 28px;
        display: block;
        border: none;
        border-radius: 50%;
        transition: all 0.1s;
        background: none;
    }
    .agent-button-sml:hover {
        color: #000;
        background: #ddd;
        cursor: pointer;
        opacity: 0.9;
    }
    .agent-button-sml:active {
        background: #ccc;
    }
    .i-left {
        transform: translateX(-1px) translateY(1px);
    }
    .i-right {
        transform: translateX(1px) translateY(1px);
    }

    .checkbox_wrapper {
        display: block;
        margin: 1px 0;
        padding: 6px 3px;
        font-family: roboto;
        font-size: 1.2rem;
        color: #333;
        transition: all 0.2s;
        border-radius: 4px;
    }

    .checkbox_wrapper:hover,
    .checkbox_wrapper label:hover {
        cursor: pointer;
        background: #eee;
    }

    /* MAIN GAME OPTIONS */
    .gameoptions {
        border-top: 1px solid #ccc;

        height: 100%;
        overflow: scroll;
        padding-bottom: 100px;
        box-sizing: border-box;

        ::-webkit-scrollbar-thumb {
            background: transparent;
        }
        ::-webkit-scrollbar-track {
            background: transparent;
        }
    }
`;

class ImbueAILab_SideBar extends Component {
    /* 
        Handle input form of an agent. Each agent has different inputs, so
        1. select mode
        2. display input options for the specific mode
    */

    constructor(props) {
        super(props);

        this.state = {
            gamemode: getGlobalGameMode() === null ? 0 : getGlobalGameMode(), // GameMode: 0 -> Single Mode | 1 -> Batch Mode
            prefilled: {},
            agentIndex: [], // Types of agent available
            agentInputs: {}, // Available parameters for each type of agent
            mode: null,
            gameinput: { user: EMAIL }, // Current Form Parameters
            isLoading: false,
            isChangingAgent: false,
            isContinue: false,
            config: {
                display_in_sample: localStorage.getItem("config__display_in_sample") === null ? 0 : localStorage.getItem("config__display_in_sample") === "true"
            }
        };

        this.getAgentGameForm = this.getAgentGameForm.bind(this);
        this.onChangeAgentId = this.onChangeAgentId.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChangeSetting = this.onChangeSetting.bind(this);
        this.collectOptions = this.collectOptions.bind(this);
        this.onChangeGameMode = this.onChangeGameMode.bind(this);
        this.prefillArgs = this.prefillArgs.bind(this);
    }

    componentDidMount() {
        this.prefillArgs();
        this.onChangeGameMode(true);
    }

    prefillArgs() {
        const { args } = this.props;
        if ("docid" in args) {
            api_ailab_doc(args.docid, ["user_inputs", "factors", "agent", "ticker"])
                .then(res => {
                    const result = res.data.content;
                    let userinputs = result.user_inputs;

                    if ("data_category" in userinputs) {
                        userinputs["factors"] = userinputs["data_category"];
                    } else {
                        userinputs["factors"] = result.factors;
                    }
                    userinputs["security"] = result.ticker;
                    userinputs["agent_id"] = result.agent;
                    userinputs["agent_pool"] = [result.agent];

                    this.setState({
                        prefilled: {
                            ...userinputs
                        }
                    });
                })
                .catch(err => console.log(err));
        } else {
            this.setState({
                prefilled: { ...{ security: "" }, ...args }
            });
        }
    }

    // --+-- Func: Change the game mode --+--
    onChangeGameMode(isInit, direction) {
        const GM_COUNT = 3;

        const { gamemode } = this.state;

        let newMode = null;
        if (isInit === true) {
            newMode = gamemode;
        } else {
            if (direction > 0) {
                newMode = gamemode === GM_COUNT - 1 ? 0 : gamemode + 1;
            } else if (direction < 0) {
                newMode = gamemode === 0 ? GM_COUNT - 1 : gamemode - 1;
            } else {
                newMode = gamemode;
            }
        }

        setGlobalGameMode(newMode);

        this.setState(
            {
                gamemode: newMode
            },
            () => {
                if (newMode === 1) {
                    // if Game is Running
                    const ENDPOINT = `${URL}/optimise?user=${EMAIL}&`;
                    AuthGet(ENDPOINT)
                        .then(rsp => rsp.json())
                        .then(res => {
                            if (res.jobs.length > 0) {
                                this.props.onSubmit(this.state);
                                this.getAgentGameForm();
                            } else {
                                this.getAgentGameForm();
                            }
                            // this.props.onSubmit(this.state)
                            // this.getAgentGameForm()
                        });

                    // const ENDPOINTPREV = `${URL}/optimise?user=${EMAIL}&which=previous&`
                    // AuthGet(ENDPOINTPREV)
                    //     .then(rsp => rsp.json())
                    //     .then(res => {
                    //         console.log(res)
                    //     })
                } else {
                    this.getAgentGameForm();
                }
            }
        );
    }

    onChangeAgentId(e) {
        // console.log(e)
        this.setState(
            {
                isChangingAgent: true
            },
            () => {
                if (e) {
                    let newAgentId = e.target ? e.target.value : typeof e === "object" ? e.value : e;
                    this.setState({
                        ...this.state,
                        mode: newAgentId,
                        isChangingAgent: false
                    });
                }
            }
        );
    }

    onFormSubmit(e) {
        e.preventDefault();
        let form = new FormData(e.target);
        let mode = this.state.mode;
        let gameinput = {};
        gameinput["mode"] = mode;
        gameinput["user"] = window.localStorage.getItem("_email");

        console.log(gameinput);

        this.state.agentInputs[mode].map(ele => {
            if (ele.type === "multiSelection") {
                let options = ele.selection.map((opt, idx) => {
                    let id = ele.label + "_" + idx;
                    return form.get(id);
                });
                gameinput[ele.identifier] = options.filter(ele => ele !== null);
            } else {
                gameinput[ele.identifier] = form.get(ele.identifier);
            }
            return null;
        });
        this.setState(
            {
                gameinput: gameinput
            },
            () => {
                this.handleSubmit();
            }
        );
    }

    onChangeSetting(e, name) {
        switch (name) {
            case "display_in_sample":
                let checked = e.target.checked;
                this.setState({
                    config: {
                        ...this.state.config,
                        display_in_sample: checked ? 1 : 0
                    }
                });
                localStorage.setItem("config__" + name, checked);
            default:
                break;
        }
    }

    // API: getAgentForm
    getAgentGameForm() {
        let { version } = this.props;
        version = version || "comdty";
        let modelist = version === "comdty" ? ["agent_form", "optimiser_form", "deep_learn_form"] : ["agent_form", "water_form", "deep_learn_form"];
        this.setState(
            {
                isLoading: true,
                isContinue: false
            },
            () => {
                const { gamemode } = this.state;
                const modename = modelist[gamemode];
                api_ailab_form(modename)
                    .then(res => {
                        const result = res.data;
                        this.setState(
                            {
                                agentIndex: result.agentIndex,
                                agentInputs: result.agentInputs,
                                mode: result.agentIndex[0], // Default Agent Id
                                isLoading: false
                            },
                            () => {
                                // Initialize the Form useing pre-filled args
                                let initAgentId = this.state.prefilled.mode;
                                if (initAgentId) {
                                    // -- If mode is validated
                                    let isAgentIdValid = this.state.agentIndex.indexOf(initAgentId) > 0;
                                    if (isAgentIdValid) {
                                        this.onChangeAgentId(initAgentId);
                                    } else {
                                        // console.log("Pre-filled agent id is INVALID!")
                                    }
                                } else {
                                    // console.log("There is no pre-filled agent id!")
                                }
                            }
                        );
                    })
                    .catch(error => console.log(error));
            }
        );
    }

    handleSubmit() {
        this.props.onSubmit(this.state);
        // console.log(this.state)
    }

    collectOptions(isPlay) {
        let agentInputIndex = this.state.agentInputs[this.state.mode];
        // console.log(agentInputIndex)
        let inputSet = {
            mode: this.state.mode,
            user: window.localStorage.getItem("_email")
        };
        for (let item of agentInputIndex) {
            let selectLabel = "select__" + item.identifier;
            let key = item.identifier;
            switch (item.type) {
                case "dropdown":
                case "multiSelection": {
                    let selectedOptions = this.refs[selectLabel].getValues() === null ? [] : "label" in this.refs[selectLabel].getValues() ? (item.type === "multiSelection" ? [this.refs[selectLabel].getValues().value] : this.refs[selectLabel].getValues().value) : this.refs[selectLabel].getValues().map(ele => ele.value);
                    inputSet[key] = selectedOptions;
                    break;
                }
                case "pipeline": {
                    const selectedOptions = this.refs[selectLabel] ? this.refs[selectLabel].collectOptions() : [];
                    inputSet[key] = selectedOptions;
                    break;
                }
                case "checkbox": {
                    let selectedOptions = this.refs[selectLabel].checked;
                    inputSet[key] = selectedOptions;
                    break;
                }
                default:
                    break;
            }
        }

        let isInputValid = ["factors"].map(ele => inputSet[ele].length > 0).reduce((a, b) => a && b);

        // console.log(inputSet)

        if (isPlay === true && isInputValid) {
            this.setState(
                {
                    gameinput: inputSet
                },
                () => {
                    this.handleSubmit();
                }
            );
        }
    }
    // ---- Render
    renderForm() {
        const { isLoading, mode, prefilled, agentIndex, agentInputs } = this.state;
        const { isInit, isRunning } = this.props;

        const currentAgentInputs = agentInputs[mode];
        const isGameRunning = isInit && isRunning;

        return (
            <form
                onSubmit={e => {
                    e.preventDefault();
                }}
            >
                {/* 
                =======================
                --+-- Play Button --+-- 
                =======================
                */}

                {isLoading || currentAgentInputs === undefined ? (
                    <div style={{ display: "flex", justifyContent: "center", margin: "30px 0" }}>
                        <ScaleLoader color="rgb(170,170,170)" />
                    </div>
                ) : (
                    <div>
                        <div style={{ animation: "textFloatToLeft .2s" }}>
                            <div>
                                <div className="select-item">
                                    <label className="select-label">Choose a mode </label>
                                    <div>
                                        <ImbueSelect
                                            isDisabled={isGameRunning}
                                            selectLabel="mode"
                                            value={{ label: mode, value: mode }}
                                            onChange={this.onChangeAgentId}
                                            formatOptionLabel={(data, state) => (
                                                <div>
                                                    <i
                                                        style={{
                                                            animation: "textFloatToRight__small .2s",
                                                            marginRight: "2px"
                                                        }}
                                                        className="fal fa-rocket"
                                                    ></i>{" "}
                                                    {data.value}
                                                </div>
                                            )}
                                            options={agentIndex.map(agentId => {
                                                return { value: agentId, label: agentId };
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>

                            {Object.keys(currentAgentInputs || {}).map(key => {
                                let paraIndex = currentAgentInputs[key];
                                let formatted = formatSelection(paraIndex, paraIndex.selection, prefilled[paraIndex.identifier]);
                                let selectOptions = formatted.formatted;
                                let defaultOption = formatted.defaultOption;

                                // console.log(`
                                // --------------------------------------
                                // id: ${paraIndex.identifier}
                                // ty: ${paraIndex.type}
                                // df: ${JSON.stringify(defaultOption)}
                                // pf: ${JSON.stringify(prefilled[paraIndex.identifier])}
                                // --------------------------------------
                                //                                         `)

                                const keyName = "form#" + paraIndex.identifier + ":" + JSON.stringify(prefilled[paraIndex.identifier] ? "hasprefilled" : "noprefilled");

                                switch (paraIndex.type) {
                                    case "dropdown":
                                        return (
                                            <div key={keyName} className="select-item">
                                                <label className="select-label">{paraIndex.label}: </label>
                                                <ImbueSelect
                                                    isDisabled={isGameRunning}
                                                    ref={"select__" + paraIndex.identifier}
                                                    onChange={this.collectOptions}
                                                    collectOptions={this.collectOptions}
                                                    customStyles={{
                                                        optionPadding: "5px 10px"
                                                    }}
                                                    defaultValue={defaultOption}
                                                    options={selectOptions}
                                                />
                                            </div>
                                        );
                                    case "multiSelection":
                                        return (
                                            <div key={keyName} className="select-item">
                                                <label className="select-label">{paraIndex.label}: </label>
                                                <ImbueSelect
                                                    onInputChange={e => {
                                                        try {
                                                            const parsed = JSON.parse(e);
                                                            var old = this.refs["select__" + paraIndex.identifier].getValues();
                                                            if (!old.length) {
                                                                old = [old];
                                                            }
                                                            console.log(selectOptions);
                                                            const allOptions = selectOptions.map(ele => ele.options).reduce((a, b) => [...a, ...b], []);
                                                            const filtered = allOptions.filter(a => hasItem(a.value, parsed));
                                                            console.log(filtered);
                                                            // this.refs["select__" + paraIndex.identifier].setValues(filtered);
                                                        } catch {
                                                            // this.refs["select-data-" + ticker].onMenuClose()
                                                        }
                                                    }}
                                                    isDisabled={isGameRunning}
                                                    ref={"select__" + paraIndex.identifier}
                                                    collectOptions={this.collectOptions}
                                                    customStyles={{
                                                        optionPadding: "2px 10px"
                                                    }}
                                                    isClearable={1}
                                                    isMulti={1}
                                                    closeMenuOnSelect={false}
                                                    defaultValue={defaultOption}
                                                    options={selectOptions}
                                                />
                                            </div>
                                        );
                                    case "checkbox":
                                        return (
                                            <div key={keyName} className="select-item">
                                                <label className="checkbox_wrapper">
                                                    <input ref={"select__" + paraIndex.identifier} style={{ width: "auto", marginRight: "5px" }} defaultChecked={defaultOption || false} type="checkbox" />
                                                    <b>{paraIndex.label}</b>
                                                </label>
                                            </div>
                                        );
                                    case "pipeline":
                                        return (
                                            <div key={keyName} className="select-item">
                                                <label className="select-label">{paraIndex.label}: </label>
                                                <ImbueGroupSelect ref={"select__" + paraIndex.identifier} options={paraIndex.selection} prefilled={prefilled[paraIndex.identifier]} />
                                            </div>
                                        );
                                    default:
                                        return "";
                                }
                            })}
                        </div>
                    </div>
                )}
            </form>
        );
    }

    mainRender() {
        const { gamemode } = this.state;
        return (
            <div className="gameoptions">
                {this.renderForm()}
                {gamemode === 0 ? (
                    <div>
                        <div className="fontFamily__imbue_info fontSize__1p2rem">
                            {" "}
                            <i className="fas fa-cog"></i> Settings
                        </div>
                        <label className="checkbox_wrapper">
                            <input
                                onChange={e => {
                                    this.onChangeSetting(e, "display_in_sample");
                                }}
                                style={{ width: "auto", marginRight: "5px" }}
                                type="checkbox"
                                checked={this.state.config.display_in_sample}
                            />
                            <b>Display In-sample</b>
                        </label>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }

    render() {
        // -- States
        const { gamemode } = this.state;

        // -- Props
        const { isInit, isRunning } = this.props;

        const isGameRunning = isInit && isRunning;

        return (
            <SideBarLayout>
                <AgentFormLayout>
                    {/* --+-- Mode Switch --+-- */}

                    <div>
                        <div className="gamemode_select noselect">
                            <button
                                onClick={() => {
                                    if (!isGameRunning) {
                                        this.onChangeGameMode(false, -1);
                                    }
                                }}
                                title="Click to Switch"
                                className="agent-button-sml"
                            >
                                <i className="fas fa-caret-left i-left"></i>
                            </button>
                            <div>
                                <div style={{ fontSize: "1rem", color: "#777", textAlign: "center", marginBottom: "-3px" }}>Game Mode</div>
                                <div style={{ fontSize: "2rem", color: "#111", textAlign: "center", animation: "fadeIn .2s" }} key={"gamemode-display-id-" + gamemode}>
                                    {["Single", "Optimiser", "Deep Learn"][gamemode]}
                                </div>
                            </div>
                            <button
                                onClick={() => {
                                    if (!isGameRunning) {
                                        this.onChangeGameMode(false, 1);
                                    }
                                }}
                                title="Click to Switch"
                                className="agent-button-sml"
                            >
                                <i className="fas fa-caret-right i-right"></i>
                            </button>
                        </div>

                        <div style={{ position: "relative", margin: "20px 0" }}>
                            <button
                                onClick={() => {
                                    this.collectOptions(true);
                                }}
                                className="agent-button"
                                style={isGameRunning ? { paddingTop: "19px", paddingLeft: "16px", fontSize: "2.5rem", background: "#C62828" } : { paddingTop: "17px", paddingLeft: "21px", fontSize: "2.5rem" }}
                            >
                                {!this.props.isInit && !this.props.isRunning ? <i className="fas fa-play"></i> : isGameRunning ? <i className="fas fa-stop"></i> : <i className="fas fa-play"></i>}
                            </button>
                        </div>
                    </div>

                    {// Main Content
                    this.mainRender()}
                </AgentFormLayout>
            </SideBarLayout>
        );
    }
}

export default ImbueAILab_SideBar;
