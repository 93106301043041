/**
 * A multiple PDFs viewer
 */
import React, { Component } from 'react'
import styled from 'styled-components';
import { GLOBALSTYLED } from '../../config';
import S3PdfViewer from "../../components/pdfViewer/S3PdfViewer";
import ImbueTabs from "../../components/tabs/ImbueTabs";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;

const documentOptions = [
    { label: "IV Value - Expiration 2", value: "IV_value.pdf" },
    { label: "IV Historical Features - Expiration 2", value: "IV_historical_features.pdf" },
    { label: "IV Value - Expiration 3", value: "IV_value_alt_step.pdf" },
    { label: "IV Historical Features - Expiration 3", value: "IV_historical_features_alt_step.pdf" },
    { label: "Gamma-Vol Mismatch", value: "Gamma_Vol_Mismatch.pdf"},
    { label: "Gamma-Vol Historical", value: "gamma_vol_history.pdf"},
    { label: "Skew-Gamma Momentum", value: 'SkewMomentum_History.pdf'}
]

export default class StrategyDetails extends Component {

    state = {
        selectedItem: documentOptions[0]
    }

    render() {
        return (
            <Layout>
                <ImbueTabs options={documentOptions} selectedValue={this.state.selectedItem.value} onClickOption={(opt) => this.setState({selectedItem: opt})} />
                <h1>{this.state.selectedItem.label}</h1>
                <S3PdfViewer key={this.state.selectedItem.value} bucketname="imbuereportbucket" id={this.state.selectedItem.value} />
            </Layout>
        )
    }
}
