import React, { Component } from "react";

import { CSVLink } from "react-csv/lib";
import { COLORMAP } from "../../config";
import { TickerDict } from "../../index/TickerDictonary";
import {
    toAusTime,
    getStats,
    titleWords,
    parsePairname,
} from "../../helpers/Utils";
import HeaderWithDownload from "../text/HeaderWithDownload";
import ImbueTable from "../table/ImbueTable";
import LoadingBar from "../bar/LoadingBar";
import moment from "moment";
import ReactHighstock from "react-highcharts/ReactHighstock";

import {
    generateTradesSeries,
    hc_rebase,
    tradeChartTooltipFormatter,
    pnlChartTooltipFormatter,
    generateSeasonal,
    hc_yaxis_percentage_formatter,
    generateTransactionSeries,
} from "../../helpers/ChartFunctions";

// Sub Components
import DynamicStatsBar from "./components/DynamicStatsBar";
import StatsBar from "./components/StatsBar";

// Suu tab components
import TabAnnualCumulativeReturn from "./tabs/TabAnnualCumulativeReturn";
import TabPerformanceBySector from "./tabs/TabPerformanceBySector";
import TabSecurityPnlBySector from "./tabs/TabSecurityPnlBySector";
import TabBySector from "./tabs/TabBySector";
import TabFeatureImportance from "./tabs/TabFeatureImportance";
import { dictCumSum } from "../../helpers/Tabular";
import { simChartCommonConfig } from "./Config";
import ImbueTabs from "../tabs/ImbueTabs";

const BASE = 100;

export default class SimulationMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statistics: [],
            periodStats: [],
            chartIndex: 0, // default index
            hasCommision: false,
            stateChangeCounter: 0,
        };
        this.updateData = this.updateData.bind(this);
        this.onChangeChart = this.onChangeChart.bind(this);
        this.onChangePeriod = this.onChangePeriod.bind(this);
        this.renderChart = this.renderChart.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.data.pageType !== this.props.data.pageType ||
            prevProps.data.secIndex !== this.props.data.secIndex
        ) {
            this.updateData(this.props);
        }
    }

    componentDidMount() {
        this.updateData(this.props);
    }

    /**
     *
     * @param {*} props
     */
    updateData(props) {
        try {
            let { pnl } = props.data;
            // 1 -- cumulative Pnl
            let data_cum_pnl = (pnl || []).map((ele) => [
                Date.parse(ele[0]),
                ele[1] * BASE,
            ]);

            let series_cum_pnl = [
                {
                    name: "Cumulative P&L",
                    type: "area",
                    turboThreshold: 0,
                    fillOpacity: 0.1,
                    color: "#00695C",
                    negativeColor: "#D50000",
                    lineWidth: 1,
                    tooltip: {
                        valueDecimals: 4,
                        pointFormatter: function (e) {
                            const reseted = data_cum_pnl.filter(
                                (ele) => ele[0] > this.series.xAxis.min
                            );
                            if (reseted.length > 0) {
                                const startY = reseted[0][1];
                                return (
                                    "Cumulative P&L: " +
                                    ((this.y - startY) * 1).toFixed(3) +
                                    "%"
                                );
                            } else {
                                return (
                                    "Cumulative P&L: " +
                                    (this.y * 1).toFixed(3) +
                                    "%"
                                );
                            }
                        },
                    },
                    data: data_cum_pnl,
                },
            ];

            // 2 -- daily Pnl
            let series_daily_pnl = [
                {
                    type: "column",
                    name: "P&L",
                    data: props.data.daily_pnl
                        ? props.data.daily_pnl.map((ele) => [
                              Date.parse(ele[0]),
                              ele[1],
                          ])
                        : [],
                    color: COLORMAP.green,
                    negativeColor: COLORMAP.red,
                    dataGrouping: {
                        units: [
                            [
                                "week", // unit name
                                [1], // allowed multiples
                            ],
                            ["month", [1, 2, 3, 4, 6]],
                        ],
                    },
                },
            ];

            // 3 -- exposure
            let series_exposure = [
                {
                    name: "Gross Exposure",
                    type: "area",
                    data: props.data.exposure
                        ? props.data.exposure.map((ele) => [
                              Date.parse(ele[0]),
                              ele[1],
                          ])
                        : [],
                    color: COLORMAP.blue,
                    fillOpacity: 0.1,
                    dataGrouping: { approximation: "high" },
                },
                {
                    name: "Net Exposure",
                    type: "area",
                    data: props.data.net_exposure
                        ? props.data.net_exposure.map((ele) => [
                              Date.parse(ele[0]),
                              ele[1],
                          ])
                        : [],
                    color: "var(--color-darkgold)",
                    fillOpacity: 0.1,
                    dataGrouping: { approximation: "high" },
                },
            ];

            // 4 -- trades
            let series_trades = generateTradesSeries(props.data);

            // 4.1 -- transactions (new)
            var transactionsData = [];
            props.data.transactions = props.data.trades;
            if (props.data.transactions) {
                transactionsData = props.data.transactions;
                transactionsData = dictCumSum(
                    transactionsData,
                    "amount",
                    "cumAmount"
                );
                props.data["transactions"] = transactionsData;
            }
            let series_transactions = generateTransactionSeries(props.data);
            // console.log(series_transactions);

            // 5 -- statistics
            let calculatedStats = getStats(
                props.data,
                props.type === "secPage",
                1
            );

            let statistics = Object.keys(calculatedStats).map((k) => ({
                label: titleWords(k),
                value: calculatedStats[k],
            }));

            // 6 -- multi Cum P&L
            const multicumpnl = props.data.multicumpnl;

            // 7 -- trade prob timeseries
            // const tradeProbaData = (props.data.trades || []).map(ele => [ele.open_date, ele.proba]).sort((a, b) => moment(a) - moment(b));
            // const tradeProbaData = [];

            // 8 -- CumPnl seasonal view | tab "Annual Cumulative Returns"
            const series_seasonal_cum_pnl = generateSeasonal(
                (pnl || []).map((ele) => [ele[0], ele[1] * 1])
            );

            // END -- set state
            this.setState(
                {
                    bulkData: props.data,

                    cumPnl: series_cum_pnl,
                    seasonCumPnl: series_seasonal_cum_pnl,

                    dailyPnl: series_daily_pnl,
                    exposure: series_exposure,
                    statistics: statistics,

                    series_transactions: series_transactions,

                    multicumpnl,

                    transactionsData: props.data.transactions,

                    isPortfolio: props.type === "simPage",

                    portfolioMeta: props.data.meta,
                },
                () => {
                    if (this.refs["docinfobar"]) {
                        this.refs["docinfobar"].getDocInfo();
                    }
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    /**
     *
     * @param {number} value -- tab index
     */
    onChangeChart(value) {
        const nextIndex = parseInt(value);
        this.setState({
            chartIndex: nextIndex,
        });
    }

    /**
     *
     * @param {object} e
     */
    onChangePeriod(e) {
        const { cumPnl } = this.state;
        if (cumPnl) {
            let newPnl = cumPnl[0].data.filter(
                (ele) =>
                    ele[0] >= Date.parse(e.startDate) &&
                    ele[0] <= Date.parse(e.endDate)
            );
            let dataForStats = {
                pnl: newPnl.map((ele) => [ele[0], ele[1]]),
            };
            let newStats = this.state.isPortfolio
                ? getStats(dataForStats, null, 1)
                : getStats(dataForStats, true, 1);
            newStats["start_date"] = e.startDate;
            newStats["end_date"] = e.endDate;
            newStats = Object.keys(newStats).map((key) => ({
                label: key,
                value: newStats[key],
            }));
            if (this.refs["stats_ref"]) {
                this.refs["stats_ref"].onChangeStats(newStats);
            }
        }
    }

    renderChart(idx) {
        const onChangePeriod = this.onChangePeriod;

        const {
            stateChangeCounter, // var: state change counter
            isPortfolio, // bool, whether current page type is portoflio

            dailyPnl, // page::portfolio/security: daily pnl
            cumPnl, // page::portfolio/security: cumulative pnl
            seasonCumPnl, // page::portfolio/security: annual cumulative pnl
            exposure, // page::portfolio/security: nexp and gexp

            pnlBySector, // page::portfolio: pnl by sector
            nExpBySector, // page::portfolio: nexp by sector
            gExpBySector, // page::portfolio: gexp by sector
            secPnlBySector, // page::portfolio: pnl by sub-sector
            secNexpBySector, // page::portfolio: nexp by sub-sector
            secGexpBySector, // page::portfolio: gexp by sub-sector

            series_transactions, // page::transacitons

            bulkData,
            transactionsData,
        } = this.state;

        const hasData = cumPnl ? cumPnl[0].data.length > 0 : false;

        // Convert 2D array data to highchart series
        const series_data_for_exposure = exposure
            ? [
                  {
                      ...exposure[0],
                      yAxis: 1,
                      lineWidth: 1,
                  },
                  {
                      ...exposure[1],
                      yAxis: 1,
                      lineWidth: 1,
                  },
              ]
            : [];
        const series_data_for_daily_pnl = dailyPnl
            ? [
                  {
                      ...dailyPnl[0],
                      yAxis: 2,
                      color: COLORMAP.green,
                      negativeColor: COLORMAP.red,
                  },
              ]
            : [];

        // Generage Configuration Data for Highchart
        const chart_configuration_for_portfolio_page = {
            ...simChartCommonConfig,
            chart: {
                ...simChartCommonConfig.chart,
                events: {
                    load: function (e) {
                        try {
                            const minX = e.target.xAxis[0].min;
                            const maxX = e.target.xAxis[0].max;
                            const startDate = moment(
                                new Date(minX).toISOString()
                            ).format("YYYY-MM-DD");
                            const endDate = moment(
                                new Date(maxX).toISOString()
                            ).format("YYYY-MM-DD");
                            const dateRange = { startDate, endDate };
                            onChangePeriod(dateRange);
                        } catch (error) {
                            console.log(error);
                        }
                    },
                },
            },
            tooltip: {
                useHTML: true,
                formatter: pnlChartTooltipFormatter,
            },
            xAxis: {
                ...simChartCommonConfig.xAxis,
                events: {
                    afterSetExtremes: function (e) {
                        try {
                            const startDate = moment(
                                new Date(e.min).toISOString()
                            ).format("YYYY-MM-DD");
                            const endDate = moment(
                                new Date(e.max).toISOString()
                            ).format("YYYY-MM-DD");
                            const dateRange = { startDate, endDate };
                            onChangePeriod(dateRange);
                        } catch (error) {
                            console.log(error);
                        }
                    },
                },
                crosshair: { width: 1, color: "green", dashStyle: "shortDash" },
            },
            yAxis: [
                {
                    title: { text: "Cumulative P&L" },
                    tickerAmount: 8,
                    opposite: false,
                    crosshair: {
                        width: 1,
                        color: "#aaa",
                        dashStyle: "shortDash",
                    },
                    height: "80%",
                    tickAmount: 8,
                    labels: {
                        formatter: hc_rebase(3),
                        tickAmount: 8,
                        tickInterval: 1,
                        align: "right",
                        x: 0,
                    },
                },
                {},
                {
                    title: { text: "P&L" },
                    tickAmount: 4,
                    top: "80%",
                    opposite: false,
                    height: "20%",
                    tickPixelInterval: 1,
                    labels: {
                        formatter: function () {
                            return this.value.toFixed(3) + "%";
                        },
                    },
                    offset: 0,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: cumPnl ? [...cumPnl, ...series_data_for_daily_pnl] : [],
        };

        const chart_configuration_for_security_page = {
            ...simChartCommonConfig,
            chart: {
                ...simChartCommonConfig.chart,
                events: {
                    load: function (e) {
                        try {
                            const minX = e.target.xAxis[0].min;
                            const maxX = e.target.xAxis[0].max;
                            const startDate = moment(
                                new Date(minX).toISOString()
                            ).format("YYYY-MM-DD");
                            const endDate = moment(
                                new Date(maxX).toISOString()
                            ).format("YYYY-MM-DD");
                            const dateRange = { startDate, endDate };
                            onChangePeriod(dateRange);
                        } catch (err) {
                            console.log(err);
                        }
                    },
                },
            },
            tooltip: {
                useHTML: true,
                formatter: tradeChartTooltipFormatter,
            },
            xAxis: {
                ...simChartCommonConfig.xAxis,
                events: {
                    afterSetExtremes: function (e) {
                        try {
                            const rangeSelected =
                                e.target.chart.rangeSelector.selected;
                            const startDate = moment(
                                new Date(e.min).toISOString()
                            ).format("YYYY-MM-DD");
                            const endDate = moment(
                                new Date(e.max).toISOString()
                            ).format("YYYY-MM-DD");
                            const dateRange = { startDate, endDate };
                            onChangePeriod(dateRange);
                        } catch (error) {
                            console.log(error);
                        }
                    },
                },
                crosshair: { width: 1, color: "#aaa", dashStyle: "shortDash" },
            },
            yAxis: [
                {
                    height: "80%",
                    tickerAmount: 8,
                    title: { text: "Price" },
                    opposite: false,
                    crosshair: {
                        width: 1,
                        color: "#aaa",
                        dashStyle: "shortDash",
                    },
                },
                {
                    height: "80%",
                    tickerAmount: 8,
                    title: { text: "Cumulative P&L" },
                    crosshair: {
                        width: 1,
                        color: "#aaa",
                        dashStyle: "shortDash",
                    },
                    labels: {
                        formatter: hc_rebase(3),
                        tickAmount: 8,
                        tickInterval: 1,
                        align: "left",
                        x: 0,
                    },
                },
            ],
            series: [
                ...(cumPnl || []).map((item, idx) => ({
                    ...item,
                    fillOpacity: 0.1,
                    yAxis: 1,
                    turboThreshold: 0,
                    lineWidth: 1,
                    ...(idx === 0
                        ? {
                              tooltip: {
                                  valueDecimals: 4,
                                  pointFormatter: function (e) {
                                      const reseted = cumPnl[0].data.filter(
                                          (ele) =>
                                              ele[0] > this.series.xAxis.min
                                      );
                                      if (reseted.length > 0) {
                                          const startY = reseted[0][1];
                                          return (
                                              "Cumulative P&L: " +
                                              (this.y - startY).toFixed(4) +
                                              "%"
                                          );
                                      } else {
                                          return (
                                              "Cumulative P&L: " + this.y + "%"
                                          );
                                      }
                                  },
                              },
                          }
                        : {}),
                })),
                // ...(trade_series.length > 0 ? trade_series : series_transactions)
                ...series_transactions,
            ],
        };

        const chart_configuration_for_daily_pnl = {
            ...simChartCommonConfig,
            series: dailyPnl,
            yAxis: { labels: { formatter: hc_yaxis_percentage_formatter() } },
        };

        const chart_configuration_for_exposure = {
            ...simChartCommonConfig,
            series: exposure,
        };

        const chart_wrapper_key = "chart_id_" + idx;

        if (!hasData) {
            // Loading Page
            return (
                <div className="flexBox flexBox_center">
                    <LoadingBar text="Loading Chart ..." />
                </div>
            );
        } else {
            switch (idx) {
                // ===========================================================
                // ---+-- Chart.1 Multi-Chart (PnL + PX_LAST + Trades) ---+---
                // ===========================================================
                case 0: {
                    let chart_configuration = isPortfolio
                        ? chart_configuration_for_portfolio_page
                        : chart_configuration_for_security_page;
                    return (
                        <div
                            className="ChartWrapper ChartWrapper_loose"
                            key={chart_wrapper_key}
                        >
                            <div className="title">
                                <HeaderWithDownload
                                    key={stateChangeCounter}
                                    title="Cumulative P&L"
                                    filename="cumulative_pnl"
                                    data={cumPnl}
                                    needConvert
                                />
                                <div>
                                    {cumPnl ? (
                                        <DynamicStatsBar ref="stats_ref" />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <ReactHighstock config={chart_configuration} />
                        </div>
                    );
                }
                // =================================
                // --+-- Chart.2 Annual Return --+--
                // =================================
                case 1:
                    return <TabAnnualCumulativeReturn series={seasonCumPnl} />;
                // =================================
                // --+-- Chart.3 Sector Return --+--
                // =================================
                case 2:
                    return (
                        <TabBySector
                            data={{
                                totalBySectorData: pnlBySector,
                                secBySectorData: secPnlBySector,
                            }}
                            showStats={true}
                            isPerc={true}
                            rebase={true}
                            title="Performance By Sector"
                        />
                    );
                case 3:
                    return (
                        <TabBySector
                            data={{
                                totalBySectorData: nExpBySector,
                                secBySectorData: secNexpBySector,
                            }}
                            showStats={false}
                            title="Net Exposure By Sector"
                        />
                    );
                case 4:
                    return (
                        <TabBySector
                            data={{
                                totalBySectorData: gExpBySector,
                                secBySectorData: secGexpBySector,
                            }}
                            showStats={false}
                            rebase={true}
                            title="Gross Exposure By Sector"
                        />
                    );
                // =============================
                // --+-- Chart.5 Net Pnl --+--
                // =============================
                case 5:
                    return (
                        <div className="ChartWrapper" key={"chart_id_" + idx}>
                            <div className="title">
                                <HeaderWithDownload
                                    title="P&L"
                                    filename="daily_pnl"
                                    data={dailyPnl}
                                    needConvert
                                />
                            </div>

                            <ReactHighstock
                                config={chart_configuration_for_daily_pnl}
                            />
                        </div>
                    );
                // =============================
                // --+--  Chart.6 Exposure --+--
                // =============================
                case 6:
                    return (
                        <div className="ChartWrapper" key={"chart_id_" + idx}>
                            <div className="title">
                                {" "}
                                <HeaderWithDownload
                                    title="Exposure"
                                    filename="exposure"
                                    data={exposure}
                                    needConvert
                                />
                            </div>

                            <ReactHighstock
                                config={chart_configuration_for_exposure}
                            />
                        </div>
                    );
                // =============================
                // --+--  Diagram.7 Trade Table --+--
                // =============================
                // case 7:
                //     return (
                //         <div className="ChartWrapper" key={"chart_id_" + idx}>
                //             <HeaderWithDownload title="Trades" />
                //             <div style={{ overflow: "auto" }}>
                //                 <ImbueTable
                //                     config={{
                //                         data: tradesData.map((ele, idx) => ({
                //                             ...ele,
                //                             id: idx + 1,
                //                             direction: (
                //                                 <span
                //                                     className="tag"
                //                                     style={{
                //                                         background: ele.direction === "long" ? COLORMAP.green : COLORMAP.red
                //                                     }}
                //                                 >
                //                                     {ele.direction}
                //                                 </span>
                //                             ),
                //                             netpnl: (
                //                                 <span
                //                                     style={{
                //                                         color: ele.netpnl >= 0 ? COLORMAP.green : COLORMAP.red
                //                                     }}
                //                                 >
                //                                     {(ele.netpnl * 1).toFixed(2)} %
                //                                 </span>
                //                             )
                //                         })),
                //                         sorted: ["id", "ticker", "direction", "open_date", "close_date", "open_px", "close_px", "open_contract", "contract_value", "netpnl"],
                //                         onSorted: true
                //                     }}
                //                 />
                //             </div>
                //         </div>
                //     );
                case 8:
                    return (
                        <div className="ChartWrapper" key={"chart_id_" + idx}>
                            <HeaderWithDownload title="Transactions" />
                            <div style={{ overflow: "auto" }}>
                                <ImbueTable
                                    config={{
                                        data: transactionsData,
                                        density: "tight",
                                        sorted: [
                                            "date",
                                            "amount",
                                            "cumAmount",
                                            "price",
                                            "value",
                                        ],
                                        onSorted: true,
                                    }}
                                />
                            </div>
                        </div>
                    );
                case 9: // feature importance
                    return (
                        <div className="ChartWrapper" key={"chart_id_" + idx}>
                            <HeaderWithDownload title="Feature Importance" />
                            <div style={{ overflow: "auto" }}>
                                <TabFeatureImportance data={bulkData} />
                            </div>
                        </div>
                    );
                default:
                    return <div />;
            }
        }
    }

    render() {
        const dailyPnl = this.state.dailyPnl;
        const cumPnl = this.state.cumPnl;
        const seasonCumPnl = this.state.seasonCumPnl;
        const pnlBySector = this.state.pnlBySector;
        const nExpBySector = this.state.nExpBySector;
        const gExpBySector = this.state.gExpBySector;
        const exposure = this.state.exposure;
        const multicumpnl = this.state.multicumpnl;

        const hasCommision = this.state.hasCommision;
        const chartIndex = this.state.chartIndex;

        const transactionsData = this.state.transactionsData;

        const { title } = this.props;

        // --+-- Prepare Data for Button --+--
        const chartsInfo = [
            { id: 0, name: "Cumulative Return", data: cumPnl },
            { id: 1, name: "Annual Cumulative Return", data: seasonCumPnl },
            { id: 5, name: "Net Return", data: dailyPnl },
            { id: 8, name: "Transactions", data: transactionsData },
            // { id: 9, name: "Feature Importance", data: [] }
        ];

        const pariInfo = parsePairname(title);
        const displayTitle =
            TickerDict[title] ||
            (pariInfo.isPair
                ? pariInfo.pair1 +
                  " / " +
                  pariInfo.pair2 +
                  " (" +
                  { "-": "spread", "%": "ratio" }[pariInfo.type] +
                  ")"
                : title);

        return (
            <div className="MainWrapper">
                {/* SECTION | header */}
                <div className="HeaderWrapper">
                    <div className="title_wrapper">
                        <div className="flexBox flexBox_between">
                            <div className="flexBox">
                                {/* header :: title */}
                                <div className="title">{displayTitle}</div>
                                {/* header :: bulk data downloader (optional) */}
                                {multicumpnl ? (
                                    <CSVLink
                                        className="csvlink"
                                        data={multicumpnl}
                                        filename={"allpnl.csv"}
                                    >
                                        <span
                                            style={{
                                                fontSize: "1.1rem",
                                                marginRight: "10px",
                                                color: "var(--color-darkgold)",
                                            }}
                                        >
                                            <i className="fas fa-download" />{" "}
                                            P&L
                                        </span>
                                    </CSVLink>
                                ) : (
                                    ""
                                )}
                            </div>
                            {/* header :: last update datetime */}
                            <div>
                                <div
                                    className="subtitle"
                                    title={toAusTime(this.props.created, 1)}
                                >
                                    <i className="fa fa-clock" />{" "}
                                    {moment(this.props.created * 1000).format()}{" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* SECTION MIDDLE | overall statistics */}
                <div className="StatsWrapper">
                    <div style={{ width: "100%" }}>
                        <StatsBar statistics={this.state.statistics} />
                    </div>
                </div>

                <ImbueTabs
                    options={chartsInfo.map((ele) => ({
                        label: ele.name,
                        value: ele.id,
                    }))}
                    selectedValue={this.state.chartIndex}
                    onClick={(val) => {
                        if (
                            chartsInfo[chartsInfo.map((i) => i.id).indexOf(val)]
                                .data
                        ) {
                            this.onChangeChart(val);
                        }
                    }}
                />

                {/* SECTION MAIN | chart body */}
                {chartsInfo.filter((ele) => ele.id === chartIndex)[0][
                    "data"
                ] ? ( // data is not null
                    this.renderChart(this.state.chartIndex)
                ) : (
                    <div className="ChartWrapper">
                        <div className="LoadingText">No Data</div>
                    </div>
                )}
            </div>
        );
    }
}
