import React, { useState, useEffect, useRef } from 'react';
import ImbueSelect from '../../components/input/ImbueSelect';
import { LoadingPage } from '../../helpers/SimpleComponents';
import HighStockWrapper from '../../components/chart/HighStockWrapper';
import { _exchange_symbol_lookup } from '../../universalSymbolMap';
import './style.css';

const todayDate = new Date();

var wsUrl = ( window.location.protocol === 'https:'
    ? "wss://app.cbot.comatl.com:49534/"
    : "ws://app.cbot.comatl.com:49533/"
);

const socket = new WebSocket(wsUrl);

// filter out assets with data unavailable, then format to fit into Select
const globexNameMap = Object.keys(_exchange_symbol_lookup).filter((key) => ('v9' in _exchange_symbol_lookup[key])).map((key) => ({
    'label': _exchange_symbol_lookup[key]['title'],
    'value' : _exchange_symbol_lookup[key]['exchange']
}));

// format of arrays returned in "minute" websocket messages
const msgIndex = {
    "date": 0,
    "session": 1,
    "open": 2,
    "high": 3,
    "low": 4,
    "last": 5,
    "vwap": 6,
    "qty": 7,
    "ba_qty": 8,
    "sa_qty": 9,
    "volume": 10,
    "trading": 11,
    "bid_px": 12,
    "ask_px": 13,
    "hittake": 14,
};

let allSymbolsByAsset = {}; // map asset key: [ ["symbol", metadata..], ... ]

document.title = "Symbol Searcher";

const SymbolSearcher = () => {

    const [asset, setAsset] = useState(null); // root globex symbols for a comdty
    const [symbolOptions, setSymbolOptions] = useState(null);
    const [symbol, setSymbol] = useState(null); // specific contract symbol for the current asset
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    // useRef hook needed to work with websocket onmessage setup in initial useEffect hook
    const assetRef = useRef(asset);
    const symbolRef = useRef(symbol);


    // get list of all available contract symbols for the currently selected asset
    useEffect(() => {
        assetRef.current = asset;

        document.title = "Symbol Searcher";

        setData(null);
        setSymbolOptions(null);
        setSymbol(null);

        if (asset) {
            setIsLoading(true);

            socket.send(JSON.stringify({
                "type": "start", 
                "source": "symbols", 
                "authorization": "Bearer ".concat(localStorage.getItem("_token")), 
                "args": {"asset": asset['value']}
            }));
        }

    }, [asset]);


    // triggered when a new symbol is selected
    useEffect(() => {
        symbolRef.current = symbol;

        setData(null);

        if (asset && symbol) {
            setIsLoading(true);

            document.title = `Symbol Searcher: ${symbol.label}`;

            socket.send(JSON.stringify({
                "type": "start", 
                "source": "minute", 
                "authorization": "Bearer ".concat(localStorage.getItem("_token")), 
                "args": {"symbol": symbol['value']}
            }));
        }

    }, [symbol]);


    // websocket setup/cleanup, called when component mounts/unmounts 
    useEffect(() => {
        socket.onmessage = (evt) => {
            const json = JSON.parse(evt.data);
            console.log(json);

            // this onmessage callback is setup on intial component mount when both asset/symbol are null
            //   need to use useRef hook to update asset/symbol refs to not be stuck null here
            const curAsset = assetRef.current;
            const curSymbol = symbolRef.current;
            try {
                if (json['type'] && json['type'] === 'data') {
                    if (curAsset && json['source'] === 'symbols') {
                    // list of all available contract symbols
                    // format: [0: "symbol", 1: "asset", 2: "type", 3: "spread_type", 4: "month_yr", 5: "expiry"]
                        // update master map
                        allSymbolsByAsset[curAsset['value']] = json['data'];
                        // ignore expired contracts
                        const activeSymbols = allSymbolsByAsset[curAsset['value']].filter((ele) => new Date(ele[5]) > todayDate);
                        setSymbolOptions(activeSymbols.map((ele) => (
                            {'label': ele[0], 'value': ele[0]} // format for Select
                        )));
                        // see if current asset's front month is available to show as default
                        const curActive = _exchange_symbol_lookup[curAsset['value']]['active']['symbol'];
                        if (activeSymbols.map((ele) => (ele[0])).includes(curActive)) {
                            setSymbol({'label': curActive, 'value': curActive});
                        }
                    } else if (curSymbol && json['source'] === "minute") {
                    // list of minute-data for the selected contract
                        if (json['response'] === 0) {
                        // initial full intraday data
                            setData(json['data']);
                        } else if (json['response'] > 0) {
                        // subsequent latest minute update
                            setData(prevData => [...prevData, json['data'][0]]);
                        }
                    }
                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        return () => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
            }
        };
    }, []);


    const configCandlestickChart = () => {
        let res = {
            chart: {
                height: (9 / 16 * 100) + '%' // 16:9 ratio 100%
            },
            title: {
                text: `${symbol.label} : Latest Price`
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                buttons: [
                    {
                        type: 'minute',
                        count: 180,
                        text: '3h'
                    },
                    {
                        type: 'minute',
                        count: 720,
                        text: '12h'
                    },
                    {
                        type: 'minute',
                        count: 1440,
                        text: '1d'
                    },
                    {
                        type: 'minute',
                        count: 4320,
                        text: '3d'
                    },
                    {
                        type: 'all',
                        text: 'All'
                    }
                ],
                selected: null, // No button selected by default
            },
            scrollbar: {
                enabled: true
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: { 
                series: {
                    animation: false,
                    turboThreshold: 3000,
                    dataGrouping: {
                        enabled: true,
                        units: [
                            ['minute', [5,10,15,30,60]]
                        ]
                    },
                    // connectNulls: true
                },
            },
            xAxis: {
                type: 'datetime',
                // minRange: 5 * 60 * 1000 // 5-min
            },
            yAxis: [
                { 
                    title: { text: "Price" },
                    offset: 20,
                    // opposite: false,
                    height: '70%',
                },
                {
                    title: { text: "volume"},
                    offset: 20,
                    top: '70%',
                    height: '30%'
                },
            ],
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "price",
                    type: "candlestick",
                    yAxis: 0,
                    data: data.map((ele) => ({
                        x: Date.parse("US/Chicago "+ele[msgIndex['date']]),
                        open: ele[msgIndex['open']] !== null ? ele[msgIndex['open']] : ele[msgIndex['last']],
                        high: ele[msgIndex['high']],
                        low: ele[msgIndex['low']],
                        close: ele[msgIndex['last']],
                        color: ele[msgIndex['last']] < ele[msgIndex['open']] ? '#ce5042' : '#39ace7', // red (-) : blue (+)
                    })),
                },
                {
                    name: "Volume",
                    type: "column",
                    color: "rgba(144,237,125,255)",
                    yAxis: 1,
                    data: data.map((ele) => ( [Date.parse("US/Chicago "+ele[msgIndex['date']]), ele[msgIndex['volume']]] )),
                },
                
            ],
        };

        return res;
    }


    return (
        <div>
            <div className='row' style={{margin: '20px'}}>

                { globexNameMap &&
                    <ImbueSelect
                        title="asset"
                        options={globexNameMap}
                        value={asset}
                        onChange={(e) => {
                            setAsset(e);
                        }}
                    />
                }

                { symbolOptions && 
                    <ImbueSelect
                        title={`${asset.label} symbols`}
                        options={symbolOptions}
                        value={symbol}
                        onChange={(e) => {
                            setSymbol(e);
                        }}
                    />
                }

            </div>
            
            { isLoading && <div>{LoadingPage()}</div>}
            { symbol && data && data.length > 0 && !isLoading && 
                <div>
                    <HighStockWrapper config={configCandlestickChart()} />
                </div>
            }
        </div>
    );
}

export default SymbolSearcher;