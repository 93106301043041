import React, { Component } from "react";
import MainBoard from "../../layout/MainBoard";
import Tweets from "./Tweets";

export default class Feeds__mainboard extends Component {

    componentDidMount() {
        document.title = "Feeds";
    }

    render() {
        const list = [{ name: "Home", path: this.props.match.path + "/tweets", component: Tweets }];

        return (
            <div>
                <MainBoard title="Feeds" list={list} />
            </div>
        );
    }
}
