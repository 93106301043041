import React from "react";

// Sub Components
import HomePage from "../screens/HomePage";
import InfraMonitor from "../screens/InfraMonitor/mainboard";
import DataCenter from "../screens/DataCenter/DataCenter__mainboard";
import ResearchAnalytics from "../screens/ResearchCenter/mainboard";
import RiskManager from "../screens/RiskManager/mainboard";
import ImbueAILab from "../screens/ImbueAILab/ImbueAILab__mainboard";
import SimulationWrapper from "../screens/Strategies/StrategyFactory";
import TradeManager from "../screens/TradeManager/TradeManager__mainboard";
import WeatherDashboard from "../screens/WeatherDashboard/WeatherDashboard__mainboard";
import Feeds from "../screens/Feeds/Feeds__mainboard";
// import SimulationHub from "../screens/MainBoards/SimulationHub.mb";
import PortfolioViewer from "../screens/MainBoards/PortfolioViewer.mb";
import GlossaryAndLinks from "../screens/GlossaryAndLinks/mb";
import SystematicStrategies from "../screens/SystematicStrategies/mb";
import HistoricalPerformance from "../screens/ResearchCenter/HistoricalPortfolioView";
import LastestPortfolioTrades from "../screens/ResearchCenter/LatestPorfolioTrades";
// import HistoricalPerformance from "../screens/ResearchCenter/FuncHistoricalPortfolioView";
// import HistoricalPerformanceLegacy from "../screens/ResearchCenter/HistoricalPortfolioView.legacy";
import { get_email } from "../helpers/localstorage";
// import PositioningAnalytics from "../screens/ResearchCenter/PositioningAnalytics";
// add my ContangoComp module (-BG)
import StreamSweep from "../contango/streaming_sweeps/StreamSweep";
import TradingBook from "../contango/book/TradingBook";
import SplitSweeps from "../contango/split/SplitSweeps";
import DefaultBoard from "../quoteBoard/DefaultBoard";
// import ZeusGeneral from "../screens/ImbueAILab/ImbueAILab_Zeus/ImbueAILab_zeus_gr.js" // not sure if this project's backend still exists
// import HighlightsMainboard from "../screens/Highlights/Highlights__mainboard.js";
//import NewHPVChart from "../screens/ResearchCenter/components/NewHPVChart"
// import TradesHeatmap from "../screens/ResearchCenter/components/TradesHeatmap";
// import CommodityReport from "../screens/Commodity/TemplateReport";
// import HermesGeneral from "../screens/ResearchCenter/HermesGeneral";
import PrototypeDashboard from "../screens/ResearchCenter/Dashboard";
import NetDeltaGamma from "../screens/ResearchCenter/NetDeltaGamma";
// import CustomSpreads from "../screens/ResearchCenter/CustomSpreads";
import EnergyBTU from "../screens/ResearchCenter/EnergyBTU";
// import GammaChart from "../screens/ResearchCenter/GammaChart"
import Hydrodynamics from "../screens/ResearchCenter/Hydrodynamics";
import CalendarSpreads from "../screens/ResearchCenter/CalendarSpreads";
import RollsHistory from "../screens/ResearchCenter/RollsHistory";
// import LiveReports from "../screens/ResearchCenter/LiveReports";
import ReportsPage from "../screens/ResearchCenter/ReportsPage";
import SymbolSearcher from "../screens/ResearchCenter/SymbolSearcher";

// helper functions
function BatchSimulationAppsMaker(list) {
    return list.map((ele) => ({
        name: ele.name,
        path: "/" + ele.name.toLowerCase().split(" ").join("_"),
        icon: ele.icon || "fas fa-chart-bar",
        component: () => <SimulationWrapper strategyId={ele.id} strategyName={ele.name} />,
    }));
}

const currentUser = get_email()
const guestviews = {
    // "henry@mileura.com": [
    //     { name: "Positioning", path: "/positioning", icon: " fas fa-window", component: PositioningAnalytics, isSingle: 1 },
    // ]
}

const masterComponents = () => {
	
	
    if (guestviews[currentUser]) {
        return  [{
            apps: [
                { name: "Home", path: "/", icon: " fas fa-home", component: HomePage, exact: true },
                ...guestviews[currentUser]
            ]
        }]
    }else{
        return [
            {
                apps: [
                    { name: "Home", path: "/", icon: " fas fa-home", component: HomePage, exact: true },
					//{ name: "Highlights", path: "/highlights", icon: " fas fa-home", component: HighlightsMainboard, exact: true },
                    { name: "Dashboard", path: "/dashboard", icon: "fas fa-border-all", component: PrototypeDashboard }, 
                    { name: "Quote Board", path: "/quoteboard", icon: "fas fa-th", component: DefaultBoard},
                    { name: "Feeds", path: "/feeds", icon: " fab fa-twitter", component: Feeds },
                    { name: "Weather", path: "/climate_ags", icon: " fas fa-cloud", component: WeatherDashboard },
                    { name: "Data Center", path: "/data_center", icon: " fas fa-database", component: DataCenter },
                    { name: "Sys Monitor", path: "/infra_monitor", icon: " fas fa-monitor-heart-rate", component: InfraMonitor },
                    { name: "Trade Manager", path: "/trade_manager", icon: " fas fa-donate", component: TradeManager },
                    { name: "AI Lab", path: "/imbue_ailab_comdty", icon: " fas fa-rocket", component: ImbueAILab },
                    { name: "Research Analytics", path: "/ra", icon: " fas fa-chart-network", component: ResearchAnalytics },
                    { name: "Risk Manager", path: "/rm", icon: " fas fa-table", component: RiskManager},
                    { name: "Portfolio Center", path: "/pv", icon: " fas fa-columns", component: PortfolioViewer },
                    { name: "Glossary & Links", path: "/gl", icon: " fas fa-book", component: GlossaryAndLinks },
                ],
            },
            {
                section: "Portfolio",
                apps: [
                    { name: "Historical Performance", path: "/historical_performance", icon: " fas fa-book", component: HistoricalPerformance, isSingle: 1 },
                    // { name: "Historical Performance OV", path: "/historical_performance_overview", icon: "fas fa-book", component: HistoricalPortfolioOverview, isSingle: 1}
                    { name: "Latest Trades", path: "/latest_trades", icon: "fas fa-list-ul", component: LastestPortfolioTrades, isSingle: 1}
                ]
            },
            {
                section: "ML Strategies",
                apps: BatchSimulationAppsMaker([
                    { name: "Deltas", id: "Deltas", version: 2 },
                    { name: "Stacked Softs", id: "Stacked Softs", version: 2 },
                    { name: "Stacked Ags", id: "Stacked Ags", version: 2 },
                    { name: "Stacked Equities", id: "equity stacked", version: 2 },
                    { name: "Les Risk Plz", id: "Les Risk Plz", version: 2 },
                    { name: "Metals Rotation", id: "Metals Rotation Slim", version: 2 },
                ]),
            },
            {
                section: "Systematic Strategies",
                apps: [
                    { name: "Systematic Strategies", path: "/ss", icon: " fas fa-solar-system", component: SystematicStrategies },
                    ...BatchSimulationAppsMaker([
                ])],
            },
            {
                section: "Contango",
                apps: [
                    {
                        name: "Streaming Sweeps",
                        path: "/s_sweeps",
                        icon: "fas fa-stream",
                        component: StreamSweep,
                        isSingle: 1
                    },
                    {
                        name: "Trading Book",
                        path: "/book",
                        icon: "fas fa-book-open",
                        component: TradingBook,
                        isSingle: 1
                    },
                    {
                        name: "Split Intraday Sweeps",
                        path: "/split",
                        icon: "fas fa-columns",
                        component: SplitSweeps,
                        isSingle: 1
                    }
                ],
            },
            {
                section: "Experimental",
                apps: [
                    // {
                    //     name: "Bids & Asks",
                    //     path: "/bid_ask_averages",
                    //     icon: "fas fa-fire",
                    //     component: BidAskAverages,
                    //     isbeta: 1
                    // },
                    // {
                    //     name: "Custom Spreads",
                    //     path: "/customSpreads",
                    //     icon: "fas fa-columns",
                    //     component: CustomSpreads,
                    //     isbeta: 1
                    // },
                    {
                        name: "Net Delta Gamma",
                        path: "/net_delta_gamma",
                        icon: "fas fa-columns",
                        component: NetDeltaGamma,
                        isbeta: 1
                    },
                    {
                        name: "Energy BTU",
                        path: "/energyBTU",
                        icon: "fas fa-fire",
                        component: EnergyBTU,
                        isbeta: 1
                    },
                    {
                        name: "Hydrodynamics",
                        path: "/hydrodynamics",
                        icon: "fas fa-faucet-drip",
                        component: Hydrodynamics,
                        isbeta: 1
                    },
                    {
                        name: "Spreads",
                        path: "/calendarspreads",
                        icon: "fas fa-calendar-minus",
                        component: CalendarSpreads,
                        isbeta: 1
                    },
                    {
                        name: "RollHistory",
                        path: "/roll_history",
                        icon: "fas fa-toilet-paper",
                        component: RollsHistory,
                        isbeta: 1
                    },
                    // {
                    //     name: "Live Reports",
                    //     path: "/live_reports",
                    //     icon: "fas fa-bell",
                    //     component: LiveReports,
                    //     isbeta: 1
                    // },
                    {
                        name: "Reports History",
                        path: "/reports_history",
                        icon: "fas fa-bell",
                        // component: IntradayPriceHistory,
                        component: ReportsPage,
                        isbeta: 1
                    },
                    {
                        name: "Symbol Searcher",
                        path: "/symbol_searcher",
                        icon: "fas fa-search",
                        component: SymbolSearcher,
                        isbeta: 1
                    },
                    
                ]
            }
        ];
    }
}

const appComponents = masterComponents()

export { appComponents };
