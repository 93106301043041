import React, { Component } from "react";
import styled from "styled-components";
import { fullAssetsOptions } from "./PositionRisk/Common";
import ImbueSelect from "../../components/input/ImbueSelect";
import { api_spreads } from "../../helpers/API";
import FullscreenWrapper from "../../components/wrapper/FullscreenWrapper";
import { GLOBALSTYLED } from "../../config";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)`
    .chartWrapper {
        background: white;
        padding: 20px;
        box-shadow: var(--boxshadow-aws);
    }

    .fullScreenImgWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
    }

    .fullScreenImgWrapper img {
    }
`;

// -- Options
// -- -- comdty
const comdtyOptions = fullAssetsOptions;
const defaultComdty = comdtyOptions[0];
// -- -- method
const methodOptions = ["difference", "percent"].map((ele) => ({ label: ele, value: ele }));
const defaultMethod = methodOptions[0];
// -- -- first
const fristOptions = ["deferred", "front"].map((ele) => ({ label: ele, value: ele }));
const defaultFirst = fristOptions[0];

class Spreads extends Component {
    state = {
        selectedComdty: defaultComdty.value,
        selectedDeferred: "2",
        selectedMethod: defaultMethod.value,
        selectedFirst: defaultFirst.value,
        isLoading: 0,
        data: [],
        clickedImgIndex: null,
    };

    onUpdate = () => {
        const paras = {
            comdty: this.state.selectedComdty.toUpperCase(),
            deferred: this.state.selectedDeferred,
            method: this.state.selectedMethod,
        };
        this.setState({ isLoading: 1 }, () => {
            api_spreads(paras).then((res) => {
                this.setState({
                    isLoading: 0,
                    data: res.data.content,
                });
            });
        });
    };

    render() {
        console.log(this.state.selectedComdty);
        return (
            <Layout>
                <div className="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="commdity"
                        options={comdtyOptions}
                        defaultValue={defaultComdty}
                        onChange={(e) => this.setState({ selectedComdty: e.value })}
                    />
                    <ImbueSelect
                        title="method"
                        options={methodOptions}
                        defaultValue={defaultMethod}
                        onChange={(e) => this.setState({ selectedMethod: e.value })}
                    />
                    <ImbueSelect
                        title="first"
                        options={fristOptions}
                        defaultValue={defaultFirst}
                        onChange={(e) => this.setState({ selectedFirst: e.value })}
                    />
                    <div className="ImbueSelectItem">
                        <div className="label">deferred:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={this.state.selectedDeferred}
                            onChange={(e) => this.setState({ selectedDeferred: e.target.value })}
                        />
                    </div>
                    <div className="CircleBtn CircleBtnLg" onClick={this.onUpdate}>
                        <i className="fas fa-play"></i>
                    </div>
                </div>
                {this.state.isLoading ? (
                    <div className="LoadingText">Loading...</div>
                ) : (
                    <div>
                        <div className="gridBox gridBox_4" style={{ gridGap: "20px" }}>
                            {this.state.data.map((ele, idx) => {
                                return (
                                    <div className="chartWrapper" key={ele.title + idx}>
                                        <img
                                            onClick={() => {
                                                this.setState({
                                                    clickedImgIndex: idx,
                                                });
                                            }}
                                            width="100%"
                                            src={"data:image/png;base64," + ele.fig}
                                            alt=""
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        <FullscreenWrapper
                            {...{
                                enabled: this.state.clickedImgIndex !== null,
                                onClick: () => this.setState({ clickedImgIndex: null }),
                                component: (
                                    <img
                                        src={
                                            this.state.data.map((ele) => "data:image/png;base64," + ele.fig)[
                                                this.state.clickedImgIndex
                                            ]
                                        }
                                        alt=""
                                    />
                                ),
                            }}
                        />
                    </div>
                )}
            </Layout>
        );
    }
}

export default Spreads;
