import { URL } from "../config";
import decode from "jwt-decode";

// User Login
const UserLogin = (usr, pwd) => {
    if (usr !== undefined && pwd !== undefined) {
        let result = fetch(URL + "/login/", {
            method: "POST",
            body: JSON.stringify({
                request: "user_log_in",
                content: {
                    email: usr,
                    password: pwd
                }
            })
        });
        return result;
    }
};

// User Signup
const UserSignup = (usr, pwd) => {
    if (usr !== undefined && pwd !== undefined) {
        let result = fetch(URL + "/signup/", {
            method: "POST",
            body: JSON.stringify({
                request: "new_user_signup",
                content: {
                    email: usr.toLowerCase(),
                    password: pwd
                }
            })
        });
        return result;
    }
};

const ChangePassword = (usr, pwd, newPwd) => {
    if (usr !== undefined && pwd !== undefined) {
        return fetch(URL + "/pwchange/", {
            method: "POST",
            // headers: new Headers({
            //     'Authorization': 'Basic ' + btoa(usr.toLowerCase() + ':' + pwd),
            //     'Content-Type': 'application/json'
            // }),
            body: JSON.stringify({
                email: usr,
                password: pwd,
                new_password: newPwd
            })
        });
    }
};

// User Logout
const UserLogout = token => {
    if (token !== undefined) {
        const AuthStr = "Bearer ".concat(token);
        fetch(URL + "/logout/", {
            method: "GET",
            headers: {
                Authorization: AuthStr
            },
            mode: "cors"
        });
    }
};

export function validateUserType() {
    let token = getToken();
    let emailInToken = "";
    try {
        emailInToken = decode(token)["sub"];
    } catch (err) {
        console.log(err);
    }

    if (token) {
        const ANALYSTS = ["claude.chen@imbuecapital.com", "ciaron.mckinley@imbuecapital.com", "les.finemore@imbuecapital.com", "colton.smith@imbuecapital.com", "tom.bowles@imbuecapital.com"];
        if (ANALYSTS.indexOf(emailInToken) >= 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function setToken(token) {
    localStorage.setItem("_token", token);
}

export function getToken() {
    let token = localStorage.getItem("_token");
    return token;
}

export function getIp() {
    let ip = sessionStorage.getItem("_client_ip");
    return ip;
}

export function signout() {
    let token = localStorage.getItem("_token");
    UserLogout(token);
    localStorage.removeItem("_token");
    window.location.replace("/login");
}

export { UserLogin, UserSignup, UserLogout, ChangePassword };
