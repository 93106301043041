import React, { Component } from "react";
import { api_couchdb } from "../../../helpers/API";
import { highchartBasicConfig, COLORMAP } from "../../../config";
import RcHighchart from "react-highcharts";
import ImbueSelect from "../../../components/input/ImbueSelect";
import { toUnique } from "../../../helpers/Utils";

const demoTsDb = {
    sim_queue: [
        ["2020-02-01 08:00:00", 23],
        ["2020-02-01 08:01:00", 2],
        ["2020-02-01 08:02:00", 30],
        ["2020-02-01 08:03:00", 23],
        ["2020-02-01 08:04:00", 10],
        ["2020-02-01 08:05:00", 13],
        ["2020-02-01 08:06:00", 23]
    ],
    feat_queue: [
        ["2020-02-01 08:00:00", 3],
        ["2020-02-01 08:01:00", 20],
        ["2020-02-01 08:02:00", 33],
        ["2020-02-01 08:03:00", 2],
        ["2020-02-01 08:04:00", 19],
        ["2020-02-01 08:05:00", 32],
        ["2020-02-01 08:06:00", 8]
    ],
    other_queue: [
        ["2020-02-01 08:00:00", 10],
        ["2020-02-01 08:01:00", 11],
        ["2020-02-01 08:02:00", 14],
        ["2020-02-01 08:03:00", 18],
        ["2020-02-01 08:04:00", 13],
        ["2020-02-01 08:05:00", 15],
        ["2020-02-01 08:06:00", 11]
    ]
};

class StatusChart extends Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
    }

    componentDidMount() {
        const data = demoTsDb[this.props.tsid];
        this.setState({ data });
    }

    render() {
        const config = ts => {
            return {
                ...highchartBasicConfig,
                chart: {
                    ...highchartBasicConfig.chart,
                    height: "150px"
                },
                legend: { enabled: false },
                xAxis: {
                    categories: ts.map(ele => ele[0])
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [
                    {
                        name: "count",
                        type: "column",
                        data: ts.map(ele => ele[1]),
                        color: COLORMAP.minor
                    }
                ]
            };
        };
        return <RcHighchart config={config(this.state.data)} />;
    }
}

export default class SM_StatusCharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            demolist: [
                {
                    queuename: "Sim Queue",
                    timeseries: [
                        ["2020-02-01 08:00:00", 23],
                        ["2020-02-01 08:01:00", 2],
                        ["2020-02-01 08:02:00", 30],
                        ["2020-02-01 08:03:00", 23],
                        ["2020-02-01 08:04:00", 10],
                        ["2020-02-01 08:05:00", 13],
                        ["2020-02-01 08:06:00", 23]
                    ]
                },
                {
                    queuename: "Feature Queue",
                    timeseries: [
                        ["2020-02-01 08:00:00", 3],
                        ["2020-02-01 08:01:00", 20],
                        ["2020-02-01 08:02:00", 33],
                        ["2020-02-01 08:03:00", 2],
                        ["2020-02-01 08:04:00", 19],
                        ["2020-02-01 08:05:00", 32],
                        ["2020-02-01 08:06:00", 8]
                    ]
                },
                {
                    queuename: "Other Queue",
                    timeseries: [
                        ["2020-02-01 08:00:00", 10],
                        ["2020-02-01 08:01:00", 11],
                        ["2020-02-01 08:02:00", 14],
                        ["2020-02-01 08:03:00", 18],
                        ["2020-02-01 08:04:00", 13],
                        ["2020-02-01 08:05:00", 15],
                        ["2020-02-01 08:06:00", 11]
                    ]
                }
            ],
            list: []
        };
    }

    componentDidMount() {
        api_couchdb("imbuecloud_index_tables", "sys_monitor_index").then(res => {
            const list = res.data.content.index.status;
            console.log(list);
            this.setState({
                list
            });
        });
    }

    render() {
        const list = this.state.list;
        const config = ts => {
            return {
                ...highchartBasicConfig,
                chart: {
                    ...highchartBasicConfig.chart,
                    height: "150px"
                },
                legend: { enabled: false },
                xAxis: {
                    categories: ts.map(ele => ele[0])
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [
                    {
                        name: "count",
                        type: "column",
                        data: ts.map(ele => ele[1]),
                        color: COLORMAP.minor
                    }
                ]
            };
        };

        const groupList = toUnique(list.map(ele => ele.group));

        return (
            <div>
                <div className="WarningPanel">Coming soon...</div>
            </div>
        );

        // return (
        //     <div>
        //         <div>
        //             <div>
        //                 <ImbueSelect options={groupList.map(ele => ({ label: ele, value: ele }))} />
        //             </div>
        //         </div>
        //         {list.map((item, idx) => {
        //             return (
        //                 <div key={idx}>
        //                     <div className="ImbueCard_section_title">{item.label}</div>
        //                     <StatusChart tsid={item.tsid} />
        //                     {/* <RcHighchart config={config(item.timeseries)} /> */}
        //                 </div>
        //             );
        //         })}
        //     </div>
        // );
    }
}
