// Functions to add cusomised buttons to Highchart
// All functions should be placed in highchartConfig.chart.events.load

const COMMMONBUTTONATTR = {
    padding: 5,
    zIndex: 9999,
};

// Add two buttons: normal | logarithmic
// Users can switch between normal and logarithmic scale
export const addLogarithmic = function (e) {
    const chartObject = e.target;
    [
        ["Normal", 0, 40],
        ["Logarithmic", 60, 40],
    ].map((item) => {
        chartObject.renderer
            .button(item[0], item[1], item[2])
            .attr(COMMMONBUTTONATTR)
            .on("click", function () {
                chartObject.update(
                    {
                        yAxis: {
                            type: {
                                Normal: undefined,
                                Logarithmic: "logarithmic",
                            }[item[0]],
                        },
                    },
                    undefined,
                    undefined,
                    false
                );
            })
            .add();
    });
};

//
export const addLegendSelect = function (e) {
    const chartObject = e.target;
    chartObject.renderer
        .button("Show All", 60, 30)
        .attr(COMMMONBUTTONATTR)
        .on("click", function () {
            console.log(chartObject);
            for (let s of chartObject.series) {
                if (s.visible === false) {
                    s.show();
                }
            }
        })
        .add();
    chartObject.renderer
        .button("Hide All", 120, 30)
        .attr({
            padding: 5,
            zIndex: 999,
        })
        .on("click", function () {
            console.log(chartObject);
            for (let s of chartObject.series) {
                if (s.visible === true) {
                    s.hide();
                }
            }
        })
        .add();
};

// # 3d chart views
// Add buttons to switch among three angles in 3d chart only
export const addViewAngles = (registerChart) =>
    function (e) {
        const chartObject = e.target;
        registerChart(chartObject);
        [
            ["Front", 0, 0, 0],
            ["Right", 30, 0, 90],
            ["Top", 60, 90, 0],
        ].map((item) => {
            chartObject.renderer
                .button(item[0], 0, item[1])
                .attr(COMMMONBUTTONATTR)
                .on("click", function () {
                    chartObject.update(
                        {
                            chart: {
                                options3d: {
                                    alpha: item[2],
                                    beta: item[3],
                                },
                            },
                        },
                        undefined,
                        undefined,
                        false
                    );
                })
                .add();
        });
    };
