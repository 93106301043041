import React, { Component } from "react";
import { api_interpretability } from "../../../helpers/API";
import styled from "styled-components";
import { COLORMAP, highchartBasicConfig, highchartSimpleConfig } from "../../../config";
import HighChart from "../../../components/chart/HighChartWrapper";

const Layout = styled.div`
    font-family: var(--font-main);
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    font-size: 1.2rem;
    box-shadow: var(--boxshadow-smooth);
    .title_layout {
        margin-bottom: 8px;
    }

    .signal_layout {
        display: flex;
        align-items: center;
    }

    .chart_layout {
    }
`;

export default class LimeVisual extends Component {
    state = {
        data: null,
        updated: "",
        pred_proba: [],
        lime_exp: [],
        isLoading: 1,
    };

    componentDidMount() {
        api_interpretability({ query: "lime", strategyname: this.props.strategyname }).then((res) => {
            const updated = res.data.docid.split(".").reverse()[0];
            let pred_proba = res.data.data.pred_proba;
            let pred_proba_max_index = pred_proba.map((ele) => ele.proba).indexOf(Math.max(...pred_proba.map((ele) => ele.proba)));
            pred_proba = pred_proba.map((ele, idx) => ({
                ...ele,
                isMax: idx === pred_proba_max_index,
            }));
            const lime_exp = Object.keys(res.data.data.lime_exp)
                .map((k) => [k, res.data.data.lime_exp[k]])
                .sort((a, b) => b[1] - a[1]);
            this.setState({ data: res.data.data, updated: updated, pred_proba, lime_exp, isLoading: 0 });
        });
    }

    render() {
        const updated = this.state.updated;
        const data = this.state.data;
        const lime_exp = this.state.lime_exp;
        const pred_proba = this.state.pred_proba;

        const SIGNALCOLOR = {
            Short: COLORMAP.red,
            Long: COLORMAP.green,
        };

        const chartconfig = {
            ...highchartSimpleConfig,
            xAxis: { categories: lime_exp.map((ele) => ele[0]) },
            legend: { enabled: 0 },
            series: [
                {
                    type: "bar",
                    data: lime_exp.map((ele) => ({
                        color: ele[1] > 0 ? COLORMAP.green : COLORMAP.red,
                        y: ele[1] * 100,
                    })),
                },
            ],
        };

        return (
            <Layout>
                {this.state.isLoading ? (
                    <div className="LoadingText">loading {this.props.strategyname}...</div>
                ) : (
                    <div>
                        <div className="title_layout">
                            <div className="flexBox flexBox_between">
                                <div>Strategy: {this.props.strategyname}</div>
                                <div>Updated: {updated}</div>
                            </div>
                        </div>
                        <div className="signal_layout">
                            <div style={{ marginRight: "10px" }}>signal: </div>
                            {pred_proba.map((ele) => {
                                return (
                                    <div
                                        style={{
                                            color: ele.isMax ? "#fff" : SIGNALCOLOR[ele.class],
                                            marginRight: "10px",
                                            background: ele.isMax ? SIGNALCOLOR[ele.class] : undefined,
                                            padding: "5px",
                                        }}
                                    >
                                        <small>{ele.class}:</small> <span>{(ele.proba * 100).toFixed(3)}%</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="chart_layout highchartResponsiveWrapper">
                            <HighChart config={chartconfig} />
                        </div>
                    </div>
                )}
            </Layout>
        );
    }
}
