import React, { Component } from "react";
import styled from "styled-components";

const Layout = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid #ccc;
    transition: all 0.4s;
    background: #fff;

    &:hover {
        background: rgb(250, 250, 245);
    }

    .icon {
        padding: 5px 10px;
    }

    input {
        font-family: var(--font-main);
        color: #222;
        font-size: 1.2rem;
        transition: all 0.3s;
    }
`;

/**
 * 
 * Generic Search Box Component
 * 
 * <ImbueSearchBox
        key={"security-input"}
        placeholder="Type security ..."
        onValueChange={onValueChange}
        afterKeyPress={(e) => {
            if (e.key === "Enter") {
                this.getAnalytics()
            }
        }}
    />
 * 
 */
export default class ImbueSearchBox extends Component {
    render() {
        const { key, defaultValue, afterKeyPress, placeholder, onValueChange } = this.props;

        return (
            <Layout>
                <div className="icon">
                    <i className="fas fa-search" />
                </div>
                <input
                    key={key}
                    type="text"
                    defaultValue={defaultValue}
                    placeholder={placeholder || ""}
                    onChange={onValueChange}
                    onKeyPress={afterKeyPress}
                />
            </Layout>
        );
    }
}
