import React, { Component } from "react";
import { ChartWrapper } from "../styled/main";
import HeaderWithDownload from "../../text/HeaderWithDownload";
import ReactHighstock from "react-highcharts/ReactHighstock";
import { simChartCommonConfig } from "../Config";
import { toUnique } from "../../../helpers/Utils";
import { multilineTooltipFormatter, hc_yaxis_percentage_formatter, multilinePercTooltipFormatter } from "../../../helpers/ChartFunctions";
import moment from "moment";
import styled from "styled-components";

const Layout = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80px calc(100% - 80px);
`;

export default class TabSecurityPnlBySector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_index: 0
        };
        this.onChangeIndex = this.onChangeIndex.bind(this);
    }

    onChangeIndex(newIndex) {
        this.setState({ selected_index: newIndex });
    }

    render() {
        // === props ===
        const series = this.props.series;
        const config = this.props.config;
        const isPerc = config.isPerc;
        const rebase = config.rebase;

        console.log(this.props, this.state);

        // === state ===
        const selected_index = this.state.selected_index;

        // variables
        const list_of_categories = toUnique(series.map(item => item.sector)).sort();
        const selected_series = series.filter(ele => ele.sector === list_of_categories[selected_index]).map(ele => ele.series);
        const rebaseFunc = (startDate, endDate, chart) => {
            if (selected_series.length > 20) {
                return null;
            }
            let newSeries = [];
            for (let i = 0; i < selected_series.length; i++) {
                const data = selected_series[i].data;
                let pivot = data.filter(ele => ele[0] >= Date.parse(startDate));
                let pivotValue = 0;
                let pivotDate = 0;
                if (pivot.length > 0) {
                    pivotDate = pivot[0][0];
                    pivotValue = pivot[0][1];
                }
                let newData = [];
                for (let j = 0; j < data.length; j++) {
                    newData.push([data[j][0], data[j][1] - pivotValue]);
                }
                newSeries.push({ data: newData });
            }
            for (let i = 0; i < chart.series.length; i++) {
                if (newSeries[i]) {
                    chart.series[i].setData(newSeries[i].data);
                }
            }
        };

        let chart_configuration = {
            ...simChartCommonConfig,
            chart: {
                ...simChartCommonConfig.chart
            },
            series: selected_series,
            tooltip: {
                ...simChartCommonConfig.tooltip,
                useHTML: true,
                formatter: multilineTooltipFormatter,
                shared: true
            },
            xAxis: {
                ...simChartCommonConfig.xAxis
            },
            yAxis: {
                labels: { formatter: hc_yaxis_percentage_formatter() }
            }
        };

        if (isPerc === true) {
            chart_configuration.tooltip.formatter = multilinePercTooltipFormatter;
        }

        if (rebase === true) {
            chart_configuration.chart.events = {
                load: function(e) {
                    try {
                        const startDate = moment(new Date(e.target.xAxis[0].min).toISOString()).format("YYYY-MM-DD");
                        const endDate = moment(new Date(e.target.xAxis[0].max).toISOString()).format("YYYY-MM-DD");
                        rebaseFunc(startDate, endDate, e.target);
                    } catch (err) {
                        console.log(err);
                    }
                }
            };
            chart_configuration.xAxis.events = {
                afterSetExtremes: function(e) {
                    try {
                        const startDate = moment(new Date(e.min).toISOString()).format("YYYY-MM-DD");
                        const endDate = moment(new Date(e.max).toISOString()).format("YYYY-MM-DD");
                        rebaseFunc(startDate, endDate, e.target.chart);
                    } catch (err) {
                        console.log(err);
                    }
                }
            };
        }

        return (
            <Layout>
                <div>
                    <div style={{ margin: "4px 0", fontFamily: "var(--font-main)", fontSize: "1.3rem", color: "#777" }}>sectors:</div>
                    <div className="tab_wrapper">
                        {list_of_categories.map((ele, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={"tab" + (idx === selected_index ? " tab-selected" : "")}
                                    onClick={() => {
                                        this.onChangeIndex(idx);
                                    }}
                                >
                                    {ele}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <ReactHighstock config={chart_configuration} />
            </Layout>
        );
    }
}
