import React, { Component } from "react";
import { COUNTRYLOOKUP } from "../../../index/CountryCode";

export const embed = (theme, keyword, country) => {
    country = country || "Global";
    // Lookup Country code for Gdelt (FIPS) and Google Trends (ISO)
    const isoCode = COUNTRYLOOKUP[country].iso;
    const fipsCode = COUNTRYLOOKUP[country].fips;
    const countryFieldForGdelt = fipsCode ? `sourcecountry:${fipsCode}` : "";
    if (keyword) {
        if (keyword.indexOf(" ") >= 0) {
            keyword = `%22${keyword}%22`;
        } else {
            keyword = keyword;
        }
    } else {
        keyword = "";
    }
    country = country || "US";
    let url = "";
    if (theme === "googletrend") {
        url = `https://trends.google.com/trends/embed/explore/TIMESERIES?req=%7B%22comparisonItem%22%3A%5B%7B%22keyword%22%3A${keyword}%2C%22geo%22%3A%22${isoCode}%22%2C%22time%22%3A%22today%205-y%22%7D%5D%2C%22category%22%3A0%2C%22property%22%3A%22%22%7D&tz=-600&eq=date%3Dtoday%25205-y%26q%3D${keyword}`;
    } else if (theme === "volume_timeline") {
        url = `https://api.gdeltproject.org/api/v2/doc/doc?format=html&timespan=FULL&query=${keyword}%20${countryFieldForGdelt}&mode=timelinevol&timezoom=yes`;
    } else if (theme === "tone_timeline") {
        url = `https://api.gdeltproject.org/api/v2/doc/doc?format=html&timespan=FULL&query=${keyword}%20${countryFieldForGdelt}&mode=timelinetone&timezoom=yes`;
    } else if (theme === "tone_bar") {
        url = `https://api.gdeltproject.org/api/v2/doc/doc?format=html&timespan=FULL&query=${keyword}%20${countryFieldForGdelt}&mode=tonechart&timezoom=yes`;
    } else if (theme === "source_map") {
        url = `https://api.gdeltproject.org/api/v2/geo/geo?format=html&zoomwheel=0&query=${keyword}%20${countryFieldForGdelt}&mode=sourcecountry`;
    } else if (theme === "location_map") {
        url = `https://api.gdeltproject.org/api/v2/geo/geo?format=html&zoomwheel=0&query=${keyword}%20${countryFieldForGdelt}&mode=pointdata`;
    } else if (theme === "tags_wordcloud") {
        url = `https://api.gdeltproject.org/api/v2/doc/doc?format=html&timespan=FULL&query=${keyword}%20${countryFieldForGdelt}&mode=wordcloudimagetags`;
    } else if (theme === "topics_wordcloud") {
        url = `https://api.gdeltproject.org/api/v2/doc/doc?format=html&timespan=FULL&query=${keyword}%20${countryFieldForGdelt}&mode=wordcloudimagewebtags`;
    }
    return <iframe width="100%" src={url} frameborder="0"></iframe>;
};
