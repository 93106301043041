import moment from "moment";
import "moment-timezone";

import { TickerDict } from "../index/TickerDictonary";
import { HOST } from "../config";
import { cumulativeToDaily, cumulativeToMonthly } from "./ChartFunctions";
import data_tradinghours from "../json/ticker_tradinghrs.json";

// =============================================================================
// #LIST #OBJECT #DICTIONARY
// =============================================================================

export function toUnique(obj) {
    if (obj.length > 0) {
        let result = obj;
        result = result.filter((ele) => ele);
        result = result.reduce((a, b) =>
            typeof a === "object" ? (a.indexOf(b) < 0 ? a.concat([b]) : a) : a === b ? a : [a, b]
        );
        if (typeof result !== "object") {
            result = [result];
        }
        return result;
    } else {
        return [];
    }
}

export function notIn(item, list) {
    return list.indexOf(item) < 0;
}

export function hasItem(item, list) {
    return list.indexOf(item) >= 0;
}

export function zipAll(rows) {
    return rows[rows.reduce((p, c, i, a) => (a[p].length > c.length ? p : i), 0)].map((_, c) =>
        rows.map((row) => row[c] || "")
    );
}

// --+-- List Handling --+--
export function getMax(list) {
    return list.reduce((a, b) => (a >= b ? a : b));
}

export function getMin(list) {
    return list.reduce((a, b) => (a <= b ? a : b));
}

export function getMargin(list) {
    if (list.length > 0) {
        const min = getMin(list);
        const max = getMax(list);
        const margin = Math.abs(min) >= Math.abs(max) ? Math.abs(min) : Math.abs(max);
        return margin;
    } else {
        return 0;
    }
}

export function groupBy(collection, property) {
    var i = 0,
        val,
        index,
        values = [],
        result = [];
    for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);
        if (index > -1) result[index].list.push(collection[i]);
        else {
            values.push(val);
            result.push({ group: val, list: [collection[i]] });
        }
    }
    return result;
}

export function argmaxFromDicts(list, key) {
    let sorted = list.sort((a, b) => b[key] - a[key]);
    return sorted[0];
}

export function flattenTree(data, withColor) {
    const key4name = "name";
    const key4kids = "children";
    const key4value = "value";
    const colormap = [
        "#8dd3c7",
        "#ffffb3",
        "#bebada",
        "#fb8072",
        "#80b1d3",
        "#fdb462",
        "#b3de69",
        "#fccde5",
        "#d9d9d9",
        "#bc80bd",
        "#ccebc5",
        "#ffed6f",
    ];
    data.parent = "";
    data = [data];
    let level = 0;
    let pipeline = data;
    let results = [];
    while (pipeline.length > 0) {
        let kids = [];
        for (let idx = 0; idx < pipeline.length; idx++) {
            let item = pipeline[idx];
            let newObj = {};
            newObj["id"] = item[key4name] + "." + level;
            newObj[key4name] = item[key4name];
            newObj["parent"] = item["parent"];
            newObj["level"] = level;

            if (key4value in item) {
                newObj[key4value] = Math.abs(item[key4value]);
                newObj["colorValue"] = item[key4value];
            }
            if (withColor) {
                if (level === 1) {
                    console.log(idx, idx % colormap.length);
                    newObj["color"] = colormap[idx % colormap.length];
                }
            }
            results.push(newObj);
            kids = kids.concat((item[key4kids] || []).map((ele) => ({ ...ele, parent: item[key4name] + "." + level })));
        }
        level += 1;
        pipeline = kids;
    }
    console.log(results);
    return results;
}

// =============================================================================
// #STRING #FORMAT
// =============================================================================

export function parsePairname(name) {
    const matched = /([a-zA-Z\d ]+)([-|%])([a-zA-Z\d ]+)_([\d]+)/g.exec(name);
    if (matched) {
        const result = {
            isPair: true,
            pair1: matched[1],
            pair2: matched[3],
            type: matched[2],
            rolling_size: matched[4],
        };
        return result;
    } else {
        return {};
    }
}

/**
 *
 * Extract name from company's email ()
 * e.g. "claude.chen@imbuecapital.com" => "Claude Chen"
 *
 * @param {string} email
 */
export function emailToName(email) {
    let name = email
        .split("@")[0]
        .split(".")
        .map((name) => name.charAt(0).toUpperCase() + name.substr(1).toLowerCase())
        .join(" ");
    return name;
}

export function titleWords(key) {
    /* 
    convert "item_id" to Item Id
    */
    if (key) {
        key = key
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        return key;
    } else {
        return "";
    }
}

export function parsePercentage(str) {
    /* 
    parse percentage like string
    e.g: "10.32%" ==> 0.1032
    */
    return parseFloat((str || "").split("%")[0] / 100);
}

export function toPercentage(num, decimal) {
    /* 
    convert number to percentage
    e.g: 0.1032 => "10.32%"
    */
    decimal = decimal || 2;
    return (num * 100).toFixed(2) + " %";
}

/**
 *
 * @param {string} str
 *
 * e.g. "C 1 Comdty%W 1 Comdty" => ["C 1 Comdty", "W 1 Comdty"]
 *
 */
export function getTickerFromPair(str) {
    if (str.indexOf("%") >= 0) {
        return str.split("%");
    } else {
        return str.split("-");
    }
}

/**
 *
 * Convert a security ticker to Bloomberg quote link (May not available to all securities)
 * eg "C 1 Comdty" => "https://www.bloomberg.com/quote/C 1:COM"
 *
 * @param {string} ticker
 */
export function tickerToBloombergUrl(ticker) {
    if (ticker) {
        let mutated = "";
        switch (ticker.toLowerCase()) {
            case "pl1 comdty":
                mutated = "XPTUSD:CUR";
                break;
            case "pa1 comdty":
                mutated = "XPDUSD:CUR";
                break;
            default:
                mutated = ticker.split("1 ")[0].toUpperCase() + "1:COM";
                break;
        }
        return "https://www.bloomberg.com/quote/" + mutated;
    }
}

// =============================================================================
// #DATE #DATETIME #TIME #TIMEZONE
// =============================================================================

export function toAusTime(ts, format) {
    // ts: Millisecond
    // -- given UTC datetime string and convert to Australia Datetime String
    // -- [optional]
    // -- -- format: 0 - datetime 1 - datetime fromnow 2- fromnow

    let newTs = moment(ts * 1000).format("D MMMM YYYY, HH:mm:ss");
    let fromNow = moment(ts * 1000).fromNow();
    let datetimeStr = "";
    switch (format) {
        case undefined:
            datetimeStr = newTs;
            break;
        case 1:
            datetimeStr = fromNow + " · " + newTs;
            break;
        case 2:
            datetimeStr = fromNow;
            break;
        case 3:
            datetimeStr = moment(ts * 1000).format("D MMMM YYYY");
            break;
        default:
            break;
    }

    return datetimeStr;
}

export function toDaily(ts) {
    if (ts && ts.length > 1) {
        const dailyTs = ts.map((item, idx) => [item[0], idx === 0 ? 0 : item[1] - ts[idx - 1][1]]);
        return dailyTs;
    } else {
        return [];
    }
}

/**
 *
 * Calculate how many business days between day1str and day2str
 *
 * Example
 * -------
 *
 * calculateBusinessDays("2019-06-18", "2019-06-24") --> 5 days
 *
 * @param {string} day1str
 * @param {string} day2str
 */
export function calculateBusinessDays(day1str, day2str) {
    const days = moment(day2str).diff(moment(day1str), "days");
    var bdays = 1;
    for (let i = 0; i < days; i++) {
        let nextDay = moment(day1str).add(i + 1, "day");
        if (nextDay.isoWeekday() !== 6 && nextDay.isoWeekday() !== 7) {
            bdays += 1;
        }
    }
    return bdays;
}

export function nextBusinessDay(daystr) {
    let thisDay = moment(daystr);
    let counter = 0;
    while (true) {
        thisDay = moment(thisDay).add(1, "day");
        if (thisDay.isoWeekday() !== 6 && thisDay.isoWeekday() !== 7) {
            break;
        }
        counter += 1;
        if (counter === 3) {
            break;
        }
    }
    return thisDay.format("YYYY-MM-DD");
}

/**
 *
 * @param {string} daterange e.g. "02:00-19:00"
 * @param {*} tzname         e.g. time zone name
 */
export function daterangeTz(daterange, tzname) {
    const convertDateRange = (dr) => {
        const open_dt = dr.split("-")[0];
        const close_dt = dr.split("-")[1];
        const open_dt_converted = moment.tz(open_dt, "HH:mm", "America/New_York").clone().tz(tzname).format("HH:mm");
        const close_dt_converted = moment.tz(close_dt, "HH:mm", "America/New_York").clone().tz(tzname).format("HH:mm");
        return open_dt_converted + "-" + close_dt_converted;
    };
    if (daterange.indexOf("&") < 0) {
        return convertDateRange(daterange);
    } else {
        const daterange1 = daterange.split(" & ")[0];
        const daterange2 = daterange.split(" & ")[1];
        return convertDateRange(daterange1) + " & " + convertDateRange(daterange2);
    }
}

export function getTradingHour(ticker) {
    // 1.filter
    const filtered = data_tradinghours.rows.filter((row) => row.ticker.lower() === ticker.lower());
    if (filtered.length < 1) {
        return {};
    } else {
        const queried = filtered[0];
        return {
            US: queried.key,
            AU: queried.key,
        };
    }
}

// ========================================
// --+-- SELECTION OPTIONS FORMATTING --+--
// e.g. [{label: __, value: __, group: __ }, ...] ------>
// [
//   {label: __, options:
//        [{label: __, value: __}]} ]
// ========================================
export function formatSelection(paraIndex, selection, prefilled) {
    let formatted = [];
    let defaultOption = null;

    if (typeof selection[0] !== "object") {
        formatted = selection.map((ele) => ({
            label: TickerDict[ele] || ele,
            value: ele,
        }));
        defaultOption = formatted[0];
    } else {
        formatted = selection
            .map((ele) => ele.group)
            .reduce(
                (a, b) => (typeof a === "object" ? (a.indexOf(b) < 0 ? [...a, ...[b]] : a) : a !== b ? [a, b] : [a]),
                []
            )
            .map((ele) => ({
                label: ele,
                options: selection
                    .filter((row) => row.group === ele)
                    .map((row) => ({
                        label: TickerDict[row.label] || row.label,
                        value: row.value,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)),
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        defaultOption = formatted[0].options[0];
    }

    if (prefilled) {
        if (paraIndex.type === "multiSelection") {
            defaultOption = selection
                .filter((ele) => prefilled.indexOf(typeof ele === "object" ? ele.value : ele) >= 0)
                .map((ele) => ({
                    label: typeof ele === "object" ? ele.value : ele,
                    value: typeof ele === "object" ? ele.value : ele,
                }));
        } else {
            switch (typeof prefilled) {
                case "boolean":
                    defaultOption = prefilled;
                    break;
                case "string":
                    prefilled = prefilled.trim();
                case "number":
                    // if (selection.map(ele => String(ele)).indexOf(prefilled) >= 0 || paraIndex.identifier === "episodes") {
                    defaultOption = {
                        label: TickerDict[prefilled] || prefilled,
                        value: prefilled,
                    };
                    // }
                    break;
                default:
                    if (selection.map((ele) => String(ele.value)).indexOf(prefilled) >= 0) {
                        defaultOption = {
                            label: selection.filter((ele) => ele.value === prefilled)[0].label,
                            value: selection.filter((ele) => ele.value === prefilled)[0].value,
                        };
                        defaultOption.label = TickerDict[defaultOption.label] || defaultOption.label;
                    }
            }
        }
    }

    return {
        formatted,
        defaultOption,
    };
}

// =============================================================================
// #URL
// =============================================================================

export function getParamFromUrl(key) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const val = urlParams.get(key);
    return val;
}

export function genAIResultLink(docid) {
    return `${HOST}/imbue_ailab/result_viewer?docid=${docid}`;
}

export function argsToUrl(args) {
    if (!args) {
        return "";
    } else {
        let url = Object.keys(args)
            .map(
                (key) =>
                    `${key}=${typeof args[key] === "object" ? args[key].map((ele) => ele + ",").join("") : args[key]}`
            )
            .join("&&");
        return url;
    }
}

export function argsFromUrl(url) {
    if (!url) {
        url = window.location.href;
    }

    if (url === "") {
        return {};
    } else {
        if (url.indexOf("?") >= 0) {
            try {
                let myRe = /\?(.+)/g;
                let args = myRe.exec(url)[1];
                args = args
                    .split("&&")
                    .map((item) => ({
                        key: item.split("=")[0],
                        value: item.split("=")[1].replace(/%20/g, " ").split(","),
                    }))
                    .map((item) => ({
                        key: item.key,
                        value: item.value.length === 1 ? item.value[0] : item.value.slice(0, item.value.length - 1),
                    }));
                let finalArgs = {};
                for (let item of args) {
                    finalArgs[item.key] = item.value;
                }
                return finalArgs;
            } catch (err) {
                return {};
            }
        } else {
            return {};
        }
    }
}

// =============================================================================
// #MATH
// =============================================================================

export function deg_to_dms(deg) {
    let seconds = parseInt(deg, 10);
    let days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    let hrs = Math.floor(seconds / 3600);
    seconds -= hrs * 3600;
    let mnts = Math.floor(seconds / 60);
    seconds -= mnts * 60;
    let dayStr = days > 0 ? days + " days" : null;
    let hourStr = hrs > 0 ? hrs + " hr" : null;
    let minStr = mnts > 0 ? mnts + " min" : null;
    let secStr = seconds > 0 ? seconds + " sec" : null;
    let formatted = [dayStr, hourStr, minStr, secStr].filter((ele) => ele !== null).join(" ");
    return formatted;
}

export function getStd(lst) {
    return standardDeviation(lst);
}

export function getPositiveRatio(lst) {
    return (lst.filter((ele) => ele > 0).length / lst.length) * 100;
}

export function getFixed(number, decimal) {
    decimal = decimal || 2;
    if (number) {
        return number.toFixed(decimal);
    } else {
        return null;
    }
}

export function standardDeviation(values) {
    var avg = average(values);

    var squareDiffs = values.map(function (value) {
        var diff = value - avg;
        var sqrDiff = diff * diff;
        return sqrDiff;
    });

    var avgSquareDiff = average(squareDiffs);

    var stdDev = Math.sqrt(avgSquareDiff);
    return stdDev;
}

export function average(data) {
    var sum = data.reduce(function (sum, value) {
        return sum + value;
    }, 0);
    var avg = sum / data.length;
    return avg;
}

// Alias of average()
export function getAvg(lst) {
    return average(lst);
}

// =============================================================================
// #SIMULATION, #BACKTEST, #TRADES
// =============================================================================

/**
 * Calculate statistics based on given P&L and Trades
 *
 * Author:      Bobby Koteski, Claude Chen
 * Created:     22 Aug 2018
 *
 *
 * @param {{pnl: [[dt, ]], trades: []}} data
 * @param {bool} isSecurity
 */
export function getStats(data, isSecurity, scale) {
    let { pnl, trades } = data;

    scale = scale || 100;

    if (pnl) {
        if (pnl.length > 0) {
            if (typeof pnl[0][0] === "number") {
                pnl = pnl.map((ele) => [moment(ele[0]).format("YYYY-MM-DD"), ele[1]]);
            }
        }
    } else {
        return {};
    }

    const cumulativePnlSeries = pnl.map((ele) => ele[1]);

    const dailyPnl = cumulativeToDaily(pnl);
    const monthlyPnl = cumulativeToMonthly(pnl);

    const dailyPnlSeries = dailyPnl.slice(1, dailyPnl.length).map((ele) => ele[1]);
    const monthlyPnlSeries = monthlyPnl.map((ele) => ele[1]);

    // --
    const TRADEDAYSINYEAR = 252;
    const TRADEMONTHSINYEAR = 12;
    const RISKFREERATE = isSecurity ? 0 : 0.015;

    const mean = average(dailyPnlSeries);
    const std = standardDeviation(dailyPnlSeries);
    const totalPnl = dailyPnlSeries.reduce((a, b) => a + b, 0);
    const annualPnl = (totalPnl / pnl.length) * TRADEDAYSINYEAR;
    const annualVol = std * Math.sqrt(TRADEDAYSINYEAR);

    // SHARPE & SORTINO
    const sharpe = (mean * TRADEDAYSINYEAR - RISKFREERATE) / annualVol;
    const sortino =
        (mean * TRADEDAYSINYEAR - RISKFREERATE) /
        (standardDeviation(dailyPnlSeries.filter((ele) => ele < 0)) * Math.sqrt(TRADEDAYSINYEAR));

    const sharpeMonthly =
        (average(monthlyPnlSeries) * TRADEMONTHSINYEAR - RISKFREERATE) /
        (standardDeviation(monthlyPnlSeries) * Math.sqrt(TRADEMONTHSINYEAR));
    const sortinoMonthly =
        (average(monthlyPnlSeries) * TRADEMONTHSINYEAR - RISKFREERATE) /
        (standardDeviation(monthlyPnlSeries.filter((ele) => ele < 0)) * Math.sqrt(TRADEMONTHSINYEAR));

    const volMonthly = standardDeviation(monthlyPnlSeries) * Math.sqrt(TRADEMONTHSINYEAR);

    // DRAWDOWN
    let peak = -99999;
    let draw = 0; // placeholder value
    let drawDown = 0;
    cumulativePnlSeries.forEach(function (e) {
        if (e > peak) peak = e;
        draw = peak - e;
        if (draw > drawDown) drawDown = draw;
    });

    // ---+--- hanlde pnl stats
    let pnlstats = {
        sharpe: sharpe.toFixed(2),
        monthly_sharpe: sharpeMonthly.toFixed(2),
        sortino: sortino.toFixed(2),
        // 'sortinoMonthly': sortinoMonthly.toFixed(2),
        "total_P&L": (totalPnl * scale).toFixed(2) + "%",
        "annual_P&L": (annualPnl * scale).toFixed(2) + "%",
        annual_vol: (annualVol * scale).toFixed(2) + "%",
        monthly_vol: (volMonthly * scale).toFixed(2) + "%",
        drawdown: (drawDown * scale).toFixed(2) + "%",
        days_in_simulation: pnl.length,
    };

    // ---+--- handle trades stats
    let tradesStats = trades
        ? {
              win_rate: ((trades.filter((ele) => ele.raw_pnl >= 0).length / trades.length) * 100).toFixed(2) + "%",
              "no._trades": trades.length,
              avg_trade_duration: (
                  trades.map((ele) => ele.days_held).reduce((a, b) => a + b, 0) / trades.length
              ).toFixed(0),
          }
        : {
              // "win_rate": "",
              // "no._trades": "",
              // "avg_trade_duration": "",
          };

    const result = {
        ...pnlstats,
        ...tradesStats,
    };

    return result;
}

/**
 *
 * @param {object} stats
 * @param {string} name
 */
export function get_stat(stats, name) {
    if (stats) {
        // FUTURE HANDLE
        if (stats.length > 0) {
            if ("label" in stats[0]) {
                return (stats.find((ele) => ele.label === name) || {}).display;
            } else {
                return (stats.find((ele) => name in ele) || {})[name];
            }
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export function getNavDict(rawMeta) {
    // Determine if meta information of portfolio is exists
    if (rawMeta && "data" in rawMeta) {
        let navDict = {};
        for (let item of rawMeta.data.meta) {
            navDict[item.ticker] = item.navweigh === null ? 0 : item.navweigh;
        }
        return navDict;
    } else {
        if (rawMeta && rawMeta.length > 0) {
            let navDict = {};
            for (let item of rawMeta) {
                navDict[item.ticker] = item.navweigh === null ? 0 : item.navweigh;
            }
            return navDict;
        } else {
            return {};
        }
    }
}

// =============================================================================
// #DEV
// =============================================================================

export function isDevMode() {
    // is dev mode if location is localhost
    return window.location.origin === "http://localhost:3000";
}
