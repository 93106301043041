import React, { useState, useEffect } from "react";
import { api_blp_seasonality } from "../../helpers/API";
import styled from "styled-components";
import ImbueSelect from "../../components/input/ImbueSelect";
import HighStockWrapper from "../../components/chart/HighStockWrapper";
import { GLOBALSELECTOPTIONS, GLOBALSTYLED, COLORMAP} from "../../config";
// import { generateSeasonal } from "../../helpers/ChartFunctions";
import ImbueTabs from "../../components/tabs/ImbueTabs";
import "./style.css";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;

// ES rolls
const ESrollDates = [
    "1983-03-18",
    "1983-06-17",
    "1983-09-16",
    "1983-12-16",
    "1984-03-16",
    "1984-06-15",
    "1984-09-21",
    "1984-12-21",
    "1985-03-15",
    "1985-06-21",
    "1985-09-20",
    "1985-12-20",
    "1986-03-21",
    "1986-06-20",
    "1986-09-19",
    "1986-12-19",
    "1987-03-20",
    "1987-06-19",
    "1987-09-18",
    "1987-12-18",
    "1988-03-18",
    "1988-06-17",
    "1988-09-16",
    "1988-12-16",
    "1989-03-17",
    "1989-06-16",
    "1989-09-15",
    "1989-12-15",
    "1990-03-16",
    "1990-06-15",
    "1990-09-21",
    "1990-12-21",
    "1991-03-15",
    "1991-06-21",
    "1991-09-20",
    "1991-12-20",
    "1992-03-20",
    "1992-06-19",
    "1992-09-18",
    "1992-12-18",
    "1993-03-19",
    "1993-06-18",
    "1993-09-17",
    "1993-12-17",
    "1994-03-18",
    "1994-06-17",
    "1994-09-16",
    "1994-12-16",
    "1995-03-17",
    "1995-06-16",
    "1995-09-15",
    "1995-12-15",
    "1996-03-15",
    "1996-06-21",
    "1996-09-20",
    "1996-12-20",
    "1997-03-21",
    "1997-06-20",
    "1997-09-19",
    "1997-12-19",
    "1998-03-20",
    "1998-06-19",
    "1998-09-18",
    "1998-12-18",
    "1999-03-19",
    "1999-06-18",
    "1999-09-17",
    "1999-12-17",
    "2000-03-17",
    "2000-06-16",
    "2000-09-15",
    "2000-12-15",
    "2001-03-16",
    "2001-06-15",
    "2001-09-21",
    "2001-12-21",
    "2002-03-15",
    "2002-06-21",
    "2002-09-20",
    "2002-12-20",
    "2003-03-21",
    "2003-06-20",
    "2003-09-19",
    "2003-12-19",
    "2004-03-19",
    "2004-06-18",
    "2004-09-17",
    "2004-12-17",
    "2005-03-18",
    "2005-06-17",
    "2005-09-16",
    "2005-12-16",
    "2006-03-17",
    "2006-06-16",
    "2006-09-15",
    "2006-12-15",
    "2007-03-16",
    "2007-06-15",
    "2007-09-21",
    "2007-12-21",
    "2008-03-20",
    "2008-06-20",
    "2008-09-19",
    "2008-12-19",
    "2009-03-20",
    "2009-06-19",
    "2009-09-18",
    "2009-12-18",
    "2010-03-19",
    "2010-06-18",
    "2010-09-17",
    "2010-12-16",
    "2011-03-17",
    "2011-06-16",
    "2011-09-15",
    "2011-12-16",
    "2012-03-15",
    "2012-06-14",
    "2012-09-20",
    "2012-12-21",
    "2013-03-15",
    "2013-06-21",
    "2013-09-20",
    "2013-12-20",
    "2014-03-21",
    "2014-06-20",
    "2014-09-19",
    "2014-12-19",
    "2015-03-20",
    "2015-06-19",
    "2015-09-18",
    "2015-12-18",
    "2016-03-18",
    "2016-06-17",
    "2016-09-16",
    "2016-12-16",
    "2017-03-17",
    "2017-06-16",
    "2017-09-15",
    "2017-12-15",
    "2018-03-16",
    "2018-06-15",
    "2018-09-21",
    "2018-12-21",
    "2019-03-15",
    "2019-06-21",
    "2019-09-20",
    "2019-12-20",
    "2020-03-20",
    "2020-06-19",
    "2020-09-18",
    "2020-12-18",
    "2021-03-19",
    "2021-06-18",
    "2021-09-17",
    "2021-12-17",
    "2022-03-18",
    "2022-06-17",
    "2022-09-16",
    "2022-12-16",
    "2023-03-17",
    "2023-06-16",
    "2023-09-15",
    "2023-12-15",
    "2024-03-15",
    "2024-06-21",
    "2024-09-20",
    "2024-12-20",
]

const WASDErollDates = [
    "2010-01-12", // FAKE VALUES FOR 2010
    "2010-02-09", // FAKE VALUES FOR 2010
    "2010-03-10", // FAKE VALUES FOR 2010 ... USDA.gov did not have data before April-2010
    "2010-04-09", 
    "2010-05-11", 
    "2010-06-10", 
    "2010-07-09", 
    "2010-08-12", 
    "2010-09-10", 
    "2010-10-08", 
    "2010-11-09", 
    "2010-12-10", 
    "2011-01-12",
    "2011-02-09",
    "2011-03-10",
    "2011-04-08",
    "2011-05-11",
    "2011-06-09",
    "2011-07-12",
    "2011-08-11",
    "2011-09-12",
    "2011-10-12",
    "2011-11-09",
    "2011-12-09",
    "2012-01-12",
    "2012-02-09",
    "2012-03-09",
    "2012-04-10",
    "2012-05-10",
    "2012-06-12",
    "2012-07-11",
    "2012-08-10",
    "2012-09-12",
    "2012-10-11",
    "2012-11-09",
    "2012-12-11",
    "2013-01-11",
    "2013-02-08",
    "2013-03-08",
    "2013-04-10",
    "2013-05-10",
    "2013-06-12",
    "2013-07-11",
    "2013-08-12",
    "2013-09-12",
    "2013-10-11", // FAKE VALUE FOR 2013-10
    "2013-11-08",
    "2013-12-10",
    "2014-01-10",
    "2014-02-10",
    "2014-03-10",
    "2014-04-09",
    "2014-05-09",
    "2014-06-11",
    "2014-07-11",
    "2014-08-12",
    "2014-09-11",
    "2014-10-10",
    "2014-11-10",
    "2014-12-10",
    "2015-01-12",
    "2015-02-10",
    "2015-03-10",
    "2015-04-09",
    "2015-05-12",
    "2015-06-10",
    "2015-07-10",
    "2015-08-12",
    "2015-09-11",
    "2015-10-09",
    "2015-11-10",
    "2015-12-09",
    "2016-01-12",
    "2016-02-09",
    "2016-03-09",
    "2016-04-12",
    "2016-05-10",
    "2016-06-10",
    "2016-07-12",
    "2016-08-12",
    "2016-09-12",
    "2016-10-12",
    "2016-11-09",
    "2016-12-09",
    "2017-01-12",
    "2017-02-09",
    "2017-03-09",
    "2017-04-11",
    "2017-05-10",
    "2017-06-09",
    "2017-07-12",
    "2017-08-10",
    "2017-09-12",
    "2017-10-12",
    "2017-11-09",
    "2017-12-12",
    "2018-01-12",
    "2018-02-08",
    "2018-03-08",
    "2018-04-10",
    "2018-05-10",
    "2018-06-12",
    "2018-07-12",
    "2018-08-10",
    "2018-09-12",
    "2018-10-11",
    "2018-11-08",
    "2018-12-11",
    "2019-01-11",
    "2019-02-08",
    "2019-03-08",
    "2019-04-09",
    "2019-05-10",
    "2019-06-11",
    "2019-07-11",
    "2019-08-12",
    "2019-09-12",
    "2019-10-10",
    "2019-11-08",
    "2019-12-10",
    "2020-01-10",
    "2020-02-11",
    "2020-03-10",
    "2020-04-09",
    "2020-05-12",
    "2020-06-11",
    "2020-07-10",
    "2020-08-12",
    "2020-09-11",
    "2020-10-09",
    "2020-11-10",
    "2020-12-10",
    "2021-01-12",
    "2021-02-09",
    "2021-03-09",
    "2021-04-09",
    "2021-05-12",
    "2021-06-10",
    "2021-07-12",
    "2021-08-12",
    "2021-09-10",
    "2021-10-12",
    "2021-11-09",
    "2021-12-09",
    "2022-01-12",
    "2022-02-09",
    "2022-03-09",
    "2022-04-08",
    "2022-05-12",
    "2022-06-10",
    "2022-07-12",
    "2022-08-12",
    "2022-09-12",
    "2022-10-12",
    "2022-11-09",
    "2022-12-09",
    "2023-01-12",
    "2023-02-08",
    "2023-03-08",
    "2023-04-11",
    "2023-05-12",
    "2023-06-09",
    "2023-07-12",
    "2023-08-11",
    "2023-09-12",
    "2023-10-12",
    "2023-11-09",
    "2023-12-08",
    "2024-01-12",
    "2024-02-08",
    "2024-03-08",
    "2024-04-11",
    "2024-05-10",
    "2024-06-12",
    "2024-07-12",
    "2024-08-12",
    "2024-09-12",
    "2024-10-11",
    "2024-11-08",
    "2024-12-10",

]

const dayCategories = [
"Jan-1","Jan-2","Jan-3","Jan-4","Jan-5","Jan-6","Jan-7","Jan-8","Jan-9","Jan-10","Jan-11","Jan-12","Jan-13","Jan-14","Jan-15","Jan-16","Jan-17","Jan-18","Jan-19","Jan-20","Jan-21","Jan-22","Jan-23","Jan-24","Jan-25","Jan-26","Jan-27","Jan-28","Jan-29","Jan-30","Jan-31",
"Feb-1","Feb-2","Feb-3","Feb-4","Feb-5","Feb-6","Feb-7","Feb-8","Feb-9","Feb-10","Feb-11","Feb-12","Feb-13","Feb-14","Feb-15","Feb-16","Feb-17","Feb-18","Feb-19","Feb-20","Feb-21","Feb-22","Feb-23","Feb-24","Feb-25","Feb-26","Feb-27","Feb-28","Feb-29",
"Mar-1","Mar-2","Mar-3","Mar-4","Mar-5","Mar-6","Mar-7","Mar-8","Mar-9","Mar-10","Mar-11","Mar-12","Mar-13","Mar-14","Mar-15","Mar-16","Mar-17","Mar-18","Mar-19","Mar-20","Mar-21","Mar-22","Mar-23","Mar-24","Mar-25","Mar-26","Mar-27","Mar-28","Mar-29","Mar-30","Mar-31",
"Apr-1","Apr-2","Apr-3","Apr-4","Apr-5","Apr-6","Apr-7","Apr-8","Apr-9","Apr-10","Apr-11","Apr-12","Apr-13","Apr-14","Apr-15","Apr-16","Apr-17","Apr-18","Apr-19","Apr-20","Apr-21","Apr-22","Apr-23","Apr-24","Apr-25","Apr-26","Apr-27","Apr-28","Apr-29","Apr-30",
"May-1","May-2","May-3","May-4","May-5","May-6","May-7","May-8","May-9","May-10","May-11","May-12","May-13","May-14","May-15","May-16","May-17","May-18","May-19","May-20","May-21","May-22","May-23","May-24","May-25","May-26","May-27","May-28","May-29","May-30","May-31",
"Jun-1","Jun-2","Jun-3","Jun-4","Jun-5","Jun-6","Jun-7","Jun-8","Jun-9","Jun-10","Jun-11","Jun-12","Jun-13","Jun-14","Jun-15","Jun-16","Jun-17","Jun-18","Jun-19","Jun-20","Jun-21","Jun-22","Jun-23","Jun-24","Jun-25","Jun-26","Jun-27","Jun-28","Jun-29","Jun-30",
"Jul-1","Jul-2","Jul-3","Jul-4","Jul-5","Jul-6","Jul-7","Jul-8","Jul-9","Jul-10","Jul-11","Jul-12","Jul-13","Jul-14","Jul-15","Jul-16","Jul-17","Jul-18","Jul-19","Jul-20","Jul-21","Jul-22","Jul-23","Jul-24","Jul-25","Jul-26","Jul-27","Jul-28","Jul-29","Jul-30","Jul-31",
"Aug-1","Aug-2","Aug-3","Aug-4","Aug-5","Aug-6","Aug-7","Aug-8","Aug-9","Aug-10","Aug-11","Aug-12","Aug-13","Aug-14","Aug-15","Aug-16","Aug-17","Aug-18","Aug-19","Aug-20","Aug-21","Aug-22","Aug-23","Aug-24","Aug-25","Aug-26","Aug-27","Aug-28","Aug-29","Aug-30","Aug-31",
"Sep-1","Sep-2","Sep-3","Sep-4","Sep-5","Sep-6","Sep-7","Sep-8","Sep-9","Sep-10","Sep-11","Sep-12","Sep-13","Sep-14","Sep-15","Sep-16","Sep-17","Sep-18","Sep-19","Sep-20","Sep-21","Sep-22","Sep-23","Sep-24","Sep-25","Sep-26","Sep-27","Sep-28","Sep-29","Sep-30",
"Oct-1","Oct-2","Oct-3","Oct-4","Oct-5","Oct-6","Oct-7","Oct-8","Oct-9","Oct-10","Oct-11","Oct-12","Oct-13","Oct-14","Oct-15","Oct-16","Oct-17","Oct-18","Oct-19","Oct-20","Oct-21","Oct-22","Oct-23","Oct-24","Oct-25","Oct-26","Oct-27","Oct-28","Oct-29","Oct-30","Oct-31",
"Nov-1","Nov-2","Nov-3","Nov-4","Nov-5","Nov-6","Nov-7","Nov-8","Nov-9","Nov-10","Nov-11","Nov-12","Nov-13","Nov-14","Nov-15","Nov-16","Nov-17","Nov-18","Nov-19","Nov-20","Nov-21","Nov-22","Nov-23","Nov-24","Nov-25","Nov-26","Nov-27","Nov-28","Nov-29","Nov-30",
"Dec-1","Dec-2","Dec-3","Dec-4","Dec-5","Dec-6","Dec-7","Dec-8","Dec-9","Dec-10","Dec-11","Dec-12","Dec-13","Dec-14","Dec-15","Dec-16","Dec-17","Dec-18","Dec-19","Dec-20","Dec-21","Dec-22","Dec-23","Dec-24","Dec-25","Dec-26","Dec-27","Dec-28","Dec-29","Dec-30","Dec-31",
"-"]

// const fullComdtyOptions = GLOBALSELECTOPTIONS.fullComdtyOptions;
const fullContractOptions = GLOBALSELECTOPTIONS.fullContractOptions;

// -- Options
// -- -- Ticker
// const tickerOptions = fullComdtyOptions;
// const defaultTicker = tickerOptions[0];
// const defaultTicker2 = tickerOptions[1];
// -- -- Contract
const contractOptions = fullContractOptions;
const defaultContract = contractOptions[0];
// -- -- Field
const fieldOptions = [
    "PX_LAST",
    "PX_VOLUME",
    "OPEN_INTEREST",
    "FUT_AGGTE_OPEN_INT",
    "FUT_AGGTE_VOL",
    "HIST_CALL_IMP_VOL",
    "HIST_PUT_IMP_VOL",
    "1ST_MTH_IMPVOL_100.0%MNY_DF",
    "3MTH_IMPVOL_100.0%MNY_DF",
    "6MTH_IMPVOL_100.0%MNY_DF",
    "30day_impvol_100.0%MNY_DF",
].map((ele) => ({
    label: ele,
    value: ele,
}));
const defaultField = fieldOptions[0];
// -- -- view options
let viewOptions = [
    { label: "Commodity 1", value: "ticker1" },
];

const ESrollOptions = ["1","2","3","4"].map((ele) => ({
    label: ele,
    value: parseInt(ele)
}));

const WASDErollOptions = ["1","2","3","4","5","6","7","8","9","10","11","12"].map((ele) => ({
    label: ele,
    value: parseInt(ele)
}));

// hard-coded index dates for current year's reports
// 4 quarters of year
const ESrollMap = {
    1: 78,
    2: 171,
    3: 262,
    4: 353,
}

// 12 WASDE reports, 1 each month
const WASDErollMap = {
    1: 10,
    2: 40,
    3: 68,
    4: 100,
    5: 131,
    6: 162,
    7: 192,
    8: 223,
    9: 254,
    10: 283,
    11: 313,
    12: 343,
}

const monthMap = {1:'F', 2:'G', 3:'H', 4:'J', 5:'K', 6:'M', 7:'N', 8:'Q', 9:'U', 10:'V', 11:'X', 12:'Z'}
const WASDEsymbols = ["BO", "C ", "KW", "MW", "O ", "S ", "SM", "W "]

// let firstRun = true;
let allowChartRedraw = true;

let selectedContract1 = defaultContract.value;
let selectedField1 = defaultField.value;

export default function Seasonality (props) {

    const root = props.bloombergRoot;
    let offerRolls = isRollSymbol(root);

    let rollMap = offerRolls ? (root === "ES" ? ESrollMap : WASDErollMap) : {}
    let rollDates = offerRolls ? (root === "ES" ? ESrollDates : WASDErollDates) : ["1111-11-11"]
    let rollOptions = offerRolls ? (root === "ES" ? ESrollOptions : WASDErollOptions) : [{label: "?", value: 0}]

    const [state, setState] = useState(
        {
            data: [],
            meta: {
                ticker1: "",
                ticker2: "",
            }
        }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [selectedView1, setSelectedView1] = useState(viewOptions[0].value);

    const [showPercent, setShowPercent] = useState(false);

    const [showRolls, setShowRolls] = useState(false);
    const [rollOption, setRollOption] = useState(rollOptions[0]);

    const plotLine = showRolls ? { color: COLORMAP.green, width: 1.5, value: rollMap[rollOption.value] } : {}


    // check if current props root should offer/try to diplay roll data
    function isRollSymbol(root) {
        return (root === "ES" || WASDEsymbols.includes(root))
    }


    // call seasonality API when props change or when showRolls is toggled
    function onUpdate() {
        const ticker1 = props.bloombergRoot + selectedContract1 + " " + props.futureType;
        viewOptions[0].label = ticker1;
        // hardcode ticker2 to not be equal to ticker1 and then ignore it from here
        // API call requires both parameters now, but will adjust the backend in the future
        const ticker2 = ticker1 === "W 1 Comdty" ? "S 1 Comdty" : "W 1 Comdty";

        const paras = {
            ticker1: ticker1,
            ticker2: ticker2, // useless
            field1: selectedField1,
            field2: selectedField1, // useless
        };

        setIsLoading(true);

        api_blp_seasonality(paras).then((res) => {
            // console.log(res.data)
            setState({
                data: res.data.data,
                meta: res.data.meta
                });
            setIsLoading(false);
            setIsInit(true);
            })
            .catch((e) => {
                console.log(e);
                setHasError(true);
                setIsLoading(false);
            });
    };


    // calculate series data as differences (in days) from each year's repsective roll dates
    // this means dates will not all be matching on the same xAxis values 
    function getFormattedRollData(timestamp, ele, curDateStr, rollNum) {
        let curYear = curDateStr.split("-")[0];
        let rollDateStr = rollDates.filter(date => date.split("-")[0] === curYear)[rollNum-1];
    
        let [curY, curM, curD] = curDateStr.split("-");
        let curDate = new Date(curY, curM-1, curD);
    
        let [rollY, rollM, rollD] = rollDateStr.split("-");
        let rollDate = new Date(rollY, rollM-1, rollD);
    
        let diff = Math.round((curDate - rollDate) / (1000 * 3600 * 24));
        // return {x: diff, y: ele[1], curDate: curDateStr, rollDate: rollDateStr, diff: diff, time: timestamp};
        return {y: ele[1], curDate: curDateStr, rollDate: rollDateStr, diff: diff, time: timestamp};
        // return {x: timestamp, y: ele[1], curDate: curDateStr, rollDate: rollDateStr, diff: diff}
    }


    // create formatted series data for Seasonality ( check if showRolls format or not )
    function generateSeasonal(data, rebase, startmonth, calCum) {
        // Generate Highchart seasonal series data
        // Data format complys to Highchart Standard
        // Exp:
        // input: [["2010-01-01", 100] ... ]
        // output: [{name: "2010", data: [...]}]
    
        const Threshold = 8; // cutoff after 8 series
    
        if (!startmonth) {
            startmonth = 1;
        }
    
        if (!data || data.length < 1) {
            return {};
        }
    
        let seasonal = {};
    
        data.forEach((ele) => {
            // let month = date.getMonth() + 1;
            // let year = date.getFullYear();
            // let md = (date.getMonth() + 1).toLocaleString() + "-" + date.getDate().toLocaleString();
            // old method had an issue where different dates had matchign day values - (2019-10-9 == 2019-10-10 ???)
            let ar = ele[0].split('-')
            let year = ar[0]
            let month = ar[1]
            let day = ar[2]
            let md = month+'-'+day
            
            // let group = "Since " + startmonth + "/" + (year + (month - startmonth >= 0 ? 0 : -1));
            let group = year
            if (!(group in seasonal)) {
                seasonal[group] = [];
            }
            let timestamp = Date.parse((month < startmonth ? "2001" : "2000") + "-" + md);
            
            if (showRolls && offerRolls) {
                seasonal[group].push(getFormattedRollData(timestamp, ele, year+"-"+md, rollOption.value));
            } else {
                seasonal[group].push([timestamp, ele[1]]);
            }
        });
    
        seasonal = Object.keys(seasonal).map((key) => {
            return { year: key, data: seasonal[key] };
        });


        // -- Rebasing
        if (rebase) {
            seasonal = seasonal.map((ele) => ({
                ...ele,
                data: ele.data.map((row) => [row[0], row[1] - ele.data[0][1]]),
            }));
        }
    
        if (seasonal.length > Threshold) {
            seasonal = seasonal.slice(seasonal.length - Threshold, seasonal.length);
        }
    
        if (calCum === true) {
            for (let index = 0; index < seasonal.length; index++) {
                const data = seasonal[index]["data"];
                let cum = 0;
                for (let seq = 0; seq < data.length; seq++) {
                    cum += data[seq][1];
                    data[seq][1] = cum;
                }
            }
        }
    
        let series = seasonal.map((item, index) => {
            let isCurrentYear = index === Object.keys(seasonal).length - 1;
            if (isCurrentYear) {
                return {
                    type: "area",
                    name: item.year,
                    data: item.data,
                    fillOpacity: 0.1,
                    lineWidth: 3,
                    dashStyle: "Solid",
                    color: COLORMAP.green,
                    threshold: null,
                };
            } else {
                return {
                    type: "line",
                    name: item.year,
                    data: item.data,
                    fillOpacity: 0.1,
                    lineWidth: 1.5,
                    dashStyle: "ShortDash",
                    color: undefined,
                };
            }
        });

        if (showRolls) {
            // determine min/max diffs of entire series
            let min = 0;
            let max = 0;
            for (let i = 0; i < series.length; i++) {
                let cur = series[i].data;
                if (cur[0].diff <= min) min = cur[0].diff;
                if (cur[cur.length-1].diff >= max) max = cur[cur.length-1].diff;
            }
            // fill in missing data points from min->max on all series
            for (let i = 0; i < series.length; i++) {
                let cur = series[i].data; // arr of objs
                let filled_data = [];
                let cur_i = 0;
                for (let day = min; day <= max; day++) {
                    if (typeof cur[cur_i] !== 'undefined' && day === cur[cur_i].diff) {
                        filled_data.push(cur[cur_i++]);
                    } else {
                        // filled_data.push({x: day, y: null, curDateStr: null, rollDate: null, diff: day, time: null});
                        filled_data.push({y: null, curDateStr: null, rollDate: null, diff: day, time: null});
                    }
                }
                series[i].data = filled_data
            }
        }
        return series;
    }
    

    // function getConfig(item) {
    function getConfig() {   
        let highchartBasicConfig = getChartConfig();
        // checking item is from original Seasonality setup, don't get rid of yet...
        // if (item === "spread") {
        //     return {
        //         ...highchartBasicConfig,
        //         chart: {
        //             ...highchartBasicConfig.chart,
        //             height: 600,
        //         },
        //         series: generateSeasonal(
        //             state.data.filter((ele) => ele.spread).map((ele) => [ele.DATE, ele.spread])
        //         ),
        //     };
        // } else if (item === "ratio") {
        //     return {
        //         ...highchartBasicConfig,
        //         chart: {
        //             ...highchartBasicConfig.chart,
        //             height: 600,
        //         },
        //         series: generateSeasonal(
        //             state.data.filter((ele) => ele.ratio).map((ele) => [ele.DATE, ele.ratio])
        //         ),
        //     };
        // } else if (item === "ticker1") {
        let res = generateSeasonal(state.data.filter((ele) => ele.ratio).map((ele) => [ele.DATE, ele[state.meta.ticker1]]))
        return {
            ...highchartBasicConfig,
            chart: {
                ...highchartBasicConfig.chart,
                height: 600,
            },
            series: res,
        };
    };


    function getChartConfig() {
        let chartConfig = {
            chart: {
                style: {
                    fontFamily: "roboto",
                },
                backgroundColor: "rgba(0,0,0,0)",
                animation: false,
                // alignTicks: true,
                events: {
                    redraw: function() {
                        if (allowChartRedraw) { // still working out how to dynamically adjust xAxis zoom
                            allowChartRedraw = false;
                        }
                    }
                }
            },
            title: { text: "" },
            credits: { enabled: false },
            navigator: {
                maskFill: "rgba(78, 78, 78, 0.15)",
                height: 35,
                animation: false,
            },
            scrollbar: {
                height: 0,
                buttonArrowColor: "rgba(0,0,0,0)",
            },
            legend: { enabled: 1 },
            rangeSelector: {
                buttons: [
                    { type: "ytd", text: "ALL" },
                    { type: "month", count: 9, text: "Q2" },
                    { type: "month", count: 6, text: "Q3" },
                    { type: "month", count: 3, text: "Q4" },
                    
                ],
                selected: 0,
                buttonTheme: {
                    fill: "#fff0",
                    style: {
                        fontSize: "1.1rem",
                        fontFamily: "var(--font-main)",
                        color: "#aaa",
                    },
                    floating: true,
                    states: {
                        hover: {
                            fill: "#fff0",
                            style: { color: "#000" },
                        },
                        select: {
                            fill: "#eee",
                            style: { color: "#111" },
                        },
                    },
                },
                labelStyle: { display: "none" },
                inputEnabled: false,
            },
            tooltip: {
                animation: false, 
                outside: true, 
                valueDecimals: 2,
                useHTML: true,
                pointFormat: !showRolls ? '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>' 
                    : showPercent ? '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/> <br/> [ {point.curDate} - {point.rollDate} ] = {point.diff}'
                        : '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/> [ {point.curDate} - {point.rollDate} ] = {point.diff}'
            },
            xAxis: {
                type: showRolls ? "categories" : "datetime",
                categories: dayCategories,
                crosshair: true,
                labels: {
                    format: showRolls ? '{value}' : '{value:%b %e}'
                },
                plotLines: [
                    plotLine // only diplay plotLine for showRolls charts
                ],
            },
            yAxis: { 
                gridLineColor: "#eee",
                labels: {
                    align: "left",
                    x: -1,
                    formatter: function() {
                        if (showPercent) {
                            return (this.value>0 ? "+"+this.value+"%" : this.value+"%")
                        } else {
                            return this.value // standard
                        }
                    }
                }
            },
            plotOptions: {
                series: {
                    compare: showPercent ? "percent" : "",
                    dataGrouping: { approximation: "close" },
                    animation: false,
                    connectNulls: true,
                },
            },
        };
    
        return chartConfig;
    }


    useEffect(() => {
        if (!isRollSymbol(props.bloombergRoot)) {
            setShowRolls(false);
        }
        onUpdate();
    }, [props.bloombergRoot]);


    useEffect(() => {
        onUpdate();
    }, [showRolls])


    // return list of roll buttons set up as 4 quarterly for ES, or 12 monthly for WASDE
    function getRollButtons() {
        if (!showRolls) return;
        let res = [];
        let N = root === 'ES' ? 4 : 12;
        for (let i = 1; i <= N; i++) {
            res.push(
                <button
                    key={i}
                    style={{backgroundColor: rollOption.value === i ? "#36c9ff" : "#d1d1d1", marginLeft: i === 1 ? "16" : "4", marginRight: "4px", width: "30px", height: "10", borderRadius: "50px"}}
                    onClick={() => setRollOption({label: "", value: i})}
                >
                    {root === 'ES' ? 'Q'+i : i+' '+monthMap[i]} {/* ES: 'Q1' , WASDE: '1 F' */}
                </button>
            )
        }
        return res;
    }


    // const chart1Config = getConfig();
    return (
        <Layout>
            <div className="ImbueSelectsWrapper">
                <ImbueSelect
                    title="Contract"
                    options={contractOptions}
                    defaultValue={defaultContract}
                    onChange={(e) => {
                        selectedContract1 = e.value
                        onUpdate()
                    }}
                />
                <ImbueSelect
                    title="Field"
                    options={fieldOptions}
                    defaultValue={defaultField}
                    onChange={(e) => {
                        selectedField1 = e.value
                        onUpdate()
                    }}
                />
                {/* <div className="CircleBtn CircleBtnLg" onClick={() => onUpdate()}>
                        <i className="fas fa-play"></i>
                </div> */}
                <button style={{backgroundColor: showPercent ? "#36c9ff" : "#d1d1d1", marginLeft: "10px", width: "80px", height: "20", borderRadius: "25px"}} 
                    onClick={() => setShowPercent(!showPercent)}>
                    Toggle %
                </button>
                { offerRolls &&
                    <button style={{backgroundColor: showRolls ? "#36c9ff" : "#d1d1d1", marginLeft: "10px", marginRight: "10px", width: "80px", height: "20", borderRadius: "25px"}} 
                        onClick={() => setShowRolls(!showRolls)}> 
                        Show Rolls
                    </button>
                }
                { showRolls && 
                    getRollButtons() 
                }
            </div>

            <div className="main">
                {isLoading ? (
                    <div className="LoadingText">
                        {hasError ? "Data is missing or other issues!" : "Loading..."}
                    </div>
                ) : !isInit ? (
                    ""
                ) : (
                    <div> {/* className="gridBox gridBox_2" > */}
                        <div>
                            <div className="row">
                                <ImbueTabs
                                    options={viewOptions}
                                    selectedValue={selectedView1}
                                    onClick={(val) => setSelectedView1(val)}
                                />
                            </div>
                            
                            <div>
                                <HighStockWrapper config={getConfig()} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}
