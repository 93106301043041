export const COUNTRYCODE = {
    US: "US",
    AU: "AU",
    UK: "GB",
    JN: "JP",
    CA: "CA",
    SW: "CH",
    BZ: "BR",
    CH: "CN",
    MA: "MY",
    NZ: "NZ",
    RU: "RU",
};

export const COUNTRYLOOKUP = {
    Global: { fips: "", iso: "" },
    "United States": { fips: "US", iso: "US" },
    "United Kingdom": { fips: "UK", iso: "GB" },
    "Russian Federation": { fips: "RS", iso: "RU" },
    China: { fips: "CH", iso: "CN" },
    Australia: { fips: "AS", iso: "AU" },
    France: { fips: "FR", iso: "FR" },
    Japan: { fips: "JA", iso: "JP" },
    Germany: { fips: "GM", iso: "DE" },
    Afghanistan: { fips: "AF", iso: "AF" },
    "Aland Islands": { fips: "–", iso: "AX" },
    Albania: { fips: "AL", iso: "AL" },
    Algeria: { fips: "AG", iso: "DZ" },
    "American Samoa": { fips: "AQ", iso: "AS" },
    Andorra: { fips: "AN", iso: "AD" },
    Angola: { fips: "AO", iso: "AO" },
    Anguilla: { fips: "AV", iso: "AI" },
    Antarctica: { fips: "AY", iso: "AQ" },
    "Antigua and Barbuda": { fips: "AC", iso: "AG" },
    Argentina: { fips: "AR", iso: "AR" },
    Armenia: { fips: "AM", iso: "AM" },
    Aruba: { fips: "AA", iso: "AW" },
    Austria: { fips: "AU", iso: "AT" },
    Azerbaijan: { fips: "AJ", iso: "AZ" },
    Bahamas: { fips: "BF", iso: "BS" },
    Bahrain: { fips: "BA", iso: "BH" },
    Bangladesh: { fips: "BG", iso: "BD" },
    Barbados: { fips: "BB", iso: "BB" },
    Belarus: { fips: "BO", iso: "BY" },
    Belgium: { fips: "BE", iso: "BE" },
    Belize: { fips: "BH", iso: "BZ" },
    Benin: { fips: "BN", iso: "BJ" },
    Bermuda: { fips: "BD", iso: "BM" },
    Bhutan: { fips: "BT", iso: "BT" },
    "Bolivia, Plurinational State Of": { fips: "BL", iso: "BO" },
    "Bonaire, Sint Eustatius and Saba": { fips: "–", iso: "BQ" },
    "Bosnia and Herzegovina": { fips: "BK", iso: "BA" },
    Botswana: { fips: "BC", iso: "BW" },
    "Bouvet Island": { fips: "BV", iso: "BV" },
    Brazil: { fips: "BR", iso: "BR" },
    "British Indian Ocean Territory": { fips: "IO", iso: "IO" },
    "Brunei Darussalam": { fips: "BX", iso: "BN" },
    Bulgaria: { fips: "BU", iso: "BG" },
    "Burkina Faso": { fips: "UV", iso: "BF" },
    Burundi: { fips: "BY", iso: "BI" },
    "Cabo Verde": { fips: "CV", iso: "CV" },
    Cambodia: { fips: "CB", iso: "KH" },
    Cameroon: { fips: "CM", iso: "CM" },
    Canada: { fips: "CA", iso: "CA" },
    "Cayman Islands": { fips: "CJ", iso: "KY" },
    "Central African Republic": { fips: "CT", iso: "CF" },
    Chad: { fips: "CD", iso: "TD" },
    Chile: { fips: "CI", iso: "CL" },
    "Christmas Island": { fips: "KT", iso: "CX" },
    "Cocos (Keeling) Islands": { fips: "CK", iso: "CC" },
    Colombia: { fips: "CO", iso: "CO" },
    Comoros: { fips: "CN", iso: "KM" },
    Congo: { fips: "CF", iso: "CG" },
    "Congo, the Democratic Republic of the": { fips: "CG", iso: "CD" },
    "Cook Islands": { fips: "CW", iso: "CK" },
    "Costa Rica": { fips: "CS", iso: "CR" },
    "Cote d’Ivoire": { fips: "IV", iso: "CI" },
    Croatia: { fips: "HR", iso: "HR" },
    Cuba: { fips: "CU", iso: "CU" },
    Curacao: { fips: "UC", iso: "CW" },
    Cyprus: { fips: "CY", iso: "CY" },
    Czechia: { fips: "EZ", iso: "CZ" },
    Denmark: { fips: "DK", iso: "DK" },
    Djibouti: { fips: "DJ", iso: "DJ" },
    Dominica: { fips: "DO", iso: "DM" },
    "Dominican Republic": { fips: "DR", iso: "DO" },
    Ecuador: { fips: "EC", iso: "EC" },
    Egypt: { fips: "EG", iso: "EG" },
    "El Salvador": { fips: "ES", iso: "SV" },
    "Equatorial Guinea": { fips: "EK", iso: "GQ" },
    Eritrea: { fips: "ER", iso: "ER" },
    Estonia: { fips: "EN", iso: "EE" },
    Ethiopia: { fips: "ET", iso: "ET" },
    "Falkland Islands (Malvinas)": { fips: "FK", iso: "FK" },
    "Faroe Islands": { fips: "FO", iso: "FO" },
    Fiji: { fips: "FJ", iso: "FJ" },
    Finland: { fips: "FI", iso: "FI" },
    "French Guiana": { fips: "FG", iso: "GF" },
    "French Polynesia": { fips: "FP", iso: "PF" },
    "French Southern Territories": { fips: "FS", iso: "TF" },
    Gabon: { fips: "GB", iso: "GA" },
    Gambia: { fips: "GA", iso: "GM" },
    Georgia: { fips: "GG", iso: "GE" },
    Ghana: { fips: "GH", iso: "GH" },
    Gibraltar: { fips: "GI", iso: "GI" },
    Greece: { fips: "GR", iso: "GR" },
    Greenland: { fips: "GL", iso: "GL" },
    Grenada: { fips: "GJ", iso: "GD" },
    Guadeloupe: { fips: "GP", iso: "GP" },
    Guam: { fips: "GQ", iso: "GU" },
    Guatemala: { fips: "GT", iso: "GT" },
    Guernsey: { fips: "GK", iso: "GG" },
    Guinea: { fips: "GV", iso: "GN" },
    "Guinea-Bissau": { fips: "PU", iso: "GW" },
    Guyana: { fips: "GY", iso: "GY" },
    Haiti: { fips: "HA", iso: "HT" },
    "Heard Island and McDonald Islands": { fips: "HM", iso: "HM" },
    "Holy See": { fips: "VT", iso: "VA" },
    Honduras: { fips: "HO", iso: "HN" },
    "Hong Kong": { fips: "HK", iso: "HK" },
    Hungary: { fips: "HU", iso: "HU" },
    Iceland: { fips: "IC", iso: "IS" },
    India: { fips: "IN", iso: "IN" },
    Indonesia: { fips: "ID", iso: "ID" },
    "Iran, Islamic Republic of": { fips: "IR", iso: "IR" },
    Iraq: { fips: "IZ", iso: "IQ" },
    Ireland: { fips: "EI", iso: "IE" },
    "Isle of Man": { fips: "IM", iso: "IM" },
    Israel: { fips: "IS", iso: "IL" },
    Italy: { fips: "IT", iso: "IT" },
    Jamaica: { fips: "JM", iso: "JM" },
    Jersey: { fips: "JE", iso: "JE" },
    Jordan: { fips: "JO", iso: "JO" },
    Kazakhstan: { fips: "KZ", iso: "KZ" },
    Kenya: { fips: "KE", iso: "KE" },
    Kiribati: { fips: "KR", iso: "KI" },
    "Korea, Democratic People’s Republic of": { fips: "KN", iso: "KP" },
    "Korea, Republic of": { fips: "KS", iso: "KR" },
    Kuwait: { fips: "KU", iso: "KW" },
    Kyrgyzstan: { fips: "KG", iso: "KG" },
    "Lao People’s Democratic Republic": { fips: "LA", iso: "LA" },
    Latvia: { fips: "LG", iso: "LV" },
    Lebanon: { fips: "LE", iso: "LB" },
    Lesotho: { fips: "LT", iso: "LS" },
    Liberia: { fips: "LI", iso: "LR" },
    Libya: { fips: "LY", iso: "LY" },
    Liechtenstein: { fips: "LS", iso: "LI" },
    Lithuania: { fips: "LH", iso: "LT" },
    Luxembourg: { fips: "LU", iso: "LU" },
    Macao: { fips: "MC", iso: "MO" },
    "Macedonia, the former Yugoslav Republic of": { fips: "MK", iso: "MK" },
    Madagascar: { fips: "MA", iso: "MG" },
    Malawi: { fips: "MI", iso: "MW" },
    Malaysia: { fips: "MY", iso: "MY" },
    Maldives: { fips: "MV", iso: "MV" },
    Mali: { fips: "ML", iso: "ML" },
    Malta: { fips: "MT", iso: "MT" },
    "Marshall Islands": { fips: "RM", iso: "MH" },
    Martinique: { fips: "MB", iso: "MQ" },
    Mauritania: { fips: "MR", iso: "MR" },
    Mauritius: { fips: "MP", iso: "MU" },
    Mayotte: { fips: "MF", iso: "YT" },
    Mexico: { fips: "MX", iso: "MX" },
    "Micronesia, Federated States of": { fips: "FM", iso: "FM" },
    "Moldova, Republic of": { fips: "MD", iso: "MD" },
    Monaco: { fips: "MN", iso: "MC" },
    Mongolia: { fips: "MG", iso: "MN" },
    Montenegro: { fips: "MJ", iso: "ME" },
    Montserrat: { fips: "MH", iso: "MS" },
    Morocco: { fips: "MO", iso: "MA" },
    Mozambique: { fips: "MZ", iso: "MZ" },
    Myanmar: { fips: "BM", iso: "MM" },
    Namibia: { fips: "WA", iso: "NA" },
    Nauru: { fips: "NR", iso: "NR" },
    Nepal: { fips: "NP", iso: "NP" },
    Netherlands: { fips: "NL", iso: "NL" },
    "New Caledonia": { fips: "NC", iso: "NC" },
    "New Zealand": { fips: "NZ", iso: "NZ" },
    Nicaragua: { fips: "NU", iso: "NI" },
    Niger: { fips: "NG", iso: "NE" },
    Nigeria: { fips: "NI", iso: "NG" },
    Niue: { fips: "NE", iso: "NU" },
    "Norfolk Island": { fips: "NF", iso: "NF" },
    "Northern Mariana Islands": { fips: "CQ", iso: "MP" },
    Norway: { fips: "NO", iso: "NO" },
    Oman: { fips: "MU", iso: "OM" },
    Pakistan: { fips: "PK", iso: "PK" },
    Palau: { fips: "PS", iso: "PW" },
    "Palestine, State of": { fips: "WE", iso: "PS" },
    Panama: { fips: "PM", iso: "PA" },
    "Papua New Guinea": { fips: "PP", iso: "PG" },
    Paraguay: { fips: "PA", iso: "PY" },
    Peru: { fips: "PE", iso: "PE" },
    Philippines: { fips: "RP", iso: "PH" },
    Pitcairn: { fips: "PC", iso: "PN" },
    Poland: { fips: "PL", iso: "PL" },
    Portugal: { fips: "PO", iso: "PT" },
    "Puerto Rico": { fips: "RQ", iso: "PR" },
    Qatar: { fips: "QA", iso: "QA" },
    Reunion: { fips: "RE", iso: "RE" },
    Romania: { fips: "RO", iso: "RO" },
    Rwanda: { fips: "RW", iso: "RW" },
    "Saint Barthelemy": { fips: "TB", iso: "BL" },
    "Saint Helena, Ascension and Tristan da Cunha": { fips: "SH", iso: "SH" },
    "Saint Kitts and Nevis": { fips: "SC", iso: "KN" },
    "Saint Lucia": { fips: "ST", iso: "LC" },
    "Saint Martin": { fips: "RN", iso: "MF" },
    "Saint Pierre and Miquelon": { fips: "SB", iso: "PM" },
    "Saint Vincent and the Grenadines": { fips: "VC", iso: "VC" },
    Samoa: { fips: "WS", iso: "WS" },
    "San Marino": { fips: "SM", iso: "SM" },
    "Sao Tome and Principe": { fips: "TP", iso: "ST" },
    "Saudi Arabia": { fips: "SA", iso: "SA" },
    Senegal: { fips: "SG", iso: "SN" },
    Serbia: { fips: "RI", iso: "RS" },
    Seychelles: { fips: "SE", iso: "SC" },
    "Sierra Leone": { fips: "SL", iso: "SL" },
    Singapore: { fips: "SN", iso: "SG" },
    "Sint Maarten": { fips: "NN", iso: "SX" },
    Slovakia: { fips: "LO", iso: "SK" },
    Slovenia: { fips: "SI", iso: "SI" },
    "Solomon Islands": { fips: "BP", iso: "SB" },
    Somalia: { fips: "SO", iso: "SO" },
    "South Africa": { fips: "SF", iso: "ZA" },
    "South Georgia and the South Sandwich Islands": { fips: "SX", iso: "GS" },
    "South Sudan": { fips: "OD", iso: "SS" },
    Spain: { fips: "SP", iso: "ES" },
    "Sri Lanka": { fips: "CE", iso: "LK" },
    Sudan: { fips: "SU", iso: "SD" },
    Suriname: { fips: "NS", iso: "SR" },
    "Svalbard and Jan Mayen": { fips: "SV", iso: "SJ" },
    Swaziland: { fips: "WZ", iso: "SZ" },
    Sweden: { fips: "SW", iso: "SE" },
    Switzerland: { fips: "SZ", iso: "CH" },
    "Syrian Arab Republic": { fips: "SY", iso: "SY" },
    Taiwan: { fips: "TW", iso: "TW" },
    Tajikistan: { fips: "TI", iso: "TJ" },
    "Tanzania, United Republic of": { fips: "TZ", iso: "TZ" },
    Thailand: { fips: "TH", iso: "TH" },
    "Timor-Leste": { fips: "TT", iso: "TL" },
    Togo: { fips: "TO", iso: "TG" },
    Tokelau: { fips: "TL", iso: "TK" },
    Tonga: { fips: "TN", iso: "TO" },
    "Trinidad and Tobago": { fips: "TD", iso: "TT" },
    Tunisia: { fips: "TS", iso: "TN" },
    Turkey: { fips: "TU", iso: "TR" },
    Turkmenistan: { fips: "TX", iso: "TM" },
    "Turks and Caicos Islands": { fips: "TK", iso: "TC" },
    Tuvalu: { fips: "TV", iso: "TV" },
    Uganda: { fips: "UG", iso: "UG" },
    Ukraine: { fips: "UA", iso: "UA" },
    "United Arab Emirates": { fips: "AE", iso: "AE" },
    "United States Minor Outlying Islands": { fips: "–", iso: "UM" },
    Uruguay: { fips: "UY", iso: "UY" },
    Uzbekistan: { fips: "UZ", iso: "UZ" },
    Vanuatu: { fips: "NH", iso: "VU" },
    "Venezuela, Bolivarian Republic of": { fips: "VE", iso: "VE" },
};
