import React, { Component } from "react";
import styled from "styled-components";
import ImbueSelect from "../../../components/input/ImbueSelect";
import { api_position_risk } from "../../../helpers/API";
import HighChartWrapper from "../../../components/chart/HighChartWrapper";
import { highchartBasicConfig } from "../../../config";

const Layout = styled.div``;

const stranglesizingoptions = [
    'root__W 2 0.15', 'root__W 4 0.3', 'root__SB 8 0.45',
    'root__CL 6 0.15', 'root__CC 2 0.3', 'root__SM 2 0.15',
    'root__SB 4 0.3', 'root__S 6 0.3', 'root__CL 4 0.15',
    'root__GC 8 0.15', 'root__CL 8 0.45', 'root__SM 4 0.3',
    'root__GC 4 0.3', 'root__CC 8 0.45', 'root__CC 4 0.15',
    'root__S 6 0.15', 'root__SB 6 0.45', 'root__LC 2 0.15',
    'root__W 2 0.3', 'root__CC 6 0.15', 'root__SB 2 0.15',
    'root__CL 4 0.3', 'root__SM 2 0.45', 'root__CC 4 0.45',
    'root__CL 6 0.3', 'root__SB 6 0.3', 'root__S 8 0.3',
    'root__CL 2 0.15', 'root__SM 4 0.15', 'root__CC 4 0.3',
    'root__CL 8 0.3', 'root__C 4 0.15', 'root__SM 8 0.3',
    'root__CL 6 0.45', 'root__GC 8 0.3', 'root__S 2 0.45',
    'root__LC 2 0.3', 'root__CL 2 0.3', 'root__SM 6 0.15',
    'root__S 6 0.45', 'root__LC 2 0.45', 'root__C 2 0.45',
    'root__C 2 0.3', 'root__GC 6 0.45', 'root__W 4 0.45',
    'root__GC 6 0.3', 'root__CC 2 0.45', 'root__GC 2 0.3',
    'root__GC 2 0.45', 'root__SM 4 0.45', 'root__CC 2 0.15',
    'root__CL 4 0.45', 'root__SM 2 0.3', 'root__S 2 0.15',
    'root__GC 4 0.45', 'root__CC 8 0.15', 'root__CC 6 0.45',
    'root__CL 2 0.45', 'root__GC 8 0.45', 'root__S 4 0.3',
    'root__SM 6 0.45', 'root__LC 8 0.15', 'root__W 2 0.45',
    'root__SB 2 0.3', 'root__SM 8 0.15', 'root__SM 8 0.45',
    'root__S 8 0.45', 'root__S 2 0.3', 'root__C 4 0.3',
    'root__LC 8 0.45', 'root__SB 4 0.45', 'root__W 4 0.15',
    'root__S 8 0.15', 'root__S 4 0.15', 'root__C 4 0.45',
    'root__GC 2 0.15', 'root__C 2 0.15', 'root__CC 6 0.3',
    'root__SM 6 0.3', 'root__SB 2 0.45', 'root__CC 8 0.3',
    'root__S 4 0.45', 'root__SB 8 0.3', 'root__GC 4 0.15',
    'root__CL 8 0.15', 'root__LC 8 0.3', 'root__GC 6 0.15',
    'root__SB 4 0.15', 'root__SB 6 0.15', 'root__W 8 0.45',
    'root__C 8 0.15', 'root__C 8 0.3', 'root__W 8 0.15',
    'root__LC 6 0.15', 'root__C 8 0.45', 'root__LC 6 0.3',
    'root__LC 6 0.45', 'root__W 8 0.3', 'root__W 6 0.3',
    'root__C 6 0.45', 'root__LC 4 0.3', 'root__LC 4 0.45',
    'root__LC 4 0.15', 'root__C 6 0.15', 'root__W 6 0.45',
    'root__C 6 0.3', 'root__W 6 0.15', 'root__SB 8 0.15',
    'root__SI 4 0.45', 'root__SI 2 0.45', 'root__SI 6 0.45',
    'root__SI 8 0.45', 'root__SI 6 0.3', 'root__SI 8 0.3',
    'root__SI 4 0.3', 'root__SI 2 0.3', 'root__SI 8 0.15',
    'root__SI 4 0.15', 'root__SI 2 0.15', 'root__SI 6 0.15',
    'root__LH 2 0.15', 'root__LH 2 0.3', 'root__LH 2 0.45',
    'root__LH 8 0.45', 'root__LH 6 0.15', 'root__LH 4 0.3',
    'root__LH 6 0.45', 'root__LH 8 0.15', 'root__LH 4 0.45',
    'root__LH 8 0.3', 'root__LH 4 0.15', 'root__LH 6 0.3',
    'root__KC 8 0.3', 'root__KC 8 0.45', 'root__KC 8 0.15',
    'root__KC 6 0.3', 'root__KC 6 0.15', 'root__KC 6 0.45',
    'root__KC 4 0.45', 'root__KC 4 0.15', 'root__KC 4 0.3',
    'root__KC 2 0.45', 'root__KC 2 0.15', 'root__KC 2 0.3',
    'root__BO 4 0.3', 'root__BO 8 0.45', 'root__BO 6 0.45',
    'root__CT 2 0.15', 'root__BO 8 0.3', 'root__BO 4 0.45',
    'root__CT 2 0.45', 'root__BO 6 0.15', 'root__BO 8 0.15',
    'root__BO 6 0.3', 'root__BO 4 0.15', 'root__CT 2 0.3',
    'root__BO 2 0.3', 'root__CT 4 0.45', 'root__BO 2 0.45',
    'root__BO 2 0.15', 'root__CT 8 0.15', 'root__CT 6 0.45',
    'root__CT 8 0.3', 'root__CT 8 0.45', 'root__CT 4 0.3',
    'root__CT 4 0.15', 'root__CT 6 0.15', 'root__CT 6 0.3',
    'root__DA 4 0.45', 'root__DA 8 0.45', 'root__DA 6 0.45',
    'root__DA 2 0.45', 'root__DA 4 0.3', 'root__DA 2 0.3',
    'root__DA 8 0.3', 'root__DA 6 0.3', 'root__DA 6 0.15',
    'root__DA 4 0.15', 'root__DA 2 0.15', 'root__DA 8 0.15',
    'root__FC 4 0.3', 'root__FC 4 0.45', 'root__FC 2 0.3',
    'root__FC 6 0.45', 'root__FC 6 0.3', 'root__FC 8 0.3',
    'root__FC 2 0.15', 'root__FC 2 0.45', 'root__FC 6 0.15',
    'root__FC 4 0.15', 'root__FC 8 0.15', 'root__FC 8 0.45'
].map(ele => ({label: ele, value: ele}) )

export default class StrangleSizing extends Component {
    state = {
        assets:  this.props.func.get_retained_options("ss", "assets") ||  stranglesizingoptions.slice(0, 4),
        weights: {},
        isLoading: 0,
        defaultWeight: 1,
        data: null
    };

    onUpdate = () => {
        let selectedAssets = this.state.assets.map((ele) => ele.value);

        let weights = {};
        for (let item of selectedAssets) {
            weights[item] = this.state.weights[item]
                ? parseFloat(this.state.weights[item])
                : this.state.defaultWeight;
        }

        const paras = {
            query: "strangle_sizing",
            assets: selectedAssets,
            weights: JSON.stringify(weights),
        };

        this.setState({ isLoading: 1 }, () => {
            api_position_risk(paras).then((res) => {
                console.log(res.data);
                this.setState({
                    data: res.data.result,
                    isLoading: 0
                })
            }).catch(e => console.log(e));
        });
    };

    render() {
        const assetsOptions = stranglesizingoptions

        const renderMultiInputs = () => {
            const selectedAssets = this.state.assets
            return (
                <section>
                    <div
                        className="gridBox gridBox_8"
                        style={{ gridGap: "10px" }}
                    >
                        {selectedAssets.map((ele) => {
                            return (
                                <div className="ImbueCardRounded">
                                    <div className="title">{ele.label}</div>
                                    <div className="content">
                                        <input
                                            value={
                                                this.state.weights[ele.value] ||
                                                "1"
                                            }
                                            onChange={(e) => {
                                                let currentWeights = this.state
                                                    .weights;
                                                currentWeights[ele.value] =
                                                    e.target.value;
                                                this.setState({
                                                    weights: currentWeights,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </section>
            );
        };

        const renderResult = () => {
            if (this.state.isLoading) {
                return <div className="LoadingText">Loading...</div>
            }else {
                if (this.state.data) {
                    const columns = [
                        "Cornish-Fisher",
                        "Gaussian",
                        "Historic"
                    ]
                    const chartConfig = {
                        ...highchartBasicConfig,
                        xAxis: {
                            categories: this.state.data.map(item => item.index),
                            crosshair: true
                        },
                        series: columns.map(c => {
                            return ({
                                type: "column",
                                name: c,
                                data: this.state.data.map(item => item[c])
                            })
                        })
                    }                
                    return <HighChartWrapper config={chartConfig} height="600px"/>
                }else {
                    return ""
                }
            }

        }

        return (
            <Layout>
                <div class="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="Assets"
                        value={this.state.assets}
                        options={assetsOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(e) => this.setState({ assets: e || [] }, () => {
                            this.props.func.register_options("ss", "assets", this.state.assets) 
                        })}
                        width={600}
                    />
                    <div
                        className="CircleBtn CircleBtnLg"
                        onClick={this.onUpdate}
                    >
                        <i className="fas fa-play"></i>
                    </div>
                </div>
                <div>{renderMultiInputs()}</div>
                {renderResult()}
            </Layout>
        );
    }
}
