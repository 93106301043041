import React, { useEffect } from 'react'
import QuoteBlock from './DashboardQuoteBlock'
import { LoadingPage } from "../helpers/SimpleComponents";

const lodash = require('lodash');

const minuteAttrs = ["root", "symbol", "session_open", "session_high", "session_low", "px_high", "px_low", 
    "px_last", "px_settle", "high_limit_price", "low_limit_price", "date"];
const minuteCalcAttrs = ["atr", "atr_z", "session_vwap", "session_vwap_diff_z"];

var WS; // web socket

// determine if current URL is live/development
// wss for live/production, ws for development/testing
var wsUrl = window.location.protocol === 'https:'
    ? "wss://www.contango.net:9534/dashboard"
    : "ws://www.contango.net:9533/dashboard";

// only logs in development/testing setting, does nothing when live
function debugLog(string) {
    if (wsUrl === "ws://www.contango.net:9533/dashboard") {
        console.log(string);
    }
}

// initialize the state data structure.  rootMap< key: roots, val: new Map() >
function initStatus(roots) {
    const rootMap = new Map();
    // Add each root to the primary map
    for (let i = 0; i < roots.length; i++) {
        // initliaze each root's map with a new map to be later filled by cache message data
        rootMap.set(roots[i], new Map());
        //console.log(roots[i])
    }
    if (rootMap.size !== roots.length) console.error("rootMap ERROR -- lengths uneven!");
    return rootMap;
}


// create/return JSON obj with necessary data from curEvt to be passed down to QuoteBlock
// TODO: indicate highest volume quote...
function makeCurEvtObj(curEvt) {
    return {root: curEvt.root,
            symbol: curEvt.symbol, 
            session_open: curEvt.session_open, 
            session_high: curEvt.session_high, 
            session_low: curEvt.session_low, 
            px_high: curEvt.px_high,
            px_low: curEvt.px_low, 
            px_last: curEvt.px_last, 
            px_settle: curEvt.px_settle,
            high_limit_price: curEvt.high_limit_price,
            low_limit_price: curEvt.low_limit_price,
            date: curEvt.date
    };
}


function updateCurObj(symbolObj, curEvt) {
    if (curEvt.type === "minute") {
        minuteAttrs.forEach(a => {symbolObj[a] = curEvt[a]});
    } else if (curEvt.type === "minute-calc") {
        minuteCalcAttrs.forEach(a => {symbolObj[a] = curEvt[a]});
        symbolObj.isMaxVol = true;
    }
    return symbolObj;
}



export default function DashboardQuoteBoard(props) {
    // ------------------------------------------------------------------------------------------------------------------ \\
    // --------------------------------------------------  INITIALIZE  -------------------------------------------------- \\
    // ------------------------------------------------------------------------------------------------------------------ \\

    // status: rootMap<"ZC", monthsMap>  ->  months<"ZCK1", quoteObj>  ->  quote{symbol: , open: , high: , low: , px: }
    // status: map(root) of maps(symbols) of objects(price_data)
    const [status, setStatus] = React.useState(() => initStatus(props.allRoots));
    const [cacheState, setCacheState] = React.useState(false);
    const statusRef = React.useRef({});


    // component mounts: set Ref to copy fresh status, init websocket
    // component dismounts: close websocket 
    useEffect(() => {
        statusRef.current = status;
        initWebSocket();
        return () => {
            closeWebSocket();
        }
    }, []);
    
    
    // when curRoot changes, trigger re-render to show new root's latest data
    useEffect(() => {
        if (statusRef.current && statusRef.current !== {}) {
            setStatus(statusRef.current);
        }
    }, [props.curRoot]);


    // check if the current message should trigger update
    function boardShouldUpdate(evt) { 
        return (
            statusRef.current.has(evt.root) && 
            statusRef.current.get(evt.root).has(evt.symbol) &&
            evt.px_last !== statusRef.current.get(evt.root).get(evt.symbol).px_last
        );
    }


    // check that current cache message should trigger update
    function cacheShouldUpdate(curEvt) {
        return (
            typeof statusRef.current !== 'undefined' && 
            statusRef.current.has(curEvt.root) && 
            new Date() <= new Date(curEvt.expiry)
        );
    }


    // Update status map using clones and Ref to only re-render when current root receives new data,
    //  store non-current root data in useRef so it's immediately available if user changes root
    function updateStatus(curEvt) {
        const clone = lodash.cloneDeep(statusRef.current); // clone = map({root, subClone})
        const subClone = lodash.cloneDeep(clone.get(curEvt.root)); // subClone = map({contract, json_obj})

        if (curEvt.type === "minute") {
            
            if (!subClone.has(curEvt.symbol)) { // new symbol, create fresh obj
                const newObj = makeCurEvtObj(curEvt);

                subClone.set(curEvt.symbol, newObj);
                clone.set(curEvt.root, subClone);

                statusRef.current = clone;

            } else { // update symbol obj, maintain existing minute-calc data
                let symbolObj = subClone.get(curEvt.symbol);
                subClone.set(curEvt.symbol, updateCurObj(symbolObj, curEvt));
                clone.set(curEvt.root, subClone);

                statusRef.current = clone;
            }
            // new data for current root, re-render to show 
            if (curEvt.root === props.curRoot && !cacheState) {
                setStatus(statusRef.current)
            } else {
                // console.log(`non-root msg:  ${curEvt.symbol} | ${curEvt.date}`)
            }
        // Currently unused...
        } else if (curEvt.type === "minute-calc") { // update symbol obj, maintain minute data
            let symbolObj = subClone.get(curEvt.symbol);
            subClone.set(curEvt.symbol, updateCurObj(symbolObj, curEvt));
            clone.set(curEvt.root, subClone);

            statusRef.current = clone;
            if (curEvt.root === props.curRoot) {
                setStatus(statusRef.current);
            }
        }
    }


    // initialize web socket connection and set its functions 
    function initWebSocket() {
        if (!WS || WS.readyState !== WebSocket.OPEN) {
            debugLog("ws OPEN");
            WS = new WebSocket(wsUrl);
            WS.onmessage = function(evt) { onMessage(evt) };
            WS.onclose = function(evt) { onClose(evt) };
        }
    }


    // handle incoming web socket messages. Update state and notify cache complete when appropriate
    function onMessage(evt) {
        const curEvt = JSON.parse(evt.data);
        if (evt !== null && curEvt !== null) { // not cache complete message
            if ((!cacheState && cacheShouldUpdate(curEvt)) || (cacheState && boardShouldUpdate(curEvt))) {
                // console.log(curEvt)
                updateStatus(curEvt);
            }
        } else { // cache complete message
            debugLog(`Cache message complete for:  ${props.curRoot}`);
            // trigger re-render for the actual board to now be displayed
            setCacheState(true);
            setStatus(statusRef.current);
        }
    }


    // properly close the web socket connection
    function closeWebSocket() {
        if (WS && WS.readyState === WebSocket.OPEN) {
            debugLog("ws CLOSE");
            WS.close();
        }
    }


    // triggered on unplanned web socket closure. Attempt to reconnect in loop
    function onClose(evt) {
        // don't attempt reconnecting on clean WS closure
        if (evt && !evt.wasClean) {
            console.log("Web Socket disconnected. retrying in 10 seconds...");
            setTimeout(() => {
                initWebSocket();
            }, 10*1000);
        }
    }


    // triggered when an individual quote piece is clicked
    // childData (string):  "ZSX1 : 2021-10-20 08:30:00"
    const handleCallback = (childData) => {
        console.log(childData);
    };


    // ----------------------------------------------------------------------------------------------------------------- \\
    // -------------------------------------------------  RENDER  ------------------------------------------------------ \\
    // ----------------------------------------------------------------------------------------------------------------- \\
    if (cacheState && typeof statusRef.current !== 'undefined' && status.has(props.curRoot)) {
        // STANDARD VIEW
        return ( <QuoteBlock status={status.get(props.curRoot)} boardCallback={handleCallback} /> );
    } else if (typeof status !== undefined && !status.has(props.curRoot)) {
        // ERROR ON ROOT
        return ( <h3 style={{color: "red"}}> {`Error: quotes for ${props.curRoot} do not exist.`} </h3> );
    } else { 
        // LOADING
        return ( <div style={{marginLeft: "8px"}}> {LoadingPage()} </div> );
    }
}
