import React, { useState, useEffect } from 'react'
import { api_bid_ask_averages } from "../../helpers/API";
// import ImbueSelect from "../../components/input/ImbueSelect";
import styled from "styled-components";
import "./style.css";
import { GLOBALSTYLED } from "../../config";
import { LoadingPage } from "../../helpers/SimpleComponents";
import HighStockWrapper from '../../components/chart/HighStockWrapper';

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)`
    .chartWrapper {
        background: white;
        padding: 20px;
        box-shadow: var(--boxshadow-aws);
    }
    .fullScreenImgWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
    }
    .fullScreenImgWrapper img {
    }
`;


let dataReceived = false;
let firstRun = true;


export default function BidAskAverages(props) {

    const [state, setState] = useState();
    const [isLoading, setIsLoading] = useState(false);

    if (firstRun) {
        firstRun = false;
        getData();
    }


    // called when props are changed
    useEffect(() => {
        if (!firstRun) {
            getData()
        }
        return () => {
            dataReceived = false;
        };
    }, [props.root]);


    useEffect(() => {
        return () => {
            console.log("Unmount!")
            dataReceived = false;
            firstRun = true;
            setState({});
        }
    }, []);
    

    function getData() {
        console.log("fetching data...")
        setIsLoading(true);
        api_bid_ask_averages({root: props.root, ticker: props.ticker, start_date: '2022-06-01', contract: props.contract}).then(res => {
            // console.log(res.data)
            dataReceived = true;
            setState({data: res.data});
            setIsLoading(false);
        }).catch((e) => {
            console.error(e);
            setIsLoading(false);
        })
    }


    function getChartConfig() {
        return {
            chart: {
                // height: (9 / 16 * 60) + '%', // 16:9 ratio @ 60%
                animation: false,
            },
            title: {
                text: `${props.root}: Daily Bid/Asks`
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            rangeSelector: {
                enabled: true,
                selected: 0,
                buttons: [
                    { type: "all", text: "ALL" },
                    { type: "week", count: 6, text: "6W" },
                    { type: "week", count: 4, text: "4W" },
                    { type: "week", count: 2, text: "2W" },
                    { type: "week", count: 1, text: "1W" },
                ],
                inputDateFormat: "%Y-%m-%d",
                setInputEnabled: false, // known highcharts bug with text overlap
            },
            plotOptions: { 
                series: {
                    // stacking: 'normal', // conflicts with arearange chart type!
                    animation: false,
                    pointPadding: 0,
                    groupPadding: 0.05,
                    connectNulls: true
                },
            },
            xAxis: {
                labels: {
                    format: '{value:%m/%e}'
                },
                // crosshair: true
            },
            yAxis: [
                {
                    title: {
                        text: 'bid/ask diff',
                    },
                    showEmpty: false,
                },
            ],
            series: [
                {
                name: 'bid/ask',
                type: 'columnrange',
                data: state.data.results.map((ele) => [Date.parse("US/Chicago "+ele['ref_date']), ele['min_bid_ask_count'], ele['max_bid_ask_count']]),
                color: '#9bd4e4',
                zIndex: 2
                },
                {
                name: 'average',
                type: 'line',
                data: state.data.results.map((ele) => [Date.parse("US/Chicago "+ele['ref_date']), ele['avg_bid_ask_count']]),
                color: "black",
                lineWidth: 1.5,
                zIndex: 2
                },
                {
                name: 'σ',
                type: 'arearange',
                data: state.data.results.filter((ele) => ele['std_bid_ask_count_1m'] !== null).map((ele) => {
                    return [
                        Date.parse("US/Chicago "+ele['ref_date']), 
                        ele['avg_bid_ask_count_1m'] - (ele['std_bid_ask_count_1m']), 
                        ele['avg_bid_ask_count_1m'] + (ele['std_bid_ask_count_1m']) 
                    ]
                }),
                color: 'darkgray',
                opacity: 0.25,
                zIndex: 1,
                },
                {
                name: '2σ',
                type: 'arearange',
                data: state.data.results.filter((ele) => ele['std_bid_ask_count_1m'] !== null).map((ele) => {
                    return [
                        Date.parse("US/Chicago "+ele['ref_date']), 
                        ele['avg_bid_ask_count_1m'] - (2* ele['std_bid_ask_count_1m']), 
                        ele['avg_bid_ask_count_1m'] + (2* ele['std_bid_ask_count_1m']) 
                    ]
                }),
                color: 'gray',
                opacity: 0.25,
                zIndex: 0,
                },
            ],
            tooltip: {
                xDateFormat: "%a, %b %e, '%y", // Thu, Dec 16, 21
                valueDecimals: 2,
                split: false,
                shared: true
            },
            
        }
    }

    if (isLoading) {

        return (<Layout>{LoadingPage()}</Layout>);

    } else if (dataReceived && state) {

        let chartConfig = getChartConfig();

        return (
            <Layout>
                <HighStockWrapper config={chartConfig}/>
            </Layout>
        );
    } else {
        return (
            <Layout>
                <h2>Error: data could not be retrieved. Try refreshing...</h2>
            </Layout>
        );
    }
}
