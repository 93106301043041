import React, { Component } from "react";
import MainBoard from "../../layout/MainBoard";
import BacktestList from "../Backtest/BacktestList";

export default class StagingStrategiesSimList extends Component {

    componentDidMount() {
        document.title = "Portfolio Center";
    }
    
    render() {
        const list = [{ name: "Portfolio Center", path: this.props.match.path + "/pfcenter", component: BacktestList }];
        return <MainBoard title="Portfolio Center" list={list} />;
    }
}
