import React, { Component } from "react";

export default class StateBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payload: []
        };
    }

    componentDidMount() {
        this.setState({
            payload: this.props.payload
        });
    }

    onChangePayload(payload) {
        this.setState({
            payload
        });
    }

    render() {
        // payload [{value: <required>, label: <required>, text: <optional>}]
        const { payload } = this.state;

        return (
            <div className="flexBox">
                {payload.map((row, idx) => {
                    return (
                        <div key={idx} className={"CardSml" + (row.value >= 0 ? " CardSml_green" : " CardSml_red")}>
                            <div className="CardSml_label_right">{row.label}</div>
                            <div className="CardSml_value">{row.text || row.value}</div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
