import React, { Component } from "react";
import TickerChart from "./TickerChart";
import MultiInput from "../input/MultiInput";
import styled from "styled-components";
import { getParamFromUrl } from "../../helpers/Utils";

const Layout = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 70px calc(100% - 70px);
    height: 100%;
`;

export default class QueryTickerChart extends Component {
    constructor(props) {
        super(props);

        const ticker = getParamFromUrl("ticker") || "CL1 R:05_0_R Comdty";
        const field = getParamFromUrl("field") || "PX_LAST";
        const from = getParamFromUrl("from") || "2018-01-01";

        this.state = {
            ticker: ticker,
            field: field,
            from: from,
            schema: "blpv4",
        };
        this.handleChange = this.handleChange.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.timer = null;
    }

    handleChange(e) {
        // clearTimeout(this.timer);
        let newState = this.state;
        newState[e.target.name.toLowerCase()] = e.target.value;
        this.setState({
            ...newState,
        });
    }

    keyPress(event) {
        if (event.key === "Enter") {
            if (this.refs.tickerchart !== undefined) {
                this.refs.tickerchart.updateChart();
            }
        }
    }

    render() {
        const title = getParamFromUrl("title") || this.state.ticker;
        return (
            <Layout>
                <MultiInput
                    fields={[
                        { label: "Ticker", value: this.state["ticker"] },
                        { label: "Field", value: this.state["field"] },
                        { label: "From", value: this.state["from"] },
                        { label: "Schema", value: this.state["schema"] },
                    ]}
                    handleChange={this.handleChange}
                    keyPress={this.keyPress}
                />
                <TickerChart
                    ref="tickerchart"
                    tableSize={5}
                    ticker={this.state.ticker}
                    title={title}
                    field={this.state.field}
                    from={this.state.from}
                    to={this.state.to}
                    schema={this.state.schema}
                    chart_type="line"
                />
            </Layout>
        );
    }
}
