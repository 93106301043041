import React, { Component } from "react";
import { api_blp_seasonality } from "../../helpers/API";
import styled from "styled-components";
import ImbueSelect from "../../components/input/ImbueSelect";
import HighStockWrapper from "../../components/chart/HighStockWrapper";
import { GLOBALSELECTOPTIONS, GLOBALSTYLED, highchartBasicConfig } from "../../config";
import { generateSeasonal } from "../../helpers/ChartFunctions";
import ImbueTabs from "../../components/tabs/ImbueTabs";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;

const fullComdtyOptions = GLOBALSELECTOPTIONS.fullComdtyOptions;
const fullContractOptions = GLOBALSELECTOPTIONS.fullContractOptions;

// -- Options
// -- -- Ticker
const tickerOptions = fullComdtyOptions;
const defaultTicker = tickerOptions[0];
const defaultTicker2 = tickerOptions[1];
// -- -- Contract
const contractOptions = fullContractOptions;
const defaultContract = contractOptions[0];
// -- -- Field
const fieldOptions = [
    "PX_LAST",
    "PX_VOLUME",
    "OPEN_INTEREST",
    "FUT_AGGTE_OPEN_INT",
    "FUT_AGGTE_VOL",
    "HIST_CALL_IMP_VOL",
    "HIST_PUT_IMP_VOL",
    "1ST_MTH_IMPVOL_100.0%MNY_DF",
    "3MTH_IMPVOL_100.0%MNY_DF",
    "6MTH_IMPVOL_100.0%MNY_DF",
    "30day_impvol_100.0%MNY_DF",
].map((ele) => ({
    label: ele,
    value: ele,
}));
const defaultField = fieldOptions[0];
// -- -- view options
const viewOptions = [
    { label: "Commodity 1", value: "ticker1" },
    { label: "Commodity 2", value: "ticker2" },
    { label: "Spread", value: "spread" },
    { label: "Ratio", value: "ratio" },
];

export default class Seasonality extends Component {
    state = {
        selectedTicker1: defaultTicker.value,
        selectedTicker2: defaultTicker2.value,
        selectedContract1: defaultContract.value,
        selectedContract2: defaultContract.value,
        selectedField1: defaultField.value,
        selectedField2: defaultField.value,
        selectedView1: viewOptions[0].value,
        selectedView2: viewOptions[1].value,
        isLoading: 0,
        isInit: 0,
        hasError: 0,
        data: [],
        meta: {
            ticker1: "",
            ticker2: "",
        },
    };

    onUpdate = () => {
        const paras = {
            ticker1: this.state.selectedTicker1.toUpperCase() + this.state.selectedContract1 + " Comdty",
            ticker2: this.state.selectedTicker2.toUpperCase() + this.state.selectedContract2 + " Comdty",
            field1: this.state.selectedField1,
            field2: this.state.selectedField2,
        };
        this.setState({ isLoading: 1, hasError: 0 }, () => {
            api_blp_seasonality(paras)
                .then((res) => {
                    this.setState({
                        meta: res.data.meta,
                        data: res.data.data,
                        isLoading: 0,
                        isInit: 1,
                    });
                })
                .catch((e) => {
                    this.setState({
                        hasError: 1,
                    });
                });
        });
    };

    render() {
        const config = (item) => {
            if (item === "spread") {
                return {
                    ...highchartBasicConfig,
                    chart: {
                        ...highchartBasicConfig.chart,
                        height: 600,
                    },
                    series: generateSeasonal(
                        this.state.data.filter((ele) => ele.spread).map((ele) => [ele.DATE, ele.spread])
                    ),
                };
            } else if (item === "ratio") {
                return {
                    ...highchartBasicConfig,
                    chart: {
                        ...highchartBasicConfig.chart,
                        height: 600,
                    },
                    series: generateSeasonal(
                        this.state.data.filter((ele) => ele.ratio).map((ele) => [ele.DATE, ele.ratio])
                    ),
                };
            } else if (item === "ticker1") {
                return {
                    ...highchartBasicConfig,
                    chart: {
                        ...highchartBasicConfig.chart,
                        height: 600,
                    },
                    series: generateSeasonal(
                        this.state.data
                            .filter((ele) => ele.ratio)
                            .map((ele) => [ele.DATE, ele[this.state.meta.ticker1]])
                    ),
                };
            } else if (item === "ticker2") {
                return {
                    ...highchartBasicConfig,
                    chart: {
                        ...highchartBasicConfig.chart,
                        height: 600,
                    },
                    series: generateSeasonal(
                        this.state.data
                            .filter((ele) => ele.ratio)
                            .map((ele) => [ele.DATE, ele[this.state.meta.ticker2]])
                    ),
                };
            } else {
                return {};
            }
        };

        const chart1Config = config(this.state.selectedView1);
        const chart2Config = config(this.state.selectedView2);

        return (
            <Layout>
                <div className="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="Commodity 1"
                        options={tickerOptions}
                        defaultValue={defaultTicker}
                        onChange={(e) => this.setState({ selectedTicker1: e.value })}
                    />
                    <ImbueSelect
                        title="Contract 1"
                        options={contractOptions}
                        defaultValue={defaultContract}
                        onChange={(e) => this.setState({ selectedContract1: e.value })}
                    />
                    <ImbueSelect
                        title="Field 1"
                        options={fieldOptions}
                        defaultValue={defaultField}
                        onChange={(e) => this.setState({ selectedField1: e.value })}
                    />
                </div>
                <div className="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="Commodity 2"
                        options={tickerOptions}
                        defaultValue={defaultTicker2}
                        onChange={(e) => this.setState({ selectedTicker2: e.value })}
                    />
                    <ImbueSelect
                        title="Contract 2"
                        options={contractOptions}
                        defaultValue={defaultContract}
                        onChange={(e) => this.setState({ selectedContract2: e.value })}
                    />
                    <ImbueSelect
                        title="Field 2"
                        options={fieldOptions}
                        defaultValue={defaultField}
                        onChange={(e) => this.setState({ selectedField2: e.value })}
                    />
                    <div className="CircleBtn CircleBtnLg" onClick={this.onUpdate}>
                        <i className="fas fa-play"></i>
                    </div>
                </div>

                <div className="main">
                    {this.state.isLoading ? (
                        <div className="LoadingText">
                            {this.state.hasError ? "Data is missing or other issues!" : "Loading..."}
                        </div>
                    ) : !this.state.isInit ? (
                        ""
                    ) : (
                        <div className="gridBox gridBox_2">
                            <div>
                                <ImbueTabs
                                    options={viewOptions}
                                    selectedValue={this.state.selectedView1}
                                    onClick={(val) => this.setState({ selectedView1: val })}
                                />
                                <div>
                                    <HighStockWrapper config={chart1Config} />
                                </div>
                            </div>
                            <div>
                                <ImbueTabs
                                    options={viewOptions}
                                    selectedValue={this.state.selectedView2}
                                    onClick={(val) => this.setState({ selectedView2: val })}
                                />
                                <div>
                                    <HighStockWrapper config={chart2Config} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        );
    }
}
