import React, { Component } from "react";
import Select from "react-select";
import { IMBUESELECTSTYLES } from "../../config";
import styled from "styled-components";

const Layout = styled.div`
    .subtitle {
        font-family: var(--font-main);
        margin: 10px 0;
        color: #555;
        font-size: 1.2rem;
        text-align: center;
    }

    .optionlabel {
        font-family: var(--font-main);
        color: #444;
        font-size: 1.1rem;
    }
`;

export default class ImbueGroupSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selectedParas: [],
            parameters: [],
            optionsDict: {},
            prefilled: {},
            hasChanged: false
        };
        this.onChangeParameters = this.onChangeParameters.bind(this);
        this.collectOptions = this.collectOptions.bind(this);
    }

    componentDidMount() {
        const { prefilled } = this.props;
        const prefilledParameters = (prefilled || []).map(ele => ele.label);
        this.setState({
            selectedParas: prefilledParameters
        });
    }

    onChangeParameters(e) {
        this.setState({
            selectedParas: e.map(item => item.value),
            hasChanged: true
        });
    }

    collectOptions() {
        const { selectedParas } = this.state;
        const form = selectedParas.map(para => ({
            label: para,
            value: this.refs["groupselect__" + para] ? (this.refs["groupselect__" + para].state.value || {}).value : null
        }));
        return form;
    }

    render() {
        const { selectedParas, hasChanged } = this.state;

        const { prefilled, options } = this.props;

        const parameters = options.map(item => item.label);
        const optionsDict = options.reduce((a, b) => {
            let obj_ = {};
            obj_[b.label] = b.options;
            return {
                ...a,
                ...obj_
            };
        }, {});

        const prefilledParameters = (prefilled || []).map(ele => ele.label);
        const prefilledOptionsDict = (prefilled || []).reduce((a, b) => {
            let obj_ = {};
            obj_[b.label] = b.value;
            return {
                ...a,
                ...obj_
            };
        }, {});

        const isPrefilled = prefilledParameters.length > 0;

        return (
            <Layout key={isPrefilled ? "p-1" : "p-0"}>
                <Select styles={IMBUESELECTSTYLES} isMulti closeMenuOnSelect={false} options={parameters.map(ele => ({ label: ele, value: ele }))} defaultValue={prefilledParameters.map(ele => ({ label: ele, value: ele }))} onChange={this.onChangeParameters} />
                {selectedParas.length < 1 && prefilledParameters.length < 1 ? (
                    ""
                ) : (
                    <div>
                        <div className="subtitle">Options</div>
                        {(hasChanged ? selectedParas : prefilledParameters).map((ele, idx) => {
                            return (
                                <div className="flexBox flexBox_between" key={ele + "__" + idx} style={{ margin: "5px 0" }}>
                                    <span className="optionlabel">{ele}: </span>
                                    <div style={{ minWidth: "100px" }}>
                                        <Select ref={"groupselect__" + ele} styles={IMBUESELECTSTYLES} options={(optionsDict[ele] || []).map(ele => ({ label: String(ele), value: ele }))} defaultValue={ele in prefilledOptionsDict ? { label: String(prefilledOptionsDict[ele]), value: prefilledOptionsDict[ele] } : optionsDict[ele].map(ele => ({ label: String(ele), value: ele }))[0]} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </Layout>
        );
    }
}
