/**
 * @author      Claude Chen
 * @company     Imbue Capital Pty Ltd
 * @created     2018
 */

import React, { Component } from "react";
import { layoutConfig } from "../config";
import { Route, Switch, NavLink } from "react-router-dom";
import NavBar from "./NavBar";
import styled from "styled-components";
import { toUnique } from "../helpers/Utils";

const Layout = styled.div`
    background: rgb(245, 245, 245);
    display: grid;
    grid-template-rows: ${layoutConfig.navbarHeight +
    "px calc(100vh - " +
    (layoutConfig.navbarHeight + layoutConfig.textStripHeight) +
    "px)"};
`;

const SubLayout = styled.div`
    margin: ${(props) => (props.isMarginAuto === undefined ? undefined : props.isMarginAuto ? "auto 0" : undefined)};
    overflow: ${(props) => (props.isOverflow === undefined ? "auto" : props.isOverflow ? "auto" : "hidden")};
    -webkit-overflow-scrolling: touch;
`;

const HomepageLayout = styled.div`
    padding: 30px;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;

    font-family: var(--font-main);

    .grid_layout {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 30px;
    }

    .title {
        margin: 10px 0;
        font-size: 2rem;
    }

    .app_btn {
        transition: all 0.2s;
        color: #555;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .icon {
            font-size: 3rem;
            margin: 10px;
        }

        .app_btn_inner {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 1.1rem;
            width: 140px;
            height: 140px;
            border-radius: 10px;
        }

        .app_btn_inner:hover {
            color: #000;
            background: #ddd;
            cursor: pointer;
        }
    }
`;

class MainBoardHomepage extends Component {
    state = {};
    render() {
        const list = this.props.list;

        const categories = toUnique(list.map(ele => ele.category).filter(ele => ele))
        let grouped = [
            {
                category: "",
                list: list.filter(l => !l.category)
            },
            ...categories.map(cat => ({
                category: cat,
                list: list.filter(l => l.category === cat)
            }))
        ]    
        if (this.props.isenabled) {
            return (
                <HomepageLayout>
                    {
                        grouped.map(g => {
                            if (g.list.length > 0) {
                                return <div>
                                    <h3>{g.category}</h3>
                                     <div className="grid_layout">
                                        {g.list.map((ele) => {
                                            return (
                                                <div className="app_btn">
                                                    <NavLink to={ele.path}>
                                                        <div className="app_btn_inner">
                                                            <app className="icon">
                                                                <i className="fas fa-window-alt"></i>
                                                            </app>
                                                            <div>
                                                                {ele.name}
                                                                {ele.isbeta ? <span className="BetaLabel">beta</span> : ""}
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                            else {
                                return ""
                            }
                        })
                    }
                   
                </HomepageLayout>
            );
        } else {
            return "";
        }
    }
}

/**
 * MainBoard: The middle layer of subpages and main menu
 *
 * props
 * list: [{
 *  name: <name displayed in buttons on the nav bar> ,
 *  path: <path> ,
 *   component: <component need to be rendered>
 * },...]
 */

export default class MainBoard extends Component {
    state = {};
    render() {
        const { title, isOverflow, isMarginAuto } = this.props;
        let { list } = this.props;
        const buttons =
            list !== undefined
                ? this.props.list.length === 1
                    ? []
                    : this.props.list.map((element) => {
                          return {
                              ...element,
                              link: element.path,
                          };
                      })
                : [];
        const navJson = {
            title: title,
            buttons: buttons,
        };

        const isHomePage = (window.location.pathname.match(/\//g) || []).length === 1 && list.length > 1;
        const homepath = "/" + window.location.pathname.split("/")[1];
        const selectedApp = list.filter((ele) => ele.path === window.location.pathname);
        const selectedAppName = selectedApp.length > 0 ? selectedApp[0].name : "";

        return (
            <Layout>
                <NavBar
                    title={navJson.title}
                    subtitle={selectedAppName}
                    buttons={navJson.buttons}
                    homepath={homepath}
                    isHomePage={isHomePage}
                />
                <MainBoardHomepage isenabled={isHomePage} list={list} title={navJson.title} />
                {list.length > 0 ? (
                    list.length === 1 ? (
                        <SubLayout isOverflow={isOverflow} isMarginAuto={isMarginAuto}>
                            <Route path={list[0].link} component={list[0].component} />
                        </SubLayout>
                    ) : (
                        <SubLayout isOverflow={isOverflow} isMarginAuto={isMarginAuto}>
                            <Switch>
                                {list.map((element, idx) => {
                                    return <Route key={idx} path={element.path} component={element.component} />;
                                })}
                            </Switch>
                        </SubLayout>
                    )
                ) : (
                    ""
                )}
            </Layout>
        );
    }
}
