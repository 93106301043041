import React, { Component } from "react";
import { ScaleLoader, FadeLoader, BounceLoader, BarLoader, BeatLoader } from "react-spinners";
import { COLORMAP } from "../../config";
import styled from "styled-components";

const Wrapper = styled.div`
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.7s;
`;

/**
 * Loading Bar with Text and Spinner
    props:
        - type
            - FadeLoader
            - BounceLoader
            - BarLoader
            - BeatLoader
            - ScaleLoader
        - color
        - spinnerSize
        - text
        - textStyles
 */
export default class LoadingBar extends Component {
    spinner(spinnerColor, spinnerSize, type, warning) {
        if (!warning) {
            switch (type) {
                case "FadeLoader":
                    return <FadeLoader color={spinnerColor || "rgb(68,117,99)"} size={spinnerSize || undefined} />;
                case "BounceLoader":
                    return <BounceLoader color={spinnerColor || "rgb(68,117,99)"} size={spinnerSize || undefined} />;
                case "BarLoader":
                    return <BarLoader color={spinnerColor || "rgb(68,117,99)"} size={spinnerSize || undefined} />;
                case "BeatLoader":
                    return <BeatLoader color={spinnerColor || "rgb(68,117,99)"} size={spinnerSize || undefined} />;
                case "ScaleLoader":
                    return <ScaleLoader color={spinnerColor || "rgb(68,117,99)"} height={spinnerSize || undefined} width={4} />;
                default:
                    return (
                        <span>
                            <i className="fal fa-cog" style={{ fontSize: "4rem", color: spinnerColor, animation: "rotate .8s infinite linear" }}></i>
                        </span>
                    );
            }
        } else {
            return (
                <div>
                    <i className="fal fa-exclamation-circle" style={{ fontSize: "5.7rem", color: spinnerColor }}></i>
                </div>
            );
        }
    }

    render() {
        let { color, size, text, textStyles, type, warning } = this.props;

        // Default
        color = warning ? COLORMAP.red : color || "#aaa";

        const defaultTextStyles = {
            textAlign: "center",
            color: color,
            fontSize: "1.3rem",
            fontFamily: "var(--font-main)",
            margin: "10px 0"
        };

        const Text = () =>
            !text ? (
                ""
            ) : (
                <div
                    style={
                        textStyles
                            ? {
                                  ...defaultTextStyles,
                                  ...textStyles
                              }
                            : defaultTextStyles
                    }
                >
                    {text !== "" ? text : "Loading ..."}
                </div>
            );

        const Spinner = () => this.spinner(color, size, type, warning);

        return (
            <Wrapper>
                {Text()}
                {Spinner()}
            </Wrapper>
        );
    }
}
