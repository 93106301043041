import React, { Component } from "react";
import { api_couchdb } from "../../../helpers/API";
import { LoadingPage } from "../../../helpers/SimpleComponents";
import { argmaxFromDicts } from "../../../helpers/Utils";
import LimeExplainerChart from "../../../components/chart/LimeExplainerChart";
import ImbueTable from "../../../components/table/ImbueTable";
import { highchartBasicConfig } from "../../../config";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import S3PdfViewer from "../../../components/pdfViewer/S3PdfViewer"

export default class PositionAnalytics extends Component {
    state = {
        isLoading: 1,
        quality_pdfname: null,
        exploreresult_pdfname: null
    };

    componentDidMount() {
        api_couchdb("strategy_live_results", this.props.position_id).then((res) => {
            if (res.data.content) {
                this.setState({
                    signalData: res.data.content,
                    isLoading: 0,
                });
            } else {
                this.setState({ isLoading: 0 });
            }
        });
        api_couchdb("experiment_live_feature", this.props.strategy_id).then(res => {
            const quality_pdfname = res.data.content["quality_pdf"]
            const exploreresult_pdfname = (Object.values(res.data.content.results.tickers) || [{}])[0]['results_pdf']
            console.log(quality_pdfname, exploreresult_pdfname);
            this.setState({
                quality_pdfname,
                exploreresult_pdfname
            })
        }).catch(e => {
            console.log(`Could Not Find ${this.props.strategy_id} Explore Result!`);
        })
    }

    render() {
        const signalData = this.state.signalData;

        const renderFeatureHistory = () => {
            if (!this.state.isLoading) {
                try {
                    const featureHistoryTimeseries = Object.keys(signalData.explainers.lime.features_history).map(
                        (k) => {
                            return {
                                name: k,
                                data: signalData.explainers.lime.features_history[k].map((ele) => [
                                    Date.parse(ele.date),
                                    ele.px_last,
                                ]),
                            };
                        }
                    );
                    const hcConfig = {
                        ...highchartBasicConfig,
                        tooltip: { shared: 1 },
                        series: featureHistoryTimeseries,
                    };
                    return <HighchartsReact highcharts={Highcharts} options={hcConfig} />;
                } catch (err) {
                    return <div>NO DATA</div>;
                }
            }
        };

        const renderBasicRiskManager = () => {
            if (this.state.isLoading) {
                return LoadingPage();
            } else {
                if (this.state.signalData) {
                    try {
                        let FeatureImportance = signalData.features;
                        FeatureImportance = Object.values(FeatureImportance)[0];
                        FeatureImportance = Object.values(FeatureImportance.importance)[0];
                        if (FeatureImportance) {
                            // re-process data
                            const tabledata = Object.keys(FeatureImportance)
                                .map((key) => ({ feature: key, value: FeatureImportance[key] }))
                                .sort((a, b) => b.value - a.value);
                            return <ImbueTable config={{ data: tabledata, density: "tight" }} />;
                        } else {
                            return <div>NO DATA</div>;
                        }
                    } catch (err) {
                        return <div>NO DATA</div>;
                    }
                } else {
                    return <div>NO DATA</div>;
                }
            }
        };

        const renderLimeExplainer = () => {
            if (this.state.isLoading) {
                return LoadingPage();
            } else {
                if (this.state.signalData) {
                    try {
                        const limeResults = this.state.signalData.explainers.lime;
                        if (limeResults) {
                            const signalLabel = argmaxFromDicts(limeResults.pred_proba, "proba").class;
                            return (
                                <div>
                                    <LimeExplainerChart
                                        signalLabel={signalLabel}
                                        type="proba"
                                        data={limeResults}
                                        height={150}
                                    />
                                    <LimeExplainerChart
                                        signalLabel={signalLabel}
                                        type="featdist"
                                        data={limeResults}
                                        height={150}
                                    />
                                </div>
                            );
                        } else {
                            return <div>NO DATA</div>;
                        }
                    } catch (err) {
                        return <div>NO DATA</div>;
                    }
                } else {
                    return <div>NO DATA</div>;
                }
            }
        };

        return (
            <div className="signal_screen_wrapper">
                <div>
                    <h3>BASIC FEATURE IMP</h3>
                    {renderBasicRiskManager()}
                    <h3>feature history</h3>
                    {renderFeatureHistory()}
                    <h3>LIME EXPLAINER</h3>
                    {renderLimeExplainer()}
                    <h3>Explore Result (pdf)</h3>
                    {
                        !this.state.exploreresult_pdfname
                            ? <div className="LoadingText">Initializing...</div>
                            : <S3PdfViewer id={this.state.exploreresult_pdfname} />
                    }
                    <h3>Data Quality (pdf)</h3>
                    {
                        !this.state.quality_pdfname
                            ? <div className="LoadingText">Initializing...</div>
                            : <S3PdfViewer bucketname={"qualitypdfs"} id={this.state.quality_pdfname} />
                    }
                </div>
            </div>
        );
    }
}
