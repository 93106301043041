import styled from "styled-components";
import { layoutConfig } from "../../config";

// ======================
// --+-- Main Board --+--
// ======================

const sidebarWidth = 280;

export const PageStyle = styled.div`
    display: grid;
    grid-template-columns: ${sidebarWidth}px calc(100% - ${sidebarWidth}px);
    grid-template-rows: calc(100vh - ${layoutConfig.navbarHeight + layoutConfig.textStripHeight}px);
`;

// ============================
// --+-- Wrap MainPanel --+--
// ============================
export const MainPanelLayout = styled.div`
    height: 100%;
    overflow: auto;
`;

// ============================
// --+-- GameDisplay --+--
// ============================
export const GameDisplay = styled.div`
    padding: 10px;

    .chart_wrapper {
        padding: 5px 15px;
        border-radius: 5px;
        border: 1px solid #ccc;
        background: white;
    }

    .chart_title {
        color: var(--color-main);
        font-family: var(--font-main);
        font-weight: 800;
        margin: 4px 0;
        font-size: 1.3rem;
    }
`;

// ============================
// --+-- Statistics Cards --+--
// ============================
export const _StatisticsWrapper = styled.div`
    .stat_wrapper {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        overflow: auto;
    }

    .stat_card {
        text-align: "left";
        padding: 4px 0;
        /* background: #fff; */
        /* border-radius: 3px; */
        /* border: 1px solid #ccc; */
        /* margin: 4px 10px 4px 0; */
        transition: all 0.2s;
    }

    .stat_card:hover > .stat_card_title {
        color: black;
    }

    .stat_card_title {
        font-size: 1rem;
        color: #777;
    }

    .stat_card_number {
        font-size: 1.3rem;
    }
`;
