import React, { Component } from "react";
import { api_postgres, api_sysevents } from "../../../helpers/API";
import ImbueRcTable from "../../../components/table/ImbueRcTable";
import moment from "moment";
import { COLORMAP } from "../../../config";
import ImbueSelect from "../../../components/input/ImbueSelect";

const INTERVAL = 10000;

export default class SM_EventsTable extends Component {
    state = {
        isLoading: false,
        list: [],
        lastupdated: "",
        isLive: 1,
        selectedEventType: "all",
    };

    onUpdate = () => {
        let ts = moment();
        ts = ts.format();
        this.setState({ isLoading: true }, () => {
            api_sysevents(this.state.selectedEventType).then((res) => {
                const data = res.data;
                this.setState({
                    list: data,
                    isLoading: false,
                    lastupdated: ts,
                });
            });
        });
    };

    onStop = () => {
        if (this.interval) {
            clearInterval(this.interval);
            this.setState({
                isLive: 0,
            });
        }
    };

    onResume = () => {
        this.setState(
            {
                isLive: 1,
            },
            () => {
                this.interval = setInterval(this.onUpdate, INTERVAL);
            }
        );
    };

    onChangeLive = () => {
        if (this.state.isLive) {
            this.onStop();
        } else {
            this.onResume();
        }
    };

    componentDidMount() {
        this.onUpdate();
        this.interval = setInterval(this.onUpdate, INTERVAL);
    }

    render() {
        const list = this.state.list;

        const statusCell = (status) => {
            switch (status.toLowerCase()) {
                case "failed":
                    return (
                        <span style={{ color: COLORMAP.red }}>
                            <i className="fas fa-circle" style={{ color: COLORMAP.red }}></i> {status}
                        </span>
                    );
                case "warning":
                case "datanotready":
                case "target":
                case "stop":
                    return (
                        <span style={{ color: COLORMAP.orange }}>
                            <i className="fas fa-circle" style={{ color: COLORMAP.orange }}></i> {status}
                        </span>
                    );
                case "completed":
                case "started":
                    return (
                        <span style={{ color: COLORMAP.green }}>
                            <i className="fas fa-circle" style={{ color: COLORMAP.green }}></i> {status}
                        </span>
                    );
                default:
                    return (
                        <span style={{ color: COLORMAP.green }}>
                            <i className="fas fa-circle" style={{ color: COLORMAP.green }}></i> {status}
                        </span>
                    );
            }
        };

        const columns = [
            { accessor: "event_date", Header: "Event Date", maxWidth: 140 },
            { accessor: "event_message", Header: "Message" },
            { accessor: "event_name", Header: "Label", maxWidth: 200 },
            { accessor: "event_status", Header: "Event Status", maxWidth: 140, Cell: (props) => statusCell(props.value) },
            { accessor: "event_type", Header: "Event Type", maxWidth: 140 },
        ];

        const typeOptions = [
            { label: "All", value: "all" },
            { label: "Portfolio", value: "Portfolio" },
            { label: "Trigger", value: "Trigger" },
            { label: "Strategy", value: "Strategy" },
            { label: "System", value: "System" },
        ];
        return (
            <div>
                <div className="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="event type"
                        options={typeOptions}
                        defaultValue={typeOptions[0]}
                        onChange={(e) => this.setState({ selectedEventType: e.value })}
                    />
                </div>
                <div style={{ fontSize: "1rem", margin: "5px 0" }} className="flexBox flexBox_between">
                    <div className="LoadingText" style={{ fontSize: "1rem" }}>
                        last updated: {this.state.lastupdated}{" "}
                        {this.state.isLoading ? (
                            <i className="fas fa-redo" style={{ color: COLORMAP.green, animation: "rotate 1s linear infinite" }}></i>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="BtnWrapper">
                        <div className="SmallBtn" onClick={this.onChangeLive}>
                            {this.state.isLive ? (
                                <span>
                                    <i className="fas fa-circle" style={{ color: COLORMAP.green }}></i> LIVE
                                </span>
                            ) : (
                                <span>
                                    <i className="fas fa-circle" style={{ color: COLORMAP.grey }}></i> NOT LIVE
                                </span>
                            )}
                        </div>
                        {!this.state.isLive ? (
                            <div className="SmallBtn" onClick={this.onUpdate}>
                                <span>
                                    <i className="fas fa-redo"></i> Update
                                </span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                <ImbueRcTable key={this.state.lastupdated} filterable={true} list={list} columns={columns} defaultPageSize={30} />
            </div>
        );
    }
}
