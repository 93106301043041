import React, { Component } from 'react'
import { get_stat, getAvg, toUnique, getStd, getPositiveRatio } from '../../../../helpers/Utils';
import Highchart from 'react-highcharts';
import { histogram, tradeChartTooltipFormatter } from '../../../../helpers/ChartFunctions';

export default class HistChart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            groupByAgent: null,
        }
        this.onChangeVisible = this.onChangeVisible.bind(this)
        this.onChangeGroupByAgent = this.onChangeGroupByAgent.bind(this)
    }


    onChangeGroupByAgent(newAgent) {
        this.setState({ groupByAgent: newAgent === "All" ? null : newAgent })
    }

    onChangeVisible(newVisibles) {
        console.log(newVisibles)
        this.setState({ visible: newVisibles })
    }

    formatStats() {
        const { gamedata } = this.props
        const { groupByAgent, visible } = this.state
        const onchangevisible = this.onChangeVisible

        const completed = (gamedata ? gamedata.completed : [])
            .filter(ele => Object.keys(ele.result).length > 0)
            .filter(ele => groupByAgent ? ele.agent === groupByAgent : true)

        const sharpe_and_pnl = completed.map(ele => [
            parseFloat(get_stat(ele.result['out_of_sample'], "Sharpe")),
            parseFloat(get_stat(ele.result['out_of_sample'], "Total Pnl")),
            ele.agent,
        ])

        // -- Validate
        if (sharpe_and_pnl.length > 0) {
            const sharpe_and_annul_pnl = completed.map(ele => [
                parseFloat(get_stat(ele.result['out_of_sample'], "Sharpe")),
                parseFloat(get_stat(ele.result['out_of_sample'], "Annualised Pnl").replace("%", "")),
            ])

            const sharpe_and_annul_vol = completed.map(ele => [
                parseFloat(get_stat(ele.result['out_of_sample'], "Sharpe")),
                parseFloat(get_stat(ele.result['out_of_sample'], "Annualised Vol").replace("%", "")),
            ])

            // 2 -- Agent List
            const agent_list = toUnique((gamedata ? gamedata.completed : []).map(ele => ele.agent))

            const dist_sharpe_and_pnl = histogram(sharpe_and_pnl, 0.1)
            const dist_sharpe_and_pnl_chart_config = {
                chart: { type: 'column', height: "180px", style: { fontFamily: "arial", background: "rgba(0,0,0,0)" } },
                credits: { enabled: 0 },
                title: { text: '' },
                xAxis: { gridLineWidth: 1 },
                yAxis: [{
                    opposite: true, title: { text: 'Count' }
                }, {
                    title: { text: 'Annulised P&L (%)' }
                },
                {
                    title: { text: 'Annulised Volatility' }
                }],
                legend: {
                    itemStyle: {
                        fontSize: '1.1rem'
                    }
                },
                plotOptions: {
                    series: {
                        events: {
                            legendItemClick: function (e) {
                                let _i = e.target._i
                                let previousVisible = e.target.chart.series.map(ele => ele.visible)
                                previousVisible[_i] = previousVisible[_i] ? false : true
                                if (previousVisible.indexOf(true) >= 0) {
                                    onchangevisible(previousVisible)
                                }
                            }
                        }
                    }
                },
                series: [{
                    visible: visible ? visible[0] : true,
                    name: 'Sharpe Distribution',
                    type: 'column',
                    animation: false,
                    data: dist_sharpe_and_pnl.map(ele => ({ x: ele[0], y: ele[1], color: ele[0] >= 0 ? "#336d33" : "#980c0c" })),
                    pointPadding: 0,
                    groupPadding: 0,
                    pointPlacement: 'between',
                    // color: "rgb(68,117,99)"
                }, {
                    visible: visible ? visible[1] : true,
                    name: 'Sharpe and Annualised P&L',
                    type: 'scatter',
                    color: "steelblue",
                    // data: sharpe_and_pnl.map(ele => ({ x: ele[0], y: ele[1], name: ele[2], color: ["red", "green", "orange", "steelblue", "brown", "pink"][agent_list.indexOf(ele[2])] })),
                    data: sharpe_and_annul_pnl,
                    animation: false,
                    yAxis: 1,
                    marker: {
                        radius: 2
                    }
                }, {
                    visible: visible ? visible[2] : true,
                    name: 'Sharpe and Annualised Vol',
                    type: 'scatter',
                    color: "orange",
                    // data: sharpe_and_pnl.map(ele => ({ x: ele[0], y: ele[1], name: ele[2], color: ["red", "green", "orange", "steelblue", "brown", "pink"][agent_list.indexOf(ele[2])] })),
                    data: sharpe_and_annul_vol,
                    animation: false,
                    yAxis: 2,
                    marker: {
                        radius: 2
                    }
                }]
            }

            return {
                chartConfig: dist_sharpe_and_pnl_chart_config,
                agent_list
            }
        } else {
            return {
                chartConfig: null
            }
        }


    }

    render() {
        const { groupByAgent } = this.state
        const { chartConfig, stats, agent_list } = this.formatStats()
        return (
            !chartConfig
                ?
                <div className="flexBox_horizon">
                    <div style={{ color: "#888", }}>NO RESULT TO VISUALISE</div>
                </div>
                :
                <div style={{ width: "100%" }}>
                    <div className="flexBox_horizon" style={{ marginBottom: "-5px" }}>
                        {
                            ['All'].concat(agent_list).map(ele => {
                                const isSelected = ele === groupByAgent || (groupByAgent === null && ele === "All")
                                return (
                                    <div
                                        className="onHover"
                                        style={{
                                            margin: "0 5px",
                                            paddingBottom: "2px",
                                            fontSize: "1.2rem",
                                            color: isSelected ? "#222" : "#888",
                                            borderBottom: isSelected ? "1px solid #555" : "1px solid transparent",
                                        }}
                                        onClick={(() => { this.onChangeGroupByAgent(ele) })}
                                    >{ele}</div>
                                )
                            })
                        }
                    </div>
                    <Highchart config={chartConfig} />
                </div>
        )
    }
}
