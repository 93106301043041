const ClimateVariationIndex = [
    {
        name: "nino3",
        ticker: "nino3_esrl",
        fullName: "Niño 3 SST Index",
        description: "Calculated from the HadISST1. It is the area averaged SST from 5S-5N and 150W-90W.",
    },
    {
        name: "nino34",
        ticker: "nino34_esrl",
        fullName: "Niño 3.4 SST Index",
        description: "Calculated from the HadISST1. It is the area averaged SST from 5S-5N and 170-120W.",
    },
    {
        name: "nino4",
        ticker: "nino4_esrl",
        fullName: "Niño 4 SST Index",
        description: "Calculated from the HadISST1. It is the area averaged SST from 5S-5N and 160E-150W.",
    },
    {
        name: "nino12",
        ticker: "nino12_esrl",
        fullName: "Niño 1+2 SST Index",
        description: "Calculated from the HadISST. It is the area averaged SST from 0-10S and 90W-80W.",
    },
    {
        name: "amo",
        ticker: "amo_esrl",
        fullName: "Atlantic Multidecadal Oscillation Index",
        description: "The timeseries are calculated from the Kaplan SST dataset which is updated monthly. It is basically an index of the N Atlantic temperatures. Time series are created; a smoothed version and an unsmoothed version. In addition, two files starting at 1948 are produced to be used in the Correlation webpages.",
    },
    {
        name: "tni",
        ticker: "tni_esrl",
        fullName: "Indices of El Niño evolution",
        description: "The timeseries is calculated from the HadISST and the NCEP OI SST Datasets. It is the standardized Nino 12 minus the Niño 4 with a 5 month running mean applied which is then standardized using the 1950-1979 period. There is now a version that just uses the HadISST. This avoids the issue where the OI SST doesn't match the HadISST. ",
    },
    {
        name: "soi",
        ticker: "soi_esrl",
        fullName: "Southern Oscillation Index",
        description: "The SOI is defined as the normalized pressure difference between Tahiti and Darwin. There are several slight variations in the SOI values calculated at various centres. Here we calculate the SOI based on the method given by Ropelewski and Jones (1987). It uses a second normalization step, and was the Climate Analysis Centre's standard method in 1987. The reader is also referred to Allan et al. (1991) and Können et al. (1998) for details of the early pressure sources and methods used to compile the series from 1866 onwards.",
    },
    {
        name: "nao",
        ticker: "nao_esrl",
        fullName: "North Atlantic Oscillation",
        description: "The NAO is traditionally defined as the normalized pressure difference between a station on the Azores and one on Iceland. An extended version of the index can be derived for the winter half of the year by using a station in the southwestern part of the Iberian Peninsula (Hurrell, 1995). Here we give data for SW Iceland (Reykjavik), Gibraltar and Ponta Delgada (Azores). The NAO calculated from Gibraltar and SW Iceland is also given (Jones et al., 1997).",
    },
    {
        name: "tpi",
        ticker: "tpi_ipo_esrl",
        fullName: "Tripole Index for the Interdecadal Pacific Oscillation",
        description: "The index is based on the difference between the SSTA averaged over the central equatorial Pacific and the average of the SSTA in the Northwest and Southwest Pacific. It is a measure of interdecadal variability in the Pacific. The map to the right shows the correlations of the low-pass index TPI with filtered HadISST2.1(composite of 10 realisations). Versions have been calculated from these SST datasets: NOAA ERSST: V5,V4,and V3b, as well as HadISST1.1 and COBE-SST.",
    },
    {
        name: "pdo",
        ticker: "pdo_esrl",
        fullName: "The Pacific Decadal Oscillation",
        description: 'The "Pacific Decadal Oscillation" (PDO) is a long-lived El Niño-like pattern of Pacific climate variability',
    },
];

export default ClimateVariationIndex;
