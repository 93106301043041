import React, { Component } from "react";
import styled from "styled-components";
import PositioningAnalysisChart from "../../components/chart/PositioningAnalysisChart";
import PositioningAnalysisChartExtra from "../../components/chart/PositioningAnalysisChartExtra";
import ImbueSelect from "../../components/input/ImbueSelect";
import { getTickerName } from "../../index/TickerDictonary";
import PositioningAnalysisDeltaChart from "../../components/chart/PositioningAnalysisDeltaChart";
import { GLOBALFINANCE } from "../../config";

const Layout = styled.div`
    padding: 30px;
`;

const optionsForCommodities = GLOBALFINANCE.SECURITY_POOL.map((ele) => ({
    value: ele,
    label: (
        <span>
            <div style={{ marginBottom: "-3px" }}>
                <small>{ele}</small>
            </div>
            <div>{getTickerName(ele)}</div>
        </span>
    ),
}));
const optionsForDatatype = [
    { label: "Futures and Options", value: 1 },
    { label: "Futures", value: 2 },
    { label: "Implied Options", value: 3 },
    { label: "Delta", value: 4 },
    { label: "Extra", value: 5 },
    // { label: "Managed Money", value: 5 },
    // { label: "Producer", value: 6 },
    // { label: "Swap Dealers", value: 7 },
    // { label: "Other Reportables", value: 8 },
];

export default class PositioningAnalytics extends Component {
    state = {
        selectedComdty: {},
    };

    onSubmit = (selectedDatayypeOption) => {
        const comdty = this.state.selectedComdty.value;
        const datatype = selectedDatayypeOption.value;

        this.setState({
            comdty,
            datatype,
        });
    };

    render() {
        const generator = () => {
            if (!(this.state.comdty && this.state.datatype)) {
                return "";
            } else {
                if ([1, 2, 3].includes(this.state.datatype)) {
                    return (
                        <div
                            key={this.state.comdty + this.state.datatype}
                            className="grid_layout grid_layout_w_gap"
                            style={{ gridTemplateColumns: "49% 49%" }}
                        >
                            <PositioningAnalysisChart
                                {...{
                                    comdty: this.state.comdty,
                                    datatype: this.state.datatype,
                                    charttype: 1,
                                }}
                            />
                            <PositioningAnalysisChart
                                {...{
                                    comdty: this.state.comdty,
                                    datatype: this.state.datatype,
                                    charttype: 2,
                                }}
                            />
                            <PositioningAnalysisChart
                                {...{
                                    comdty: this.state.comdty,
                                    datatype: this.state.datatype,
                                    charttype: 3,
                                }}
                            />
                            <PositioningAnalysisChart
                                {...{
                                    comdty: this.state.comdty,
                                    datatype: this.state.datatype,
                                    charttype: 4,
                                }}
                            />
                        </div>
                    );
                } else if (this.state.datatype === 4) {
                    return (
                        <PositioningAnalysisDeltaChart
                            key={this.state.comdty}
                            {...{
                                comdty: this.state.comdty,
                            }}
                        />
                    );
                } else {
                    return <div
                            key={this.state.comdty + this.state.datatype}
                            className="grid_layout grid_layout_w_gap"
                            style={{ gridTemplateColumns: "49% 49%" }}
                        >
                            <PositioningAnalysisChartExtra 
                                {...{
                                    comdty: this.state.comdty,
                                    datatype: 5,
                                }}
                            />
                            <PositioningAnalysisChartExtra 
                                {...{
                                    comdty: this.state.comdty,
                                    datatype: 6,
                                }}
                            />
                            <PositioningAnalysisChartExtra 
                                {...{
                                    comdty: this.state.comdty,
                                    datatype: 7,
                                }}
                            />
                            <PositioningAnalysisChartExtra 
                                {...{
                                    comdty: this.state.comdty,
                                    datatype: 8,
                                }}
                            />
                        </div>;
                }
            }
        };
        return (
            <Layout>
                <div>
                    <div className="ImbueSelectsWrapper">
                        <ImbueSelect
                            options={optionsForCommodities}
                            onChange={(e) => this.setState({ selectedComdty: e })}
                            title="commodity"
                        />
                        <ImbueSelect
                            key={this.state.selectedComdty.value}
                            onChange={(e) => this.onSubmit(e)}
                            options={optionsForDatatype}
                            title="datatype"
                        />
                    </div>
                </div>
                {generator()}
            </Layout>
        );
    }
}
