import React, { Component } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

/**
 * ClipboardBtn
 * ------------
 */
export default class ClipboardBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClicked: false
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState({ isClicked: true }, () => {
            setTimeout(() => {
                this.setState({ isClicked: false });
            }, 3000);
        });
    }

    render() {
        let { text } = this.props;
        const { isClicked } = this.state;
        return (
            <div>
                <CopyToClipboard text={text}>
                    <div
                        className="onHover"
                        style={{ padding: "0", fontSize: "1.2rem" }}
                        onClick={this.onClick}
                    >
                        <i
                            className={
                                isClicked ? "fas fa-check" : "fas fa-copy"
                            }
                        />
                    </div>
                </CopyToClipboard>
            </div>
        );
    }
}
