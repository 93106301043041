import React, { Component } from "react";

import ImbueAILab from "./ImbueAILab_GameInterface/ImbueAILab";

import ImbueAILabResultViewerComponent from "./ImbueAILab_ResultViewer/ImbueAILab_resultviewerpage";
import MainBoard from "../../layout/MainBoard";
import { argsFromUrl } from "../../helpers/Utils";
import ImbueAILab_FeatureExploring from "./ImbueAILab_v2/homepage";

export default class ImbueAILabMainboard extends Component {

    componentDidMount() {
        document.title = "AI Lab";
    }
    
    render() {
        const query = argsFromUrl(this.props.location.search || "");
        const ImbueAILabWithQuery = () => {
            return <ImbueAILab query={query} />;
        };
        const ImbueAILabResultViewerWithQuery = () => {
            return <ImbueAILabResultViewerComponent query={query} />;
        };
        const { path } = this.props.match;
        const list = [
            { name: "AI Lab V2", path: path + "/ailabv2", component: ImbueAILab_FeatureExploring, isbeta: 1 },
            { name: "AI Lab", path: path + "/ai_lab_interface", component: ImbueAILabWithQuery },
            { name: "Result Viewer", path: path + "/result_viewer", component: ImbueAILabResultViewerWithQuery },
        ];
        return <MainBoard title="Imbue AI Lab" list={list} />;
    }
}
