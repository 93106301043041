import React, {useState, useEffect} from 'react';
import {api_energy_prices} from "../../helpers/API";
import styled from "styled-components";
import { GLOBALSTYLED } from "../../config"
import HighStockWrapper from '../../components/chart/HighStockWrapper';
import { LoadingPage } from "../../helpers/SimpleComponents";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)`
    .chartWrapper {
        background: white;
        padding: 20px;
        box-shadow: var(--boxshadow-aws);
    }

    .fullScreenImgWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
    }

    .fullScreenImgWrapper img {
    }
`;

const buttonPad = {marginLeft: "10px", marginTop: "10px"};

const ZL_BTU_LB = 1.7035e4; // 17,035 btu per pound
const CL_BTU_BBL = 5.826e6; // 5,826,000 btu per barrel
const HO_BTU_GAL = 1.39e5; // 139,000 btu per gallon
const RB_BTU_GAL = 1.25e5; // 125,000 btu per gallon
const QS_BTU_MT = 42986514.9; // 137,381 btu/gal -> 42gal/bbl, -> 7.45bbl/MT == ~42,986,514 btu per MT
const FN_BTU_THERM = 10.00238767; // 1 therm = 99976.129 btu
const TZT_MW_BTU = 3.4121; // 1 MWh == 3412141.6331279
const XW_BTU_MT = 23794002 // 23,794,002 btu per metric tonne

const BTU_COEF = 1e6;

const nonNGsymbols = ['cl', 'co', 'bo', 'ho', 'rb', 'qs'];
const NGsymbols = ['ng', 'fn', 'tzt', 'jkl'];

// used to control series visibililty when toggling NG or log()
let seriesVisMap = {
    'cl'  : true,
    'co'  : true,
    'bo'  : true,
    'ho'  : true,
    'rb'  : true,
    'qs'  : true,
    'ng'  : true,
    'fn'  : true,
    'tzt' : true,
    'jkl' : true
}

let firstRun = true;
let dataReceived = false;
export default function EnergyBTU() {

    document.title = "Energy BTU"

    const [state, setState] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [toggleOthers, setToggleOthers] = useState(false);
    const [toggleNG, setToggleNG] = useState(false);
    const [toggleLog, setToggleLog] = useState(false);

    let api_query = "..."; // todo

    if (firstRun) {
        getData();
        firstRun = false;
    }

    // handle resetting bools when components unmounts
    useEffect(() => {
        return () => {
            dataReceived = false;
            firstRun = true;
            setState({});
        };
    }, []);

    function clickedToggleOthers() {
        if (toggleOthers) {
            nonNGsymbols.forEach(sym => seriesVisMap[sym] = true);
        } else {
            nonNGsymbols.forEach(sym => seriesVisMap[sym] = false);
        }
        setToggleOthers(!toggleOthers);
    }


    function clickedToggleNG() {
        if (toggleNG) {
            NGsymbols.forEach(sym => seriesVisMap[sym] = true);
        } else {
            NGsymbols.forEach(sym => seriesVisMap[sym] = false);
        }
        setToggleNG(!toggleNG); // trigger state re-render
    }

    function getData() {
        setIsLoading(true);
        console.log("loading...")
        api_energy_prices(api_query).then(res => {
            console.log(res);
            // console.log(res.data.cl); // crude oil ($/bbl)
            // console.log(res.data.co); //  Brent crude ($/bbl)
            // console.log(res.data.bo); // bean oil (¢/lb)
            // console.log(res.data.ho); // heating oil ($/gal)  sometimes ¢/gal
            // console.log(res.data.rb); // gasoline ($/gal)    sometimes ¢/gal
            // console.log(res.data.qs); // low-sulphur gas  ($/MT)
            // console.log(res.data.ng); // nat gas ($/MMBtu) million btu
            // console.log(res.data.fn); // UK nat gas (pence/therm) (already converted into $/therm)
            // console.log(res.data.tzt); // Dutch nat gas (Euro/Mwh) (already converted into $/Mwh)
            // console.log(res.data.jkl); // Japan/Korea nat gas ($/MMbtu)
            setState({'bo': res.data.bo, 'cl': res.data.cl, 'ng': res.data.ng, 'ho': res.data.ho, 
                'rb': res.data.rb, 'fn': res.data.fn, 'jkl': res.data.jkl, 'tzt': res.data.tzt, 
                'xw': res.data.xw, 'co': res.data.co, 'qs': res.data.qs});
            dataReceived = true;
            setIsLoading(false);
        }).catch((e) => {
            console.error(e);
            setIsLoading(false);
        });
    }


    function configChart() {
        const priceDotStyle = 'ShortDashDot';
        let res = {
            chart: {
                height: (9 / 16 * 90) + '%' // 16:9 ratio 90%
            },
            title: {
                text: toggleLog ? 'log( Energies as $ per MMBTU )' : 'Energies as $ per MMBTU'
            },
            legend: {
                enabled: true,
                align: 'left',
                verticalAlign: 'left',
                layout: 'vertical',
                x: -20,
                y: 100
            },
            rangeSelector: {
                enabled: true
            },
            plotOptions: { 
                series: {
                    animation: false,
                    connectNulls: true
                },
            },
            xAxis: {
                type: "datetime"
            },
            yAxis: [{
                title: {
                    text: toggleLog ? 'log( $ per MMBTU )' : '$ per MMBTU'
                },
                opposite: true
            },
            {
                title: {
                    text: 'price'
                },
                opposite: false
            }],
            credits: {
                enabled: false
            },
            series: [
                {
                name: "WTI Crude",
                data: state.cl.map((i) => [Date.parse(i['DATE']), toggleLog ? Math.log((i['CLOSE'] / CL_BTU_BBL) * BTU_COEF) : (i['CLOSE'] / CL_BTU_BBL) * BTU_COEF ]), // 5.826e6btu/bbl  /  $/bbl  ==  btu/$
                visible: seriesVisMap['cl'],
                color: '#0D233A', // black
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: toggleLog ? '' : '$ ',
                    valueSuffix: toggleLog ? '' : ' / MMBTU'
                    },
                events: {
                    legendItemClick: () => {seriesVisMap['cl'] = !seriesVisMap['cl'];}
                    }    
                },
                {
                name: "Brent Crude",
                data: state.co.map((i) => [ Date.parse(i['DATE']), toggleLog ? Math.log((i['CLOSE'] / CL_BTU_BBL) * BTU_COEF) : (i['CLOSE'] / CL_BTU_BBL) * BTU_COEF]), // 5.826e6btu/bbl  /  $/bbl  ==  btu/$
                visible: seriesVisMap['co'],
                color: '#0D233A', // black
                dashStyle: 'ShortDashDotDot',
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: toggleLog ? '' : '$ ',
                    valueSuffix: toggleLog ? '' : ' / MMBTU'
                    },
                events: {
                    legendItemClick: () => {seriesVisMap['co'] = !seriesVisMap['co'];}
                    }    
                },
                {
                name: "Soybean Oil",
                data: state.bo.map((i) => [ Date.parse(i['DATE']), toggleLog ? Math.log((i['CLOSE'] / (100 * ZL_BTU_LB)) * BTU_COEF) : (i['CLOSE'] / (100 * ZL_BTU_LB)) * BTU_COEF ]), // ( 100¢/$ * 17,035btu/lb )  /  ¢/lb  == btu/$ 
                visible: seriesVisMap['bo'],
                color: '#2F7ED8', // blue
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: toggleLog ? '' : '$ ',
                    valueSuffix: toggleLog ? '' : ' / MMBTU'
                    },
                events: {
                    legendItemClick: () => {seriesVisMap['bo'] = !seriesVisMap['bo'];}
                    }
                },
                {
                name: "Heating Oil",
                data: state.ho.map((i) => [ Date.parse(i['DATE']), toggleLog ? Math.log(((i['CLOSE']*.01) / HO_BTU_GAL) * BTU_COEF) : ((i['CLOSE']*.01) / HO_BTU_GAL) * BTU_COEF ]), // 1.39e5btu/gal  /  $/gal  ==  btu/$
                visible: seriesVisMap['ho'],
                color: '#910000', // red
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: toggleLog ? '' : '$ ',
                    valueSuffix: toggleLog ? '' : ' / MMBTU'
                    },
                events: {
                    legendItemClick: () => {seriesVisMap['ho'] = !seriesVisMap['ho'];}
                    }
                },
                {
                name: "Gasoline",
                data: state.rb.map((i) => [ Date.parse(i['DATE']) , toggleLog ? Math.log(((i['CLOSE']*.01) / RB_BTU_GAL) * BTU_COEF) : ((i['CLOSE']*.01) / RB_BTU_GAL) * BTU_COEF ]), // 1.25e5btu/gal  /  $/gal  ==  btu/$
                visible: seriesVisMap['rb'],
                color: '#f28f43', // orange
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: toggleLog ? '' : '$ ',
                    valueSuffix: toggleLog ? '' : ' / MMBTU'
                    },
                events: {
                    legendItemClick: () => {seriesVisMap['rb'] = !seriesVisMap['rb'];}
                    }
                },
                {
                name: "Low-Sulphur Diesel",
                data: state.qs.map((i) => [ Date.parse(i['DATE']), toggleLog ? Math.log(((i['CLOSE']) / QS_BTU_MT) * BTU_COEF) : ((i['CLOSE']) / QS_BTU_MT) * BTU_COEF ]),
                visible: seriesVisMap['qs'],
                color: 'gold',
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: toggleLog ? '' : '$ ',
                    valueSuffix: toggleLog ? '' : ' / MMBTU'
                    },
                events: {
                    legendItemClick: () => {seriesVisMap['qs'] = !seriesVisMap['qs'];}
                    }
                },
                {
                    name: "Thermal Coal",
                    data: state.xw.map((i) => [ Date.parse(i['DATE']), toggleLog ? Math.log(((i['CLOSE']) / XW_BTU_MT) * BTU_COEF) : ((i['CLOSE']) / XW_BTU_MT) * BTU_COEF ]),
                    visible: seriesVisMap['qs'],
                    color: '#571d00',
                    tooltip: {
                        valueDecimals: 2,
                        valuePrefix: toggleLog ? '' : '$ ',
                        valueSuffix: toggleLog ? '' : ' / MMBTU'
                        },
                    events: {
                        legendItemClick: () => {seriesVisMap['qs'] = !seriesVisMap['qs'];}
                        }
                    },
                {
                name: "Natural Gas",
                data: state.ng.map((i) => [ Date.parse(i['DATE']), toggleLog ? Math.log(i['CLOSE']) : i['CLOSE'] ]), // 1e6btu/1MMbtu  /  $/MMbtu  ==  btu/$
                visible: seriesVisMap['ng'],
                color: '#8bbc21', // green
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: toggleLog ? '' : '$ ',
                    valueSuffix: toggleLog ? '' : ' / MMBTU'
                    },
                events: {
                    legendItemClick: () => {seriesVisMap['ng'] = !seriesVisMap['ng'];}
                    }
                },
                {
                name: "UK Nat Gas",
                data: state.fn.map((i) => [ Date.parse(i['DATE']), i['CLOSE'] === null ? null : (toggleLog ? Math.log(i['CLOSE'] * FN_BTU_THERM) : i['CLOSE'] * FN_BTU_THERM) ]),
                visible: seriesVisMap['fn'],
                color: '#8bbc21', // green
                dashStyle: 'ShortDashDotDot',
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: toggleLog ? '' : '$ ',
                    valueSuffix: toggleLog ? '' : ' / MMBTU'
                    },
                events: {
                    legendItemClick: () => {seriesVisMap['fn'] = !seriesVisMap['fn'];}
                    }
                },
                { 
                name: "Dutch Nat Gas",
                data: state.tzt.map((i) => [ Date.parse(i['DATE']), i['CLOSE'] === null ? null : (toggleLog ? Math.log((i['CLOSE'] / TZT_MW_BTU)) : (i['CLOSE'] / TZT_MW_BTU))  ]),
                visible: seriesVisMap['tzt'],
                color: '#8bbc21', // green
                dashStyle: 'ShortDash',
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: toggleLog ? '' : '$ ',
                    valueSuffix: toggleLog ? '' : ' / MMBTU'
                    },
                events: {
                    legendItemClick: () => {seriesVisMap['tzt'] = !seriesVisMap['tzt'];}
                    }
                },
                {
                name: "Japan/Korea Nat Gas",
                data: state.jkl.map((i) => [ Date.parse(i['DATE']), (toggleLog ? Math.log(i['CLOSE']) : i['CLOSE']) ]),
                visible: seriesVisMap['jkl'],
                color: '#8bbc21', // green
                dashStyle: 'ShortDot',
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: toggleLog ? '' : '$ ',
                    valueSuffix: toggleLog ? '' : ' / MMBTU'
                    },
                events: {
                    legendItemClick: () => {seriesVisMap['jkl'] = !seriesVisMap['jkl'];}
                    }
                },
                {
                name: "CL price",
                data: state.cl.map((i) => [Date.parse(i['DATE']), i['CLOSE']]),
                yAxis: 1,
                visible: false,
                color: '#0D233A', // black
                lineWidth: 0.5,
                dashStyle: priceDotStyle,
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: '$ '
                    },
                },
                {
                name: "CO price",
                data: state.co.map((i) => [Date.parse(i['DATE']), i['CLOSE']]),
                yAxis: 1,
                visible: false,
                color: '#0D233A', // black
                lineWidth: 0.5,
                dashStyle: 'ShortDashDotDot',
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: '$ '
                    },
                },
                {
                name: "BO price",
                data: state.bo.map((i) => [Date.parse(i['DATE']), i['CLOSE']]),
                yAxis: 1,
                visible: false,
                color: '#2F7ED8', // blue
                lineWidth: 0.5,
                dashStyle: priceDotStyle,
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: ' ¢'
                    },
                },
                {
                name: "HO price",
                data: state.ho.map((i) => [Date.parse(i['DATE']), i['CLOSE']]),
                yAxis: 1,
                visible: false,
                color: '#910000', // red
                lineWidth: 0.5,
                dashStyle: priceDotStyle,
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: ' ¢'
                    },
                },
                {
                name: "RB price",
                data: state.rb.map((i) => [Date.parse(i['DATE']), i['CLOSE']]),
                yAxis: 1,
                visible: false,
                color: '#f28f43', // orange
                lineWidth: 0.5,
                dashStyle: priceDotStyle,
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: ' ¢'
                    },
                },
                {
                name: "QS price",
                data: state.qs.map((i) => [Date.parse(i['DATE']), i['CLOSE']]),
                yAxis: 1,
                visible: false,
                color: 'gold',
                lineWidth: 0.5,
                dashStyle: priceDotStyle,
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: ' ¢'
                    },
                },
                // {
                // name: "NG price",
                // data: state.ng.map((i) => [Date.parse(i['DATE']), i['CLOSE']]),
                // yAxis: 1,
                // visible: false,
                // color: '#8bbc21', // green
                // lineWidth: 0.5,
                // dashStyle: priceDotStyle,
                // tooltip: {
                //     valueDecimals: 2,
                //     valuePrefix: '$ '
                //     },
                // },
                // {
                // name: "FN price",
                // data: state.fn.map((i) => [Date.parse(i['DATE']), i['CLOSE']]),
                // yAxis: 1,
                // visible: false,
                // color: '#8bbc21', // green
                // lineWidth: 0.5,
                // dashStyle: 'ShortDashDotDot',
                // tooltip: {
                //     valueDecimals: 2,
                //     valuePrefix: '$ '
                //     },
                // },
                // {
                // name: "TZT price",
                // data: state.tzt.map((i) => [Date.parse(i['DATE']), i['CLOSE']]),
                // yAxis: 1,
                // visible: false,
                // color: '#8bbc21', // green
                // lineWidth: 0.5,
                // dashStyle: 'ShortDash',
                // tooltip: {
                //     valueDecimals: 2,
                //     valuePrefix: '$ '
                //     },
                // },
                // {
                // name: "JKL price",
                // data: state.jkl.map((i) => [Date.parse(i['DATE']), i['CLOSE']]),
                // yAxis: 1,
                // visible: false,
                // color: '#8bbc21', // green
                // lineWidth: 0.5,
                // dashStyle: 'ShortDot',
                // tooltip: {
                //     valueDecimals: 2,
                //     valuePrefix: '$ '
                //     },
                // },
            ],
        }
        // console.log(res.series)
        return res;
    }

    if (isLoading) {
        return (<Layout>{LoadingPage()}</Layout>);
    } else if (dataReceived) {
        let chartConfig = configChart();

        return (
            <Layout>
                <button style={{marginLeft: '60px', marginTop: '20px'}} onClick={() => clickedToggleNG()}>Toggle NG</button>
                <button style={{marginLeft: '20px', marginTop: '20px'}} onClick={() => clickedToggleOthers()}>Toggle Others</button>
                <button style={{marginLeft: '20px', marginTop: '20px'}} onClick={() => setToggleLog(!toggleLog)}>Toggle Log()</button>

                <div style={{marginLeft: '40px'}}>
                    <HighStockWrapper config={chartConfig}/>
                </div>
            </Layout>
        );
    } else {
        return (
            <div>
                <div className="CircleBtn CircleBtnLg" style={buttonPad} onClick={() => {getData()}}>
                    <i className="fas fa-play"></i>
                </div>
            </div>
        );
    }
}
