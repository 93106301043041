import React, { Component } from "react";
import { StatisticsWrapper } from "../styled/main";

/**
 * Fixed stats bar for simulation
 */
export default class StatsBar extends Component {
    render() {
        const { statistics } = this.props;

        if (statistics.length > 0) {
            return (
                <StatisticsWrapper count={this.props.statistics.length}>
                    <div className="InfoCardGridWrapper">
                        {statistics.map((item, idx) => {
                            return item.value != null ? (
                                <div className="InfoCardSml" key={idx}>
                                    <div className="InfoCardSml_label">{item.label}</div>
                                    <div className="InfoCardSml_value">{item.value}</div>
                                </div>
                            ) : (
                                ""
                            );
                        })}
                    </div>
                </StatisticsWrapper>
            );
        } else {
            return (
                <div className="flexBox flexBox_center">
                    <i style={{ color: "#888" }}>Loading...</i>
                </div>
            );
        }
    }
}
