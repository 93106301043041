import React, { Component } from "react";
import MultiFunctionalChart from "../../components/chart/MultiFunctionalChart";
import { generateSeasonal } from "../../helpers/ChartFunctions";
import styled from "styled-components";
import { BarLoader } from "react-spinners";
import { api_ndvi, api_couchdb } from "../../helpers/API";
import { COLORMAP } from "../../config";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { toPercentage, parsePercentage } from "../../helpers/Utils";

const Layout = styled.div`
    font-family: var(--font-main);
    padding: 20px;

    .section_title {
        font-size: 1.8rem;
        margin-top: 30px;
    }

    .gridWrapper {
        display: grid;
    }
    @media (min-width: 700px) {
        .gridWrapper {
            grid-template-columns: 100%;
        }
    }
    @media (min-width: 1400px) {
        .gridWrapper {
            grid-template-columns: 50% 50%;
        }
    }
    @media (min-width: 2000px) {
        .gridWrapper {
            grid-template-columns: 33% 33% 33%;
        }
    }

    .chart_layout {
        background: white;
        padding: 20px;
        margin: 10px;
        border-radius: 5px;
        font-family: var(--font-main);
    }

    .simple_layout {
        display: flex;
        margin: 5px;
        border: 1px solid #aaa;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        background: rgb(230, 230, 230);
    }

    .simple_layout div {
        flex: 1;
    }

    .simple_chart_wrapper {
        width: 150px;
    }
`;

class NDVIChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "complex",
            lastDate: "---",
            chartData: {},
            rawData: [],
            isLoading: false,
            stats: {},
        };
        this.renderComplex = this.renderComplex.bind(this);
        this.renderSimple = this.renderSimple.bind(this);
    }

    getData() {
        const id = this.props.id;
        this.setState(
            {
                isLoading: true,
            },
            () => {
                api_ndvi(id)
                    .then((res) => {
                        const data = res.data.content;
                        console.log(data);

                        let stats = {
                            last_date: 0,
                            last_anom: 0,
                            last_2_date: 0,
                            last_2_anom: 0,
                        };
                        if (data.length > 1) {
                            stats.last_date = data[data.length - 1].date;
                            stats.last_anom = data[data.length - 1].anom;
                            stats.last_2_date = data[data.length - 2].date;
                            stats.last_2_anom = data[data.length - 2].anom;
                            stats.last_pct_chg = stats.last_2_anom ? toPercentage(1 - stats.last_anom / stats.last_2_anom, 2) : "0%";
                        }
                        console.log(stats, data);

                        let chartData = {
                            line: [
                                {
                                    name: "NDVI Anomaly",
                                    data: data.map((ele) => [Date.parse(ele.date), ele.anom]),
                                    color: COLORMAP.blue,
                                },
                                // {
                                //     type: "line",
                                //     name: "NDVI Sample",
                                //     data: data.map(ele => [Date.parse(ele.date), ele.sample]),
                                //     color: "var(--color-darkgold)"
                                // },
                                // {
                                //     type: "line",
                                //     name: "NDVI Mean",
                                //     data: data.map(ele => [Date.parse(ele.date), ele.mean]),
                                //     color: COLORMAP.green_o
                                // }
                            ],
                            seasonal: generateSeasonal(
                                data.map((ele) => [ele.date, ele.anom]),
                                true
                            ),
                        };
                        this.setState({
                            isLoading: false,
                            rawData: data,
                            chartData,
                            stats,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        );
    }

    componentDidMount() {
        this.getData(this.props.climateIndexName);
    }

    renderComplex() {
        return (
            <div className="chart_layout boxBorder">
                <div>
                    <div>{this.props.name}</div>
                    <div style={{ fontSize: "1rem", color: "#aaa" }}>ZONEID: {this.props.id}</div>
                </div>
                <div className="chart_main">
                    {this.state.isLoading ? (
                        <div style={{ textAlign: "center", padding: "100px", fontFamily: "var(--font-main)", fontSize: "1.2rem", color: "#777" }}>
                            <div style={{ marginBottom: "10px" }}>Loading Data ...</div>
                            <div style={{ background: "#eee" }}>
                                <BarLoader color="var(--color-main)" width="100%" />
                            </div>
                        </div>
                    ) : (
                        <MultiFunctionalChart data={this.state.chartData} hotReload={true} height={300} defaultZoom={3} />
                    )}
                </div>
            </div>
        );
    }

    renderSimple() {
        const isLoading = this.state.isLoading;
        const stats = this.state.stats;

        let data = this.state.rawData.map((ele) => [ele["anom"]]);
        data = data.slice(data.length - 14, data.length);
        return (
            <div className="simple_layout boxBorders">
                <div>
                    <div>{this.props.name}</div>
                    <div style={{ fontSize: "1rem", color: "#aaa" }}>ZONEID: {this.props.id}</div>
                </div>
                <div>
                    <div style={{ fontSize: "1rem" }}>{stats.last_date}</div>
                    <div>
                        <span style={{ fontSize: "1.5rem" }}>{stats.last_anom}</span> <small style={{ color: parsePercentage(stats.last_pct_chg) >= 0 ? COLORMAP.green : COLORMAP.red }}>{stats.last_pct_chg}</small>
                    </div>
                </div>
                <div style={{}}>
                    <div className="simple_chart_wrapper">
                        {isLoading ? (
                            <div className="LoadingText">loading...</div>
                        ) : (
                            <Sparklines data={data}>
                                <SparklinesLine style={{ strokeWidth: 3, stroke: COLORMAP.green }} />
                                {/* <SparklinesReferenceLine type="custom" value={4} style={{ strokeWidth: 2, stroke: "#aaa" }} /> */}
                            </Sparklines>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const mode = this.props.mode;
        if (mode === "complex") {
            return this.renderComplex();
        } else {
            return this.renderSimple();
        }
    }
}

class ClimateDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ndvi_index: null,
        };
    }

    componentDidMount() {
        api_couchdb("imbuecloud_index_tables", "ndvi_index").then((res) => {
            const data = res.data.content.index;
            this.setState({
                ndvi_index: data,
            });
        });
    }

    render() {
        // ===== state =====
        const ndvi_index = this.state.ndvi_index;

        if (ndvi_index) {
            return (
                <Layout>
                    <div>
                        {ndvi_index.map((section) => (
                            <section>
                                <div className="section_title">{section.group}</div>
                                <hr />
                                <div className="gridWrapper">
                                    {section.list.map((item, idx) => (
                                        <NDVIChart key={item[0]} id={item[0]} name={item[1]} />
                                    ))}
                                </div>
                            </section>
                        ))}
                    </div>
                </Layout>
            );
        } else {
            return (
                <Layout>
                    <div className="LoadingText">retrieve index...</div>
                </Layout>
            );
        }
    }
}

export default ClimateDashboard;
