import React, { Component, Fragment } from "react";
import { api_blp_meta_finder } from "../../helpers/API";
import MultiInput from "../../components/input/MultiInput";
import styled from "styled-components";
import { NATIONCODE } from "../../config";
import NationFlag from "react-country-flag";
import LoadingBar from "../../components/bar/LoadingBar";
import ImbueTable from "../../components/table/ImbueTable";

const Layout = styled.div`
    overflow: auto;
    font-family: var(--font-main);
    padding: 30px;
    box-sizing: border-box;

    .infocard_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }

    .infocard {
        background: #fff;
        padding: 10px 20px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    .title {
        font-size: 1.8rem;
        margin: 2px 0;
        color: var(--color-main);
    }

    .subtitle {
        font-size: 1.3rem;
        margin: 4px 0;
        color: #555;
    }

    .row {
        font-family: roboto;
        font-size: 1.3rem;
        /* border: 1px solid #ccc; */
        background: #fff;
        display: flex;
        align-items: center;
        padding: 6px;
        color: #333;
        margin: 5px 0;
    }
`;

export default class BloombergSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kw: "crude oil",
            results: [],
            isLoading: 0,
            viewmode: 0 // 0: table 1: card
        };
        this.get_result = this.get_result.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onEnter = this.onEnter.bind(this);
    }

    componentDidMount() {
        this.get_result();
    }

    get_result() {
        this.setState({ isLoading: true }, () => {
            const { kw } = this.state;
            api_blp_meta_finder(kw, 100).then(res => {
                // console.log(res.data)
                this.setState({
                    results: res.data,
                    isLoading: false
                });
            });
        });
    }

    onEnter(e) {
        if (e.key === "Enter") {
            this.get_result();
        }
    }

    handleChange(e) {
        this.setState({
            kw: e.target.value
        });
    }

    renderBox(data) {
        return (
            <div className="infocard">
                <div className="subtitle">
                    {data.ticker || data._id}{" "}
                    <span style={{ fontSize: "1.4rem", marginLeft: "4px" }}>
                        <NationFlag code={data.COUNTRY || ""} svg />{" "}
                    </span>{" "}
                </div>
                <div className="title">{data.NAME}</div>
                <hr />
                {[
                    ["SECURITY_TYP", "Type"],
                    ["SECURITY_NAME", "Security Name"],
                    ["LONG_COMP_NAME", "Company"],
                    ["ID_BB_GLOBAL_ULT_PARENT_CO_NAME", "Company"],
                    ["SECURITY_DES", "Description"],
                    ["REGION_OR_COUNTRY", "Country"],
                    ["EXCH_CODE", "Exchange Code"],
                    ["FUT_TRADING_HRS", "Trading Hour"],
                    ["TRADING_DAY_START_TIME_EOD", "Open Time"],
                    ["TRADING_DAY_END_TIME_EOD", "Close Time"],
                    ["FUT_CUR_GEN_TICKER", "Current Contract"],
                    ["ID_FULL_EXCHANGE_SYMBOL", "Exchannge Symbol"]
                ].map(ele => {
                    const key = ele[0];
                    const label = ele[1];
                    if (key in data) {
                        return (
                            <div>
                                <div
                                    style={{
                                        fontSize: "1.2rem",
                                        fontFamily: "roboto",
                                        margin: "2px 0"
                                    }}
                                >
                                    <div>
                                        <b>{label}</b>
                                    </div>
                                    <div style={{ margin: "2px 0" }}>{data[key]}</div>
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        );
    }

    // renderTableRow(data, idx) {
    //     return (
    //         <div className="row">
    //             {
    //                 cols.map(ele => {
    //                     const key = ele[0]
    //                     const label = ele[1]

    //                     if (hasItem("#", key)) {
    //                         switch (key) {
    //                             case "#id":
    //                                 return <div style={{ flex: "0.3" }}>{idx + 1}</div>
    //                         }
    //                     } else {
    //                         if (key in data) {
    //                             return (
    //                                 <div style={{ flex: "1" }}>{data[key]}</div>
    //                             )
    //                         } else {
    //                             return <div style={{ flex: "1" }}>---</div>
    //                         }
    //                     }

    //                 })
    //             }
    //         </div>
    //     )
    // }

    render() {
        const { isLoading, viewmode } = this.state;

        let results = this.state.results;
        results = results.map((ele, idx) => {
            return {
                ...ele,
                ...("COUNTRY" in ele
                    ? {
                          COUNTRY: (
                              <span>
                                  <NationFlag code={NATIONCODE[ele["COUNTRY"]] || ele["COUNTRY"]} svg /> {ele.COUNTRY}
                              </span>
                          )
                      }
                    : {}),
                "#id": idx + 1
            };
        });

        console.log(results);

        const tableconfig = {
            keynamemap: {
                _id: "Ticker",
                NAME: "Name",
                SECURITY_TYP: "Type",
                SECURITY_NAME: "Security Name",
                LONG_COMP_NAME: "Company",
                REGION_OR_COUNTRY: "Country",
                SECURITY_DES: "Description",
                COUNTRY: "Country",
                EXCH_CODE: "Exchange Code",
                FUT_TRADING_HRS: "Trading Hour",
                TRADING_DAY_START_TIME_EOD: "Open Time",
                TRADING_DAY_END_TIME_EOD: "Close Time",
                FUT_CUR_GEN_TICKER: "Current Contract",
                ID_FULL_EXCHANGE_SYMBOL: "Exchannge Symbol",
                ID_BB_GLOBAL_ULT_PARENT_CO_NAME: "Company",
                SOURCE_NAME: "Source"
            },
            excluded: ["_rev", "REGION_OR_COUNTRY", "ticker", "SECURITY_NAME"],
            sorted: ["#id", "SECURITY_TYP", "_id", "NAME", "COUNTRY", "SECURITY_DES"],
            flexDict: {
                COUNTRY: 0.4,
                "#id": 0.4
            },
            data: results
        };

        return (
            <div
                style={{
                    height: "100%",
                    display: "grid",
                    gridTemplateRows: "70px calc(100% - 70px)"
                }}
            >
                {/* <input type="text" onKeyPress={this.onEnter} /> */}
                <MultiInput fields={[{ label: "Keyword", value: this.state.kw }]} handleChange={this.handleChange} keyPress={this.onEnter} />
                <Layout>
                    {isLoading ? (
                        <LoadingBar />
                    ) : viewmode === 0 ? (
                        // <div>
                        //     {results.map((ele, idx) => this.renderTableRow(ele, idx))}
                        // </div>
                        tableconfig.data.length > 0 ? (
                            <ImbueTable config={tableconfig} />
                        ) : (
                            <div style={{ fontSize: "3rem", color: "#777" }}>No Results</div>
                        )
                    ) : (
                        <div className="infocard_wrapper">{results.map(ele => this.renderBox(ele))}</div>
                    )}
                </Layout>
            </div>
        );
    }
}
