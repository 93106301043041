import React, { Component } from "react";
import styled from "styled-components";
import { getTickerName, SECURITY_POOL } from "../../../index/TickerDictonary";
import ImbueSelect from "../../../components/input/ImbueSelect";
import { api_position_risk } from "../../../helpers/API";
import { hasItem } from "../../../helpers/Utils";
import { parseNumber, formatNumber, fullAssetsOptions } from "./Common";

const Layout = styled.div``;

export default class AtrBounds extends Component {
    state = {
        assets: fullAssetsOptions[0],
        holding_period: "5",
        scaling_parameter: "2",
        data: [],
        isLoading: 0,
    };

    onUpdate = () => {
        let selectedAssets = this.state.assets.value;

        const paras = {
            query: "atr_bounds",
            assets: selectedAssets,
            holding_period: parseNumber(this.state.holding_period),
            scaling_parameter: this.state.scaling_parameter,
        };

        this.setState({ isLoading: 1 }, () => {
            api_position_risk(paras).then((res) => {
                this.setState({ data: Object.keys(res.data.result).map((k) => [k, res.data.result[k]]), isLoading: 0 });
            });
        });
    };

    render() {
        const assetsOptions = fullAssetsOptions;

        const renderChart = () => {
            if (this.state.isLoading) {
                return <div className="LoadingText">loading...</div>;
            } else if (this.state.data.length > 0) {
                return (
                    <div className="flexBox">
                        {this.state.data.map((ele) => {
                            return (
                                <div className="DigitCard">
                                    <div className="title">{ele[0]}</div>
                                    <div className="digit">{ele[1]}</div>
                                </div>
                            );
                        })}
                    </div>
                );
            }
        };

        return (
            <Layout>
                <div class="ImbueSelectsWrapper">
                    <ImbueSelect title="Assets" value={this.state.assets} options={assetsOptions} onChange={(e) => this.setState({ assets: e })} />
                    <div className="ImbueSelectItem">
                        <div className="label">holding period:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={this.state.holding_period}
                            onChange={(e) => this.setState({ holding_period: e.target.value })}
                        />
                    </div>

                    <div className="ImbueSelectItem">
                        <div className="label">scaling_parameter:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={this.state.scaling_parameter}
                            onChange={(e) => this.setState({ scaling_parameter: e.target.value })}
                        />
                    </div>
                    <div className="CircleBtn CircleBtnLg" onClick={this.onUpdate}>
                        <i className="fas fa-play"></i>
                    </div>
                </div>
                <div>{renderChart()}</div>
            </Layout>
        );
    }
}
