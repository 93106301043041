import React, { Component } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import styled from "styled-components";

const Layout = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: calc(100% - 500px) 500px;
    grid-template-rows: 100%;

    @media only screen and (max-width: 1200px) {
        grid-template-columns: calc(100% - 300px) 300px;
    }

    .topic_grid {
        /* display: flex;
        flex-direction: row; */
        display: grid;
        grid-template-columns: 33% 34% 33%;
        height: 100%;
        overflow-x: auto;
    }

    .tweet_wrapper {
        height: 100%;
        overflow-y: auto;
        border-right: 1px solid #aaa;
    }

    .right_side_grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .border {
        border-left: 1px solid #aaa;
        border-bottom: 1px solid #aaa;
        box-sizing: border-box;
    }
`;

export default class Tweets extends Component {
    render() {
        return (
            // <Layout>
            //     <div className="topic_grid">
            //         <div className="tweet_wrapper">
            //             <TwitterTimelineEmbed sourceType="url" url="https://twitter.com/TheFundTrader/lists/breaking-news" />
            //         </div>
            //         <div className="tweet_wrapper">
            //             <TwitterTimelineEmbed sourceType="url" url="https://twitter.com/TheFundTrader/lists/financial-news" />
            //         </div>
            //         <div className="tweet_wrapper">
            //             <TwitterTimelineEmbed sourceType="url" url="https://twitter.com/gregorymckenna/lists/pantheon" options={{ fontSize: "10px" }} />
            //         </div>
            //     </div>
            //     <div className="right_side_grid">
            //         <div className="tweet_wrapper border">
            //             <TwitterTimelineEmbed sourceType="profile" screenName="WSJ" />
            //         </div>
            //         <div className="tweet_wrapper border">
            //             <TwitterTimelineEmbed sourceType="profile" screenName="Reuters" />
            //         </div>
            //         <div className="tweet_wrapper border">
            //             <TwitterTimelineEmbed sourceType="profile" screenName="realDonaldTrump" />
            //         </div>
            //     </div>
            // </Layout>
            <Layout>
                <div className="topic_grid">
                    <div className="tweet_wrapper border">
                        <TwitterTimelineEmbed sourceType="profile" screenName="markets" />
                    </div>

                    <div className="tweet_wrapper border">
                        <TwitterTimelineEmbed sourceType="profile" screenName="ReutersCommods" />
                    </div>
                    
                    <div className="tweet_wrapper border">
                        <TwitterTimelineEmbed sourceType="profile" screenName="federalreserve" />
                    </div>

                    <div className="tweet_wrapper border">
                        <TwitterTimelineEmbed sourceType="profile" screenName="WSJmarkets" />
                    </div>

                    <div className="tweet_wrapper border">
                        <TwitterTimelineEmbed sourceType="profile" screenName="CMEGroup" />
                    </div>

                    <div className="tweet_wrapper border">
                        <TwitterTimelineEmbed sourceType="profile" screenName="FinancialTimes" />
                    </div>

                    <div className="tweet_wrapper border">
                        <TwitterTimelineEmbed sourceType="profile" screenName="rc_markets" />
                    </div>    

                    <div className="tweet_wrapper border">
                        <TwitterTimelineEmbed sourceType="profile" screenName="zerohedge" />
                    </div>

                    <div className="tweet_wrapper border">
                        <TwitterTimelineEmbed sourceType="profile" screenName="PredictIt" />
                    </div>

                    
                </div>
                
                <div className="right_side_grid">
                    <div className="tweet_wrapper">
                        <TwitterTimelineEmbed sourceType="profile" screenName="GrainStats" />
                    </div>

                    <div className="tweet_wrapper border">
                        <TwitterTimelineEmbed sourceType="profile" screenName="usda_nass" />
                    </div>

                    <div className="tweet_wrapper border">
                        <TwitterTimelineEmbed sourceType="profile" screenName="bea_news" />
                    </div>
                </div>
            </Layout>
        );
    }
}
