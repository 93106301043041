import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import fileDownload from "react-file-download";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class PdfViewer extends Component {
    /**
     *
     * props
     * -----
     *
     * > arrayBuffer,
     *
     * > filename, str
     *
     */

    state = {
        numPages: null,
        selectedPage: 1,
        zoomLevel: 1,
    };

    onDocumentLoadSuccess = (document) => {
        const { numPages } = document;
        this.setState({
            numPages,
        });
    };

    onChangePage = (flag) => {
        const nextPage = this.state.selectedPage + flag;
        if (flag === 1 && nextPage <= this.state.numPages) {
            this.setState({ selectedPage: nextPage });
        } else if (flag === -1 && nextPage >= 1) {
            this.setState({ selectedPage: nextPage });
        }
    };

    onDownloadData = () => {
        fileDownload(this.props.arrayBuffer, this.props.filename || "download.pdf");
    };

    onSwitchZoom = () => {
        if (this.state.zoomLevel === 4) {
            this.setState({ zoomLevel: 1 });
        } else {
            this.setState({ zoomLevel: this.state.zoomLevel + 1 });
        }
    };

    render() {
        const { numPages, selectedPage } = this.state;
        const arrayBuffer = this.props.arrayBuffer;

        const generateBtns = () => {
            return (
                <div className="PdfBtnWrapper">
                    <div
                        className="CircleBtn"
                        onClick={() => {
                            this.onChangePage(-1);
                        }}
                    >
                        <i className="fas fa-chevron-left"></i>
                    </div>

                    <div className="CircleBtn" onClick={this.onDownloadData}>
                        <i className="fas fa-download"></i>
                    </div>

                    <span style={{ fontSize: "1.2rem" }}>
                        {selectedPage} / {numPages}
                    </span>

                    <div className="CircleBtn" onClick={() => this.setState({zoomLevel: 1})}>
                        S
                    </div>

                    <div className="CircleBtn" onClick={() => this.setState({zoomLevel: 2})}>
                        M
                    </div>

                    <div className="CircleBtn" onClick={() => this.setState({zoomLevel: 3})}>
                        L
                    </div>

                    <div
                        className="CircleBtn"
                        onClick={() => {
                            this.onChangePage(1);
                        }}
                    >
                        <i className="fas fa-chevron-right"></i>
                    </div>
                </div>
            );
        };
        const width = {
            1: 800,
            2: 1000,
            3: 1500,
        }[this.state.zoomLevel];
        return (
            <Document noData="Loading..." file={arrayBuffer} onLoadSuccess={this.onDocumentLoadSuccess}>
                {generateBtns()}
                <div className="pdfPageWrapper">
                    <Page key={`page_${this.state.selectedPage}`} pageNumber={this.state.selectedPage} width={width} />
                </div>
                {generateBtns()}
            </Document>
        );
    }
}
