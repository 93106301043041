var escape = require("../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Asap+Condensed&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Lato);", ""]);

// module
exports.push([module.id, "@font-face {\n    font-family: \"google-ai\";\n    font-style: normal;\n    src: url(" + escape(require("../fonts/google_ai.woff2")) + ") format(\"woff2\");\n}\n\n/* Google Fonts */\n\n.font_condensed {\n    font-family: var(--font-condensed);\n}\n", ""]);

// exports
