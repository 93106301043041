import React, { Component } from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";

const Layout = styled.div`
    display: flex;
    overflow-x: auto;

    .price_container {
        clear: both;
        font-family: var(--font-main);
        text-align: left;
        margin-right: 20px;
    }

    .price_title {
        font-size: 12px;
        color: #555;
    }

    .price_change_board {
        display: flex;
        & > div {
            padding: 0 4px 0 0;
        }
    }

    .price_arrow,
    .price_current {
        font-weight: 800;
        margin: auto 0;
        font-size: 24px;
    }

    .price_change,
    .price_change_pct {
        font-size: 10px;
    }

    .price_info_item {
        white-space: nowrap;
        font-size: 10px;
        color: #444;
    }
`;

class TickerChangeCard extends Component {
    render() {
        let data = this.props.data;
        return (
            <Layout>
                {(data || []).map((element, idx) => {
                    const colorDict = {
                        trend: element.change.diff !== undefined ? (element.change.diff > 0 ? "#00b894" : element.change.diff < 0 ? "#d63031" : "grey") : "grey",
                    };
                    return (
                        <div key={idx} className="price_container" style={{ opacity: element.style.opacity || 1 }}>
                            <div className="price_title">{element.title}</div>
                            <div className="price_change_board">
                                <div
                                    style={{
                                        color: colorDict.trend,
                                    }}
                                    className="price_arrow"
                                >
                                    <i className={element.change.diff !== undefined ? (element.change.diff > 0 ? "fas fa-arrow-up" : element.change.diff < 0 ? "fas fa-arrow-down" : "fas fa-circle") : ""} />
                                </div>
                                <div className="price_current">
                                    <NumberFormat value={element.change.value} displayType={"text"} thousandSeparator={true} decimalScale={2} />
                                </div>
                                {element.change.valueSuffix ? (
                                    <div
                                        style={{
                                            color: "#2980b9",
                                            fontSize: "15px",
                                        }}
                                    >
                                        {element.change.valueSuffix}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div
                                    style={{
                                        color: element.change.diff !== undefined ? (element.change.diff > 0 ? "#00b894" : element.change.diff < 0 ? "#d63031" : "grey") : "grey",
                                    }}
                                >
                                    <div className="price_change">
                                        {element.change.diff > 0 ? "+" : ""}
                                        {element.change.diff}
                                    </div>
                                    <div className="price_change_pct">
                                        {element.change.diff > 0 ? "+" : ""}
                                        {element.change.perc + "%"}
                                    </div>
                                </div>
                            </div>
                            <div className="price_info_board">
                                {element.info.map((item, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className="price_info_item"
                                            style={{
                                                fontSize: item.large ? "14px" : "11px",
                                                color: item.large ? "black" : "",
                                            }}
                                        >
                                            {item.label}: {item.value === null ? "-" : item.value}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </Layout>
        );
    }
}

export default TickerChangeCard;
