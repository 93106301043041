import React, { Component } from "react";
import styled from "styled-components";
import { getTickerName } from "../../../index/TickerDictonary";
import ImbueSelect from "../../../components/input/ImbueSelect";
import { api_position_risk } from "../../../helpers/API";
import { hasItem } from "../../../helpers/Utils";
import { parseNumber, formatNumber, fullAssetsOptions } from "./Common";
const Layout = styled.div``;

export default class EqualWeight extends Component {
    state = {
        assets: [{ label: "ALL", value: "*all" }],
        portf: "20,000,000",
        data: [],
        isLoading: 0,
    };

    onUpdate = () => {
        let selectedAssets = this.state.assets.map((ele) => ele.value);

        if (hasItem("*all", selectedAssets)) {
            selectedAssets = fullAssetsOptions.map((ele) => ele.value);
        }

        const paras = {
            query: "equal_weight",
            assets: selectedAssets.join(","),
            portf: parseNumber(this.state.portf),
        };

        this.setState({ isLoading: 1 }, () => {
            api_position_risk(paras).then((res) => {
                this.setState({ data: Object.keys(res.data.result).map((k) => [k, res.data.result[k]]), isLoading: 0 });
            });
        });
    };

    render() {
        const assetsOptions = [{ label: "ALL", value: "*all" }, ...fullAssetsOptions];

        const renderChart = () => {
            if (this.state.isLoading) {
                return <div className="LoadingText">loading...</div>;
            } else if (this.state.data.length > 0) {
                return (
                    <div className="flexBox">
                        {this.state.data.map((ele) => {
                            return (
                                <div className="DigitCard">
                                    <div className="title">{getTickerName(ele[0])}</div>
                                    <div className="digit">{formatNumber(`${ele[1]}`)}</div>
                                </div>
                            );
                        })}
                    </div>
                );
            }
        };

        return (
            <Layout>
                <div class="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="Assets"
                        value={this.state.assets}
                        options={assetsOptions}
                        isMulti
                        width="400px"
                        height="30px"
                        closeMenuOnSelect={false}
                        onChange={(e) => this.setState({ assets: e })}
                    />
                    <div className="ImbueSelectItem">
                        <div className="label">portf:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={formatNumber(this.state.portf)}
                            onChange={(e) => this.setState({ portf: e.target.value })}
                        />
                    </div>
                    <div className="CircleBtn CircleBtnLg" onClick={this.onUpdate}>
                        <i className="fas fa-play"></i>
                    </div>
                </div>
                <div>{renderChart()}</div>
            </Layout>
        );
    }
}
