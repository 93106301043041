import React, { Component } from "react";
import MainBoard from "../../layout/MainBoard";
import SystematicStrategies from "./SystematicStrategies";

export default class Feeds__mainboard extends Component {
    
    componentDidMount() {
        document.title = "Systemic Strategies";
    }

    render() {
        const list = [
            {
                name: "Systematic Strategies",
                path: this.props.match.path + "/SystematicStrategies",
                component: SystematicStrategies,
            },
        ];

        return (
            <div>
                <MainBoard title="Systematic Strategies" list={list} />
            </div>
        );
    }
}
