import React, { Component } from "react";
import MainBoard from "../../layout/MainBoard";

import RiskManager from "./RiskManager";

export default class Mainboard extends Component {

    componentDidMount() {
        document.title = "Risk Manager";
    }
    
    render() {
        const { path } = this.props.match;
        const list = [{ name: "Risk Manager (Beta)", path: path + "/rm", component: RiskManager }];
        return <MainBoard title="Risk Manager" list={list} />;
    }
}
