import React, { Component } from "react";
import { api_s3, api_utils_lasttradeday } from "../../../helpers/API";
import { LoadingPage } from "../../../helpers/SimpleComponents";

export default class ScriptResultsHtml extends Component {
    constructor(props) {
        super(props);
        this.state = {
            html: "no results",
            isLoading: false,
            filename: "[retrieving date...]",
        };
    }

    componentDidMount() {
        api_utils_lasttradeday().then((res) => {
            const dt = res.data;
            let filename = `script_results.${dt}.html`;
            this.setState({ isLoading: true, filename }, () => {
                api_s3("scriptresults", filename)
                    .then((res) => {
                        this.setState({ html: res.data, isLoading: false });
                    })
                    .catch((err) => {
                        this.setState({
                            isLoading: false,
                        });
                    });
            });
        });
    }

    render() {
        return (
            <div className="HtmlWrapper">
                <h3>script result</h3>
                <div>{this.state.filename}</div>
                {this.state.isLoading ? LoadingPage() : <div dangerouslySetInnerHTML={{ __html: this.state.html }} />}
            </div>
        );
    }
}
