import React, { useState } from 'react';
import styles from '../../css/TopStrikes.module.css'


const TopStrikesTableHead = ({ columns, handleSorting }) => {

    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("desc");

    const handleSortingChange = (accessor) => {
        const sortOrder = (accessor === sortField && order === "desc") ? "asc" : "desc";
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    }

    return (
        <thead>
            <tr>
                {columns.map(({ label, accessor, sortable }) => {
                    const cl = sortable
                        ? sortField === accessor && order === "asc"
                            ? "up"
                            : sortField === accessor && order === "desc"
                            ? "down"
                            : "default"
                        : "";
                    return (
                        <th key={accessor} onClick={sortable ? () => handleSortingChange(accessor) : null} className={styles[cl]}>
                            {label}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default TopStrikesTableHead;