import React from 'react';
import Quote from './DashboardQuote';
// import './Quotes.css'
import styles from "./Quotes.module.css"

// commodities that only display a single contract on the board
const solo_contracts = ["DX", "6E", "6J", "CNH", "6B", "6C", "6A", "6L", "6M",
                        "ZT", "Z3N", "ZF", "ZN", "TN", "ZB",
                        "ES", "YM", "NQ", "RTY",
                        "BTC", "ETH"];

function getPXcolor(quote_obj) {
    // determine gradient color based off of % change
    if (hasNulls(quote_obj)) {
        return {color: 'gray'};
    }
    const percent_dif = ((quote_obj.px_last - quote_obj.px_settle) / quote_obj.px_last) * 100;
    if (percent_dif <= -5) {
        //"quoteLoss4";
        return {color: 'white', textShadow: '1px 1px #000000', fontWeight: "bold"};
    } else if (percent_dif > -5 && percent_dif <= -2.5) {
        //"quoteLoss3";
        return {color: 'white', textShadow: '1px 1px #000000'};
    } else if (percent_dif > -2.5 && percent_dif <= -1) {
        //"quoteLoss2";
        return {color: "white", textShadow: '0.8px 0.8px black'};
    } else if (percent_dif > -1 && percent_dif < 0) {
        //"quoteLoss1";
        return {color: 'red', textShadow: '0.3px 0.3px #ffffff'};
    } else if (percent_dif > 0 && percent_dif < 1) {
        //"quoteGain1";
        return {color: 'blue', textShadow: '0.3px 0.3px #ffffff'};
    } else if (percent_dif >= 1 && percent_dif < 2.5) {
        //"quoteGain2";
        return {color: "white", textShadow: '0.8px 0.8px black'};
    } else if (percent_dif >= 2.5 && percent_dif < 5) {
        //"quoteGain3";
        return {color: 'white', textShadow: '1px 1px #000000'};
    } else if  (percent_dif >= 5) {
        //"quoteGain4";
        return {color: 'white', textShadow: '1px 1px #000000', fontWeight: "bold"};
    } else {
        //"quoteDefault";
        return {color:'gray'};
    }
}

//TODO: consider merging these two functions into one

// Check quote stats and return css styling for colors to reflect price change and volume
function getQuoteStyle(quote_obj) {
    let style = {};
    if (hasNulls(quote_obj)) {
        return {backgroundColor: "lightgrey", borderStyle: "ridge", marginRight: "1px"};
    }
    // determine gradient color based off of % change
    const percent_dif = ((quote_obj.px_last - quote_obj.px_settle) / quote_obj.px_last) * 100;
    if (percent_dif <= -5) {
        //"quoteLoss4";
        style.backgroundColor = "#ae1103";
    } else if (percent_dif > -5 && percent_dif <= -2.5) {
        //"quoteLoss3";
        style.backgroundColor = "#ce5042";
    } else if (percent_dif > -2.5 && percent_dif <= -1) {
        //"quoteLoss2";
        style.backgroundColor = "#ff6666";
    } else if (percent_dif > -1 && percent_dif < 0) {
        //"quoteLoss1";
        style.backgroundColor = "#ffcccc";
    } else if (percent_dif > 0 && percent_dif < 1) {
        //"quoteGain1";
        style.backgroundColor = "#cadeef";
    } else if (percent_dif >= 1 && percent_dif < 2.5) {
        //"quoteGain2";
        style.backgroundColor = "#9bd4e4";
    } else if (percent_dif >= 2.5 && percent_dif < 5) {
        //"quoteGain3";
        style.backgroundColor = "#39ace7";
    } else if  (percent_dif >= 5) {
        //"quoteGain4";
        style.backgroundColor = "#0784b5";
    } else {
        //"quoteDefault";
        style.backgroundColor = "lightgrey";
    }
    // check for limit up/down
    if ((quote_obj.high_limit_price !== null && quote_obj.px_high >= quote_obj.high_limit_price) || 
        (quote_obj.low_limit_price !== null && quote_obj.px_low <= quote_obj.low_limit_price)) {
        style.borderStyle = "solid";
    } else if (quote_obj.isMaxVol && !solo_contracts.includes(quote_obj.root)) {
        style.borderStyle = "double";
    } else {
        style.borderStyle = "ridge";
    }
    style.marginRight = "1px";

    return style;
}

function hasNulls(quote_obj) {
    return (quote_obj.session_open === null || quote_obj.session_high === null || quote_obj.session_low === null || quote_obj.px_last === null || quote_obj.px_settle === null);
}


// each comdty has its own QuoteBlock consisting of multiple quotes (months)
export default function QuoteBlock(props) {
    // props.status: comdtysMap<"ZC", monthsMap>  ->  months<"ZCK1", quoteObj>  ->  quote{symbol: , open: , high: , low: , px: }
    const map = props.status;
    const keys = [...map.keys()];

    let quotes = [];
    for (let i = 0; i < keys.length; i++) {
        quotes.push(map.get(keys[i]));
    }

    const handleCallback = (childData) => {
        props.boardCallback(childData);
    }

    const listItems = quotes.map((quote) =>
        <li key={quote.symbol} style={{display: "inline-block"}}>
            <Quote  obj=    {quote}
                    delta=  {quote.px_last === null || quote.px_settle === null ? "?" : quote.px_last - quote.px_settle}
                    pxColor={getPXcolor(quote)}
                    style=  {getQuoteStyle(quote)}
                    key=    {quote.symbol + "key"}
                    blockCallback= {handleCallback}
            />
        </li>
    );


    return (
        <div className="row" >
            <ul style={{textAlign: "center", margin: "1px", padding: "0px"}}>{listItems}</ul>
        </div>
    );
}   
