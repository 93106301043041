import React, { Component } from "react";
import MainBoard from "../../layout/MainBoard";
import SysMonitor from "./SysMonitor";
import Events from "./Events";

export default class mainboard extends Component {

    componentDidMount() {
        document.title = "System Monitor";
    }
    
    render() {
        const { path } = this.props.match;
        const list = [
            { name: "General Monitor", path: path + "/sys_monitor", component: SysMonitor },
            { name: "Events", path: path + "/events", component: Events },
        ];
        return <MainBoard title="System Monitor" list={list} />;
    }
}
