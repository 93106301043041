import moment from "moment-timezone";

/**
 * Return Weekdays Range as a list
 *
 * input: "2019-01-01", "2019-01-10"
 *
 * return: 2019-01-01,2019-01-02,2019-01-03,2019-01-04,2019-01-07,2019-01-08,2019-01-09,2019-01-10
 */
export function get_weekday_range(start, end) {
    const open = moment(start);
    const close = moment(end);
    const diff = close.diff(open, "days");

    let day_list = [open.format("YYYY-MM-DD")];
    for (let i = 0; i < diff; i++) {
        const nDay = open.add("day", 1);
        if (nDay.isoWeekday() !== 6 && nDay.isoWeekday() !== 7) {
            day_list.push(nDay.format("YYYY-MM-DD"));
        }
    }
    return day_list;
}

export function get_previous_bday(momentObject) {
    switch (momentObject.day()) {
        // If it is Monday (1),Saturday(6), or Sunday (0), Get the previous Friday (5)
        // and ensure we are on the previous week
        case 0:
        case 1:
        case 6:
            return momentObject.subtract(6, "days").day(5);
        // If it any other weekend, just return the previous day
        default:
            return momentObject.subtract(1, "days");
    }
}

/**
 * get current date based on EST time
 *
 * > 5.00pm --> current date
 * < 5.00pm --> previous date
 *
 * e.g.
 *
 * 2020-04-01 2.00pm --> 2020-03-31
 * 2020-04-01 6.00pm --> 2020-04-01
 *
 */
export function get_current_date(momentObject, isString) {
    const now = momentObject || moment();
    const pivot = now.hours() >= 17;
    if (pivot) {
        return isString ? now.format("YYYY-MM-DD") : now;
    } else {
        return isString ? get_previous_bday(now).format("YYYY-MM-DD") : get_previous_bday(now);
    }
}


export function get_datetime_by_n_day(n, format) {
    format = format || "YYYY-MM-DD"
    const now = moment()
    return now.subtract(n, "days").format("YYYY-MM-DD")
}

