import React, { Component } from "react";
import { HOST, highchartBasicConfig, COLORMAP } from "../../../../config";
import { generateTradesSeries, histogram, tradeChartTooltipFormatter } from "../../../../helpers/ChartFunctions";
import styled from "styled-components";
import ReactHighchart from "react-highcharts";
import Highstock from "react-highcharts/ReactHighstock";
import LoadingBar from "../../../../components/bar/LoadingBar";
import { api_ailab_doc } from "../../../../helpers/API";
import { getStats } from "../../../../helpers/Utils";
import moment from "moment";
import SimulationPeriodStats from "../../../../components/simulation.legacy/components/SimulationPeriodStats";

const HEADERHIGHT = 80;

const Layout = styled.div`
    padding: 0 20px;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: ${HEADERHIGHT}px calc(100% - ${HEADERHIGHT}px);
    position: relative;

    .chart-wrapper {
        height: 100%;
        position: relative;
    }

    .chart-wrapper > div {
        height: 100%;
    }

    .chart_grid {
        display: grid;
        grid-template-columns: 70% 30%;
        grid-template-rows: 100%;
    }

    .chart_grid_right {
        display: grid;
        grid-template-rows: 50% 50%;
        grid-template-columns: 100%;
    }
`;

export default class MiniTradesViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
        this.onChangePeriod = this.onChangePeriod.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    shouldComponentUpdate(nP, nS) {
        const { data } = this.state;
        if (!data && nS.data) {
            return true;
        } else {
            return false;
        }
    }

    onChangePeriod(e) {
        const { cumPnl, tradesData } = this.state;
        if (cumPnl) {
            // console.log(tradesData)

            let newPnl = cumPnl.filter((ele) => Date.parse(ele[0]) >= Date.parse(e.startDate) && Date.parse(ele[0]) <= Date.parse(e.endDate));
            let newTrades = tradesData.filter((ele) => Date.parse(ele["close_date"]) >= Date.parse(e.startDate) && Date.parse(ele["close_date"]) <= Date.parse(e.endDate));
            let dataForStats = { pnl: newPnl.map((ele) => [ele[0], ele[1]]), trades: newTrades };

            // console.log(dataForStats)

            let newStats = getStats(dataForStats, true);

            newStats["start_date"] = e.startDate;
            newStats["end_date"] = e.endDate;
            newStats = Object.keys(newStats).map((key) => ({ label: key, value: newStats[key] }));

            if (this.refs["stats_ref"]) {
                this.refs["stats_ref"].onChangeStats(newStats);
            }
        }
    }

    getData() {
        const onChangePeriod = this.onChangePeriod;

        const { data } = this.state;
        if (!data) {
            const { docid } = this.props;

            api_ailab_doc(docid, ["out-trades", "out-statistics", "out-px_last", "out-pnl"])
                .then((res) => {
                    const tradesRaw = res.data.content;
                    console.log(tradesRaw);

                    const distOfDailyPnl = histogram(
                        tradesRaw["out-trades"].map((ele) => [ele.raw_pnl]),
                        0.001
                    );
                    const distOfTradeProb = histogram(
                        tradesRaw["out-trades"].map((ele) => [ele.proba]),
                        0.01
                    );

                    const trades = generateTradesSeries({
                        trades: tradesRaw["out-trades"],
                        px_last: tradesRaw["out-px_last"],
                    });
                    const pnl = tradesRaw["out-pnl"];

                    const chartConfig__pnl = {
                        ...highchartBasicConfig,
                        chart: {
                            ...highchartBasicConfig.chart,
                            animation: false,
                            zoomType: "x",
                            panning: true,
                            panKey: "shift",
                            events: {
                                load: function (e) {
                                    const minX = e.target.xAxis[0].min;
                                    const maxX = e.target.xAxis[0].max;
                                    const startDate = moment(new Date(minX).toISOString()).format("YYYY-MM-DD");
                                    const endDate = moment(new Date(maxX).toISOString()).format("YYYY-MM-DD");
                                    const dateRange = { startDate, endDate };
                                    onChangePeriod(dateRange);
                                },
                            },
                        },
                        title: { text: "" },
                        yAxis: [
                            {
                                title: { text: "Trades" },
                                crosshair: { color: "#777", dashStyle: "dot" },
                                opposite: false,
                                tickAmount: 8,
                            },
                            {
                                title: { text: "Cumulative P&L" },
                                crosshair: { color: "#777", dashStyle: "dot" },
                                labels: {
                                    formatter: function () {
                                        return (this.value * 100).toFixed(2) + "%";
                                    },
                                },
                                tickAmount: 8,
                            },
                        ],
                        tooltip: {
                            useHTML: true,
                            formatter: tradeChartTooltipFormatter,
                        },
                        xAxis: {
                            type: "datetime",
                            crosshair: { color: "#777", dashStyle: "dot" },
                            events: {
                                afterSetExtremes: function (e) {
                                    try {
                                        const startDate = moment(new Date(e.min).toISOString()).format("YYYY-MM-DD");
                                        const endDate = moment(new Date(e.max).toISOString()).format("YYYY-MM-DD");
                                        const dateRange = { startDate, endDate };
                                        onChangePeriod(dateRange);
                                    } catch (error) {
                                        console.log(error);
                                    }
                                },
                            },
                        },
                        legend: {
                            enabled: true,
                        },
                        plotOptions: {
                            series: {
                                dataGrouping: {
                                    enabled: 0,
                                },
                                animation: {
                                    duration: 0,
                                },
                            },
                            scatter: {
                                animation: {
                                    duration: 0,
                                },
                            },
                        },
                        series: [
                            ...trades,
                            {
                                type: "areaspline",
                                lineWidth: 1,
                                fillOpacity: 0.1,
                                color: COLORMAP.green,
                                negativeColor: COLORMAP.red,
                                name: "Cumulative P&L",
                                data: pnl.map((ele) => [Date.parse(ele[0]), ele[1]]),
                                marker: { symbol: "circle" },
                                yAxis: 1,
                                softThreshold: true,
                            },
                        ],
                    };

                    const common_histchart_config = {
                        chart: { style: { fontFamily: "roboto" }, backgroundColor: "rgba(0,0,0,0)" },
                        title: { text: "" },
                        credits: { enabled: false },
                        plotOptions: {
                            series: {
                                animation: { duration: 400 },
                                pointPadding: 0,
                                groupPadding: 0.1,
                            },
                        },
                    };

                    const chartConfig__dist_of_pnl = {
                        ...common_histchart_config,
                        series: [
                            {
                                type: "column",
                                name: "Distribution of Daily P&L",
                                data: distOfDailyPnl.map((ele) => ({ x: ele[0], y: ele[1], color: ele[0] >= 0 ? COLORMAP.green : COLORMAP.red })),
                                color: COLORMAP.green,
                            },
                        ],
                    };
                    const chartConfig__dist_of_tradeproba = {
                        ...common_histchart_config,
                        series: [
                            {
                                type: "column",
                                name: "Distribution of Trade Proba",
                                data: distOfTradeProb.map((ele) => ({ x: ele[0], y: ele[1], color: ele[0] >= 0 ? COLORMAP.green : COLORMAP.red })),
                                color: COLORMAP.green,
                            },
                        ],
                    };

                    const chartConfig = {
                        pnl: chartConfig__pnl,
                        dist_of_pnl: chartConfig__dist_of_pnl,
                        dist_of_tradeproba: chartConfig__dist_of_tradeproba,
                    };

                    this.setState({
                        data: {
                            trades,
                            pnl,
                            stats: tradesRaw["out-statistics"],
                        },
                        cumPnl: pnl,
                        tradesData: tradesRaw["out-trades"],
                        chartConfig,
                        isLoading: false,
                    });
                })
                .catch((e) => console.log(e));
        }
    }

    render() {
        const { isLoading, data, chartConfig } = this.state;
        const { docid, factors } = this.props;

        const { stats, trades } = data || {};

        return (
            <Layout>
                <div>
                    <div className="flexBox flexBox_between" style={{ color: "#888", fontSize: "1.1rem" }}>
                        <div># {docid}</div>
                        <a target="_blank" rel="noopener noreferrer" style={{ color: "#777", fontSize: "1.2rem" }} href={`${HOST}/imbue_ailab_comdty/result_viewer?docid=${docid}`}>
                            {" "}
                            View More <i style={{ color: "#777", fontSize: "1.3rem", marginLeft: "4px" }} className="fas fa-search-plus"></i>{" "}
                        </a>
                    </div>
                    <div className="flexBox" style={{ overflow: "auto", flexWrap: "nowrap", margin: "5px 0" }}>
                        {(factors || [])
                            .sort((a, b) => a.localeCompare(b))
                            .map((item) => (
                                <div className="factor_tag">{item}</div>
                            ))}
                    </div>
                    <SimulationPeriodStats ref="stats_ref" />
                </div>

                {!chartConfig ? (
                    <div className="loading-text" style={{ nimation: "fadeIn .4s", display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <LoadingBar type="FaSpinner" color="#aaa" size="4rem" />
                    </div>
                ) : (
                    <div className="chart-wrapper" style={{ animation: "fadeIn .4s" }}>
                        <div className="chart_grid">
                            <Highstock config={chartConfig.pnl} />
                            <div className="chart_grid_right">
                                <ReactHighchart config={chartConfig.dist_of_pnl} />
                                <ReactHighchart config={chartConfig.dist_of_tradeproba} />
                            </div>
                        </div>
                    </div>
                )}
            </Layout>
        );
    }
}
