import React, { Component } from 'react'
import { api_factor_meta } from '../../../helpers/API';
import ImbueTable from '../../../components/table/ImbueTable';

export default class FactorCard extends Component {

    constructor(e) {
        super(e)
        this.state = {
            results: [],
            isInit: false
        }
        this.query = this.query.bind(this)
    }

    componentDidMount() {
        const { factor } = this.props
        this.query(factor)
    }

    query(factor) {
        api_factor_meta(factor)
            .then(res => {
                if (res.data.status) {
                    this.setState(
                        {
                            results: res.data.message,
                            isInit: true
                        }
                    )
                }
            })
    }

    render() {

        const results = this.state.results
        const isInit = this.state.isInit

        const tableConfig = {
            excluded: [
                'id',
            ],
            sorted: [
                'factorname',
                'ticker',
                'bbg_frequency',
                'description',
            ],
            data: results
        }

        return (
            <div>
                <ImbueTable key={isInit} config={tableConfig} />
            </div>
        )
    }
}
