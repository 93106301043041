// Claude Chen | claude.chen@imbuecapital.com
// Created at: 7 Sept 2017
// Control all read and write to localstroage and sessionstorage behaviours

function setLocal(key, value) {
    localStorage.setItem(key, value);
}
function getLocal(key) {
    return localStorage.getItem(key);
}

export function setLocalAsObject(key, value) {
    value = JSON.stringify(value);
    localStorage.setItem(key, value);
}
export function getLocalAsObject(key) {
    const object = localStorage.getItem(key);
    if (object) {
        return JSON.parse(object);
    } else {
        return null;
    }
}

// --+-- AI Lab Settings --+--
export function setGlobalGameMode(mode) {
    setLocal("_gamemode", mode);
}
export function getGlobalGameMode() {
    const mode = parseInt(getLocal("_gamemode"));
    if (!mode || Number.isNaN(mode)) {
        setGlobalGameMode(0);
        return 0;
    } else {
        return mode;
    }
}
