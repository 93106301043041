/**
 * API.js
 * All api functions
 * ------------------------------------------
 * Claude Chen | claude.chen@imbuecapital.com
 */

import { URL, EMAIL } from "../config";
import { AxiosGet, AxiosPost, AxiosPut, AxiosGetFile } from "./AuthFetch";
// import Axios from "axios";

// =============================================================================
// #AUTHENTICATION
// =============================================================================
/**
 * Validate stored access token
 */
export function api_validate_token() {
    const API = `${URL}`;
    return AxiosGet(API);
}

// =============================================================================
// # TRADES HEATMAP (-Brian Gardner) 9/27/21
// =============================================================================
export function api_trades_heatmap(paras) {
    const API = `${URL}/trades_heatmap`;
    return AxiosPost(API, paras);
}

// =============================================================================
// # DELTAS OPTIONS CHART (-Brian Gardner) 11/30/21
// =============================================================================
export function api_option_indicators(paras) {
    const API = `${URL}/optionindicators`;
    return AxiosPost(API, paras);
}

// (-Brian Gardner) 12/13/21
export function api_options(paras) {
    const API = `${URL}/options`;
    return AxiosPost(API, paras);
}

export function api_options_highlights(paras) {
    const API = `${URL}/options_highlights`;
    return AxiosPost(API, paras);
}

export function api_net_delta_gamma(paras) {
    const API = `${URL}/net_delta_gamma`;
    return AxiosPost(API, paras);
}

export function api_bid_ask_averages(paras) {
    const API = `${URL}/bid_ask_averages`;
    return AxiosPost(API, paras);
}

// not ready yet
// export function api_custom_spreads(paras) {
//     const API = `${URL}/custom_spreads`;
//     return AxiosPost(API, paras);
// }

export function api_energy_prices(paras) {
    const API = `${URL}/energyprices`;
    return AxiosPost(API, paras);
}

// export function api_gamma_chart(paras) {
//     const API = `${URL}/gamma_chart`;
//     return AxiosPost(API, paras);
// }

export function api_hydrodynamics(paras) {
    const API = `${URL}/hydrodynamics`;
    return AxiosPost(API, paras);
}

export function api_calendar_spreads(paras) {
    const API = `${URL}/calendarspreads`;
    return AxiosPost(API, paras);
}

export function api_calendar_spreads_z(paras) {
    const API = `${URL}/calendarspreadsZ`;
    return AxiosPost(API, paras);
}

export function api_spreads_seasonality(paras) {
    const API = `${URL}/spreads_seasonality`;
    return AxiosPost(API, paras);
}

export function api_spreads_series(paras) {
    const API = `${URL}/spreads_series`;
    return AxiosPost(API, paras);
}

export function api_roll_history(paras) {
    const API = `${URL}/roll_history`;
    return AxiosPost(API, paras);
}

export function api_live_reports(paras) {
    const API = `${URL}/live_reports`;
    return AxiosPost(API, paras);
}

export function api_intraday_price_history(paras) {
    const API = `${URL}/intraday_price_history`;
    return AxiosPost(API, paras);
}

// =============================================================================
// #DATA_CENTER #RESEARCHCENTER
// =============================================================================


export function api_intraday_data(paras) {
    let {root,
        symbol,
        minutes,
        datefrom} = paras
    let API = `${URL}/data/intraday?root=${root}&symbol=${symbol}&minutes=${minutes}&datefrom=${datefrom}`
    return AxiosGet(API);
}

export function api_historical_portfolio_view(paras) {
    let API = `${URL}/historicalportoflioview`
    return AxiosPost(API, paras);
}

export function api_rank_historical_portfolio(paras) {
    let API = `${URL}/rank_historical_performance`
    return AxiosPost(API, paras);
}

export function api_get_latest_portfolio_trades(paras) {
    let API = `${URL}/get_latest_portfolio_trades`
    return AxiosPost(API, paras);
}

export function api_systemic_monitor(paras) {
    const { commodities, measurements, interval } = paras;
    let API = `${URL}/systemicmonitor?commodities=${commodities}&measurements=${measurements}&interval=${interval}`;
    return AxiosGet(API);
}


export function api_optionchain(paras) {
    const { date } = paras;
    let API = `${URL}/optionchain`;
    if (date) {
        API += "?date=" + date;
    }
    return AxiosGet(API);
}

export function api_vpin(paras) {
    const { asset, query, datefrom, dateto } = paras;
    let API = `${URL}/vpin?query=${query}`;
    if (asset) {
        API += `&asset=${asset}`;
    }
    if (datefrom) {
        API += `&datefrom=${datefrom}`;
    }
    if (dateto) {
        API += `&dateto=${dateto}`;
    }
    return AxiosGet(API);
}

export function api_spreads(paras) {
    const { comdty, deferred, method, first } = paras;
    const API = `${URL}/spreads?comdty=${comdty}&deferred=${deferred}&method=${method}&first=${first}`;
    return AxiosGet(API);
}

export function api_gdelt(paras) {
    const { keyword, country } = paras;
    const API = `${URL}/external/gdelt?keyword=${keyword}&country=${country}`;
    return AxiosGet(API);
}

export function api_ecocalendar(date) {
    const API = `${URL}/forexfactorycal?date=${date}`;
    return AxiosGet(API);
}

export function api_structural_breaks(keyname) {
    const API = `${URL}/structuralbreaks?keyname=${keyname}`;
    return AxiosGet(API);
}

export function api_interpretability(paras) {
    const { query, strategyname } = paras;
    let API = `${URL}/interpretability?query=${query}`;
    if (strategyname) {
        API += `&strategyname=${strategyname}`;
    }
    return AxiosGet(API);
}

export function api_robintrack_realtime(paras) {
    const { query, symbol, limit, hoursago, relative, isintraday } = paras;
    let API = `${URL}/robintrack?query=${query}`;
    if (symbol) {
        API += `&symbol=${symbol}`;
    }
    if (limit) {
        API += `&limit=${limit}`;
    }
    if (hoursago) {
        API += `&hoursago=${hoursago}`;
    }
    if (relative) {
        API += `&relative=${relative}`;
    }
    if (isintraday) {
        API += `&isintraday=${isintraday}`;
    }
    return AxiosGet(API);
}

export function api_intraday_movement(paras) {
    const { 
        root,
        symbol,
        minutes,
        datefrom,
        aggfunc 
    } = paras;
    const API = `${URL}/intradaymove?root=${root}&symbol=${root}${symbol}&minutes=${minutes}&datefrom=${datefrom}&aggfunc=${aggfunc}`;
    return AxiosGet(API);
}

export function api_position_risk(paras) {
    const {
        query,
        assets,
        holding_period,
        portf,
        scaling_parameter,
        scaling_method,
        weights,
        // #HRP
        positions_map,
        datefrom,
        long_bias,
        // #STOP LOSS RISK
        contract,
        number_of_contracts,
        risk,
        // #portfolio vol
        tn,
    } = paras;
    let API = `${URL}/positionrisk?query=${query}`;
    if (assets) {
        API += `&assets=${assets}`;
    }
    if (holding_period) {
        API += `&holding_period=${holding_period}`;
    }
    if (portf) {
        API += `&portf=${portf}`;
    }
    if (scaling_parameter) {
        API += `&scaling_parameter=${scaling_parameter}`;
    }
    if (scaling_method) {
        API += `&scaling_method=${scaling_method}`;
    }
    if (weights) {
        API += `&weights=${weights}`;
    }
    if (positions_map) {
        API += `&positions_map=${positions_map}`;
    }
    if (datefrom) {
        API += `&datefrom=${datefrom}`;
    }
    if (long_bias) {
        API += `&long_bias=${long_bias}`;
    }
    if (contract) {
        API += `&contract=${contract}`;
    }
    if (number_of_contracts) {
        API += `&number_of_contracts=${number_of_contracts}`;
    }
    if (risk) {
        API += `&risk=${risk}`;
    }
    if (tn) {
        API += `&tn=${tn}`
    }
    return AxiosGet(API);
}

export function api_positioning_analysis(paras) {
    // const { comdty, datatype, charttype, datefrom, query } = paras;
    const { comdty, datatype, charttype, datefrom } = paras;
    const API = `${URL}/pa?security=${comdty}&datatype=${datatype}&charttype=${charttype}&datefrom=${datefrom}`;
    return AxiosGet(API);
}

export function api_positioning_cotranking() {
    const API = `${URL}/pa?query=summary`;
    return AxiosGet(API);
}

export function api_corr_analysis(paras) {
    const tickers = paras.tickers;
    const sector = paras.sector;
    const datefrom = paras.datefrom;
    const dateto = paras.dateto;
    const query = [
        [tickers, "tickers"],
        [sector, "sector"],
        [datefrom, "datefrom"],
        [dateto, "dateto"],
    ]
        .filter((ele) => ele[0])
        .map((ele) => `${ele[1]}=${ele[0]}`)
        .join("&");
    const API = `${URL}/corr_analysis/live?${query}`;
    return AxiosGet(API);
}

// =============================================================================
// #DATA_CENTER
// =============================================================================

export function api_couchdb(database, docid) {
    const API = `${URL}/couch?database=${database}&docid=${docid}`;
    return AxiosGet(API);
}

export function api_couchdb_view(dbname, keyname, viewdocname, viewname) {
    const API = `${URL}/couch/view?dbname=${dbname}&keyname=${keyname}&viewdocname=${viewdocname}&viewname=${viewname}`;
    return AxiosGet(API);
}

export function api_postgres(label) {
    const API = `${URL}/postgresaccess?data=${label}`;
    return AxiosGet(API);
}

/**
 *
 * @param {string} ticker --- e.g. "C 1 Comdty"
 * @param {string} fields --- e.g. "px_last", "px_last,px_open"
 * @param {string} from  --- e.g. "2010-01-01"
 * @param {string} to --- e.g. "2018-01-01"
 * @param {string} rolling --- e.g. "30D"
 * @param {boolean} cumsum --- e.g. true
 */
export function api_blp(ticker, fields, from, config) {
    config = config || {};
    let API = `${URL}/data/blp?ticker=${ticker}&fields=${fields}&from=${from}&`;
    if (config.rolling) {
        API += "rolling=" + config.rolling + "&";
    }
    if (config.cumsum) {
        API += "cumsum=true&";
    }
    if (config.nofill) {
        API += "nofill=true&";
    }
    if (config.schema) {
        API += "schema=" + config.schema + "&";
    }
    return AxiosGet(API);
}

/**
 *
 * @param {string} tickers --- e.g. "C 1 Comdty"
 * @param {string} fields --- e.g. "px_last", "px_last,px_open"
 * @param {string} from --- e.g. "2010-01-01"
 */
export function api_blp_multi(tickers, fields, from) {
    let API = `${URL}/data/blpmulti?tickers=${tickers}&fields=${fields}&datefrom=${from}&`;
    return AxiosGet(API);
}

export function api_blp_meta(ticker) {
    const API = `${URL}/data/blpref?ticker=${ticker}`;
    return AxiosGet(API);
}

/**
 *
 * @param {*} keyword
 * @param {*} limit
 */
export function api_blp_meta_finder(keyword, limit) {
    if (limit) {
        const API = `${URL}/data/blpreffinder?keyword=${keyword}&limit=${limit}`;
        return AxiosGet(API);
    } else {
        const API = `${URL}/data/blpreffinder?keyword=${keyword}`;
        return AxiosGet(API);
    }
}

export function api_blp_seasonality(paras) {
    // Blp Seasonality (14 Jul 2020)
    const { ticker1, ticker2, field1, field2 } = paras;
    const API = `${URL}/data/blpseasonality?ticker1=${ticker1}&ticker2=${ticker2}&field1=${field1}&field2=${field2}`;
    return AxiosGet(API);
}

export function api_factor_meta_search(keyword) {
    const API = `${URL}/data/factormeta/search?keyword=${keyword}`;
    return AxiosGet(API);
}

export function api_factor_meta(factor) {
    const API = `${URL}/data/factormeta?factor=${factor}`;
    return AxiosGet(API);
}

export function api_data_noaa_daily_station_index() {
    const API = `${URL}/data/noaadaily?request_type=station_index`;
    return AxiosGet(API);
}

export function api_data_noaa_daily_station_meta(stationid) {
    const API = `${URL}/data/noaadaily?request_type=station_meta&station_id=${stationid}`;
    return AxiosGet(API);
}

export function api_data_noaa_daily_data(tablename, datefrom) {
    if (datefrom) {
        const API = `${URL}/data/noaadaily?request_type=data&tablename=${tablename}&datefrom=${datefrom}`;
        return AxiosGet(API);
    } else {
        const API = `${URL}/data/noaadaily?request_type=data&tablename=${tablename}`;
        return AxiosGet(API);
    }
}

// *** BOM ONLINE
export function api_data_bomonline_station_index() {
    const API = `${URL}/data/bomonline?request_type=station_index`;
    return AxiosGet(API);
}

export function api_data_bomonline_station_meta(stationname) {
    const API = `${URL}/data/bomonline?request_type=station_meta&station_name=${stationname}`;
    return AxiosGet(API);
}

export function api_data_bomonline_data(tsid) {
    const API = `${URL}/data/bomonline?request_type=data&ts_id=${tsid}`;
    return AxiosGet(API);
}

export function api_ndvi(id) {
    const API = `${URL}/data/demand?src=ndvi&id=${id}&`;
    return AxiosGet(API);
}

// =============================================================================
// #AILAB
// =============================================================================

/**
 *
 * get AILab Form Data
 * @param {string} gamemode -- e.g. "agent_form", "optimiser_form", "deep_learn_form"
 */
export function api_ailab_form(gamemode) {
    const API = `${URL}/agentform?mode=${gamemode}&`;
    return AxiosGet(API);
}

// Optimiser
export function api_ailab_optimiser_query_user(user) {
    const API = `${URL}/optimise?user=${user}`;
    return AxiosGet(API);
}

export function api_ailab_factoranalysis(docids) {
    const API = `${URL}/ailab_factoranalysis?docids=${docids}`;
    return AxiosGet(API);
}

/**
 *
 * @param {string} ticker
 * @param {string} factors
 */
export function api_ailab_zeus_work_put(requestBody) {
    const API = `${URL}/zeuswork`;
    return AxiosPut(API, requestBody);
}

export function api_ailab_zeus_work_get() {
    const API = `${URL}/zeuswork`;
    return AxiosGet(API);
}

export function api_ailab_zeus_general_report() {
    const API = `${URL}/zeusgeneral`;
    return AxiosGet(API);
}

// =============================================================================
// #AILAB Version 2
// =============================================================================

export function api_ailabv2_get_job(docid) {
    const API = `${URL}/ailabv2`;
    const body = {
        request: "get",
        params: {
            docid,
        },
    };
    return AxiosPost(API, body);
}

export function api_ailabv2_list_jobs() {
    const API = `${URL}/ailabv2`;
    const body = {
        request: "list",
    };
    return AxiosPost(API, body);
}

export function api_ailabv2_delete_job(docid) {
    const API = `${URL}/ailabv2`;
    const body = {
        request: "delete",
        params: {
            docid,
        },
    };
    return AxiosPost(API, body);
}

export function api_ailabv2_init_job(config) {
    const API = `${URL}/ailabv2`;
    const body = {
        request: "init",
        params: {
            config,
        },
    };
    return AxiosPost(API, body);
}

export function api_ailabv2_duplicate_job(docid) {
    const API = `${URL}/ailabv2`;
    const body = {
        request: "duplicate",
        params: {
            docid,
        },
    };
    return AxiosPost(API, body);
}

export function api_ailabv2_change_status(docid, status) {
    const newStatus = status === "active" ? "inactive" : "active";
    const API = `${URL}/ailabv2`;
    const body = {
        request: "change_status",
        params: {
            docid,
            status: newStatus,
        },
    };
    return AxiosPost(API, body);
}

export function api_ailabv2_modify_job(docid, config) {
    const API = `${URL}/ailabv2`;
    const body = {
        request: "modify",
        params: {
            docid,
            config,
        },
    };
    return AxiosPost(API, body);
}

// =============================================================================
// #AILAB_MODEL_DOC
// =============================================================================
/**
 * @param {string} docid
 * @param {array} fields (Optional, Default = [])
 */
export function api_ailab_doc(docid, fields = []) {
    let API = `${URL}/ailabdoc?docid=${docid}`;
    if (fields.length > 0) {
        API += `&fields=${fields.join(",")}`;
    }
    return AxiosGet(API);
}

/**
 *
 * Function to remove AILab result
 * @param {string} docid
 */
export function api_ailab_remove(docid) {
    const USER = EMAIL;
    const API = `${URL}/ailabremove/?`;
    const requestBody = {
        user: USER,
        docid: docid,
    };
    return AxiosPost(API, requestBody);
}

export function api_ailab_finder(requestBody, timeout) {
    const API = `${URL}/docfinder`;
    return AxiosPost(API, requestBody, timeout);
}

export function api_ailab_explain(requestBody) {
    const API = `${URL}/docfinder?explain=true`;
    return AxiosPost(API, requestBody);
}

// =============================================================================
// #AILAB_LEADERBOARD
// =============================================================================

/**
 * AILab general security leaderboard
 * API: `${URL}/ailab_secrank/?`
 */
export function api_leaderboard_overview() {
    const API = `${URL}/ailab_secrank/?`;
    return AxiosGet(API);
}

/**
 *
 * @param {string} ticker
 * @param {number} limit
 * @param {number} skip
 */
export function api_leaderboard_security(ticker, limit, skip, userField = "") {
    const DEFAULTLIMIT = 50;
    const DEFAULTSKIP = 0;
    const API =
        `${URL}/ailab_secrank?ticker=${ticker}&limit=${limit || DEFAULTLIMIT}&skip=${skip || DEFAULTSKIP}` + userField;
    return AxiosGet(API);
}

// =============================================================================
// #FEATURE_ENGINEER
// =============================================================================

export function api_feature_importance_external(data) {
    const API = `${URL}/feateng`;
    return AxiosPost(API, data);
}

export function api_feature_importance_internal(ticker, factors) {
    const API = `${URL}/feateng?ticker=${ticker}&factors=${factors}`;
    return AxiosGet(API);
}

// =============================================================================
// #SIMULATION
// =============================================================================

/**
 * Get Simulation Overview (Portfolio data and securities stats)
 * <API>/simulations?type=strategy&strategyid=<Strategy ID>
 *
 * @param {string} strategy_id
 */
export function api_simulation_portfolio(strategy_id, isV2) {
    if (isV2) {
        const API = `${URL}/simulationsv2?type=strategy&strategyid=${strategy_id}`;
        return AxiosGet(API);
    } else {
        const API = `${URL}/simulations?type=strategy&strategyid=${strategy_id}`;
        return AxiosGet(API);
    }
}
export function api_simulation_portfolio_raw(strategy_id) {
    const API = `${URL}/simulations?type=strategy&strategyid=${strategy_id}&isRaw=true`;
    return AxiosGet(API);
}

// User's portfolio simulation
export function api_simulation_user_portfolio(strategy_id) {
    const USER = EMAIL;
    const API = `${URL}/simulations?type=strategy&strategy_type=user&strategy_owner=${USER}&strategyid=${strategy_id}`;
    return AxiosGet(API);
}

export function api_simulation_user_portfolio_raw(strategy_id) {
    const USER = EMAIL;
    const API = `${URL}/simulations?type=strategy&strategy_type=user&strategy_owner=${USER}&strategyid=${strategy_id}&isRaw=true`;
    return AxiosGet(API);
}

export function api_simulation_trades(strategy_id) {
    const API = `${URL}/simulations?type=trades&strategyid=${strategy_id}&`;
    return AxiosGet(API);
}

/**
 * Get simulation security data based on given docid and security index
 *
 * @param {string} docid
 * @param {number} secIndex
 */
export function api_simulation_security(docid, secIndex, isV2) {
    if (isV2) {
        const API = `${URL}/simulations?source=couch&type=security&docid=${docid}&secindex=${secIndex}`;
        return AxiosGet(API);
    } else {
        const API = `${URL}/simulationsv2?source=couch&type=security&docid=${docid}&secindex=${secIndex}`;
        return AxiosGet(API);
    }
}

/**
 *
 * @param {string} docid
 */
export function api_simulation_overview(docid) {
    const API = `${URL}/simulations?source=couch&type=overview&docid=${docid}&`;
    return AxiosGet(API);
}

/**
 * Fund Level Performance
 * @param {string} strategy_id
 */
export function api_fund_performance(strategy_id) {
    const API = `${URL}/simulations?type=portfolio&strategyid=${strategy_id}&`;
    return AxiosGet(API);
}

export function api_fund_performance_from_data(list_of_portfolio) {
    const API = `${URL}/simulations?type=fund`;
    return AxiosPost(API, list_of_portfolio);
}

export function api_fund_performance_from_names(list_of_portfolio_name) {
    const API = `${URL}/simulations?type=fund&strategyids=${list_of_portfolio_name.join(",")}`;
    return AxiosGet(API);
}

// =============================================================================
// #PORTFOLIO
// =============================================================================
export function api_portfolio_meta(user, portfolioName) {
    // user = "les.finemore@imbuecapital.com"
    user = user || EMAIL;
    const API = `${URL}/portfolio?type=single&user=${user}&portfolio=${portfolioName}`;
    return AxiosGet(API);
}

export function api_portfolio_meta_list(user) {
    const API = `${URL}/portfolio?type=list&user=${user}&`;
    return AxiosGet(API);
}

export function api_save_portfolio_meta(portfolioMeta) {
    const API = `${URL}/portfolio/`;
    return AxiosPost(API, portfolioMeta);
}

export function api_remove_portfolio_meta(user, portfolioName) {
    const API = `${URL}/portfolio?type=remove&user=${user}&portfolio=${portfolioName}`;
    return AxiosGet(API);
}

// =============================================================================
// #TRADE_MANAGER
// =============================================================================

// -- Trade Manager
/**
 *
 * @param {string} id -- table id for live trades, e.g. vertex_trades
 */
export function api_realtime_trade(id) {
    const API = `${URL}/trades?which=${id}`;
    return AxiosGet(API);
}

export function api_portfolio_trades(portfolio, strategy) {
    const API = `${URL}/portfoliotrades?portfolio=${portfolio}&strategy=${strategy}`;
    return AxiosGet(API);
}

export function api_realtime_trade_performance(id) {
    const API = `${URL}/paper/performance?which=${id}`;
    return AxiosGet(API);
}

export function api_trade_evaluater(ticker, date, field) {
    const API = `${URL}/prices/?ticker=${ticker}&date=${date}&field=${field}`;
    return AxiosGet(API);
}

// -- Saxo Trading System
export function api_saxobalance() {
    const API = `${URL}/saxobalance/`;
    return AxiosGet(API);
}

export function api_saxopositions() {
    const API = `${URL}/saxopositions/`;
    return AxiosGet(API);
}

export function api_trades_general_report(portfolioName) {
    const API = `${URL}/trades/generalreport?portfolio_name=${portfolioName}`;
    return AxiosGet(API);
}

export function api_model_explain() {
    const API = `${URL}/modelexplain`;
    return AxiosGet(API);
}

// Live Trades Mananger (TMV3), added 2020-02
export function api_live_trades__overview(status, date) {
    status = status || "live";
    let API = `${URL}/livetrades?request=overview&status=${status}`;
    if (date) {
        API += "&date=" + date;
    }
    return AxiosGet(API);
}

export function api_live_trades__latest(label_strategy_name) {
    const API = `${URL}/livetrades?request=latest&label_strategy_name=${label_strategy_name}`;
    return AxiosGet(API);
}

// =============================================================================
// #NEWs
// =============================================================================
export function api_news(limit) {
    if (!limit) {
        limit = 100;
    }
    const API = `${URL}/news?limit=${limit}`;
    return AxiosGet(API);
}

// =============================================================================
// #META #INDEX
// =============================================================================
export function api_weather_dashboard_index() {
    const API = `${URL}/data/weather`;
    return AxiosGet(API);
}

// =============================================================================
// #S3
// =============================================================================

export function api_s3(bucketname, keyname, isfile, isjson) {
    let API = `${URL}/s3?bucketname=${bucketname}&keyname=${keyname}`;
    if (isjson) {
        API+="&isjson=true"
    }
    if (isfile) {
        return AxiosGetFile(API);
    } else {
        return AxiosGet(API);
    }
}

// =============================================================================
// #UTILS
// =============================================================================

export function api_utils_lasttradeday() {
    const API = `${URL}/public/utils/lasttradeday`;
    return AxiosGet(API);
}

// =============================================================================
// #ADMIN
// =============================================================================

export function api_monit() {
    const API = `${URL}/monit/`;
    return AxiosGet(API);
}

export function api_couchtasks() {
    const API = `${URL}/couchtasks/`;
    return AxiosGet(API);
}

export function api_sysevents(event_type) {
    const API = `${URL}/sysevents?event_type=${event_type}`;
    return AxiosGet(API);
}
