import React, { Component } from 'react';
import QueryTickerChart from "../../components/chart/QueryTickerChart";

export default class DataQuery extends Component {
    render() {
        return (
            <QueryTickerChart />
        );
    }
}
