import React, { Component } from "react";
import { api_couchdb_view } from "../../../helpers/API";

class StrategyInsight extends Component {
    componentDidMount() {
        const strategy_name = this.props.strategy_name;
        console.log(strategy_name);
        api_couchdb_view("experiment_live_feature", strategy_name, "views", "results_pdf").then(res => {
            const results = res.data;
            console.log(results);
        });
    }

    render() {
        return <div></div>;
    }
}

export default StrategyInsight;
