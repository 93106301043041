exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Z-score color/weight styles from Cooper's contango */\n.DashboardQuotes_z1p__24Otw {\ntext-align: center;\nbackground: #c0e0ff;\n}\n.DashboardQuotes_z2p__2Jkpz {\ntext-align: center;\nbackground: #c0e0ff;\nfont-weight: bold;\n}\n.DashboardQuotes_z3p__21c3q {\ntext-align: center;\ncolor: white;\nbackground-color: #6080d0;\nfont-weight: bold;\n}\n.DashboardQuotes_z1n__3oX3J {\ntext-align: center;    \nbackground: #ffe0e0;\n}\n.DashboardQuotes_z2n__21zxt {\ntext-align: center;\nbackground: #ffe0e0;\nfont-weight: bold;\n}\n.DashboardQuotes_z3n__2Ga0D {\ntext-align: center;\ncolor: white;\nbackground-color: #d06080;\nfont-weight: bold;\n}", ""]);

// exports
exports.locals = {
	"z1p": "DashboardQuotes_z1p__24Otw",
	"z2p": "DashboardQuotes_z2p__2Jkpz",
	"z3p": "DashboardQuotes_z3p__21c3q",
	"z1n": "DashboardQuotes_z1n__3oX3J",
	"z2n": "DashboardQuotes_z2n__21zxt",
	"z3n": "DashboardQuotes_z3n__2Ga0D"
};