import React, { Component } from 'react'
import HighChart from 'react-highcharts';
import { URL } from '../../config';
import { AuthGet } from '../../helpers/AuthFetch';
import { toAusTime } from '../../helpers/Utils';

export default class MiniPnlChart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        const { isStatic } = this.props
        if (isStatic) {
        } else {
            const { ifHover } = this.props
            if (!ifHover) {
                this.renderChart()
            }
        }

    }

    renderChart() {
        const { pnl } = this.state
        if (!pnl) {
            const { docid } = this.props
            const API = `${URL}/ailabdoc?docid=${docid}&fields=out-pnl&`;
            this.setState({ isLoading: true }, () => {
                AuthGet(API)
                    .then(rsp => rsp.json())
                    .then(res => {
                        let pnl = res.content['out-pnl']
                        this.setState({
                            pnl,
                            isLoading: false
                        })
                    })
                    .catch(e => console.log(e))
            })
        }
    }

    shouldComponentUpdate(nextPorps, nextStates) {
        const { pnl, isLoading } = this.state
        if ((!pnl && nextStates.pnl) || (isLoading !== nextStates.isLoading)) {
            return true
        }
        else if (this.props.isStatic) {
            if (nextPorps.data.length !== this.props.data.length) {
                return true
            }
        }
        return false
    }

    render() {
        const { initText, data, isStatic } = this.props
        const { pnl, isLoading } = this.state

        const seriesData = pnl ? pnl.map(ele => [Date.parse(ele[0]), ele[1]]) : (isStatic ? data : [])

        const config = {
            chart: {
                height: 100,
                backgroundColor: "rgba(0,0,0,0)",
                style: { fontFamily: "roboto" }
            },
            title: { text: "" },
            credits: { enabled: 0 },
            legend: { enabled: 0 },
            navigator: { enabled: 0 },
            tooltip: {
                animation: false,
                hideDelay: 0,
                useHtml: true,
                formatter: function () {
                    return `
                        <span style="margin-right: 4px">${(this.y * 100).toFixed(2) + "%"} </span>
                        <span style="color: #888">${toAusTime(this.x / 1000, 3)}</span>
                    `
                },
            },
            yAxis: {
                title: { text: "" },
                labels: { enabled: false },
                gridLineWidth: 0,
                lineWidth: 0,
                tickAmount: 30,
                startOnTick: false,
                endOnTick: false,
            },
            xAxis: { type: "datetime", lineWidth: 0, minorGridLineWidth: 0, minorTickLength: 0, tickLength: 0, labels: { enabled: false }, crosshair: { width: 1, color: 'green', dashStyle: "shortDash" } },
            plotOptions: {
                series: {
                    animation: false,
                    dataGrouping: {
                        enabled: 0
                    },
                }
            },
            series: [{
                data: seriesData,
                lineWidth: 1,
                type: "areaspline",
                fillOpacity: .1,
                states: { hover: { lineWidth: 0, halo: { size: 0 } } },
                color: '#00695C',
                negativeColor: '#D50000',
                softThreshold: true
            }]
        }

        return (
            pnl || isStatic
                ?
                <HighChart config={config} />
                :
                <div style={{ fontSize: "1.2rem", color: "#999" }}>{isLoading ? "Loading ..." : initText || "no data"}</div>
        )
    }
}
