import React, { Component } from "react";
import styled from "styled-components";

import { TickerDict, getTickerName } from "../../index/TickerDictonary";

const Layout = styled.div`
    overflow: auto;
    font-family: var(--font-main);
    padding: 20px 5px;
    box-sizing: border-box;
    background: #fff;

    span {
        font-family: var(--font-main);
    }

    .digit_wrapper {
        font-family: var(--font-main);
        font-weight: 800;
        padding: 2px 4px;
        color: white;
        font-size: 1rem;
    }

    .sidebar_btn {
        padding: 10px 15px;
        margin-bottom: 5px;
        font-size: 1rem;
        color: var(--color-main);
        border-radius: 4px;
        cursor: default;
        transition: all 0.1s;
    }

    .sidebar_btn:hover:not(.selected) {
        background: #ddd;
    }

    .selected {
        background: var(--color-main);
        color: #fff;
    }
`;

export default class SimulationSideBar extends Component {
    onClickItem = (idx) => {
        this.props.onChangeIndex(idx);
    };

    formatSibarLabel = (sidebarItem, idx) => {
        if (sidebarItem.sidebar_config.isPrime == true) {
            return <div style={{ fontWeight: 800, fontSize: "1.3rem", letterSpacing: "1px", padding: "5px 0" }}>{sidebarItem.label.toUpperCase()}</div>;
        } else {
            const isStatarb =
                this.props.simId in
                {
                    "Statistical Arbitrage": null,
                    "Statistical Arbitrage Equity (beta)": null,
                    "Statistical Arbitrage (ML)": null,
                };

            const heading = () => {
                return (
                    <div className="flexBox flexBox_between">
                        <div># {idx - 1}</div>
                    </div>
                );
            };

            if (isStatarb) {
                let label = sidebarItem.label;
                label = label.split("_");
                const label__lookback = label[1];
                const isSpread = label[0].indexOf("-") > -1;
                const label__type = isSpread ? "spread" : "ratio";
                const tickerPairs = label[0].split(isSpread ? "-" : "%");
                const t1 = tickerPairs[0];
                const t2 = tickerPairs[1];
                return (
                    <div style={{ padding: "5px 0 10px" }}>
                        {heading()}
                        <div>
                            <div style={{ padding: "5px 0 2px", fontSize: "1.2rem", fontFamily: "var(--font-main)" }}>
                                <b> {TickerDict[t1] || t1}</b> <br />
                                <b> {TickerDict[t2] || t2}</b>
                            </div>
                            <div style={{ fontSize: "1rem" }}>
                                <b>
                                    {" "}
                                    {t1} & {t2}{" "}
                                </b>{" "}
                                <br />
                                Type: {label__type} | Rolling Period: {label__lookback}
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        {heading()}
                        {getTickerName(sidebarItem.label) ? (
                            <div>
                                <div style={{ fontWeight: 800, fontSize: "1.2rem" }}>{getTickerName(sidebarItem.label)}</div>
                                <span style={{ fontSize: "1rem" }}> {sidebarItem.label} </span>
                            </div>
                        ) : (
                            <span style={{ fontWeight: 800, fontSize: "1.2rem" }}> {sidebarItem.label}</span>
                        )}
                    </div>
                );
            }
        }
    };

    render() {
        const { selectedIndex, contentIndex } = this.props;
        return (
            <Layout>
                <div
                    className="flexBox flexBox_between"
                    style={{
                        padding: "10px",
                        color: "#999",
                        fontSize: "1.2rem",
                    }}
                >
                    {this.props.backBtn ? (
                        <div className="LinkBtn" onClick={() => this.props.backBtn()}>
                            <i className="fas fa-chevron-left"></i> Back
                        </div>
                    ) : (
                        ""
                    )}
                    <div>Backtest Viewer V2</div>
                </div>
                {contentIndex.map((element, index) => {
                    return (
                        <div className={"sidebar_btn" + (selectedIndex === index ? " selected" : "")} key={index} onClick={() => this.onClickItem(index)}>
                            {this.formatSibarLabel(element, index)}
                        </div>
                    );
                })}
            </Layout>
        );
    }
}
