import React, { Component } from "react";
import { useState, useEffect } from 'react';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

function Book() {
        
    const { height, width } = useWindowDimensions();
    const h = Math.round(height * .9);
    const w = Math.round(width - 230);
    return (
        <iframe src="https://www.contango.net/x1/book/" height={h} width={w}></iframe>
    );
}

function TradingBook() {

    useEffect(() => {
        document.title = "Trading Book";
    }, []);

    return (
        <div className="TradingBook">
            <Book></Book>
        </div>
    )
}

export default TradingBook;