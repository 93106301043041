import React, { useState, useEffect, useRef } from "react";
import HighStockWrapper from '../../components/chart/HighStockWrapper';
import { LoadingPage } from "../../helpers/SimpleComponents";
import ImbueSelect from "../../components/input/ImbueSelect";


// use React Hooks to handle browser window resizing
// https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const wsURL = (document.location.protocol === 'https:')
    ? "wss://app.cbot.comatl.com:49534/"
    : "ws://app.cbot.comatl.com:49533/";
let WS;
let ws_message_chart_max = 0;

// dimensions for the charts' configs
const dimY = 9;
const dimX = 4;
const dimPercent = 45;

const rollMap = {
    '6A': ['Austrarian Dollar', 'AD1 A:00_0_R Curncy'],
    '6B': ['British Pound', 'BP1 A:00_0_R Curncy'],
    '6C': ['Canada Dollar', 'CD1 A:00_0_R Curncy'],
    '6E': ['Euro', 'EC1 A:00_0_R Curncy'],
    '6J': ['Japan Yen', 'JY1 A:00_0_R Curncy'],
    '6L': ['Brazil Real', 'BR1 A:00_0_R Curncy'],
    '6M': ['Mexican Peso', 'PE1 A:00_0_R Curncy'],
    'BTC': ['Bitcoin', 'BTC1 A:00_0_R Curncy'],
    'CNH': ['China Yuan', 'CHY1 A:00_0_R Curncy'],
    'DX': ['Dollar Index', 'DX1 A:00_0_R Curncy'],
    'ETH': ['Etherium', 'DCR1 A:00_0_R Curncy'],
    'CL': ['Crude Oil', 'CL1 R:02_0_R Comdty'],
    'NG': ['Natural Gas', 'NG1 R:02_0_R Comdty'],
    'HO': ['Heating Oil', 'HO1 R:02_0_R Comdty'],
    'RB': ['Rbob', 'XB1 R:02_0_R Comdty'],
    'ZS': ['Soybean', 'S 1 A:00_0_R Comdty'],
    'ZL': ['Soy Oil', 'BO1 A:00_0_R Comdty'],
    'ZW': ['Chicago Wheat', 'W 1 A:00_0_R Comdty'],
    'KE': ['Kansas Wheat', 'KW1 A:00_0_R Comdty'],
    'MWE': ['Minnesota Wheat', 'MW1 A:00_0_R Comdty'],
    'ZM': ['SoyMeal', 'SM1 A:00_0_R Comdty'],
    'ZC': ['Corn', 'C 1 A:00_0_R Comdty'],
    'ZF': ['5yr Notes', 'FV1 A:00_0_R Comdty'],
    'ZB': ['T-Bonds', 'US1 A:00_0_R Comdty'],
    'ZN': ['10yr Notes', 'TY1 A:00_0_R Comdty'],
    'UB': ['Ultra Bonds', 'WN1 A:00_0_R Comdty'],
    'HE': ['Lean Hog', 'LH1 A:00_0_R Comdty'],
    'LE': ['Live Cattle', 'LC1 A:00_0_R Comdty'],
    'GF': ['Feeder Cattle', 'FC1 A:00_0_R Comdty'],
    'GC': ['Gold', 'GC1 A:00_0_R Comdty'],
    'HG': ['Copper', 'HG1 A:00_0_R Comdty'],
    'PL': ['Platinum', 'PL1 A:00_0_R Comdty'],
    'PA': ['Paladium', 'PA1 A:00_0_R Comdty'],
    'SI': ['Silver', 'SI1 A:00_0_R Comdty'],
    'ES': ['E mini', 'ES1 A:00_0_R Index'],
    'NQ': ['Nasdaq Index', 'NQ1 A:00_0_R Index']
};


const commodityOptions = Object.keys(rollMap).map((ele) => ({
    label: rollMap[ele][0],
    value: ele,
}));

const intervalOptions = [
    {label: "1 minute", value: 1},
    {label: "5 minute", value: 5},
    {label: "10 minute", value: 10},
    {label: "15 minute", value: 15},
    {label: "30 minute", value: 30},
    {label: "60 minute", value: 60},
    {label: "daily", value: 0},
];

const defaultCommodity = commodityOptions[15]; // ZS
// const defaultInterval = intervalOptions[4]; // 30
const defaultInterval = intervalOptions[0]; // 1

let rootTitle;
let intervalTitle;

let firstRun = true;
let showAllCharts = true;
export default function Hydrodynamics() {

    const { height } = useWindowDimensions();

    const [state, setState] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [root, setRoot] = useState(defaultCommodity);
    const [interval, setInterval] = useState(defaultInterval);
    const [timestamp, setTimestamp] = useState(); // keep track of most recent datetime point

    const chartRef0 = useRef({});
    const chartRef1 = useRef({});
    const chartRef2 = useRef({});
    const chartRef3 = useRef({});
    const chartRef4 = useRef({});
    const chartRef5 = useRef({});
    const chartRef6 = useRef({});

    const chartRefs = [chartRef0, chartRef1, chartRef2, chartRef3, chartRef4, chartRef5, chartRef6];

    document.title = root.value + " Hydrodynamics";


    // handle resetting vars when components unmounts
    useEffect(() => {
        if (firstRun) {
            // getData();
            rootTitle = rollMap[root.value][0];
            intervalTitle = interval.value === 0 ? "daily" : interval.value+"-minute";
            firstRun = false;
            initWS(wsURL);
        }
        return () => {
            firstRun = true;
            setState({});
            onClose();
        };
    }, []);

    
    function initWS() {
        if (!WS || WS.readyState !== WebSocket.OPEN) {
            ws_message_chart_max = 0;
            
            WS = new WebSocket(wsURL);
            WS.onmessage = (evt) => { onMessage(evt) };
            WS.onclose = () => { onClose() }
            WS.onopen = () => { 
                console.log("sending WS message...")
                WS.send(`{"type": "start", "source": "hydrodynamics", "args": {"root":"${root.value}","interval":${interval.value}}}`) 
                setIsLoading(true);
            }        
        }
    }

    // reset WS with new root or interval parameters
    function onWsParamChange() {
        console.log("reconfiguring WS...")
        rootTitle = rollMap[root.value][0];
        intervalTitle = interval.value === 0 ? "daily" : interval.value+"-minute";
        setIsLoading(true);
        // setState({});
        WS.send(`{"type": "start", "source": "hydrodynamics", "args": {"root":"${root.value}","interval":${interval.value}}}`) 
        console.log("WS reset with new parameters")
    }


    // handle incoming WS messages
    //      first msg contains history
    //      following messages contain updates for each new interval, sometimes repeated
    function onMessage(evt) {
        const curMsgs = JSON.parse(evt.data);
        // console.log(curMsgs)
        if (curMsgs.response === 0) { // initial array of data
            // format data to fit into existing state structure for charts
            
            let res = {
                '0': {
                    'x': [],
                    'y1': [],
                    'y2': [],
                    'y3': [],
                    'y4': [],
                    'y5': [],
                    'contract': []
                },
                '1': {
                    'x': [],
                    'y1': [],
                    'y2': [],
                    'y3': [],
                    'y4': [],
                    'y5': [],
                    'contract': []
                },
                '2': {
                    'x': [],
                    'y1': [],
                    'y2': [],
                    'y3': [],
                    'y4': [],
                    'y5': [],
                    'contract': []
                },
                '3': {
                    'x': [],
                    'y1': [],
                    'y2': [],
                    'y3': [],
                    'y4': [],
                    'y5': [],
                    'contract': []
                },
                '4': {
                    'x': [],
                    'y1': [],
                    'y2': [],
                    'y3': [],
                    'y4': [],
                    'y5': [],
                    'contract': []
                },
                '5': {
                    'x': [],
                    'y1': [],
                    'y2': [],
                    'y3': [],
                    'y4': [],
                    'y5': [],
                    'contract': []
                },
                '6': {
                    'x': [],
                    'y1': [],
                    'y2': [],
                    'y3': [],
                    'y4': [],
                    'y5': [],
                    'contract': []
                },
            };
            curMsgs.data.forEach((msg) => {
                const chart_i = msg[0];

                res[chart_i]['x'].push(msg[1]);  // datetime
                res[chart_i]['y1'].push(msg[2]);
                res[chart_i]['y2'].push(msg[3]);
                res[chart_i]['y3'].push(msg[4]); 
                res[chart_i]['y4'].push(msg[5]);
                res[chart_i]['y5'].push(msg[6]);
                res[chart_i]['contract'].push(msg[7]); // ex: 'May 23'
            });
            setTimestamp(curMsgs.data[curMsgs.data.length - 1][1]) // get most recent datetime stamp
            // console.log(res);
            setState({data: res});
            setIsLoading(false);

        } else if (curMsgs.response > 0 && curMsgs.data) { // single update
            // console.log(curMsgs.data)

            //  loop thru the data for each chart in the curMsg
            for (let chart_i = 0; chart_i < chartRefs.length; chart_i++) {
                const cur = curMsgs.data[chart_i];
                const curTime = Date.parse(cur[1]);
                const curPrice = cur[2];
                const curFeature = cur[4]; // velocity/viscocity/volatility/REynolds
                const curLower = cur[5];
                const curUpper = cur[6];
                const curContract = cur[7];

                const curChartRef = chartRefs[chart_i];

                if (curChartRef.current && curChartRef.current.chart) {
                    let priceSeries = curChartRef.current.chart.series[0];
                    let featureSeries = curChartRef.current.chart.series[1];
                    let lowerSeries = curChartRef.current.chart.series[2]; // different for chart6
                    let upperSeries = curChartRef.current.chart.series[3]; // different for chart6

                    let lastTime = priceSeries.xData[priceSeries.xData.length - 1];

                    // console.log(lastTime);
                    // console.log(curTime);

                    if (curTime > lastTime) { // add new point to series
                        // console.log("new point");

                        if (chart_i !== 6) {
                            priceSeries.addPoint([curTime, curPrice, curContract], false);
                            featureSeries.addPoint([curTime, curFeature], false);
                            lowerSeries.addPoint([curTime, curLower], false);
                            upperSeries.addPoint([curTime, curUpper], false);
                        } else { // chart6 has unique data format
                            priceSeries.addPoint([curTime, cur[2], curContract], false);
                            featureSeries.addPoint([curTime, cur[3]], false);
                            lowerSeries.addPoint([curTime, cur[4]], false);
                            upperSeries.addPoint([curTime, cur[5]], false);
                        }

                        setTimestamp(cur[1]) // update latest timestamp (non-Epoch format)
                        curChartRef.current.chart.redraw();
                    } else if (curTime === lastTime) { // same old msg, do nothing
                        // console.log("same point");
                    }
                }
            }   
        }
    }


    function onClose() {
        WS.close();
        console.log("WS closed.")
    }


    // update all the other charts' xAxises whenever a chart updates its navigator
    function setAllExtremes(evt) {
        if (evt.trigger === "navigator") {
            let numCharts = showAllCharts ? 7 : 5; // don't try to edit all 7 charts if only 5 daily charts shown 
            for (let i = 0; i < numCharts; i++) {
                chartRefs[i].current.chart.xAxis[0].setExtremes(evt.min, evt.max);
            }
        }
    }


    // Price vs Normalized Velocity (estimated)
    function configChart0() {
        const curState = state.data['0'];
        let seriesData0 = [];
        let seriesData1 = [];
        let seriesData2 = [];
        let seriesData3 = [];
        for (let i = 0; i < curState['x'].length; i++) {
            seriesData0.push([Date.parse(curState['x'][i]), curState['y1'][i], curState['contract'][i]]);
            seriesData1.push([Date.parse(curState['x'][i]), curState['y3'][i]]);
            seriesData2.push([Date.parse(curState['x'][i]), curState['y4'][i]]);
            seriesData3.push([Date.parse(curState['x'][i]), curState['y5'][i]]);
        }
        let res = {
            chart: {
                height: (dimY / dimX * dimPercent) + '%',
                animation: false,
                events: {
                    load: function () {
                        chartRef0.current = this;
                    } 
                }
            },
            title: {
                text: `Price vs Normalized Velocity (estimated)`
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: { 
                series: {
                    animation: false,
                    connectNulls: true
                },
            },
            xAxis: {
                type: "datetime",
                events: {
                    setExtremes: (e) => { setAllExtremes(e); }
                }
            },
            yAxis: [
                { 
                    title: { text: "Price" },
                    opposite: false
                },
                { 
                    title: { text: 'Velocity' },
                }
            ],
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "price",
                    data: seriesData0,
                    yAxis: 0,
                    color: 'blue',
                    keys: ['x', 'y', 'contract'],
                    tooltip: {
                        pointFormatter: function() {
                            return '<div>' +
                            '<p> [' + this.contract + ']</p>' +
                            '<p> price: ' + this.y + '</p>' +
                            '</div>'
                        }
                    }
                },
                {
                    name: "velocity",
                    data: seriesData1,
                    yAxis: 1,
                    color: 'green'
                },
                {
                    name: "lower",
                    data: seriesData2,
                    yAxis: 1,
                    color: 'orange'
                },
                {
                    name: "upper",
                    data: seriesData3,
                    yAxis: 1,
                    color: 'red'
                }
            ],
        };
        return res;
    }


    // Price vs Volatility (estimated)
    function configChart1() {
        const curState = state.data['1'];
        let seriesData0 = [];
        let seriesData1 = [];
        let seriesData2 = [];
        let seriesData3 = [];
        for (let i = 0; i < curState['x'].length; i++) {
            seriesData0.push([Date.parse(curState['x'][i]), curState['y1'][i], curState['contract'][i]]);
            seriesData1.push([Date.parse(curState['x'][i]), curState['y3'][i]]);
            seriesData2.push([Date.parse(curState['x'][i]), curState['y4'][i]]);
            seriesData3.push([Date.parse(curState['x'][i]), curState['y5'][i]]);
        }
        let res = {
            chart: {
                height: (dimY / dimX * dimPercent) + '%',
                animation: false,
                events: {
                    load: function () {
                        chartRef1.current = this;
                    } 
                }
            },
            title: {
                text: `Price vs Volatility (estimated)`
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: { 
                series: {
                    animation: false,
                    connectNulls: true
                },
            },
            xAxis: {
                type: "datetime",
                events: {
                    setExtremes: (e) => { setAllExtremes(e); }
                }
            },
            yAxis: [
                { 
                    title: { text: "Price" },
                    opposite: false
                },
                { 
                    title: { text: 'Volatility' },
                }
            ],
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "price",
                    data: seriesData0,
                    yAxis: 0,
                    color: 'blue',
                    keys: ['x', 'y', 'contract'],
                    tooltip: {
                        pointFormatter: function() {
                            return '<div>' +
                            '<p> [' + this.contract + ']</p>' +
                            '<p> price: ' + this.y + '</p>' +
                            '</div>'
                        }
                    }
                },
                {
                    name: "volatility",
                    data: seriesData1,
                    yAxis: 1,
                    color: 'green'
                },
                {
                    name: "lower",
                    data: seriesData2,
                    yAxis: 1,
                    color: 'orange'
                },
                {
                    name: "upper",
                    data: seriesData3,
                    yAxis: 1,
                    color: 'red'
                }
            ],
        };
        return res;
    }


    // Price vs Viscocity=internal frictional force (estimated)
    function configChart2() {
        const curState = state.data['2'];
        let seriesData0 = [];
        let seriesData1 = [];
        let seriesData2 = [];
        let seriesData3 = [];
        for (let i = 0; i < curState['x'].length; i++) {
            seriesData0.push([Date.parse(curState['x'][i]), curState['y1'][i], curState['contract'][i]]);
            seriesData1.push([Date.parse(curState['x'][i]), curState['y3'][i]]);
            seriesData2.push([Date.parse(curState['x'][i]), curState['y4'][i]]);
            seriesData3.push([Date.parse(curState['x'][i]), curState['y5'][i]]);
        }
        let res = {
            chart: {
                height: (dimY / dimX * dimPercent) + '%',
                animation: false,
                events: {
                    load: function () {
                        chartRef2.current = this;
                    } 
                }
            },
            title: {
                text: `Price vs Viscocity = Internal Frictional Force (estimated)`
            },
            legend: {
                enabled: true,
            },
            navigator: { 
                enabled: true
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: { 
                series: {
                    animation: false,
                    connectNulls: true
                },
            },
            xAxis: {
                type: "datetime",
                events: {
                    setExtremes: (e) => { setAllExtremes(e); }
                }
            },
            yAxis: [
                { 
                    title: { text: "Price" },
                    opposite: false
                },
                { 
                    title: { text: 'Viscocity' },
                }
            ],
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "price",
                    data: seriesData0,
                    yAxis: 0,
                    color: 'blue',
                    keys: ['x', 'y', 'contract'],
                    tooltip: {
                        pointFormatter: function() {
                            return '<div>' +
                            '<p> [' + this.contract + ']</p>' +
                            '<p> price: ' + this.y + '</p>' +
                            '</div>'
                        }
                    }
                },
                {
                    name: "viscocity",
                    data: seriesData1,
                    yAxis: 1,
                    color: 'green'
                },
                {
                    name: "lower",
                    data: seriesData2,
                    yAxis: 1,
                    color: 'orange'
                },
                {
                    name: "upper",
                    data: seriesData3,
                    yAxis: 1,
                    color: 'red'
                }
            ],
        };
        return res;
    }


    // Price vs Kinetic Viscocity = Viscocity / Density (estimated)
    function configChart3() {
        const curState = state.data['3'];
        let seriesData0 = [];
        let seriesData1 = [];
        let seriesData2 = [];
        let seriesData3 = [];
        for (let i = 0; i < curState['x'].length; i++) {
            seriesData0.push([Date.parse(curState['x'][i]), curState['y1'][i], curState['contract'][i]]);
            seriesData1.push([Date.parse(curState['x'][i]), curState['y3'][i]]);
            seriesData2.push([Date.parse(curState['x'][i]), curState['y4'][i]]);
            seriesData3.push([Date.parse(curState['x'][i]), curState['y5'][i]]);
        }
        let res = {
            chart: {
                height: (dimY / dimX * dimPercent) + '%',
                animation: false,
                events: {
                    load: function () {
                        chartRef3.current = this;
                    } 
                }
            },
            title: {
                text: `Price vs Kinetic Viscocity = Viscocity / Density (estimated)`
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: { 
                series: {
                    animation: false,
                    connectNulls: true
                },
            },
            xAxis: {
                type: "datetime",
                events: {
                    setExtremes: (e) => { setAllExtremes(e); }
                }
            },
            yAxis: [
                { 
                    title: { text: "Price" },
                    opposite: false
                },
                { 
                    title: { text: 'Viscocity' },
                }
            ],
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "price",
                    data: seriesData0,
                    yAxis: 0,
                    color: 'blue',
                    keys: ['x', 'y', 'contract'],
                    tooltip: {
                        pointFormatter: function() {
                            return '<div>' +
                            '<p> [' + this.contract + ']</p>' +
                            '<p> price: ' + this.y + '</p>' +
                            '</div>'
                        }
                    }
                },
                {
                    name: "kinetic viscocity",
                    data: seriesData1,
                    yAxis: 1,
                    color: 'green'
                },
                {
                    name: "lower",
                    data: seriesData2,
                    yAxis: 1,
                    color: 'orange',
                    visible: false
                },
                {
                    name: "upper",
                    data: seriesData3,
                    yAxis: 1,
                    color: 'red',
                    visible: false
                }
            ],
        };
        return res;
    }


    // Price vs Reynold's Number = inertial / viscous = velocity / Kinamatic Viscocity (estimated)
    function configChart4() {
        const curState = state.data['4'];
        console.log(curState)
        let seriesData0 = [];
        let seriesData1 = [];
        let seriesData2 = [];
        let seriesData3 = [];
        for (let i = 0; i < curState['x'].length; i++) {
            seriesData0.push([Date.parse(curState['x'][i]), curState['y1'][i], curState['contract'][i]]);
            seriesData1.push([Date.parse(curState['x'][i]), curState['y3'][i]]);
            seriesData2.push([Date.parse(curState['x'][i]), curState['y4'][i]]);
            seriesData3.push([Date.parse(curState['x'][i]), curState['y5'][i]]);
        }
        let res = {
            chart: {
                height: (dimY / dimX * dimPercent) + '%',
                animation: false,
                events: {
                    load: function () {
                        chartRef4.current = this;
                    } 
                }
            },
            title: {
                text: `Price vs Reynold's Number = Inertial / Viscous = Velocity / Kinetic Viscocity (estimated)`
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: { 
                series: {
                    animation: false,
                    connectNulls: true
                },
            },
            xAxis: {
                type: "datetime",
                events: {
                    setExtremes: (e) => { setAllExtremes(e); }
                }
            },
            yAxis: [
                { 
                    title: { text: "Price" },
                    opposite: false
                },
                { 
                    title: { text: "Reynold's Number"},
                }
            ],
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "price",
                    data: seriesData0,
                    yAxis: 0,
                    color: 'blue',
                    keys: ['x', 'y', 'contract'],
                    tooltip: {
                        pointFormatter: function() {
                            return '<div>' +
                            '<p> [' + this.contract + ']</p>' +
                            '<p> price: ' + this.y + '</p>' +
                            '</div>'
                        }
                    }
                },
                {
                    name: "Reynold's number",
                    data: seriesData1,
                    yAxis: 1,
                    color: 'green'
                },
                {
                    name: "lower",
                    data: seriesData2,
                    yAxis: 1,
                    color: 'orange'
                },
                {
                    name: "upper",
                    data: seriesData3,
                    yAxis: 1,
                    color: 'red'
                }
            ],
        };
        return res;
    }


    // Cumulative Hit Take vs Volatility (estimated)
    function configChart5() {
        const curState = state.data['5'];
        let seriesData0 = [];
        let seriesData1 = [];
        let seriesData2 = [];
        let seriesData3 = [];
        for (let i = 0; i < curState['x'].length; i++) {
            seriesData0.push([Date.parse(curState['x'][i]), curState['y1'][i], curState['contract'][i]]);
            seriesData1.push([Date.parse(curState['x'][i]), curState['y3'][i]]);
            seriesData2.push([Date.parse(curState['x'][i]), curState['y4'][i]]);
            seriesData3.push([Date.parse(curState['x'][i]), curState['y5'][i]]);
        }
        let res = {
            chart: {
                height: (dimY / dimX * dimPercent) + '%',
                animation: false
            },
            title: {
                text: `Cumulative Hit Take vs Volatility (estimated)`
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: { 
                series: {
                    animation: false,
                    connectNulls: true
                },
            },
            xAxis: {
                type: "datetime",
                events: {
                    setExtremes: (e) => { setAllExtremes(e); }
                }
            },
            yAxis: [
                { 
                    title: { text: "Cumulative Hit Take" },
                    opposite: false
                },
                { 
                    title: { text: 'Volatility' },
                }
            ],
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "cumulative hit take",
                    data: seriesData0,
                    yAxis: 0,
                    color: 'blue',
                    keys: ['x', 'y', 'contract'],
                    tooltip: {
                        pointFormatter: function() {
                            return '<div>' +
                            '<p> [' + this.contract + ']</p>' +
                            '<p> price: ' + this.y + '</p>' +
                            '</div>'
                        }
                    }
                },
                {
                    name: "volatility",
                    data: seriesData1,
                    yAxis: 1,
                    color: 'green'
                },
                {
                    name: "lower",
                    data: seriesData2,
                    yAxis: 1,
                    color: 'orange'
                },
                {
                    name: "upper",
                    data: seriesData3,
                    yAxis: 1,
                    color: 'red'
                }
            ],
        };
        return res;
    }


    // Price vs Cumulative Hit Take
    function configChart6() {
        const curState = state.data['6'];
        let seriesData0 = [];
        let seriesData1 = [];
        let seriesData2 = [];
        let seriesData3 = [];
        for (let i = 0; i < curState['x'].length; i++) {
            seriesData0.push([Date.parse(curState['x'][i]), curState['y1'][i], curState['contract'][i]]); // price
            seriesData1.push([Date.parse(curState['x'][i]), curState['y2'][i]]); // bp price
            seriesData2.push([Date.parse(curState['x'][i]), curState['y3'][i]]); // cum hit
            seriesData3.push([Date.parse(curState['x'][i]), curState['y4'][i]]); // bp cum hit
        }
        let res = {
            chart: {
                height: (dimY / dimX * dimPercent) + '%',
                animation: false
            },
            title: {
                text: `Price vs Cumulative Hit Take`
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: { 
                series: {
                    animation: false,
                    connectNulls: true
                },
            },
            xAxis: {
                type: "datetime",
                events: {
                    setExtremes: (e) => { setAllExtremes(e); }
                }
            },
            yAxis: [
                { 
                    title: { text: "Price" },
                    opposite: false
                },
                { 
                    title: { text: 'Cumulative Hit Take' },
                }
            ],
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "price",
                    data: seriesData0,
                    yAxis: 0,
                    color: 'blue',
                    keys: ['x', 'y', 'contract'],
                    tooltip: {
                        pointFormatter: function() {
                            return '<div>' +
                            '<p> [' + this.contract + ']</p>' +
                            '<p> price: ' + this.y + '</p>' +
                            '</div>'
                        }
                    }
                },
                {
                    name: "bandpassed price",
                    data: seriesData1,
                    yAxis: 0,
                    color: 'brown'
                },
                {
                    name: "cumulative hit take",
                    data: seriesData2,
                    yAxis: 1,
                    color: 'green'
                },
                {
                    name: "bandpassed cumulative hit take",
                    data: seriesData3,
                    yAxis: 1,
                    color: 'orange'
                }
            ],
        };
        return res;
    }


    if (isLoading) {
        return (<div>{LoadingPage()}</div>);
    } else if (state && state.data) {
        const chartConfig0 = configChart0();
        const chartConfig1 = configChart1();
        const chartConfig2 = configChart2();
        const chartConfig3 = configChart3();
        const chartConfig4 = configChart4();
        const chartConfig5 = showAllCharts ? configChart5() : {};
        const chartConfig6 = showAllCharts ? configChart6() : {};

        const chartStyle = {width: "45%"}
        const ChartSpacing = () => {return <div style={{width: "5%"}}></div>}

        return (
            <div>
                <div className="ImbueSelectsWrapper" style={{marginLeft: "20px"}}>
                    <ImbueSelect
                        title="Commodity"
                        options={commodityOptions}
                        defaultValue={root}
                        onChange={(e) => {
                            setRoot(e)
                        }} // TODO: expand to handle socket close/re-open
                    />
                    <ImbueSelect
                        title="Interval"
                        options={intervalOptions}
                        defaultValue={interval}
                        onChange={(e) => {
                            setInterval(e)
                        }} // TODO: expand to handle socket close/re-open
                    />
                    {/* <div className="CircleBtn CircleBtnLg" style={{marginTop: "10px"}} onClick={() => getData()}> */}
                    <div className="CircleBtn CircleBtnLg" style={{marginTop: "10px"}} onClick={() => onWsParamChange()}>
                        <i className="fas fa-play"></i>
                    </div>
                </div>
                <h3 style={{textAlign: "center", marginTop: "-50px"}}>{rootTitle}: {intervalTitle} ({timestamp})</h3>
                <div style={{height: (height - 120), marginTop: "30px", overflowY: "scroll"}}>
                    <div className="row" style={{marginTop: "30px"}}>
                        <div style={chartStyle}>
                            <HighStockWrapper  innerRef={chartRef0} config={chartConfig0}/>
                        </div>
                        <ChartSpacing/>
                        <div style={chartStyle}>
                            <HighStockWrapper innerRef={chartRef1} config={chartConfig1}/>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: "30px"}}>
                        <div style={chartStyle}>
                            <HighStockWrapper innerRef={chartRef2} config={chartConfig2}/>
                        </div>
                        <ChartSpacing/>
                        <div style={chartStyle}> 
                            <HighStockWrapper innerRef={chartRef3} config={chartConfig3}/>
                        </div>
                    </div>
                    <div style={chartStyle}>
                        {/* Reynold's Number */}
                        <HighStockWrapper innerRef={chartRef4} config={chartConfig4}/>
                    </div>
                    {showAllCharts &&
                    <div className="row">
                        <div style={chartStyle}>
                            <HighStockWrapper innerRef={chartRef5} config={chartConfig5}/>
                        </div>
                        <ChartSpacing/>
                        <div style={chartStyle}>
                            <HighStockWrapper innerRef={chartRef6} config={chartConfig6}/>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    } else {
        return (
            <div>
                {/* <div className="CircleBtn CircleBtnLg" onClick={() => {getData()}}> */}
                <div className="CircleBtn CircleBtnLg" onClick={() => {onWsParamChange()}}>
                    <i className="fas fa-play"></i>
                </div>
            </div>
        );
    }
}
