import React from "react";
import LoadingBar from "../components/bar/LoadingBar";

// =============================================================================
// #TEMPLATE
// =============================================================================

// Wrapper function for react-spinner
// spinner types:
// -- ScaleLoader (DEFAULT)
// -- BarLoader, BeatLoader, BounceLoader, CircleLoader, DotLoader, FadeLoader,
// -- GridLoader, HashLoader, MoonLoader, PulseLoader, RotateLoader,
// -- SyncLoader
export const LoadingPage = (text, type) => {
    text = text || "Loading...";
    type = type || "ScaleLoader";
    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#aaa"
            }}
        >
            <LoadingBar type={type} text={text} />
        </div>
    );
};

export const PlaceholderPage = (text, iconid) => {
    text = text || "Nothing!";
    iconid = iconid || "question";
    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                color: "#aaa",
                fontFamily: "var(--font-main)"
            }}
        >
            <div style={{ fontSize: "6rem" }}>
                <i className={"fas fa-" + iconid}></i>
            </div>
            <div>{text}</div>
        </div>
    );
};
