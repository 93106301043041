import React, { useState, useEffect } from "react";
import { api_options } from "../../helpers/API";
import HighStockWrapper from "./HighStockWrapper";
import {LoadingPage} from "../../helpers/SimpleComponents";
import "../../../src/screens/ResearchCenter/style.css"

const MAX_CHAINS = 10;

const colorGradients = [
    "#2e3e50", // black
    "#8f44ad", // purple
    "#2b80b9", // blue
    "#11a186", // green
    "#f1c40f", // yellow
    "#e67d23", // dark yellow
    "#e84d3d", // orange
    "#c03a2b", // red
];

let firstRun = true;
let apiError = false;
export default function OptionsIVHistory(props) {

    const [state, setState] = useState({});
    const [subchart, setSubchart] = useState(0);

    // get data on first render, but not again until props change
    if (firstRun) {
        firstRun = false;
        getData(props.root, props.chart, props.interval)
    }

    // get new data whenever props.root changes
    useEffect(() => {
        if (!firstRun) {
            getData(props.root, props.chart, props.interval);
        }
    }, [props.root, props.interval])


    function convertJSON1(data) {
        let res = new Map();
        for (let i = 0; i < data.length; i++) {
            const cur = data[i];
            if (res.has(cur["chain"])) {
                res.get(cur["chain"]).push(cur)
            } else { // new chain, add to map with new list
                res.set(cur["chain"], [cur])
            }
        }
        return res;
    }

    function convertJSON2(data) { // candlesticks version
        // console.log(data)
        let res = new Map();
        for (let i = 0; i < data.length; i++) {
            res.set(data[i]["chain"], data[i]);
        }
        return res;
    }

    // TODO: error-check input
    // setup array in format for HighStocks series: [{x: timestamp, y: column ,...,} ,...,]
    function configSeries1(column) {
        const chainsAr = state.chains;
        let gradientIndex = 0;
        let series = [];
        for (let i = 0; i < chainsAr.length; i++) {
            let curChain = state.seriesMap.get(chainsAr[i]);
            if (curChain.length > 1) {
                series.push({
                    name: `${chainsAr[i]}`,
                    data: curChain.map(row => {
                        return {x: row["timestamp"], y: row[column], 
                            atm_strike: row["atm_strike"], under_close: row["under_close"], serial: row["serial"], new_crop: row["new_crop"]}
                    }),
                    color: colorGradients[gradientIndex++],
                    lineWidth: curChain[0]["serial"] === 0 ? 2.5 : 1, // make non-serial chains thicker
                    turboThreshold: 0
                });
                if (gradientIndex >= colorGradients.length) gradientIndex = 0;
            }
        }
        return series;
    }

    /*
    data: [{
        x: 1,
        open: 9,
        high: 2,
        low: 4,
        close: 6,
        name: "Point2",
        color: "#00FF00"
    }, ... {} ]
    */
    function configSeries2() { // dont need multiple series ...!
        // [curChain["timestamp"], curChain["iv_open"], curChain["iv_high"], curChain["iv_low"], curChain["iv_close"]]
        const chainsAr = state.chains;
        let dataAr = [];
        for (let i = 0; i < Math.min(chainsAr.length, MAX_CHAINS); i++) { // stop after MAX_CHAINS limit
            let cur = state.seriesMap.get(chainsAr[i]);
            dataAr.push({
                x: i,
                open: cur["iv_open"], 
                high: cur["iv_high"], 
                low: cur["iv_low"], 
                close: cur["iv_close"],
                name: cur["chain"],
                color: cur["iv_close"] < cur["iv_open"] ? "#ce5042" : "#39ace7"
            });
        }
        return dataAr;
    }

    // change from ^above^ function to have alternate data for different chart view
    // could maybe combine into one configSeries func with subchart type as a parameter
    // function altConfigSeries(column) {
    //     const chainsAr = state.chains;
    //     // let gradientIndex = 0;
    //     let series = [];
    //     for (let i = 0; i < chainsAr.length; i++) {
    //         let curChain = state.seriesMap.get(chainsAr[i]);
    //         if (curChain.length > 1) {
    //             series.push({
    //                 name: `${chainsAr[i]}`,
    //                 data: curChain.map(row => {
    //                     return {x: row["timestamp"], y: row[column], 
    //                         atm_strike: row["atm_strike"], under_close: row["under_close"], serial: row["serial"], new_crop: row["new_crop"]}
    //                 }),
    //                 // color: colorGradients[gradientIndex++],
    //                 lineWidth: curChain[0]["serial"] === 0 ? 2.5 : 1 // make non-serial chains thicker
    //             });
    //             // if (gradientIndex >= colorGradients.length) gradientIndex = 0;
    //         }
    //     }
    //     return series;
    // }


    function chartConfig2() {
        // console.log(state.chains)
        return {
            title: {
                text: `${props.title} : IV Snapshot`
            },
            legend: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            xAxis: {
                labels: {
                    formatter() {
                        let chain = state.chains[this.value];
                        return (chain.charAt(chain.length-1)+chain.charAt(chain.length-3)+chain.charAt(chain.length-2));
                    },
                    rotation: 0
                },
                tickInterval: 1,
                
            },
            rangeSelector: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: "ATM IV",
                    offset: 25
                },
                labels: {
                    align: "right",
                    x: 22, // offset
                    formatter: function() {
                        return (this.value).toFixed(0)+"%";
                    }
                },
            },
            plotOptions: { 
                series: { animation: false },
                
            },
            series: [{
                type: "candlestick",
                data: configSeries2(),
                turboThreshold: 0
            }],
            tooltip: {
                pointFormatter: function() {
                    let point = this;
                    let format = `
                            open: ${(point.open).toFixed(2)}<br/>
                            high: ${(point.high).toFixed(2)}<br/>
                            low: ${(point.low).toFixed(2)}<br/>
                            close: ${(point.close).toFixed(2)}<br/>`
                    return format;
                },
                split: false
            },
            credits: {
                enabled: false
            }
        }
    }

    function chartConfig1() {
        return {
            title: {
                text: `${props.title} : IV History`
            },
            legend: {
                enabled: true
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: true
            },
            rangeSelector: {
                buttons: [
                    {
                        type: "week",
                        count: 1,
                        text: "1w",
                    }, {
                        type: "week",
                        count: 2,
                        text: "2w"
                    }, {
                        type: "week",
                        count: 4,
                        text: "4w"
                    }, {
                        type: "all",
                        text: "All"
                    }
                ],
                inputDateFormat: "%Y-%m-%d",
                setInputEnabled: true, // known highcharts bug with text overlap
            },
            xAxis: {
                type: "datetime",
                labels: {
                    format: '{value:%m/%e}'
                }
            },
            yAxis: {
                title: {
                    text: "ATM IV",
                    offset: 25
                },
                labels: {
                    align: "right",
                    x: 22,
                    formatter: function() {
                        return (this.value).toFixed(0)+"%";
                    }
                },
                //tickInterval: 1
            },
            series: configSeries1("atm_iv"),
            tooltip: {
                xDateFormat: "%a, %b %e, '%y", // Thu, Dec 16, 21
                pointFormatter: function() {
                    let point = this;
                    let format = `
                            <span style="color: ${point.series.color}">${point.series.name}</span><br/>
                            ATM IV: <b>${(point.y).toFixed(2)}</b><br/>
                            strike: ${point.atm_strike}<br/>
                            close: ${(point.under_close).toFixed(2)}<br/>`;
                    if (point.serial)
                        format += '<b>serial</b><br/>';
                    if (point.new_crop)
                        format += '<b>new crop</b><br/>';
                    return format;
                },
                split: false,
                shared: false
            },
            credits: {
                enabled: false
            }
        }
    }

    // function altChartConfig() {
    //     return {
    //         title: {
    //             text: `${props.title} : IV History (ALT)`
    //         },
    //         legend: {
    //             enabled: true
    //         },
    //         navigator: {
    //             enabled: false
    //         },
    //         scrollbar: {
    //             enabled: false
    //         },
    //         rangeSelector: {
    //             buttons: [
    //                 {
    //                     type: "week",
    //                     count: 1,
    //                     text: "1w",
    //                 }, {
    //                     type: "week",
    //                     count: 2,
    //                     text: "2w"
    //                 }, {
    //                     type: "week",
    //                     count: 4,
    //                     text: "4w"
    //                 }, {
    //                     type: "all",
    //                     text: "All"
    //                 }
    //             ]
    //         },
    //         yAxis: {
    //             title: {
    //                 text: "ATM IV",
    //                 offset: 25
    //             },
    //             labels: {
    //                 align: "right",
    //                 x: 22,
    //                 formatter: function() {
    //                     return (this.value).toFixed(0)+"%";
    //                 }
    //             },
    //             //tickInterval: 1
    //         },
    //         plotOptions: { series: { animation: false } },
    //         series: altConfigSeries("atm_iv"),
    //         tooltip: {
    //             xDateFormat: '%a, %b %e, %y', // Thu, Dec 16, 21
    //             pointFormatter: function() {
    //                 let point = this;
    //                 let format = `
    //                         <span style="color: ${point.series.color}">${point.series.name}</span><br/>
    //                         ATM IV: <b>${(point.y).toFixed(2)}</b><br/>
    //                         strike: ${point.atm_strike}<br/>
    //                         close: ${(point.under_close).toFixed(2)}<br/>`;
    //                 if (point.serial)
    //                     format += '<b>serial</b><br/>';
    //                 if (point.new_crop)
    //                     format += '<b>new crop</b><br/>';
    //                 return format;
    //             },
    //             split: false,
    //             shared: false
    //         }
    //     }
    // }


    function getData(root, chart, interval) {
        // console.log(`getting data for ${root}`)
        api_options({"root" : root, "chart" : chart, "interval" : interval})
            .then((res) => {
                apiError = false;
                if (chart === 1) {
                    let seriesMap = convertJSON1(res.data.data);
                    setState({data: res.data.data, chains: Array.from(seriesMap.keys()), seriesMap: seriesMap, curRoot: root});
                } else if (chart === 2) {
                    let seriesMap = convertJSON2(res.data.data);
                    setState({data: res.data.data, chains: Array.from(seriesMap.keys()), seriesMap: seriesMap, curRoot: root});
                }
                // console.log(root)
                // console.log(res.data.data)
            }).catch((e) => {
                apiError = true;
                console.log(e);
            });
    }

    // const onChangeSubView = (e) => {
    //     setSubchart(e);
    //     getData(props.root, props.chart, props.interval);
    // };

    // const viewmodeOptions = [
    //     { label: "General", value: 0 },
    //     { label: "Alt Chart", value: 1 },
    // ];


    if (typeof props.root === "undefined" || apiError) {
        return <h3 style={{color: "red"}}>{`Error: ( ${props.title} : ${props.root} ) - unrecognized.`}</h3>
    } else if (typeof state.seriesMap !== "undefined" && state.curRoot === props.root) {
        const ChartEngine = () => {
            let config = props.chart === 1 ? chartConfig1() : chartConfig2();
            return (<HighStockWrapper key={props.root+subchart+props.chart} config={config}/>);
        };
        return (
            <div>
                {/* <ImbueTabs options={viewmodeOptions} selectedValue={subchart} onClick={onChangeSubView} /> */}
                {ChartEngine()}
            </div>);
    } else {
        return (<div>{LoadingPage()}</div>);
    }
}