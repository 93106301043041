import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
highcharts3d(Highcharts);

export default class HighChartWrapper extends Component {
    render() {
        if (this.props.chartObject && this.props.preload) {
            let chart = this.props.chartObject;
            let isChartValid = (Object.keys(chart) || []).length > 0;
            if (this.props.preload.is3d) {
                (function (H) {
                    function dragStart(eStart) {
                        eStart = chart.pointer.normalize(eStart);
                        var posX = eStart.chartX,
                            posY = eStart.chartY,
                            alpha = chart.options.chart.options3d.alpha,
                            beta = chart.options.chart.options3d.beta,
                            sensitivity = 6, // lower is more sensitive
                            handlers = [];
                        function drag(e) {
                            // Get e.chartX and e.chartY
                            e = chart.pointer.normalize(e);
                            chart.update(
                                {
                                    chart: {
                                        options3d: {
                                            alpha: alpha + (e.chartY - posY) / sensitivity,
                                            beta: beta + (posX - e.chartX) / sensitivity,
                                        },
                                    },
                                },
                                undefined,
                                undefined,
                                false
                            );
                        }
                        function unbindAll() {
                            handlers.forEach(function (unbind) {
                                if (unbind) {
                                    unbind();
                                }
                            });
                            handlers.length = 0;
                        }
                        handlers.push(H.addEvent(document, "mousemove", drag));
                        handlers.push(H.addEvent(document, "touchmove", drag));
                        handlers.push(H.addEvent(document, "mouseup", unbindAll));
                        handlers.push(H.addEvent(document, "touchend", unbindAll));
                    }

                    if (isChartValid) {
                        H.addEvent(chart.container, "mousedown", dragStart);
                        H.addEvent(chart.container, "touchstart", dragStart);
                    }
                })(Highcharts);
            }
        }
        let config = this.props.config;
        if (this.props.height && "chart" in config) {
            config.chart.height = this.props.height;
        }
        return <HighchartsReact highcharts={Highcharts} options={config} />;
    }
}
