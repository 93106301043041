import React, { Component } from "react";
import styled from "styled-components";
import { getTickerName } from "../../../index/TickerDictonary";
import ImbueSelect from "../../../components/input/ImbueSelect";
import { api_position_risk } from "../../../helpers/API";
import { hasItem } from "../../../helpers/Utils";
import { parseNumber, formatNumber, fullAssetsOptions } from "./Common";
import HighChartWrapper from "../../../components/chart/HighChartWrapper";
import { COLORMAP, highchartBasicConfig } from "../../../config";

const Layout = styled.div``;

export default class PortfolioVolatility extends Component {
    state = {
        // assets: [{ label: "ALL", value: "*all" }],
        assets: this.props.func.get_retained_options("pv", "assets") || fullAssetsOptions.slice(0, 5),
        tn: "20,000,000",
        weights: {},
        data: [],
        isLoading: 0,
        defaultContract: 1
    };

    onUpdate = () => {
        let selectedAssets = this.state.assets.map((ele) => ele.value);

        if (hasItem("*all", selectedAssets)) {
            selectedAssets = fullAssetsOptions.map((ele) => ele.value);
        }

        let weights = {};
        for (let item of selectedAssets) {
            weights[item] = this.state.weights[item]
                ? parseFloat(this.state.weights[item])
                : this.state.defaultContract;
        }

        const paras = {
            query: "portfolio_volatility",
            weights: JSON.stringify(weights),
            tn: parseNumber(this.state.tn),
        };

        this.setState({ isLoading: 1 }, () => {
            api_position_risk(paras).then((res) => {
                console.log(res.data);
                this.setState({
                    data: Object.keys(res.data.result).map((k) => [
                        k,
                        res.data.result[k],
                    ]),
                    isLoading: 0,
                });
            });
        });
    };

    render() {
        const assetsOptions = [
            { label: "ALL", value: "*all" },
            ...fullAssetsOptions,
        ];

        const renderMultiInputs = () => {
            const selectedAssets = hasItem(
                "*all",
                this.state.assets.map((ele) => ele.value)
            )
                ? fullAssetsOptions
                : this.state.assets;
            return (
                <section>
                    <div
                        className="gridBox gridBox_8"
                        style={{ gridGap: "10px" }}
                    >
                        {selectedAssets.map((ele) => {
                            return (
                                <div className="ImbueCardRounded">
                                    <div className="title">
                                        {getTickerName(ele.label)}
                                    </div>
                                    <div className="content">
                                        <input
                                            value={
                                                this.state.weights[ele.value] ||
                                                "1"
                                            }
                                            onChange={(e) => {
                                                let currentWeights = this.state
                                                    .weights;
                                                currentWeights[ele.value] =
                                                    e.target.value;
                                                this.setState({
                                                    weights: currentWeights,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </section>
            );
        };

        const renderChart = () => {
            if (this.state.isLoading) {
                return <div className="LoadingText">loading...</div>;
            } else if (this.state.data.length > 0) {
                return (
                    <div className="flexBox">
                        {this.state.data.map((ele) => {
                            let content = formatNumber(`${ele[1]}`)
                            if (ele[0] === "Weights") {
                                content = Object.keys(ele[1]).map(k => <span> <small>{k}: </small>{ele[1][k]} </span>)
                            } else if ((ele[0] === "Annualized Volatility") || (ele[0] === "Portfolio Volatility") || (ele[0] === "Directional Risk Percent")) {
                                content = ele[1]
                            }
                            return (
                                <div className="DigitCard">
                                    <div className="title">
                                        {ele[0]}
                                    </div>
                                    <div className="digit">
                                        {content}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            }
        };

        const renderWeightChart = () => {
            if (this.state.data.map(ele => ele[0]).includes("Weights")) {
                const weights = this.state.data.filter(ele => ele[0] === "Weights")[0][1]
                const contracts = this.state.weights
                const chartConfig = {
                    ...highchartBasicConfig,
                    tooltip: {
                        shared: 1,
                    },
                    xAxis: {
                        categories: Object.keys(
                            weights
                        ),
                        crosshair: true,
                    },
                    yAxis: [
                        {
                            title: {
                                text: "Weight",
                            },
                            height: "50%",
                        },
                        {
                            title: {
                                text: "Contract",
                            },
                            height: "50%",
                            top: "50%",
                            opposite: 1,
                        },
                    ],
                    series: [
                        {
                            color: COLORMAP.blue,
                            type: "column",
                            name: "Contract",
                            data: Object.keys(weights).map(k => Number.parseInt(contracts[k] || this.state.defaultContract) ),
                            dataLabels: {
                                enabled: 1,
                            },
                            yAxis: 1,
                        },
                        {
                            color: COLORMAP.green,
                            negativeColor: COLORMAP.red,
                            type: "column",
                            name: "Weight",
                            data: Object.values(weights),
                            dataLabels: {
                                enabled: 1,
                                format: "{point.y:.2f}",
                            },
                        }
                    ]
                }
                console.log(chartConfig);
                return <div><HighChartWrapper config={chartConfig} /></div>

            }
        }

        return (
            <Layout>
                <div class="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="Assets"
                        value={this.state.assets}
                        options={assetsOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        width="500px"
                        onChange={(e) => this.setState({ assets: e || []}, () => {
                            this.props.func.register_options("pv", "assets", e)
                        })}
                    />
                    <div className="ImbueSelectItem">
                        <div className="label">tn:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={formatNumber(this.state.tn)}
                            onChange={(e) =>
                                this.setState({ tn: e.target.value })
                            }
                        />
                    </div>
                    <div
                        className="CircleBtn CircleBtnLg"
                        onClick={this.onUpdate}
                    >
                        <i className="fas fa-play"></i>
                    </div>
                </div>
                <div>{renderMultiInputs()}</div>
                <div>{renderChart()}</div>
                <div>{renderWeightChart()}</div>
            </Layout>
        );
    }
}
