import React, { Component } from "react";
import styled from "styled-components";

const Layout = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);

    .close_text {
        font-family: var(--font-main);
        color: #fff;
        margin: 10px 0;
    }
`;

export default class FullscreenWrapper extends Component {
    render() {
        if (this.props.enabled) {
            return (
                <Layout onClick={this.props.onClick}>
                    <div>
                        <div className="component">{this.props.component}</div>
                        <div className="close_text">Click to Close</div>
                    </div>
                </Layout>
            );
        } else {
            return "";
        }
    }
}
