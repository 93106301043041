import React, { Component } from "react";
import CircularProgressbar from "react-circular-progressbar";
import { COLORMAP } from "../../config";

export default class RadiusProgress extends Component {
    render() {
        let {
            percentage,
            color,
            background,
            type // small, medium, big
        } = this.props;

        const config__width = () => {
            switch (type) {
                case "small":
                    return "50px";
                case "medium":
                    return "100px";
                case "big":
                    return "400px";
                default:
                    return "50px";
            }
        };

        const config__fontsize = () => {
            switch (type) {
                case "small":
                    return ["1.4rem", "0.7rem"];
                case "medium":
                    return ["3rem", "1.3rem"];
                case "big":
                    return ["3rem", "1.5rem"];
                default:
                    return ["1.4rem", "0.7rem"];
            }
        };

        return (
            <div
                style={{
                    position: "relative",
                    width: config__width()
                }}
            >
                <CircularProgressbar
                    strokeWidth={8}
                    backgroundPadding={0}
                    percentage={percentage}
                    background={1}
                    styles={{
                        background: {
                            fill: background || "rgba(29, 60, 52, .03)"
                        },
                        path: {
                            stroke: color || (percentage < 40 ? COLORMAP.green : percentage >= 80 ? COLORMAP.red : COLORMAP.orange)
                        }
                    }}
                />
                <div
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "96%",
                        left: "0",
                        top: "0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span style={{ fontSize: config__fontsize()[0], color: "var(--color-main)" }}>{percentage}</span>
                    <span style={{ fontSize: config__fontsize()[1], color: "#555", marginBottom: "10px", marginLeft: "1px", fontWeight: "800" }}>%</span>
                </div>
            </div>
        );
    }
}
