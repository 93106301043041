import React, { Component } from "react";
import styled from "styled-components";
import SM_EventsTable from "./components/SM_EventsTable";

const Layout = styled.div`
    padding: 30px;
`;

class Events extends Component {
    render() {
        return (
            <Layout>
                <SM_EventsTable />
            </Layout>
        );
    }
}

export default Events;
