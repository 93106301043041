/**
 * Claude Chen | claude.chen@imbuecapital.com
 * 2020-02-18
 *
 */

import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { COLORMAP } from "../../config";
import { api_live_trades__overview, api_live_trades__latest } from "../../helpers/API";
import { LoadingPage } from "../../helpers/SimpleComponents";
import moment from "moment";
import { toUnique } from "../../helpers/Utils";
import PositionCard from "./components/PositionCard";
import PositionAnalytics from "./components/PositionAnalytics";
import StrategyInsight from "./components/StrategyInsight";
import ReactTable from "../../components/table/ImbueRcTable";
import { getTickerName } from "../../index/TickerDictonary";

const INTERVAL = 10000;

const Layout = styled.div`
    padding: 30px;
    font-family: var(--font-main);

    .heading_title {
        font-family: var(--font-main);
        font-size: 2rem;
        color: var(--color-main);
        margin-bottom: 10px;
    }

    .trade_list_wrapper .trade_list_table_wrapper {
        font-family: roboto;
        font-size: 1.2rem;
        display: grid;
        overflow: auto;
    }

    .trade_list_wrapper .PositionAnalyticsWrapper {
        border: 1px solid #ddd;
        border-left: 0px;
        padding: 0 10px;
        box-sizing: border-box;
        height: 100%;
        overflow: auto;
    }

    .overview_list_wrapper .overview_list_menu_wrapper {
    }

    .overview_list_wrapper .overview_list_menu_wrapper .overview_list_item_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding: 3px 0;
    }
`;

// ======================================================================================================================================================
// ======================================================================================================================================================
// ======================================================================================================================================================
// Sub-component #1: overview page
// ======================================================================================================================================================
// ======================================================================================================================================================
// ======================================================================================================================================================

class OverviewScreen extends Component {
    state = {
        strategy_list: [],
        position_list: [],
        pflist: [],
        selectedPf: null,
        isLoading: false,
        position_view_type: 0,
        isLive: 0,
        isDateSpecified: 0,
        selectedDate: "",
    };

    componentDidMount() {
        this.onUpdate();
    }

    onSelectPf = (newPf) => {
        if (this.state.selectedPf !== newPf) {
            this.setState({
                selectedPf: newPf,
            });
        }
    };

    onUpdate = () => {
        this.setState({ isLoading: true }, () => {
            let date = null;
            if (this.state.isDateSpecified) {
                date = this.state.selectedDate;
            }
            api_live_trades__overview(this.props.status, date).then((res) => {
                let data = res.data;
                // Overwrite portfolio names
                data.strategies = data.strategies.map((ele) => ({ ...ele, full_label: ele.strategy_name }));
                // Getting list of unique portoflios
                const pflist = ["ALL", ...toUnique(data.strategies.map((ele) => ele.portfolio)).sort()];
                // Update states
                this.setState({
                    strategy_list: data.strategies,
                    position_list: data.positions,
                    pflist,
                    selectedPf: pflist[0],
                    isLoading: false,
                });
            });
        });
    };

    onSwitchLiveMode = () => {
        if (this.state.isLive) {
            this.setState({ isLive: 0 }, () => {
                if (this.interval) {
                    clearInterval(this.interval);
                }
            });
        } else {
            this.setState({ isLive: 1 }, () => {
                this.onUpdate();
                this.interval = setInterval(this.onUpdate, INTERVAL);
            });
        }
    };

    render() {
        let strategy_list_to_display = this.state.strategy_list;
        let positions_to_display = this.state.position_list;
        if (this.state.selectedPf !== "ALL") {
            strategy_list_to_display = this.state.strategy_list.filter(
                (ele) => ele.portfolio === this.state.selectedPf
            );
            positions_to_display = this.state.position_list.filter((ele) => ele.portfolio === this.state.selectedPf);
        }
        const positions_render = () => {
            if (positions_to_display.length > 0) {
                if (this.state.position_view_type === 0) {
                    positions_to_display = positions_to_display.map((ele) => ({
                        ...ele,
                        dataforts: { strategy_name: ele.strategy_name, security: ele.ticker },
                        strategy_w_date:
                            ele.strategy_name + "." + ele.ticker + "." + moment(ele.datetime).format("YYYY-MM-DD"),
                        self: ele,
                    }));
                    const columns = [
                        { Header: "portfolio", accessor: "portfolio" },
                        { Header: "strategy", accessor: "strategy_name" },
                        { Header: "security", accessor: "ticker" },
                        { Header: "Name", accessor: "ticker", Cell: (props) => getTickerName(props.value) },
                        { Header: "price", accessor: "price" },
                        { Header: "date", accessor: "datetime" },
                        {
                            Header: "long_short",
                            accessor: "long_short",
                            Cell: (row) => (
                                <span className={"Tag " + (row.value > 0 ? "Tag_green" : "Tag_red")}>
                                    {row.value > 0 ? "LONG" : "SHORT"}
                                </span>
                            ),
                        },
                        { Header: "size", accessor: "size" },
                        {
                            Header: "",
                            accessor: "self",
                            Cell: (row) => (
                                <div onClick={() => this.props.onSelectPosition(row.value)} className="SmallBtn">
                                    More
                                </div>
                            ),
                        },
                    ];
                    return <ReactTable list={positions_to_display} columns={columns} />;
                } else {
                    return (
                        <div className="ImbuePositionCardWrapper" style={{ margin: "10px 0" }}>
                            {positions_to_display.map((ele, idx) => {
                                return (
                                    <div key={idx}>
                                        <PositionCard
                                            key={idx}
                                            paras={ele}
                                            onSelectPosition={this.props.onSelectPosition}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    );
                }
            } else {
                return <div className="WarningText">NO DATA</div>;
            }
        };

        return (
            <div className="overview_list_wrapper">
                <h1 className="headtitle">{this.props.title || "Live Trade Manager V3"}</h1>
                <div className="BtnWrapper">
                    <div className="SmallBtn" onClick={this.onUpdate}>
                        <span>
                            <i className="fas fa-sync"></i> UPDATE
                        </span>
                    </div>
                    <div
                        className="SmallBtn"
                        onClick={() => {
                            if (!this.state.isDateSpecified) {
                                this.setState({ isDateSpecified: 1 });
                            }
                        }}
                    >
                        {this.state.isDateSpecified ? (
                            <div className="flexBox flexBox_between">
                                <div>
                                    <input
                                        placeholder="YYYY-MM-DD"
                                        style={{ fontFamily: "var(--font-main)", fontSize: "0.8rem" }}
                                        value={this.state.selectedDate}
                                        type="text"
                                        onChange={(e) => this.setState({ selectedDate: e.target.value })}
                                    />
                                </div>
                                <div>
                                    <i
                                        className="fas fa-times"
                                        onClick={() => this.setState({ isDateSpecified: 0 })}
                                    ></i>
                                </div>
                            </div>
                        ) : (
                            <span>
                                <i className="fas fa-calendar-week"></i> DATE
                            </span>
                        )}
                    </div>
                </div>
                <div className="overview_list_menu_wrapper">
                    {this.state.isLoading ? (
                        LoadingPage("Retrieving...")
                    ) : (
                        <Fragment>
                            {/* ####### SECTION :: Portfolio Selection #######  */}
                            <h3>Portfolios</h3>
                            <div className="ImbueTabWrapper">
                                {this.state.pflist.map((ele, idx) => {
                                    const classname =
                                        ele === this.state.selectedPf ? "ImbueTab ImbueTabSelected" : "ImbueTab";
                                    return (
                                        <div
                                            key={idx}
                                            className={classname}
                                            onClick={() => {
                                                this.onSelectPf(ele);
                                            }}
                                        >
                                            {ele}
                                        </div>
                                    );
                                })}
                            </div>

                            {/* ####### SECTION: Positions Display ####### */}
                            <h3>
                                <div className="flexBox">
                                    <span>Positions</span>
                                    <div className="BtnWrapper" style={{ marginLeft: "10px" }}>
                                        <div
                                            className="SmallBtn"
                                            onClick={() => {
                                                this.setState({
                                                    position_view_type: this.state.position_view_type === 0 ? 1 : 0,
                                                });
                                            }}
                                        >
                                            <span>
                                                <i
                                                    className={
                                                        this.state.position_view_type === 0
                                                            ? "fas fa-table"
                                                            : "fas fa-th"
                                                    }
                                                ></i>{" "}
                                                View
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </h3>
                            {positions_render()}

                            {/* ####### SECTION: Strategies List ####### */}
                            <h3>Strategies</h3>
                            <div>
                                {strategy_list_to_display.map((item, idx) => {
                                    const onSelectStrategyLabel = item.strategy_name;
                                    return (
                                        <div key={idx} className="overview_list_item_wrapper">
                                            {/* Tab Section */}
                                            <div style={{ fontSize: "1rem", color: "#888" }}>
                                                <div>
                                                    <span style={{ fontSize: "1.1rem", color: "#333" }}>
                                                        {item.strategy_name}
                                                        {/* (V:{item.strategy_version}) */}
                                                    </span>
                                                    <span style={{ marginLeft: "10px" }}>
                                                        Date: {item.strategy_date}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="BtnWrapper">
                                                <div className="SmallBtn">
                                                    <span>
                                                        <i
                                                            className="fas fa-circle"
                                                            style={{
                                                                color:
                                                                    item.status === "live"
                                                                        ? COLORMAP.green_o
                                                                        : COLORMAP.grey,
                                                            }}
                                                        ></i>{" "}
                                                        {item.status}
                                                    </span>
                                                </div>
                                                <div
                                                    className="SmallBtnAlt"
                                                    onClick={() => this.props.onSelectStrategy(onSelectStrategyLabel)}
                                                >
                                                    View More
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}

// ======================================================================================================================================================
// ======================================================================================================================================================
// ======================================================================================================================================================
// Sub-component #2: strategy page
// ======================================================================================================================================================
// ======================================================================================================================================================
// ======================================================================================================================================================

class StrategyScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trade_list: [],
            isLoading: false,
            display_columns: [
                {
                    Header: "",
                    accessor: "signalid",
                    Cell: (props) => (
                        <a
                            className="LinkBtn"
                            onClick={() => {
                                this.onOpenSignalScreen(props.value);
                            }}
                        >
                            View More
                        </a>
                    ),
                },
                { Header: "Enter Date", accessor: "datetime" },
                { Header: "Security", accessor: "ticker" },
                { Header: "Contract Name", accessor: "contract_name" },
                { Header: "Contract Size", accessor: "contract_size" },
                {
                    Header: "Long/Short",
                    accessor: "long_short",
                    Cell: (props) => <span>{props.value < 0 ? "SHORT" : "LONG"}</span>,
                },
                { Header: "size", accessor: "size" },
                { Header: "Price", accessor: "price" },
                { Header: "Exit Date", accessor: "exit_date" },
                { Header: "stop", accessor: "stop" },
                { Header: "stop_pct", accessor: "stop_pct" },
                { Header: "target", accessor: "target" },
                { Header: "target_pct", accessor: "target_pct" },
            ],
            selectedSignalId: "",
            isSignalSelected: false,
        };
        this.onOpenSignalScreen = this.onOpenSignalScreen.bind(this);
        this.onCloseSignalScreen = this.onCloseSignalScreen.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            api_live_trades__latest(this.props.strategy_name).then((res) => {
                let trade_list = res.data;
                // Adding extra columns
                trade_list = trade_list.map((ele) => ({
                    ...ele,
                    signalid: this.props.strategy_name + "." + moment(ele.datetime).format("YYYY-MM-DD"),
                }));
                this.setState({ trade_list: trade_list, isLoading: false });
            });
        });
    }

    onOpenSignalScreen(signalId) {
        this.setState(
            {
                isSignalSelected: false,
            },
            () => {
                this.setState({
                    isSignalSelected: true,
                    selectedSignalId: signalId,
                });
            }
        );
    }

    onCloseSignalScreen() {
        this.setState({
            isSignalSelected: false,
            selectedSignalId: "",
        });
    }

    render() {
        const strategy_name = this.props.strategy_name;
        const hasData = this.state.trade_list.length > 0;
        return (
            <div className="trade_list_wrapper">
                <div className="flexBox" style={{ marginBottom: "10px" }}>
                    <div className="SmallBtnAlt" onClick={() => this.props.onSwitchPageIndex(0)}>
                        <i className="fal fa-angle-left"></i>Back
                    </div>
                </div>

                <div className="heading_title">{strategy_name}</div>

                <section>
                    <StrategyInsight strategy_name={strategy_name} />
                </section>

                <div
                    className="trade_list_table_wrapper"
                    style={{ gridTemplateColumns: this.state.isSignalSelected ? "40% 60%" : "100%" }}
                >
                    {this.state.isLoading ? (
                        LoadingPage()
                    ) : (
                        <Fragment>
                            <div>
                                <ReactTable
                                    filterable={false}
                                    list={this.state.trade_list}
                                    columns={this.state.display_columns}
                                />
                            </div>
                            <div>
                                {this.state.isSignalSelected ? (
                                    <div className="PositionAnalyticsWrapper">
                                        <PositionAnalytics
                                            position_id={this.state.selectedSignalId}
                                            onClose={this.onCloseSignalScreen}
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}

// ======================================================================================================================================================
// ======================================================================================================================================================
// ======================================================================================================================================================
// Sub-component #3: positions page
// ======================================================================================================================================================
// ======================================================================================================================================================
// ======================================================================================================================================================

class PositionScreen extends Component {
    state = {
        data_quality_pdf_name: null,
    }

    componentDidMount() {

    }

    render() {
        const paras = this.props.paras;
        const formattedDate = moment(paras.datetime).format("YYYY-MM-DD");
        // const formattedDate = "2020-08-26";
        const strategy_id = paras.strategy_name + "." + formattedDate;
        const position_id = paras.strategy_name + "." + paras.ticker + "." + formattedDate;
        const info_list = Object.keys(paras).map((key) => ({ label: key, value: paras[key] }));

        return (
            <div>
                <div className="flexBox" style={{ marginBottom: "10px" }}>
                    <div className="SmallBtnAlt" onClick={() => this.props.onSwitchPageIndex(0)}>
                        <i className="fal fa-angle-left"></i>Back
                    </div>
                </div>

                <div className="heading_title">Position</div>

                <div className="grid_layout grid_layout_4">
                    {info_list.map((ele, idx) => (
                        <div className="label-value" key={idx}>
                            <div className="label">{ele.label}</div>
                            <div className="value">{ele.value}</div>
                        </div>
                    ))}
                </div>

                <div>
                    <PositionAnalytics position_id={position_id} strategy_id={strategy_id} />
                </div>

            </div>
        );
    }
}

// ======================================================================================================================================================
// ======================================================================================================================================================
// ======================================================================================================================================================
// Main Component
// ======================================================================================================================================================
// ======================================================================================================================================================
// ======================================================================================================================================================

export default class TradeManagerV3 extends Component {
    constructor(props) {
        super(props);

        const stateCases = {
            normal: {
                pageindex: 0, // 0: overview page, 1: trades page, 2: position page, 3. strategy insight page
                selectedStrategyId: "",
                selectedPositionParas: "",
            },
            test1: {
                pageindex: 1,
                selectedStrategyId: "SM_China_OI",
                selectedPositionParas: "",
            },
            test2: {
                pageindex: 2,
                selectedPositionParas: {
                    strategy_name: "SM_China_Oi",
                    datetime: "2020-03-27",
                    long_short: -1,
                    strategy_id: "e986f855d62242b2991cb33cff38790e",
                },
            },
        };
        this.state = stateCases.normal;
        this.onSwitchPageIndex = this.onSwitchPageIndex.bind(this);
        this.onSelectStrategy = this.onSelectStrategy.bind(this);
        this.onSelectPosition = this.onSelectPosition.bind(this);
    }

    componentDidMount() {}

    onSwitchPageIndex(newIndex) {
        if (this.state.pageindex !== newIndex) {
            this.setState({ pageindex: newIndex });
        }
    }

    onSelectStrategy(label) {
        this.setState(
            {
                selectedStrategyId: label,
            },
            () => {
                this.onSwitchPageIndex(1);
            }
        );
    }

    onSelectPosition(positionParas) {
        this.setState(
            {
                selectedPositionParas: positionParas,
            },
            () => {
                this.onSwitchPageIndex(2);
            }
        );
    }

    render() {
        const mainRenderer = () => {
            switch (this.state.pageindex) {
                case 0:
                    return (
                        <OverviewScreen
                            onSelectStrategy={this.onSelectStrategy}
                            onSelectPosition={this.onSelectPosition}
                            {...this.props}
                        />
                    );
                case 1:
                    return (
                        <StrategyScreen
                            onSwitchPageIndex={this.onSwitchPageIndex}
                            strategy_name={this.state.selectedStrategyId}
                        />
                    );
                case 2:
                    return (
                        <PositionScreen
                            onSwitchPageIndex={this.onSwitchPageIndex}
                            paras={this.state.selectedPositionParas}
                        />
                    );
                default:
                    return "";
            }
        };

        return (
            <Layout>
                <div className="main">{mainRenderer()}</div>
            </Layout>
        );
    }
}
