import React, { useState, useEffect } from 'react';
import { LoadingPage } from "../../helpers/SimpleComponents";
import HighStockWrapper from '../../components/chart/HighStockWrapper';
import "./style.css";

var wsUrl = ( window.location.protocol === 'https:'
    ? "wss://static.cbot.comatl.com:49534/"
    : "ws://static.cbot.comatl.com:49533/"
);

/*
    - Chart displaying daily price changes compared to the expected risk using standard deviation @ 90% confidence
    - Also contains daily price and volume data, both defaulted as hidden series
    - Most recent datapoint contains live data for today
*/
const ExpectedRisk = (props) => {

    const [data, setData] = useState(null);
    const [symbol, setSymbol] = useState(props.symbol);


    // fetch data on symbol change, either from user input or props change (below)
    useEffect(() => {
        const socket = new WebSocket(wsUrl);

        setData(null);

        socket.onopen = () => {
            if (symbol) {
                socket.send(JSON.stringify({
                    "type": "start", 
                    "source": "symbol_risk", 
                    "authorization": "Bearer ".concat(localStorage.getItem("_token")), 
                    "args": {"symbol": symbol}
                }));
            }
        };

        socket.onmessage = (event) => {
            const json = JSON.parse(event.data);
            try {
                console.log(json);
                if (json['type'] && json['type'] === 'data') {
                    setData(json['data']);
                }
            } catch (err) {
                console.error(err);
            }
        };

        return () => {
            socket.close();
        };

    }, [symbol]);


    // props change
    useEffect(() => {
        setSymbol(props.symbol);
    }, [props.symbol]);


    const getChartConfig = () => {
        const res = {
            chart: {
                animation: false,
            },
            title: {
                text: `Expected Risk : ${symbol}`,
            },
            legend: {
                enabled: true,
            },
            scrollbar: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            rangeSelector: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    animation: false,
                    pointPadding: 0,
                    groupPadding: 0.05,
                    connectNulls: true,
                },
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%m/%d}'
                },
            },
            yAxis: [
                {
                    title: 'change',
                    offset: 30,
                },
                {
                    title: 'price',
                    opposite: false,
                },
                {
                    title: 'volume',
                    opposite: false,
                    top: '75%',
                    height: '25%',
                    labels: {
                        enabled: false,
                    },
                    gridLineWidth: 0,
                }
            ],
            series: [
                {
                    name: 'Px Change',
                    type: 'column',
                    yAxis: 0,
                    data: data.map((ele) => [Date.parse("US/Chicago "+ele['session']), ele['change']]),
                    color: '#FFA726', // orange
                    zIndex: 1,
                },
                {
                    name: 'Px Risk',
                    type: 'arearange',
                    yAxis: 0,
                    data: data.map((ele) => [Date.parse("US/Chicago "+ele['session']), -ele['risk'], ele['risk']]),
                    tooltip: {
                        pointFormatter: function () { 
                            return `<span style="color:${this.series.color}">\u25CF</span> 
                                ${this.series.name}: ± <b>${(this.high).toFixed(2)}</b><br>`;
                        }
                    },
                    color: '#9bd4e4',
                    opacity: 0.75,
                    zIndex: 0,
                },
                {
                    name: 'Price',
                    type: 'line',
                    yAxis: 1,
                    data: data.map((ele) => [Date.parse("US/Chicago "+ele['session']), ele['px']]),
                    color: 'black',
                    lineWidth: 0.8,
                    visible: false,
                    zIndex: 1,
                },
                {
                    name: 'Volume',
                    type: 'column',
                    yAxis: 2,
                    data: data.map((ele) => [Date.parse("US/Chicago "+ele['session']), ele['volume']]),
                    color: '#0066CC', // dark blue
                    visible: false,
                    zIndex: 1,
                },
            ],
            tooltip: {
                split: false,
                shared: true,
            },
        };
        return res;
    };

    

    // Text input for allowing user to specify a particular symbol
    const SymbolSelector = (props) => {

        const [inputValue, setInputValue] = useState(props.symbol);

        const handleChange = (evt) => {
            setInputValue((evt.target.value).toUpperCase());
        }
    
        const handleKeyDown = (evt) => {
            // TODO: validate input, create/display warning on bad input
            if (evt.key === 'Enter') {
                setSymbol(inputValue);
            }
        }

        return (
            <div> 
                <div className="row" style={{marginTop: "4px", marginLeft: "20px"}}>
                    <p style={{marginTop: "0px"}}> {"symbol: "} </p>
                    <div style={{border: "2px solid #447463", borderRadius: "5px", marginLeft: "4px", width: "50px", height: "22px"}}>
                        <input type="text" value={inputValue} onChange={handleChange} onKeyDown={handleKeyDown} maxLength={32}/>
                    </div>
                </div>
                <p style={{fontSize: "10px", marginLeft: "35px", marginTop: "-14px"}}>{"(press ENTER)"}</p>
            </div>
            
        );
    }


    return (
        <div>
            <SymbolSelector symbol={symbol}/>
            { data === null && LoadingPage() }
            { data !== null &&
                <div style={{}}>
                    <HighStockWrapper config={getChartConfig()} />
                </div>
            }
        </div>
    );

};

export default ExpectedRisk;
