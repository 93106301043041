import React from 'react';
import { LoadingPage } from "../../helpers/SimpleComponents";
import HighStockWrapper from "../../components/chart/HighStockWrapper";


const IntradayPriceHistory = (props) => {

    const data = props.data;
    const comdtyName = props.comdtyName;
    const reportType = props.reportType;
    const reportLabel = props.reportLabel;
    const reportDatetime = props.reportDatetime;


    function configCandlestickChart() {
        let res = {
            chart: {
                height: (9 / 16 * 100) + '%' // 16:9 ratio 100%
            },
            title: {
                text: `${reportType} | ${reportLabel} : (${comdtyName})`
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                enabled: true
            },
            scrollbar: {
                enabled: true
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: { 
                series: {
                    animation: false,
                    turboThreshold: 3000,
                    dataGrouping: {
                        // enabled: false,
                        units: [
                            ['minute', [1,5,10,15,30,60]] // TODO: create external button to manually set
                        ]
                    },
                    // connectNulls: true
                },
            },
            xAxis: {
                type: "datetime",
                plotLines: [
                    {
                        color: "lightgray",
                        dashStyle: "ShortDash",
                        width: 1,
                        // value: Date.parse("US/Chicago "+"2023-03-31 11:00:00")
                        value: Date.parse("US/Chicago " + reportDatetime),
                    }
                ]
            },
            yAxis: [
                { 
                    title: { text: "Price" },
                    offset: 20,
                    // opposite: false,
                    height: '70%',
                    // plotLines: [{
                    //     color: "black",
                    //     width: 2,
                    //     value: 0
                    // }]
                },
                {
                    title: { text: "volume"},
                    offset: 20,
                    top: '70%',
                    height: '30%'
                },
            ],
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "price",
                    type: "candlestick",
                    yAxis: 0,
                    data: data.map((ele) => ({
                        x: Date.parse("US/Chicago "+ele['date']),
                        open: ele['px_open'] !== null ? ele['px_open'] : ele['px_last'],
                        high: ele['px_high'],
                        low: ele['px_low'],
                        close: ele['px_last'],
                        color: ele['px_last'] < ele['px_open'] ? "#ce5042" : "#39ace7", // red (-) : blue (+)
                    })),
                },
                {
                    name: "Volume",
                    type: "column",
                    color: "rgba(144,237,125,255)",
                    yAxis: 1,
                    data: data.map((ele) => ([Date.parse("US/Chicago "+ele['date']), ele['px_volume']])),
                },
                
            ],
        };
        // console.log(res.series)
        return res;
    }

    return (
        <div>
            { (!data || data.length == 0) && <div>{LoadingPage()}</div>}
            { data && 
                <div>
                    <HighStockWrapper config={configCandlestickChart()} />
                </div>
            }
        </div>
    );
}

export default IntradayPriceHistory;