import React, { Component, Fragment } from "react";
import ReactHighstock from "react-highcharts/ReactHighstock";
import styled from "styled-components";
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import { highchartBasicConfig, COLORMAP } from "../../config";
import ReactHighcharts from "react-highcharts";
import { addLogarithmic } from "./helpers/HighchartButton";
var HighchartsMore = require("highcharts-heatmap");
HighchartsMore(ReactHighcharts.Highcharts);

const Layout = styled.div`
    background: none;
    transition: all 0.4s;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30px calc(100% - 30px);

    .chart_container {
        display: grid;
        padding: 0 10px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .grid_head {
        font-family: var(--font-main);
        text-align: left;
        font-size: 14px;
        padding-bottom: 8px;
    }
`;

const TableWrapper = styled.div`
    margin: 10px 0;
    font-family: "Roboto";
    color: #444;
    font-size: 11px;
`;

/**
 * @class MultiFunctionalChart
 */
class MultiFunctionalChart extends Component {
    state = {
        chartid: "",
        hasValidData: false,
        chartType: "line",
        candleSeries: [],
        lineSeries: [],
        barSeries: [],
        seasonalSeries: [],
        heatmapConfig: {},
        tableView: {},
    };

    componentDidMount() {
        this.updateChart(this.props);
    }

    updateChart = (props) => {
        if (props.data === undefined) {
            return null;
        } else {
            const data = props.data;
            const candleSeries = data.candle || [];
            const lineSeries = data.line || [];
            const barSeries = data.bar || [];
            const seasonalSeries = data.seasonal || [];
            const heatmapConfig = data.heatmapConfig || {};
            const tableView = data.table || {};
            // Update Data
            this.setState({
                // chartType: this.props.chartType || "line",
                candleSeries,
                lineSeries,
                barSeries,
                seasonalSeries,
                tableView,
                heatmapConfig,
            });
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.hasValidData && nextProps.data !== undefined) {
            return { hasValidData: true };
        } else if (prevState.chartid !== nextProps.chartid) {
            return { chartid: nextProps.chartid, newProps: nextProps };
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.hasValidData && this.state.hasValidData) {
            this.updateChart(this.props);
        } else if (prevState.chartid !== this.state.chartid) {
            this.updateChart(this.state.newProps);
        }
    }

    // Chart Management
    onChangeChartType = (chartType) => {
        this.setState({
            ...this.state,
            chartType: chartType,
        });
    };

    renderChart = (states, props) => {
        // Highchart Configuration for linechart view and barchart view
        const chartType = states.chartType;
        const tableView = states.tableView;
        const lineSeries = states.lineSeries;
        const barSeries = states.barSeries;
        const seasonalSeries = states.seasonalSeries;
        const heatmapConfig = states.heatmapConfig;
        const candleSeries = states.candleSeries;

        let defaultZoom = props.defaultZoom;
        let height = props.height;

        const commonConfig = {
            ...highchartBasicConfig,
            chart: {
                backgroundColor: "transparent",
                ...(height ? { height } : {}),
                style: { fontFamily: "arial" },
                animation: false,
            },
            rangeSelector: {
                buttons: [
                    { type: "month", count: 1, text: "1M" },
                    { type: "month", count: 3, text: "3M" },
                    { type: "ytd", text: "YTD" },
                    { type: "year", count: 3, text: "3Y" },
                    { type: "all", text: "ALL" },
                ],
                buttonTheme: {
                    padding: 0,
                    height: 18,
                    width: 34,
                    fill: "#00000000",
                    r: 8,
                    style: { fontSize: "1.1rem", fontFamily: "var(--font-main)", color: "#666" },
                    states: {
                        hover: {
                            fill: "rgb(240, 240, 240)",
                            style: { color: "black" },
                        },
                        select: {
                            fill: "rgb(46, 96, 83)",
                            style: { color: "white" },
                        },
                    },
                },
                labelStyle: { display: "none" },
                selected: defaultZoom || 4,
                inputEnabled: false,
            },
            yAxis: [
                {
                    labels: { x: 10 },
                    gridLineWidth: 1,
                },
                {
                    labels: { enabled: false },
                    gridLineWidth: 0,
                    opposite: true,
                },
            ],
            tooltip: {
                ...highchartBasicConfig.tooltip,
                animation: true,
                useHTML: true,
                shadow: false,
                borderWidth: 2,
                borderRadius: 4,
                style: {
                    fontSize: "12px",
                },
            },
        };

        // --+-- 1. Line View Chart Configuration --+--
        const lineViewconfig = {
            ...commonConfig,
            chart: {
                ...commonConfig.chart,
                type: "area",
                events: {
                    load: addLogarithmic,
                },
            },
            legend: {
                enabled: lineSeries ? (lineSeries.length > 1 ? 1 : 0) : 1,
                itemStyle: { fontSize: 10 },
            },
            plotOptions: {
                series: {
                    lineWidth: 1,
                    connectNulls: true,
                    fillOpacity: 0.1,
                    softThreshold: true,
                    dataGrouping: {
                        approximation: "close",
                    },
                    animation: false,
                },
            },
            series: lineSeries || [],
        };

        // --+-- 1.1 Bar View Chart Configuration (e.g. daily changes) --+--
        const barViewconfig = {
            ...commonConfig,
            chart: {
                ...commonConfig.chart,
                type: "column",
            },
            legend: {
                enabled: barSeries ? (barSeries.length > 1 ? 1 : 0) : 1,
                itemStyle: { fontSize: 10 },
            },
            plotOptions: {
                series: {
                    color: COLORMAP.main, // Default Line Color
                    lineWidth: 2,
                    connectNulls: true,
                    fillOpacity: 0.1,
                    softThreshold: true,
                    dataGrouping: {
                        approximation: "average",
                    },
                    animation: false,
                },
            },
            series: barSeries || [],
        };

        // --+-- 2. Seasonal View Chart Configuration --+--
        const seasonalViewConfig = {
            ...commonConfig,
            chart: {
                ...commonConfig.chart,
                type: "line",
            },
            rangeSelector: {
                enabled: false,
            },
            navigator: {
                ...lineViewconfig.navigator,
                baseSeries: 1,
                xAxis: {
                    dateTimeLabelFormats: {
                        second: "%Y-%m-%d<br/>%H:%M:%S",
                        minute: "%Y-%m-%d<br/>%H:%M",
                        hour: "%Y-%m-%d<br/>%H:%M",
                        day: "%b %d",
                        week: "%b %d",
                        month: "%b 01",
                        year: "%b",
                    },
                },
            },
            plotOptions: {
                series: {
                    ...lineViewconfig.plotOptions.series,
                    connectNulls: true,
                    lineWidth: 1,
                },
            },
            xAxis: {
                dateTimeLabelFormats: {
                    second: "%Y-%m-%d<br/>%H:%M:%S",
                    minute: "%Y-%m-%d<br/>%H:%M",
                    hour: "%Y-%m-%d<br/>%H:%M",
                    day: "%b %d",
                    week: "%b %d",
                    month: "%b",
                    year: "%b",
                },
            },
            legend: {
                ...lineViewconfig.legend,
                enabled: !0,
                backgroundColor: "rgba(255,255,255, 0.3)",
                verticalAlign: "top",
                itemDistance: 4,
                symbolHeight: 1,
                symbolPadding: 0,
                padding: 1,
                margin: 4,
                itemMarginBottom: 1,
                maxHeight: 35,
            },
            series: seasonalSeries || [],
        };

        const candleViewConfig = {
            ...commonConfig,
            rangeSelector: {
                ...commonConfig.rangeSelector,
                selected: 1,
            },
            navigator: {
                ...commonConfig.navigator,
                series: [],
            },
            yAxis: [
                {
                    labels: { x: 10 },
                    gridLineWidth: 1,
                },
            ],
            plotOptions: {
                series: {
                    lineWidth: 1,
                    animation: false,
                },
            },
            series: candleSeries || [],
        };

        const monthlyHeatmapConfig = heatmapConfig;

        console.log(seasonalViewConfig);

        switch (chartType) {
            case "candle":
                return <ReactHighstock config={candleViewConfig} ref="chart-candle" />;
            case "table":
                return (
                    <TableWrapper>
                        <ReactTable
                            data={tableView.data || []}
                            columns={tableView.columns || []}
                            defaultPageSize={50}
                            style={{ height: height || "100%" }} // This will force the table body to overflow and scroll, since there is not enough room }}
                        />
                    </TableWrapper>
                );
            case "seasonal":
                return <ReactHighstock config={seasonalViewConfig} ref="chart-season" />;
            case "line":
                return <ReactHighstock config={lineViewconfig} ref="chart" />;
            case "column":
                return <ReactHighstock config={barViewconfig} ref="chart" />;
            case "monthlyHeatmap":
                return <ReactHighcharts config={monthlyHeatmapConfig} ref="chart" />;
            default:
                return <div></div>;
        }
    };

    // :: Render Buttons
    renderButtons = () => {
        // ===== state =====
        let tableView = this.state.tableView;
        const lineSeries = this.state.lineSeries;
        const barSeries = this.state.barSeries;
        const seasonalSeries = this.state.seasonalSeries;
        const heatmapConfig = this.state.heatmapConfig;
        const candleSeries = this.state.candleSeries;
        const chartType = this.state.chartType;

        // ===== valid data =====
        const showCandle = candleSeries.length > 0;
        const showLine = lineSeries.length > 0;
        const showBar = barSeries.length > 0;
        const showSeasonal = seasonalSeries.length;
        const showMonthlyHeatmap = Object.keys(heatmapConfig).length > 0;
        const showTable = tableView.data != undefined && tableView.data.length > 0;

        // helper
        const genButton = (props, idx) => {
            const isSelected = chartType === props.id;
            if (!props.isHidden) {
                return (
                    <div
                        key={idx.key}
                        className={"ChartBtn" + (isSelected ? " ChartBtnSelected" : "")}
                        onClick={() => this.onChangeChartType(props.id)}
                    >
                        <i className={"fas fa-" + props.icon}></i> {props.label}
                    </div>
                );
            }
        };

        return (
            <div className="BtnWrapper">
                {[
                    {
                        isHidden: !showCandle,
                        title: "Candlesticks Chart",
                        id: "candle",
                        label: "Candlestick",
                        icon: "chart-bar",
                    },
                    { isHidden: !showLine, title: "Line Chart", id: "line", label: "Line", icon: "chart-line" },
                    { isHidden: !showBar, title: "Bar Chart", id: "column", label: "Bar", icon: "chart-bar" },
                    {
                        isHidden: !showMonthlyHeatmap,
                        title: "Heatmap Chart",
                        id: "monthlyHeatmap",
                        label: "Monthly",
                        icon: "th-large",
                    },
                    {
                        isHidden: !showSeasonal,
                        title: "Annual Chart",
                        id: "seasonal",
                        label: "Seasonal",
                        icon: "chart-line",
                    },
                    { isHidden: !showTable, title: "Table Chart", id: "table", label: "Table", icon: "table" },
                ].map((ele, idx) => genButton(ele, idx))}
                <div className="ChartBtn" title="Download as CSV">
                    <CSVLink
                        className="csvlink"
                        data={tableView.data || []}
                        filename={(tableView.name || "data") + ".csv"}
                    >
                        <i className="fas fa-download"></i> Download
                    </CSVLink>
                </div>
            </div>
        );
    };

    render() {
        return (
            <Layout>
                {this.renderButtons(this.state, this.props)}
                {this.renderChart(this.state, this.props)}
            </Layout>
        );
    }
}

export default MultiFunctionalChart;
