import OptionsIVHistory from "../../components/chart/OptionsIVHistory";
import React, {useState} from "react";
import "./style.css";

export default function DashboardOptionsIV (props) {

    const [curInterval, setCurInterval] = useState(12);
    const [propsInterval, setPropsInterval] = useState(curInterval);
    const [warning, setWarning] = useState("");

    const handleChange = (e) => {
        setCurInterval(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            let num = parseInt(e.target.value);
            // assert valid number input
            if (Number.isInteger(num) && num >= 1 && num <= 52) {
                if (warning !== "") {
                    setWarning("");
                }
                console.log("valid number")
                setPropsInterval(num);
            } else { // bad input
                console.log("invalid number")
                setWarning("input must be integer between 1 and 52")
            }
            
        }
    }

    return (
        <div>
            <div className="row">
                weeks back:
                <div style={{border: "1px solid #447463", borderRadius: "5px", marginLeft: "2px", marginRight: "2px", width: "20px", height: "15px"}}>
                    <input type="text" value={curInterval} onChange={handleChange} onKeyDown={handleKeyDown} maxLength={2}/>
                </div>
            </div>
            <p style={{color: "red"}}>{warning}</p>
            <div className="row">
                <div style={{width:"60%"}}>
                    <OptionsIVHistory  root={props.root} title={props.title} chart={1} interval={propsInterval}/>
                </div>
                <div style={{width: "35%", marginLeft: "30px"}}>
                    <OptionsIVHistory  root={props.root} title={props.title} chart={2} interval={propsInterval}/>
                </div>
            </div>
        </div>
        
    );
}
