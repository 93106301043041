import React, { Component } from "react";

// External Modules
import { BarLoader } from "react-spinners";
import styled from "styled-components";
import ReactCountryFlag from "react-country-flag";

import MultiFunctionalChart from "../chart/MultiFunctionalChart";
import { generateSeasonal, generateTable, standardiseTableColumnName } from "../../helpers/ChartFunctions";
import TickerChangeCard from "../card/TickerChangeCard";

import { COLORMAP, URL } from "../../config";
import { api_blp, api_ndvi } from "../../helpers/API";

const Layout = styled.div`
    .chart_header {
        display: flex;
        font-family: var(--font-main);
        font-size: 1em;
        margin-bottom: 5px;
    }
    .chart_header_flag {
        margin-right: 10px;
    }
    .chart_header_title {
    }
    .chart_header_subtitle {
        font-size: 1.2rem;
        color: #444;
    }

    .chart_wrapper {
        transition: all 0.1s;
        padding: 20px;
    }
`;

// >>>>>>>>>>>>> CSS
const SpinnerWrapper = styled.div`
    height: 2px;
    margin-bottom: 10px;

    & > .loading_text {
        font-size: 12px;
        color: #888;
        margin: 5px 0;
        text-align: center;
    }
`;

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
class ReportChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            title: "Report Chart",
            errorMsg: "",
        };
        this.updateChart = this.updateChart.bind(this);
    }

    componentDidMount() {
        this.updateChart(this.props);
    }

    updateChart(props) {
        const ticker = props.ticker;
        const field = (props.field || "PX_LAST").toUpperCase();
        const ts_fields = [field];
        const full_ts_fields = ts_fields.concat(["BN_SURVEY_HIGH", "BN_SURVEY_MEDIAN", "BN_SURVEY_LOW"]);
        const from = "2000-01-01";

        let stylebook = {
            BN_SURVEY_HIGH: {
                name: "Survey High",
                color: "rgba(28, 72, 181, 0.7)",
                lineWidth: 1,
                type: "line",
                marker: {
                    // enabled: true,
                    radius: 2.5,
                    symbol: "triangle",
                    enabledThreshold: 10,
                },
                showInNavigator: false,
            },
            BN_SURVEY_MEDIAN: {
                name: "Survey Median",
                color: "rgba(192, 57, 43, 0.5)",
                lineWidth: 1,
                type: "line",
                marker: {
                    // enabled: true,
                    radius: 2.5,
                    symbol: "diamon",
                    enabledThreshold: 10,
                },
            },
            BN_SURVEY_LOW: {
                name: "Survey Low",
                color: "rgba(142, 68, 173, 0.7)",
                lineWidth: 1,
                type: "line",
                marker: {
                    // enabled: true,
                    radius: 2.5,
                    symbol: "circle",
                    enabledThreshold: 10,
                },
            },
            PX_LAST: {
                name: "Latest",
                color: "rgb(68, 117, 99)",
                marker: {
                    // enabled: true,
                    radius: 3,
                },
                showInNavigator: true,
            },
        };
        stylebook[field] = {
            name: field,
            color: COLORMAP.main,
        };

        this.setState(
            {
                isLoading: true,
            },
            () => {
                const src = props.src;
                const extra_api = {
                    NDVI: api_ndvi,
                };
                const extra_api_response_handler = {
                    NDVI: (rsp) => {
                        const data = { PX_LAST: rsp.data.content.map((ele) => [ele.date, ele.anom]) };
                        return data;
                    },
                };

                // helper function after api

                const afterData = (data, ts_fields) => {
                    let default_field = ts_fields[0];
                    default_field = standardiseTableColumnName(default_field);
                    let formattedData = generateTable(data)["data"].filter((ele) => ele[default_field] != null);

                    let lst_three_px_last = formattedData.slice(0, 3);
                    let latest_diff = lst_three_px_last[0][default_field] - lst_three_px_last[1][default_field];
                    let latest_diff_perc =
                        ((lst_three_px_last[0][default_field] - lst_three_px_last[1][default_field]) /
                            lst_three_px_last[1][default_field]) *
                        100;
                    let prior_diff = lst_three_px_last[1][default_field] - lst_three_px_last[2][default_field];
                    let prior_diff_perc =
                        ((lst_three_px_last[1][default_field] - lst_three_px_last[2][default_field]) /
                            lst_three_px_last[2][default_field]) *
                        100;
                    const change = [
                        {
                            title: "Latest Release",
                            change: {
                                value: lst_three_px_last[0][default_field],
                                valueSuffix: lst_three_px_last[0]["BN_SURVEY_MEDIAN"]
                                    ? `surv ${lst_three_px_last[0]["BN_SURVEY_MEDIAN"]}`
                                    : "",
                                diff: latest_diff.toFixed(2),
                                perc: latest_diff_perc.toFixed(2),
                                label: "Actual",
                            },
                            info: [
                                // { label: "Survey Median", value: lst_three_px_last[0]['BN_SURVEY_MEDIAN'], large: true },
                                // { label: "Release Date", value: lst_three_px_last[0]["ECO_RELEASE_DT"] },
                                // { label: "Release Time", value: lst_three_px_last[0]['ECO_RELEASE_TIME'] }
                            ],
                            style: {},
                        },
                        {
                            title: "Prior Release",
                            change: {
                                value: lst_three_px_last[1][default_field],
                                valueSuffix: lst_three_px_last[1]["BN_SURVEY_MEDIAN"]
                                    ? `surv ${lst_three_px_last[1]["BN_SURVEY_MEDIAN"]}`
                                    : "",
                                diff: prior_diff.toFixed(2),
                                perc: prior_diff_perc.toFixed(2),
                                label: "Actual",
                            },
                            info: [
                                // { label: "Survey Median", value: lst_three_px_last[1]['BN_SURVEY_MEDIAN'], large: true },
                                // { label: "Release Date", value: lst_three_px_last[1]["ECO_RELEASE_DT"] },
                                // { label: "Release Time", value: lst_three_px_last[1]['ECO_RELEASE_TIME'] }
                            ],
                            style: {
                                opacity: 0.6,
                            },
                        },
                    ];

                    // 1. Make Line Chart Series
                    let lineChartSeries = [];
                    ts_fields.forEach((field) => {
                        lineChartSeries.push({
                            data: data[field].map((ele) => [Date.parse(ele[0]), ele[1]]),
                            ...stylebook[field],
                        });
                    });

                    // 2. Make Seasonal Series
                    let seasonalSeries = generateSeasonal(data[default_field] || data[Object.keys(data)[0]]);

                    // 3. Make Table
                    let tableData = generateTable(data);

                    const multiChartConfig = {
                        line: lineChartSeries,
                        seasonal: seasonalSeries,
                        table: tableData,
                    };

                    this.setState({
                        isLoading: false,
                        data: multiChartConfig,
                        change: change,
                    });
                };

                const report_error = (errMsg) => {
                    console.log(errMsg);
                    this.setState({
                        errorMsg: "Error while loading " + ticker + " (err: " + errMsg + ")",
                        isLoading: false,
                    });
                };

                // START request
                if (extra_api[src]) {
                    extra_api[src](ticker).then((res) => {
                        const data = extra_api_response_handler[src](res);
                        afterData(data, ts_fields);
                    });
                } else {
                    // api_endpoint = api_blp(ticker, ts_fields.join(","), from); // default BLpData
                    // Attemp 1: try all fields with SURVEY AND RELEASE
                    api_blp(ticker, full_ts_fields.join(","), from)
                        .then((res) => {
                            afterData(res.data.content.data, full_ts_fields);
                        })
                        .catch((err) => {
                            // console.log("E1: ", ticker, "doesn't have full fields. try attempt#2");
                            // Attemp 2: try all fields without SURVEY AND RELEASE
                            api_blp(ticker, ts_fields.join(","), from)
                                .then((res) => {
                                    afterData(res.data.content.data, ts_fields);
                                })
                                .catch((err_) => {
                                    // console.log("E2: ", ticker, " has empty data or", err_);
                                    report_error("empty data or " + err_);
                                });
                        });
                }
            }
        );
    }

    render() {
        // ==== props ====
        const ticker = this.props.ticker;
        const title = this.props.title;
        const height = this.props.height;
        const tableSize = this.props.tableSize;

        // ==== state ====
        const isLoading = this.state.isLoading;
        const change = this.state.change;
        const data = this.state.data;
        const errorMsg = this.state.errorMsg;

        const goToDatacenter = () => {
            if (ticker) {
                const url = `${window.location.origin}/data_center/data_query?ticker=${ticker}&from=2000-01-01&title=${title}`;
                window.open(url, "_blank");
            }
        };

        return (
            <Layout>
                {/* --+-- isLoading Bar --+-- */}
                <SpinnerWrapper>
                    {isLoading ? <BarLoader width={100} widthUnit="%" height={3} color={COLORMAP.main} /> : ""}
                </SpinnerWrapper>

                {/* --+-- Chart Wrapper --+-- */}
                <div className="chart_wrapper" style={{ opacity: isLoading ? 0.6 : 1 }}>
                    {/* --+-- Header Title --+-- */}
                    <div className="chart_header_subtitle onHover" onClick={goToDatacenter}>
                        {errorMsg === "" ? ticker : <span style={{ color: COLORMAP.red }}>{errorMsg}</span>}
                    </div>
                    <div className="flexBox flexBox_between">
                        <div className="chart_header_title"> {title} </div>
                    </div>
                    <TickerChangeCard simple={true} data={change} />
                    <MultiFunctionalChart data={data} height={height} tableSize={tableSize} />
                </div>
            </Layout>
        );
    }
}

export default ReportChart;
