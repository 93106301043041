import React, { Component, useState } from 'react'
import styled from 'styled-components';
import { GLOBALSTYLED } from '../../config';
import S3PdfViewer from "../../components/pdfViewer/S3PdfViewer";
import ImbueTabs from "../../components/tabs/ImbueTabs";
import { by_overload, by_common, valid_common } from "../../universalSymbolMap";

import { useParams, useHistory, Redirect } from "react-router-dom";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;

const common_roots_available = ["W", "SM", "KW", "MW", "C", "BO", "FC", "LH", "LC", "DA", "CL", "CO", "HO", "GC", "SI", "HG", "CT", "S", "SB", "RB", "NG", "CC", "PA", "PL"];

const tab_set = valid_common().filter(x => common_roots_available.includes(x))
      .map(root => ({ 'label': root, 'value': by_common(root).bloomberg_root + "_Master_Term_Structure.pdf" }));
const tab_index = tab_set.reduce(
    (hash, entry, index) => { hash[entry.label] = index; return(hash); }, {}
);

export default function MasterTermStructureViewer() {

    const {root} = useParams();
    const history = useHistory();
    var state_index = 0;
    var base_path = '/ra/MasterTermStructureViewer';
    var base_title = 'Term Structure';

    // was a root provided?
    if (root) {
	// look up the root in the overload table
	const mapped = by_overload(root);
	
	// root found in overload?
	if (mapped) {
	    // does it point to a valid common root?
	    if (mapped.common in tab_index) {
		// get state_index for mapped.common from tab set index
		state_index = tab_index[mapped.common];

		// is the mapped common root different from the requested overload root?
		if (root != mapped.common) {

		    // root was translated to common, redirect
		    const path = `${base_path}/${mapped.common}`;

		    if (false) {
			// index.js:1437 Warning: React has detected a
			// change in the order of Hooks called by
			// MasterTermStructureViewer. This will lead
			// to bugs and errors if not fixed. For more
			// information, read the Rules of Hooks:
			// https://fb.me/rules-of-hooks

			//    Previous render            Next render
			//    ------------------------------------------------------
			// 1. useContext                 useContext
			// 2. useContext                 useContext
			// 3. undefined                  useState
			//    ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
			return (
			  <div>
			    <Redirect to={path} />
			  </div>
			)
		    } else {
			// Warning: Cannot update during an existing state transition (such as within `render`).
			// Render methods should be a pure function of props and state.
			history.replace(path);
			//history.push(path);
		    }
		}
	    }
	} else {

	    // root was not found in overload, redirect to remove invalid root
	    return (
	      <div>
	    	<Redirect to={base_path} />
	      </div>
	    )
	    
	    // // root was not found in overload, invalid, delete from path
	    // const path = base_path;
	    // history.replace(path);
	    // //history.push(path);
	}
    }

    // useState with state_index found above
    const [item, setItem] = useState(tab_set[state_index]);
    
    function optionSelected(option) {
        const path = `${base_path}/${option.label}`;
        history.replace(path);
        // history.push(path);
        setItem(option);
    }

    var symbol = by_common(item.label);
    document.title = symbol.common + ' ' + base_title;
    
    return (
        <Layout>
            <ImbueTabs options={tab_set} selectedValue={item.value} onClickOption={(opt) => optionSelected(opt)} />

            <h1>{item.label} Term Structure - {symbol.title}</h1>
            <S3PdfViewer key={item.value} bucketname="imbuereportbucket" id={item.value} />
        </Layout>
    )
}
