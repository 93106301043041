import React, {createContext, useContext, useReducer} from 'react';

export const StateContext = createContext();

export const StateProvider = ({reducer, initialState, children}) =>(

  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);




const defaultVal = {profile: {}} //Insert the default value here.
export const MainContext = React.createContext(defaultVal);


export const useStateValue = () => useContext(StateContext);