import React, { Component } from "react";
import ReactHighstock from "react-highcharts/ReactHighstock";
import { simChartCommonConfig } from "../Config";
import moment from "moment";
import StateBox from "../components/StateBox";
import { hc_yaxis_percentage_formatter, multilinePercTooltipFormatter, multilineTooltipFormatter } from "../../../helpers/ChartFunctions";
import styled from "styled-components";

const Layout = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80px calc(100% - 80px);
`;

export default class TabPerformanceBySector extends Component {
    
    state = {
        startDate: null,
        endDate: null
    }

    onUpdateStats = (newData) => {
        // console.log(newData)
        if (this.statebox) {
            this.statebox.onChangePayload(newData);
        }
    }

    getStats(data) {
        let sector_performance_infolist = data.map(ele => {
            let ret = ele.data.slice().reverse()[0];
            let text = ret ? (
                <span>
                    {" "}
                    <i className={ret[1] >= 0 ? "fas fa-caret-up" : "fas fa-caret-down"}></i> {(ret[1] * 100).toFixed(3) + " %"}
                </span>
            ) : (
                ""
            );
            let value = ret ? ret[1] : "";
            return { label: ele.name, text: text, value: value };
        });

        sector_performance_infolist = [
            sector_performance_infolist[0],
            ...sector_performance_infolist
                .slice(1, sector_performance_infolist.length)
                .sort((a, b) => b.value - a.value)
                .map((ele, idx) => ({ ...ele, label: "#" + (idx + 1) + " " + ele.label }))
        ];

        return sector_performance_infolist;
    }

    render() {
        // === props ===
        const series = this.props.series;
        const config = this.props.config;
        const rebase = config.rebase;
        const showStats = config.showStats;
        const isPerc = config.isPerc;

        // === attribute functions ===
        const onUpdateStats = this.onUpdateStats;
        const getStats = this.getStats;

        let sector_performance_infolist = [];
        if (showStats) {
            sector_performance_infolist = this.getStats(series);
        }

        const rebaseFunc = (startDate, endDate, chart) => {
            let newSeries = [];
            for (let i = 0; i < series.length; i++) {
                const data = series[i].data;
                let pivot = data.filter(ele => ele[0] >= Date.parse(startDate));
                
                let pivotValue = 0;
                let pivotDate = 0;

                if (pivot.length > 0) {
                    pivotDate = pivot[0][0];
                    pivotValue = pivot[0][1];
                }
                
                let newData = [];
                for (let j = 0; j < data.length; j++) {
                    newData.push([data[j][0], data[j][1] - pivotValue]);
                }
                newSeries.push({ data: newData });
            }

            for (let i = 0; i < chart.series.length; i++) {
                if (newSeries[i]) {
                    chart.series[i].setData(newSeries[i].data);
                }
            }

            // update stats
            onUpdateStats(
                getStats(
                    series.map(ele => ({
                        ...ele,
                        data: ele.data.filter(a => a[0] >= Date.parse(startDate) && a[0] < Date.parse(endDate))
                    }))
                )
            );
        };

        let chart_configuration = {
            ...simChartCommonConfig,
            chart: {
                ...simChartCommonConfig.chart
            },
            series: series,
            tooltip: {
                ...simChartCommonConfig.tooltip,
                useHTML: true,
                formatter: multilineTooltipFormatter,
                shared: true
            },
            xAxis: {},
            yAxis: {
                title: { text: "Value" },
                tickerAmount: 8,
                crosshair: { width: 1, color: "#aaa", dashStyle: "shortDash" },
                tickAmount: 8
            },
            navigator: {
                ...simChartCommonConfig.navigator,
                adaptToUpdatedData: false
            }
        };

        if (isPerc === true) {
            chart_configuration.yAxis.labels = { formatter: hc_yaxis_percentage_formatter() };
            chart_configuration.tooltip.formatter = multilinePercTooltipFormatter;
        }

        if (rebase === true) {
            chart_configuration.chart.events = {
                load: function(e) {
                    try {
                        const startDate = moment(new Date(e.target.xAxis[0].min).toISOString()).format("YYYY-MM-DD");
                        const endDate = moment(new Date(e.target.xAxis[0].max).toISOString()).format("YYYY-MM-DD");
                        rebaseFunc(startDate, endDate, e.target);
                    } catch (e) {
                        console.log(e);
                    }
                }
            };
            chart_configuration.xAxis.events = {
                afterSetExtremes: function(e) {
                    try {
                        const startDate = moment(new Date(e.min).toISOString()).format("YYYY-MM-DD");
                        const endDate = moment(new Date(e.max).toISOString()).format("YYYY-MM-DD");
                        rebaseFunc(startDate, endDate, e.target.chart);
                    } catch (e) {
                        console.log(e);
                    }
                }
            };
        }

        return (
            <Layout>
                {showStats ? <StateBox payload={sector_performance_infolist} ref={a => (this.statebox = a)} /> : <div></div>}
                <ReactHighstock config={chart_configuration} />
            </Layout>
        );
    }
}
