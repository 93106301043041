export const noaaDataTypeDict = {
    ACMC: 'Average cloudiness midnight to midnight from 30-second ceilometer data',
    ACMH: 'Average cloudiness midnight to midnight from manual observations',
    ACSC: 'Average cloudiness sunrise to sunset from 30-second ceilometer data',
    ACSH: 'Average cloudiness sunrise to sunset from manual observations',
    AWND: 'Average wind speed',
    CDSD: 'Cooling Degree Days Season to Date',
    CLDD: 'Cooling Degree Days',
    DAEV: 'Number of days included in the multiday evaporation total (MDEV)',
    DAPR: 'Number of days included in the multiday precipitation total (MDPR)',
    DASF: 'Number of days included in the multiday snow fall total (MDSF) ',
    DATN: 'Number of days included in the multiday minimum temperature (MDTN)',
    DATX: 'Number of days included in the multiday maximum temperature (MDTX)',
    DAWM: 'Number of days included in the multiday wind movement (MDWM)',
    FZF6: 'Last freeze <= 28F/-2.2C of the year',
    FZF7: 'Last freeze <= 24F/-4.4C of the year',
    DP01: 'Number of days with greater than or equal to 0.1 inch of precipitation',
    DP05: 'Number of days with greater than or equal to 0.5 inch of precipitation',
    DP10: 'Number of days with greater than or equal to 1.0 inch of precipitation',
    DPNP: 'Departure from normal monthly precipitation.',
    DPNT: 'Departure from normal monthly temperature.',
    DSND: 'Number days with snow depth > 1 inch(25.4mm) for the period.',
    DSNW: 'Number days with snow depth > 1 inch.',
    DT00: 'Number days with minimum temperature less than or equal to 0.0 F',
    DT32: 'Number days with minimum temperature less than or equal to 32.0 F',
    DT60: 'Number days with minimum temperature < 59 F. (Puerto Rico and Virgin Islands only.)',
    DT70: 'Number days with maximum temperature > 70 F. (Alaska only.)',
    DT90: 'Number days with maximum temperature greater than or equal 90.0 F',
    DWPR: 'Number of days with non-zero precipitation included in multiday precipitation total (MDPR)',
    DX32: 'Number days with maximum temperature < 32 F.',
    DX60: 'Number days with maximum temperature < 59 F. (Puerto Rico and Virgin Islands only.)',
    DX70: 'Number days with maximum temperature > 70 F (21.1C)',
    DX90: 'Number days with maximum temperature > 90 F (32.2C)',
    EMNT: 'Extreme minimum temperature for the period.',
    EMSD: 'Extreme maximum snow depth for the period.',
    EMSN: 'Extreme maximum snowfall for the period.',
    EMXP: 'Extreme maximum precipitation for the period.',
    EMXT: 'Extreme maximum temperature for the period.',
    EVAP: 'Evaporation of water from evaporation pan',
    FMTM: 'Time of fastest mile or fastest 1-minute wind',
    FRGB: 'Base of frozen ground layer',
    FRGT: 'Top of frozen ground layer',
    FRTH: 'Thickness of frozen ground layer',
    FZF0: 'First freeze <= to 32F/0C of the year.',
    FZF1: 'First freeze <= 28F/-2.2C of the year',
    FZF2: 'First freeze <= 24F/-4.4C of the year',
    FZF3: 'First freeze <= 20F/-6.7C of the year',
    FZF4: 'First freeze <= 16F/-8.9C of the year',
    FZF5: 'Last freeze <= 32F/0C of the year',
    FZF8: 'Last freeze <= 20F/-6.7C of the year',
    FZF9: 'Last freeze <= 16F/-8.9C of the year',
    GAHT: 'Difference between river and gauge height',
    HDSD: 'Heating Degree Days Season to Date',
    SN01: 'Minimum soil temperature with unknown cover at 5 cm depth',
    SN02: 'Minimum soil temperature with unknown cover at 10 cm depth',
    SN03: 'Minimum soil temperature with unknown cover at 20 cm depth',
    SN11: 'Minimum soil temperature with grass cover at 5 cm depth',
    SN12: 'Minimum soil temperature with grass cover at 10 cm depth',
    SN13: 'Minimum soil temperature with grass cover at 20 cm depth',
    SN14: 'Minimum soil temperature with grass cover at 50 cm depth',
    SN21: 'Minimum soil temperature with fallow cover at 5 cm depth',
    SN22: 'Minimum soil temperature with fallow cover at 10 cm depth',
    SN23: 'Minimum soil temperature with fallow cover at 20 cm depth',
    SN31: 'Minimum soil temperature with bare ground cover at 5 cm depth',
    SN32: 'Minimum soil temperature with bare ground cover at 10 cm depth',
    SN33: 'Minimum soil temperature with bare ground cover at 20 cm depth',
    SN34: 'Minimum soil temperature with bare ground cover at 50 cm depth',
    SN35: 'Minimum soil temperature with bare ground cover at 100 cm depth',
    SN36: 'Minimum soil temperature with bare ground cover at 150 cm depth',
    SN51: 'Minimum soil temperature with sod cover at 5 cm depth',
    SN52: 'Minimum soil temperature with sod cover at 10 cm depth',
    SN53: 'Minimum soil temperature with sod cover at 20 cm depth',
    SN54: 'Minimum soil temperature with sod cover at 50 cm depth',
    SN55: 'Minimum soil temperature with sod cover at 100 cm depth',
    SN56: 'Minimum soil temperature with sod cover at 150 cm depth',
    SN57: 'Minimum soil temperature with sod cover at 180 cm depth',
    SN61: 'Minimum soil temperature with straw multch cover at 5 cm depth',
    SN72: 'Minimum soil temperature with grass muck cover at 10 cm depth',
    SN81: 'Minimum soil temperature with bare muck cover at 5 cm depth',
    SN82: 'Minimum soil temperature with bare muck cover at 10 cm depth',
    SN83: 'Minimum soil temperature with bare muck cover at 20 cm depth',
    SNOW: 'Snowfall',
    SNWD: 'Snow depth',
    SX01: 'Maximum soil temperature with unknown cover at 5 cm depth',
    SX02: 'Maximum soil temperature with unknown cover at 10 cm depth',
    SX03: 'Maximum soil temperature with unknown cover at 20 cm depth',
    SX11: 'Maximum soil temperature with grass cover at 5 cm depth',
    SX12: 'Maximum soil temperature with grass cover at 10 cm depth',
    SX13: 'Maximum soil temperature with grass cover at 20 cm depth',
    SX14: 'Maximum soil temperature with grass cover at 50 cm depth',
    SX15: 'Maximum soil temperature with grass cover at 100 cm depth',
    SX17: 'Maximum soil temperature with grass cover at 180 cm depth',
    SX21: 'Maximum soil temperature with fallow cover at 5 cm depth',
    SX22: 'Maximum soil temperature with fallow cover at 10 cm depth',
    SX23: 'Maximum soil temperature with fallow cover at 20 cm depth',
    SX31: 'Maximum soil temperature with bare ground cover at 5 cm depth',
    SX32: 'Maximum soil temperature with bare ground cover at 10 cm depth',
    SX33: 'Maximum soil temperature with bare ground cover at 20 cm depth',
    SX34: 'Maximum soil temperature with bare ground cover at 50 cm depth',
    SX35: 'Maximum soil temperature with bare ground cover at 100 cm depth',
    SX36: 'Maximum soil temperature with bare ground cover at 150 cm depth',
    SX51: 'Maximum soil temperature with sod cover at 5 cm depth',
    SX52: 'Maximum soil temperature with sod cover at 10 cm depth',
    SX53: 'Maximum soil temperature with sod cover at 20 cm depth',
    SX54: 'Maximum soil temperature with sod cover at 50 cm depth',
    SX55: 'Maximum soil temperature with sod cover at 100 cm depth',
    SX56: 'Maximum soil temperature with sod cover at 150 cm depth',
    SX57: 'Maximum soil temperature with sod cover at 180 cm depth',
    SX61: 'Maximum soil temperature with straw multch cover at 5 cm depth',
    SX72: 'Maximum soil temperature with grass muck cover at 10 cm depth',
    SX81: 'Maximum soil temperature with bare muck cover at 5 cm depth',
    SX82: 'Maximum soil temperature with bare muck cover at 10 cm depth',
    SX83: 'Maximum soil temperature with bare muck cover at 20 cm depth',
    TAVG: 'Average Temperature.',
    TEVP: 'Total monthly evaporation.',
    THIC: 'Thickness of ice on water',
    TMAX: 'Maximum temperature',
    TMIN: 'Minimum temperature',
    TOBS: 'Temperature at the time of observation',
    TPCP: 'Total precipitation',
    TSNW: 'Total snow fall',
    TSUN: 'Total sunshine for the period',
    TWND: 'Total monthly wind movement over evaporation pan.',
    WDF1: 'Direction of fastest 1-minute wind',
    WDF2: 'Direction of fastest 2-minute wind',
    WDF5: 'Direction of fastest 5-second wind',
    WDFG: 'Direction of peak wind gust',
    WDFI: 'Direction of highest instantaneous wind',
    WDFM: 'Fastest mile wind direction',
    WDMV: 'Total wind movement',
    WESD: 'Water equivalent of snow on the ground',
    WESF: 'Water equivalent of snowfall',
    WSF1: 'Fastest 1-minute wind speed',
    WSF2: 'Fastest 2-minute wind speed',
    WSF5: 'Fastest 5-second wind speed',
    WSFG: 'Peak gust wind speed',
    WSFI: 'Highest instantaneous wind speed',
    WSFM: 'Fastest mile wind speed',
    WT01: 'Fog, ice fog, or freezing fog (may include heavy fog)',
    WT02: 'Heavy fog or heaving freezing fog (not always distinguished from fog)',
    WT03: 'Thunder',
    WT04: 'Ice pellets, sleet, snow pellets, or small hail" ',
    WT05: 'Hail (may include small hail)',
    WT06: 'Glaze or rime ',
    WT07: 'Dust, volcanic ash, blowing dust, blowing sand, or blowing obstruction',
    WT08: 'Smoke or haze ',
    WT09: 'Blowing or drifting snow',
    WT10: 'Tornado, waterspout, or funnel cloud" ',
    WT11: 'High or damaging winds',
    WT12: 'Blowing spray',
    WT13: 'Mist',
    WT14: 'Drizzle',
    WT15: 'Freezing drizzle ',
    WT16: 'Rain (may include freezing rain, drizzle, and freezing drizzle)" ',
    WT17: 'Freezing rain ',
    WT18: 'Snow, snow pellets, snow grains, or ice crystals',
    WT19: 'Unknown source of precipitation ',
    WT21: 'Ground fog ',
    WT22: 'Ice fog or freezing fog',
    WV01: 'Fog, ice fog, or freezing fog (may include heavy fog)',
    WV03: 'Thunder',
    WV07: 'Ash, dust, sand, or other blowing obstruction',
    WV18: 'Snow or ice crystals',
    WV20: 'Rain or snow shower',
    PRCP: 'Precipitation',
    PGTM: 'Peak gust time',
}