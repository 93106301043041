import React, { Component } from "react";
import styled from "styled-components";

const Layout = styled.div`
    font-family: var(--font-main);
    padding: 15px 10px;
    border: 1px solid #ccc;
    background: #ddd;

    .tagLayout {
        display: flex;
        flex-wrap: wrap;
    }

    .label {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #444;
        margin-bottom: 5px;
    }

    .tagWrapper {
        font-family: "roboto";
        border: 1px solid transparent;
        padding: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        &:hover {
            cursor: pointer;
            background: #eee;
        }
    }

    .tagWrapperSelected {
        border: 1px solid #ccc;
        background: #fff;
    }
`;

export default class MultiTagSelect extends Component {
    state = {
        selected: [],
    };

    componentDidMount() {
        this.setState({
            selected: this.props.value || [],
        });
    }

    onChange = (selectedOption) => {
        let currentSelected = this.state.selected;
        if (currentSelected.map((ele) => ele.value).includes(selectedOption.value)) {
            this.setState(
                {
                    selected: currentSelected.filter((ele) => ele.value !== selectedOption.value),
                },
                () => this.postChange()
            );
        } else {
            this.setState(
                {
                    selected: [...currentSelected, selectedOption],
                },
                () => this.postChange()
            );
        }
    };

    onSelectAndDeselect = () => {
        let currentSelected = this.state.selected;
        if (currentSelected.length === 0) {
            this.setState(
                {
                    selected: this.props.options,
                },
                () => {
                    this.postChange();
                }
            );
        } else {
            this.setState(
                {
                    selected: [],
                },
                () => {
                    this.postChange();
                }
            );
        }
    };

    onActiveSelectAndDeselect = () => {
        let currentSelected = this.state.selected;
        if (currentSelected.length === 0) {
            this.setState(
                {
                    selected: this.props.activeOptions,
                },
                () => {
                    this.postChange();
                }
            );
        } else {
            this.setState(
                {
                    selected: [],
                },
                () => {
                    this.postChange();
                }
            );
        }
    };


    postChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.state.selected);
        }
    };

    render() {
        const label = this.props.label; // label
        const options = this.props.options; // all options

        return (
            <Layout>
                <div className="flexBox flexBox_between">
                    <div className="label">{label}</div>
                    <div>
                        <div className="LinkBtn" onClick={this.onSelectAndDeselect}>
                            select/deselct all
                        </div>
                        { this.props.label === "Hyo_2 securities" && 
                        <div className="LinkBtn" onClick={this.onActiveSelectAndDeselect}>
                            select/deselct active
                        </div>
                        }
                    </div>
                </div>

                <div className="tagLayout">
                    {options.map((ele, idx) => {
                        const isSelected = this.state.selected.map((ele) => ele.value).includes(ele.value);
                        return (
                            <div
                                key={idx}
                                className={isSelected ? "tagWrapper tagWrapperSelected" : "tagWrapper"}
                                onClick={() => {
                                    this.onChange(ele);
                                }}
                            >
                                {ele.label}
                            </div>
                        );
                    })}
                </div>
            </Layout>
        );
    }
}
