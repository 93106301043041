import React, { useState } from 'react'
import { api_trades_heatmap } from "../../../helpers/API";
import { LoadingPage } from "../../../helpers/SimpleComponents";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {by_exchange} from "../../../universalSymbolMap";
import { getMonth, getYear} from 'date-fns';
import range from "lodash/range";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../style.css";


const SYMBOLS_LIST = [
	"ZS", "ZM", "ZL", "ZC", "ZW", "KE", "MWE", "ZO",
	"GF", "LE", "HE", "LBS",
	"CL", "NG", "RB", "HO",
	"GC", "SI", "HG", "PL", "PA",
	"6E", "6J", "CNH", "6B", "6C", "6A", "6L", "6M",
	"ZT", "ZF", "ZN", "ZB", "ZQ", "UB",
	"ES", "YM", "NQ",
	"BTC", "ETH",
    "ZR",
    ]; 

// known broken symbols: 
// const BROKEN_SYMBOLS_LIST = ["CC", "CO", "CT", "DA", "DX", "ED",
//     "FF", "FV", "KC", "NV", "RTY", "SB", "SF"]; // as of: 2022-05-27

const div10 = ["GC", "PL", "ZM"];
const div100 = ["ZL", "BTC", "CL", "ES", "LBS", "NQ", "PA"];
const div1000 = ["GF", "LE", "HE", "NG", "SI", "ZR"];
const div10000 = ["6A","RB", "6B", "6L", "6C", "CNH", "6E", "ETH", "HG", "HO",];
const div1000000 = ["6J", "6M"];

const MONTHS = ["F", "G", "H", "J", "K", "M", "N", "Q", "U", "V", "X", "Z"];


/* input: json obj with key: datetime, val: json obj with key: tick, val: [trades, bids, asks]
    { datetime: {tick: [t,b,a],... },... }
  pushes into and returns values in a list that is formatted for highcharts heatmap
  output: [ [datetime, tick, val],... ]
*/
function format_json(input_json, root) {
    let master_list = [];
    let denominator = 1; // figure out how much to divide tick by in order to get exchange's decimal format
    if (div10.includes(root)) {
        denominator = 10;
    } else if (div100.includes(root)) {
        denominator = 100;
    } else if (div1000.includes(root)) {
        denominator = 1000;
    } else if (div10000.includes(root)) {
        denominator = 10000;
    } else if (div1000000.includes(root)) {
        denominator = 1000000;
    }
    for (let i in input_json) {
        if (input_json.hasOwnProperty(i)) {
            for (let j in input_json[i]) {
                if (input_json[i].hasOwnProperty(j)) {
                    let tick = parseFloat(j);
                    tick /= denominator;
                    let bid_ask_ratio = input_json[i][j][1] / (input_json[i][j][1] + input_json[i][j][2]);
                    bid_ask_ratio = Number.isNaN(bid_ask_ratio) ? NaN : Number(bid_ask_ratio.toFixed(3))
                    if (input_json[i][j][0] > 0 && !Number.isNaN(bid_ask_ratio)) {
                        master_list.push([i, tick, bid_ask_ratio]);
                    }
                }
            }
        }
    }
    //console.table(master_list)
    return master_list;
}

let d = new Date();
let fifteenDaysAgo = new Date(d.setDate(d.getDate() - (15)));

let queryStartDate = fifteenDaysAgo;
let queryEndDate = new Date();

function getMinTickSize(symbol) {
    let root = symbol.substring(0, symbol.length - 2)

    let ticker000001 = ["6J"];
    let ticker00001 = ["6M"];
    let tick0001 = ["6A", "6B", "6L", "6C", "6E"];
    let tick001 = ["CNH", "HG", "HO", "NG", "RB"];
    let tick01 = ["CL", "ZF", "6M", "SI", "ZT",];
    let tick025 = ["ZL", "GF", "LE", "HE", "ZN", "ZR"];
    let tick1 = ["PL", "ZM", "UB", "ZB"];
    let tick25 = ["ZC", "ZO", "ZS", "ZW"];
    let tick5 = ["ZQ"]
    let tick10 = ["ETH", "GC", "LBS", "MWE", "PA",];
    let tick250 = ["ES"];
    let tick50 = ["YM", "NQ",];
    let tick100 = ["BTC"];

    // could probably find a cleaner approach here...
    if (ticker000001.includes(root)) {
        return .000001;
    } else if (ticker00001.includes(root)) {
        return .00001; 
    } else if (tick0001.includes(root)) {
        return .0001;
    } else if (tick001.includes(root)) {
        return .001;
    } else if (tick01.includes(root)) {
        return .01;
    } else if (tick025.includes(root)) {
        return .025;
    } else if (tick1.includes(root)) {
        return .1;
    } else if (tick25.includes(root)) {
        return .25;
    } else if (tick5.includes(root)) {
        return .5
    } else if (tick10.includes(root)) {
        return 1;
    } else if (tick250.includes(root)) {
        return 2.5;
    } else if (tick50.includes(root)) {
        return 5;
    } else if (tick100.includes(root)) {
        return 10;
    } else {
        return .5; // default
    }
}

const StartDatePicker = () => {
    const [startDate, setStartDate] = useState(queryStartDate);
    const years = range(1990, getYear(new Date()) + 1, 1);
    const months = [
      "Jan F",
      "Feb G",
      "Mar H",
      "Apr J",
      "May K",
      "Jun M",
      "Jul N",
      "Aug Q",
      "Sep U",
      "Oct V",
      "Nov X",
      "Dec Z",
    ];
    return (
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
  
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
  
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        selected={startDate}
        onChange={(date) => {
            changeStartDate(date)
            setStartDate(date)}
        }
        dateFormat = "yyyy-MM-d"
      />
    );
};

const EndDatePicker = () => {
    const [endDate, setEndDate] = useState(queryEndDate);
    const years = range(1990, getYear(new Date()) + 1, 1);
    const months = [
      "Jan F",
      "Feb G",
      "Mar H",
      "Apr J",
      "May K",
      "Jun M",
      "Jul N",
      "Aug Q",
      "Sep U",
      "Oct V",
      "Nov X",
      "Dec Z",
    ];
    return (
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
  
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
  
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        selected={endDate}
        onChange={(date) => {
            changeEndDate(date)
            setEndDate(date)}
        }
        dateFormat = "yyyy-MM-d"
      />
    );
};

function changeStartDate(newStart) {
    queryStartDate = newStart;
}

function changeEndDate(newEnd) {
    queryEndDate = newEnd;
}

// convert from 2022F -> F22
function formatMonth(yyyyM) {
    // console.log(yyyyM + " -> " + (yyyyM.charAt(yyyyM.length - 1) + yyyyM.substring(2,4)))
    return (yyyyM.charAt(yyyyM.length - 1) + yyyyM.substring(2,4))
}

// convert from F22 -> ZSF2
function reFormatMonth(root, mYY) {
    return (root + mYY.charAt(0) + mYY.charAt(mYY.length - 1))
}

// verify user's input for contract is valid format: X22 (X is any valid month letter, followed by 2 numbers for year)
function isValidContractInput(input) {
    return (input.length === 3 && MONTHS.includes(input.charAt(0)) && !isNaN(input.charAt(1)) && !isNaN(input.charAt(2)));
}


let heatmapData = [];
let volumeData = [];
let dates = [];
let curSymbol = "";
let newSymbol = "";
let minTick = "";
let isClicked = false;
let changedContract = false;
let isNewRoot = false;
let initRoot = "";
export default function TradesHeatmap(props) {
    let query = {"symbol": curSymbol, "start": queryStartDate.toISOString().substring(0,10), "end": queryEndDate.toISOString().substring(0,10)};

    const propsAr = props.symbol.split("_");
    const root = propsAr[0];
    const contract = propsAr[1];

    const [state, setState] = useState({isLoading: false, subchartview: 0});
    const [curRoot, setCurRoot] = useState();
    const [curContract, setCurContract] = useState(formatMonth(contract));
    const [warning, setWarning] = useState("");

    const isFixedRoot = typeof root !== "undefined" && SYMBOLS_LIST.includes(root);

    if (isFixedRoot && !changedContract) {
        // console.log(root)
        const curActive = by_exchange(root).active;
        const curSymbolMonth = curActive["symbol"];
        // check if props has changed, avoid rendering previous props
        isNewRoot = root !== curSymbol.substring(0 , curSymbol.length - 2);
        curSymbol = curSymbolMonth;
        newSymbol = curSymbolMonth;
    }


    const clickedButton = () => {
        getData();
    }

    if (initRoot !== root) {
        changedContract = false;
        getData();
        initRoot = root;
    }


    const handleChange = (e) => {
        setCurContract((e.target.value).toUpperCase());
    }


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (isValidContractInput(e.target.value)) {
                changedContract = true;
                newSymbol = reFormatMonth(root, e.target.value);
                console.log("newSymbol : " + newSymbol);
                getData();
                setWarning("");
            } else {
                setWarning("bad contract input! ( format example: H22 )");
            }
            
        }
    }


    function getData() {
        if (newSymbol !== "") {
            isClicked = true;
            curSymbol = newSymbol;
            setState({isLoading: true})
            if (curSymbol !== "") {
                // console.log("getData : " + curSymbol)
                const heatmapQuery = {
                    "symbol": curSymbol, 
                    "start": queryStartDate.toISOString().substring(0,10), 
                    "end": queryEndDate.toISOString().substring(0,10)
                };
                // console.table(query)
                api_trades_heatmap(heatmapQuery).then(res => {
                    let validDates = res.data.dates;
                    // TODO: filter valid dates to ignore holidays
                    console.log(res.data.result)
                    console.log(res.data.volumes)
                    dates = validDates;
                    volumeData = res.data.volumes;
                    minTick = getMinTickSize(curSymbol);
                    heatmapData = format_json(res.data.result, root);
                    setState({isLoading: false});
                    setCurRoot(curSymbol);
                }).catch(e => {
                    console.log(e)
                    heatmapData = [];
                    try {
                        setState({isLoading: false});
                        setCurRoot(curSymbol);
                    } catch (error) {
                        console.log(error)
                    }
                });
            }
        }
    }
     

    function symbolSelectChange(event) {
        newSymbol = event.target.value;
        query.symbol = event.target.value;
    }


    function getMonthOptions() {
        let optionsList = [<option key={-1} disabled value="prompt"> -- select an option -- </option>]; // start with default val
        for (let i = 0; i < SYMBOLS_LIST.length; i++) {
            try {
                const curActive = by_exchange(SYMBOLS_LIST[i]).active
                const curSymbolMonth = curActive["symbol"];
                const curName = `${SYMBOLS_LIST[i]} - ${curActive["month_yr"]}`;
                optionsList.push(<option key={i} value={curSymbolMonth}>{curName}</option>);
            } catch (error) {
                console.log("ERROR: " + SYMBOLS_LIST[i])
                console.log(by_exchange(SYMBOLS_LIST[i]))
                // console.log(error)
            }
        }
        return optionsList;
    }


    let promptPage = () => {
        return (
            <div style={{margin: "20px", display: "flex", flexDirection: "row", flexWrap: "wrap", 
                    borderRadius: "40px", border: "4px solid #447463", padding: "14px", width: "360px", height: "38px"}}>
                    { !isFixedRoot &&
                        <select onChange={symbolSelectChange} defaultValue={"prompt"}>
                            {getMonthOptions()}
                        </select>
                    }
                    <div style={{marginLeft: "6px"}}>
                            <b style={{display: "table", margin: "0 auto"}}>Start Date</b>
                            <div style={{border: "1px solid", width: "72px", margin: "2px"}}>
                                <StartDatePicker/>
                            </div>
                        </div>
                        <div style={{marginLeft: "4px"}}>
                            <b style={{display: "table", margin: "0 auto"}}>End Date</b>
                            <div style={{border: "1px solid", width: "72px", margin: "2px"}}>
                                <EndDatePicker/>
                            </div>
                        </div>
                    <div style={{marginLeft: "6px"}}className="CircleBtn CircleBtnLg" onClick={clickedButton}>
                        <i className="fas fa-play"></i>
                    </div>
                </div>
        );   
    };


    let chartPage = () => {
        const chartOptions = {
            chart: {
                height: "100%",
                events: {
                    load: function() {
                        let data = this.series[0].data
                        for (let i = 0; i < data.length; i++) {
                            if (isNaN(data[i].value) || data[i].value === undefined) {
                                data[i].update({
                                color: 'red'
                                })
                            }
                        }
                    }
                }
            },
            credits: {
                enabled: false
            },
            title: {
                text: `${curSymbol}  -  Bid:Ask Ratio`
            },            
            xAxis: {
                type: 'category',
                categories: dates,
                minPadding: 0,
                maxPadding: 0,
                startOnTick: false,
                endOnTick: false,
                minorGridLineWidth: 0,
                lineWidth: 0,
                lineColor: 'transparent',
                tickLength: 0,
                crosshair: true
            },
            yAxis: [
                {
                    title: {
                        text: "price"
                    },
                    crosshair: true,
                    height: "70%"
                },
                {
                    title: {
                        text: "volume",
                    },
                    offset: 5,
                    top: "75%",
                    height: "25%"
                }
            ],
            colorAxis: {
                min: 0,
                max: 1,
                reversed: false,
                stops: [
                    [0, '#ff0000'], //red
                    [0.5, '#e6e6e6'], //white
                    [1, '#0000ff'] //blue
                ]
            },
            legend: {
                align: "right",
                layout: "vertical",
                margin: 0,
                verticalAlign: "top",
                y: 25,
                symbolHeight: 280
            },
            plotOptions: {
                heatmap: {
                    rowsize: minTick,
                    colsize: 1,
                    dataLabels: {
                        formatter: function() {
                        if (isNaN(this.point.value) && this.point.value !== null) {
                            return this.point.value;
                        }
                        }
                    }
                }
            },
            tooltip: {
                shared: 1,
                useHTML: true,
                formatter: function () {
                    if (this.point) { // heatmap
                        const date = this.point.name;
                        const y = this.point.y;
                        const z = this.point.value;
                        return `<div>
                            ${date.substring(5)} | ${y}
                            <br><b>${z}</b>
                            </div>`;
                    } else { // volume columns
                        return `<div>${this.y}<b</div>`
                    }
                }
            },
            series: [
                {
                    name: "bid_ask_heatmap",
                    type: "heatmap",
                    colorAxis: 0,
                    borderWidth: 0,
                    data: heatmapData,
                    turboThreshold: 0, // allows input data > 1,000 rows
                    yAxis: 0,
                },
                {
                    name: "daily_volumes",
                    type: "column",
                    coloAxis: 1,
                    color: "black",
                    data: volumeData.map((ele) => [ele['date'], ele['volume']]),
                    yAxis: 1,

                }
            ]
        };

        if (isFixedRoot) {
            return (
                <div>
                    <div className="row">

                            <div className="row" style={{margin: "10px", display: "flex", flexDirection: "row", flexWrap: "wrap", 
                                borderRadius: "40px", border: "4px solid #447463", padding: "5px", width: "290px", height: "65px"}}>
                                <div>
                                    contract:
                                    <div style={{border: "1px solid #447463", borderRadius: "5px", marginLeft: "2px", marginRight: "2px", width: "30px", height: "20px"}}>
                                        <input type="text" value={curContract} onChange={handleChange} onKeyDown={handleKeyDown} maxLength={3}/>
                                    </div>
                                </div>
                                <div style={{marginLeft: "6px"}}>
                                    <b style={{display: "table", margin: "0 auto"}}>Start Date</b>
                                    <div style={{border: "1px solid", width: "72px", margin: "2px"}}> <StartDatePicker/> </div>
                                </div>

                                <div style={{marginLeft: "4px"}}>
                                    <b style={{display: "table", margin: "0 auto"}}>End Date</b>
                                    <div style={{border: "1px solid", width: "72px", margin: "2px"}}> <EndDatePicker/> </div>
                                </div>

                                <div style={{marginLeft: "6px"}} className="CircleBtn CircleBtnLg" onClick={clickedButton}>
                                    <i className="fas fa-play"></i>
                                </div>
                            </div>
                                
                            <p style={{color: "red"}}>{warning}</p>
                            
                    </div>
                        <div style={{marginLeft: "10px", width: "90%"}}> <HighchartsReact highcharts={Highcharts} options={chartOptions}/> </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div style={{margin: "10px", display: "flex", flexDirection: "row", flexWrap: "wrap", 
                        borderRadius: "40px", border: "4px solid #447463", padding: "14px", width: "360px", height: "38px"}}>
                        <select onChange={symbolSelectChange} defaultValue={"prompt"}>
                            {getMonthOptions()}
                        </select>
                        <div style={{marginLeft: "6px"}}>
                            <b style={{display: "table", margin: "0 auto"}}>Start Date</b>
                            <div style={{border: "1px solid", width: "72px", margin: "2px"}}>
                                <StartDatePicker/>
                            </div>
                        </div>
                        <div style={{marginLeft: "4px"}}>
                            <b style={{display: "table", margin: "0 auto"}}>End Date</b>
                            <div style={{border: "1px solid", width: "72px", margin: "2px"}}>
                                <EndDatePicker/>
                            </div>
                        </div>
                        <div style={{marginLeft: "6px"}}className="CircleBtn CircleBtnLg" onClick={clickedButton}>
                            <i className="fas fa-play"></i>
                        </div>
                    </div>
                    
                    <div style={{marginLeft: "10px", width: "90%", }}>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
                    </div>
                </div>
            );
        }
    };

    
    let loadingPage = () => {
        return (
            <div>
                <h3>Symbol: {curSymbol}</h3>
                <div>{LoadingPage()}</div>
            </div>
        );
    };

    if (isClicked && !isNewRoot) {
        if (state.isLoading) { // loading...
            return loadingPage();
        } else { // done loading, show chart
            return chartPage();
        }
    } else { // prompt user
        return promptPage();
    }
}
