import React, { Component } from 'react'
import styled from 'styled-components';
import { GLOBALSTYLED } from '../../config';
import S3PdfViewer from "../../components/pdfViewer/S3PdfViewer";
import ImbueTabs from "../../components/tabs/ImbueTabs";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;

const documentOptions = [
    { label: "Master Term Structure", value: "Master_Term_Structure.pdf" },
    { label: "Dry Powder", value: "dry_powder.pdf" },
    { label: "Dry Powder Spreading", value: "dry_powder_spreading.pdf" },
    { label: "Flat vs Spreading", value: "flat_vs_spread.pdf"},
    { label: "Implied Volatility Skews", value: "Master_Vol_Skew.pdf"},
    { label: "Research Chart Pack", value: "research_chartpack.pdf"},
    { label: "Session Breakdown", value: "Overnight_vs_Day_Session.pdf"},
    { label: "Currency Correlations", value: "Currency_Correlations.pdf"},
    { label: "Gamma Positioning", value: "Gamma_PDFs.pdf"},
    { label: "Contemporaneous Correlations", value: "correlation_plots.pdf"},
]

export default class ResearchCenterPdfViewer extends Component {

    state = {
        selectedItem: documentOptions[0]
    }

    render() {
        return (
            <Layout>
                <ImbueTabs options={documentOptions} selectedValue={this.state.selectedItem.value} onClickOption={(opt) => this.setState({selectedItem: opt})} />
                <h1>{this.state.selectedItem.label}</h1>
                <S3PdfViewer key={this.state.selectedItem.value} bucketname="imbuereportbucket" id={this.state.selectedItem.value} />
            </Layout>
        )
    }
}
