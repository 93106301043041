import React, { Component } from "react";
import styled from "styled-components";

const Layout = styled.div`
    height: calc(100vh - 73px);
    margin: 0;
    padding: 0;
    overflow: hidden;
    animation: fadeIn 2s;

    .title {
        font-family: var(--font-main);
        color: #888;
        position: absolute;
        right: 30px;
        bottom: 50px;
        font-size: 4rem;
        color: #fff;
    }

    .btn_zone {
        position: absolute;
        display: flex;
        right: 20px;
        top: 50px;
    }

    .mode_btn {
        padding: 5px 10px;
        font-family: var(--font-main);
        font-size: 2rem;
        transition: all 0.3s;
        color: #aaa;
    }

    .mode_btn:hover {
        cursor: pointer;
        color: #fff;
    }
`;

const orthographic = "-242.67,0," + window.innerWidth / 5;

const mode = [
    {
        label: "Wind",
        note: "Wind",
        url: `https://earth.nullschool.net/#current/wind/surface/level/orthographic=${orthographic}`
    },
    {
        label: "Temp",
        note: "Temperature",
        url: `https://earth.nullschool.net/#current/wind/surface/level/overlay=temp/orthographic=${orthographic}`
    },
    {
        label: "3HPA",
        note: "3-hour Precipitation Accumulation",
        url: `https://earth.nullschool.net/#current/wind/surface/level/overlay=precip_3hr/orthographic=${orthographic}`
    },
    {
        label: "TPW",
        note: "Total Precipitation Water",
        url: `https://earth.nullschool.net/#current/wind/surface/level/overlay=total_precipitable_water/orthographic=${orthographic}`
    }
];

class Earth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0,
            zoom: 0
        };
    }

    componentDidMount() {
        this.setState({
            zoom: window.innerWidth / 5
        });
    }

    onChangeIndex(idx) {
        this.setState({
            selected: parseInt(idx)
        });
    }

    render() {
        return (
            <Layout>
                <div className="title">{mode[this.state.selected].note}</div>
                <div className="btn_zone">
                    {mode.map((ele, idx) => {
                        return (
                            <div
                                className="mode_btn"
                                style={{
                                    color: this.state.selected === idx ? "white" : undefined
                                }}
                                onClick={() => {
                                    this.onChangeIndex(idx);
                                }}
                            >
                                {ele.label}
                            </div>
                        );
                    })}
                </div>
                <iframe title="Earth" width="100%" height="100%" src={mode[this.state.selected].url} frameborder="0" onLoad={() => {}} scrolling="yes"></iframe>
            </Layout>
        );
    }
}

export default Earth;
