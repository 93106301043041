import React, { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import moment from 'moment';

HighchartsMore(Highcharts);


// export default class HighStockWrapper extends Component {
//     render() {
//         let config = this.props.config;
//         if (this.props.height && "chart" in config) {
//             config.chart.height = this.props.height;
//         }
//         return <HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} options={config} />;
//     }
// }

export default function HighStockWrapper(props) {
    let config = props.config;
    if (props.height && "chart" in config) {
        config.chart.height = props.height;
    }

    useEffect(() => {
        Highcharts.setOptions({
            global: {
                /**
                 * Use moment-timezone.js to return the timezone offset for individual
                 * timestamps, used in the X axis labels and the tooltip header.
                 */
                getTimezoneOffset: function (timestamp) {
                    var zone = 'America/Chicago',
                        timezoneOffset = -moment.tz(timestamp, zone).utcOffset();

                    return timezoneOffset;
                }
            },
            // chart: {
            //     animation: false
            // },
            // plotOptions: {
            //     series: {
            //         animation: false
            //     }
            // },
            lang: {
                thousandsSep: ','
            }
        });
    });

    return (<HighchartsReact ref={props.innerRef} highcharts={Highcharts} constructorType={"stockChart"} options={config} />);
}