import React, { Component } from "react";
import styled from "styled-components";
import { api_ailabv2_list_jobs, api_ailabv2_delete_job, api_ailabv2_duplicate_job, api_ailabv2_change_status } from "../../../../helpers/API";
import { COLORMAP } from "../../../../config";
import moment from "moment";

const Layout = styled.div`
    .jobcard_wrapper {
        padding: 20px 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }

    .jobcard_item {
        font-size: 1.2rem;
        padding: 10px;
        border-radius: 10px;
        background: rgb(240, 240, 240);
        border: 1px solid rgb(220, 220, 220);
    }
`;

export default class OverviewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            joblist: [],
            isLoading: false,
        };
        this.api = this.api.bind(this);
    }

    api() {
        return {
            getJob: (docid) => {
                this.props.funcs.switchPage({ docid, mode: 1 });
            },
            getJobList: (_) => {
                this.setState(
                    {
                        isLoading: true,
                    },
                    () => {
                        api_ailabv2_list_jobs().then((res) => {
                            const data = res.data.sort((a, b) => b.created - a.created);
                            this.setState({
                                joblist: data,
                                isLoading: false,
                            });
                        });
                    }
                );
            },
            deleteJob: (docid) => {
                const isConfirmed = window.confirm(`Do you want to delete this job? [docid: ${docid}]`);
                if (isConfirmed) {
                    api_ailabv2_delete_job(docid).then((res) => {
                        // window.alert(res.data);
                        this.api().getJobList();
                    });
                }
            },
            duplicateJob: (docid) => {
                const isConfirmed = window.confirm("Do you want to duplicate this job? ");
                if (isConfirmed) {
                    api_ailabv2_duplicate_job(docid).then((res) => {
                        this.api().getJobList();
                    });
                }
            },
            changestatus: (docid, status) => {
                api_ailabv2_change_status(docid, status).then((res) => {
                    this.api().getJobList();
                });
            },
        };
    }

    componentDidMount() {
        this.api().getJobList();
    }

    render() {
        // console.log(this.state.joblist);
        // ===== state =====

        // ===== props =====
        const parentFuncs = this.props.funcs;

        return (
            <Layout>
                <h1>Overview</h1>
                <hr />
                <div className="BtnWrapper">
                    <div
                        className="SmallBtn"
                        onClick={() => {
                            parentFuncs.switchPage({ docid: null, mode: 1 });
                        }}
                    >
                        <span>
                            <i className="fas fa-plus-circle"></i> Create
                        </span>
                    </div>
                    <div
                        className="SmallBtn"
                        onClick={() => {
                            this.api().getJobList();
                        }}
                    >
                        <span>
                            <i className="fas fa-redo"></i> Refresh
                        </span>
                    </div>
                    <div
                        className="SmallBtn"
                        onClick={() => window.open("https://couchdb.imbue.cloud/_utils/#database/ailabv2_jobs/_all_docs", "_blank")}
                    >
                        <span>
                            <i className="fas fa-database"></i> CouchDB
                        </span>
                    </div>
                    <div className="LoadingText" style={{ marginLeft: "10px" }}>
                        {this.state.isLoading ? "loading..." : ""}
                    </div>
                </div>
                <JobCards joblist={this.state.joblist} api={this.api()} />
            </Layout>
        );
    }
}

// ========== Sub-Component ============

class JobCards extends Component {
    render() {
        // ===== props ======
        const joblist = this.props.joblist || [];
        const api = this.props.api;

        return (
            <div className="jobcard_wrapper">
                {joblist.map((ele, idx) => {
                    const created_ts = moment(ele.created * 1000).format("MMMM Do YYYY, h:mm:ss a");
                    const infolist = [
                        ["Job#", ele._id],
                        ["Date From", ele.config.date_from],
                        ["Explorer", ele.config.explorer],
                        ["Frequency", ele.config.frequency],
                        ["Factors", ((ele.config.data || [""])[0].factors || []).join(", ")],
                        ["Securities", ((ele.config.data || [""])[0].securities || []).join(", ")],
                        ["Fields", ((ele.config.data || [""])[0].fields || []).join(", ")],
                    ];
                    return (
                        <div key={ele._id} className="jobcard_item">
                            <div style={{ color: "#444" }}>
                                <small>{created_ts}</small>
                            </div>
                            <div className="flexBox flexBox_between">
                                <div className="jobcard_title">{ele.config.name}</div>
                                <div
                                    className="SmallBtn"
                                    onClick={() => {
                                        api.changestatus(ele._id, ele.status);
                                    }}
                                >
                                    <span>
                                        <i
                                            className={ele.status === "active" ? "fas fa-circle" : "fal fa-circle"}
                                            style={{ color: ele.status === "active" ? COLORMAP.green_o : COLORMAP.grey }}
                                        ></i>{" "}
                                        {ele.status}
                                    </span>
                                </div>
                            </div>
                            <hr />
                            {infolist.map((item, idx_) => {
                                let display = item[1] || "";
                                if (display.length > 50) {
                                    display = display.slice(0, 50) + " ...";
                                }
                                return (
                                    <div key={idx_} className="flexBox flexBox_between" style={{ margin: "4px 0", fontFamily: "roboto" }}>
                                        <div style={{ color: "#777" }}>{item[0]}</div>
                                        <div>{display}</div>
                                    </div>
                                );
                            })}
                            {/* <hr /> */}
                            <div className="BtnWrapper" style={{ marginTop: "20px" }}>
                                <div
                                    className="SmallBtn SmallBtn_blue"
                                    onClick={() => {
                                        api.getJob(ele._id);
                                    }}
                                >
                                    <span>
                                        <i className="fas fa-info-circle"></i> View
                                    </span>
                                </div>
                                <div
                                    className="SmallBtn SmallBtn_orange"
                                    onClick={() => {
                                        api.duplicateJob(ele._id);
                                    }}
                                >
                                    <span>
                                        <i className="fas fa-clone"></i> Duplicate
                                    </span>
                                </div>
                                <div
                                    className="SmallBtn SmallBtn_red"
                                    onClick={() => {
                                        api.deleteJob(ele._id);
                                    }}
                                >
                                    <span>
                                        <i className="fas fa-trash"></i> Remove
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
