import React, { Component } from "react";
import styled from "styled-components";
import { api_vpin } from "../../helpers/API";
import ImbueSelect from "../../components/input/ImbueSelect";
import { highchartBasicConfig, COLORMAP, GLOBALSTYLED } from "../../config";
import HighStockWrapper from "../../components/chart/HighStockWrapper";
import HighChartWrapper from "../../components/chart/HighChartWrapper";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;

// -- options
const assetOptions = [
    "6E",
    "6J",
    "CL",
    "CLT",
    "CT",
    "DX",
    "ES",
    "GC",
    "HE",
    "HG",
    "HO",
    "KE",
    "LE",
    "NG",
    "RB",
    "SI",
    "ZB",
    "ZC",
    "ZF",
    "ZL",
    "ZM",
    "ZN",
    "ZS",
    "ZW",
].map((ele) => ({ label: ele, value: ele }));
const defaultAsset = assetOptions[0];

class Vpin extends Component {
    state = {
        selectedAsset: defaultAsset.value,
        datefrom: "2018-01-01",
        dateto: "2020-01-01",
        isLoading: 0,
        isLoading2: 0,
        timeseries: {
            vpin: [],
            dvpin: [],
            price: [],
            symbolmapping: {},
        },
        corr: {},
    };

    componentDidMount() {
        this.onUpdate();
        this.onUpdateCorrelation();
    }

    onUpdate = () => {
        this.setState({ isLoading: 1 }, () => {
            api_vpin({
                query: "timeseries",
                asset: this.state.selectedAsset.toLowerCase(),
            }).then((res) => {
                this.setState({
                    isLoading: 0,
                    timeseries: res.data,
                });
            });
        });
    };

    onUpdateCorrelation = () => {
        this.setState({ isLoading2: 1 }, () => {
            api_vpin({
                query: "correlation",
                datefrom: this.state.datefrom,
                dateto: this.state.dateto,
            }).then((res) => {
                this.setState({
                    corr: res.data,
                    isLoading2: 0,
                });
            });
        });
    };

    render() {
        const chartConfig = {
            timeseries: {
                ...highchartBasicConfig,
                yAxis: [
                    {
                        height: "60%",
                    },
                    {
                        top: "60%",
                        height: "20%",
                    },
                    {
                        top: "80%",
                        height: "20%",
                    },
                ],
                tooltip: {
                    shared: true,
                },
                series: [
                    {
                        name: "price",
                        data: (this.state.timeseries.price || []).map((ele) => [Date.parse(ele.date), ele.value]),
                        yAxis: 0,
                        color: COLORMAP.main,
                    },
                    {
                        name: "vpin",
                        data: (this.state.timeseries.vpin || []).map((ele) => [Date.parse(ele.date), ele.value]),
                        yAxis: 1,
                        color: COLORMAP.orange,
                    },
                    {
                        name: "dvpin",
                        data: (this.state.timeseries.dvpin || []).map((ele) => [Date.parse(ele.date), ele.value]),
                        yAxis: 2,
                        color: COLORMAP.blue,
                    },
                ],
            },
            correlation: (data) => {
                if (data.data) {
                    return {
                        ...highchartBasicConfig,
                        xAxis: {
                            categories: data.columns,
                        },
                        yAxis: {
                            categories: data.index,
                        },
                        colorAxis: {
                            min: -1,
                            max: 1,
                            stops: [
                                [0, "#a10000"],
                                [0.5001, "#ffcfcf"],
                                [0.5, "#FFFFFF"],
                                [0.4999, "#d6ffd6"],
                                [1, "#005200"],
                            ],
                        },
                        tooltip: {
                            useHtml: true,
                            outside: true,
                            formatter: function (e) {
                                let xLabel = this.series.xAxis.categories[this.point.x];
                                let yLabel = this.series.yAxis.categories[this.point.y];
                                let value = this.point.value;
                                let textColor = value >= 0 ? COLORMAP.green : COLORMAP.red;
                                const HTML = `
                                <div style="font-family: Lato">
                                    <span style="color: #444;">${xLabel}, ${yLabel}</span><br>
                                    <span style="color: ${textColor}; font-size: 1.5rem;">${value.toFixed(6)}</span>
                                </div>
                                `;
                                return HTML;
                            },
                        },
                        series: [
                            {
                                type: "heatmap",
                                data: data.data
                                    .map((row, rowindex) => {
                                        return row.map((col, colindex) => {
                                            return [rowindex, colindex, col];
                                        });
                                    })
                                    .reduce((a, b) => {
                                        return [...a, ...b];
                                    }, []),
                            },
                        ],
                    };
                } else {
                    return {};
                }
            },
        };

        return (
            <Layout>
                <div className="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="asset"
                        options={assetOptions}
                        defaultValue={defaultAsset}
                        onChange={(e) => this.setState({ selectedAsset: e.value }, () => this.onUpdate())}
                    />
                </div>
                <div>
                    {this.state.isLoading ? (
                        <div className="LoadingText">loading...</div>
                    ) : (
                        <div>
                            <h3>
                                {this.state.selectedAsset} -{" "}
                                {this.state.timeseries.symbolmapping[this.state.selectedAsset.toLowerCase()]}
                            </h3>
                            <HighStockWrapper height="700px" config={chartConfig.timeseries} />

                            {/* <div className="gridBox gridBox_2">
                                <div>
                                    <h3>VPIN correlation</h3>
                                    <HighChartWrapper
                                        height="500px"
                                        config={chartConfig.correlation(this.state.data.couchdoc.vpin_corr || {})}
                                    />
                                </div>
                                <div>
                                    <h3>DVPIN correlation</h3>
                                    <HighChartWrapper
                                        height="500px"
                                        config={chartConfig.correlation(this.state.data.couchdoc.dvpin_corr || {})}
                                    />
                                </div>
                            </div> */}

                            {/* <HighChartWrapper height="500px" config={chartConfig.timeseries} /> */}
                        </div>
                    )}
                </div>

                <div className="ImbueSelectsWrapper">
                    <div className="ImbueSelectItem">
                        <div className="label">Date From:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={this.state.datefrom}
                            onChange={(e) => this.setState({ datefrom: e.target.value })}
                        />
                    </div>
                    <div className="ImbueSelectItem">
                        <div className="label">Date To:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={this.state.dateto}
                            onChange={(e) => this.setState({ dateto: e.target.value })}
                        />
                    </div>
                    <div className="CircleBtn CircleBtnLg" onClick={this.onUpdateCorrelation}>
                        <i className="fas fa-play"></i>
                    </div>
                </div>
                <div>
                    {this.state.isLoading2 ? (
                        <div className="LoadingText">Loading Correlation...</div>
                    ) : (
                        <div className="gridBox gridBox_2">
                            <div>
                                <h3>VPIN correlation</h3>
                                <HighChartWrapper
                                    height="500px"
                                    config={chartConfig.correlation(this.state.corr.vpin_corr || {})}
                                />
                            </div>
                            <div>
                                <h3>DVPIN correlation</h3>
                                <HighChartWrapper
                                    height="500px"
                                    config={chartConfig.correlation(this.state.corr.dvpin_corr || {})}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        );
    }
}

export default Vpin;
