import React, { Component } from "react";
import { api_s3 } from "../../helpers/API";
import PdfViewer from "./PdfViewer";
import { COLORMAP } from "../../config";

export default class S3PdfViewer extends Component {
    /**
     * PDF viewer direct access to S3
     * 
     * props
     * -----
     * 
     * > bucketname, string
     *      S3 Bucket Name, default="imbuereportbucket"
     * 
     * > id, string
     *      S3 Object ID
     * 
     */

    state = {
        arrayBuffer: "",
        error: "",
        isLoading: 0,
    };

    componentDidMount() {
        const bucketname = this.props.bucketname || "imbuereportbucket";
        const objectid = this.props.id;
        this.setState({ isLoading: 1 }, () => {
            api_s3(bucketname, objectid, true)
                .then((res) => {
                    this.setState({
                        arrayBuffer: res.data,
                        isLoading: 0,
                    });
                })
                .catch((e) => {
                    this.setState({ error: "File is not Found!", isLoading: 0 });
                });
        });
    }

    render() {
        const isLoaded = this.state.base64 !== "";
        const bucketname = this.props.bucketname || "imbuereportbucket";
        const objectid = this.props.id;
        if (objectid) {
            return (
                <div >
                    <small style={{ color: "#999" }}>
                        {bucketname} <i style={{ margin: "0 10px" }} className="fas fa-caret-right"></i> {objectid}
                    </small>
                    {isLoaded ? (
                        this.state.error ? (
                            <div style={{ color: COLORMAP.red_o }} className="LoadingText">
                                File is not Found!
                            </div>
                        ) : (
                            <div>
                                <PdfViewer arrayBuffer={this.state.arrayBuffer} filename={this.props.id} />
                            </div>
                        )
                    ) : (
                        <div className="LoadingText">Loading Pdf...</div>
                    )}
                </div>
            );
        } else {
            return <div>ERROR (no pdf name)</div>
        }

    }
}
