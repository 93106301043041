import React, { Component } from "react";
import { titleWords } from "../../../helpers/Utils";

/**
 *
 *
 * Dynamitc simulation statistics display component
 *
 */
export default class SimulationPeriodStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: []
        };
        this.onChangeStats = this.onChangeStats.bind(this);
    }

    componentDidMount() {
        this.setState({ stats: this.props.stats || [] });
    }

    onChangeStats(newStats) {
        this.setState({ stats: newStats });
    }

    render() {
        const stats = this.state.stats || [];

        const stats__displayaslist = stats.filter(ele => ele.label !== "start_date" && ele.label !== "end_date");
        const stats__startdate = (stats.filter(ele => ele.label === "start_date")[0] || {}).value;
        const stats__enddate = (stats.filter(ele => ele.label === "end_date")[0] || {}).value;

        return (
            <div style={{ fontFamily: "var(--font-main)" }}>
                <div className="InfoCardFlexWrapper">
                    {stats__displayaslist.map(element => {
                        return (
                            <div className="InfoCardMid">
                                <div className="InfoCardMid_label">{titleWords(element.label)}</div>
                                <div className="InfoCardMid_value">{element.value}</div>
                            </div>
                        );
                    })}
                    <div style={{ fontSize: "1rem", color: "#666" }}>
                        <div>START: {stats__startdate || "--"}</div>
                        <div>END: {stats__enddate || "--"}</div>
                    </div>
                </div>
            </div>
        );
    }
}
