import moment from "moment";
import { DEFAULTNAVWEIGHT } from "../../config";
import { get_weekday_range } from "../../helpers/DatetimeFunctions";
import { getTickerFromPair, zipAll } from "../../helpers/Utils";

/**
 *
 * @param {object} data
 * @param {object} navDict --- Dictionary of NAV weights
 */
export function generateMultiCumPnl(data, navDict, isWeight = true) {
    if (data) {
        const pnls = [
            ...data.securities.map(item => {
                let pnl = [];
                if (item.timeseries) {
                    pnl = item.timeseries.map(ele => [ele.date, ele.pnl]);
                } else {
                    pnl = item.pnl;
                }
                let weight = 100;
                if (isWeight) {
                    if (item.ticker in navDict) {
                        weight = navDict[item.ticker];
                    } else {
                        weight = DEFAULTNAVWEIGHT;
                    }
                }
                return [item.ticker, pnl, `${navDict[item.ticker]}%`, pnl.map(ele => [ele[0], ele[1] * weight])];
            }),
            ["Portfolio", data.pnl, "", data.pnl]
        ];

        const header = pnls.map(ele => [ele[0], ele[2]]).reduce((a, b) => [...a, ...b], []);
        const columns = pnls.map(_ => ["date", "value"]).reduce((a, b) => [...a, ...b], []);
        const allPnls = zipAll(pnls.map(ele => ele[3])).map(ele => ele.reduce((a, b) => [...a, ...(b === "" ? ["", ""] : b)], []));
        const result = [...[header], ...[columns], ...allPnls];
        return result;
    } else {
        return [];
    }
}

/**
 *
 * @param {object} data
 * @param {object} navDict
 */
export function generateAllTrades(data, navDict) {
    // 1. Split to daily
    data = data
        .map((row, idx) => {
            const open_dt = row.open_date;
            const close_dt = row.close_date;
            const date_range = get_weekday_range(open_dt, close_dt);
            return date_range.map(r => ({
                ...row,
                date: r,
                trade_id: idx + 1
            }));
        })
        .reduce((a, b) => [...a, ...b], [])
        .sort((a, b) => moment(a.date) - moment(b.date));

    // 2. Determine if this is a pair strategy
    let isPair = false;
    if (data.length > 0) {
        if (data[0].ticker.indexOf("%") >= 0 || data[0].ticker.indexOf("-") >= 0) {
            isPair = true;
        }
    }
    if (isPair) {
        data = data
            .map(row => {
                const newRow = [
                    {
                        ...row,
                        close_contract: row.close_contract[0],
                        open_contract: row.open_contract[0],
                        close_px: row.close_px[0],
                        open_px: row.open_px[0],
                        contract_value: row.contract_value[0],
                        ticker: getTickerFromPair(row.ticker)[0],
                        ref: row.ticker + " (leg1)"
                    },
                    {
                        ...row,
                        close_contract: row.close_contract[1],
                        open_contract: row.open_contract[1],
                        close_px: row.close_px[1],
                        open_px: row.open_px[1],
                        contract_value: row.contract_value[1],
                        ticker: getTickerFromPair(row.ticker)[1],
                        ref: row.ticker + " (leg2)"
                    }
                ];
                return newRow;
            })
            .reduce((a, b) => [...a, ...b], []);
    }
    // 3. NAV weight assignment
    if (navDict) {
        data = data.map(row => {
            return {
                ...row,
                portfolio_weight_after: row.portfolio_weight * (navDict[row.ticker] || 3) * 0.01,
                nav: navDict[row.ticker] || 3
            };
        });
    }
    // 4. Final format
    data = data.map(row => ({
        trade_id: row.trade_id,
        date: row.date,
        ref: row.ref,
        direction: row.direction,
        ticker: row.ticker,
        open_date: row.open_date,
        close_date: row.close_date,
        contract_value: row.contract_value,
        portfolio_weight: row.portfolio_weight_after || row.portfolio_weight,
        nav: row.nav
    }));

    return data;
}
