import React, { Component } from "react";
import ReactJson from "react-json-view";
import styled from "styled-components";

import default_config from "../config_template.json";
import { api_ailabv2_get_job, api_ailabv2_modify_job, api_ailabv2_init_job } from "../../../../helpers/API.js";

const Layout = styled.div``;

export default class ConfigPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doc: {},
            config: {},
            config_og: {},
            isChanged: false,
            counter: 0,
            editMode: "json",
        };
        this.onConfigChange = this.onConfigChange.bind(this);
        this.api = this.api.bind(this);
    }

    componentDidMount() {
        // this.setState({ default_config: default_config, counter: this.state.counter + 1 });
        if (this.props.docid) {
            this.api().getJob(this.props.docid);
        } else {
            this.setState({
                config: default_config,
                counter: this.state.counter + 1,
            });
        }
    }

    onConfigChange(config, withCounter) {
        this.setState({
            config: config,
            counter: this.state.counter + (withCounter ? 1 : 0),
        });
    }

    api() {
        return {
            getJob: (docid) => {
                api_ailabv2_get_job(docid).then((res) => {
                    const data = res.data;
                    this.setState({
                        doc: data,
                        config: data.config,
                        config_og: data.config,
                        counter: this.state.counter + 1,
                    });
                });
            },
        };
    }

    render() {
        // ===== state =====
        const config = this.state.config;
        const isChanged = this.state.isChanged;

        console.log(config);

        // ===== props =====
        const parentFuncs = this.props.funcs;
        const docid = this.props.docid;
        const isInitJob = docid === null; // null: create; not null: modify

        return (
            <Layout>
                <h1>{isInitJob ? "Initialize Job" : `Job: ${docid}`}</h1>
                <hr />
                <section>
                    <div className="flexBox flexBox_between head_toolbar_wrapper">
                        {/* Left Tools */}
                        <div className="BtnWrapper">
                            <div
                                className="SmallBtn"
                                onClick={() => {
                                    parentFuncs.switchPage({ docid: null, mode: 0 });
                                }}
                            >
                                <span>
                                    <i className="fas fa-chevron-left"></i> Back
                                </span>
                            </div>
                            <div
                                className="SmallBtn"
                                onClick={() => {
                                    console.log("Switch");
                                }}
                            >
                                <span>
                                    {" "}
                                    <i className="fas fa-pen"></i> Mode (JSON)
                                </span>
                            </div>
                        </div>

                        <div className="BtnWrapper">
                            <div
                                className="SmallBtn SmallBtn_green"
                                onClick={() => {
                                    if (isInitJob) {
                                        api_ailabv2_init_job(config).then((res) => {
                                            window.alert("Configuration has been initialized.");
                                            parentFuncs.switchPage({ docid: null, mode: 0 });
                                        });
                                    } else {
                                        api_ailabv2_modify_job(docid, config).then((res) => {
                                            window.alert("Configuration has been updated!");
                                            parentFuncs.switchPage({ docid: null, mode: 0 });
                                        });
                                    }
                                }}
                            >
                                <span>
                                    <i className="fas fa-save"></i> Save
                                </span>
                            </div>
                            <div
                                className="SmallBtn SmallBtn_orange"
                                onClick={() => {
                                    this.onConfigChange(this.state.config_og, true);
                                }}
                            >
                                <span>
                                    {" "}
                                    <i className="fas fa-undo"></i> Reset
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <input
                        type="file"
                        name="file"
                        onChange={e => {
                            const formData = new FormData();
                            formData.append("file", e.target.files[0]);
                            console.log(formData);
                        }}
                    /> */}
                    <ReactJson
                        key={this.state.counter}
                        src={config}
                        onEdit={(edit) => {
                            // console.log(edit.updated_src);
                            this.onConfigChange(edit.updated_src, false);
                        }}
                        onAdd={(add) => {
                            this.onConfigChange(add.updated_src, false);
                        }}
                        onDelete={(dlt) => {
                            this.onConfigChange(dlt.updated_src, false);
                        }}
                    />
                </section>
            </Layout>
        );
    }
}
