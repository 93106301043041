import React, { useState, useEffect } from 'react';
import TopStrikesTableBody from './TopStrikesTableBody';
import TopStrikesTableHead from './TopStrikesTableHead';
import styles from '../../css/TopStrikes.module.css';
import { LoadingPage } from "../../helpers/SimpleComponents";


var wsUrl = ( window.location.protocol === 'https:'
    ? "wss://static.cbot.comatl.com:49534/"
    : "ws://static.cbot.comatl.com:49533/"
);

const TopStrikesTable = (props) => {
    const [data, setData] = useState(null);


    useEffect(() => {
        const socket = new WebSocket(wsUrl);

        setData(null);

        socket.onopen = () => {
            if (props.root) {
                socket.send(JSON.stringify({"type": "start", "source": "top_strikes", 
                    "authorization": "Bearer ".concat(localStorage.getItem("_token")), 
                    "args": {"asset":props.root}}));
            }
        }

        socket.onmessage = (event) => {
            const json = JSON.parse(event.data);
            try {
                console.log(json)
                if (json['type'] && json['type'] == 'data') {
                    setData(json['data']);
                }
            } catch (err) {
                console.error(err);
            }
        }

        return () => {
            socket.close();
        }; 
    }, [props.root]);

    // {
    //     "symbol": "ZS3U3 C1400",
    //     "asset": "ZS3",
    //     "session": "2023-09-11",
    //     "time": "2023-09-11 10:55:00",
    //     "month_yr": "SEP 23",
    //     "expiry": "2023-09-15 13:20:00",
    //     "under": "ZSX3",
    //     "under_asset": "ZS",
    //     "under_px": 1368.625,
    //     "strike": 1400,
    //     "flag": "C",
    //     "exercise": "A",
    //     "iv": 0.294,
    //     "delta": 0.23810979264575394,
    //     "gamma_pct": 0.09936426370591354,
    //     "mark_px": 5.873745705335041,
    //     "session_qty": 1023,
    //     "session_volume": 1068,
    //     "session_spread": 45,
    //     "top_strikes_oi.session": "2023-09-08",
    //     "open_interest": 3579,
    //     "oi_chg": -790,
    //     "cleared_volume": 959,
    //     "settle_px": 5,
    //     "settle_delta": 0.2062,
    //     "settle_iv": 0.2317,
    //     "settle_iv_chg": 0.0186,
    //     "delta_wtd_oi": 852,
    //     "delta_wtd_oi_max": 0,
    //     "open_interest_max": 0,
    //     "oi_chg_max": 0,
    //     "cleared_volume_max": 0,
    //     "session_qty_max": 2,
    //     "session_volume_max": 0,
    //     "session_spread_max": 0
    //   },

    const columns = [
        { label: "Time", accessor: "time", sortable: false },
        { label: "Symbol", accessor: "symbol", sortable: false },
        // { label: "Month Yr", accessor: "month_yr", sortable: false },
        { label: "Under", accessor: "under", sortable: false },
        { label: "DTE", accessor: "dte", sortable: true },
        { label: "Δ-wtd OI", accessor: "delta_wtd_oi", sortable: true },
        { label: "OI", accessor: "open_interest", sortable: true },
        { label: "OI Chg", accessor: "oi_chg", sortable: true },
        {label: "OI Chg Week", accessor: "oi_chg_week", sortable: true },
        { label: "Cleared Vol", accessor: "cleared_volume", sortable: true },
        { label: "Session Qty", accessor: "session_qty", sortable: true },
        { label: "Session Vol", accessor: "session_volume", sortable: true },
        { label: "Session Spread", accessor: "session_spread", sortable: true },
        { label: "Under PX", accessor: "under_px", sortable: true },
        { label: "Strke", accessor: "strike", sortable: true },
        { label: "Mark PX", accessor: "mark_px", sortable: true },
        // { label: "PX % Under", accessor: "px_pct_under", sortable: true },
        // { label: "Prem % Under", accessor: "prem_pct_under", sortable: true },
        { label: "IV", accessor: "iv", sortable: true },
        { label: "Δ", accessor: "delta", sortable: true },
        { label: "Γ %", accessor: "gamma_pct", sortable: true },
    ];

    const abs_sort_fields = [
        "delta_wtd_oi",
        "oi_chg",
        "oi_chg_week",
    ];

    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...data].sort((a, b) => {
                if (a[sortField] === null && b[sortField] === null) return 0;
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                return (
                    (
                        (typeof a[sortField] === 'string' && typeof b[sortField] === 'string') ?
                            (a[sortField].localeCompare(b[sortField])) // localCompare() String
                        : (abs_sort_fields.includes(sortField) ? // check nums for Absolute Value sorting
                            (Math.abs(a[sortField]) - Math.abs(b[sortField])) : (a[sortField] - b[sortField])) 
                    ) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setData(sorted);
        }
    };

    if (data === null) {
        return (<div>{LoadingPage()}</div>);
    } else {
        const dataError = (!data || data.length === 0);
        const sessionDate = (!dataError && data[0]['session']) ? data[0]['session'] : "-";
        const oiDate = (!dataError && data[0]['session_oi']) ? data[0]['session_oi'] : "-";
        const chainDelay = (props.chain && !props.chain.live) ? " | (Delayed T1)" : "";
        return (
            <div>
                <table className={styles.table}>
                    <caption>
                        {props.root} Top Strikes as of {sessionDate} (OI for {oiDate}) {chainDelay}
                    </caption>
                    {dataError &&
                    <p style={{color: 'red'}}> DATA ERROR </p>
                    }
                    <TopStrikesTableHead columns={columns} handleSorting={handleSorting}/>
                    <TopStrikesTableBody columns={columns} tableData={data}/>
                </table>
            </div>
        );
    }
};

export default TopStrikesTable;