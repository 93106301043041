import React, { Component } from 'react'
import HighChartWrapper from '../../../components/chart/HighChartWrapper';
// import { CSVDownloader } from '../../../components/SimpleComponents';
import { COLORMAP, highchartBasicConfig } from '../../../config';


const specialStratNames = {
    'S 1 A:00_0_R Comdty - C 1 A:00_0_R Comdty_HYO - hyo' : '1ZS-2ZC : hyo'
}
// return trimmed strategy name without full ticker notation
function getTrimmedName(ogName) {
    if (typeof ogName !== 'string') {
        return ogName
    } else {
        if (charCount(ogName, '-') > 1) {
            if (ogName in specialStratNames) { // should find a better dynamic approach down the line...
                return specialStratNames[ogName];
            } else {
                return ogName;
            }
        } else {
            const splitName = ogName.split("-");
            return splitName[0].split(" ")[0] + " :" + splitName[1];
        }
    }
}

function charCount(str, char) {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
        if (str.charAt(i) === char) {
            count++;
        }
    }
    return count;
}

export default class HPVChart extends Component {

    state = {
        selectedStrategies: []
    }

    onSelectStrategyIdx = (idx) => {
        if (this.state.selectedStrategies.includes(idx)) {
            this.setState({
                selectedStrategies: this.state.selectedStrategies.filter(ele => ele !== idx)
            })
        }else {
            this.setState({
                selectedStrategies: [...this.state.selectedStrategies, idx]
            })
        }
    }

    render() {
        const data = this.props.data
        const strategylist = this.props.strategylist
        const onSelectStrategyIdx = this.onSelectStrategyIdx
        const meta = data.meta
        let metaMapping = {};
        for (let k of meta || []) {
            metaMapping[k[0]] = k[1];
        }

        const chartConfig = {
            ...highchartBasicConfig,
            chart: {
                ...highchartBasicConfig.chart,
                zoomType: "x",
            },
            legend: { enabled: false },
            yAxis: [
                {
                    height: "35%",
                    title: { text: "" },
                    plotLines: [
                        {
                            color: '#333333',
                            width: 2,
                            dashStyle: "dash",
                            value: 0
                        },
                    ],
                    labels: {
                        formatter: function() {
                           return (this.value*100) +"%";
                        }
                    },
                },
                {
                    title: { text: "" },
                    height: "60%",
                    top: "40%",
                    categories: strategylist,
                    labels: {
                        x: 30,
                        formatter: function() {
                            return getTrimmedName(this.value)
                         }
                    },
                },
            ],
            xAxis: {
                ...highchartBasicConfig.xAxis,
                categories: data.signal_rets_csum.map((ele) => ele.date),
                type: "datetime",
            },
            tooltip: {
                shared: 1,
                useHTML: true,
                formatter: function (e) {
                    if (this.point) { // heatmap
                        const x = this.point.series.xAxis.categories[this.point.x]
                        const y = this.point.series.yAxis.categories[this.point.y]
                        const z = this.point.z
                        return `<div>
                        <div>${x}</div>
                        <div><b>${getTrimmedName(y)}:</b>${z}</div>
                        </div>`
                    } else {
                        const info = (this.points || []).map(ele => ({
                            x: ele.x,
                            y: ele.y,
                            name: ele.series.name,
                            color: ele.color
                        }))
                        if (info.length > 0) {
                            return `<div>
                            <div>${info[0].x}</div>
                            ${info.map(item => "<div style='color: " + item.color + "'>" + item.name + ": " + ( (100*item.y).toFixed(4)) + "%</div>").join("")}
                            </div>`
                        }

                    }
                }
            },
            plotOptions: {
                series: {
                    connectNulls: true,
                    animation: false,
                    turboThreshold: 5000,
                    states: {
                        inactive: {
                            opacity: 1 // fixes issue of other series being faded onHover
                        }
                    }
                },
                heatmap: {
                }
            },
            series: [
                {
                    name: "S&P 500",
                    data: data.signal_rets_csum.map((ele, idx) => [
                        // Date.parse(ele.date.split(" ")[0]),
                        idx,
                        ele["SP"],
                    ]),
                    color: "red",
                    lineWidth: 1,
                    marker: {
                        enabled: 0,
                    },
                },
                {
                    name: "BCOM",
                    data: data.signal_rets_csum.map((ele, idx) => [
                        // Date.parse(ele.date.split(" ")[0]),
                        idx,
                        ele["bcom"],
                    ]),
                    color: "blue",
                    lineWidth: 1,
                    marker: {
                        enabled: 0,
                    },
                },
                {
                    name: "Portfolio",
                    data: data.signal_rets_csum.map((ele, idx) => [
                        idx,
                        ele["portf"],
                    ]),
                    color: COLORMAP.main,
                    lineWidth: 3,
                    marker: {
                        enabled: 0,
                    },
                },
                ...this.state.selectedStrategies.map(sIdx => {
                    // console.log(sIdx, strategylist[sIdx], metaMapping[strategylist[sIdx]]);
                    return {
                        name: strategylist[sIdx],
                        data: data.signal_rets_csum.map((ele, idx) => [
                            idx,
                            ele[ metaMapping[strategylist[sIdx]] + "_signal"],
                        ]),
                        lineWidth: 3,
                        dashStyle: "DashDot",
                        marker: {
                            enabled: 0,
                        },
                        yAxis: 0,
                    };
                }),
                ...strategylist.map((name, x) => {
                    const isSelected = this.state.selectedStrategies.includes(x);
                    return {
                        type: "heatmap",
                        name: name,
                        borderWidth: 1,
                        borderColor: "#aaa",
                        data: data.master_signals.map((ele, y) => ({
                            x: y,
                            y: x,
                            z: ele[name],
                            color: isSelected ? (ele[name] > 0 ? COLORMAP.green : ((ele[name] === 0) ? "#ddd" : COLORMAP.red)) : (ele[name] > 0 ? COLORMAP.green_o : (ele[name] === 0 ? "#ddd" : COLORMAP.red_o)),
                            opacity: 0.4,
                        })),
                        yAxis: 1,
                        events: {
                            click: (e) => {
                                if (meta) {
                                    onSelectStrategyIdx(e.point.y)
                                }
                            
                            }
                        },
                    };
                }),
            ],
        };

        return (
            <div>
                <div className="flexBox">
                    {/* {CSVDownloader({
                        text: "download as csv",
                        data: this.props.data.signal_rets_csum || []
                    })} */}
                </div>
                <HighChartWrapper key={chartConfig.series.length || ""} height="800px" config={chartConfig} />
            </div>
            
        )
    }
}
