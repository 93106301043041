import styled from "styled-components";

export const Layout = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80px 70px calc(100% - 150px);

    .sim_board_wrapper {
        background: white;
        padding: 5px 5px 0;
        border-bottom: 1px solid #ccc;
    }

    .title_wrapper {
        font-family: var(--font-main);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .title {
        font-size: 1.5rem;
        font-weight: 800;
        margin-right: 10px;
        color: var(--color-main);
    }

    .subtitle {
        font-family: "Open Sans";
        font-size: 1.1rem;
        color: #888;
    }

    .btn_wrapper {
        display: flex;
        align-items: center;
        /* padding: 0 10px; */
    }

    .sim_button {
        font-family: Roboto;
        font-size: 0.8em;
        display: grid;
        padding: 10px 10px;
        margin-right: 5px;
    }

    .sim_button:hover {
        cursor: pointer;
        color: rgb(39, 104, 87) !important;
    }

    .sim_button_text {
        margin: auto;
    }
`;

export const ChartWrapper = styled.div`
    display: ${props => (props.isShown === false ? "none" : "grid")};
    position: relative;
    grid-template-rows: 80px calc(100% - 80px);
    grid-template-columns: 100%;
    padding: 20px 25px;
    background: #fff;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    animation: fadeIn 0.2s;
`;

export const StatsWrapper = styled.div`
    animation: fadeIn 0.3s;
    padding: 10px 25px;
    background: #fff;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
`;

export const StatisticsWrapper = styled.div`
    font-family: var(--font-main);

    .stat_wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        position: relative;
    }

    .stat_card {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
        font-size: 1.4rem;
    }

    .stat_card > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
    }

    .stat_card_title {
        word-wrap: nowrap;
        white-space: nowrap;
        color: #666;
        margin-right: 8px;
        font-size: 1rem;
    }

    .stat_card_number {
        color: #000;
    }
`;
