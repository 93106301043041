import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { highchartBasicConfig, COLORMAP } from "../../../config";
import Axios from "axios";
import { api_blp } from "../../../helpers/API";
import moment from "moment";
import { LoadingPage } from "../../../helpers/SimpleComponents";
import { toAusTime } from "../../../helpers/Utils";
import { dailyToCumulative } from "../../../helpers/ChartFunctions";

export default class PositionCardTimeseries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: null,
        };
    }

    componentDidMount() {
        const strategy_name = this.props.strategy_name;
        const security = this.props.security;
        const datefrom = moment().subtract(2, "week").format("YYYY-MM-DD");

        api_blp(strategy_name + "_results", "px_last,returns", datefrom).then((res) => {
            let series = res.data.content.data;
            series.CUMRET = dailyToCumulative(series.RETURNS);
            this.setState({
                series,
            });
        });

        // Axios.all([api_blp(security, "PX_LAST", datefrom), api_blp(strategy_name, "return", datefrom, { cumsum: 1 })]).then((res) => {
        //     const series = res.map((ele) => ele.data.content.data);
        //     this.setState({
        //         series,
        //     });
        // });
    }

    render() {
        const series = this.state.series;
        if (series) {
            let options = {};
            if (!this.props.mini) {
                options = {
                    ...highchartBasicConfig,
                    chart: {
                        ...highchartBasicConfig.chart,
                        height: 150,
                    },
                    legend: { enabled: false },
                    yAxis: [{ title: { text: this.props.security } }, { opposite: true, title: { text: this.props.strategy_name } }],
                    tooltip: { shared: 1 },
                    series: [
                        {
                            name: "price",
                            color: COLORMAP.darkgold,
                            data: series.PX_LAST.map((ele) => [Date.parse(ele[0]), ele[1]]),
                        },
                        {
                            type: "areaspline",
                            name: "return",
                            data: series.CUMRET.map((ele) => [Date.parse(ele[0]), ele[1]]),
                            fillOpacity: 0.2,
                            color: COLORMAP.green,
                            negativeColor: COLORMAP.red,
                            yAxis: 1,
                        },
                        {
                            type: "column",
                            name: "net",
                            data: series.RETURNS.map((ele) => [Date.parse(ele[0]), ele[1]]),
                            color: COLORMAP.green_o,
                            negativeColor: COLORMAP.red_o,
                            yAxis: 1,
                        },
                    ],
                };
            } else {
                options = {
                    ...highchartBasicConfig,
                    chart: {
                        ...highchartBasicConfig.chart,
                        height: 60,
                        width: 100,
                    },
                    tooltip: {
                        animation: false,
                        hideDelay: 0,
                        useHtml: true,
                        outside: true,
                        formatter: function () {
                            return `
                                <span style="margin-right: 4px">${this.y}</span>
                                <span style="color: #888">${toAusTime(this.x / 1000, 3)}</span>
                            `;
                        },
                    },
                    legend: { enabled: false },
                    yAxis: [
                        { title: { text: "" }, labels: { enabled: false } },
                        { opposite: true, title: { text: "" }, labels: { enabled: false } },
                    ],
                    xAxis: { visible: false },
                    series: [
                        {
                            name: "price",
                            color: "#bbb",
                            data: series[0].PX_LAST.map((ele) => [Date.parse(ele[0]), ele[1]]),
                        },
                        {
                            type: "areaspline",
                            name: "return",
                            data: series[1].RETURN.map((ele) => [Date.parse(ele[0]), ele[1]]),
                            fillOpacity: 0.2,
                            color: COLORMAP.green,
                            negativeColor: COLORMAP.red,
                            yAxis: 1,
                        },
                    ],
                };
            }

            return (
                <div>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
            );
        } else {
            return <div>{this.props.mini ? "loading..." : LoadingPage(undefined, "BeatLoader")}</div>;
        }
    }
}
