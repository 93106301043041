import React, { Component } from "react";
import { ChartWrapper } from "../styled/main";
import HeaderWithDownload from "../../text/HeaderWithDownload";
import ReactHighstock from "react-highcharts/ReactHighstock";
import { simChartCommonConfig } from "../Config";
import moment from "moment";
import StateBox from "../components/StateBox";

export default class TabAnnualCumulativeReturn extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onUpdateStats = this.onUpdateStats.bind(this);
    }

    onUpdateStats(newData) {
        // console.log(newData)
        if (this.statebox) {
            this.statebox.onChangePayload(newData);
        }
    }

    getStats(data) {
        let infolist = data.map(ele => {
            let ret = ele.data.slice().reverse()[0];
            let text = ret ? (
                <span>
                    {" "}
                    <i className={ret[1] >= 0 ? "fas fa-caret-up" : "fas fa-caret-down"}></i> {ret[1].toFixed(3) + " %"}
                </span>
            ) : (
                ""
            );
            let value = ret ? ret[1] : null;
            return { label: ele.name, text: text, value: value };
        });
        return infolist;
    }

    render() {
        const chart_title = "Annual Returns";
        const series = this.props.series;

        const getStats = this.getStats;
        const onUpdateStats = this.onUpdateStats;

        const annual_return_infolist = series.map(ele => {
            const year = ele.name;
            let ret = ele.data.slice().reverse()[0];
            let text = ret ? (
                <span>
                    {" "}
                    <i className={ret[1] >= 0 ? "fas fa-caret-up" : "fas fa-caret-down"}></i> {ret[1].toFixed(3) + " %"}
                </span>
            ) : (
                ""
            );
            return { label: year, text: text, value: ret[1] };
        });

        const rebaseFunc = (startDate, endDate, chart) => {
            let newSeries = [];
            for (let i = 0; i < series.length; i++) {
                const data = series[i].data;
                let pivot = data.filter(ele => ele[0] >= Date.parse(startDate));
                let pivotValue = 0;
                let pivotDate = 0;
                if (pivot.length > 0) {
                    pivotDate = pivot[0][0];
                    pivotValue = pivot[0][1];
                }
                let newData = [];
                for (let j = 0; j < data.length; j++) {
                    newData.push([data[j][0], data[j][1] - pivotValue]);
                }
                newSeries.push({ data: newData });
            }

            for (let i = 0; i < chart.series.length; i++) {
                if (newSeries[i]) {
                    chart.series[i].setData(newSeries[i].data);
                }
            }

            // update stats
            onUpdateStats(
                getStats(
                    series.map(ele => ({
                        ...ele,
                        data: ele.data.filter(a => a[0] >= Date.parse(startDate) && a[0] < Date.parse(endDate))
                    }))
                )
            );
        };

        return (
            <div className="ChartWrapper ChartWrapper_loose">
                <div className="title">
                    <HeaderWithDownload title={chart_title} />
                    <StateBox payload={annual_return_infolist} ref={a => (this.statebox = a)} />
                </div>
                <ReactHighstock
                    config={{
                        ...simChartCommonConfig,
                        chart: {
                            ...simChartCommonConfig.chart,
                            events: {
                                load: function(e) {
                                    const startDate = moment(new Date(e.target.xAxis[0].min).toISOString()).format("YYYY-MM-DD");
                                    const endDate = moment(new Date(e.target.xAxis[0].max).toISOString()).format("YYYY-MM-DD");
                                    rebaseFunc(startDate, endDate, e.target);
                                }
                            }
                        },
                        yAxis: {
                            title: { text: "Cumulative P&L" },
                            tickerAmount: 8,
                            crosshair: { width: 1, color: "#aaa", dashStyle: "shortDash" },
                            tickAmount: 8,
                            labels: {
                                format: "{value}%"
                            }
                        },
                        xAxis: {
                            ...simChartCommonConfig.xAxis,
                            events: {
                                afterSetExtremes: function(e) {
                                    const startDate = moment(new Date(e.min).toISOString()).format("YYYY-MM-DD");
                                    const endDate = moment(new Date(e.max).toISOString()).format("YYYY-MM-DD");
                                    rebaseFunc(startDate, endDate, e.target.chart);
                                }
                            }
                        },
                        series: series
                    }}
                />
            </div>
        );
    }
}
