import React, { Component } from "react";

import OverviewPage from "./components/OverviewPage";
import ConfigPage from "./components/ConfigPage";

import styled from "styled-components";

const Layout = styled.div`
    padding: 30px;

    font-family: var(--font-main);

    h3 {
        color: #555;
    }

    .head_toolbar_wrapper {
        margin: 20px 0;
    }
`;

export default class homepage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 0, // 0: overview, 1: config
            selectedDocId: null
        };
        this.switchPage = this.switchPage.bind(this);
    }

    switchPage(paras) {
        const mode = paras["mode"];
        const docid = paras["docid"];
        this.setState({
            mode: mode,
            selectedDocId: docid
        });
    }

    render() {
        const generalFuncs = {
            switchPage: this.switchPage
        };
        return <Layout>{this.state.mode === 0 ? <OverviewPage funcs={generalFuncs} /> : <ConfigPage docid={this.state.selectedDocId} funcs={generalFuncs} />}</Layout>;
    }
}
