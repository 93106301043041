import React, { Component } from "react";
import styled from "styled-components";
import { api_ecocalendar } from "../../helpers/API";
import moment from "moment";
import ImbueTable from "../../components/table/ImbueTable";

const Layout = styled.div`
    padding: 30px;
`;

export default class ForexfactoryCalendar extends Component {
    state = {
        date: moment().format("YYYY-MM-DD"),
        data: [],
        isLoading: 0,
    };

    componentDidMount() {
        this.onUpdate();
    }

    onUpdate = () => {
        this.setState({ isLoading: 1 }, () => {
            api_ecocalendar(this.state.date).then((res) => {
                const data = res.data.data;
                this.setState({
                    isLoading: 0,
                    data,
                });
            });
        });
    };

    onShiftDate = (days) => {
        const newDate = moment(this.state.date).add(days, "days").format("YYYY-MM-DD");
        this.setState({ date: newDate }, () => {
            this.onUpdate();
        });
    };

    onPrevDate = () => {
        this.onShiftDate(-1);
    };

    onNextDate = () => {
        this.onShiftDate(1);
    };

    render() {
        const tableConfig = {
            data: this.state.data,
            sorted: ["datetime", "currency", "impact", "event", "actual", "forecat", "previous"],
        };
        const dow = moment(this.state.date).format("dddd");

        return (
            <Layout>
                <div>
                    <h3>
                        {this.state.date} | {dow}
                    </h3>
                </div>
                <div className="ImbueSelectsWrapper">
                    <div className="ImbueSelectItem CircleBtn CircleBtnLg" onClick={this.onPrevDate}>
                        <i className="fas fa-chevron-left"></i>
                    </div>
                    <div className="ImbueSelectItem">
                        <div className="label">date:</div>
                        <input className="ImbueInput" type="text" value={this.state.date} onChange={(e) => this.setState({ date: e.target.value })} />
                    </div>
                    <div className="ImbueSelectItem CircleBtn CircleBtnLg" onClick={this.onNextDate}>
                        <i className="fas fa-chevron-right"></i>
                    </div>
                    <div className="ImbueSelectItem CircleBtn CircleBtnLg" onClick={this.onUpdate}>
                        <i className="fas fa-play"></i>
                    </div>
                </div>
                <div>
                    {this.state.isLoading ? (
                        <div className="LoadingText">Loading...</div>
                    ) : this.state.data.length < 1 ? (
                        <div className="ErrorText">No Data Available!</div>
                    ) : (
                        <ImbueTable config={tableConfig} />
                    )}
                </div>
            </Layout>
        );
    }
}
