import React from 'react'
import QuoteBoard from './QuoteBoard'

export default class DefaultBoard extends React.Component {
    
    componentDidMount() {
        document.title = "Quote Board";
    }
    
    render() {
        return (
            <div>
                <QuoteBoard
                    roots={["ZS", "ZM", "ZL", "ZC", "ZW", "KE", "MWE", 
                            "GF", "LE", "HE", "LBR",
                            "CL", "NG", "RB", "HO",
                            "GC", "SI", "HG", "PL",
                            "DX", "6E", "6J", "CNH", "6B", "6C", "6A", "6L", "6M",
                            "SR3", "ZT", "Z3N", "ZF", "ZN", "ZB",
                            "ES", "YM", "NQ", "RTY",
                            "BTC", "ETH"
                            ]} />
            </div>
        )
    }
}