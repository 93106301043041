import { highchartBasicConfig, COLORMAP } from "../../../config";

export const heatmapConfig = (paras, callback) => {
    let data = [];
    for (let i = 0; i < paras.data.length; i++) {
        for (let j = 0; j < paras.data[i].length; j++) {
            data.push([i, j, paras.data[i][j]]);
        }
    }
    return {
        title: highchartBasicConfig.title,
        credits: highchartBasicConfig.credits,
        chart: {
            type: "heatmap",
            backgroundColor: "transparent",
            style: {
                fontFamily: "roboto",
            },
            height: 300 + 0.6 * data.length,
            animation: false,
        },
        tooltip: {
            useHtml: true,
            outside: true,
            formatter: function (e) {
                let xLabel = this.series.xAxis.categories[this.point.x];
                let yLabel = this.series.yAxis.categories[this.point.y];
                let value = this.point.value;
                let textColor = value >= 0 ? COLORMAP.green : COLORMAP.red;
                const HTML = `
                <div style="font-family: Lato">
                    <span style="color: #444;">${xLabel} (Click to show more)</span><br>
                    <span style="color: ${textColor}; font-size: 1.5rem;">${yLabel}: ${value.toFixed(3)}</span>
                </div>
                `;
                return HTML;
            },
        },
        xAxis: {
            categories: paras.x,
        },
        yAxis: {
            categories: paras.y,
            title: null,
            reversed: true,
        },
        colorAxis: {
            min: -1,
            max: 1,
            stops: [
                [0, "#a10000"],
                [0.3, "#ffcfcf"],
                [0.5, "#FFFFFF"],
                [0.7, "#d6ffd6"],
                [1, "#005200"],
            ],
        },
        legend: {
            align: "right",
            layout: "vertical",
            margin: 0,
            verticalAlign: "top",
            y: 25,
            symbolHeight: 280,
        },
        series: [
            {
                name: "Correlation",
                borderWidth: 1,
                data: data,
            },
        ],
        plotOptions: {
            heatmap: {
                events: {
                    click: function (event) {
                        let pivotX = paras.x[event.point.x];
                        const treemapdata = [
                            {
                                name: pivotX,
                                id: pivotX,
                            },
                            ...paras.data[event.point.x]
                                .map((item, idx) => ({
                                    name: paras.y[idx],
                                    parent: pivotX,
                                    value: Math.abs(item),
                                    colorValue: item,
                                }))
                                .filter((item) => item.name !== pivotX),
                        ];
                        callback(paras.x[event.point.x], paras.y[event.point.y], treemapdata);
                    },
                },
            },
        },
    };
};
