import React, { useState, useEffect, useRef } from 'react'
import styled from "styled-components";
import "./style.css";
import { GLOBALSTYLED } from "../../config";
import { LoadingPage } from "../../helpers/SimpleComponents";
import HighStockWrapper from '../../components/chart/HighStockWrapper';

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)`
    .chartWrapper {
        background: white;
        padding: 20px;
        box-shadow: var(--boxshadow-aws);
    }
    .fullScreenImgWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
    }
    .fullScreenImgWrapper img {
    }
`;


let firstRun = true;
let cacheDone = false;

// const wsURL = "wss://contango.net:9523/" 
const wsURL = "wss://contango.net:9522/"  // testing version
// const wsURLbackup = "wss://contango.net:9523/"

let WS;
// let wsUnmount;
let ws_message_chart_max  = 0;

export default function LiveBidAskAverages(props) {

    const [state, setState] = useState();
    const chartRef = useRef({});

    let data = [];
   
    // called when props are changed
    useEffect(() => {
        if (firstRun) {
            firstRun = false;
            initWS(wsURL);
            // wsUnmount = false;
        }
        return () => {
            if (WS.readyState === WebSocket.OPEN) {
                WS.close();
            }
            firstRun = true;
            cacheDone = false;
            setState();
        };
    }, [props.root]);


    // called when component default unmounts
    useEffect(() => {
        return () => {
            // console.log("Unmount!")
            // wsUnmount = true;
            if (WS.readyState === WebSocket.OPEN) {
                WS.close();
            }
            firstRun = true;
            setState();
        }
    }, [])


    function initWS() {
        console.log("init for " + props.root)
        if (!WS || WS.readyState !== WebSocket.OPEN) {
            console.log("ws OPEN");
            // reset  chart_max
            ws_message_chart_max  = 0;
            // at this time cache must not be done
            cacheDone = false;
            
            // WS = new WebSocket(url);
            WS = new WebSocket(wsURL); // testing intra-minute data 
            WS.onmessage = function(evt) { onMessage(evt) };
            WS.onclose = function() { onClose() };
            WS.onopen = () => { WS.send(`["Date::Dashboard",{"rel":"${props.root}"}]`) } // send init message

        }
    }


    // msg format : [ 0: timestamp, 1: bid-ask count, 2: daily avg bid-ask count, 3: vwap_diff, 
    //                4: vwap_diff_% , 5: 1month bid-ask avg, 6: 1month bid-ask stddev, 
    //                7: 1month vwap_diff avg, 8: 1month vwapp_diff ]
    function onMessage(evt) {
        const curMsgs = JSON.parse(evt.data);

        if (cacheDone) { // live update messages

            curMsgs.forEach((msg) => {
                if (chartRef.current) {
                    let vwap = chartRef.current.chart.series[0];
                    let vwapAvg = chartRef.current.chart.series[1];
                    let vwapStd = chartRef.current.chart.series[2];
                    let vwapStd2 = chartRef.current.chart.series[3];

                    let bidAsk = chartRef.current.chart.series[4];
                    let bidAskAvg = chartRef.current.chart.series[5];
                    let bidAskStd = chartRef.current.chart.series[6];
                    let bidAskStd2 = chartRef.current.chart.series[7];


                    if (msg[0] === ws_message_chart_max) { // update same minute point
                        // console.log("replacing last point at", ws_message_chart_max)
                        // console.table(msg);

                        // BID-ASK
                        let yBidAsk = bidAsk.yData;
                        const xBidAsk = bidAsk.xData;
                        yBidAsk[xBidAsk.length-1] = msg[1];
                        // BID-ASK AVG
                        let yBidAskAvg = bidAskAvg.yData;
                        const xBidAskAvg = bidAskAvg.xData;
                        yBidAskAvg[xBidAskAvg.length-1] = msg[2];
                        // VWAP
                        let yVwap = vwap.yData;
                        const xVwap = vwap.xData;
                        yVwap[xVwap.length-1] = msg[3];
                        // VWAP AVG
                        let yVwapAvg = vwapAvg.yData;
                        const xVwapAvg = vwapAvg.xData;
                        yVwapAvg[xVwapAvg.length-1] = msg[7];

                        let temp0 = [];
                        let temp1 = [];
                        let temp2 = [];
                        let temp3 = [];
                        // TODO: assert all lengths are equal
                        for (let i = 0; i < xBidAsk.length; i++) {
                            temp0.push([xBidAsk[i], yBidAsk[i]]); // Bid-Ask
                            temp1.push([xBidAskAvg[i], yBidAskAvg[i]]); // Bid-Ask Avg
                            temp2.push([xVwap[i], yVwap[i]]); // Vwap
                            temp3.push([xVwapAvg[i], yVwapAvg[i]]); // Vwap Avg
                        }
                        // update each series' data, no need to update constant stddev values
                        bidAsk.setData(temp0);
                        bidAskAvg.setData(temp1);
                        vwap.setData(temp2);
                        vwapAvg.setData(temp3);
                    } else { // add new minute point
                        // console.log("adding new point after", ws_message_chart_max, "at", msg[0]);
                        // console.table(msg);
                        // ChartRef1 : VWAP
                        vwap.addPoint([msg[0], msg[3]], false);
                        vwapAvg.addPoint([msg[0], msg[7], false]);
                        vwapStd2.addPoint([msg[0], msg[7]-(2* msg[8]), msg[7]+(2* msg[8])], false);
                        vwapStd.addPoint([msg[0], msg[7]-(msg[8]), msg[7]+(msg[8])], false);
                        // ChartRef2 : BID-ASK
                        bidAsk.addPoint([msg[0], msg[1]], false);
                        bidAskAvg.addPoint([msg[0], msg[2]], false);
                        bidAskStd2.addPoint([msg[0], msg[5]-(2* msg[6]), msg[5]+(2* msg[6])], false);
                        bidAskStd.addPoint([msg[0], msg[5]-(msg[6]), msg[5]+(msg[6])], false);

                        ws_message_chart_max = msg[0]; // update chart max to newest timestamp that was just added
                    }
                }
            });
            // Trigger chart redraw outside of loop
            if (chartRef.current) chartRef.current.chart.redraw()
        } else {
            curMsgs.forEach((msg) => {
                if (msg === "X") {
                    cacheDone = true;
                    setState(data);
                } else {
                    if (cacheDone) {
                        // ws_message_queue.push(msg);
                    } else {
                        data.push(msg);
                        ws_message_chart_max = msg[0];
                    }
                }
            });
        }
    }


    function onClose() {
        console.log("ws closed.");
        // TODO: handle reconnection with backup url
        // if (!wsUnmount) {
        //     console.log("Web Socket disconnected. retrying in 10 seconds...");
        //     setTimeout(function() {
        //         initWS(wsURL);
        //     }, 10*1000);
        // }
    }


    // VWAP
    function getChartConfig() {
        const res = {
            chart: {
                // height: (9 / 16 * 40) + '%', // 16:9 ratio @ 40%
                animation: false,
                events: {
                    load: function () {
                        chartRef.current = this;
                    } 
                }
            },
            title: {
                text: `Intraday VWAP & Bids-Asks`
            },
            legend: {
                enabled: true,
            },
            navigator: {
                // enabled: false
            },
            scrollbar: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            rangeSelector: {
                enabled: true,
                selected: 3,
                buttons: [
                    { type: "all", text: "ALL" },
                    { type: "hour", count: 12, text: "12H" },
                    { type: "hour", count: 6, text: "6H" },
                    { type: "hour", count: 3, text: "3H" },
                    { type: "hour", count: 1, text: "1H" },
                ],
                inputDateFormat: "%Y-%m-%d",
                setInputEnabled: false, // known highcharts bug with text overlap
            },
            plotOptions: { 
                series: {
                    animation: false,
                    pointPadding: 0,
                    groupPadding: 0.05,
                    connectNulls: true
                },
            },
            xAxis: {
                labels: {
                    format: '{value:%m/%e | %H:%M}'
                },
            },
            yAxis: [
                {
                    title: {
                        text: 'VWAP',
                    },
                    // opposite: false,
                    showEmpty: false,
                    offset: 30,
                    height: "50%"
                },
                {
                    title: {
                        text: 'bid/ask diff',
                    },
                    showEmpty: false,
                    offset: 30,
                    top: "50%",
                    height: "50%"

                },
            ],
            series: [
                {
                    name: 'vwap_diff',
                    data: state.map((ele) => [ele[0], ele[3]]),
                    color: "rgb(230,159,0)", // orange
                    lineWidth: 2.5,
                    yAxis: 0,
                    zIndex: 3,
                },
                {
                    name: 'avg vwap_diff',
                    data: state.map((ele) => [ele[0], ele[7]]),
                    color: "black",
                    lineWidth: 2,
                    yAxis: 0,
                    zIndex: 2
                },
                {
                    name: 'vwap_diff σ',
                    type: 'arearange',
                    data: state.map((ele) => [ ele[0], ele[7] - (ele[8]), ele[7] + (ele[8]) ]),
                    color: 'darkgray',
                    opacity: 0.25,
                    yAxis: 0,
                    zIndex: 1,
                    enableMouseTracking: false
                }, 
                {
                    name: 'vwap_diff 2σ',
                    type: 'arearange',
                    data: state.map((ele) => [ ele[0], ele[7] - (2* ele[8]), ele[7] + (2* ele[8]) ]),
                    color: 'gray',
                    opacity: 0.25,
                    yAxis: 0,
                    zIndex: 0,
                    enableMouseTracking: false
                },
                {
                    name: 'bid-ask',
                    data: state.map((ele) => [ele[0], ele[1]]),
                    color: 'rgb(86,180,233)', // sky blue
                    lineWidth: 2.5,
                    yAxis: 1,
                    zIndex: 3
                },
                {
                    name: 'avg bid-ask',
                    data: state.map((ele) => [ele[0], ele[2]]),
                    color: "black",
                    lineWidth: 2,
                    yAxis: 1,
                    zIndex: 2,
                },      
                {
                    name: 'bid-ask σ',
                    type: 'arearange',
                    data: state.map((ele) => [ ele[0], ele[5] - (ele[6]), ele[5] + (ele[6]) ]),
                    color: 'darkgray',
                    opacity: 0.25,
                    yAxis: 1,
                    zIndex: 1,
                    enableMouseTracking: false
                }, 
                {
                    name: 'bid-ask 2σ',
                    type: 'arearange',
                    data: state.map((ele) => [ ele[0], ele[5] - (2* ele[6]), ele[5] + (2* ele[6]) ]),
                    color: 'gray',
                    opacity: 0.25,
                    yAxis: 1,
                    zIndex: 0,
                    enableMouseTracking: false
                },
            ],
            tooltip: {
                xDateFormat: "%a, %b %e, '%y | %H:%M", // Thu, Dec 16, 21
                valueDecimals: 2,
                split: true,
                shared: true,
            },
        }
        return res;
    }
    

    return (
        <div>
            <div style={{marginLeft: "50px"}}>
                {/* <p style={{margin: "0px"}}>cache done: {cacheDone ? "✅" : "❌"}</p> */}
            </div>
            {cacheDone && state && 
                <Layout style={{marginTop: "0px"}}>
                    <div className="container">
                        <div className="row">
                            <div style={{width: "100%"}}>
                                <HighStockWrapper innerRef={chartRef} config={getChartConfig()}/>
                            </div>
                            {/* <div style={{width: "50%"}}>
                                <HighStockWrapper innerRef={chartRef2} config={getChartConfig2()}/>
                            </div>                         */}
                        </div>
                    </div>
                </Layout>
            }
            {!cacheDone &&
                LoadingPage()
            }
        </div>
    );
}
