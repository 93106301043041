import React, { Component, Fragment } from "react";
import styled from "styled-components";

const Layout = styled.div`
    padding: 0 40px;

    height: 100%;
    overflow: auto;

    p {
        font-family: var(--font-main);
        line-height: 2rem;
        color: #666;
    }

    .p_title {
        font-family: var(--font-main);
        letter-spacing: 1px;
    }

    .header {
        margin: 30px 0;
        font-family: var(--font-main);
        font-size: 4rem;
    }

    .Paragraph {
        margin: 1rem 0;
        font-size: 1.4rem;
    }
`;

const SectionText = props => {
    return (
        <Fragment>
            <div className="fontSize__1p5rem fontWeight__fat fontColor__imbue_gold_dark fontCase__upper p_title">{props.text}</div>
            <hr style={{ margin: "2px 0" }} />
        </Fragment>
    );
};

const Paragraph = props => {
    return (
        <div style={{ fontWeight: props.Bold ? 800 : undefined }} className="Paragraph">
            {props.text}
        </div>
    );
};

class Instruction extends Component {
    render() {
        return (
            <Layout>
                <div style={{ animation: "textFloat 1s" }} className="header fontColor__imbue_green">
                    Welcome to Imbue Artificial Intelligence Lab !{" "}
                </div>

                {/* <p style={{ animation: "textFloat 1s", animationDelay: ".1s", animationFillMode: "forwards", opacity: "0" }}>
                    <SectionText text="introduction" />
                    <div>
                        {
                            [
                                "These are exciting times. Artificial Intelligence (AI) is the next frontier for financial markets that will transform the investment landscape.",
                                "The AI Lab is a unique tool that allows anyone to begin crafting AI trading strategies, without the need to know how to code.",
                                "To begin exploring, you will simply select which parameters you would like to investigate and then click the play option."].map(para => {
                                    return <Paragraph text={para} />
                                })
                        }

                    </div>
                </p> */}

                {/* <p style={{ animation: "textFloat 1s", aneimationDelay: ".1s", animationFillMode: "forwards", opacity: "0" }}>
                    <SectionText text="agents" />
                    {
                        <Fragment>
                            <p>
                                <SubTitle text="Agent Jarvis" />
                                {
                                    "Jarvis applies reinforcement learning, a concept recently brought to fame by DeepMind, who saw an algorithm beat the best player in the world, in the board game of Chinese Go, by 4 games to 1. Jarvis builds on this concept by first starting out to trade randomly. Once the algorithm has enough memory, it starts to correct its behavior from our proprietary reward system, in short, it rewards itself for making good and profitable trades and receives a negative reward for losing trades. Given enough run time, Jarvis learns to trade.".split("\n").map(para => {
                                        return <Paragraph text={para} />
                                    })
                                }
                            </p>

                            <p>
                                <SubTitle text="Agent Deep Forest" />
                                {
                                    "This agent is based on supervised learning, as supposed to Jarvis, which is under the category of Reinforcement Learning. The advantage of Deep Forest is that it creates rules based on what it has seen in the past. It is upfront given examples of in time and the action. Deep Forest doesn’t need to trade randomly, as it is told what to do from the first episode. The clear advantage of this is that it is much faster when compared to Jarvis. \nOk so what is so special about Deep Forest? The agent, uses a voting system, where with each episode, it generates a new randomly created forest. At the episode end, the model makes a number of forecasts and averages the result, that is from each forest that it has created. In other word, after say 10 episodes, there are 10 forests that each have a vote. It has been shown that when doing so, the out of sample results stabalise and become less predictable. This is mostly true if you look at the trades (in sample and out of sample). There is very little change between each episode (this is not the case with Jarvis). \nAnother advantage to Deep Forest is that it has the ability to find important factors/features natively. However, it is also it’s weakness, it is only as good as the data that you show it. In comparison to Jarvis, the neural network that is the brain of Jarvis can potentially find important interactions between features, without being it told.".split("\n").map(para => {
                                        return <Paragraph text={para} />
                                    })
                                }
                                <Paragraph text="Here is a summary table:" Bold />
                                <table style={{
                                    borderCollapse: 'collapse',
                                    border: "1px solid #aaa",
                                    margin: "10px 0",
                                }}>
                                    {
                                        [
                                            ["", "Jarvis", "Deep Forest"],
                                            ["Category", "Reinforcement Learner", "Supervised learner"],
                                            ["Speed", "Slow", "Very Fast"],
                                            ["Feature Selection", "Yes", "Yes"],
                                            ["Feature Engineer", "Yes", "No"],
                                            ["Stability", "Questionable", "Yes (given the right data)"],
                                        ].map(row => {
                                            return (
                                                <tr style={{
                                                    borderBottom: "1px solid #aaa"
                                                }}>
                                                    {
                                                        row.map(col => {
                                                            return (
                                                                <td style={{ padding: "5px 10px", fontSize: "1.2rem" }}>{col}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }

                                </table>
                            </p>


                            <p>
                                <SubTitle text="Agent Bagging" />
                                {
                                    "The bagging algorithm aims to take element of randomness to achieve a better fit over the data. It does so by choosing random features of some size smaller than the entire set of possible features and a decision tree is trained. This happens during each episode, following this procedure, an average vote is taken to compute the actual signal. Overtime, the algorithm will converge holding in memory a number of decision trees that have been trained on random sets of features and during which time it has hopefully learned the most important features while ignoring noise and irrelevant data.".split("\n").map(para => {
                                        return <Paragraph text={para} />
                                    })
                                }
                            </p>

                            <p>
                                <SubTitle text="Agent Meta" />
                                {
                                    "Furthermore, the Meta agent adds an additional final step to the bagging algorithm by learning from the votes themselves, rather than computing an average to generate a signal. The idea here is to have a model learn which episodes contribute most to the signal. The drawback of this approach is that it may take many more episodes to achieve good performance.".split("\n").map(para => {
                                        return <Paragraph text={para} />
                                    })
                                }
                            </p>
                        </Fragment>
                    }
                </p>

                <p style={{ animation: "textFloat 1s", animationDelay: ".2s", animationFillMode: "forwards", opacity: "0" }}>
                    <SectionText text="parameters" />
                    {
                        [
                            ["AgentID", "Select the agent you would like to use to test. The AI agents have been created by Imbue’s team of quantitative analysts. In time, significant value will be derived by testing numerous agents that are rewarded and punished in different ways to learn how to trade financial markets."],
                            ["Securities", "This is a list of securities that you can request the agent to trade and learn on"],
                            ["Episodes", "An episode is one full training event. The power of AI is that by training repeatedly on hundreds or thousands of episodes (training data) it can learn to identify patterns in data. Generally, the more episodes that the agent is exposed to the better the performance should be to a point of diminishing returns."],
                            ["Data", "Click to include different data sets for the AI agent to learn from."],
                            ["Trade Duration", "Select the number of days each trade will be held for."],
                        ]
                            .map(item => {
                                return (
                                    <p>
                                        <SubTitle text={item[0]} />
                                        <Paragraph text={item[1]} />
                                    </p>
                                )
                            })
                    }
                </p>

                <p style={{ animation: "textFloat 1s", animationDelay: ".3s", animationFillMode: "forwards", opacity: "0" }}>
                    <SectionText text="usage" />
                    <Paragraph text="Select parameters and press 'Play' button" />
                </p>

                <p style={{ animation: "textFloat 1s", animationDelay: ".4s", animationFillMode: "forwards", opacity: "0" }}>
                    <SectionText text="common questions" />
                    <p>
                        <SubTitle text="How does the AI agent work?" />
                        <Paragraph text="Key aspect to self-learning is that the agent must observe the environment, otherwise known as a state, and given the state, the algorithm will choose an action based on what it has learned. The state in our example could be some data that we have observed historically. A fundamental driver to the success of the algorithm is in the data that is fed to the agent." />
                    </p>
                    <p>
                        <SubTitle text="How do I do determine if I created a robust, reliable and profitable trading strategy that is not suffering from overfitting?" />
                        <Paragraph text="As each episode progresses, the key to identifying a robust agent, is when the profit and loss curve stabalises, this is usually a sign that the model has learned to generalise the data quite well. It is also important to note that choosing too many data factors will be more prone to over fitting and also more time consuming." />
                        <Paragraph text="One of the best ways to evaluate the model is to eye the way it is trying to trade. We have gone to great lengths to make this as visual as possible. Is it only just buying or selling? Is it creative and interesting? Are some of the questions to ask before deploying an agent." />
                    </p>

                </p> */}
            </Layout>
        );
    }
}

export default Instruction;
