import React, { Component, Fragment } from "react";
import MainBoard from "../../layout/MainBoard";
import BloombergSearch from "./BloombergSearch";
import DataQuery from "./DataQuery";
import FactorSearch from "./FactorSearch";
import ForexfactoryCalendar from "./ForexfactoryCalendar";

export default class DataCenter__mainboard extends Component {

    componentDidMount() {
        document.title = "Data Center";
    }
    
    render() {
        const path = this.props.match.path;
        const list = [
            {
                name: "Data Query",
                path: path + "/data_query",
                component: DataQuery,
            },
            {
                name: "Economic Calendar",
                path: path + "/eco_calendar",
                component: ForexfactoryCalendar,
            },
            {
                name: "Blp Search",
                path: path + "/blp_search",
                component: BloombergSearch,
            },
            {
                name: "Factor Search",
                path: path + "/factor_search",
                component: FactorSearch,
            },
        ];
        return (
            <Fragment>
                <MainBoard title="Data Center" list={list} />
            </Fragment>
        );
    }
}
