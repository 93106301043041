/**
 * @author      Claude Chen
 * @company     Imbue Capital Pty Ltd
 * @created     2018
 */

import React, { Component } from "react";
import styled from "styled-components";
import { api_couchdb } from "../helpers/API";
import moment from "moment";

const Layout = styled.div`
    grid-area: subbar;
    font-family: "Roboto";
    display: flex;
    overflow: hidden;
    z-index: 998;
    background-color: #1d3c34;
    color: white;

    i {
        font-size: 1.8rem;
    }

    .news-wrapper {
        margin-top: 3px;
        display: flex;
        padding: 6px 5px 5px;
        text-transform: uppercase;
        letter-spacing: 0.12rem;
        overflow: hidden;
        white-space: nowrap;
    }

    .news-source {
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-radius: 4px;
        background-color: white;
        color: #616161;
    }

    .news-text {
        overflow: hidden;
        white-space: nowrap;
    }

    .news-published-time {
        color: #ef5350;
        margin-left: 10px;
    }
`;

function storeIndex(index) {
    localStorage.setItem("articleIndex", index);
}

function loadIndex() {
    let articleIndex = localStorage.getItem("articleIndex");
    if (articleIndex === undefined || articleIndex === null) {
        articleIndex = 0;
    }
    return parseInt(articleIndex);
}

class TextStrip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            index: loadIndex(),
            isChanged: false,
        };
        this.FLIP_INTERVAL = 1000 * 8; // Interval for flipping a new News. 1000 * second
        this.FLIP_DURATION = 1000 * 1; // Duration of flipping animation
        this.MAX_NEWS_COUNT = 180;
        this.requestNewsFromDB = this.requestNewsFromDB.bind(this);
        this.changeArticleIndex = this.changeArticleIndex.bind(this);
    }

    componentDidMount() {
        // this.requestNewsFromDB();
        // setInterval(() => this.changeArticleIndex(), this.FLIP_INTERVAL);
    }

    changeArticleIndex() {
        /* Handle changing article index after every 8s */
        this.setState({ isChanged: true }, () => {
            let nextIndex = null;
            if (this.state.index === this.state.articles.length - 1 && this.state.index > 0) {
                // Loop Through all current articles
                nextIndex = 0;
                this.setState({ index: nextIndex }, () => {
                    setTimeout(() => {
                        this.setState({ isChanged: false });
                    }, this.FLIP_DURATION);
                });
                storeIndex(nextIndex);
            } else {
                nextIndex = this.state.index;
                if (nextIndex > this.MAX_NEWS_COUNT - 2) {
                    nextIndex = 0;
                } else {
                    nextIndex += 1;
                }
                setTimeout(() => {
                    this.setState({ isChanged: false });
                }, this.FLIP_DURATION);
                setTimeout(() => {
                    this.setState({ index: nextIndex });
                }, 200);
                storeIndex(nextIndex);
            }
        });
    }

    requestNewsFromDB() {
        /* Request latest 180 articles from Imbue Database */
        api_couchdb("news", "latest_200").then((res) => {
            const articles = res.data.content.list;
            articles.sort(function (A, B) {
                return moment(A.published_at) - moment(B.published_at);
            });
            this.setState({ articles });
        });
    }

    render() {
        const { articles, index, isChanged } = this.state;
        const info =
            "article index: " + index + " published: " + (articles.length > 0 ? articles[index].published_at : "");
        const isArticleValid = articles.length > 1 && articles[index];
        return (
            <Layout>
                <div className="news-wrapper">
                    {/* <i title={info} className="fas fa-rss"></i> */}
                    {isArticleValid ? (
                        <div style={{ animation: "fadeIn 1.5s" }}>
                            <div style={{ animation: isChanged ? "textFlip .5s" : undefined }}>
                                <span className="news-source news-text">{articles[index].news_source}</span>
                                <span className="news-text">
                                    <a
                                        style={{ color: "white" }}
                                        href={articles[index].url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {`${articles[index].title} (${articles[index].published_at})`}
                                    </a>
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div style={{ marginLeft: "10px", color: "#aaa" }}></div>
                    )}
                </div>
            </Layout>
        );
    }
}

export default TextStrip;
