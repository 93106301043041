import React, { Component } from "react";
import ProgressiveImage from "react-progressive-image";
import styled from "styled-components";
import InputRange from "react-input-range";
import ImbueTable from "../table/ImbueTable";
import imgPlaceholder from "../../img/img_placeholder.jpg";
import imgPlaceholderError from "../../img/img_placeholder_error.jpg";
import ImbueSelect from "../../components/input/ImbueSelect";
import { api_couchdb } from "../../helpers/API";
import moment from "moment";

import "react-input-range/lib/css/index.css";
import { CSVLink } from "react-csv";
const Layout = styled.div`
    font-family: var(--font-main);
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80px calc(100% - 80px);

    .selectWrapper {
        display: flex;
        .ImbueSelectWrapper {
            margin-right: 10px;
        }
    }

    .waiting_bar {
        color: var(--color-main);
        font-size: 2em;
        text-align: center;
        width: 100%;
        margin-top: 100px;
    }

    .spinner_wrapper {
        padding: 20px;
    }

    .spinner_wrapper > div {
        margin: auto;
    }

    /* Close Button */
    .toogle_btn {
        font-size: 1.1rem;
        padding: 5px;
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid #eee;
    }

    .toogle_btn:hover {
        background: rgba(230, 230, 230, 1);
        cursor: pointer;
    }

    .period_selector {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        /* grid-template-rows: 100%; */
        margin: 10px 0;
    }

    /* Period tab */
    .period_tab {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #777;
        font-size: 1.1rem;
        text-align: center;
        transition: all 0.2s;
        border-radius: 8px;
        padding: 4px 5px;
        margin-right: 3px;
    }

    .period_tab:hover {
        cursor: pointer;
        color: #000;
    }
`;

const GridMapping = (input) => ({
    1: "100%",
    2: "50% 50%",
    3: "33% 34% 33%",
    4: "25% 25% 25% 25%",
    5: "20% 20% 20% 20% 20%"
}[input])

const ImagesLayout = styled.div`
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
    grid-gap: 2px;
    width: 100%;

    .image_grid {
        width:100%;
        display: grid;
        grid-template-columns: ${(props) => GridMapping(props.gridType)};
    }
`;

const SelectionContainer = styled.div`
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    padding: 0 20px;
    .innerContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
`;

const InputRangeWrapper = styled.div`
    margin-left: 10px;
    width: 100px;
    font-size: 1.2rem;
    z-index: 99999;
`;

const ImageContainer = styled.div`
    padding: 20px 25px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: white;
    margin: 15px;
    animation: fadeIn 0.2s;
`;

const InfoBoard = styled.div`
    text-align: left;
    margin: 5px 0 15px;
`;

const ImageBoard = styled.div`
    padding: 5px;
`;

function getOptions(index) {
    let types = Array.from(
        new Set(
            index.map((ele) => {
                return ele.Type;
            })
        )
    );
    let typeOptions = types.map((ele) => {
        return { label: ele, value: ele };
    });
    let geos = Array.from(
        new Set(
            index.map((ele) => {
                return ele.Geography;
            })
        )
    );
    let geoOptions = geos.map((ele) => {
        return { label: ele, value: ele };
    });
    return {
        typeOptions,
        geoOptions,
    };
}

// ===== Helper ======
function urlParser(rawUrl) {
    // Regex
    const rules = {
        sequenceReplace: function (rawUrl) {
            let re = /{seq:(.+)-(.+)}/g;
            let text = rawUrl;
            let match = re.exec(text);
            if (match) {
                let seqOld = parseInt(match[1]);
                let dateOld = match[2];
                let momentNow = moment();
                let momentBefore = moment(dateOld);
                let momentDiff = momentNow.diff(momentBefore, "days");
                let seqToday = momentDiff + seqOld - 2;
                let newUrl = text.replace(re, seqToday.toString());
                return newUrl;
            } else {
                return rawUrl;
            }
        },
        freqReplace: function (rawUrl) {
            let re = /{freq:(.+),(.+)}/g;
            let text = rawUrl;
            let match = re.exec(text);
            if (match) {
                let pivotDate = match[1];
                let freq = parseInt(match[2]);
                let momentNow = moment();
                let momentBefore = moment(pivotDate);
                let momentDiff = momentNow.diff(momentBefore, "days");
                let gapScale = Math.floor(momentDiff / freq);
                let gapDelay = 2;
                let daysGap = freq * (gapScale - gapDelay);
                let momentAfter = momentBefore.add(daysGap, "days");
                let momentAfterFomatted = momentAfter.format("YYYYMM_DD");
                let newUrl = text.replace(re, momentAfterFomatted);
                return newUrl;
            } else {
                return rawUrl;
            }
        },
        monthlyReplace: function (rawUrl) {
            let re = /{monthly:([-|]\d)}/g;
            let text = rawUrl;
            let match = re.exec(text);
            if (match) {
                let monthOffset = parseInt(match[1]);
                let pivotMonth = moment().month() + 1 + monthOffset;
                let pivotYear = pivotMonth === 0 ? moment().year() - 1 : moment().year();
                pivotMonth = pivotMonth === 0 ? 12 : pivotMonth;
                let dateStr = moment(pivotYear + "-" + pivotMonth + "-01")
                    .add(-1, "days")
                    .format("YYYYMM_DD");
                let newUrl = text.replace(re, dateStr);
                return newUrl;
            } else {
                return rawUrl;
            }
        },
        yyyymmdd: function (rawUrl) {
            let re = /{yyyymmdd:(.+)}/g;
            let text = rawUrl;
            let match = re.exec(text);
            if (match) {
                let dayoffset = parseInt(match[1]);
                let pivotDate = moment();
                pivotDate = pivotDate.add(dayoffset, "days");
                let newUrl = text.replace(re, pivotDate.format("YYYYMMDD"));
                return newUrl;
            } else {
                return rawUrl;
            }
        },
    };
    rawUrl = rules.sequenceReplace(rawUrl);
    rawUrl = rules.freqReplace(rawUrl);
    rawUrl = rules.monthlyReplace(rawUrl);
    rawUrl = rules.yyyymmdd(rawUrl);
    return rawUrl;
}

export default class WeatherModelsDashboard extends Component {
    constructor(props) {
        super(props);
        const initGridType = window.localStorage.getItem("_grid")
            ? parseInt(window.localStorage.getItem("_grid"))
            : window.innerWidth < 700
            ? 1
            : window.innerWidth < 1024
            ? 2
            : 3;
        this.state = {
            datatype: "",
            typeOptionsRange: [],
            geoOptionsRange: [],
            selectedTypeOption: "",
            selectedGeoOption: "",
            selectedIndex: [],
            gridType: initGridType,
            annotation: "",
        };
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleGeoChange = this.handleGeoChange.bind(this);
        this.onSwitchView = this.onSwitchView.bind(this);
    }

    componentDidMount() {
        const indexName = this.props.indexName;
        this.setState({ annotation: "loading meta information..." });
        api_couchdb("imbuecloud_index_tables", indexName).then((res) => {
            const index = res.data.content.index;
            let optionsDict = getOptions(index);
            this.setState(
                {
                    index: index,
                    options: optionsDict,
                    annotation: "",
                },
                () => {
                    this.handleGeoChange(optionsDict.geoOptions[0]);
                    this.handleTypeChange(optionsDict.typeOptions[0]);
                }
            );
        });
    }

    // Level 1
    handleGeoChange = (selectedGeoOption) => {
        if ("label" in selectedGeoOption && "value" in selectedGeoOption) {
            let newGeo = selectedGeoOption !== null ? selectedGeoOption.value : "";
            let validTypeOptions = new Set(
                this.state.index
                    .filter((ele) => {
                        return ele.Geography === newGeo;
                    })
                    .map((ele) => ele.Type)
            );
            validTypeOptions = Array.from(validTypeOptions);
            let defaultType = validTypeOptions[0];
            this.setState(
                {
                    selectedGeoOption: newGeo,
                    selectedTypeOption: defaultType,
                    options: {
                        ...this.state.options,
                        typeOptions: validTypeOptions.map((ele) => ({ label: ele, value: ele })),
                    },
                },
                () => {
                    this.handleFilter();
                }
            );
        }
    };

    handleTypeChange = (selectedTypeOption) => {
        let newType = selectedTypeOption !== null ? selectedTypeOption.value : "";
        this.setState(
            {
                selectedTypeOption: newType,
            },
            () => {
                this.handleFilter();
            }
        );
    };

    handleFilter = () => {
        let selectedTypeOption = this.state.selectedTypeOption;
        let selectedGeoOption = this.state.selectedGeoOption;
        let filteredIndex = this.state.index.filter((ele) => {
            return ele.Type === selectedTypeOption && ele.Geography === selectedGeoOption;
        });
        // console.log(selectedTypeOption, selectedGeoOption, filteredIndex);
        this.setState({ selectedIndex: filteredIndex });
    };

    onSwitchView(idx) {
        if (this.state.viewIndex !== idx) {
            this.setState({ viewIndex: idx });
        }
    }

    renderImages() {
        const { selectedTypeOption, selectedGeoOption } = this.state;
        if (selectedTypeOption !== "" && selectedGeoOption !== "") {
            const keyprefix = selectedTypeOption + "-" + selectedGeoOption + "-";
            let filteredIndex =
                this.state.selectedGeoOption + this.state.selectedTypeOption === ""
                    ? this.state.index || []
                    : this.state.selectedIndex;
            // console.log("count: ", filteredIndex.length);
            let filteredIndexDict = {};
            filteredIndex.map((ele) => {
                if (!filteredIndexDict[ele.Data + "__" + ele.Description]) {
                    filteredIndexDict[ele.Data + "__" + ele.Description] = [];
                }
                filteredIndexDict[ele.Data + "__" + ele.Description].push(ele);
            });
            return this.state.index
                ? Object.keys(filteredIndexDict).map((key, idx) => {
                      let dataPoint = filteredIndexDict[key];
                      return <SingleFrame key={keyprefix + idx} DataPointIndex={dataPoint} />;
                  })
                : "loading";
        }
    }

    renderTable() {
        const { selectedTypeOption, selectedGeoOption } = this.state;
        if (selectedTypeOption !== "" && selectedGeoOption !== "") {
            const keyprefix = selectedTypeOption + "-" + selectedGeoOption + "-";
            let filteredIndex =
                this.state.selectedGeoOption + this.state.selectedTypeOption === ""
                    ? this.state.index || []
                    : this.state.selectedIndex;
            return <ImbueTable key={keyprefix} config={{ data: filteredIndex, flexDict: { URL: 10 } }} />;
        }
    }

    render() {
        const { selectedTypeOption, selectedGeoOption } = this.state;
        const grid_status = this.state.gridType ? (this.state.index ? this.state.gridType : 1) : 1;
        console.log(this.state.index);
        return (
            <Layout>
                <SelectionContainer>
                    <div className="innerContainer">
                        <div className="selectWrapper">
                            <ImbueSelect
                                value={{ label: selectedGeoOption, value: selectedGeoOption }}
                                onChange={this.handleGeoChange}
                                placeholder="Select..."
                                options={this.state.options ? this.state.options.geoOptions : []}
                            />
                            <ImbueSelect
                                value={{ label: selectedTypeOption, value: selectedTypeOption }}
                                onChange={this.handleTypeChange}
                                placeholder="Select..."
                                options={this.state.options ? this.state.options.typeOptions : []}
                            />
                            <div>
                                <CSVLink data={this.state.index || []} filename={this.props.indexName + ".csv"}>
                                    <span className="SmallBtn">Download Index</span>
                                </CSVLink>
                            </div>
                        </div>

                        <div className="ImbueRangeSelectorWrapper">
                            <div className="lefticon">
                                <i className="fas fa-th-large"></i>
                            </div>
                            <InputRangeWrapper>
                                <InputRange
                                    maxValue={5}
                                    minValue={1}
                                    value={this.state.gridType}
                                    onChange={(value) =>
                                        this.setState({ gridType: value }, () => {
                                            window.localStorage.setItem("_grid", value);
                                        })
                                    }
                                />
                            </InputRangeWrapper>
                            <div className="righticon">
                                <i className="fas fa-th"></i>
                            </div>
                        </div>
                    </div>
                </SelectionContainer>

                <ImagesLayout className="ImagesLayout" gridType={grid_status}>
                    <div className="flexBox flexBox_between">
                        <div>
                            <i style={{ fontSize: "1rem", color: "#aaa" }}>{this.state.annotation}</i>
                        </div>
                    </div>
                    {this.state.viewIndex ? (
                        this.renderTable()
                    ) : (
                        <div className="image_grid">{this.renderImages()}</div>
                    )}
                </ImagesLayout>
            </Layout>
        );
    }
}

// ===== sub components =====
class SingleFrame extends Component {
    state = {
        selectedIndex: 0,
    }

    onChangeIndex = (newIndex) => {
        this.setState({
            selectedIndex: newIndex,
        });
    }

    render() {
        let dataPoint = this.props.DataPointIndex;
        let data = dataPoint[0].Data;
        let description = dataPoint[0].Description;
        let geo = dataPoint[0].Geography;

        console.log(dataPoint);

        return (
            <ImageContainer className="boxBorder">
                <InfoBoard>
                    <div style={{ color: "var(--color-main)", fontSize: "1.8rem" }}>{description}</div>
                    <div style={{ color: "var(--color-main)", fontSize: "1.3rem" }}>
                        {data} | <i className="fas fa-globe-asia"></i> {geo}
                    </div>
                    <div className="period_selector">
                        {dataPoint.map((ele, idx) => {
                            return (
                                <div
                                    key={idx}
                                    style={{
                                        border:
                                            idx === this.state.selectedIndex
                                                ? "2px solid rgb(203, 175, 135)"
                                                : "2px solid rgba(203, 175, 135, 0)",
                                    }}
                                    className="period_tab"
                                    onMouseOver={() => {
                                        this.onChangeIndex(idx);
                                    }}
                                >
                                    {ele.Period}
                                </div>
                            );
                        })}
                    </div>
                </InfoBoard>
                <ImageBoard>
                    {dataPoint.map((ele, idx) => {
                        const parsedUrl = urlParser(ele.URL);
                        return (
                            <div key={idx}>
                                <div
                                    key={idx}
                                    style={{
                                        width: "100%",
                                        display: idx === this.state.selectedIndex ? "flex" : "none",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <SingleImageWrapper src={parsedUrl} />
                                </div>
                            </div>
                        );
                    })}
                </ImageBoard>
            </ImageContainer>
        );
    }
}


class SingleImageWrapper extends Component {
    state = {
        hasError: 0
    }

    render() {
        const src = this.props.src
        return (
            <div style={{width: "100%"}}>
                <ProgressiveImage 
                    key={this.state.hasError} 
                    src={src} 
                    placeholder={this.state.hasError ? imgPlaceholderError : imgPlaceholder} 
                    onError={p => {
                    this.setState({hasError: 1})
                }}>
                    {(src, loading) => {
                        return (
                            <img
                                style={{
                                    width: "100%",
                                }}
                                src={src}
                                alt="an image"
                            />
                        );
                    }}
                </ProgressiveImage>
                <div>
                    <a
                        href={src}
                        target="_blank"
                        style={{ fontFamily: "roboto", fontSize: "1rem", color: "#555" }}
                    >
                        {src}
                    </a>
                </div>
            </div>
        )
    }
}