import React, { Component } from "react";
import { api_s3 } from "../../helpers/API";
import { parseCsv } from "../../helpers/Tabular";
import styled from "styled-components";
import HighStockWrapper from "../../components/chart/HighStockWrapper";
import { highchartBasicConfig, GLOBALSTYLED } from "../../config";
import ImbueTabs from "../../components/tabs/ImbueTabs";
import { addLegendSelect } from "../../components/chart/helpers/HighchartButton";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;

const OPTIONS = [
    { label: "Dynamic Carry Returns", value: "dynamic_carry_returns" },
    { label: "COT Reaction Returns", value: "COT_reaction_returns" },
    { label: "Dry Powder Returns", value: "Dry_powder_returns" },
    { label: "Bull Spreading RB Crack", value: "energy_returns" },
    { label: "Gold Flows", value: "gold_flows" }
];

// ///////////////////////////////////////////////////////

class ChartModule extends Component {
    state = {
        headers: [],
        data: [],
        isLoading: 0,
    };

    componentDidMount() {
        if (this.props.id) {
            this.onUpdateData(this.props.id);
        }
    }

    onUpdateData = (id) => {
        this.setState({ isLoading: 1 }, () => {
            api_s3("imbuereportbucket", id + ".csv").then((res) => {
                let data = parseCsv(res.data);
                console.log(data);
                let converted = data.rows.map((r) => {
                    let dct = {};
                    for (let i = 0; i < data.headers.length; i++) {
                        if (i === 0) {
                            dct.date = r[0];
                        } else {
                            dct[data.headers[i]] = Number.parseFloat(r[i]) || null;
                        }
                    }
                    return dct;
                });
                this.setState({
                    isLoading: 0,
                    headers: data.headers.slice(1),
                    data: converted,
                });
            });
        });
    };



    render() {

        const chartConfig = {
            ...highchartBasicConfig,
            chart: {
                ...highchartBasicConfig.chart,
                events: {
                    load: addLegendSelect
                }
            },
            tooltip: {
                // useHTML: true,
                // formatter: function () {
                //     const list = this.points
                //         .map((item) => {
                //             return {
                //                 color: item.color,
                //                 x: item.x,
                //                 y: item.y,
                //                 name: item.series.userOptions.name,
                //             };
                //         })
                //         .sort((a, b) => b.y - a.y);
                //     const innerHtml = list
                //         .map(
                //             (ele) =>
                //                 `<div style="padding: 3px 5px; color: #fff; background: ${
                //                     ele.y > 0 ? COLORMAP.green : COLORMAP.red
                //                 }" >${ele.name}: ${ele.y}</div>`
                //         )
                //         .join("");
                //     let html = `<div style="width: 100px;">${innerHtml}</div>`;
                //     console.log(html);
                //     return html;
                // },
            },
            series: this.state.headers.map((hdr, idx) => ({
                name: hdr,
                data: this.state.data.map((item) => [Date.parse(item.date), item[hdr]]),
                // type: idx === this.state.headers.length - 1 ? "areaspline" : "line",
                color: idx === this.state.headers.length - 1 ? "#111" : undefined,
                lineWidth: idx === this.state.headers.length - 1 ? 5 : 1,
                opacity: 0.2,
            })),
        };
        return (
            <div>
                {this.state.isLoading ? (
                    <div className="LoadingText">loading...</div>
                ) : (
                    <HighStockWrapper config={chartConfig} height="700px" />
                )}
            </div>
        );
    }
}

// ///////////////////

export default class SystematicStrategies extends Component {
    state = {
        headers: [],
        data: [],
        isLoading: 0,
        selected: OPTIONS[0].value,
    };

    render() {
        const tabOptions = {
            options: OPTIONS,
            selectedValue: this.state.selected,
            onClick: (val) => {
                this.setState({ selected: val });
            },
        };
        const selectedOption = OPTIONS.filter((ele) => ele.value === this.state.selected)[0];
        return (
            <Layout>
                <h1>{selectedOption.label}</h1>
                <ImbueTabs {...tabOptions} />
                <ChartModule key={selectedOption.value} id={selectedOption.value} />
            </Layout>
        );
    }
}
