import React, { Component } from "react";
import { api_robintrack_realtime } from "../../../helpers/API";
import HighStockWrapper from "../../../components/chart/HighStockWrapper";
import { COLORMAP, highchartBasicConfig } from "../../../config";

export default class PopularityChart extends Component {
    state = {
        isLoading: 1,
        data: [],
        meta: {},
    };

    componentDidMount() {
        api_robintrack_realtime({
            query: "popularity",
            symbol: this.props.symbol,
            isintraday: this.props.isintraday === 1 ? "true" : "false",
        }).then((res) => {
            this.setState({
                isLoading: 0,
                data: res.data.data,
                meta: res.data.meta,
            });
        });
    }

    render() {
        const chartConfig = {
            ...highchartBasicConfig,
            yAxis: [{ title: { text: "Quote" } }, { title: { text: "Popularity" }, opposite: 0 }],
            legend: { enabled: 1 },
            series: [
                {
                    name: "Quote",
                    data: this.state.data.map((ele) => [Date.parse(ele.index), ele.last_trade_price]),
                    yAxis: 0,
                    color: COLORMAP.red,
                },
                {
                    name: "Popularity",
                    data: this.state.data.map((ele) => [Date.parse(ele.index), ele.popularity]),
                    yAxis: 1,
                    color: COLORMAP.green,
                },
            ],
        };

        return (
            <div>
                <h3>
                    {this.props.symbol} ({this.state.meta.name || <span className="LoadingText">retrieving name...</span>})
                </h3>
                {this.state.isLoading ? <div className="LoadingText">loading...</div> : <HighStockWrapper config={chartConfig} />}
            </div>
        );
    }
}
