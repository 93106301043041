import { api_ailab_doc, api_ailab_remove } from "../../../helpers/API";
import { COLORMAP, HOST } from "../../../config";
import { TickerDict } from "../../../index/TickerDictonary";
import { toAusTime, argsToUrl } from "../../../helpers/Utils";
import ClipboardBtn from "../../../components/buttons/ClipboardBtn";
import CSVDownloader from "../../../components/other/CSVDownloader";
import LoadingBar from "../../../components/bar/LoadingBar";
import React, { Component } from "react";
import styled from "styled-components";
import TradesChart from "../../../components/chart/TradesChart";

const TradeHeaders = ["ticker", "open_date", "open_contract", "open_px", "close_date", "close_contract", "close_px", "direction", "raw_pnl", "days_held", "comment", "proba", "drawdown", "max_return", "portfolio_weight", "hedge_ratio", "pnl"].map(ele => {
    return {
        label: ele,
        key: ele
    };
});

const Layout = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 200px calc(100% - 200px);

    a {
        color: #333;
    }

    /* --+-- Result content area --+-- */

    .page_header {
        font-family: var(--font-main);
        background: #fff;
        padding: 15px;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .chart_title {
        font-family: var(--font-main);
        color: var(--color-main);
    }

    .stats_grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .small_btn {
        font-size: 1.2rem;
        border-radius: 4px;
        margin: 2px 0 2px 10px;
    }

    .small_btn:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    // -- Btn for Game Remove
    .remove_game_btn {
        color: #ca2f09;
    }

    .chart_wrapper {
        background: #fff;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 50px calc(100% - 50px);
        min-height: 400px;
    }

    .chart_wrapper .chart_title {
        padding: 0 30px;
    }
`;

export default class GameResultViewer extends Component {
    /**
     * Result Viewer of One AI Lab Game
     * Props:
     * --- docid: The Document ID of the AI Game
     * ---
     */
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            display: 0 // 0: display all | 1. display left | 2. display right
        };
        this.getData = this.getData.bind(this);
        this.removeGame = this.removeGame.bind(this);
        this.onChangeDisplay = this.onChangeDisplay.bind(this);
    }

    componentDidMount() {
        this.getData(this.props.docid);
    }

    refreshData() {
        let docid = this.props.docid;
        this.getData(docid);
    }

    getData(docid) {
        this.setState(
            {
                isLoading: true
            },
            () => {
                api_ailab_doc(docid)
                    .then(res => {
                        const data = res.data.content.user_history;
                        const in_data = data.simulation.securities[0];
                        const out_data = data.simulation.securities[1];

                        this.setState({
                            ...this.state,
                            data,
                            in_data,
                            out_data,
                            isLoading: false
                        });
                    })
                    .catch(error => {
                        this.setState({
                            isLoading: false
                        });
                    });
            }
        );
    }

    // -- Format url for replaying agent game
    formatUrl(meta) {
        let user_inputs = meta.user_inputs;
        if (user_inputs === undefined) {
            console.log("Result metadata dosen't contain user_input!");
            user_inputs = {
                agent_id: meta.agent,
                data: meta.factors,
                episodes: meta.episodes,
                security: meta.ticker
            };
        }
        let url = `${HOST}/imbue_ailab/ai_lab_interface?${argsToUrl(user_inputs)}`;
        return url;
    }

    replayGame(meta) {
        let url = this.formatUrl(meta);
        window.open(url, "_blank");
    }

    getUserEmail() {
        return localStorage.getItem("_email");
    }

    removeGame(docid) {
        const isConfirm = window.confirm(`Are you sure you want to hide this result (id: ${docid}) ???`);
        const { onClose } = this.props;
        if (isConfirm) {
            this.setState({ isLoading: true }, () => {
                api_ailab_remove(docid)
                    .then(res => {
                        if (res.data.status === "success") {
                            window.alert("Success!");
                            this.setState({
                                data: null,
                                isLoading: false
                            });
                        } else {
                            this.setState({
                                isLoading: false
                            });
                        }

                        if (onClose) {
                            onClose(true);
                        }
                    })
                    .catch(err => console.log(err));
            });
        }
    }

    // ----------

    onChangeDisplay(mode) {
        this.setState({
            display: this.state.display === mode ? 0 : mode
        });
    }

    render() {
        const { data, in_data, out_data, isLoading, display } = this.state;
        const { docid } = this.props;

        const user_inputs = (data || {}).user_inputs;

        const chartDisplayGrid = () => {
            switch (display) {
                case 0:
                    return "50% 50%";
                case 1:
                    return "100% 0%";
                case 2:
                    return "0% 100%";
                default:
                    return "50% 50%";
            }
        };

        return isLoading ? (
            <LoadingBar text="Loading ..." />
        ) : !data ? (
            <Layout style={{ textAlign: "center", color: "#777" }}>
                <LoadingBar text="No Data" warning />
            </Layout>
        ) : (
            <Layout>
                <div className="page_header">
                    <div>
                        <div style={{ fontSize: "1.4rem", display: "flex", alignItems: "center" }}>
                            <div style={{ color: COLORMAP.main }}>
                                <i className="fas fa-dollar-sign"></i> {TickerDict[data.ticker] ? `${TickerDict[data.ticker]} (${data.ticker})` : data.ticker}
                            </div>
                            <div style={{ padding: "0 10px" }}>·</div>
                            <div>
                                {" "}
                                <a style={{ color: "var(--color-darkgold)" }} href={`${HOST}/imbue_ailab/ai_lab_interface?docid=${docid}`} target="_blank">
                                    <i className="fas fa-rocket"></i> {data.agent}
                                </a>{" "}
                            </div>
                        </div>
                        <div style={{ color: "#777", fontSize: "1rem", margin: "3px 0" }}>
                            <div>
                                <i style={{ marginRight: "5px" }}> # {docid}</i>
                                <span>
                                    <i className="fas fa-clock"></i> {toAusTime(data.timestamp, 1)}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* <div style={{ fontSize: "1rem", color: "#999" }}>{
                                    Object.keys(user_inputs).map(ele => (
                                        <span style={{ marginRight: "8px" }}><span>{ele}: </span> <span style={{ color: "#000", fontSize: "1.1rem" }}>{JSON.stringify(user_inputs[ele])}</span></span>
                                    ))
                                }</div> */}

                    <div className="flexBox" style={{ overflow: "auto", flexWrap: "nowrap" }}>
                        <div className="CircleBtn" style={{ marginRight: "10px", fontSize: "1.1rem" }}>
                            <ClipboardBtn text={"[" + (data.factors || []).map(w => `"${w}"`).join(",") + "]"} />
                        </div>
                        {(data.factors || []).map(ele => {
                            return <span className="factor_tag">{ele}</span>;
                        })}
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        {data.simulation.securities.map((row, idx) => {
                            return (
                                <div>
                                    {/* <div className="chart_title" >{row.security_name.split("_").join(" ")}</div> */}
                                    <div className="stats_grid">
                                        {row.statistics.length > 0
                                            ? row.statistics
                                                  .filter(ele => !(ele.label in { "Sharpe by Year": null }))
                                                  .map(subItem => {
                                                      let label = null;
                                                      let value = null;

                                                      if ("label" in subItem) {
                                                          label = subItem.label;
                                                          value = subItem.display;
                                                      } else {
                                                          label = Object.keys(subItem)[0];
                                                          value = Object.values(subItem)[0];
                                                      }

                                                      return (
                                                          <div style={{ lineHeight: "1.8rem" }}>
                                                              <span style={{ color: "#444", fontFamily: "roboto", marginRight: "12px", fontSize: "1.1rem", whiteSpace: "nowrap", wordSpacing: "nowrap" }}>{label}</span>
                                                              <span style={{ color: "#000", fontSize: "1.3rem" }}>{value}</span>
                                                          </div>
                                                      );
                                                  })
                                            : " -- "}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div style={{ display: "grid", gridTemplateColumns: chartDisplayGrid(), gridTemplateRows: "100%" }}>
                    <div className="chart_wrapper" style={{ visibility: display !== 2 ? "visible" : "hidden", opacity: display !== 2 ? 1 : 0 }}>
                        <div className="chart_title flexBox flexBox_between">
                            <div className="flexBox">
                                <div>In-Sample Trade</div>
                                <CSVDownloader headers={TradeHeaders} data={in_data.trades || []} name={"trade"} />
                            </div>
                            <div className="flexBox">
                                <div className="CircleBtn" onClick={() => this.onChangeDisplay(1)}>
                                    <i className={display === 0 ? "fas fa-expand" : "fas fa-compress"}></i>
                                </div>
                            </div>
                        </div>
                        <TradesChart data={in_data} />
                    </div>
                    <div className="chart_wrapper" style={{ visibility: display !== 1 ? "visible" : "hidden", opacity: display !== 1 ? 1 : 0 }}>
                        <div className="chart_title flexBox flexBox_between">
                            <div className="flexBox">
                                <div>Out-of-Sample Trade</div>
                                <CSVDownloader headers={TradeHeaders} data={out_data.trades || []} name={"trade"} />
                            </div>
                            <div className="flexBox">
                                <div className="CircleBtn" onClick={() => this.onChangeDisplay(2)}>
                                    <i className={display === 0 ? "fas fa-expand" : "fas fa-compress"}></i>
                                </div>
                            </div>
                        </div>

                        <TradesChart data={out_data} />
                    </div>
                </div>
            </Layout>
        );
    }
}
