import React, { useState, useEffect } from 'react';
import RollsTableBody from './RollsTableBody';
import RollsTableHead from './RollsTableHead';
import styles from '../../css/TopStrikes.module.css';
import { LoadingPage } from "../../helpers/SimpleComponents";


const RollsTable = (props) => {
    const [data, setData] = useState(props.data);

    const columns = [
        // { label: "", accessor: "", sortable: false },
        { label: "Title", accessor: "title", sortable: true },
        { label: "Symbol", accessor: "symbol", sortable: false },
        { label: "Contract", accessor: "current_contract", sortable: false },
        { label: "Initial Date", accessor: "initial_date", sortable: false },
        { label: "Last Date", accessor: "last_date", sortable: false },
        { label: "Last Price", accessor: "px_last", sortable: false },
        { label: "Total Roll", accessor: "px_roll", sortable: false },
        { label: "Total Roll %", accessor: "roll_pct", sortable: true },
        { label: "2000-2010", accessor: "2000-2010", sortable: true },
        { label: "2010-2020", accessor: "2010-2020", sortable: true },
        { label: "2020", accessor: "2020", sortable: true },
        { label: "2021", accessor: "2021", sortable: true },
        { label: "2022", accessor: "2022", sortable: true },
        { label: "2023", accessor: "2023", sortable: true },
        { label: "2024", accessor: "2024", sortable: true },
        // { label: "6mo", accessor: "6mo", sortable: true },
        // { label: "3mo", accessor: "3mo", sortable: true },
        // { label: "1mo", accessor: "1mo", sortable: true },
    ];

    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...data].sort((a, b) => {
                if (a[sortField] === null && b[sortField] === null) return 0;
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                return (
                    (
                        (typeof a[sortField] === 'string' && typeof b[sortField] === 'string') ? 
                            (a[sortField].localeCompare(b[sortField])) : (a[sortField] - b[sortField]) 
                    ) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setData(sorted);
        }
    };

    if (data === null) {
        return (<div>{LoadingPage()}</div>);
    } else {
        const dataError = (!data || data.length === 0);
        return (
            <div>
                <table className={styles.table}>
                    <caption>
                        {props.sector}
                    </caption>
                    {dataError &&
                    <p style={{color: 'red'}}> DATA ERROR </p>
                    }
                    <RollsTableHead columns={columns} handleSorting={handleSorting}/>
                    <RollsTableBody columns={columns} tableData={data} onChartSymbolChange={props.onChartSymbolChange} />
                </table>
            </div>
        );
    }
};

export default RollsTable;