import React, { Component } from "react";
import RcTable from "react-table-v6";
import "react-table-v6/react-table.css";

const defaultFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) >= 0 : true;
};

/**
 * ReactTable Wrapper
 *
 * props:
 * - list: list
 * - columns: list
 */

export default class ImbueRcTable extends Component {
    render() {
        const list = this.props.list;
        const columns = this.props.columns || Object.keys(list[0] || []).map((ele) => ({ Header: ele, accessor: ele }));
        const filterable = this.props.filterable;
        return (
            <RcTable
                columns={columns}
                data={list}
                filterable={filterable}
                defaultFilterMethod={defaultFilterMethod}
                defaultPageSize={10}
                {...this.props}
            />
        );
    }
}
