import React, { Component } from "react";
import MainBoard from "../../layout/MainBoard";

import ClimateDashboard from "./ClimateDashboard";
import Earth from "./Earth";
import NDVIDashboard from "./NDVIDashboard";
import NoaaDaily from "./NoaaDaily";
import WeatherModelsDashboard from "./WeatherModelsDashboard";
import ProductionsMapDashboard from "./ProductionsMapDashboard";

class WeatherDashboard extends Component {

    componentDidMount() {
        document.title = "Weather";
    }

    render() {
        const path = this.props.match.path;
        const list = [
            { name: "Weather", path: path + "/weather_dashboard", component: WeatherModelsDashboard },
            { name: "Production Map", path: path + "/productionsmap", component: ProductionsMapDashboard },
            { name: "NDVI", path: path + "/ndvi_dashboard", component: NDVIDashboard },
            // { name: "NDVI Classic", path: path + "/ndvi_classic", component: NDVIClassicDashboard },
            { name: "Climate Indices", path: path + "/climate", component: ClimateDashboard },
            { name: "NOAA Center", path: path + "/noaa", component: NoaaDaily },
            { name: "Earth", path: path + "/earth", component: Earth },
        ];
        return <MainBoard title="Climate & Agriculture" list={list} />;
    }
}

export default WeatherDashboard;
