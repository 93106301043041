import React, { Component } from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import styled from "styled-components";

const IconWrapper = styled.div`
    margin-left: 10px;
    display: inline-block;

    &:hover {
        opacity: 0.9;
    }
    &:active {
        transform: translateY(1px);
    }
`;

// Default Text Style
const DEFAULTSTYLE = {
    fontFamily: "var(--font-main)",
    fontSize: "1.5rem",
    color: "var(--color-main)",
    margin: "5px 0"
};

export default class HeaderWithDownload extends Component {
    // ---+--- props list ---+---
    // title        | string    | the header text content
    // filename     | string    | filename of the csv file
    // headers      | object    | column name's order
    // styles       | object    | font style for the text
    // data         | object    | data need to be download
    // needConvert  | bool      | if the input data is highchart series like

    seriesToCsv(series) {
        let csvData = series
            .map(srs => {
                let timeseries = srs.data.map(ele => {
                    let ts = new Date(ele[0]);
                    ts = moment(ts.toDateString()).format("YYYY-MM-DD");
                    let row = { date: ts };
                    row[srs.name] = ele[1];
                    return row;
                });
                return timeseries;
            })
            .reduce((a, b) => a.map((aEle, idx) => ({ ...aEle, ...b[idx] })));
        return csvData;
    }

    render() {
        const { data } = this.props;
        return (
            <div style={this.props.styles ? { ...DEFAULTSTYLE, ...this.props.styles } : DEFAULTSTYLE}>
                {this.props.title}
                {data ? (
                    <CSVLink data={this.props.data ? (this.props.needConvert ? this.seriesToCsv(this.props.data) : this.props.data) : []} headers={this.props.headers} target="_blank" filename={this.props.filename + "_" + moment().format("YYYYMMDDhhmmss") + ".csv"}>
                        <IconWrapper>
                            <i className="fas fa-download" style={{ color: (this.props.styles ? { ...DEFAULTSTYLE, ...this.props.styles } : DEFAULTSTYLE).color }} title="Download data to CSV"></i>
                        </IconWrapper>
                    </CSVLink>
                ) : (
                    ""
                )}
            </div>
        );
    }
}
