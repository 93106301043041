import React, { Component } from "react";
import { api_data_noaa_daily_station_index } from "../../helpers/API";
import ReactMapboxGl, { Layer, Source, Popup } from "react-mapbox-gl";
import styled from "styled-components";
import NoaaCard from "./components/NoaaCard";
import { MAPBOX_ACCESS_TOKEN } from "../../security";
import { LoadingPage } from "../../helpers/SimpleComponents";

const Map = ReactMapboxGl({
    accessToken: MAPBOX_ACCESS_TOKEN,
});

const Layout = styled.div`
    width: 100%;
    height: 100%;

    .grid_layout {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 100%;
        width: 100%;
        height: 100%;
    }

    .rhs {
        height: 100%;
        overflow: auto;
    }
`;

export default class NoaaDaily extends Component {
    constructor(p) {
        super(p);
        this.state = {
            stationindex: [],
            popupCoord: [],
            zoom: [3],
            center: [-95.518250335096376, 37.0902],
        };
    }

    componentDidMount() {
        api_data_noaa_daily_station_index().then((res) => {
            const data = res.data.message;
            this.setState({
                stationindex: data,
            });
        });
    }

    onChangePopupCord(event) {
        this.setState({
            popupCoord: [event.lngLat.lng, event.lngLat.lat],
            selectedProperty: event.features[0].properties,
        });
    }

    render() {
        const stationindex = this.state.stationindex;
        const popupCoord = this.state.popupCoord;
        const zoom = this.state.zoom;
        const center = this.state.center;
        const selectedProperty = this.state.selectedProperty;

        if (stationindex.length > 0) {
            return (
                <Layout>
                    <div
                        className="grid_layout"
                        style={{
                            gridTemplateColumns: selectedProperty
                                ? "50% 50%"
                                : "100%",
                        }}
                    >
                        <Map
                            id="Mainmap"
                            {...{
                                style: "mapbox://styles/mapbox/light-v9",
                                zoom: zoom,
                                center: center,
                                containerStyle: {
                                    height: "100%",
                                    width: "100%",
                                },
                            }}
                        >
                            <Source
                                id="source_id"
                                geoJsonSource={{
                                    type: "geojson",
                                    data: {
                                        type: "FeatureCollection",
                                        features: stationindex.map((ele) => ({
                                            type: "Feature",
                                            geometry: {
                                                type: "Point",
                                                coordinates: [
                                                    ele.longitude,
                                                    ele.latitude,
                                                ],
                                            },
                                            properties: ele,
                                        })),
                                    },
                                }}
                            />
                            <Layer
                                {...{
                                    id: "population",
                                    sourceId: "source_id",
                                    type: "circle",
                                    paint: {
                                        "circle-radius": 3,
                                        "circle-color": "red",
                                    },
                                    onClick: (e) => {
                                        this.onChangePopupCord(e);
                                    },
                                    onMouseEnter: (e) => {},
                                }}
                            ></Layer>
                            {selectedProperty ? (
                                <Popup coordinates={popupCoord}>
                                    <div
                                        style={{
                                            fontFamily: "var(--font-main)",
                                        }}
                                    >
                                        <small>{selectedProperty.id}</small>
                                        <div>
                                            {selectedProperty.station_name}
                                        </div>
                                    </div>
                                </Popup>
                            ) : (
                                ""
                            )}
                        </Map>
                        <div className="rhs">
                            <NoaaCard
                                key={(selectedProperty || {}).id}
                                payload={selectedProperty}
                            />
                        </div>
                    </div>
                </Layout>
            );
        } else {
            return <Layout>{LoadingPage()}</Layout>;
        }
    }
}
