import React, { Component } from "react";

export default class ImbueTabs extends Component {
    /**
     * props:
     *
     * > options, list, REQUIRED
     *      e.g. [{label: "", value: _ }]
     *
     * > selectedValue, object (NOT like {label, value}), REQUIRED
     *      e.g. value
     *
     * > onClick, function, REQUIRED
     *      e.g function(e) => {....}
     */

    render() {
        const options = this.props.options; // [{label: "", value: _ }]
        const selectedValue = this.props.selectedValue;
        const onClickValue = this.props.onClick;
        const onClickOption = this.props.onClickOption

        return (
            <div className="ImbueTabWrapper">
                {options.map((item, idx) => {
                    return (
                        <div
                            key={idx}
                            className={"ImbueTab" + (item.value === selectedValue ? " ImbueTabSelected" : "")}
                            onClick={() => {
                                if (onClickValue) {
                                    onClickValue(item.value);
                                }else {
                                    onClickOption(item)
                                }
                                
                            }}
                        >
                            {item.label}
                        </div>
                    );
                })}
            </div>
        );
    }
}
