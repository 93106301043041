import React, { Component } from "react";
import styled from "styled-components";

const InputLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(
        ${(props) => (props.count ? props.count : 4)},
        auto
    );

    .input_wrapper {
        padding: 15px 15px 10px;
        border-bottom: 1px solid rgba(104, 104, 104, 0.514);
        transition: box-shadow 0.2s;
        background: white;
    }
    .input_wrapper input {
        font-family: var(--font-main);
        font-weight: 800;
        text-align: left;
        font-size: 1.8rem;
    }
    .input_wrapper:hover > .input_label {
        color: rgb(22, 22, 22);
    }
    .input_label {
        color: rgb(172, 172, 172);
        font-size: 12px;
        text-align: left;
        transition: all 0.3s;
    }
    .input_upper {
        text-transform: uppercase;
    }

    /* Auto Suggestions */
    .suggestion_layout {
        border: 10px solid rgb(245, 245, 245);
        border-radius: 5px;
        margin-top: 15px;
        margin-right: 0;
        position: absolute;
        background: rgba(245, 245, 245, 0.95);
        padding: 5px;
        max-width: 300px;
        max-height: 200px;
        overflow: auto;
        z-index: 9999;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    .suggestion_item {
        overflow: auto;
        padding: 5px;
        font-family: var(--font-main);
        transition: all 0.1s;
    }

    .suggestion_item:hover {
        background: #eee;
        cursor: pointer;
    }
`;

class MultiInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
        };
    }

    UNSAFE_componentWillMount() {
        let suggestions = {};
        this.props.fields.map((element) => {
            suggestions[element.label] = element.suggestions
                ? element.suggestions
                : [];
            return null;
        });
        this.setState({
            ...this.state,
            suggestions,
        });
        this.selectAll = this.selectAll.bind(this);
    }

    selectAll(e) {
        e.target.select();
    }

    render() {
        return (
            <InputLayout count={this.props.fields.length}>
                {this.props.fields.map((element) => {
                    return (
                        <div className="input_wrapper">
                            <div className="input_label">{element.label}</div>
                            <input
                                className="fontColor__imbue_green"
                                type="text"
                                name={element.label}
                                onKeyPress={this.props.keyPress}
                                onChange={this.props.handleChange}
                                onFocus={this.selectAll}
                                value={element.value}
                            />
                        </div>
                    );
                })}
            </InputLayout>
        );
    }
}

export default MultiInput;
