import React, { Component } from "react";
import MainBoard from "../../layout/MainBoard";
import TradeRef from "./TradeRef";
import TradeManagerV3 from "./TradeManagerV3";
import DailyTrades from "./DailyTrades";
import Interpretability from "./Interpretability";
import OptionStrangles from "./OptionStrangles";
import DailyPortfolioSummary from "./DailyPortfolioSummary";
import StrategyDetails from "./StrategyDetails";

export default class TradeManagerMainboard extends Component {

    componentDidMount() {
        document.title = "Trade Manager";
    }
    
    render() {
        const { path } = this.props.match;

        const LiveTradeManager = () => <TradeManagerV3 status={"live"} />;
        const StagingTradeManager = () => <TradeManagerV3 status={"staging"} title={"Staging Trade Manager V3"} />;
        const ScriptTradeManager = () => <TradeManagerV3 status={"script"} title={"Script Trade Manager V3"} />;

        const list = [
            { name: "Daily Portfolio Summary", path: path + "/daily_portf_summary", component: DailyPortfolioSummary },
            { name: "Live Trade Manager", path: path + "/live_tm", component: LiveTradeManager },
            { name: "Staging Trade Manager", path: path + "/staging_tm", component: StagingTradeManager },
            { name: "Script Trade Manager", path: path + "/script_tm", component: ScriptTradeManager },
            { name: "Daily Trades", path: path + "/dailytrades", component: DailyTrades },
            { name: "Interpretability", path: path + "/interpretability", component: Interpretability, isbeta: 1 },
            { name: "Option Strangles", path: path + "/optionstrangles", component: OptionStrangles },
            { name: "Trade Reference", path: path + "/traderef", component: TradeRef },
            { name: "Strategy Details", path: path + "/StrategyDetails", component: StrategyDetails },
        ];
        return <MainBoard title="Trade Manager" list={list} />;
    }
}
