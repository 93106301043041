import React, { Component } from "react";
import data_tradinghours from "../../json/ticker_tradinghrs.json";
import styled from "styled-components";
import { COLORMAP, BOXSHADOW } from "../../config.js";
import moment from "moment";
import "moment-timezone";
import NationFlag from "react-country-flag";
import { daterangeTz } from "../../helpers/Utils.js";

import ImbueTable from "../../components/table/ImbueTable";

const Layout = styled.div`
    font-family: "open sans";
    padding: 30px;

    .section_title {
        font-family: var(--font-main);
        font-size: 1.5rem;
        color: #444;
        margin: 20px 0;
    }

    .tb_wrapper {
        /* overflow: auto; */
        /* max-height: 500px; */
        /* background: white; */
        box-shadow: ${BOXSHADOW.smooth};
    }

    .tb_row {
        color: #444;
        font-size: 1.3rem;
        padding: 5px 10px;
        border-bottom: 1px solid #ccc;
        background: white;
        border-left: 5px solid transparent;
    }

    .tb_row:not(.tb_row_header):nth-child(odd) {
        background: rgb(245, 245, 245);
    }

    .tb_row:not(.tb_row_header):hover {
        color: #111;
        background: #ddd;
        border-left: 5px solid var(--color-gold);
    }

    .tb_row_header {
        background: var(--color-main);
        color: #fff;
    }
`;

function inTrading(daterange) {
    const current = moment().tz("America/New_York");
    const inRange = dr => {
        const open_dt = dr.split("-")[0];
        const open_time = parseInt(open_dt.split(":")[0]) + parseInt(open_dt.split(":")[1]) / 60;
        const close_dt = dr.split("-")[1];
        const close_time = parseInt(close_dt.split(":")[0]) + parseInt(close_dt.split(":")[1]) / 60;
        const curren_time = parseInt(current.format("H")) + current.format("m") / 60;

        // console.log(curren_time, open_time, close_time)

        if (open_time > close_time) {
            if (curren_time >= open_time || curren_time <= close_time) {
                return true;
            } else {
                return false;
            }
        } else {
            if (curren_time >= open_time && curren_time <= close_time) {
                return true;
            } else {
                return false;
            }
        }
    };
    if (daterange.indexOf("&") < 0) {
        return inRange(daterange);
    } else {
        const daterange1 = daterange.split(" & ")[0];
        const daterange2 = daterange.split(" & ")[1];
        return inRange(daterange1) || inRange(daterange2);
    }
}

export default class extends Component {
    render() {
        console.log(data_tradinghours.rows);
        const rows_for_table = data_tradinghours.rows.map(ele => ({
            Ticker: ele.doc.ticker,
            Country: (
                <span>
                    <NationFlag code={ele.doc.COUNTRY} svg /> {ele.doc.COUNTRY}
                </span>
            ),
            Name: ele.doc.ID_BB_GLOBAL_ULT_PARENT_CO_NAME || ele.doc.NAME,
            "Trading hours (NY)": (
                <span>
                    <NationFlag code="US" svg /> {daterangeTz(ele.key, "America/New York")}
                </span>
            ),
            "Trading hours (CHI)": (
                <span>
                    <NationFlag code="US" svg /> {daterangeTz(ele.key, "America/Chicago")}
                </span>
            ),
            "Trading hours (GB)": (
                <span>
                    <NationFlag code="US" svg /> {daterangeTz(ele.key, "Europe/London")}
                </span>
            ),
            "Market Status": inTrading(ele.key) ? (
                <span>
                    <i
                        style={{
                            color: COLORMAP.green
                        }}
                        className="fas fa-circle"
                    />{" "}
                    In Trading
                </span>
            ) : (
                <span>
                    <i style={{ color: "#999" }} className="fas fa-circle" /> Market Closed
                </span>
            )
        }));

        const columns = ["Ticker", "Country", "Name", "Trading hours (NY)", "Trading hours (CHI)", "Trading hours (GB)", "Market Status"];

        return (
            <Layout>
                <ImbueTable config={{ data: rows_for_table, density: "tight", sorted: columns, height: "75vh" }} />

                {/* <div>
                    <div className="section_title">Commodity Trading Hours</div>
                    <div className="tb_wrapper">
                        <div className="tb_row_header tb_row flexBox">
                            {[{ name: "Ticker" }, { name: "Country", flex: 0.5 }, { name: "Name", flex: 2 }, { name: "Trading Hours (NY)", flex: 1.5 }, { name: "Trading Hours (CHI)", flex: 1.5 }, { name: "Trading Hours (GB)", flex: 1.5 }, { name: "Market Status" }].map((ele, key) => (
                                <div key={key} style={{ flex: ele.flex || 1 }}>
                                    {ele.name}
                                </div>
                            ))}
                        </div>
                        {data_tradinghours.rows.map((ele, idx) => {
                            const isTrading = inTrading(ele.key);
                            const row = [
                                {
                                    value: ele.doc.ticker,
                                    data: ele.doc.ticker || ele.doc._id
                                },
                                {
                                    value: ele.doc.COUNTRY,
                                    data: (
                                        <span>
                                            <NationFlag code={ele.doc.COUNTRY} svg /> {ele.doc.COUNTRY}
                                        </span>
                                    ),
                                    flex: 0.5
                                },
                                // { value: ele.doc.FUT_CUR_GEN_TICKER, data: ele.doc.FUT_CUR_GEN_TICKER },
                                {
                                    value: ele.doc.ID_BB_GLOBAL_ULT_PARENT_CO_NAME || ele.doc.NAME,
                                    data: ele.doc.ID_BB_GLOBAL_ULT_PARENT_CO_NAME || ele.doc.NAME,
                                    flex: 2
                                },
                                {
                                    value: ele.key,
                                    data: (
                                        <span>
                                            <NationFlag code="US" svg /> {ele.key}
                                        </span>
                                    ),
                                    flex: 1.5
                                },
                                {
                                    value: daterangeTz(ele.key, "America/Chicago"),
                                    data: (
                                        <span>
                                            <NationFlag code="US" svg /> {daterangeTz(ele.key, "America/Chicago")}
                                        </span>
                                    ),
                                    flex: 1.5
                                },
                                {
                                    value: daterangeTz(ele.key, "Europe/London"),
                                    data: (
                                        <span>
                                            <NationFlag code="GB" svg /> {daterangeTz(ele.key, "Europe/London")}
                                        </span>
                                    ),
                                    flex: 1.5
                                },
                                {
                                    data: isTrading ? (
                                        <span>
                                            <i
                                                style={{
                                                    color: COLORMAP.green
                                                }}
                                                className="fas fa-circle"
                                            />{" "}
                                            In Trading
                                        </span>
                                    ) : (
                                        <span>
                                            <i style={{ color: "#999" }} className="fas fa-circle" /> Market Closed
                                        </span>
                                    )
                                }
                            ];
                            return (
                                <div
                                    key={idx}
                                    className="tb_row flexBox"
                                    style={{
                                        background: isTrading ? COLORMAP.green_oo : undefined
                                    }}
                                >
                                    {row.map((col, idx_) => {
                                        return (
                                            <div
                                                key={idx_}
                                                className=""
                                                style={{
                                                    flex: col.flex || 1,
                                                    color: col.color
                                                }}
                                            >
                                                {col.data || "--"}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div> */}
            </Layout>
        );
    }
}
