import React, { useState, useEffect } from 'react'
import { api_net_delta_gamma } from "../../helpers/API";
import ImbueSelect from "../../components/input/ImbueSelect";
import styled from "styled-components";
import "./style.css";
import { GLOBALSTYLED } from "../../config";
import { LoadingPage } from "../../helpers/SimpleComponents";
import HighStockWrapper from '../../components/chart/HighStockWrapper';
import { by_exchange, _exchange_symbol_lookup } from "../../universalSymbolMap";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)`
    .chartWrapper {
        background: white;
        padding: 20px;
        box-shadow: var(--boxshadow-aws);
    }
    .fullScreenImgWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
    }
    .fullScreenImgWrapper img {
    }
`;

// const rootOptions = [
//     {label: 'Lean Hogs', value: 'LH'},
//     {label: "Chicago Soft Wheat", value: 'ZW'},
//     {label: 'Kansas Hard Wheat', value: 'KE'},
//     {label: 'Corn', value: 'ZC'},
//     {label: 'Minn Spring Wheat', value: 'MWE'},
//     {label: 'Soybeans', value: 'ZS'},
//     {label: 'Soybean Meal', value: 'ZM'},
//     {label: 'Soybean Oil', value: 'ZL'},
//     {label: 'Crude Oil', value: 'CL'},
//     {label: 'Heating Oil', value: 'HO'}
//     {label: 'Natural Gas', value: 'NG'},
//     {label: 'US T-Bond', value: 'ZB'},
//     {label: '10-Year T-Note', value: 'ZN'},
//     {label: '5-Year T-Note', value: 'ZF'},
//     {label: '2-Year T-Note', value: 'ZT'},
//     ];

 // return array of label,value objects from USM for select dropdown options 
 function getSymbolOptions() {
    let res = [];
    for (let i in _exchange_symbol_lookup) {
        let cur = _exchange_symbol_lookup[i];
        if (cur.hasOwnProperty('chain')) {
            const root = cur['exchange'];
            const title = cur['title'];
            const live = cur['chain']['live'] ? "" : "(T1)"
            res.push({label: `${root} ${title} ${live}`, value: root});
        }
    }
    return res;
}

const rootOptions = getSymbolOptions();

const expiryOptions = [
    {label: "≥ 1 week", value: 0}, // at least one week to go
    {label: "< 1 week", value: 1}, // less than a week to go
    {label: "total", value: null}  // total row, as from original query
]


let dataReceived = false;
let firstRun = true;
let rootTitle = rootOptions[rootOptions.findIndex((ele) => ele.value === 'ZS')]; // won't work if 'ZS' is not in USM
let chartRangeIndex = 3; // default at 1week


export default function NetDeltaGamma(props) {

    const [state, setState] = useState();
    const [root, setRoot] = useState(rootOptions[0]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [expiryState, setExpiryState] = useState(expiryOptions[2]);

    let symbol = by_exchange(root.value);


    const unmountFunc = () => {
        useEffect(() => {
            return () => {
                dataReceived = false;
                firstRun = true;
                setState({});
            };
        }, []);
    }


    // make net_greeks api call and update state
    function getData() {
        setIsLoading(true);
        setRoot(rootTitle);
        console.log(`getting data for ${rootTitle.value}...`)
        api_net_delta_gamma({root: rootTitle.value}).then(res => {
            console.log(res.data)
            dataReceived = true;
            setState({data: res.data});
            setIsLoading(false);
            setHasError(false);
        }).catch((e) => {
            console.error(e);
            setIsLoading(false);
            setHasError(true);
        })
    }


    // function getChartConfig(greek) {
    //     const dataAboveKeys  = {'Gamma': 'net_gamma_1pct_above', 'Delta': 'net_delta_above', 'Vega': 'net_vega_above'};
    //     const dataBelowKeys = {'Gamma': 'net_gamma_1pct_below', 'Delta': 'net_delta_below', 'Vega': 'net_vega_below'};
    //     const colorAboveKeys = {'Gamma': '#009933', 'Delta': 'rgb(50, 200, 200)', 'Vega': 'blue'}; // green, cyan, blue
    //     const colorBelowKeys = {'Gamma': 'rgb(255, 160, 0)', 'Delta': 'rgb(200, 0, 150)', 'Vega': 'red'}; // orange, purple, red
    //     return {
    //         title: {
    //             text: `${root.value} : ${greek}`
    //         },
    //         legend: {
    //             enabled: true
    //         },
    //         navigator: {
    //             enabled: false
    //         },
    //         scrollbar: {
    //             enabled: false
    //         },
    //         credits: {
    //             enabled: false
    //         },
    //         rangeSelector: {
    //             buttons: [
    //                 {
    //                     type: "hour",
    //                     count: 12,
    //                     text: "12h",
    //                 }, {
    //                     type: "day",
    //                     count: 1,
    //                     text: "1d"
    //                 }, {
    //                     type: "day",
    //                     count: 3,
    //                     text: "3d"
    //                 }, {
    //                     type: 'week',
    //                     count: 1,
    //                     text: '1w'
    //                 }, {
    //                     type: 'week',
    //                     count: 4,
    //                     text: '4w'
    //                 }, {
    //                     type: "all",
    //                     text: "All"
    //                 }
    //             ],
    //             selected: 3
    //         },
    //         plotOptions: { 
    //             series: {
    //                 stacking: 'normal',
    //                 animation: false,
    //                 pointPadding: 0,
    //                 // groupPadding: 0.2
    //             },
    //         },
    //         xAxis: {
    //             type: "datetime",
    //             crosshair: true,
    //         },
    //         yAxis: [
    //             {
    //             title: {
    //                 text: `${greek} 1%`,
    //             },
    //             offset: 20,
    //             showEmpty: false
    //             },
    //         ],
    //         series: [
    //             {
    //             name: "Above",
    //             type: "column",
    //             color: colorAboveKeys[greek],
    //             data: state.data.results.map((ele) => [ele['time'], ele[dataAboveKeys[greek]]])
    //             },
    //             {
    //             name: "Below",
    //             type: "column",
    //             color: colorBelowKeys[greek],
    //             data: state.data.results.map((ele) => [ele['time'], ele[dataBelowKeys[greek]]])
    //             },
    //         ],
    //         tooltip: {
    //             // xDateFormat: "%a, %b %e, '%y", // Thu, Dec 16, 21
    //             valueDecimals: 3,
    //             split: false,
    //             shared: true
    //         },
            
    //     }
    // }

    function getChartConfig() {
        const dataAboveKeys  = {'Gamma': 'net_gamma_1pct_above', 'Delta': 'net_delta_above', 'Vega': 'net_vega_above'};
        const dataBelowKeys = {'Gamma': 'net_gamma_1pct_below', 'Delta': 'net_delta_below', 'Vega': 'net_vega_below'};
        const colorAboveKeys = {'Gamma': '#009933', 'Delta': 'rgb(50, 200, 200)', 'Vega': 'blue'}; // green, cyan, blue
        const colorBelowKeys = {'Gamma': 'rgb(255, 160, 0)', 'Delta': 'rgb(200, 0, 150)', 'Vega': 'red'}; // orange, purple, red
        return {
            chart: {
                // height: (9 / 16 * 100) + '%' // 16:9 ratio @ 90%
                height: 1000,
                animation: false
            },
            title: {
                text: `${root.value} : Greeks`
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            rangeSelector: {
                buttons: [
                    {
                        type: "hour",
                        count: 12,
                        text: "12h",
                        events: {
                            click: () => { chartRangeIndex = 0 }
                        }
                    }, {
                        type: "day",
                        count: 1,
                        text: "1d",
                        events: {
                            click: () => { chartRangeIndex = 1 }
                        }
                    }, {
                        type: "day",
                        count: 3,
                        text: "3d",
                        events: {
                            click: () => { chartRangeIndex = 2 }
                        }
                    }, {
                        type: 'week',
                        count: 1,
                        text: '1w',
                        events: {
                            click: () => { chartRangeIndex = 3 }
                        }
                    }, {
                        type: 'week',
                        count: 4,
                        text: '4w',
                        events: {
                            click: () => { chartRangeIndex = 4 }
                        }
                    }, {
                        type: "all",
                        text: "All",
                        events: {
                            click: () => { chartRangeIndex = 5 }
                        }
                    }
                ],
                selected: chartRangeIndex
            },
            plotOptions: { 
                series: {
                    stacking: 'normal',
                    animation: false,
                    pointPadding: 0,
                    // groupPadding: 0.2
                },
            },
            xAxis: {
                type: "datetime",
                crosshair: true,
            },
            yAxis: [
                {
                    title: {
                        text: `Gamma 1%`,
                    },
                    offset: 20,
                    showEmpty: false,
                    height: '33%',
                },
                {
                    title: {
                        text: `Delta`,
                    },
                    offset: 20,
                    showEmpty: false,
                    top: '33%',
                    height: '33%'
                },
                {
                    title: {
                        text: `Vega`,
                    },
                    offset: 20,
                    showEmpty: false,
                    top: '66%',
                    height: '33%'
                },
            ],
            series: [
                {
                name: "Gamma + ",
                type: "column",
                color: colorAboveKeys['Gamma'],
                data: state.data.results.filter((ele) => ele['expiring'] === expiryState.value).map((ele) => [ele['time'], ele[dataAboveKeys['Gamma']]]),
                yAxis: 0
                },
                {
                name: "Gamma - ",
                type: "column",
                color: colorBelowKeys['Gamma'],
                data: state.data.results.filter((ele) => ele['expiring'] === expiryState.value).map((ele) => [ele['time'], ele[dataBelowKeys['Gamma']]]),
                yAxis: 0
                },
                {
                name: "Delta + ",
                type: "column",
                color: colorAboveKeys['Delta'],
                data: state.data.results.filter((ele) => ele['expiring'] === expiryState.value).map((ele) => [ele['time'], ele[dataAboveKeys['Delta']]]),
                yAxis: 1
                },
                {
                name: "Delta - ",
                type: "column",
                color: colorBelowKeys['Delta'],
                data: state.data.results.filter((ele) => ele['expiring'] === expiryState.value).map((ele) => [ele['time'], ele[dataBelowKeys['Delta']]]),
                yAxis: 1
                },
                {
                name: "Vega + ",
                type: "column",
                color: colorAboveKeys['Vega'],
                data: state.data.results.filter((ele) => ele['expiring'] === expiryState.value).map((ele) => [ele['time'], ele[dataAboveKeys['Vega']]]),
                yAxis: 2
                },
                {
                name: "Vega - ",
                type: "column",
                color: colorBelowKeys['Vega'],
                data: state.data.results.filter((ele) => ele['expiring'] === expiryState.value).map((ele) => [ele['time'], ele[dataBelowKeys['Vega']]]),
                yAxis: 2
                },
        ],
            tooltip: {
                // xDateFormat: "%a, %b %e, '%y", // Thu, Dec 16, 21
                valueDecimals: 3,
                split: true,
                shared: true
            },
            
        }
    }

    if (firstRun) {
        firstRun = false;
        getData();
    }

    unmountFunc(); // unmount cleanup

    if (isLoading) {

        return (<Layout>{LoadingPage()}</Layout>);

    } else {

        // let gammaConfig = getChartConfig("Gamma");
        // let deltaConfig = getChartConfig("Delta");
        // let vegaConfig = getChartConfig("Vega");
        // let allChartsConfig = getChartConfig();

        return (
            <Layout>
                <div className="ImbueSelectsWrapper">
                    <div className="row">
                        <ImbueSelect
                            title="Root"
                            options={rootOptions}
                            defaultValue={rootTitle}
                            onChange={(e) => {
                                rootTitle = e
                                getData()
                            }} 
                        />
                        <ImbueSelect
                            title="Expiry"
                            options={expiryOptions}
                            defaultValue={expiryState}
                            onChange={(e) => {
                                setExpiryState(e)
                            }} 
                        />
                        <div className="CircleBtn CircleBtnLg" onClick={() => {getData()}}>
                            <i className="fas fa-play"></i>
                        </div>
                    </div>
                    
                </div>
                { dataReceived && !hasError && 
                    <div style={{width: '50%'}}>
                        <HighStockWrapper config={getChartConfig()}/>
                        {/* <HighStockWrapper config={deltaConfig}/>
                        <HighStockWrapper config={gammaConfig}/>
                        <HighStockWrapper config={vegaConfig}/> */}
                    </div>
                }
                { hasError && 
                    <h2>Error: data could not be retrieved. Try refreshing...</h2>
                }
        </Layout>
        );
    }
}
