import React, { Component } from "react";
import { api_blp } from "../../../../helpers/API";
import Axios from "axios";
import ImbueSelect from "../../../../components/input/ImbueSelect";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { highchartBasicConfig, COLORMAP } from "../../../../config";
import { getTickerName } from "../../../../index/TickerDictonary";

/**
 * props
 * | ticker1
 * | ticker2
 * | tickerlist
 * | datefrom
 * | dateto
 */

export default class CA_RollingCompare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTicker: "",
            series: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        let ticker1 = this.props.ticker1;
        let ticker2 = null;
        if (this.props.ticker2) {
            ticker2 = this.props.ticker2;
        } else {
            ticker2 = this.props.tickerlist[0];
        }
        this.setState(
            {
                selectedTicker: ticker2,
            },
            () => {
                this.onUpdateData(ticker1, ticker2);
            }
        );
    }

    onUpdateData(ticker1, ticker2) {
        this.setState({ isLoading: true }, () => {
            const datefrom = this.props.datefrom;
            let window = this.props.window;
            window = window === "ALL" ? "null" : window;
            Axios.all([api_blp(ticker1, "PX_LAST", datefrom, { rolling: window }), api_blp(ticker2, "PX_LAST", datefrom, { rolling: window })]).then((res) => {
                const series = res.map((ele) => ele.data.content);
                this.setState({
                    series,
                    isLoading: false,
                });
            });
        });
    }

    onChangeTicker(nTicker) {
        this.setState(
            {
                selectedTicker: nTicker,
            },
            () => {
                this.onUpdateData(this.props.ticker1, nTicker);
            }
        );
    }

    render() {
        const selectorOptions = this.props.tickerlist.map((ele) => ({ label: getTickerName(this.props.ticker1) + " | " + getTickerName(ele) + " - " + this.props.window, value: ele }));
        const selectorValue = { label: getTickerName(this.props.ticker1) + " | " + getTickerName(this.state.selectedTicker) + " - " + this.props.window, value: this.state.selectedTicker };
        const chartConfig = {
            ...highchartBasicConfig,
            chart: { ...highchartBasicConfig.chart, height: 300 },
            yAxis: [{ opposite: false }, { opposite: true }],
            tooltip: { shared: 1 },
            series: this.state.series
                ? [
                      {
                          data: this.state.series[0].data.PX_LAST.map((ele) => [Date.parse(ele[0]), ele[1]]),
                          name: getTickerName(this.state.series[0].ticker),
                          yAxis: 0,
                          color: COLORMAP.main,
                      },
                      {
                          data: this.state.series[1].data.PX_LAST.map((ele) => [Date.parse(ele[0]), ele[1]]),
                          name: getTickerName(this.state.series[1].ticker),
                          yAxis: 1,
                          color: COLORMAP.orange,
                      },
                  ]
                : [],
        };
        return (
            <div>
                <ImbueSelect
                    options={selectorOptions}
                    value={selectorValue}
                    onChange={(e) => {
                        this.onChangeTicker(e.value);
                    }}
                />
                <div style={{ margin: "30px 0", opacity: this.state.isLoading ? ".4" : "1" }}>
                    <HighchartsReact highcharts={Highcharts} options={chartConfig} />
                </div>
            </div>
        );
    }
}
