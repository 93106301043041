import { SECURITY_POOL, getTickerName } from "../../../index/TickerDictonary";

export const parseNumber = (str) => parseInt(str.replace(/,/g, ""));

export const formatNumber = (value) => {
    return parseInt(value.toString().replace(/,/g, "")).toLocaleString();

    // if (typeof value === "number") {
    //     return parseInt(value.toString().replace(/,/g, "")).toLocaleString();
    // }
    // const converted = parseInt(value.replace(/,/g, "")).toLocaleString();
    // if (converted) {
    //     console.log(value, converted);
    //     if (isNaN(converted)) {
    //         return value;
    //     } else {
    //         return converted;
    //     }
    // } else {
    //     return value;
    // }

    // if (value) {
    //     if (isNaN(value)) {
    //         return value;
    //     } else {
    //         value = value.toString();
    //         if (value.indexOf(".") > 0) {
    //             // float
    //             return (
    //                 parseInt(
    //                     value.split(".")[0].replace(/,/g, "")
    //                 ).toLocaleString() +
    //                 "." +
    //                 value.split(".")[1]
    //             );
    //         } else {
    //             // int
    //             return parseInt(value.replace(/,/g, "")).toLocaleString();
    //         }
    //     }
    // } else {
    //     return value;
    // }
};

export const formatAnything = (value) => {
    if (value) {
        if (isNaN(value)) {
            return value;
        } else {
            value = value.toString();
            if (value.indexOf(".") > 0) {
                // float
                return (
                    parseInt(
                        value.split(".")[0].replace(/,/g, "")
                    ).toLocaleString() +
                    "." +
                    value.split(".")[1]
                );
            } else {
                // int
                return parseInt(value.replace(/,/g, "")).toLocaleString();
            }
        }
    } else {
        return value;
    }
};

export const fullAssetsOptions = SECURITY_POOL.map((ele) => ({
    label: getTickerName(ele),
    value: ele.split("1")[0],
}));
