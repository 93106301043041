/**
 *
 * @author      Claude Chen
 * @company     Imbue Capital Pty Ltd
 * @created     2018
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

const bg_url =
    "https://images.unsplash.com/photo-1530089711124-9ca31fb9e863?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&dl=carlos-alfonso-AlBgcDfDG_s-unsplash.jpg";

const Layout = styled.div`
    font-family: var(--font-main);
    background: url(${bg_url});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 100%;
    overflow: auto;

    position: relative;

    .hp_fullpage {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .hp_halfpage {
    }

    .hp_section {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: rgba(29, 60, 52, 1);
        transition: all 1s;
    }

    .hp_spotlight {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-areas: "l r";
        background: rgb(245, 245, 245);
        height: 600px;
    }

    .hp_spotlight_img {
        background-position: center;
        background-size: cover;
    }

    .hp_bgFadeIn {
        animation: backgroundFadeIn 1s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
    }

    .learn_more {
        /* margin-top: 40px; */
    }

    .learn_more a {
        color: #999;
        transition: all 0.4s;
    }

    .learn_more a:hover {
        color: #fff;
    }

    @keyframes backgroundScaleIn {
        0% {
            background-size: 100%;
        }
        100% {
            background-size: 105%;
        }
    }

    @keyframes backgroundFadeIn {
        0% {
            background: rgba(29, 60, 52, 1);
        }
        100% {
            background: rgba(29, 60, 52, 0.7);
        }
    }

    @keyframes goDownEffect {
        0% {
            transform: translateY(-20px);
            opacity: 0;
        }
        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }
`;

export default class HomePage extends Component {

    componentDidMount() {
        document.title = "Home";
    }

    render() {
        return (
            <Layout pageHeight={window.innerHeight}>
                <div className="hp_section hp_fullpage hp_bgFadeIn">
                    <div>
                        <div style={{ color: "white", fontSize: "8rem" }}>
                            <Fade left delay={200} distance={"10px"}>
                                 Pochtecayotl Cloud
                            </Fade>
                        </div>
                        <div
                            className="fontSize__2p5rem fontColor__imbue_gold_light"
                            style={{
                                marginTop: "-10px",
                            }}
                        >
                            <Fade right delay={300} distance={"10px"}>
                                Welcome to Pochtecayotl's Financial Analytics
                                Platform
                            </Fade>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
