import React, { Component } from "react";
import MainBoard from "../../layout/MainBoard";
import SimulationLayout from "../../components/simulation/SimulationLayout";
/* 
meta:
- "strategyName"
- "strategyId"
- "strategyDescription"
*/

class SimulationWrapper extends Component {
    render() {
        const { strategyName, strategyId, strategyDescription } = this.props;
        let Simulation = () => <SimulationLayout isRaw={true} strategyMeta={{ strategyName, strategyId, strategyDescription }} />;

        // Prepare props for mainboard
        const mainboardList = [{ component: Simulation }];
        const mainboardTitle = strategyName;

        return <MainBoard title={mainboardTitle} list={mainboardList} />;
    }
}

export default SimulationWrapper;
