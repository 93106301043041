import React, { Component } from "react";
import styled from "styled-components";
import { toUnique } from "../../helpers/Utils";
import ImbueSelect from "../../components/input/ImbueSelect";
import { embed } from "./News/helper";
import { setLocalAsObject, getLocalAsObject } from "../../helpers/StoragController";
import { COUNTRYLOOKUP } from "../../index/CountryCode";
import ImbueSearchBox from "../../components/input/ImbueSearchBox";
import { api_couchdb } from "../../helpers/API";
import { GLOBALSTYLED } from "../../config";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)`
    height: 100%;
    .grid_layout {
        display: grid;
        grid-template-columns: 300px calc(100% - 300px);
        height: 100%;
        .side_bar {
            .side_bar_item {
                margin: 20px 0;
            }
            .termpool {
                font-family: roboto;
                display: flex;
                flex-wrap: wrap;
                background: #ddd;
                padding: 10px;
                border: 1px solid #ccc;
                .termtab {
                    padding: 2px 4px;
                    border: 1px solid #ccc;
                    background: #fff;
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
                .termtab:hover {
                    background: #dbc8c8;
                    cursor: pointer;
                }
            }
        }
        .dashboard {
            height: 100%;
            overflow: auto;
            .search_wrapper {
                padding: 10px 30px;
            }
        }
    }
    .dashboard_layout {
        padding: 30px;
        .embed_wrapper {
            background: #fff;
            padding: 20px;
            iframe {
                height: 450px;
            }
        }
    }
`;

// -- global
// -- -- Country
const countryOptions = Object.keys(COUNTRYLOOKUP).map((key) => ({
    label: key,
    value: key,
}));
const defaultCountry = countryOptions[0];
// -- -- Theme
const themeOptions = [
    "googletrend",
    "volume_timeline",
    "tone_timeline",
    "tone_bar",
    "source_map",
    "location_map",
    "tags_wordcloud",
    "topics_wordcloud",
].map((ele) => ({
    label: ele.split("_").join(" "),
    value: ele,
}));
const defaultTheme = themeOptions[0];

// -- helpers
const termsToOptions = (terms) => {
    const groups = toUnique(terms.map((ele) => ele["category"])).sort();
    const grouped = groups.map((g) => ({
        label: g,
        options: terms
            .filter((ele) => ele.category === g)
            .map((ele) => ({
                label: ele["term"],
                value: ele["term"],
            })),
    }));
    return grouped;
};

class MynewsDashboard extends Component {
    state = {};

    render() {
        const theme = this.props.theme;
        return (
            <div className="dashboard_layout">
                {this.props.terms.map((term) => {
                    return (
                        <div className="embed_wrapper" key={term + theme}>
                            <h3>{term}</h3>
                            {embed(theme, term, this.props.country)}
                        </div>
                    );
                })}
            </div>
        );
    }
}

class Mynews extends Component {
    state = {
        terms: [],
        termpool: getLocalAsObject("mynews.terms") || [],
        selectedTerms: getLocalAsObject("mynews.terms") || [],
        selectedCountry: defaultCountry.value,
        selectedTheme: defaultTheme.value,
        isSaved: 1,
    };

    componentDidMount() {
        api_couchdb("imbuecloud_index_tables", "news_keyword_lookup").then((res) => {
            this.setState({
                terms: res.data.content.index,
            });
        });
    }

    onSelectTerm = (e) => {
        this.setState({ isSaved: 0 }, () => {
            let term = e.value || e;
            let termpool = this.state.termpool;
            if (termpool.indexOf(term) < 0) {
                termpool = [...termpool, term];
                this.setState({
                    termpool,
                });
            } else {
                this.setState({
                    termpool: termpool.filter((ele) => ele.value !== term),
                });
            }
        });
    };

    onEnterMultipleTerms = (rawstr) => {
        this.setState({ isSaved: 0 }, () => {
            const terms = rawstr
                .trim()
                .split(",")
                .map((ele) => ele.toLowerCase());
            let newTerms = this.state.termpool.concat(terms).filter((ele) => ele);
            newTerms = toUnique(newTerms);
            this.setState({
                termpool: newTerms,
            });
        });
    };

    onDeselectTerm = (term) => {
        this.setState({
            isSaved: 0,
            termpool: this.state.termpool.filter((t) => t !== term),
        });
    };

    onConfirm = () => {
        this.setState({ selectedTerms: this.state.termpool, isSaved: 1 }, () => {
            setLocalAsObject("mynews.terms", this.state.selectedTerms);
        });
    };

    render() {
        const termOptions = termsToOptions(this.state.terms);
        return (
            <Layout>
                <div className="grid_layout">
                    <div className="side_bar">
                        <div className="side_bar_item">
                            <ImbueSelect
                                title="theme"
                                options={themeOptions}
                                defaultValue={defaultTheme}
                                onChange={(e) => this.setState({ selectedTheme: e.value })}
                            />
                        </div>
                        <div className="side_bar_item">
                            <ImbueSelect
                                title="Select a term"
                                options={termOptions}
                                onChange={(e) => this.onSelectTerm(e)}
                            />
                        </div>
                        <div className="side_bar_item">
                            <div className="termpool">
                                {this.state.termpool.map((t) => {
                                    return (
                                        <div onClick={() => this.onDeselectTerm(t)} className="termtab">
                                            {t}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="side_bar_item">
                            <ImbueSelect
                                title="country"
                                options={countryOptions}
                                defaultValue={defaultCountry}
                                onChange={(e) => this.setState({ selectedCountry: e.value })}
                            />
                        </div>
                        {this.state.isSaved ? (
                            ""
                        ) : (
                            <div className="side_bar_item">
                                <div className="FormBtn" onClick={this.onConfirm}>
                                    Save and Update
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="dashboard">
                        <div className="search_wrapper">
                            <ImbueSearchBox
                                placeholder="Type search term and press Enter to add"
                                afterKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.onEnterMultipleTerms(e.target.value);
                                    }
                                }}
                            />
                        </div>
                        <MynewsDashboard
                            country={this.state.selectedCountry}
                            terms={this.state.selectedTerms}
                            theme={this.state.selectedTheme}
                        />
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Mynews;
