import React, { Component } from "react";
import DEMODATA from "./DEMODATA.json";
import styled from "styled-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsHeatmap from "highcharts/modules/heatmap";

import { api_corr_analysis } from "../../../helpers/API";
import ImbueSelect from "../../../components/input/ImbueSelect";
import { PlaceholderPage, LoadingPage } from "../../../helpers/SimpleComponents";
// import { heatmapConfig } from "./helpers";
import { highchartBasicConfig, COLORMAP } from "../../../config";
import CaRollingCompare from "./components/CA_RollingCompare";
import CaTreemap from "./components/CA_Treemap";
import moment from "moment";
import { getTickerName, getTicker } from "../../../index/TickerDictonary";
import {verifyDateFormat} from "../../../HelperFuncs.js";

highchartsHeatmap(Highcharts);

const Layout = styled.div`
    padding: 30px;

    .grid_top {
        display: flex;
        align-items: center;
    }

    .grid_main {
        box-sizing: border-box;
        overflow: auto;
    }
`;

const rootMap = {
    "C " : "Corn [CBOT]",
    "W " : "Wheat [CBOT]",
    "MW" : "Spring Wheat",
    "KW" : "Wheat [KCBT]",
    "S " : "Soybean [CBOT]",
    "SM" : "Soybean Meal [CBOT]",
    "BO" : "Soybean Oil [CBOT]",
    "FC" : "Feeder Cattle [CME]",
    "LC" : "Live Cattle [CME]",
    "LH" : "Lean Hogs [CME]",
    "CL" : "Crude Oil [NYMEX]",
    "RB" : "Harbor Gasoline [NYMEX]",
    "HO" : "Heating Oil [NYMEX]",
    "NG" : "Natural Gas [NYMEX]",
    "CO" : "Brent Crude Oil [ICE]",
    "QS" : "Gas Oil [ICE]",
    "GC" : "Gold [COMEX]",
    "HG" : "Copper [COMEX]",
    "PA" : "Palladium [NYMEX]",
    "PL" : "Platinum [NYMEX]",
    "SI" : "Silver [COMEX]",
    "KC" : "Coffee [NYBOT]",
    "CC" : "Cocoa [NYBOT]",
    "RR" : "Rough Rice [CBOT]",
    "SB" : "Sugar [NYBOT]",
    "CT" : "Cotton [NYBOT]",
    "DX" : "Dollar Index",
    "SFR" : "3 Month SOFR",
    "BP" : "British Pound",
    "EC" : "Euro FX",
    "JY" : "Japanese Yen",
    "BR" : "Brazil Real",
    "CD" : "Canadian Dollar",
    "AD" : "Australian Dollar",
    "NV" : "New Zealand Dollars",
    "PE" : "Mexican Peso",
    "US" : "US Treasury Bond",
    "WN" : "Ultra US Treasury Bond",
    "TY" : "10-Year T-Note",
    "FV" : "5-Year T-Note",
    "TU" : "2-Year T-Note",
    "ES" : "S&P500 E-mini",
    "NQ" : "Nasdaq 100 E-mini",
    "YM" : "Dow Jones E-mini",
    "RTY" : "Russell 2000 E-mini",
    "BTC" : "Bitcoin",
    "FF" : "30-Day Federal Funds",
};

// Have a way to access by rootMap's key or value
const reverseRootMap = Object.fromEntries(Object.entries(rootMap).map(
    ([key, value]) => [value, key]
));


const heatmapConfig = (paras, callback, root) => {
    let data = [];
    for (let i = 0; i < paras.data.length; i++) {
        for (let j = 0; j < paras.data[i].length; j++) {
            if (paras.y[j] === rootMap[root] && i !== j) {
                data.push([i, j, paras.data[i][j]]);
            }
        }
    }

    return {
        title: highchartBasicConfig.title,
        credits: highchartBasicConfig.credits,
        chart: {
            type: "heatmap",
            backgroundColor: "transparent",
            style: {
                fontFamily: "roboto",
            },
            height: 100 + 0.3 * data.length,
            animation: false,
            marginBottom: 0,
        },
        tooltip: {
            useHtml: true,
            outside: true,
            formatter: function () {
                let xLabel = this.series.xAxis.categories[this.point.x];
                let yLabel = this.series.yAxis.categories[this.point.y];
                // avoids crashing from attemping .toFixed() on null
                let value = this.point.value == null ? this.point.value : this.point.value.toFixed(3);
                let textColor = value >= 0 ? COLORMAP.green : COLORMAP.red;
                const HTML = `
                <div style="font-family: Lato">
                    <span style="color: #444;">${rootMap[xLabel]} </span><br>
                    <span style="color: #444;"> (Click to show more) </span><br>
                    <span style="color: ${textColor}; font-size: 1.5rem;">${yLabel}: ${value}</span>
                </div>
                `;
                return HTML;
            },
        },
        xAxis: {
            categories: paras.x.map(x => reverseRootMap[x]),

            labels: {
                rotation: 0,
                y: -40,
                style: {
                    color: "#000000"
                }
            }
        },
        yAxis: {
            categories: paras.y.map(x => reverseRootMap[x]),
            title: null,
            reversed: true,
        },
        colorAxis: {
            min: -1,
            max: 1,
            stops: [
                [0, "#a10000"],
                [0.3, "#ffcfcf"],
                [0.5, "#FFFFFF"],
                [0.7, "#d6ffd6"],
                [1, "#005200"],
            ],
        },
        legend: {
            align: "right",
            layout: "vertical",
            margin: 0,
            //verticalAlign: "top",
            y: 10,
            symbolHeight: 68,
        },
        series: [
            {
                name: "Correlation",
                borderWidth: 1,
                data: data,
            },
        ],
        plotOptions: {
            heatmap: {
                events: {
                    click: function (event) {
                        let pivotX = paras.x[event.point.x];
                        const treemapdata = [
                            {
                                name: pivotX,
                                id: pivotX,
                            },
                            ...paras.data[event.point.x]
                                .map((item, idx) => ({
                                    name: paras.y[idx],
                                    parent: pivotX,
                                    value: Math.abs(item),
                                    colorValue: item,
                                }))
                                .filter((item) => item.name !== pivotX),
                        ];
                        callback(paras.x[event.point.x], paras.y[event.point.y], treemapdata);
                    },
                },
            },
        },
    };
};

class CorrelationAnalytics extends Component {
    state = {
        pageIndex: 0, // 0. heatmap 1. correlation and treemap
        data: DEMODATA,
        selectedWindow: this.props.selectedWindow,
        pivotSecurity_x: "",
        pivotSecurity_y: "",
        treemapdata: [],
    }

    componentDidMount() {}

    onChangeWindow = (window)  => {
        if (this.state.selectedWindow !== window) {
            this.setState({ selectedWindow: window });
        }
    }

    onSelectSecurity = (pivotSecurity_x, pivotSecurity_y, treemapdata) => {
        this.setState({
            pageIndex: 1,
            pivotSecurity_x,
            pivotSecurity_y,
            treemapdata,
        });
    }

    render() {
        const callback = (x, y, treemapdata) => {
            this.onSelectSecurity(x, y, treemapdata);
        };

        if (this.state.pageIndex === 0) {
            if (this.props.data) {
                const selectedWindow = this.state.selectedWindow;
                let chartList = [];

                if (selectedWindow === "ALL") {
                    chartList = ["1D", "30D", "90D", "180D", "365D"];
                } else {
                    chartList = [selectedWindow];
                }
            
                const chartOptionsList = chartList.map((ele) => ({
                    label: ele,
                    data: this.props.data.result.corr[ele].data,
                    x: this.props.data.result.corr[ele].columns,
                    y: this.props.data.result.corr[ele].index,
                }));

                // const options_window = [
                //     { label: "ALL", value: "ALL" },
                //     { label: "1 Day", value: "1D" },
                //     { label: "1 Month", value: "30D" },
                //     { label: "3 Months", value: "90D" },
                //     { label: "6 Months", value: "180D" },
                //     { label: "1 Year", value: "365D" },
                // ];

                return (
                    <div>
                        {/* <div className="ImbueSelectWrapper">
                            <div className="ImbueSelectItem">
                                <ImbueSelect title="window" defaultValue={options_window[2]} options={options_window} onChange={(e) => this.onChangeWindow(e.value)} />
                            </div>
                        </div> */}
                        {chartOptionsList.map((ele, idx) => {
                            ele.x = ele.x.map((t) => getTickerName(t));
                            ele.y = ele.y.map((t) => getTickerName(t));
                            const options = heatmapConfig(ele, callback, this.props.root);
                            return (
                                <div key={idx} style={{}}>
                                    {/* <h3>{ele.label}</h3> */}
                                    <HighchartsReact highcharts={Highcharts} options={options} />
                                </div>
                            );
                        })}
                    </div>
                );
            } else {
                return <div style={{ padding: "5px" }}>{PlaceholderPage("")}</div>;
            }
        } else {
            return (
                <div>
                    <h3>
                        {this.state.pivotSecurity_x} ({this.state.selectedWindow})
                    </h3>
                    <div className="flexBox">
                        <div className="SmallBtnAlt" 
                            onClick={() => this.setState({ pageIndex: 0 })}
                            onMouseDown={() => this.setState({ pageIndex: 0 })}
                        >
                            <i className="fal fa-angle-left"></i>Back
                        </div>
                    </div>
                    {/* #### TREEMAP #### */}
                    <h3>Correlation with {this.state.pivotSecurity_x}</h3>
                    <CaTreemap
                        {...{
                            data: this.state.treemapdata,
                        }}
                    />
                    {/* #### Rolling Comparison #### */}
                    <h3>Select ticker to compare</h3>
                    <CaRollingCompare
                        {...{
                            ticker1: getTicker(this.state.pivotSecurity_x, "1 A:00_0_R Comdty"),
                            ticker2: getTicker(this.state.pivotSecurity_y, "1 A:00_0_R Comdty"),
                            tickerlist: this.props.data.tickers,
                            datefrom: this.props.paras.datefrom,
                            window: this.state.selectedWindow,
                        }}
                    />
                </div>
            );
        }
    }
}

let firstRun = true;
export default class CorrelationAnalyticsWrapper extends Component {
    state = {
        selectedSector: "All",
        datefrom: "2018-05-08", // start of SOFR data
        dateto: moment().format("YYYY-MM-DD"),
        selectedWindow: "30D",
        corr_data: null,
        isLoading: false,
        isValidDate: true,
        warning: "",
        lastDate: "",
    }


    onChangeWindow = (window)  => {
        if (this.state.selectedWindow !== window) {
            this.setState({ selectedWindow: window });
        }
    }


    onChangeSector = (sector) => {
        if (this.state.selectedSector !== sector) {
            this.setState({ selectedSector: sector });
        }
    }


    onUpdate = () => {
        if (!this.state.isValidDate) {
            this.setState({ warning: "invalid date format" });
        } else {
            if (this.state.warning !== "") {
                this.setState({ warning: "" });
            }
            const paras = {
                sector: this.state.selectedSector,
                datefrom: this.state.datefrom,
                dateto: this.state.dateto,
            };
            this.setState({ isLoading: true }, () => {
                api_corr_analysis(paras).then((res) => {
                    this.setState({
                        corr_data: res.data,
		                lastDate: "Data: " + res.data.first_date + " - " + res.data.last_date,
                        isLoading: false,
                    });
                });
            });
        }
    }


    componentDidUpdate(prevProps) {
        if (prevProps.root !== this.props.root) {
            this.onUpdate();
        }
    }


    componentWillUnmount() {
        firstRun = true;
        // console.log("dc unmount")
    }
    


    render() {
        if (firstRun) {
            firstRun = false;
            this.onUpdate();
        }
        // const options_sector = [
        //     { label: "All Commodities", value: "All" },
        //     { label: "Grains & Oilseeds", value: "AgricultureGO" },
        //     { label: "Livestock", value: "AgricultureLS" },
        //     { label: "Softs", value: "Softs" },
        //     { label: "Energy", value: "Energy" },
        //     { label: "Metal", value: "Metal" },
        // ];
        const options_window = [
            { label: "ALL", value: "ALL" },
            { label: "1 Day", value: "1D" },
            { label: "1 Month", value: "30D" },
            { label: "3 Months", value: "90D" },
            { label: "6 Months", value: "180D" },
            { label: "1 Year", value: "365D" },
        ];
        return (
            <Layout>
            <div className="row">
                <div className="ImbueSelectWrapper">
                    <div className="ImbueSelectItem" style={{marginTop: "12px"}}>
                        <ImbueSelect title="time window (smoothing)" defaultValue={options_window[2]} options={options_window} onChange={(e) => this.onChangeWindow(e.value)} />
                    </div>
                </div>
                <div className="grid_top" style={{marginLeft: "10px"}}>
                    <div className="ImbueSelectsWrapper">
                        <div className="ImbueSelectItem">
                            <div className="label">From:</div>
                            <input
                                className="ImbueInput"
                                type="text"
                                defaultValue={this.state.datefrom}
                                onChange={(e) => {
                                    // ensure proper date format and datefrom is not a future date
                                    if (verifyDateFormat(e.target.value) && moment(e.target.value).isBefore(moment().format("YYYY-MM-DD"))) {
                                        this.setState({ datefrom: e.target.value, isValidDate: true });
                                    } else {
                                        this.setState({ isValidDate: false})
                                    }
                                }}
                            />
                        </div>
                        <div className="ImbueSelectItem">
                            <div className="label">To:</div>
                            <input
                                className="ImbueInput"
                                type="text"
                                defaultValue={this.state.dateto}
                                onChange={(e) => {
                                    // ensure proper date format and dateto is not before datefrom
                                    if (verifyDateFormat(e.target.value) && moment(this.state.datefrom).isBefore(moment(e.target.value))) {
                                        this.setState({ dateto: e.target.value, isValidDate: true });
                                    } else {
                                        this.setState({ isValidDate: false})
                                    }
                                }}
                            />
                        </div>
                        <div className="CircleBtn CircleBtnLg" onClick={this.onUpdate}>
                            {this.state.isLoading ? <i style={{ animation: "rotate 1s infinite" }} className="fas fa-spinner"></i> : <i style={{ marginLeft: "3px" }} className="fas fa-play"></i>}
                        </div>
                        <p style={{marginLeft: "10px"}}>
                            {this.state.lastDate}
                        </p>
                        <h3 style={{marginLeft: "10px", color: "red"}}>
                            {this.state.warning}
                        </h3>
                    </div>
                </div>
            </div>
                <div className="grid_main">
                    {this.state.isLoading ? (
                        LoadingPage()
                    ) : (
                        <CorrelationAnalytics
                            root={this.props.root}
                            data={this.state.corr_data}
                            paras={{ datefrom: this.state.datefrom, dateto: this.state.dateto }}
                            selectedWindow={this.state.selectedWindow}
                        />
                    )}
                </div>
            </Layout>
        );
    }
}
