import React, { Component } from "react";
import { api_data_noaa_daily_station_meta, api_data_noaa_daily_data } from "../../../helpers/API";
import styled from "styled-components";

import MultiFunctionalChart from "../../../components/chart/MultiFunctionalChart";
import ImbueSelect from "../../../components/input/ImbueSelect";

import { noaaDataTypeDict } from "../../../index/NoaaDataType";
import { generateSeasonal } from "../../../helpers/ChartFunctions";
import { hasItem } from "../../../helpers/Utils";
import { LoadingPage } from "../../../helpers/SimpleComponents";
import { COLORMAP } from "../../../config";
const namedict = noaaDataTypeDict;

const Layout = styled.div`
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    font-family: var(--font-main);
    background: #fff;
    box-shadow: var(--boxshadow-smooth);
`;

export default class noaa_card extends Component {
    constructor(p) {
        super(p);
        this.state = {
            meta: [],
            timeseries: [],
            seasonalStart: 1,
            isLoading: false
        };
    }

    componentDidMount() {
        if (this.props.payload) {
            this.getData();
        }
    }

    getData() {
        const station_id = this.props.payload.id;
        api_data_noaa_daily_station_meta(station_id).then(res => {
            this.setState(
                {
                    meta: res.data.message
                },
                () => {
                    this.getTableData(res.data.message[0].table_name);
                }
            );
        });
    }

    getTableData(table_name) {
        this.setState({ isLoading: true }, () => {
            api_data_noaa_daily_data(table_name, "2010-01-01").then(res => {
                const data = res.data.message;
                this.setState({
                    table_name,
                    timeseries: data,
                    isLoading: false
                });
            });
        });
    }

    onChangeOption(tablename) {
        this.getTableData(tablename);
    }

    onChangeSeasonalStart(newStart) {
        this.setState({
            seasonalStart: newStart
        });
    }

    render() {
        const payload = this.props.payload;

        // ==== state ====
        const meta = this.state.meta.map(ele => {
            const itemCode = ele.table_name.split("_")[3].toUpperCase();
            const itemName = itemCode in namedict ? `${itemCode}: ${namedict[itemCode]}` : itemCode;
            return {
                ...ele,
                itemCode,
                itemName
            };
        });
        const timeseries = this.state.timeseries;
        const table_name = this.state.table_name;
        const seasonalStart = this.state.seasonalStart;

        // ==== Other ====
        const seasonalStartOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele, idx) => ({
            label: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][idx],
            value: ele
        }));

        if (!payload) {
            return <div />;
        } else {
            const selected = meta.filter(ele => ele.table_name === table_name)[0] || {};
            const itemCode = selected.itemCode;
            let calCum = false;
            if (hasItem(itemCode, ["SNOW", "SNWD", "PRCP"])) {
                calCum = true;
            }
            const chartData = {
                line: [
                    {
                        name: table_name,
                        data: timeseries.map(ele => [Date.parse(ele.date), ele.px_last]),
                        color: COLORMAP.main
                    }
                ],
                seasonal: generateSeasonal(
                    timeseries.map(ele => [ele.date, ele.px_last]),
                    false,
                    seasonalStart,
                    calCum
                )
            };
            return (
                <Layout>
                    <div className="flexBox flexBox_between">
                        <h3>{payload.station_name}</h3>
                        <small style={{ color: "#888" }}>{payload.id}</small>
                    </div>
                    <small>
                        [{payload.latitude}, {payload.longitude}]{" "}
                    </small>
                    <section>
                        <div className="grid_layout grid_layout_w_gap grid_layout_1">
                            <div>
                                <div style={{ margin: "10px 0" }}>Datapoints</div>
                                <ImbueSelect
                                    options={meta.map(ele => {
                                        return {
                                            label: ele.itemName,
                                            value: ele.table_name
                                        };
                                    })}
                                    value={{
                                        label: selected.itemName,
                                        value: selected.table_name
                                    }}
                                    onChange={e => {
                                        this.onChangeOption(e.value);
                                    }}
                                />
                            </div>
                            <div>
                                <div style={{ margin: "10px 0" }}>Seasonal Start</div>
                                <ImbueSelect
                                    options={seasonalStartOptions}
                                    defaultValue={seasonalStartOptions[0]}
                                    onChange={e => {
                                        this.onChangeSeasonalStart(e.value);
                                    }}
                                />
                            </div>
                        </div>
                    </section>

                    {this.state.isLoading ? LoadingPage() : <MultiFunctionalChart chartid={payload.id + "." + seasonalStart} height={500} data={chartData} />}
                </Layout>
            );
        }
    }
}
