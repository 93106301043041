/**
 *
 * Parse csv text to object
 *
 * NOTE: this function only extract raw string values, further formatting is required
 *
 * @param {string} text
 */
export function parseCsv(text) {
    text = text.split("\n").map((row) => row.split(","));
    let headers = text[0];
    let rows = text.slice(1, -1);
    return {
        headers,
        rows,
    };
}

/**
 *
 * @param {list} list_of_dict   | list of dictionary
 * @param {string} key          | which key to calculate cum sum
 * @param {string} targetKey    | key to store cum sum
 */
export function dictCumSum(list_of_dict, key, targetKey) {
    let base = 0;
    for (let index = 0; index < list_of_dict.length; index++) {
        const current = list_of_dict[index][key];
        base += current;
        list_of_dict[index][targetKey] = base;
    }
    return list_of_dict;
}
