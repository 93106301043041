import React, { Component } from "react";
import Select from "react-select";
import { COLORMAP } from "../../config";

/**
 *
 * Imbue Made dropdown selection menu
 *
 * props
 * -----
 *
 * > options, list, REQUIRED
 * e.g. [{label: "option1", value: 1}, {label: "option2", value: 2}]
 *
 * > value, object, REQUIRED
 * e.g. {label: xxx, value: yyy}
 *
 * > onChange, callback, REQUIRED
 * e.g. (e) => {this.onChangeOption(e)} (e is {label: xxx, value: yyy})
 *
 */
class ImbueSelect extends Component {
    state = {
        cnt: 0,
    }

    getValues = () => {
        return this.refs.imbueselect.state.value;
    }

    onMenuClose = () => {
        this.setState({ cnt: this.state.cnt + 1 });
    }

    render() {
        const options = this.props.options; // required
        const value = this.props.value; // required
        const onChange = this.props.onChange; // required

        const width = this.props.width;
        const title = this.props.title || this.props.label;
        const onKeyDown = this.props.onKeyDown;
        

        const { cnt } = this.state;


        const theme = (theme) => {
            return {
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary: COLORMAP.main,
                    primary25: "#ddd",
                    primary50: "#ddd",
                    primary75: "#ddd",
                },
            };
        };

        const styles = {
            valueContainer: (base) => ({
                ...base,
                fontFamily: "var(--font-main)",
                fontSize: "1.2rem",
            }),
            option: (base) => ({
                ...base,
                fontFamily: "var(--font-main)",
                fontSize: "1.2rem",
            }),
        };

        const formatGroupLabel = (data) => (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px 0",
                }}
            >
                <span
                    style={{
                        fontFamily: "Open Sans",
                        fontSize: "1.4rem",
                        color: "#333",
                    }}
                >
                    <i
                        className="fas fa-archive"
                        style={{
                            marginRight: "2px",
                            animation: "scaleUp .2s",
                        }}
                    ></i>
                    <span style={{ color: "#999" }}> | </span>
                    {data.label}
                </span>
                <span style={{}}>{data.options.length}</span>
            </div>
        );

        return (
            <div className="ImbueSelectWrapper" style={{ maxWidth: width }}>
                <div className="ImbueSelectLabel">{title}</div>
                <Select
                    key={cnt}
                    className="ImbueSelect"
                    classNamePrefix="ImbueSelect"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    options={options}
                    value={value}
                    styles={styles}
                    formatGroupLabel={formatGroupLabel}
                    backspaceRemovesValue={0}
                    theme={theme}
                    {...this.props}
                />
            </div>
        );
    }
}

export default ImbueSelect;
