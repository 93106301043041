/**
 * ReportContainer.js
 * -- Imbue market report template component
 *
 * Claude Chen | claude.chen@imbuecapital.com
 *
 * required sub-components:
 * ReportContainer -> ReportChart -> MultiFunctionalChart
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import { toUnique } from "../../../helpers/Utils";
import { LoadingPage, PlaceholderPage } from "../../../helpers/SimpleComponents";
import ReportChart from "../../../components/chart/ReportChart";
import ImbueSelect from "../../../components/input/ImbueSelect";

const Layout = styled.div`
    height: 100%;
    width: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: 200px calc(100% - 200px);

    .layout_sidebar {
        border-right: 1px solid #aaa;
        padding: 0 5px;
        display: grid;
        grid-template-rows: 200px calc(100% - 200px);
    }

    .layout_main {
        overflow: auto;
        height: 100%;
    }

    .main_report_wrapper {
        display: grid;
        grid-template-columns: 200px calc(100% - 200px);
        grid-template-rows: 100%;
        height: 100%;
    }

    .main_report_wrapper .SideBar_layout {
        border-right: 1px solid #aaa;
        padding: 0 5px;
    }

    .main_report_wrapper .MainReport_layout {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 60px calc(100% - 60px);
    }

    .main_report_wrapper .MainReport_layout .layout_main_topbar {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 6px 10px;
        background: #eee;
        border-bottom: 1px solid #ddd;
    }

    .main_report_wrapper .MainReport_layout .layout_main_charts_wrapper {
        overflow: auto;
        height: 100%;
    }

    .main_report_wrapper .MainReport_layout .layout_main_charts_wrapper .main_report_grid {
        overflow: auto;
        display: grid;
        grid-template-rows: 1fr;
    }
`;

class MainReport extends Component {
    state = {
        selectedLevel: null,
        gridmode: 3,
        selectedChartIdx: null,
    };

    componentDidMount() {
        const categories = toUnique(this.props.list.map((ele) => ele.level3));
        if (categories.length > 0) {
            this.setState({
                selectedLevel: categories[0],
            });
        }
    }

    onChangeGridMode = (newMode) => {
        if (newMode !== this.state.gridmode) {
            this.setState({
                gridmode: newMode,
            });
        }
    };

    onClickFullscreen = (chartidx) => {
        if (this.state.selectedChartIdx === chartidx) {
            this.setState({ selectedChartIdx: null });
        } else {
            this.setState({ selectedChartIdx: chartidx });
        }
    };

    render() {
        const tickerList = this.props.list;
        const categories = toUnique(tickerList.map((ele) => ele.level3));
        const selectedLevel = this.state.selectedLevel;
        const filtered = tickerList.filter((ele) => ele.level3 === selectedLevel).filter((ele, idx) => (this.state.selectedChartIdx !== null ? this.state.selectedChartIdx === idx : true));

        const gridmode = this.state.gridmode;
        const gridmodedict = {
            1: "100%",
            2: "50% 50%",
            3: "33% 34% 33%",
            4: "repeat(4, 25%)",
            5: "repeat(5, 20%)",
        };

        if (tickerList.length > 0) {
            return (
                <div className="main_report_wrapper">
                    <div className="SideBar_layout">
                        <div className="SideBar_title">Category</div>
                        <div className="SideBar_scrollable">
                            {categories.map((ele) => (
                                <div
                                    key={ele}
                                    className={"SideBarBtn" + (selectedLevel === ele ? " SideBarBtnSelected" : "")}
                                    onClick={() => {
                                        this.setState({ selectedLevel: ele });
                                    }}
                                >
                                    {ele}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="MainReport_layout">
                        <div className="layout_main_topbar">
                            <div className="imbue_select_wrapper">
                                <div className="imbue_select_label">Zoom: </div>
                                <div className="imbue_select_main">
                                    <ImbueSelect
                                        options={[1, 2, 3, 4, 5].map((ele) => ({ label: ele + " Columns", value: ele }))}
                                        value={{ label: gridmode + " Columns", value: gridmode }}
                                        onChange={(e) => {
                                            this.onChangeGridMode(e.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="layout_main_charts_wrapper">
                            <div className="main_report_grid" style={{ gridTemplateColumns: gridmodedict[gridmode] }}>
                                {filtered.map((ele, idx) => (
                                    <div key={selectedLevel + idx}>
                                        <ReportChart
                                            height={350}
                                            title={ele.Description}
                                            country={ele.Country}
                                            ticker={ele.Ticker}
                                            field={ele.Field}
                                            src={ele.level3}
                                            tableSize={10}
                                            // fullscreenBtnAction={() => {
                                            //     this.onClickFullscreen(idx);
                                            // }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return PlaceholderPage("No Select");
        }
    }
}

export default class ReportContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLevel1: null,
            selectedLevel2: null,
        };
        this.onSelectLevel = this.onSelectLevel.bind(this);
    }

    onSelectLevel(selected, level) {
        switch (level) {
            case 1:
                this.setState({
                    selectedLevel1: selected,
                });
                break;
            default:
                this.setState({
                    selectedLevel2: selected,
                });
                break;
        }
    }

    render() {
        const index = this.props.index;

        const selectedLevel1 = this.state.selectedLevel1;
        const selectedLevel2 = this.state.selectedLevel2;

        if (index) {
            const level1_list = toUnique(index.map((ele) => ele.level1));
            const level2_list = toUnique(index.filter((ele) => ele.level1 === selectedLevel1).map((ele) => ele.level2));
            const filtered = index.filter((ele) => selectedLevel1 === ele.level1 && selectedLevel2 === ele.level2);

            return (
                <Layout>
                    <div className="layout_sidebar">
                        <div className="SideBar_layout">
                            <div className="SideBar_title">Asset Class</div>
                            <div className="SideBar_scrollable">
                                {level1_list.map((ele) => {
                                    return (
                                        <div
                                            className={"SideBarBtn" + (ele === selectedLevel1 ? " SideBarBtnSelected" : "")}
                                            onClick={() => {
                                                this.onSelectLevel(ele, 1);
                                            }}
                                            key={ele}
                                        >
                                            {ele}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {selectedLevel1 ? (
                            <div className="SideBar_layout">
                                <div className="SideBar_title">Sector</div>
                                <div className="SideBar_scrollable">
                                    {level2_list.map((ele) => {
                                        return (
                                            <div
                                                className={"SideBarBtn" + (ele === selectedLevel2 ? " SideBarBtnSelected" : "")}
                                                key={ele}
                                                onClick={() => {
                                                    this.onSelectLevel(ele, 2);
                                                }}
                                            >
                                                {ele}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <div className="SideBar_layout">
                                <div className="SideBar_title">
                                    <i style={{ fontSize: "1rem", color: "#ccc" }}>Please select asset</i>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="layout_main">
                        <MainReport key={selectedLevel1 + selectedLevel2} list={filtered} />
                    </div>
                </Layout>
            );
        } else {
            return LoadingPage();
        }
    }
}
