import React, { useState } from 'react'
import styled from 'styled-components';
import { GLOBALSTYLED } from '../../config';
import S3PdfViewer from "../../components/pdfViewer/S3PdfViewer";
import ImbueTabs from "../../components/tabs/ImbueTabs";
import { by_overload, by_common, valid_common } from "../../universalSymbolMap";
import {symbol_url_map} from "../../ContractUrlMap";
import "./style.css";
import TradesHeatmap from "./components/TradesHeatmap";
import DashboardDeltas from "../../components/chart/DashboardDeltas";
import DashboardSeasonality from "./DashboardSeasonality";
import CorrelationAnalytics from "./CorrelationAnalytics/DashboardCorrelation";
import DashboardQuoteBoard from "../../quoteBoard/DashboardQuoteBoard";
import IntradayMovement  from "./DashboardIntradayMovement";
import DashboardOptionsIV from "./DashboardOptionsIV";
import BidAskAverages from "./BidAskAverages";
import DashboardCalendarSpreads from "./DashboardCalendarSpreads";
import LiveBidAskAverages from "./LiveBidAskAverages";
import TopStrikesTable from '../../components/table/TopStrikesTable';
import ExpectedRisk from './ExpectedRisk';
import DashboardDeltaICE from './DashboardDeltaICE';
import { useParams, useHistory, Redirect } from "react-router-dom";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;
const cellBorder = "2px solid #447463";
const linkRow = {marginLeft: "30px"}
const noShowSymbols = ["QS"] // add symbols from Universal Symbol Map that should NOT be shown in Dashboard

const tab_set = valid_common().filter(root => !noShowSymbols.includes(root))
	.map(root => ({ 'label': root, 'value': by_common(root).bloomberg_root}));
	  
const tab_index = tab_set.reduce(
    (hash, entry, index) => { hash[entry.label] = index; return(hash); }, {}
);

// TODO: turn into dynamic list of tickers from above
const quoteBoardRoots = [
	"ZS", "ZM", "ZL", "ZC", "ZW", "KE", "MWE", "ZR",
	"GF", "LE", "HE", "LBR",
	"CL", "NG", "RB", "HO",
	"GC", "SI", "HG", "PL",
	"DX", "6E", "6J", "CNH", "6B", "6C", "6A", "6L", "6M",
	"SR3", "ZT", "Z3N", "ZF", "ZN", "ZB",
	"ES", "YM", "NQ", "RTY",
	"BTC", "ETH",
	"CC", "BRN", "CT", "DC", "GE", "ZQ", "KC", "6N", "ZO", "PA", "SB", "6S", "UB" 
	];

export default function Dashboard() {

    const {root} = useParams();
    const history = useHistory();
    var state_index = tab_index['S']; // default to S, index: 41
    var base_path = '/dashboard';
	var base_title = 'Dashboard';
	

    // was a root provided?
    if (root) {
		// look up the root in the overload table
		const mapped = by_overload(root);
		// root found in overload?
		if (mapped) {
			// does it point to a valid common root?
			if (mapped.common in tab_index) {
				// get state_index for mapped.common from tab set index
				state_index = tab_index[mapped.common];
				// is the mapped common root different from the requested overload root?
				if (root !== mapped.common) {
					// root was translated to common, redirect
					const path = `${base_path}/${mapped.common}`;
					// Warning: Cannot update during an existing state transition (such as within `render`).
					// Render methods should be a pure function of props and state.
					history.replace(path);
					//history.push(path);
				}
			}
		} else {
			// root was not found in overload, redirect to remove invalid root
			return (
			<div>
				<Redirect to={base_path} />
			</div>
			)
		}
	}
	
	const heatmapSymbols = [
		"ZS", "ZM", "ZL", "ZC", "ZW", "KE", "MWE", 
		"GF", "LE", "HE", "LBS",
		"CL", "NG", "RB", "HO",
		"GC", "SI", "HG", "PL",
		"6E", "6J", "CNH", "6B", "6C", "6A", "6L", "6M",
		"ZT", "ZF", "ZN", "ZB",
		"ES", "YM", "NQ",
		"BTC", "ETH",
		"ZO", "PA", "UB",
		"ZQ", "ZR",
	]; 

	const spreadSymbols = ['C', 'W', 'S', 'BO', 'SM'];

    // useState with state_index found above
    const [item, setItem] = useState(tab_set[state_index]);
    
    function optionSelected(option) {
        const path = `${base_path}/${option.label}`;
		history.push(path)
        setItem(option);
	}
	
	const symbol = by_common(item.label);

	// if root has changed, need to call setItem() to trigger re-render
	if (root && root !== item.label) {
		const overload = by_overload(root)
		// handle root being passed as alternative symbol (e.g. ZW -> W)
		if (overload && overload.common in tab_index && overload.common !== item.label) {
			setItem(tab_set[tab_index[overload.common]]);
		}
	}

	document.title = symbol.common + ' ' + base_title;

	const bloombergRoot = symbol.bloomberg[0];
	const futureType = symbol.bloomberg[1];
	const corrRoot = bloombergRoot === "XB" ? "RB" : bloombergRoot; // hardcoded fix for RBOB confusing RB/XB
	const rootMaster = bloombergRoot + "_Master_Term_Structure.pdf";
	const rootGammas = item.value + "_Gamma_PDFs.pdf";
	const rootDryPowder = bloombergRoot + "_dry_powder.pdf";
	const activeSymbol = symbol.active && symbol.active.symbol ? symbol.active.symbol : undefined;
	const rollTickerN = (symbol.rolled && symbol.rolled.N) ? 
		(symbol.rolled.N).replace(/ /g, '_').toLowerCase() : null;
	const contractRel = symbol.active ? symbol.active.rel : "?";
	const symbol_urls = symbol_url_map[symbol.exchange];

    return (
        <Layout>
			<div style={{position: "sticky", top: 0, zIndex: 100, backgroundColor: "white", border: cellBorder}}>
				<ImbueTabs options={tab_set} selectedValue={item.value} onClickOption={(opt) => optionSelected(opt)} />
			</div>
			<div className="row">
				<h1 style={{marginRight: "20px"}}>{item.label} Dashboard - {symbol.title}</h1>
				{ symbol_urls && symbol_urls.quotes && 
					<a href={symbol_urls.quotes}
						style={linkRow}
						rel="noreferrer"
						target="_blank">
						<h3>quotes</h3>
				    </a>
				}
				{ symbol_urls && symbol_urls.specs &&
					<a href={symbol_urls.specs}
						style={linkRow}
						rel="noreferrer"
						target="_blank">
						<h3>specs</h3>
					</a>
				}
				{ symbol_urls && symbol_urls.rulebook &&
					<a href={symbol_urls.rulebook}
						style={linkRow}
						rel="noreferrer"
						target="_blank">
						<h3>rulebook</h3>
					</a>
				}
			</div>
			<hr style={{marginTop: '-20px'}}></hr>
			<div>
				<div className="row">
					<div style={{marginLeft: "50px", marginBottom: "5px"}}>
						<DashboardQuoteBoard allRoots={quoteBoardRoots} curRoot={symbol.exchange}/>
					</div>
					{ spreadSymbols.includes(symbol.common) &&
						<div style={{marginLeft: "100px"}}>
							<DashboardCalendarSpreads root={symbol.common}/>
						</div>
					}
				</div>
				{symbol['v9'] && 
					<div style={{ border: cellBorder, borderRadius: "10px"}}>
						<ExpectedRisk symbol={activeSymbol}/>
					</div>
				}
				{symbol.deltas && // don't render if no deltas
					<div style={{ border: cellBorder, borderRadius: "10px",}}>
						OptionsIVHistory: {symbol.deltas}
						<DashboardOptionsIV root={symbol.deltas} title={symbol.title}/>
					</div>
				}
				<div style={{ border: cellBorder, borderRadius: "10px",}}> 
					<CorrelationAnalytics root={corrRoot}/>
				</div>
				{symbol.chain && symbol.chain.live !== undefined &&
					<div style={{ border: cellBorder, borderRadius: "10px", }}>
						TopStrike : {symbol.exchange}
						<TopStrikesTable root={symbol.exchange} chain={symbol.chain}/>
					</div>
				}
				<div className="row">
					{heatmapSymbols.includes(symbol.exchange) && // don't show heatmap for symbols that won't work
						<div style={{ border: cellBorder, borderRadius: "10px", width: "30%"}}>
							TradesHeatmap : {contractRel}
							<TradesHeatmap symbol={contractRel}/>
						</div>
					}
					{symbol.v9 && 
						<div style={{ border: cellBorder, borderRadius: "10px", width: "70%"}}>
							LiveBidAsk: {contractRel}
							<LiveBidAskAverages root={contractRel}/>
						</div>
					}
				</div>
				{symbol.v9 &&
					<div style={{ border: cellBorder, borderRadius: "10px",}}>
							BidAskAverages: {symbol.exchange}, {rollTickerN}, {contractRel}
							<BidAskAverages root={symbol.exchange} ticker={rollTickerN} contract={contractRel}/>
					</div>
				}
				{ (symbol.delta || symbol.deltas) &&
					<div style={{ border: cellBorder, borderRadius: "10px" }}>
						{symbol.delta ? (
							<DashboardDeltaICE root={symbol.delta.root} commonRoot={symbol.common}/>
						) : (
							<DashboardDeltas root={symbol.deltas}/>
						)}
					</div>
				}
				<div style={{ border: cellBorder, borderRadius: "10px",}}>
					Seasonality : {bloombergRoot}
					<DashboardSeasonality bloombergRoot={bloombergRoot} futureType={futureType}/>
				</div>
				<div className="row">
					<div style={{ border: cellBorder, borderRadius: "10px", width: "48%"}}>
						MasterTermPDF : {rootMaster}
						<S3PdfViewer key={rootMaster} bucketname="imbuereportbucket" id={rootMaster} />
					</div>
					<div style={{ border: cellBorder, borderRadius: "10px", width: "48%"}}>
						Gammas PDF: {rootGammas}
						<S3PdfViewer key={rootGammas} bucketname="imbuereportbucket" id={rootGammas} />
					</div>
				</div>
				<div className="row">
					<div style={{ border: cellBorder, borderRadius: "10px", width: "48%"}}>
						DryPowder PDF: {rootDryPowder}
						<S3PdfViewer key={rootDryPowder} bucketname="imbuereportbucket" id={rootDryPowder} />
					</div>
					{ activeSymbol && 
						<div style={{ border: cellBorder, borderRadius: "10px", width: "48%"}}>
							IntradayMovement: {symbol.exchange} : {activeSymbol.slice(-2)}
							<IntradayMovement root={symbol.exchange} symbol={activeSymbol.slice(-2)}/>
						</div>
					}
				</div>
			</div>
        </Layout>
    );
}
