/**
 * ref
 * ---
 *
 * https://terminal.tradesignalonline.com/wiki/3/bloomberg+symbollists
 */

import agentTickers from "../json/tickerIndex.json";
import tickerGroups from "../json/tickerGroupIndex.json";

export const SECURITY_POOL = [
    "w 1 comdty",
    "sm1 comdty",
    "kw1 comdty",
    "mw1 comdty",
    "c 1 comdty",
    "cl1 comdty",
    // "CL1 R:05_0_R Comdty",
    "bo1 comdty",
    "fc1 comdty",
    "lh1 comdty",
    "lc1 comdty",
    "da1 comdty",
    "xb1 comdty",
    "ng1 comdty",
    "co1 comdty",
    "ho1 comdty",
    "gc1 comdty",
    "si1 comdty",
    "pl1 comdty",
    "pa1 comdty",
    "hg1 comdty",
    "ct1 comdty",
    "kc1 comdty",
    "cc1 comdty",
    "ad1 curncy",
    "br1 curncy",
    "dm1 index",
    "dx1 curncy",
    "sfr1 comdty",
    "es1 index",
    "s 1 comdty",
    "sb1 comdty",
    "ty1 comdty",
    "ux1 index",
    "jy1 curncy",
    "jo1 comdty",
    "cd1 curncy",
    "sf1 curncy",
    "bp1 curncy",
    "pe1 curncy",
    "nv1 curncy",
    "nq1 index",
    "btc1 curncy",
];

let namingIndex = {
    // [CBOT]
    "c 1 comdty": "Corn [CBOT]",
    "o 1 comdty": "Oats [CBOT]",
    "s 1 comdty": "Soybean [CBOT]",
    "w 1 comdty": "Wheat [CBOT]",
    "dl1 comdty": "Denatured Ethanol [CBOT]",
    "bo1 comdty": "Soybean Oil [CBOT]",
    "sm1 comdty": "Soybean Meal [CBOT]",

    // [NYBOT]
    "cc1 comdty": "Cocoa [NYBOT]",
    "ct1 comdty": "Cotton [NYBOT]",
    "jo1 comdty": "Orange Juice [NYBOT]",
    "kc1 comdty": "Coffee [NYBOT]",
    "sb1 comdty": "Sugar [NYBOT]",
    "rr1 comdty": "Rough Rice [CBOT]",

    // [NYMEX]
    "ho1 comdty": "Heating Oil [NYMEX]",
    "cl1 comdty": "Crude Oil [NYMEX]",
    "ng1 comdty": "Natural Gas [NYMEX]",
    "pa1 comdty": "Palladium [NYMEX]",
    "pl1 comdty": "Platinum [NYMEX]",
    "xb1 comdty": "Harbor Gasoline [NYMEX]",

    // [COMEX]
    "gc1 comdty": "Gold [COMEX]",
    "hg1 comdty": "Copper [COMEX]",
    "qc1 comdty": "Cocoa [COMEX]",
    "si1 comdty": "Silver [COMEX]",

    // [Euronext]
    "ca1 comdty": "Milling Wheat [Euronext]",
    "ep1 comdty": "Maize [Euronext]",
    "ij1 comdty": "Rapeseed [Euronext]",

    // [ICE]
    "co1 comdty": "Brent Crude Oil [ICE]",
    "qs1 comdty": "Gas Oil [ICE]",

    // [CME]
    "fc1 comdty": "Feeder Cattle [CME]",
    "da1 comdty": "Milk [CME]",
    "lc1 comdty": "Live Cattle [CME]",
    "lh1 comdty": "Lean Hogs [CME]",
    "lb1 comdty": "Lumber [CME]",

    // [LME]
    "ll1 comdty": "Lead [LME]",
    "ln1 comdty": "Nickel [LME]",
    "lp1 comdty": "Copper [LME]",
    "lt1 comdty": "Tin [LME]",
    "lx1 comdty": "Zinc [LME]",

    // Other
    "rs1 comdty": "Canola",
    "mw1 comdty": "Spring Wheat",
    "qw1 comdty": "White Sugar",
    "df1 comdty": "Robusta Coffee",
    "wz1 comdty": "White Maize",
    "yw1 comdty": "Yellow Maize",
    "ko1 comdty": "Crude Palm Oil",
    "kw1 comdty": "Wheat [KCBT]",
    "le1 comdty": "Non-Fat Dry Milk",

    // Non-Commodity
    "ad1 curncy": "Australian Dollar",
    "bp1 curncy": "British Pound",
    "br1 curncy": "Brazil Real", // altered from "Brazil Real" {Brazilian Real}
    "btc1 curncy": "Bitcoin",
    "bts1 index": "EUX Short term Euro-BTP",
    "cd1 curncy": "Canadian Dollar",
    "cn1 comdty": "Montreal Exchange 10 Year Cana",
    "dm1 index": "Dow Jones E-mini",
    "du1 comdty": "Eurex 2 Year Euro SCHATZ",
    "dx1 curncy": "Dollar Index",
    "sfr1 comdty" : "3 Month SOFR",
    "es1 index": "S&P500 E-mini",
    "g 1 comdty": "Long Gilt",
    "ik1 comdty": "Euro-BTP Italian Bond",
    "jo1 curncy": "Orange Juice",
    "jy1 curncy": "Japanese Yen",
    "kaa1 comdty": "KFE 10 Year Treasury Bond",
    "ke1 comdty": "KFE 3 Year Treasury Bond",
    "nq1 index": "Nasdaq 100 E-mini",
    "nv1 curncy": "New Zealand Dollars", // altered from "New Zealand Dollars" {New Zealand Dollar}
    "oat1 comdty": "French Government Bond",
    "oe1 comdty": "Eurex 5 Year Euro BOBL",
    "pe1 curncy": "Mexican Peso",
    "qk1 comdty": "Euronext Feed Wheat",
    "rx1 comdty": "Eurex 10 Year Euro BUND",
    "sf1 curncy": "Swiss Franc",
    "ub1 comdty": "Eurex 30 Year Euro BUXL",
    "ux1 index": "CBOE VIX",
    "ec1 curncy": "Euro FX", // added
    "ff1 comdty" : "30-Day Federal Funds",
    "us1 comdty": "US Treasury Bond",
    "wn1 comdty": "Ultra US Treasury Bond",
    "ty1 comdty": "10-Year T-Note",
    "fv1 comdty": "5-Year T-Note",
    "tu1 comdty": "2-Year T-Note",
    "rty1 index": "Russell 2000 E-mini"
};

// symbol only
for (let k of Object.keys(namingIndex)) {
    namingIndex[k.split("1")[0]] = namingIndex[k];
}

// Ticker's Short Name Index
const TickerDict = agentTickers;

export function getTickerName(tickerStr) {
    if (tickerStr) {
        if (tickerStr.toLowerCase() in namingIndex) {
            return namingIndex[tickerStr.toLowerCase()];
        }
        let affix = "comdty";
        if (tickerStr.toLowerCase().includes("index")) {
            affix = "index";
        } else if (tickerStr.toLowerCase().includes("curncy")) {
            affix = "curncy";
        }
        let symbol = tickerStr.toLowerCase().split("1 ")[0];
        symbol += "1 " + affix;
        let translated = namingIndex[symbol];
        return translated || tickerStr;
    } else {
        return "";
    }
}

export function getTicker(tickerName, suffix) {
    let defaultticker = Object.keys(namingIndex).find((key) => namingIndex[key] === tickerName);
    if (suffix) {
        defaultticker = defaultticker.replace("1 comdty", suffix);
    }
    return defaultticker;
}

// Ticker's Group Index
const TickerGroups = tickerGroups;
const TickerGroupSet = Array.from(new Set(Object.keys(tickerGroups).map((k) => tickerGroups[k])));

export { TickerDict, TickerGroups, TickerGroupSet };
