import React, { Component } from "react";

// sub screens
import CorrelationAnalytics from "./CorrelationAnalytics/CorrelationAnalytics";
import PositioningAnalytics from "./PositioningAnalytics";
import DashboardReport from "./Reports/TemplateReport";
import Intraday from "./Intraday";
import IntradayMovement from "./IntradayMovement";
import RobinTrack from "./RobinTrack";
import COTRanking from "./COTRanking";

import MainBoard from "../../layout/MainBoard";
import PositionRisk from "./PositionRisk";
import News from "./News";
import NewsReference from "./NewsReference";
import Spreads from "./Spreads";
import Seasonality from "./Seasonality";
import Mynews from "./Mynews";
import Vpin from "./Vpin";
import OptionChain from "./OptionChain";
import ChinaMargin from "./ChinaMargin";
import SystemicRiskMonitor from "./SystemicRiskMonitor/SystemicRiskMonitor";
import ResearchCenterPdfViewer from "./ResearchCenterPdfViewer";
import ResearchChartpackViewer from "./ResearchChartpackViewer";
import MasterTermStructureViewer from "./MasterTermStructureViewer";


class ResearchCenter__mainboard extends Component {

    componentDidMount() {
        document.title = "Research Analytics";
    }
    
    render() {
        const { path } = this.props.match;
        const list = [
            { category: "Research", name: "Dashboards", path: path + "/dashboards", component: DashboardReport },
            { category: "Positioning", name: "Positioning", path: path + "/positioning", component: PositioningAnalytics },
            { category: "Positioning", name: "COT Ranking", path: path + "/cotranking", component: COTRanking },
            { category: "Intraday", name: "Intraday", path: path + "/intraday", component: Intraday },
            { category: "Research", name: "Correlation Analytics", path: path + "/corr", component: CorrelationAnalytics },
            { category: "Intraday", name: "Intraday Movement", path: path + "/intramove", component: IntradayMovement },
            { category: "Research", name: "ETF Flow", path: path + "/etfflow", component: RobinTrack },
            { category: "Risk", name: "Position Risk", path: path + "/positionrisk", component: PositionRisk },
            { category: "Research", name: "Spreads", path: path + "/spreads", component: Spreads },
            { category: "Alternative", name: "News", path: path + "/news", component: News },
            { category: "Alternative", name: "My News", path: path + "/mynews", component: Mynews },
            { category: "Alternative", name: "News Reference", path: path + "/newsref", component: NewsReference },
            { category: "Research", name: "Seasonality", path: path + "/seasonality", component: Seasonality },
            { category: "Research", name: "VPIN", path: path + "/vpin", component: Vpin },
            { category: "Intraday", name: "Option Chain", path: path + "/optionchain", component: OptionChain },
            { category: "Alternative", name: "China Margin", path: path + "/chinamargin", component: ChinaMargin },
            {
                category: "Risk",
                name: "Systemic Risk Monitor - DEMO",
                path: path + "/SystemicRiskMonitor",
                component: SystemicRiskMonitor,
                isbeta: 1,
            },
            { category: "Research", name: "Research PDF Viewer", path: path + "/ResearchPdfViewer", component: ResearchCenterPdfViewer },
			{ category: "Research", name: "Research Chartpack", path: path + "/ResearchChartpackViewer", component: ResearchChartpackViewer },
			{ category: "Research", name: "Master TermStructure Viewer", path: path + "/MasterTermStructureViewer", component: MasterTermStructureViewer },
			
        ];
        return <MainBoard title="Research Analytics" list={list} />;
    }
}

export default ResearchCenter__mainboard;
