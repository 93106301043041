import React, { Component } from "react";
import { api_blp } from "../../helpers/API";
import { COLORMAP } from "../../config";
import {
    generateSeasonal,
    calculateChange,
    generateCandleSeries,
    generateMonthlyHeatmapConfig,
} from "../../helpers/ChartFunctions";
import MultiFunctionalChart from "../chart/MultiFunctionalChart";
import PriceChangeCard from "../../components/card/PriceChangeCard";
import styled from "styled-components";

const EXCLUDEDFIELDS = ["ECO_RELEASE_DT", "ECO_RELEASE_TIME", "ECO_FUTURE_RELEASE_DATE"];

const Layout = styled.div`
    padding: 20px;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100px calc(100% - 100px);

    transition: opacity 0.3s ease-in-out;
    opacity: ${(props) => (props.loading ? "0.3" : "1")};

    .title {
        font-family: var(--font-main);
        color: var(--color-main);
        margin-bottom: 5px;
    }
`;

// ::::::::::: Helpers :::::::::::::

function generateLineSeries(data) {
    let ts_data = Object.keys(data)
        .map((key, idx) => {
            const isEmpty = data[key].filter((ele) => ele[1]).length === 0;
            const isExcluded = EXCLUDEDFIELDS.indexOf(key) >= 0;
            const hasString = data[key].map((ele) => typeof ele[1] === "string").indexOf(true) >= 0;
            if (!isEmpty && !isExcluded && !hasString) {
                return {
                    name: key,
                    data: data[key].map((ele) => [Date.parse(ele[0]), ele[1]]),
                    color: idx === 0 ? COLORMAP.minor : undefined,
                };
            }
        })
        .filter((ele) => ele);
    return ts_data;
}

function generateBarSeries(data) {
    let ts_data = Object.keys(data)
        .map((key, idx) => {
            const isEmpty = data[key].filter((ele) => ele[1]).length === 0;
            const isExcluded = EXCLUDEDFIELDS.indexOf(key) >= 0;
            if (!isEmpty && !isExcluded) {
                return {
                    name: key,
                    type: "column",
                    data: data[key].map((ele) => [Date.parse(ele[0]), ele[1]]),
                    color: idx === 0 ? COLORMAP.minor : undefined,
                };
            }
        })
        .filter((ele) => ele);
    return ts_data;
}

function generateTableConfig(data, name) {
    // data => {dp1: [["2010-01-01", 100] ... ], dp2: [["2010-01-01", 442] ... ]}
    let key_value = {};
    Object.keys(data).map((key) => {
        key_value["DATE"] = data[key].map((ele) => {
            return ele[0];
        });
        key_value[key.toUpperCase()] = data[key].map((ele) => {
            return ele[1];
        });
    });
    let table_data = [];
    key_value["DATE"].map((element, index) => {
        let one_row = {};
        Object.keys(key_value).map((key_) => {
            one_row[key_] = key_value[key_][index];
        });
        table_data.push(one_row);
    });
    table_data = table_data.slice().reverse();
    let columns = Object.keys(key_value).map((key) => {
        return {
            Header: key,
            accessor: key,
            Cell: (ele) =>
                ele.value ? (
                    ele.value
                ) : (
                    <div style={{ width: "100%", height: "100%", background: COLORMAP.red_o }}></div>
                ),
        };
    });
    return {
        name: name,
        data: table_data,
        columns: columns,
    };
}

class TickerChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            chartid: "",
            chart_type: this.props.chart_type,
        };
        this.updateChart = this.updateChart.bind(this);
    }

    shouldComponentUpdate(nP, nS) {
        if (this.props.ticker !== nP.ticker || this.props.field !== nP.field || this.props.from !== nP.from) {
            if (nP.hotreload) {
                this.updateChart();
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    componentDidMount() {
        this.updateChart(this.props);
    }

    updateChart(props) {
        if (props === undefined) {
            props = this.props;
        }

        let ticker = props.ticker || "C 1 Comdty";
        let field = props.field || "PX_LAST";
        const from = props.from || "1990-01-01";
        const schema = props.schema || "blpv4";

        const chartid = ticker + field + from;

        let tickers = ticker;
        let fields = field;

        const title = props.title || ticker;

        // Determine if fields is in command mode
        let isShowCandle = false;
        if (fields[0] === "/") {
            switch (fields) {
                case "/candle": {
                    isShowCandle = true;
                    fields = "px_open,px_last,px_high,px_low";
                    break;
                }
                default:
                    break;
            }
        }

        this.setState(
            {
                title,
                loading: true,
                refdata: null,
                chartid: "",
            },
            () => {
                api_blp(tickers, fields, from, { schema: schema })
                    .then((res) => {
                        const api_data = res.data.content.data;

                        let main_data = null;
                        if (api_data.PX_LAST) {
                            main_data = api_data.PX_LAST;
                        } else if (api_data.px_last) {
                            main_data = api_data.px_last;
                        } else if (api_data.value) {
                            main_data = api_data.value;
                        } else if (api_data.px_open) {
                            main_data = api_data.px_open;
                        } else {
                            main_data = api_data[Object.keys(api_data)[0]];
                        }

                        // 0. Make a title
                        const title = this.props.title;

                        let data = {};

                        if (isShowCandle) {
                            // 1. Make Line Chart Series
                            let candleData = {
                                date: api_data["PX_LAST"].map((ele) => ele[0]),
                                high: (api_data["PX_HIGH"] || api_data["PX_LAST"]).map((ele) => ele[1]),
                                low: (api_data["PX_LOW"] || api_data["PX_LAST"]).map((ele) => ele[1]),
                                open: api_data["PX_OPEN"].map((ele) => ele[1]),
                                close: api_data["PX_LAST"].map((ele) => ele[1]),
                            };
                            let candleSeries = generateCandleSeries(candleData);
                            data = {
                                candle: candleSeries,
                            };
                            this.setState({
                                chart_type: "candle",
                                data,
                                loading: false,
                            });
                        } else {
                            if (this.state.chart_type === "candle") {
                                this.setState({ chart_type: "line" });
                            }
                            // ==== Series Generator ====
                            let lineSeries = generateLineSeries(api_data);
                            let barSeries = generateBarSeries(api_data);
                            let seasonalSeries = generateSeasonal(main_data);

                            // ==== Config Generator ====
                            let tableDataConfig = generateTableConfig(api_data, title);
                            let monthlyHeatmapConfig = generateMonthlyHeatmapConfig(api_data);

                            data = {
                                line: lineSeries,
                                seasonal: seasonalSeries,
                                bar: barSeries,
                                table: tableDataConfig,
                                heatmapConfig: monthlyHeatmapConfig,
                            };

                            let change = calculateChange(main_data);

                            this.setState({
                                chartid,
                                data,
                                change,
                                loading: false,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        );
    }

    render() {
        const isLoading = this.state.loading;
        const chartid = this.state.chartid;

        return (
            <Layout loading={isLoading}>
                <div>
                    <div className="title">{this.props.title}</div>
                    <PriceChangeCard change={this.state.change || {}} />
                </div>

                <MultiFunctionalChart
                    chartid={chartid}
                    data={this.state.data}
                    country={this.props.country}
                    tableSize={this.props.tableSize}
                    chartType={this.state.chart_type}
                />
            </Layout>
        );
    }
}

export default TickerChart;
