/**
 * 
 * websocket example
 * 
 * let ws = new WebSocket(ENDPOINT)
 *
 *    // --+-- ON OPEN --+--
 *    ws.onopen = () => {
 *        const message = {
 *            ...
 *        };
 *        ws.send(JSON.stringify(message));
 *    };
 *
 *    // --+-- ON MESSAGE --+--
 *    ws.onmessage = (event) => {
 *        const data = JSON.parse(event.data);
 *        ....
 *    };
 *
 *    // --+-- ON CLOSE --+--
 *    ws.onclose = event => {
 *        ...
 *    }; 
 *
 * 
 */


import { WS } from "../config";
import { getToken } from "../auth/UserAuth";

export function ailab_optimizer_ws() {
    const URL = `${WS}optimise?token=${getToken()}`
    return new WebSocket(URL)
}

export function intraday_ws(name) {
    const URL = `wss://nrx8et3zqf.execute-api.us-east-2.amazonaws.com/production?name=${name}`
    return new WebSocket(URL)
}