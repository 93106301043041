/**
 *
 * Page for China Margin Data
 * http://www.csf.com.cn/publish/english/1073/1085/1086/index.html
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import { api_blp } from "../../helpers/API";
import Chart from "../../components/chart/HighStockWrapper";
import { GLOBALSTYLED, highchartBasicConfig } from "../../config";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;

export default class ChinaMargin extends Component {
    state = {
        isLoading: 0,
        data_mfl: {
            NEW_LOAN_1D: [],
            NEW_LOAN_7D: [],
            NEW_LOAN_14D: [],
            NEW_LOAN_28D: [],
            NEW_LOAN_91D: [],
            NEW_LOAN_182D: [],
            NEW_LOAN_TOTAL: [],
            OUTSTANDING_FUNDS_LOANS_EOLTD_RMB100M: [],
            OUTSTANDING_FUNDS_LOANS_RMB100M: [],
            REPLAYMENT_RMB100M: [],
        },
    };

    componentDidMount() {
        this.getDailyMarginFunLoans();
    }

    getDailyMarginFunLoans = () => {
        const ticker = "cnmargin_mfl_daily";
        const fields =
            "NEW_LOAN_1D,NEW_LOAN_7D,NEW_LOAN_14D,NEW_LOAN_28D,NEW_LOAN_91D,NEW_LOAN_182D,NEW_LOAN_TOTAL,OUTSTANDING_FUNDS_LOANS_EOLTD_RMB100M,OUTSTANDING_FUNDS_LOANS_RMB100M,REPLAYMENT_RMB100M";
        api_blp(ticker, fields, "2012-01-01").then((res) => {
            this.setState({
                data_mfl: res.data.content.data,
            });
        });
    };

    render() {
        const chartConfig = {
            mfl: {
                ...highchartBasicConfig,
                yAxis: [
                    {
                        height: "70%",
                    },
                    {
                        height: "30%",
                        top: "70%",
                    },
                ],
                series: [
                    ...[
                        // "REPLAYMENT_RMB100M",
                        "OUTSTANDING_FUNDS_LOANS_EOLTD_RMB100M",
                        "OUTSTANDING_FUNDS_LOANS_RMB100M",
                    ].map((k) => ({
                        name: k,
                        data: this.state.data_mfl[k].map((ele) => [Date.parse(ele[0]), ele[1]]),
                        yAxis: 0,
                    })),
                    ...[
                        "NEW_LOAN_1D",
                        "NEW_LOAN_7D",
                        "NEW_LOAN_14D",
                        "NEW_LOAN_28D",
                        "NEW_LOAN_91D",
                        "NEW_LOAN_182D",
                        "NEW_LOAN_TOTAL",
                    ].map((k) => ({
                        name: k,
                        data: this.state.data_mfl[k].map((ele) => [Date.parse(ele[0]), ele[1]]),
                        yAxis: 1,
                    })),
                ],
            },
        };

        return (
            <Layout>
                <h1>Daily Margin Funds Loan </h1>
                <Chart config={chartConfig.mfl} height="500" />
            </Layout>
        );
    }
}
