import React, { Component } from "react";
import styled from "styled-components";
import { api_optionchain } from "../../helpers/API";
import { toUnique, getFixed } from "../../helpers/Utils";
import ImbueSelect from "../../components/input/ImbueSelect";
import Table from "../../components/table/ImbueRcTable";
import { COLORMAP, GLOBALSTYLED } from "../../config";
import ImbueTag from "../../components/tag/ImbueTag";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)`
    .glossary_row {
        font-family: var(--font-main);
        font-size: 1.2rem;
        color: #555;
        padding: 4px 0;
    }

    .glossary_row:hover {
        color: #111;
    }

    .glossary_row:not(:last-child) {
        border-bottom: 1px solid #ccc;
    }
`;
// --- helper mapping
const option_raw_code_to_ticker_map = {
    G3BV0: "BH9",
    CSCU0: "CSC",
    CSCV0: "CSC",
    CSCX0: "CSC",
    CSCZ0: "CSC",
    CSCF1: "CSC",
    CSCG1: "CSC",
    CSCH1: "CSC",
    DCQ0: "DC",
    DCU0: "DC",
    DCV0: "DC",
    DCX0: "DC",
    DCZ0: "DC",
    DCF1: "DC",
    DCG1: "DC",
    DCH1: "DC",
    DCJ1: "DC",
    DCK1: "DC",
    DCM1: "DC",
    G4XU0: "G8X",
    G4XV0: "G8X",
    G4XZ0: "G8X",
    G4XF1: "G8X",
    G4XH1: "G8X",
    G4XJ1: "G8X",
    GE0U0: "GE",
    GE2U0: "GE",
    GE3U0: "GE",
    GEU0: "GE",
    GEV0: "GE",
    GE0V0: "GE",
    GE2V0: "GE",
    GE3V0: "GE",
    GEX0: "GE",
    GE0X0: "GE",
    GE2X0: "GE",
    GE3X0: "GE",
    GE0Z0: "GE",
    GE2Z0: "GE",
    GE3Z0: "GE",
    GE4Z0: "GE",
    GEZ0: "GE",
    GEF1: "GE",
    GE0G1: "GE",
    GE0H1: "GE",
    GE2H1: "GE",
    GE3H1: "GE",
    GEH1: "GE",
    GE0M1: "GE",
    GE2M1: "GE",
    GE3M1: "GE",
    GEM1: "GE",
    GE2U1: "GE",
    GEU1: "GE",
    GEZ1: "GE",
    GEH2: "GE",
    GEM2: "GE",
    GEU2: "GE",
    GEZ2: "GE",
    GEH3: "GE",
    GEM3: "GE",
    GEZ3: "GE",
    GFQ0: "GF",
    GFU0: "GF",
    GFV0: "GF",
    GFX0: "GF",
    GFF1: "GF",
    GNFV0: "GNF",
    GNFZ0: "GNF",
    HEV0: "HE",
    HEZ0: "HE",
    HEG1: "HE",
    HEJ1: "HE",
    HEM1: "HE",
    AE7U0: "HH",
    OKEU0: "KE",
    OKEV0: "KE",
    OKEZ0: "KE",
    OKEH1: "KE",
    LEU0: "LE",
    LEV0: "LE",
    LEZ0: "LE",
    LEG1: "LE",
    LEJ1: "LE",
    LEM1: "LE",
    LNEU0: "NG",
    ONU0: "NG",
    LNEV0: "NG",
    ONV0: "NG",
    LNEX0: "NG",
    ONX0: "NG",
    LNEZ0: "NG",
    ONZ0: "NG",
    LNEF1: "NG",
    LNEG1: "NG",
    ONG1: "NG",
    LNEH1: "NG",
    LNEJ1: "NG",
    LNEK1: "NG",
    LNEM1: "NG",
    LNEN1: "NG",
    LNEQ1: "NG",
    LNEU1: "NG",
    LNEV1: "NG",
    LNEF2: "NG",
    LNEG2: "NG",
    LNEH2: "NG",
    LNEJ2: "NG",
    LNEK2: "NG",
    LNEM2: "NG",
    LNEN2: "NG",
    LNEQ2: "NG",
    LNEU2: "NG",
    LNEV2: "NG",
    LNEX2: "NG",
    LNEZ2: "NG",
    LNEF3: "NG",
    LNEG3: "NG",
    LNEH3: "NG",
    LNEJ3: "NG",
    LNEK3: "NG",
    LNEM3: "NG",
    LNEN3: "NG",
    LNEQ3: "NG",
    LNEU3: "NG",
    LNEV3: "NG",
    LNEX3: "NG",
    LNEZ3: "NG",
    CZCZ0: "QC3",
    A6JF1: "U8P",
    OZBU0: "ZB",
    ZB4Q0: "ZB",
    ZB1U0: "ZB",
    OZBV0: "ZB",
    OZBX0: "ZB",
    OZBZ0: "ZB",
    OZCU0: "ZC",
    OCDU0: "ZC",
    ZC4Q0: "ZC",
    OZCV0: "ZC",
    OZCX0: "ZC",
    OZCZ0: "ZC",
    OCDZ0: "ZC",
    OZCH1: "ZC",
    OZCK1: "ZC",
    OZCN1: "ZC",
    OZCU1: "ZC",
    OZCZ1: "ZC",
    OZFU0: "ZF",
    ZF4Q0: "ZF",
    ZF1U0: "ZF",
    OZFV0: "ZF",
    OZFX0: "ZF",
    OZFZ0: "ZF",
    OZLU0: "ZL",
    OZLV0: "ZL",
    OZLZ0: "ZL",
    OZLF1: "ZL",
    OZLH1: "ZL",
    OZLK1: "ZL",
    OZMU0: "ZM",
    OZMV0: "ZM",
    OZMZ0: "ZM",
    OZMF1: "ZM",
    OZMH1: "ZM",
    OZMK1: "ZM",
    OZMN1: "ZM",
    OZNU0: "ZN",
    WY4Q0: "ZN",
    ZN4Q0: "ZN",
    ZN1U0: "ZN",
    ZN2U0: "ZN",
    OZNV0: "ZN",
    OZNX0: "ZN",
    OZNZ0: "ZN",
    OZOZ0: "ZO",
    OZSU0: "ZS",
    OSDU0: "ZS",
    ZS4Q0: "ZS",
    ZS1U0: "ZS",
    OZSV0: "ZS",
    OZSX0: "ZS",
    OZSF1: "ZS",
    OZSH1: "ZS",
    OZSK1: "ZS",
    OZSN1: "ZS",
    OZSX1: "ZS",
    OZTU0: "ZT",
    OZTV0: "ZT",
    OZWU0: "ZW",
    ZW4Q0: "ZW",
    OZWV0: "ZW",
    OZWX0: "ZW",
    OZWZ0: "ZW",
    OZWH1: "ZW",
    OZWN1: "ZW",
};

const option_ticker_to_name_map = {
    BH9: ["Natural Gas Synthetic 3 Months Spread", "Energy"],
    CSC: ["Cash-Settled Cheese", "Dairy"],
    DC: ["Class III Milk", "Dairy"],
    G8X: ["Natural Gas Synthetic 1 Month Spread", "Energy"],
    GE: ["3 Month Eurodollar", "Financial"],
    GF: ["Feeder Cattle", "Livestock"],
    GNF: ["Nonfat Dry Milk", "Dairy"],
    HE: ["Lean Hogs", "Livestock"],
    HH: ["Natural Gas Last-Day Financial Futures", "Energy"],
    KE: ["KC HRW Wheat", "Grains"],
    LE: ["Live Cattle", "Livestock"],
    NG: ["Natural Gas", "Energy"],
    QC3: ["Consecutive Corn CSO", "Grains"],
    U8P: ["Natural Gas Option on Calendar Futures Strip Combo", "Energy"],
    ZB: ["T-Bond Futures", "Financial"],
    ZC: ["Corn", "Grains"],
    ZF: ["5-Year T-Note", "Financial"],
    ZL: ["Soybean Oil", "Oilseeds"],
    ZM: ["Soybean Meal", "Oilseeds"],
    ZN: ["10-Year T-Note", "Financial"],
    ZO: ["Oat", "Grains"],
    ZS: ["Soybean", "Oilseeds"],
    ZT: ["2-Year T-Note", "Financial"],
    ZW: ["Wheat", "Grains"],
};

const month_codes = {
    F: "Jan",
    G: "Feb",
    H: "Mar",
    J: "Apr",
    K: "May",
    M: "Jun",
    N: "Jul",
    Q: "Aug",
    U: "Sep",
    V: "Oct",
    X: "Nov",
    Z: "Dec",
};

const parseMaturity = (code) => {
    return month_codes[code[0]] + "-202" + code[1];
};

const glossary = [
    "Net Aggressor = sum(quantity*sgn(aggressor))/sum(quantity)",
    "Raw Net Aggressor = sum(quantity*sgn(aggressor))",
    "Net Aggressor Delta = sum(quantity*delta*sgn(aggressor))/sum(quantity)",
    "IV Change = session IV percent change", 
];

export default class OptionChain extends Component {
    state = {
        isLoading: 0,
        isInit: 0,
        date: null,
        list: [],
        code_list: [],
        selectedCode: "",
        selectedOption: "", // C for Call or P for Put
        selectedSector: "",
        selectedMaturity: "",
        selectedCommodities: [],
        minimumTotalQty: null,
    };

    componentDidMount() {
        this.onUpdate();
        this.timer = setInterval(this.onUpdate, 5000);
    }

    onUpdate = () => {
        this.setState({ isLoading: 1 }, () => {
            api_optionchain({ date: this.state.date })
                .then((res) => {
                    let list = res.data.rows.map((item) => ({
                        ...item,
                        code: item.symbol.split(" ")[0].split(":")[2],
                        maturity: parseMaturity(item.symbol.split(" ")[0].split(":")[2].slice(-2)),
                        option: item.symbol.split(" ")[1][0],
                    }));
                    list = list.map((ele) => ({
                        ...ele,
                        ticker: option_raw_code_to_ticker_map[ele.code],
                    }));
                    list = list.map((ele) => ({
                        ...ele,
                        commodity: (option_ticker_to_name_map[ele.ticker] || [null, null])[0],
                        sector: (option_ticker_to_name_map[ele.ticker] || [null, null])[1],
                    }));
                    const code_list = toUnique(list.map((ele) => ele.code));
                    this.setState({
                        list: list,
                        code_list: code_list,
                        isLoading: 0,
                        isInit: 1,
                    });
                })
                .catch((e) => {
                    this.setState({
                        list: [],
                        isLoading: 0,
                    });
                });
        });
    };

    onChangeCode = (code) => {
        this.setState({
            selectedCode: code,
        });
    };

    onChangeOption = (code) => {
        this.setState({
            selectedOption: code,
        });
    };

    onChangeSector = (sector) => {
        this.setState({
            selectedSector: sector,
        });
    };

    onChangeCommodity = (code) => {
        this.setState({
            selectedCommodities: code ? code.map((ele) => ele.value) : [],
        });
    };

    onChangeMaturity = (code) => {
        this.setState({
            selectedMaturity: code,
        });
    };

    render() {
        // ////////////////////////// Selector Options
        const codeOptions = [
            { label: "--- ALL ---", value: "" },
            ...this.state.code_list.sort().map((ele) => ({ label: ele, value: ele })),
        ];
        const optionOptions = [
            { label: "--- ALL ---", value: "" },
            { label: "C", value: "C" },
            { label: "P", value: "P" },
        ];
        const commodityOptions = toUnique(Object.values(option_ticker_to_name_map).map((ele) => ele[0]))
            // .filter((ele) => ele)
            .map((ele) => ({
                label: ele,
                value: ele,
            }));
        const sectorOptions = [
            { label: "--- ALL ---", value: "" },
            ...toUnique(this.state.list.map((ele) => ele.sector))
                .filter((ele) => ele)
                .map((ele) => ({
                    label: ele,
                    value: ele,
                })),
        ];
        const maturityOptions = [
            { label: "--- ALL ---", value: "" },
            ...toUnique(this.state.list.map((ele) => ele.maturity))
                .filter((ele) => ele)
                .map((ele) => ({
                    label: ele,
                    value: ele,
                })),
        ];
        console.log(maturityOptions);

        // ////////////////////////// Filter data
        let filtered = this.state.list;
        if (this.state.selectedCode) {
            filtered = this.state.list.filter((ele) => ele.code === this.state.selectedCode);
        }
        if (this.state.selectedOption) {
            filtered = filtered.filter((ele) => ele.option === this.state.selectedOption);
        }
        if (this.state.minimumTotalQty) {
            if (Number.parseFloat(this.state.minimumTotalQty)) {
                const minimumTotalQty = Number.parseFloat(this.state.minimumTotalQty);
                filtered = filtered.filter((ele) => ele.total_quantity >= minimumTotalQty);
            }
        }
        if (this.state.selectedSector) {
            filtered = filtered.filter((ele) => ele.sector === this.state.selectedSector);
        }
        if (this.state.selectedMaturity) {
            filtered = filtered.filter((ele) => ele.maturity === this.state.selectedMaturity);
        }
        if (this.state.selectedCommodities.length > 0) {
            filtered = filtered.filter((ele) => this.state.selectedCommodities.includes(ele.commodity));
        }

        // ////////////////////////// Table Config
        const customcell = {
            option: (props) => {
                const colormap = {
                    P: COLORMAP.red,
                    C: COLORMAP.green,
                };
                const textmap = {
                    P: "PUT",
                    C: "CALL",
                };
                return <ImbueTag value={textmap[props.value] || "?"} color={colormap[props.value]} />;
            },
            date: (p) => p.value.split(" ")[0],
            net_aggresor: (p) => getFixed(p.value, 4),
            net_aggresor_delta: (p) => getFixed(p.value, 4),
            iv_change: (p) => getFixed(p.value, 4),
        };
        const tablecolumns = [
            "date",
            "most_recent_traded",
            "commodity",
            "sector",
            "symbol",
            "code",
            "option",
            "total_trades",
            "total_quantity",
            "last_quantity",
            "volume",
            "net_aggresor",
            "raw_net_aggresor",
            "net_aggresor_delta",
            "delta",
            "iv",
            "iv_change",
        ].map((ele) => ({
            Header: ele,
            accessor: ele,
            Cell: (props) => (customcell[ele] ? customcell[ele](props) : props.value),
        }));

        return (
            <Layout>
                <div className="ImbueSelectsWrapper">
                    <div className="ImbueSelectItem">
                        <div className="label">Date</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            value={this.state.date}
                            onChange={(e) => this.setState({ date: e.target.value })}
                            placeholder="Today"
                        />
                    </div>
                    <div className="CircleBtn CircleBtnLg" onClick={this.onUpdate}>
                        {this.state.isLoading ? (
                            <i className="fas fa-redo" style={{ animation: "rotate 0.5s linear infinite" }}></i>
                        ) : (
                            <i className="fas fa-play"></i>
                        )}
                    </div>
                </div>
                <div>
                    {!this.state.isInit ? (
                        <div className="LoadingText">loading...</div>
                    ) : (
                        <div>
                            <div className="ImbueSelectsWrapper">
                                <ImbueSelect
                                    title="Code"
                                    defaultValue={codeOptions[0]}
                                    options={codeOptions}
                                    onChange={(e) => this.onChangeCode(e.value)}
                                />
                                <ImbueSelect
                                    title="Option"
                                    defaultValue={optionOptions[0]}
                                    options={optionOptions}
                                    onChange={(e) => this.onChangeOption(e.value)}
                                />
                                <ImbueSelect
                                    title="Sector"
                                    defaultValue={sectorOptions[0]}
                                    options={sectorOptions}
                                    onChange={(e) => this.onChangeSector(e.value)}
                                />
                                <ImbueSelect
                                    title="Maturity"
                                    defaultValue={maturityOptions[0]}
                                    options={maturityOptions}
                                    onChange={(e) => this.onChangeMaturity(e.value)}
                                />
                                <ImbueSelect
                                    width={400}
                                    isMulti={1}
                                    title="Commodity Products"
                                    options={commodityOptions}
                                    onChange={(e) => this.onChangeCommodity(e)}
                                />
                                <div className="ImbueSelectItem">
                                    <div className="label">Min Total Qty</div>
                                    <input
                                        className="ImbueInput"
                                        type="number"
                                        onChange={(e) => this.setState({ minimumTotalQty: e.target.value })}
                                    />
                                </div>
                            </div>
                            <Table
                                // filterable={true}
                                list={filtered}
                                columns={tablecolumns}
                            />
                        </div>
                )}
                </div>
                <h1>Glossary</h1>
                {glossary.map((ele, i) => (
                    <div key={i} className="glossary_row">
                        {ele}
                    </div>
                ))}
            </Layout>
        );
    }
}
