import React, { Component } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsTreemap from "highcharts/modules/treemap";

highchartsTreemap(Highcharts);

export default class CA_Treemap extends Component {
    render() {
        const data = this.props.data;
        console.log(data.map(ele => [ele.name, ele.value]));
        const options = {
            chart: {
                // width: 400,
                // height: 400,
                backgroundColor: "rgba(0,0,0,0)",
                style: {
                    fontFamily: "var(--font-main)",
                    fontSize: "1rem"
                }
            },
            colorAxis: {
                max: 1,
                min: -1,
                stops: [
                    [0, "#a10000"],
                    [0.3, "#ffcfcf"],
                    [0.5, "#FFFFFF"],
                    [0.7, "#d6ffd6"],
                    [1, "#005200"]
                ]
            },
            color: ["#2f7ed8", "#0d233a", "#8bbc21"],
            series: [
                {
                    type: "treemap",
                    layoutAlgorithm: "stripes",
                    alternateStartingDirection: true,
                    levels: [
                        {
                            level: 2,
                            layoutAlgorithm: "squarified"
                        }
                    ],
                    data: data,
                    label: {
                        enabled: true
                    }
                }
            ],
            credits: { enabled: false },
            plotOptions: {
                treemap: {
                    animation: false,
                    dataLabels: {
                        style: {
                            fontWeight: "normal",
                            fontSize: "1rem",
                            textOutline: "0"
                        }
                    }
                }
            },
            title: {
                text: ""
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={options} />;
    }
}
