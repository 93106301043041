/**
 * @author      Claude Chen
 * @company     Imbue Capital Pty Ltd
 * @created     2018
 */

import React, { Component } from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import SideBar from "./SideBar";
import TextStrip from "./TextStrip";
import MainBoard from "./MainBoard";
import { appComponents } from "../index/AppIndex";

import MasterTermStructureViewer from "../../src/screens/ResearchCenter/MasterTermStructureViewer";
// import HermesGeneral from "../../src/screens/ResearchCenter/HermesGeneral";
import Dashboard from "../screens/ResearchCenter/Dashboard";
import CalendarSpreads from "../screens/ResearchCenter/CalendarSpreads";

const InterfaceLayout = styled.div`
    .app_wrapper {
        width: 100vw;
        height: 100vh;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: calc(100vh - 33px) 33px;
        grid-template-areas:
            "sidebar mainboard"
            "sidebar subbar";
    }
`;

const MainBoardLayout = styled.div`
    grid-area: mainboard;
    background: white;
    overflow: auto;
`;



// Helper Functions
const singleAppWrapper = (paras) => {
    class mb extends Component {
        render() {
            const list = [
                {
                    name: paras.name,
                    // path: this.props.match.path + "/SystematicStrategies",
                    component: paras.component,
                },
            ];

            return (
                <div>
                    <MainBoard title={paras.name} list={list} />
                </div>
            );
        }
    }
    return mb
}

// App -> MainBoardWrapper
class MainBoardWrapper extends Component {
    render() {
        return (
            <MainBoardLayout>
                <Switch>
                    {/* return <Route path="/hermesgeneral/:root" component={HermesGeneral}/> */}
                    return <Route path="/dashboard/:root" component={Dashboard}/>
                    return <Route path="/calendarspreads/:spreadParam" component={CalendarSpreads}/>
                    return <Route path="/ra/MasterTermStructureViewer/:root" component={MasterTermStructureViewer}/>
                    {this.props.data.map((element, idx) => {
                        return element.apps.map((app) => {
                            let component = null
                            if (app.isSingle) {
                                component = singleAppWrapper({ name: app.name, component: app.component })
                            } else {
                                component = app.component
                            }
                            return <Route key={"mainboar_route_" + idx} exact={app.exact} path={app.path} component={component} />;
                        });
                    })}
                </Switch>
            </MainBoardLayout>
        );
    }
}

// Interface
class Interface extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <InterfaceLayout>
                <div className="app_wrapper">
                    <SideBar data={appComponents} />
                    <MainBoardWrapper data={appComponents} />
                    <TextStrip />
                </div>
            </InterfaceLayout>
        );
    }
}

export default Interface;
