import React, { Component } from "react";
import { api_positioning_analysis } from "../../helpers/API";
import { groupBy, getMargin } from "../../helpers/Utils";
import { highchartSimpleConfig, COLORMAP, GLOBALCOLOR } from "../../config";
import ImbueTabs from "../tabs/ImbueTabs";
import HighChartWrapper from "./HighChartWrapper";
import { BarLoader } from "react-spinners";
import styled from "styled-components";
import { addLegendSelect, addViewAngles } from "./helpers/HighchartButton";

const Layout = styled.div`
    .meta {
        color: #888;
        font-size: 1rem;
        overflow: auto;
    }

    .meta .item {
        padding-left: 5px;
        border-left: 2px solid #888;
    }
`;

const SeriesColorGenerator = GLOBALCOLOR.seriesColorGenerator;

// ---+--- Helpers ---+---

function generatePaChartConfig(data, viewmode, registerChart) {
    switch (viewmode) {
        // traders/dollarnotional scatter, most recent
        case 0: {
            // + chart 2: Scatter Chart: most recent and last 2 weeks
            let series = [
                {
                    type: "scatter",
                    name: "historical",
                    color: "rgba(100, 100, 100, 0.33)",
                    marker: {
                        radius: 2,
                    },
                    data: data.slice(0, data.length - 3).map((ele) => ({
                        x: ele.x,
                        y: ele.y,
                        name: ele.date,
                    })),
                },
                {
                    type: "scatter",
                    name: "2 weeks",
                    color: SeriesColorGenerator(1),
                    marker: {
                        radius: 4,
                    },
                    data: data.slice(data.length - 3, data.length - 2).map((ele) => ({
                        x: ele.x,
                        y: ele.y,
                        name: ele.date,
                    })),
                },
                {
                    type: "scatter",
                    name: "1 week",
                    color: SeriesColorGenerator(5),
                    marker: {
                        radius: 4,
                    },
                    data: data.slice(data.length - 2, data.length - 1).map((ele) => ({
                        x: ele.x,
                        y: ele.y,
                        name: ele.date,
                    })),
                },
                {
                    type: "scatter",
                    name: "most recent",
                    color: SeriesColorGenerator(3),
                    marker: {
                        radius: 8,
                        symbol: "diamond",
                    },
                    data: data.slice(data.length - 1, data.length).map((ele) => ({
                        x: ele.x,
                        y: ele.y,
                        name: ele.date,
                    })),
                },
            ];
            return {
                ...highchartSimpleConfig,
                chart: {
                    ...highchartSimpleConfig.chart,
                    zoomType: "xy",
                },
                xAxis: { title: { text: "traders" } },
                yAxis: { title: { text: "dollar notional positioning" } },
                series: series,
                plotOptions: {
                    scatter: {
                        animation: false,
                        turboThreshold: 3000,
                        tooltip: {
                            pointFormatter: function () {
                                let ret = `<b>${this.name}</b><br> x:<b>${this.x}</b> <br>y:<b>${this.y}</b> `;
                                return ret;
                            },
                        },
                        marker: {
                            states: {
                                hover: {
                                    radius: 8,
                                },
                            },
                        },
                    },
                },
            };
        }
        // traders/dollarnotional scatter, by year
        case 1: {
            // + chart 1: Scatter chart splitted by year
            data = data.map((ele) => ({ ...ele, year: ele.date.slice(0, 4) }));
            let splittedByYear = groupBy(data, "year");
            let series = splittedByYear.map((item, idx) => ({
                type: "scatter",
                name: item.group,
                marker: {
                    symbol: "circle",
                },
                color: SeriesColorGenerator(idx, 1),
                data: item.list.map((ele) => ({
                    x: ele.x,
                    y: ele.y,
                    name: ele.date,
                })),
            }));
            return {
                ...highchartSimpleConfig,
                chart: {
                    ...highchartSimpleConfig.chart,
                    zoomType: "xy",
                    events: {
                        load: addLegendSelect,
                    },
                },
                xAxis: { title: { text: "traders" } },
                yAxis: { title: { text: "dollar notional positioning" } },
                series: series,
                plotOptions: {
                    scatter: {
                        animation: false,
                        turboThreshold: 3000,
                        tooltip: {
                            pointFormatter: function () {
                                let ret = `<b>${this.name}</b><br> x:<b>${this.x}</b> <br>y:<b>${this.y}</b> `;
                                return ret;
                            },
                        },
                        marker: {
                            states: {
                                hover: {
                                    radius: 8,
                                },
                            },
                        },
                    },
                },
            };
        }
        // traders/dollarnotional/return 3d scatter plot
        case 2: {
            // + chart 3: 3d Scatter: net, return, and DRP
            data = data.filter((ele) => ele.x && ele.y && ele.z);
            const series = [
                {
                    type: "scatter3d",
                    name: "return",
                    color: "rgba(200, 200, 200, 0.3)",
                    data: data.map((ele) => {
                        return {
                            x: ele.x,
                            y: ele.z,
                            z: ele.y,
                            name: ele.date,
                            color:
                                ele.z > 0
                                    ? `rgba(0, 250, 0, ${0.1 + ele.z / getMargin(data.map((item) => item.z))})`
                                    : `rgba(250, 0, 0, ${0.1 + (ele.z * -1) / getMargin(data.map((item) => item.z))})`,
                        };
                    }),
                },
                {
                    type: "scatter3d",
                    name: "2 weeks",
                    color: SeriesColorGenerator(1),
                    marker: {
                        radius: 4,
                    },
                    data: data.slice(data.length - 3, data.length - 2).map((ele) => ({
                        x: ele.x,
                        y: ele.z,
                        z: ele.y,
                        name: ele.date,
                    })),
                },
                {
                    type: "scatter3d",
                    name: "1 week",
                    color: SeriesColorGenerator(4),
                    marker: {
                        radius: 4,
                    },
                    data: data.slice(data.length - 2, data.length - 1).map((ele) => ({
                        x: ele.x,
                        y: ele.z,
                        z: ele.y,
                        name: ele.date,
                    })),
                },
                {
                    type: "scatter3d",
                    name: "most recent",
                    color: SeriesColorGenerator(3),
                    marker: {
                        radius: 8,
                        symbol: "diamond",
                    },
                    data: data.slice(data.length - 1, data.length).map((ele) => ({
                        x: ele.x,
                        y: ele.z,
                        z: ele.y,
                        name: ele.date,
                    })),
                },
            ];
            return {
                ...highchartSimpleConfig,
                chart: {
                    ...highchartSimpleConfig.chart,
                    animation: false,
                    options3d: {
                        enabled: true,
                        alpha: 10,
                        beta: 30,
                        depth: 250,
                        viewDistance: 5,
                        fitToPlot: false,
                        frame: {
                            bottom: { size: 1, color: "rgba(0,0,0,0.02)" },
                            back: { size: 1, color: "rgba(0,0,0,0.04)" },
                            side: { size: 1, color: "rgba(0,0,0,0.06)" },
                        },
                    },
                    events: {
                        load: addViewAngles(registerChart),
                    },
                },
                zAxis: { title: { text: "dollar notional positioning" } },
                yAxis: {
                    title: { text: "1 Week % Price Change" },
                    labels: {
                        formatter: function () {
                            return this.value * 100 + "%";
                        },
                    },
                },
                xAxis: { title: { text: "traders" } },
                series: series,
                plotOptions: {
                    series: {
                        animation: false,
                        tooltip: {
                            pointFormatter: function () {
                                let ret = `<b>${this.name}</b><br> x:<b>${this.x}</b> <br>y:<b>${
                                    this.y * 100
                                }%</b>  <br>z:<b>${this.z}</b> `;
                                return ret;
                            },
                        },
                    },
                },
            };
        }
        // y value (dollar notional) timeseries
        case 3: {
            const series = [
                {
                    name: "Adjusted Price R",
                    type: "line",
                    color: COLORMAP.blue_o,
                    yAxis: 0,
                    data: data.map((ele) => [Date.parse(ele.date), ele.px_last_r]),
                    lineWidth: 1,
                },
                {
                    name: "Adjusted Price D",
                    type: "line",
                    color: COLORMAP.red_o,
                    yAxis: 0,
                    data: data.map((ele) => [Date.parse(ele.date), ele.px_last_d]),
                    lineWidth: 1,
                },
                {
                    name: "Dollar Notional Positioning",
                    type: "line",
                    color: COLORMAP.main,
                    yAxis: 1,
                    data: data.map((ele) => [Date.parse(ele.date), ele.y]),
                },
            ];
            return {
                ...highchartSimpleConfig,
                chart: {
                    ...highchartSimpleConfig.chart,
                    zoomType: "x",
                },
                tooltip: { shared: 1 },
                xAxis: { type: "datetime" },
                yAxis: [
                    { title: { text: "Adjusted Price" }, opposite: true },
                    { title: { text: "Dollar Notional Positioning" } },
                ],
                series: series,
            };
        }
        // delta_pct_combined_oi (delta only)
        case 4: {
            const series = [
                {
                    name: "Delta Pct Combined OI",
                    type: "line",
                    color: COLORMAP.main,
                    data: data
                        .filter((ele) => ele.delta_pct_combined_oi)
                        .map((ele) => [Date.parse(ele.date), ele.delta_pct_combined_oi]),
                },
            ];
            return {
                ...highchartSimpleConfig,
                chart: {
                    ...highchartSimpleConfig.chart,
                    zoomType: "x",
                },
                xAxis: { type: "datetime" },
                yAxis: { title: { text: "Delta Pct Combined OI" } },
                series: series,
            };
        }
        default:
            return {};
    }
}

export default class PositioningAnalysisChart extends Component {
    state = {
        meta: {},
        scatter: [],
        ticker1label: "",
        ticker2label: "",
        viewmode: 0,
        isLoading: false,
        isError: false,
    };

    componentDidMount() {
        this.getData();
    }

    onChangeView = (viewmode) => {
        this.setState({ viewmode });
    };

    getData = () => {
        this.setState({ isLoading: true }, () => {
            const comdty = this.props.comdty || "W 1 Comdty";
            const datatype = this.props.datatype || 1;
            const charttype = this.props.charttype || 1;
            const datefrom = this.props.date || "2010-01-01";
            api_positioning_analysis({ comdty, datatype, charttype, datefrom })
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    this.setState({
                        ...data,
                        isLoading: false,
                    });
                })
                .catch((e) => {
                    this.setState({ isError: true, isLoading: false });
                });
        });
    };

    registerChart = (chartObject) => {
        this.setState({
            chartObject,
        });
    };

    render() {
        // ::props
        const datatype = this.props.datatype || 1;
        // ::states
        const viewmode = this.state.viewmode;
        const data = this.state.scatter;
        const meta = this.state.meta;
        const registerChart = this.registerChart;

        let viewmodeOptions = [
            { label: "Most Recent", value: 0 },
            { label: "By Year", value: 1 },
            { label: "return", value: 2 },
            { label: "historical", value: 3 },
        ];
        if (datatype === 4) {
            viewmodeOptions.push({ label: "delta pct combined oi", value: 4 });
        }
        viewmodeOptions.push({ label: "meta", value: 5 });

        const chartConfig = generatePaChartConfig(data, viewmode, registerChart);
        const chartid = JSON.stringify(this.props) + viewmode;

        const charttitle = this.state.isError
            ? "no data for selected parameters"
            : {
                  1: "Net Non Commercials by # of Traders",
                  2: "Short Non Commercials by # of Traders",
                  3: "Long Non Commercials by # of Traders",
                  4: "Net Non Commercial Mismatch",
              }[this.props.charttype];
        const chartObject = this.state.chartObject;
        const is3d = { 2: true }[viewmode];

        const mainRender = () => {
            if (viewmode === 5) {
                return (
                    <div className="meta">
                        {Object.keys(this.state.meta).map((k) => {
                            return (
                                <div className="item">
                                    <b>{k}</b>: {this.state.meta[k]}
                                </div>
                            );
                        })}
                    </div>
                );
            } else {
                return (
                    <HighChartWrapper
                        key={chartid}
                        config={chartConfig}
                        chartObject={chartObject}
                        preload={{ is3d: is3d }}
                    />
                );
            }
        };

        return (
            <Layout>
                <h3>{charttitle}</h3>
                <ImbueTabs options={viewmodeOptions} selectedValue={this.state.viewmode} onClick={this.onChangeView} />
                <div>
                    {this.state.isLoading ? (
                        <BarLoader width={100} widthUnit="%" height={3} color={COLORMAP.main} />
                    ) : (
                        ""
                    )}
                </div>
                <div
                    className="highchartResponsiveWrapper"
                    style={{ margin: "20px 0", width: this.props.width, height: this.props.height || "30vh" }}
                >
                    {mainRender()}
                </div>
            </Layout>
        );
    }
}
