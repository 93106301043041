import React, { Component, Fragment } from "react";
import styled from "styled-components";
import ImbueSelect from "../../components/input/ImbueSelect";
import { toUnique } from "../../helpers/Utils";
import { COUNTRYLOOKUP } from "../../index/CountryCode";

import { api_couchdb } from "../../helpers/API";
import { embed } from "./News/helper";
import { GLOBALSTYLED } from "../../config";

// -- styled
const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)`
    .module_wrapper {
        padding: 20px;
    }
    iframe {
        height: 420px;
        box-shadow: var(--boxshadow-aws);
        padding: 20px;
        background: white;
    }
`;
// -- Options
// -- -- View Mode
const viewmodeOptions = [
    { label: "simple", value: 0 },
    { label: "detailed", value: 1 },
];
const defaultViewmode = viewmodeOptions[0];
// -- -- Input Mode
const inputmodeOptions = [
    { label: "False", value: 0 },
    { label: "True", value: 1 },
];
const defaultInputmode = inputmodeOptions[0];

// -- -- Country
const countryOptions = Object.keys(COUNTRYLOOKUP).map((key) => ({
    label: key,
    value: key,
}));
const defaultCountry = countryOptions[0];

export default class News extends Component {
    state = {
        keywordlookup: [],
        selectedViewMode: defaultViewmode.value,
        selectedInputMode: defaultInputmode.value,
        selectedCountry: defaultCountry.value,

        selectedCategory: "",
        selectedKeyword: "",

        isLoading: 0,
        isInit: 0,
        isTermSelected: 0,
        data: {
            avg_tone: [],
            vol_intensity: [],
            geojson: null,
        },
        popupCoord: null,
        popupProp: null,
        zoom: [1],
        center: [-95.518250335096376, 37.0902],
    };

    componentDidMount() {
        api_couchdb("imbuecloud_index_tables", "news_keyword_lookup").then((res) => {
            this.setState({
                keywordlookup: res.data.content.index,
                isInit: 1,
            });
        });
    }

    render() {
        // -- -- Category
        const categoryOptions = toUnique(this.state.keywordlookup.map((ele) => ele.category))
            .sort()
            .map((ele) => ({
                label: ele,
                value: ele,
            }));
        const defaultCategory = categoryOptions[0];
        // -- -- Keyword
        const keywordOptions = this.state.keywordlookup.map((ele) => ({
            label: ele.term,
            value: ele.term,
            category: ele.category,
        }));
        const defaultKeyword = "";
        const filterdKeywordOptions = keywordOptions.filter((ele) => ele.category === this.state.selectedCategory);

        if (this.state.isInit) {
            return (
                <Layout>
                    <div className="ImbueSelectsWrapper">
                        <ImbueSelect
                            title="View Mode"
                            defaultValue={defaultViewmode}
                            options={viewmodeOptions}
                            onChange={(e) => this.setState({ selectedViewMode: e.value })}
                        />
                        <ImbueSelect
                            title="Text Input"
                            defaultValue={defaultInputmode}
                            options={inputmodeOptions}
                            onChange={(e) => this.setState({ selectedInputMode: e.value })}
                        />
                        {this.state.selectedInputMode == 0 ? (
                            <Fragment>
                                <ImbueSelect
                                    title="Category"
                                    defaultValue={defaultCategory}
                                    options={categoryOptions}
                                    onChange={(e) => this.setState({ selectedCategory: e.value })}
                                />
                                <ImbueSelect
                                    key={this.state.selectedCategory}
                                    title="Keyword"
                                    defaultValue={defaultKeyword}
                                    options={filterdKeywordOptions}
                                    onChange={(e) => this.setState({ isTermSelected: 1, selectedKeyword: e.value })}
                                />
                            </Fragment>
                        ) : (
                            <div className="ImbueSelectItem">
                                <div className="label">Keywords:</div>
                                <input
                                    className="ImbueInput"
                                    type="text"
                                    placeholder="Press Enter to Search..."
                                    // value={this.state.selectedKeyword}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            this.setState({ isTermSelected: 1, selectedKeyword: e.target.value });
                                        }
                                    }}
                                />
                            </div>
                        )}
                        <ImbueSelect
                            title="Country"
                            defaultValue={defaultCountry}
                            options={countryOptions}
                            onChange={(e) => this.setState({ selectedCountry: e.value })}
                        />
                        <div className="ImbueSelectItem">
                            <div className="LinkBtn">
                                <a href="/ra/newsref" target="_blank">
                                    Keyword Lookup <i className="fas fa-external-link"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="main">
                        <div>
                            <h3>
                                Keyword:
                                {this.state.selectedKeyword || <span className="LoadingText">Select a Term</span>}
                            </h3>
                        </div>

                        {!this.state.isTermSelected ? (
                            ""
                        ) : this.state.isLoading ? (
                            <div className="LoadingText">Loading...</div>
                        ) : this.state.selectedViewMode == 0 ? (
                            <div>
                                <div className="gridBox gridBox_2">
                                    <div className="module_wrapper">
                                        {/* <HighStockWrapper config={chartConfig("gdelt")} /> */}
                                        {embed(
                                            "volume_timeline",
                                            this.state.selectedKeyword,
                                            this.state.selectedCountry
                                        )}
                                    </div>
                                    <div className="module_wrapper">
                                        {embed("googletrend", this.state.selectedKeyword, this.state.selectedCountry)}
                                    </div>
                                </div>
                                <div>
                                    <div className="module_wrapper">
                                        {embed("location_map", this.state.selectedKeyword, this.state.selectedCountry)}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            [
                                { title: "Google Trends", theme: "googletrend" },
                                { title: "Volume Timeline", theme: "volume_timeline" },
                                { title: "Tone Timeline", theme: "tone_timeline" },
                                { title: "Tone Barchart", theme: "tone_bar" },
                                { title: "Source Map", theme: "source_map" },
                                { title: "Location Map", theme: "location_map" },
                                { title: "Tags Wordcloud", theme: "tags_wordcloud" },
                                { title: "Topics Wordcloud", theme: "topics_wordcloud" },
                            ].map((ele) => {
                                return (
                                    <div className="module_wrapper">
                                        <h3>{ele.title}</h3>
                                        {embed(ele.theme, this.state.selectedKeyword, this.state.selectedCountry)}
                                    </div>
                                );
                            })
                        )}
                    </div>
                </Layout>
            );
        } else {
            return (
                <Layout>
                    <div className="LoadingText">Init...</div>
                </Layout>
            );
        }
    }
}
