import React, { Component } from 'react'
import styled from 'styled-components';
import { GLOBALSTYLED } from '../../config'
import S3PdfViewer from "../../components/pdfViewer/S3PdfViewer";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;

export default class DailyPortfolioSummary extends Component {
    render() {
        return (
            <Layout>
                <h1>Daily Portfolio Summary</h1>
                <S3PdfViewer bucketname="imbuereportbucket" id="daily_portfolio_trades.pdf" />
                <h1>Daily Sector Breakdown</h1>
                <S3PdfViewer bucketname="imbuereportbucket" id="sector_breakdown.pdf" />
            </Layout>
        )
    }
}
