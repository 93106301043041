import React, { Component } from "react";

export default class ImbueTag extends Component {
    render() {
        const DEFAULTCOLOR = "#999";
        return (
            <div className="ImbueTag" style={{ background: this.props.color || DEFAULTCOLOR }}>
                {this.props.value}
            </div>
        );
    }
}
