import React, { useState } from 'react';
import ImbueSelect from '../../components/input/ImbueSelect';
import {api_intraday_price_history} from "../../helpers/API";
import IntradayPriceHistory from './IntradayPriceHistory';
import "./style.css";


const comdtyMap = {
    'Corn' : 'C_',
    'Soybeans' : 'S_',
    'Wheat' : 'W_',
};

const reportCategories = [
    'Prospective Plantings',
    'WASDE'
];

const prospectivePlantingsReports = {
    // "" : "",
    "Mar '24" : "2024-03-28 11:00:00",
    "Mar '23" : "2023-03-31 11:00:00",
    "Mar '22" : "2022-03-31 11:00:00",
    "Mar '21" : "2021-03-31 11:00:00",
};

const wasdeReports = {
    // "" : "",

    "Apr '24" : "2024-04-11 11:00:00",
    "Mar '24" : "2024-03-08 11:00:00",
    "Feb '24" : "2024-02-08 11:00:00",
    "Jan '24" : "2024-01-12 11:00:00",
     
    "Dec '23" : "2023-12-08 11:00:00",
    "Nov '23" : "2023-11-09 11:00:00",
    "Oct '23" : "2023-10-12 11:00:00",
    "Sep '23" : "2023-09-12 11:00:00",
    "Aug '23" : "2023-08-11 11:00:00",
    "Jul '23" : "2023-07-12 11:00:00",
    "Jun '23" : "2023-06-09 11:00:00",
    "May '23" : "2023-05-12 11:00:00",
    "Apr '23" : "2023-04-11 11:00:00",
    "Mar '23" : "2023-03-08 11:00:00",
    "Feb '23" : "2023-02-08 11:00:00",
    "Jan '23" : "2023-01-12 11:00:00",
};

const reportOptionsMap = {
    'WASDE': Object.keys(wasdeReports).map((ele) => ({label: ele, value: wasdeReports[ele]})),
    'Prospective Plantings': Object.keys(prospectivePlantingsReports).map((ele) => ({label: ele, value: prospectivePlantingsReports[ele]})),
};

const comdtyOptions = Object.keys(comdtyMap).map((ele) => ({label: ele, value: comdtyMap[ele]}));
const reportCategoryOptions = reportCategories.map((ele) => ({label: ele, value: ele}));
let chartTitleVals = {};
const ReportsPage = () => {

    const [comdty, setComdty] = useState(comdtyOptions[0]);
    const [reportCategory, setReportCategory] = useState(reportCategoryOptions[0]);
    const [report, setReport] = useState(reportOptionsMap[reportCategory.value][0]);
    const [chartData, setChartData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



    function getData() {
        setIsLoading(true);
        setChartData(null);

        chartTitleVals = {
            comdtyName: comdty.label,
            reportType: reportCategory.label,
            reportLabel: report.label,
            reportDatetime: report.value,
        };
        const params = {
            comdty: comdty.value,
            date: (report.value).split(' ')[0],
        };

        api_intraday_price_history(params).then(res => {
            console.log(res.data);
            setChartData(res.data.results);
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            setIsLoading(false);
        });
    }  


    return (
        <>
            <div style={{margin: '10px', display: 'inline-block'}}>
                <div className='row'>
                    <ImbueSelect 
                        title="comdty"
                        options={comdtyOptions}
                        value={comdty}
                        onChange={(e) => {
                            setComdty(e);
                        }}
                    />
                    <ImbueSelect 
                        title="category"
                        options={reportCategoryOptions}
                        value={reportCategory}
                        onChange={(e) => {
                            setReportCategory(e);
                            setReport(reportOptionsMap[e.value][0])
                        }}
                    />
                    <ImbueSelect
                        title="report"
                        options={reportOptionsMap[reportCategory.value]}
                        value={report}
                        onChange={(e) => {
                            setReport(e);
                        }}
                    />

                    <div className="CircleBtn CircleBtnLg" onClick={() => getData()} style={{marginLeft: '10px', marginTop: '12px'}}>
                        {isLoading ? <i style={{ animation: "rotate 1s infinite" }} className="fas fa-spinner"></i> : <i style={{ marginLeft: "3px" }} className="fas fa-play"></i>}
                    </div>

                    
                </div>
                
            </div>
           
            <div style={{border: "2px solid #447463", margin: '20px'}}>
                {/* <IntradayPriceHistory comdty={comdty.value} session={'2023-03-31'} datetime={'2023-03-31 11:00:00'}/> */}
                { chartData && chartData.length > 0 && !isLoading &&
                    <IntradayPriceHistory data={chartData}  {...chartTitleVals} />
                }
            </div>
        </>
    );
}

export default ReportsPage;