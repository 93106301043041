import React, { Component } from "react";
import { api_ailab_factoranalysis } from "../../../../helpers/API";
import { COLORMAP } from "../../../../config";
import styled from "styled-components";

const Layout = styled.div`
    min-width: 100px;
`;

export default class FactorAnalysisModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        };
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        const { docids } = this.props;
        api_ailab_factoranalysis(docids)
            .then(res => {
                this.setState({
                    data: res.data
                });
            })
            .catch(err => console.log(err));
    }

    render() {
        const { data } = this.state;
        const coef_rank = data.result;
        if (coef_rank) {
            return (
                <Layout>
                    <div style={{ color: "var(--color-darkgold)", fontFamily: "roboto" }} className="onHover" onClick={this.getData}>
                        {" "}
                        Reload
                    </div>
                    {coef_rank.map(ele => (
                        <div>
                            <span style={{ color: ele.coef >= 0 ? COLORMAP.green : COLORMAP.red }}>{ele.coef.toFixed(2)}</span> -- {ele.factor}
                        </div>
                    ))}
                </Layout>
            );
        } else {
            return (
                <Layout>
                    <i style={{ color: "#999", textAlign: "center" }}>Loading ...</i>
                </Layout>
            );
        }
    }
}
