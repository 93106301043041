import React, { Component, useEffect } from "react";
import { render } from "react-dom";

class Sweep extends React.Component {

    constructor(props) {
        super(props);
    }

    // initialize web socket on mount
    componentDidMount() {
        init();
    }

    // Close the socket when unmounting to allow for fresh socket on reclick
    componentWillUnmount() { 
        // if (websocket && websocket.readyState == WebSocket.OPEN) {
        //     websocket.close();
        // }
        websocket.close();
    }

    
    render () {
        return (
            <div>
            <p>
                <label htmlFor="filter">Filter </label>
                {/* changed onChange= to => func */}
                <select id="filter" onChange={() => changeFilter()}>
                    <option value="">All Markets</option>
                    <option value="CL" >CL</option>
                    <option value="ES" >ES</option>
                    <option value="GC" >GC</option>
                    <option value="LE" >LE</option>
                    <option value="HE" >HE</option>
                    <option value="NG" >NG</option>
                    <option value="ZC" >ZC</option>
                    <option value="ZL" >ZL</option>
                    <option value="ZM" >ZM</option>
                    <option value="ZS" >ZS</option>
                    <option value="ZW" >ZW</option>
                </select>
                
            </p>
            {/* <p style={{marginTop: "-15px", marginBottom: "0px"}}>
              <label htmlFor="sound">  Sound</label>
              <input style={{marginLeft: "-835px", marginBottom: "-5px"}}type="checkbox" id="sound" name="sound"/>
            </p> */}
            
            <div id="status"></div>
            <div id="output"></div>
        </div>
        );
    }
}

var wsUri = window.location.protocol === 'https:'
? "wss://www.contango.net:9524/"
: "ws://www.contango.net:9532/";

// blank initializations that are set on socket-open
var output;
var websocket;
var el;

function do_warn (msg) {
  //if (is_beta) console.warn(msg);
}

function do_log(msg) {
  //if (is_beta) console.log(msg);
}


function signed (value) {
    return ( value > 0 ? "+" : "" ) + value;
}


// temporarily highglight new, incoming sweeps for 2 seconds
function highlight (el, color, after_color) {
    el.style.backgroundColor = color;
    setTimeout(function() {
      el.style.backgroundColor = after_color;
    }, 2000 * 1);
}


// get proper color based on direction
function dir_color (dir) {
    if (dir > 0) { // +
      return "rgb(57, 172, 231)"; 	// blue
    } else if (dir < 0) { // -
      return "rgb(255, 75, 75)"; 	// red
    } else { // implied trade
      return "rgb(224, 224, 224)"; 	// dark gray 
    }
}


// get proper highlight color based on direction
function dir_color_hl (dir) {
    if (dir > 0) { // +
      return "rgb(200, 225, 250)"; 	// light blue
    } else if (dir < 0) { // -
      return "rgb(255, 200, 200)";	// light red
    } else { // implied trade
      return "rgb(192, 192, 192)";	// gray
    }
}


// initialize web socket (makes sure it's not already open/connecting)
function init () {
    if (!websocket || websocket.readyState === WebSocket.CLOSED) {
        start_web_socket();
    }
    output = document.getElementById("output");
}


// set up web socket and its functions
function start_web_socket () {
    var status = document.getElementById("status");
    if (status)
        status.innerHTML = "<b>Connecting...</b>";
    websocket = new WebSocket(wsUri);
    websocket.onopen = function(evt) { onOpen(evt) };
    websocket.onclose = function(evt) { onClose(evt) };
    websocket.onmessage = function(evt) { onMessage(evt) };
    websocket.onerror = function(evt) { onError(evt) };
}

function onOpen (evt) {
    do_warn("CONNECTED");
    changeFilter(document.getElementById("filter"));
    var status = document.getElementById("status");
    if (status)
      status.innerHTML = "";
}


// reopen the socket after a second
function onClose (evt) {
    do_warn("DISCONNECTED");
    var status = document.getElementById("status");
    if (status)
      status.innerHTML = "<b>DISCONNECTED. (re-click 'Streaming Sweeps' to reconnect)</b>";
    //setTimeout(start_web_socket, 1000);
}


// change what markets are pulled from socket and displayed
// (Still under construction)
function changeFilter () {
    var filterEl = document.getElementById("filter");
    if (filterEl) {
      var filter = filterEl.value;
      //console.log(filter)
      var filter_json;
      if (filter === "*" || filter === "") {
        filter_json = JSON.stringify(['Stream']);
      } else {
        filter_json = JSON.stringify(['Stream', {filter:filter}]);
      }
      do_warn("FILTER TO "+filter_json);
      doSend(filter_json);
    }
}


function onMessage (evt) {
    var msg = JSON.parse(evt.data);
    writeMatch(msg);
}


function onError (evt) {
    do_warn(evt.data);
}


function doSend (msg) {
    if (websocket.readyState === WebSocket.OPEN) {
        do_warn("SENT: " + msg);
        websocket.send(msg);
    }
}


// helper function to count number of decimals places in a number
function countDecimals (num) {
    if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
    return num.toString().split(".")[1].length || 0;
}


// take in new matches from socket and format/display them 
function writeMatch (msg) {
    if (!msg.match)
      return;
    el = document.createElement("div");
    let multiple = msg.match.volume / msg.match.minimum;
    // if (is_beta)
    //   el.title = msg.match.time;
    let dateTime = ` (${msg.match.time.substring(0, 19)})`;
    let symbol = msg.match.rel;
    let amt = `[${msg.match.dir ? signed(msg.match.hittake) : msg.match.volume}]`.padEnd(8);
    let worst = countDecimals(msg.match.worst) > 2 ? 
        `worst=${(msg.match.worst).toFixed(2)}` : `worst=${msg.match.worst}`;
    worst = worst.padEnd(14);
    // if (is_beta) {
    //   el.innerHTML += " minimum= "+msg.match.minimum;
    //   el.innerHTML += " multiple= "+multiple;
    // }
    let splash = "";
    if (msg.match.splash !== 0) {
      splash = `splash=${Number(parseFloat(msg.match.splash).toPrecision(3))}`.padEnd(15);
    }
    if (!msg.match.dir && msg.match.apparent_dir)
      el.innerHTML += ", apparent= " + msg.match.apparent_dir;
    if (msg.match.match_seq > 0)
      el.innerHTML += ", stop";
    
    // place text in with proper formatting
    let pad = `          `;
    el.innerHTML = `<pre>${dateTime}${pad}${symbol}${pad}<b>${amt}${pad}</b>${worst}${pad}${splash}</pre>`;
    // styling
    el.style.borderStyle = "solid";
    el.style.borderWidth = "thin";
    el.style.borderColor = "black";
    el.style.backgroundColor = dir_color(msg.match.hittake);
    el.style.fontWeight = "500";
    el.style.color = "black";

    if (msg.sent) {
      el.style.fontStyle = "italic";
    } else {
      highlight(el, dir_color_hl(msg.match.hittake), dir_color(msg.match.hittake));
    }
    // get and insert previous sweeps on socket-open and continuously insert new ones
    output.insertBefore(el, output.childNodes[0]);
    // if (is_beta || !msg.sent) 
    //     make_sound(msg.match.hittake, multiple);
    while (output.childElementCount > 100) {
      output.removeChild(output.lastChild);
    }
}


function StreamSweep() {

  useEffect(() => {
    document.title = "Streaming Sweeps";
  }, []);

  return (
      <div className="SweepComp">
        <header className="SweepComp-header">
          <Sweep></Sweep>
        </header>
      </div>
  );
}

export default StreamSweep