import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {api_calendar_spreads_z} from "../../helpers/API";
import { LoadingPage } from "../../helpers/SimpleComponents";
import styles from "../../css/DashboardQuotes.module.css";


// check Z-score to determine which class style to use
function getClassName(z) {
    if (z < -2) {
        return 'z3n';
    } else if (z >= -2 && z < -1) {
        return 'z2n';
    } else if (z >= -1 && z < 0) {
        return 'z1n';
    } else if (z > 0 && z <= 1) {
        return 'z1p';
    } else if (z > 1 && z <= 2) {
        return 'z2p'; 
    } else if (z > 2) {
        return 'z3p';
    } else { // Z == 0, neutral
        return '';
    }
}


export default function DashboardCalendarSpreads(props) {
    
    const [state, setState] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const history = useHistory();

    // handle when props.root is change in dashboard
    useEffect(() => {
        getData();
    }, [props.root]);


    function getData() {
        setIsLoading(true);
        console.log(`loading data for ${props.root} ...`)
        api_calendar_spreads_z({root: props.root}).then(res => {
            console.log(res.data.results);
            setState(res.data.results);
            setHasError(false);
            setIsLoading(false);
        }).catch((e) => {
            console.log(e);
            setIsLoading(false);
            setHasError(true);
        });
    }


    // single cell Component display each spread's data
    function SpreadCell(props) {
        const name = props.name.split(" ")[0];
        const date = props.dataObj['DATE'];
        const price = props.dataObj['PX_LAST'];
        const price_z = props.dataObj['price_z'];
        const atr = props.dataObj['ATR'].toFixed(3);
        const atr_z = props.dataObj['atr_z'];
        const volume = props.dataObj['PX_VOLUME'];
        const volume_z = props.dataObj['vol_z'];
        return (
            <div style={{width: "80px", height: "90px", borderStyle: "ridge"}}>
                <div style={{textAlign:"center"}} 
                    onClick={() => {
                        console.log("switching to /calendarspreads/" + name);
                        history.push(`/calendarspreads/${name}`);
                    }}>
                    <abbr title={date}> {name} </abbr>
                </div>
                <div className={styles[getClassName(price_z)]}> {price !== null ? price: '-'} </div>
                <div className={styles[getClassName(atr_z)]}> {atr !== null ? atr : '-'} </div>
                <div className={styles[getClassName(volume_z)]}> {volume !== null ? volume : '-'} </div>
            </div>
        );
    }


    // Container Component that holds row of multiple SpreadCells
    function SpreadsRow() {
        const spreadItems = (Object.entries(state)).map(([spread, spreadData]) => (
            <li key={spread} style={{display: "inline-block"}}>
                <SpreadCell name={spread} dataObj={spreadData}/>
            </li>
        ));
        return (
            <div className="row">
                <ul style={{textAlign: "center", margin: "1px", padding: "2px"}}> {spreadItems} </ul>
            </div>
        );
    }


    if (isLoading) {
        return ( <div> {LoadingPage()} </div> );
    } else if (hasError) {
        return ( <div> Error fetching spreads data for {props.root} </div> );
    } else {
        return (
            <div>
                {state && 
                    <div className="row" >
                        <div style={{textAlign: "right"}}>
                            <div style={{marginTop: "8px"}}> 
                                {"Spread:"} 
                            </div>
                            <div> {"Price:"} </div>
                            <div> {"ATR:"} </div>
                            <div> {"Volume:"} </div>
                        </div>
                        <SpreadsRow/>
                    </div>
                }
            </div>
        );
    }
}
