import React, { useEffect,useContext, useState } from 'react'
import QuoteBlock from './QuoteBlock'
// import './Quotes.css'
import styles from "./Quotes.module.css"
import { LoadingPage } from "../helpers/SimpleComponents";
import {useHistory} from 'react-router-dom';
import {MainContext } from '../state';


const _ = require('lodash');



//          indices:  [ 0,  1,  2,  3,  4,  5,  6,  7,  8,  9, 10, 11 ]
// const allMonths = ['F','G','H','J','K','M','N','Q','U','V','X','Z'];
//                    Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec
// Trading Months (the months with available contracts to trade on CME for various commodities):
// const bean_months = ["F","H","K","N","Q","U","X"]; // ZS
// const bp_months =   ["F","H","K","N","Q","U","V","Z"] // ZM/ZL
// const grain_months = ["H","K","N","U","Z"]; // ZC, ZW/KW/MW, ZO, CC, CT, KC, HG, SI
// const rlj_months =  ["F", "H", "K", "N", "U", "X"]; // RR, LB, JO
// const feed_months = ["F", "H", "J", "K", "Q", "U", "V", "X"]; // GF
// const wood_months = ["F", "H", "K", "N", "U", "X"]; //LBS
// const live_months = ["G", "J", "M", "Q", "V", "Z"]; // LE
// const lean_months = ["G", "J", "K", "M", "N", "Q", "V", "Z"]; // HE
// const gold_months = ["G","J","M","Q","V","Z"]; // GC
// const plat_months = ["F","J","N","V"]; // PL
// const rate_months = ["H", "M", "U", "Z"]; // DX, EU, BP, JU, SF, CD, AD, US, TY, TU, MB, FV, ED, UB
// Categories
const size12_roots = ["CL", "NG", "RB", "HO"];
const size8_roots = ["ZS", "ZM", "ZL", "ZC", "ZW"];
const size4_roots = ["KE", "MWE", "GF", "HE", "LE", "LBR"];
const size3_roots = ["GC", "SI", "HG", "PL"];
// commodities that only display a single contract on the board
const solo_contracts = ["DX", "6E", "6J", "CNH", "6B", "6C", "6A", "6L", "6M",
                        "SR3", "ZT", "Z3N", "ZF", "ZN", "TN", "ZB",
                        "ES", "YM", "NQ", "RTY",
                        "BTC", "ETH"];

var WS; // web socket
var cacheComplete;  // cache messages are completed after seeing the first NULL ws message
var wsUnmount;      // denotes if component was unmounted 
var maxInterest;    // key: "ROOT", val: {symbol: "????", vol: #?}
var firstRun = true;// denotes when page was actually first opened instead of refreshed
var statusInit = false;
export default function QuoteBoard(props) {

    const [quoteStatus, setQuoteStatus] = useState("(click quote for timestamp)");
	
    const context = useContext(MainContext);
    const history = useHistory();
	//console.log(context);
	 
    // determine if current URL is live/development
    // wss for live/production, ws for development/testing
    var wsUrl = window.location.protocol === 'https:'
	  ? "wss://www.contango.net:9534/quoteboard"
      : "ws://www.contango.net:9533/quoteboard";

    // ------------------------------------------------------------------------------------------------------------------- \\
    // ---------------------------------------------  FUNCTION DECLARATIONS  --------------------------------------------- \\
    // ------------------------------------------------------------------------------------------------------------------- \\

    // only logs in development/testing setting, does nothing when live
    function debugLog(string) {
		
        if (wsUrl === "ws://www.contango.net:9533/") {
            console.log(string);
        }
    }

    // create/return JSON obj with necessary data from curEvt to be passed down to QuoteBlock
    function makeCurEvtObj(curEvt, isMaxVol) {
        return {root: curEvt.root,
                symbol: curEvt.symbol, 
                open: curEvt.session_open, 
                session_high: curEvt.session_high, 
                session_low: curEvt.session_low, 
                px_high: curEvt.px_high,
                px_low: curEvt.px_low, 
                px: curEvt.px_last, 
                set: curEvt.px_settle,
                upLmt: curEvt.high_limit_price,
                downLmt: curEvt.low_limit_price,
                isMaxVol: isMaxVol,
                timeStamp: curEvt.date
        }
    }

    // look at the root to determine how many quotes should be displayed in that QuoteBlock
    function getNumContractsToShow(root) {
        if (size12_roots.includes(root)) {
            return 12;
        } else if (size8_roots.includes(root)) {
            return 8;
        } else if (size4_roots.includes(root)) {
            return 4;
        } else if (size3_roots.includes(root)) {
            return 3;
        } else if (solo_contracts.includes(root)) {
            return 1;
        } else {
            debugLog("props did not contain root: " + root);
            return -1;
        }
    }
    
    // check if the current message should trigger update
    function boardShouldUpdate(evt) { 
        return ((evt.type === "minute" && statusRef.current.has(evt.root) && 
                statusRef.current.get(evt.root).has(evt.symbol) &&
                evt.px_last !== statusRef.current.get(evt.root).get(evt.symbol).px)) ||
                (solo_contracts.includes(evt.root) && evt.type === "minute");
    }

    // check that current cache message should trigger update
    function cacheShouldUpdate(curEvt) {
        return (statusRef.current.has(curEvt.root) && 
                new Date() <= new Date(curEvt.expiry) && 
                statusRef.current.get(curEvt.root).size < getNumContractsToShow(curEvt.root));
    }

    // initialize the state data structure.  rootMap< key: roots, val: new Map() >
    function initStatus(roots) {
        if (firstRun) {
            maxInterest = new Map();
            const rootMap = new Map();
            // Add each root to the primary map
            for (let i = 0; i < roots.length; i++) {
                //console.log(i)
                // initliaze each root's map with a new map to be later filled by cache message data
                rootMap.set(roots[i], new Map());
                //console.log(roots[i])
            }
            if (rootMap.size !== roots.length) console.error("rootMap ERROR -- lengths uneven!");
            debugLog("initStatus");
            firstRun = false;
            wsUnmount = false;
            statusInit = true;
            return rootMap;
        }
    }

    // update the state with info from new messages
    function updateStatus(curEvt) {
        // update status with clone to properly trigger re-render
        // I found this cloneDeep solution on SO (the bottom comment in the link below)
        // https://stackoverflow.com/questions/47624142/right-way-to-clone-objects-arrays-during-setstate-in-react
        const clone = _.cloneDeep(statusRef.current); // changed from status to statusRef
        const subClone = _.cloneDeep(clone.get(curEvt.root));
        // check for changes in contract with highest open interest
        if (maxInterest && !maxInterest.has(curEvt.root)) { 
            // first time seeing root, set new max
            maxInterest.set(curEvt.root, {symbol: curEvt.symbol, vol: curEvt.px_volume});
        } else if (maxInterest && maxInterest.has(curEvt.root) && subClone.has(maxInterest.get(curEvt.root)) 
            && curEvt.px_volume > maxInterest.get(curEvt.root).vol) {
            // update the new max volume, and the previous max
            let oldMaxSymbol = maxInterest.get(curEvt.root).symbol;
            maxInterest.set(curEvt.root, {symbol: curEvt.symbol, vol: curEvt.px_volume})
            let oldMaxObj = subClone.get(oldMaxSymbol);
            oldMaxObj.isMaxVol = false;
            subClone.set(oldMaxSymbol, oldMaxObj);
        } // else, do nothing
        const isMaxVol = maxInterest.get(curEvt.root).symbol === curEvt.symbol;
        const newObj = makeCurEvtObj(curEvt, isMaxVol);

        if (solo_contracts.includes(curEvt.root) && isMaxVol) {
            let newMap = new Map();
            newMap.set(curEvt.symbol, newObj);
            clone.set(curEvt.root, newMap);
        } else if (!solo_contracts.includes(curEvt.root)){
            subClone.set(curEvt.symbol, newObj);
            clone.set(curEvt.root, subClone);
        }
        setStatus(clone);
        
    }

    // initialize web socket connection and set its functions 
    function initWebSocket() {
        if (!WS || WS.readyState !== WebSocket.OPEN) {
            cacheComplete = false;
            debugLog("ws OPEN");
            WS = new WebSocket(wsUrl);
            WS.onmessage = function(evt) { onMessage(evt) };
            WS.onclose = function(evt) { onClose(evt) };
        }
    }

    // properly close the web socket connection
    function closeWebSocket() {
        if (WS.readyState === WebSocket.OPEN) {
            debugLog("ws CLOSE");
            WS.close();
        }
    }

    // triggered on unplanned web socket closure. Attempt to reconnect in loop
    function onClose() {
        if (!wsUnmount) {
            console.log("Web Socket disconnected. retrying in 10 seconds...");
            setTimeout(function() {
                initWebSocket();
            }, 10*1000);
        }
    }

    // handle incoming web socket messages. Update state and notify cache complete when appropriate
    function onMessage(evt) {
        const curEvt = JSON.parse(evt.data);
        if (evt !== null && curEvt !== null) { // not cache complete message
            if ((!cacheComplete && cacheShouldUpdate(curEvt)) || (cacheComplete && boardShouldUpdate(curEvt))) {
                updateStatus(curEvt);
            }
        } else { // cache complete message
            console.log("Cache message complete.");
            cacheComplete = true;
            const clone = _.cloneDeep(statusRef.current);
            // trigger re-render for the actual board to now be displayed
            clone.set("cacheComplete", true);
            setStatus(clone);
        }
    }

    // called on component unmount. Close web socket and manage booleans
    const unmountFunc = () => {
        useEffect(() => {
            return () => {
                wsUnmount = true;
                cacheComplete = false;
                firstRun = true;
                closeWebSocket();
                // console.log("unmount")
            }
        }, []);
    };

    // triggered when an individual quote piece is clicked
    // childData (string):  "ZSX1 : 2021-10-20 08:30:00"
    const handleCallback = (childData) => {
        console.log(childData);

        setQuoteStatus(childData.symbol + ": " + childData.timestamp);

        // const root = childData;
        // const path = `/Dashboard/${root}`;
		// history.push(path);
        
    };

    // ------------------------------------------------------------------------------------------------------------------ \\
    // --------------------------------------------------  INITIALIZE  -------------------------------------------------- \\
    // ------------------------------------------------------------------------------------------------------------------ \\

    // status: rootMap<"ZC", monthsMap>  ->  months<"ZCK1", quoteObj>  ->  quote{symbol: , open: , high: , low: , px: }
    // status: map(root) of maps(symbols) of objects(price_data) 
    const [status, setStatus] = React.useState(initStatus(props.roots));
    const statusRef = React.useRef({});
    statusRef.current = status;

    initWebSocket();
    // sets unmount function, but not executed until component unmounts
    unmountFunc();

    // ----------------------------------------------------------------------------------------------------------------- \\
    // -------------------------------------------------  RENDER  ------------------------------------------------------ \\
    // ----------------------------------------------------------------------------------------------------------------- \\
    if (cacheComplete && typeof statusRef.current !== 'undefined') {
        let keys = [...statusRef.current.keys()];
        return ( // STANDARD VIEW
            <div>
                <p style={{margin: "0px"}}>status: {quoteStatus}</p>
                 <div className={styles.row} style={{marginTop: "5px", marginLeft: "5px"}}>
                <div>
                    <div>
                        {/* Grains */}
                        <QuoteBlock status={status.get(keys[0])} boardCallback={handleCallback} /> 
                        <QuoteBlock status={status.get(keys[1])} boardCallback={handleCallback}/>
                        <QuoteBlock status={status.get(keys[2])} boardCallback={handleCallback}/>
                        <QuoteBlock status={status.get(keys[3])} boardCallback={handleCallback}/>
                        <QuoteBlock status={status.get(keys[4])} boardCallback={handleCallback}/>
                        <div className={styles.row}>
                            <QuoteBlock status={status.get(keys[5])} boardCallback={handleCallback}/>
                            <QuoteBlock status={status.get(keys[6])} boardCallback={handleCallback}/>
                        </div>
                    </div> 
                    <div className={styles.row} style={{marginTop: "5px"}}>
                        <div style={{marginRight: "3px"}}>
                            {/* Feeder/Live Cattle */}
                            <QuoteBlock status={status.get(keys[7])} boardCallback={handleCallback}/>
                            <QuoteBlock status={status.get(keys[8])} boardCallback={handleCallback}/>
                        </div>
                        <div>
                            {/* Lean Hogs / Lumber */}
                            <QuoteBlock status={status.get(keys[9])} boardCallback={handleCallback}/>
                            <QuoteBlock status={status.get(keys[10])} boardCallback={handleCallback}/>
                        </div>
                    </div>
                </div>
                <div> 
                    <div style={{marginLeft: "4px"}}>
                        {/* Energies */}
                        <QuoteBlock status={status.get(keys[11])} boardCallback={handleCallback}/>
                        <QuoteBlock status={status.get(keys[12])} boardCallback={handleCallback}/>
                        <QuoteBlock status={status.get(keys[13])} boardCallback={handleCallback}/>
                        <QuoteBlock status={status.get(keys[14])} boardCallback={handleCallback}/>
                    </div>
    
                    <div className={styles.row} style={{marginLeft: "5px"}}>
                        <div style={{marginTop: "5px"}}>
                            {/* Metals */}
                            <QuoteBlock status={status.get(keys[15])} boardCallback={handleCallback}/>
                            <QuoteBlock status={status.get(keys[16])} boardCallback={handleCallback}/>
                            <QuoteBlock status={status.get(keys[17])} boardCallback={handleCallback}/>
                            <QuoteBlock status={status.get(keys[18])} boardCallback={handleCallback}/>
                        </div>
                        <div style={{marginLeft: "5px", marginTop: "5px"}}>
                            <div className={styles.row}>
                                {/* Currencies */}
                                <QuoteBlock status={status.get(keys[19])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[20])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[21])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[22])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[23])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[24])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[25])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[26])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[27])} boardCallback={handleCallback}/>
                            </div>
                            <div className={styles.row}>
                                {/* Bonds */}
                                <QuoteBlock status={status.get(keys[28])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[29])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[30])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[31])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[32])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[33])} boardCallback={handleCallback}/>
                            </div>
                            <div className={styles.row}>
                                {/* Equity Indexes */}
                                <QuoteBlock status={status.get(keys[34])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[35])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[36])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[37])} boardCallback={handleCallback}/>
                            </div>
                            <div className={styles.row}>
                                {/* Crypto */}
                                <QuoteBlock status={status.get(keys[38])} boardCallback={handleCallback}/>
                                <QuoteBlock status={status.get(keys[39])} boardCallback={handleCallback}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    } else if (cacheComplete && typeof statusRef.current === 'undefined') { // user clicked 'Quote Board' while already on-page
        return (
            <div style={{marginLeft: "8px"}}>
                <h3>Quote Board DISCONNECTED.</h3>
                <b>(re-click 'Quote Board' to reconnect)</b>
            </div>
        );
    } else { // LOADING
        return (
            <div style={{marginLeft: "8px"}}>
                {LoadingPage()}
            </div>
        );
    }
}

