import React, { useState } from 'react';
// import './Quotes.css';
import styles from "./Quotes.module.css"


const grain_category = ["ZC", "ZW", "ZS", "KE", "MWE", "ZO"];
// const rate_cat = ["ZT", "Z3", "ZF", "ZN", "TN", "ZB"];
// const fx_cat = ["DX", "6E", "6J", "RMB", "6B", "6C", "6A", "6L", "6M"];
// const bean_cat = ["ZM", "ZL"]
const trim4_category = ["ZT", "Z3", "ZF", "ZN", "TN", "ZB",
    "DX", "6E", "6J", "RMB", "6B", "6C", "6A", "6L", "6M",
    "ZM", "ZL", "LBS"]


function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
}


function trimVal(sym, val) {
    let remainder =  val - Math.floor(val);
    if (grain_category.includes(sym)) {
        let tick;
        if (remainder === .25) {
            tick = '2';
        } else if (remainder === .5) {
            tick = '4';
        } else if (remainder === .75) {
            tick = '6';
        } else {
            tick ='0';
        }
        return parseInt(Math.floor(val).toString() + tick);
    } else if (trim4_category) {
        return toFixed(val, 4);
    }
    return val;
}


const staleStyle = {borderStyle: "ridge", marginRight: "1px"} // stale quotes have no color

export default function Quote(props) {
    // check cur date on each re-render to avoid midnight issues
    let todaysDate = new Date().toISOString().split('T')[0];

    const isStale = props.obj.date.split(' ')[0] !== todaysDate;
    // make font gray if the quote's date is not current
    const fontStyle = isStale ? {color: "gray"} : {color: "black"}

    const [displayDefault, setDisplayDefault] = useState(true);

    const onClick = () => {
        props.blockCallback(props.obj);
        // console.log(props.style)
        console.table(props.obj);
        if (props.obj.isMaxVol) {
            setDisplayDefault(!displayDefault);  // flip bool each click
            console.log(displayDefault);
        }
    }

    console.log()
    if (props.obj.isMaxVol && !displayDefault) {
        // click to toggle showing ATR and VWAP 
        return (
            <div className={styles.quote} style={props.style} onClick={onClick}>
                <div>
                    <h4 className={styles.title}>{props.obj.symbol}</h4>
                    <span className={styles.innerData}><pre>atr: </pre></span>
                    <span className={styles.innerData}><pre>{(props.obj.atr).toFixed(3)}</pre></span>
                    <span className={styles.innerData}><pre>vwap: </pre></span>
                    <span className={styles.innerData}><pre>{(props.obj.session_vwap).toFixed(3)}</pre></span>
                    <span className={styles.innerData}><pre> </pre></span>
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.quote} style={isStale ? staleStyle : props.style} onClick={onClick}>
                <div>
                    <h4 className={styles.title}>
                        <abbr title={props.obj.date}>
                            {props.obj.symbol}
                        </abbr>
                    </h4>
                    <span className={styles.innerData}>
                        <pre style={fontStyle}>{trimVal(props.obj.root, props.obj.session_open)}</pre>
                    </span>
                    <span className={styles.innerData}>
                        <pre style={fontStyle}>{trimVal(props.obj.root, props.obj.px_high)}</pre>
                    </span>
                    <span className={styles.innerData}>
                        <pre style={fontStyle}>{trimVal(props.obj.root, props.obj.px_low)}</pre>
                    </span>
                    <span className={styles.innerData}>
                        <b style={fontStyle}>{trimVal(props.obj.root, props.obj.px_last)}</b>
                    </span>
                    <span className={styles.innerData}>
                        <pre>
                            <span style={props.pxColor}>{
                                props.delta > 0 ? 
                                    "+"+trimVal(props.obj.root, props.delta.toString()) : 
                                    trimVal(props.obj.root, props.delta.toString())}
                            </span>
                        </pre>
                    </span>
                </div>
            </div>
        );
    }
}
    