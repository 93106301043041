import React, { Component } from "react";
import { api_monit } from "../../../helpers/API";
import moment from "moment";
import RadiusProgress from "../../../components/chart/RadiusProgress";
import { COLORMAP } from "../../../config";
import { deg_to_dms } from "../../../helpers/Utils";
import { LoadingPage } from "../../../helpers/SimpleComponents";

export default class SM_InstanceStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sysData: null,
            isLoading: false
        };
        this.updateStatus = this.updateStatus.bind(this);
    }

    updateStatus() {
        this.setState({ isLoading: true }, () => {
            api_monit()
                .then(res => {
                    let monit = res.data;
                    // 1.monit
                    let data = monit.message;
                    let sysData = {
                        platform: data.filter(ele => ele.type === "platform")[0],
                        os: data.filter(ele => ele.type === "os")[0],
                        services: data.filter(ele => ele.type === "service"),
                        http: data.filter(ele => ele.type === "http")
                    };
                    this.setState({
                        sysData,
                        isLoading: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    componentDidMount() {
        this.updateStatus();
        this.interval = setInterval(this.updateStatus, 3000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if (this.state.sysData) {
            let sysData = this.state.sysData;
            let cpu_perc = Math.round(
                sysData.os.cpu.map(ele => ele[1]).reduce((a, b) => a + b, 0),
                2
            );
            let mem_perc = sysData.os.memory[0];
            let { services } = sysData || [];

            return (
                <div className="flexBox flexBox_between">
                    <div>
                        {sysData.platform.data
                            .filter(ele => ["swap", "version", "machine"].indexOf(ele.label) < 0)
                            .map(ele => {
                                let label = ele.label;
                                let value = ele.value;
                                if (label === "memory") {
                                    if (value < 1024) {
                                        value = value + " KB";
                                    } else if (value < 1024 * 1024) {
                                        value = (value / 1024).toFixed(2) + " MB";
                                    } else {
                                        value = (value / 1024 / 1024).toFixed(2) + " GB";
                                    }
                                } else if (label === "cpu") {
                                    value = value + " cores";
                                }
                                return (
                                    <div style={{ marginBottom: "2px" }}>
                                        <div className="label">{label}</div>
                                        <div className="value">
                                            <div>{value}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        <div style={{ marginBottom: "2px" }}>
                            <div className="label">Updated</div>
                            <div className="value">{moment(sysData.os.updated).fromNow()}</div>
                        </div>
                    </div>

                    <div className="flexBox">
                        <div style={{ textAlign: "center", margin: "5px" }}>
                            <div className="fontSize__1rem fontColor__imbue_grey" style={{ margin: "5px 0" }}>
                                {" "}
                                <i style={{ fontSize: "2rem" }} className="fal fa-microchip"></i> <br /> CPU
                            </div>
                            <RadiusProgress type="medium" percentage={cpu_perc} />
                        </div>
                        <div style={{ textAlign: "center", margin: "5px" }}>
                            <div className="fontSize__1rem fontColor__imbue_grey" style={{ margin: "5px 0" }}>
                                <i style={{ fontSize: "2rem" }} className="fal fa-memory"></i> <br /> MEM
                            </div>
                            <RadiusProgress type="medium" percentage={mem_perc} />
                        </div>
                    </div>
                    <div className="grid_layout grid_layout_2">
                        {services.map(ele => {
                            let isHealthy = ele.status === 0;
                            return (
                                <div style={{ margin: "10px 0" }}>
                                    <div>
                                        {ele.name.split("_").join(" ")}
                                        <i
                                            className="fas fa-circle"
                                            style={{
                                                animation: isHealthy ? "heartbeat 1s infinite" : undefined,
                                                color: isHealthy ? COLORMAP.health : COLORMAP.red,
                                                margin: "0 5px"
                                            }}
                                        ></i>
                                    </div>
                                    {isHealthy ? (
                                        <div>
                                            <div className="flexBox" style={{ margin: "5px 0" }}>
                                                <div style={{ fontSize: "3rem" }}>{ele.memory[0]}%</div>
                                                <div style={{ fontSize: "1.1rem", color: "#333", marginLeft: "8px", fontFamily: "Roboto Mono", lineHeight: "13px" }}>
                                                    <div>MEM: {ele.memory[2]}</div>
                                                    <div>THR: {ele.threads}</div>
                                                    <div>CPU: {ele.cpu}</div>
                                                </div>
                                            </div>
                                            <div style={{ fontSize: "1rem", color: "#333", marginTop: "6px", fontFamily: "Roboto Mono" }}>
                                                <b>{deg_to_dms(ele.uptime)}</b>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return LoadingPage();
        }
    }
}
