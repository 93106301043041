import React, { Component } from "react";
import styled from "styled-components";
import { titleWords, toUnique } from "../../helpers/Utils";
import SimulationLayout from "../../components/simulation/SimulationLayout";
import { api_postgres } from "../../helpers/API";
import ImbueTabs from "../../components/tabs/ImbueTabs";

const Layout = styled.div`
    padding: 30px;

    .itemWrapper {
        font-family: var(--font-main);
        font-size: 1.1rem;
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    .itemWrapper .tag_rounded {
        margin-right: 10px;
    }

    .itemWrapper .LinkBtn {
        margin-left: 10px;
    }
`;

export default class BacktestList extends Component {
    state = {
        pfList: [],
        pfGroups: [],
        selectedGroup: "",
        selectedId: "",
    };

    componentDidMount() {
        this.initData();
    }

    initData = () => {
        api_postgres("portfolios").then((res) => {
            const pfList = res.data;
            const pfGroups = toUnique(pfList.map((ele) => ele.status));
            const selectedGroup = pfGroups[0];
            this.setState({
                pfList,
                pfGroups,
                selectedGroup,
            });
        });
    };

    render() {
        const optionsForGroups = this.state.pfGroups.map((ele) => ({ label: ele, value: ele }));

        console.log(optionsForGroups);

        if (this.state.selectedId) {
            const selectedId = this.state.selectedId;
            const props = {
                strategyId: selectedId,
                strategyName: titleWords(selectedId),
                strategyDescription: "",
            };
            return (
                <SimulationLayout
                    strategyMeta={props}
                    backBtn={() => {
                        this.setState({ selectedId: "" });
                    }}
                />
            );
        } else if (this.state.pfGroups.length > 0) {
            const filtered = this.state.pfList.filter((ele) => ele.status === this.state.selectedGroup);
            return (
                <Layout>
                    <ImbueTabs options={optionsForGroups} selectedValue={this.state.selectedGroup} onClick={(val) => this.setState({ selectedGroup: val })} />
                    <div>
                        {filtered.map((item) => {
                            return (
                                <div className="itemWrapper">
                                    <div className="tag_rounded" style={{ background: "var(--color-main)" }}>
                                        # {item.status}
                                    </div>
                                    <div>{item.name}</div>
                                    <div className="LinkBtn" onClick={() => this.setState({ selectedId: item.name })}>
                                        <i className="fas fa-chart-line"></i> View
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Layout>
            );
        } else {
            return (
                <Layout>
                    <div className="LoadingText">Initializing...</div>
                </Layout>
            );
        }
    }
}

/**
 *
 * optionsForGroupsZ
 */
