import React, { Component } from "react";
import styled from "styled-components";
import { getTickerName, SECURITY_POOL } from "../../../index/TickerDictonary";
import ImbueSelect from "../../../components/input/ImbueSelect";
import { api_position_risk } from "../../../helpers/API";
import { hasItem } from "../../../helpers/Utils";
import { parseNumber, formatNumber, fullAssetsOptions } from "./Common";

const Layout = styled.div``;

export default class StopLossRisk extends Component {
    state = {
        asset: fullAssetsOptions[0],

        contract: 2,
        number_of_contracts: 100,
        risk: 100000,

        data: [],
        isLoading: 0,
    };

    onUpdate = () => {
        const paras = {
            query: "single_notional_loss",
            assets: this.state.asset.value,
            contract: this.state.contract,
            number_of_contracts: this.state.number_of_contracts,
            risk: this.state.risk,
        };

        this.setState({ isLoading: 1 }, () => {
            api_position_risk(paras).then((res) => {
                this.setState({
                    data: Object.keys(res.data.result).map((k) => [
                        k,
                        res.data.result[k],
                    ]),
                    isLoading: 0,
                });
            });
        });
    };

    render() {
        const assetsOptions = fullAssetsOptions;

        const renderChart = () => {
            if (this.state.isLoading) {
                return <div className="LoadingText">loading...</div>;
            } else if (this.state.data.length > 0) {
                return (
                    <div className="flexBox">
                        {this.state.data.map((ele) => {
                            return (
                                <div className="DigitCard">
                                    <div className="title">
                                        {getTickerName(ele[0])}
                                    </div>
                                    <div className="digit">
                                        {/* {formatNumber(ele[1])} */}
                                        {ele[1]}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            }
        };

        return (
            <Layout>
                <div class="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="Asset"
                        value={this.state.asset}
                        options={assetsOptions}
                        onChange={(e) => this.setState({ asset: e })}
                    />

                    {["contract", "number_of_contracts", "risk"].map((ele) => {
                        return (
                            <div key={ele} className="ImbueSelectItem">
                                <div className="label">{ele}:</div>
                                <input
                                    className="ImbueInput"
                                    type="text"
                                    value={this.state[ele]}
                                    onChange={(e) => {
                                        let state = this.state;
                                        state[ele] = e.target.value;
                                        this.setState(state);
                                    }}
                                />
                            </div>
                        );
                    })}
                    <div
                        className="CircleBtn CircleBtnLg"
                        onClick={this.onUpdate}
                    >
                        <i className="fas fa-play"></i>
                    </div>
                </div>
                <div>{renderChart()}</div>
            </Layout>
        );
    }
}
