import React, { useState } from "react";
import styled from "styled-components";
import { api_historical_portfolio_view, api_rank_historical_portfolio } from "../../helpers/API";
import { GLOBALSTYLED } from "../../config";
import MultiTagSelect from "../../components/input/MultiTagSelect";
import HPVChart from "./components/HPVChart";
import "./style.css";
import moment from "moment";
import {verifyDateFormat} from "../../HelperFuncs.js";

document.title = "Historical Porfolio View";

const activeHyoTickers = ['EC', 'CL', 'HG', 'TY', 'W ', 'ES'];
// const START_DATE = '2022-10-20'
const START_DATE = '2022-01-01'

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;

const GlobalOptions = {
    'Dry Powder': [
        "W ",
        "SM",
        "KW",
        "MW",
        "C ",
        "BO",
        "FC",
        "LH",
        "LC",
        "DA",
        "CL",
        "XB",
        "NG",
        "CO",
        "HO",
        "GC",
        "SI",
        "PL",
        "PA",
        "HG",
        "CT",
        "KC",
        "CC",
        "S ",
        "SB",
        "BR",
        "DX",
        "ES",
        "UX",
        "JY",
        "CD",
        "BP",
        "NQ",
        "BTC",
    ],
    'Dynamic Carry': [
        "CL_3",
        "CL_6",
        "HO_3",
        "HO_6",
        "NG_3",
        "NG_6",
        "C _3",
        "C _6",
        "S _3",
        "S _6",
        "BO_3",
        "BO_6",
        "SM_3",
        "SM_6",
        "W _3",
        "W _6",
        "KW_3",
        "KW_6",
        "SB_3",
        "SB_6",
        "KC_3",
        "KC_6",
        "LH_3",
        "LH_6",
        "LC_3",
        "LC_6",
        "FC_3",
        "FC_6",
    ],
    'HYG': ["HG", "CL", "CO", "QS", "HO", "PA", "PL", "XB"],
    'ETFlows': ["GC", "SI", "PL", "PA", "HG"],
    'Breakeven': ["HG", "GC", "SI", "PA", "PL", "CL", "HO", "XB"],
    // 'Paired_Term_Structure': ["DEC_RBHO", "JUN_RBHO", "JUL_SMBO", "DEC_SMBO"],
    // crack_term_structure: ["CL6XB1_CL1XB3"],
    'Stacked Softs': ["SB", "KC", "CT", "CC"],
    'equity stacked': ["NQ", "ES"],
    'Stacked_Ags': ["W ", "SM", "S "],
    'Metals Rotation Slim v2': ["SI", "PL", "GC", "TY"],
    'Les Risk Plz': ["XB", "PL", "PA", "HO", "CO", "BO"],
    'CFTC Reactions': ["W ", "SM", "KW", "MW", "C ", "BO", "DA", "CL", "XB", "CO", "HO", "GC", "SI", "PL", "PA", "HG", "CT", "KC", "CC"],
    // skew_momentum: ["SM", "S ", "W ", "C ", "CL", "GC", "SI", "CT", "SB", "KC", "CC"],
    'SkewMomentum': ["SM", "S ", "W ", "C ", "CL", "GC", "SI", "CT", "SB", "KC", "CC"],
    'hyo': ["EC", "DX", "CD", "BR", "CHY", "CL", "HO", "XB", "NG", "GC", "HG", "PA", "PL", "SI",
        "BTC", "FV", "TY", "US", "WN", "S ", "SM", "BO", "C ", "W ", "KW", "MW", "LH", "LC", "FC",
        "ES", "NQ",
        "1ZS-2ZC",], // Hyo's new strategy
    'hyo_2': ["EC", "AD", "JY", "DX", "CD", "BR", "CHY", "CL", "HO", "XB", "NG", "GC", "HG", "PA", "PL", "SI",
        "BTC", "FV", "TY", "US", "WN", "S ", "SM", "BO", "C ", "W ", "KW", "MW", "LH", "LC", "FC",
        "ES", "NQ", "DM",
        "HOX"],
    // 'EUR_AI_Slow': ["EC"],
    'AI_Slow': ["C ", "S ", "W ", "BO", "CL", "NG", "GC", "SI", "EC", "BP", "CHY", "DX", "ES", "TY", "TU"],
};

const strategyOptions = [
    { label: "Dry Powder", value: "Dry Powder"},
    { label: "Dynamic Carry", value: "Dynamic Carry"},
    { label: "HYG", value: "HYG" },
    { label: "ETF", value: "ETFlows" },
    { label: "Breakevens", value: "Breakeven"},
    // { label: "Paired Term Structure", value: "Paired_Term_Structure"},
    // { label: "Crack Term Structure", value: "crack_term_structure"}, // doesn't exist anymore 
    { label: "Stacked Softs", value: "Stacked Softs"},
    { label: "Stacked Equities", value: "equity stacked"},
    { label: "Stacked Ags", value: "Stacked_Ags"},
    { label: "Metals Rotation", value: "Metals Rotation Slim v2"},
    { label: "Les Risk Plz", value: "Les Risk Plz"},
    { label: "COT", value: "CFTC Reactions"},
    // { label: "Skew Momentum", value: "skew_momentum"},
    { label: "Skew Momentum", value: "SkewMomentum"},
    { label: "Hyo", value: "hyo" }, // Hyo's new strategy
    { label: "Hyo_2", value: "hyo_2" },
    // { label: "AI Slow", value: "EUR_AI_Slow" },
    { label: "AI_Slow", value: "AI_Slow" },
];
const getTickerOptions = (strategy) => {
    return GlobalOptions[strategy].map((ele) => ({ label: ele, value: ele }));
};

const getActiveOptions = () => {
    return activeHyoTickers.map((ele) => ({ label: ele, value: ele }));
}

const strategySuffixes = {
    'Dry Powder'               : '_BE - Breakeven',
    'Dynamic Carry'            : '_DC - Dynamic Carry',
    'HYG'                      : '_HYG - HYG',
    'ETFlows'                  : '_ETF - ETFlows',
    'Breakeven'                : '_BE - Breakeven',
    // 'Paired_Term_Structure'    : '_PTS - Paired_Term_Structure',
    'Stacked Softs'            : '_SS - Stacked Softs',
    'equity stacked'           : '_SE - equity stacked',
    'Stacked_Ags'              : '_SA - Stacked_Ags',
    'Metals Rotation Slim v2'  : '_MR - Metals Rotation Slim v2',
    'Les Risk Plz'             : '_LRP - Les Risk Plz',
    'CFTC Reactions'           : '_COT - CFTC Reactions',
    // 'skew_momentum'         : '_SM - SkewMomentum',
    'SkewMomentum'             : '_SM - SkewMomentum',
    'hyo'                      : '_HYO - hyo',
    'hyo_2'                    : '_HYO_2 - hyo_2',
    // 'EUR_AI_Slow'              : '_AIS - EUR_AI_Slow'
    'AI_Slow'                  : '_AIS - AI_Slow',
}


// return strategylist sorted in accordance to its rankings
function getSortedStrats(strategylist, strats) {
    let sortedStrategyList = []
    // loop thru each strategy
    for (let strat in strats) {
        let curStratRanks = strats[strat].results;
        const suffix = strategySuffixes[strat]; // suffix = '_XX - xx_strategy'
        // loop thru each ticker within current strategy
        curStratRanks.forEach((ticker) => {
            if (strategylist.includes(ticker['ticker']+suffix)) {
                sortedStrategyList.unshift(ticker['ticker']+suffix); // add to front of list, heatmap format
            }
        })
    }
    return sortedStrategyList;
}


function setUpRankParams(query, startDate, endDate) {
    let rank_params = []
    for (let stratName in query) {
        rank_params.push({strategy_id: stratName, start_date: startDate, end_date: endDate})
    }
    return rank_params;
}


// Component to show the returns for each comdty, ranked in order of performance
function SectorRanks(props) {
    const ranks = props.ranks;
    const strat = props.strat;
    // console.table(ranks)
    const sectorStyle = {marginRight: "50px"};
    const decimalPlaces = 4;

    const sectors = ['agriculture', 'energy', 'metal', 'treasury', 'equity', 'currency'];
    let hasUndefined = false;
    sectors.forEach((sector) => {
        if (ranks[sector] === undefined) {
            hasUndefined = true;
        }
    })

    if (ranks && !hasUndefined) {
        return (
            <div>
                <h1>{strat} : Top Performers by Sector:</h1>
                <div className="row">
                    <div style={sectorStyle}>
                        <h3>Agriculture</h3>
                        <ol>
                            {ranks['agriculture'].map(rank =>
                                <li key={rank.index}>
                                    {`${rank.ticker} : ${(rank.total).toFixed(decimalPlaces)}`}
                                </li>
                            )}
                        </ol>
                    </div>
                    <div style={sectorStyle}>
                        <h3>Energy</h3>
                        <ol>
                            {ranks['energy'].map(rank =>
                                <li key={rank.index}>
                                    {`${rank.ticker} : ${(rank.total).toFixed(decimalPlaces)}`}
                                </li>
                            )}
                        </ol>
                    </div>
                    <div style={sectorStyle}>
                        <h3>Metal</h3>
                        <ol>
                            {ranks['metal'].map(rank =>
                                <li key={rank.index}>
                                    {`${rank.ticker} : ${(rank.total).toFixed(decimalPlaces)}`}
                                </li>
                            )}
                        </ol>
                    </div>
                    <div style={sectorStyle}>
                        <h3>Treasury</h3>
                        <ol>
                            {ranks['treasury'].map(rank =>
                                <li key={rank.index}>
                                    {`${rank.ticker} : ${(rank.total).toFixed(decimalPlaces)}`}
                                </li>
                            )}
                        </ol>
                    </div>
                    <div style={sectorStyle}>
                        <h3>Equity</h3>
                        <ol>
                            {ranks['equity'].map(rank =>
                                <li key={rank.index}>
                                    {`${rank.ticker} : ${(rank.total).toFixed(decimalPlaces)}`}
                                </li>
                            )}
                        </ol>
                    </div>
                    <div>
                        <h3>Currency</h3>
                        <ol>
                            {ranks['currency'].map(rank =>
                                <li key={rank.index}>
                                    {`${rank.ticker} : ${(rank.total).toFixed(decimalPlaces)}`}
                                </li>
                            )}
                        </ol>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

function getReturnsStyle(returnVal) {
    if (returnVal > 0) {
        return <p style={{color: "green", marginBottom: "-50px"}}>+{returnVal}%</p>
    } else if (returnVal <  0) {
        return <p style={{color: "red", marginBottom: "-50px"}}>{returnVal}%</p>
    } else  {
        return <p style={{color: "gray", marginBottom: "-50px"}}>unch</p>
    }
}
function LatestHighlight(props) {
    const latestVals = props.data.signal_rets_csum[props.data.signal_rets_csum.length - 1];
    const latestSP = (latestVals['SP'] * 100).toFixed(2);
    const latestBCOM = (latestVals['bcom'] * 100).toFixed(2);
    const latestPortf = (latestVals['portf'] * 100).toFixed(2);
    const style = {marginBottom: "-25px", fontWeight: "bold"};
    return (
        <div style={{marginTop: "-30px"}}>
            <div className="row" style={style}>
                <p>S&P 500: </p>
                {getReturnsStyle(latestSP)}
            </div>
            <div className="row" style={style}>
                <p>BCOM: </p>
                {getReturnsStyle(latestBCOM)}
            </div>
            <div className="row" style={style}>
                <p>Portfolio: </p>
                {getReturnsStyle(latestPortf)}
            </div>
        </div>
    )
}


function ChartComponent(props) {
    const data = props.data;
    const strategylist = Object.keys(data.master_signals[0] || {}).filter(ele => !["date"].includes(ele)).sort((a, b) => a.split(" - ")[1].localeCompare(b.split(" - ")[1]));
    const ranks = props.ranks;

    return (
        <HPVChart data={data} strategylist={getSortedStrats(strategylist, ranks)} ranks={ranks}/>
    );
}

let todaysDate = (new Date().toISOString()).split('T')[0]
// let stratRanks = {};
// let lastTrades;
export default function HistoricalPortfolioView() {

    const [stateData, setStateData] = useState({ master_signals: [], signal_rets_csum: [] });
    const [selectedStrategies, setSelectedStrategies] = useState([]);
    const [candidate, setCandidate] = useState({});
    const [viewpage, setViewpage] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState(null);

    const [startDate, setStartDate] = useState(START_DATE);
    const [endDate, setEndDate] = useState(todaysDate.toString());
    const [dateError, setDateError] = useState(false);
    const [dateStamp, setDateStamp] = useState("");
    
    const [stratRanks, setStratRanks] = useState({});
    // const [lastTrades, setLastTrades] = useState([]);


    const onUpdate = () => {
        let query = selectedStrategies.reduce((a,b) => {
            if (candidate[b.value]) {
                a[b.value] = candidate[b.value]
            }
            return a
        }, {} );
        let rank_params = setUpRankParams(query, startDate, endDate)
        // console.log(rank_params)
        setIsLoading(1);
        api_rank_historical_portfolio(rank_params).then(res => {
            setStratRanks(res.data);
            // console.log(stratRanks);
        }).catch(e => {
            console.error(e);
        })

        api_historical_portfolio_view({'start': startDate, 'end': endDate, 'strats': query}).then(res => {
            console.log(res.data)
            setStateData(res.data.result)
            setIsLoading(0);
            setError(null);
            setViewpage(1);
            setDateStamp(`Data: ${startDate} - ${endDate}`)
        }).catch(e => {
            setIsLoading(0);
            setError(`${e}`);
        })
    }


    const onSelectStrategies = (selectedStrategyOptions) => {
        setSelectedStrategies(selectedStrategyOptions)
    }


    const onSelectTickers = (strategy, selectedTickerOptions) => {
        if (selectedTickerOptions.length > 0) {
            candidate[strategy] = selectedTickerOptions.map(ele => ele.value)
        } else {
            if (strategy in candidate) {
                delete candidate[strategy]
            }
        }
        setCandidate(candidate)
    }


    const stratIsSelected = (strategy) => {
        return (selectedStrategies.some(strat => strat.value === strategy))
    }

    let data = stateData // data={master_signals: ..., signal_rets_csum: ...}
    if (viewpage === 0) {
        return (
        <Layout>
            <div className="flexBox flexBox_between">
                <div className="ImbueSelectsWrapper">
                
                    <div className="ImbueSelectItem">
                        <div className="label">Start Date:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            defaultValue={startDate}
                            onChange={(e) => {
                                // ensure proper date format and datefrom is not a future date or after dateto
                                if (verifyDateFormat(e.target.value) && moment(e.target.value).isBefore(moment().format("YYYY-MM-DD")) &&
                                moment(e.target.value).isSameOrBefore(moment(endDate))) {
                                    setStartDate(e.target.value)
                                    setDateError(false)
                                } else {
                                    setDateError(true)
                                }
                            }}
                        />
                        
                    </div>
                    <div className="ImbueSelectItem">
                        <div className="label">End Date:</div>
                        <input
                            className="ImbueInput"
                            type="text"
                            defaultValue={endDate}
                            onChange={(e) => {
                                // ensure proper date format and dateto is not a future date or before datefrom
                                if (verifyDateFormat(e.target.value) && moment(e.target.value).isSameOrBefore(moment().format("YYYY-MM-DD")) && 
                                moment(e.target.value).isSameOrAfter(moment(startDate))) {
                                    setEndDate(e.target.value)
                                    setDateError(false)
                                } else {
                                    setDateError(true)
                                }
                            }}
                        />
                        
                    </div>
                    <div className="ImbueSelectItem">
                        <div className="CircleBtn CircleBtnLg" onClick={() => {if (!dateError) {onUpdate()}}}>
                            <i className="fas fa-play"></i>
                        </div>
                    </div>
                    <p style={{marginLeft: "10px"}}>
                        {dateStamp}
                    </p>
                    { dateError &&
                    <p style={{color: "red"}}>date error</p>
                    }
                </div>
            <div className="LoadingText">{isLoading ? "Loading..." : <span className="LoadingTextError">{error}</span> }</div>
            </div>
            <div>
                <MultiTagSelect label="strategies" options={strategyOptions} value={selectedStrategies} onChange={(e) => onSelectStrategies(e)} />
            </div>
            <div>
                {
                selectedStrategies.map((ele, idx) => {
                    return <MultiTagSelect 
                        key={idx} 
                        label={ele.label+" securities"} 
                        options={getTickerOptions(ele.value)} 
                        activeOptions={getActiveOptions(ele.value)}
                        value={candidate[ele.value] ? getTickerOptions(ele.value).filter(item => candidate[ele.value].includes(item.value)) : [] }  
                        onChange={(e) => onSelectTickers(ele.value, e)} 
                    />
                })
                }
            </div>
        </Layout>)
    } else {
        return (
            <Layout>
                <LatestHighlight data={data} />
                <h3> (from: {startDate}  , to: {endDate}) </h3>
                <div className="flexBox">
                    <div className="SmallBtnAlt" onClick={() => setViewpage(0)}>
                        <i className="fal fa-angle-left"></i>Back
                    </div>
                </div>
                <ChartComponent data={data} ranks={stratRanks}/>
                <div className="row">
                    { stratIsSelected("hyo") && stratRanks['hyo'] &&
                        <SectorRanks ranks={stratRanks['hyo'].sectors} strat={"hyo"}/>
                    }
                    { stratIsSelected("hyo_2") && stratRanks['hyo_2'] &&
                        <div style={{marginLeft: "100px"}}>
                            <SectorRanks ranks={stratRanks['hyo_2'].sectors} strat={"hyo_2"}/>
                        </div>
                    }
                </div>
            </Layout>
        ); 
    }
}

