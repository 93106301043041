import React, { Component } from "react";
import { api_positioning_cotranking } from "../../helpers/API";
import styled from "styled-components";
import ImbueTable from "../../components/table/ImbueTable";
import { getTickerName } from "../../index/TickerDictonary";
import ImbueTabs from "../../components/tabs/ImbueTabs";
import { CSVLink } from "react-csv";

const Layout = styled.div`
    padding: 30px;

    section {
        margin: 10px 0;
    }
`;

const datatypeOptions = [
    { label: "Future+Options", value: "fo" },
    { label: "Future", value: "f" },
];

export default class COTRanking extends Component {
    state = {
        data: { fo: [], f: [] },
        isLoading: 0,
        selectedDatatype: datatypeOptions[0].value,
    };

    componentDidMount() {
        this.setState({ isLoading: 1 }, () => {
            api_positioning_cotranking().then((res) => {
                this.setState({ data: res.data, isLoading: 0 });
            });
        });
    }

    render() {
        const columns = [
            "security",
            "name",
            "date",
            "contract_value",
            "dollar notional",
            "netcommercial net",
            "netcommercial net wow",
            "netcommercial net 3yr_zscore",
            "netcommercial net wow 3yr_zscore",
        ];

        const tableconfig = {
            data: this.state.data[this.state.selectedDatatype].map((ele) => ({
                ...ele,
                name: getTickerName(ele.security),
            })),
            sorted: columns,
            onSorted: 1,
            flexDict: {
                "netcommercial net 3yr_zscore": 2,
                "netcommercial net wow 3yr_zscore": 2,
            },
        };

        return (
            <Layout>
                <section>
                    <h3>Select a data type</h3>
                    <ImbueTabs
                        options={datatypeOptions}
                        selectedValue={this.state.selectedDatatype}
                        onClick={(val) => this.setState({ selectedDatatype: val })}
                    />
                </section>
                <section>
                    <div className="flexBox">
                        <CSVLink data={tableconfig.data} headers={columns} filename={"data.csv"}>
                            <div className="SmallBtn">
                                <i className="fas fa-download"></i> download
                            </div>
                        </CSVLink>
                    </div>
                </section>
                <section>
                    {this.state.isLoading ? (
                        <div className="LoadingText">loading...</div>
                    ) : (
                        <ImbueTable key={this.state.selectedDatatype} config={tableconfig} />
                    )}
                </section>
            </Layout>
        );
    }
}
