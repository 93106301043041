/**
 * /config.js
 * Global configuration for the web-app
 * Claude Chen | claude.chen@imbuecapital.com
 */

import jwt_decode from "jwt-decode";
import styled from "styled-components";
import { getTickerName, SECURITY_POOL } from "./index/TickerDictonary";

/**
 * 0: localhost dev server
 * 1: mixtli staging server
 * 2: cbot new production server
 */
const mode = 2;






/**
 * URLS is a list of url prefix used to query data from web server
 */
const URLS = {
    LOCAL_URL: "http://localhost:8900",
	CBOT_STAGING_URL: "https://web.mixtli.comatl.com",
	CBOT_LIVE_URL: "https://web.cbot.comatl.com"
};

/**
 WSS is a list of websocket addresses for establishing 
 websocket communication with the web server
*/
const WSS = {
    LOCAL_URL: "ws://localhost:8900/ws/",
	CBOT_STAGING_URL: "wss://web.imbue.cloud/ws/",
	CBOT_LIVE_URL: "wss://web.imbue.cloud/ws/"
};

/**
 * URL used for connection to the webserver
 */
export const URL = [URLS.LOCAL_URL, URLS.CBOT_STAGING_URL, URLS.CBOT_LIVE_URL][mode];
export const WS = [WSS.LOCAL_URL,  WSS.CBOT_STAGING_URL, WSS.CBOT_LIVE_URL][mode];

/**
 * HOST is the current user's host address
 * e.g. http://localhost:3000 or https://imbue.cloud
 **/
export const HOST = window.location.origin;

export const EMAIL = localStorage.getItem("_email") || "";
export const FNAME = localStorage.getItem("_fname") || "";
export const TOKENEMAIL = localStorage.getItem("_token") ? jwt_decode(localStorage.getItem("_token")).sub : null;

/**
 * IP
 */
export const CLIENTIP = sessionStorage.getItem("_client_ip") || "";

/**
 * App global layout configuration
 */
export const layoutConfig = {
    sidebarWidth: 220,
    sidebarWidthCollapsed: 40,
    innerSidebarWidth: 200,
    navbarHeight: 48,
    textStripHeight: 33,
    navPlusTextHeight: 48 + 33,
};

export const COLORMAP = {
    main: "rgb(29, 60, 52)",
    minor: "rgb(68,117,99)",
    darkgold: "rgb(153, 122, 78)",
    gold: "rgb(247, 208, 153)",
    blue: "rgb(47, 101, 145)",
    grey: "#777",
    orange: "rgb(232, 123, 6)",
    health: "#06c32b",
    red: "#DD2C00",
    green: "#1B5E20",

    green_bg: "rgb(75, 198, 81)",
    red_o: "rgba(213, 0, 0, 0.5)",
    green_o: "rgba(46, 125, 50, 0.5)",
    red_oo: "rgba(213, 0, 0, 0.1)",
    red_bg: "rgb(232, 55, 55)",
    green_oo: "rgba(46, 125, 50, 0.1)",
    gold_oo: "rgb(153, 122, 78, 0.1)",
    blue_o: "rgba(47, 101, 145, 0.5)",

    bgGreen: "#C8E6C9",
    bgGrey: "#F5F5F5",
    bgRed: "#FFCCBC",

    imbue1: "rgb(96, 119, 110)",
    imbue2: "rgb(26, 44, 37)",
    imbue3: "rgb(73, 90, 83)",
    imbue4: "rgb(68, 117, 99)",
    imbue5: "rgb(70, 109, 85)",
    imbue6: "rgb(73, 127, 108)",
    imbue7: "rgb(219, 194, 154)",
    imbue8: "rgb(203, 175, 135)",
};

export const BOXSHADOW = {
    aws: "0 1px 1px 0 rgba(0,28,36,0.5)",
    strong: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    smooth: "0 0 0 1px rgba(0,0,0,.15),0 12px 30px rgba(0,0,0,.2)",
};

/**
 * STRATEGIC PORTOFLIO NAMES
 */
export const STRATEGIC_PFNAMES = [
    "statistical_arbitrage",
    "statistical_arbitrage_ml",
    "statistical_arbitrage_equity",
    "ailab_comdty",
    "ailab_comdty_exp",
    "ailab_equity",
    "ailab_bond_futures",
    "exp",
    "linear_comdty_portfolio",
    "ann_comdty_portfolio",
    "factor_comdty_statarb",
    "stat_arb_AI",
];

/**
 *
 */
export const highchartBasicConfig = {
    chart: {
        style: {
            fontFamily: "roboto",
        },
        backgroundColor: "rgba(0,0,0,0)",
        animation: false,
    },
    title: { text: "" },
    credits: { enabled: false },
    navigator: {
        maskFill: "rgba(78, 78, 78, 0.15)",
        height: 35,
        animation: false,
    },
    scrollbar: {
        height: 0,
        buttonArrowColor: "rgba(0,0,0,0)",
    },
    legend: { enabled: 1 },
    rangeSelector: {
        buttons: [
            { type: "all", text: "ALL" },
            { type: "year", count: 3, text: "3Y" },
            { type: "year", count: 1, text: "1Y" },
            { type: "ytd", text: "YTD" },
            { type: "month", count: 6, text: "6M" },
            { type: "month", count: 3, text: "3M" },
            { type: "month", count: 1, text: "1M" },
        ],
        buttonTheme: {
            fill: "#fff0",
            style: {
                fontSize: "1.1rem",
                fontFamily: "var(--font-main)",
                color: "#aaa",
            },
            floating: true,
            states: {
                hover: {
                    fill: "#fff0",
                    style: { color: "#000" },
                },
                select: {
                    fill: "#eee",
                    style: { color: "#111" },
                },
            },
        },
        labelStyle: { display: "none" },
        inputEnabled: false,
    },
    tooltip: { animation: false, outside: true, valueDecimals: 2 },
    xAxis: { type: "datetime", crosshair: true },
    yAxis: { gridLineColor: "#eee" },
    plotOptions: {
        series: {
            // color: COLORMAP.minor,
            dataGrouping: { approximation: "close" },
            animation: false,
        },
    },
};

export const highchartIntradayBasicConfig = {
    ...highchartBasicConfig,
    rangeSelector: {
        buttons: [
            { type: "all", text: "ALL" },
            // { type: "year", count: 3, text: "3Y" },
            // { type: "year", count: 1, text: "1Y" },
            // { type: "ytd", text: "YTD" },
            { type: "month", count: 1, text: "1M" },
            { type: "week", count: 3, text: "3w" },
            { type: "week", count: 1, text: "1w" },
            { type: "day", count: 3, text: "3d" },
            { type: "hour", count: 12, text: "12h" },
            { type: "hour", count: 1, text: "1h" },
        ],
        buttonTheme: {
            fill: "#fff0",
            style: {
                fontSize: "1.1rem",
                fontFamily: "var(--font-main)",
                color: "#aaa",
            },
            floating: true,
            states: {
                hover: {
                    fill: "#fff0",
                    style: { color: "#000" },
                },
                select: {
                    fill: "#eee",
                    style: { color: "#111" },
                },
            },
        },
        labelStyle: { display: "none" },
        inputEnabled: false,
    },
}

/**
 *
 */
export const highchartBasicDarkConfig = {
    chart: {
        style: {
            fontFamily: "roboto",
        },
        backgroundColor: "rgba(0,0,0,0)",
    },
    credits: { enabled: false },
    navigator: {
        maskFill: "rgba(240, 240, 240, .6)",
        height: 30,
    },
    scrollbar: {
        height: 0,
        buttonArrowColor: "rgba(0,0,0,0)",
    },
    rangeSelector: {
        inputEnabled: false,
        buttonTheme: {
            // styles for the buttons
            fill: "none",
            stroke: "none",
            "stroke-width": 0,
            r: 3,
            padding: 4,
            style: {
                fontFamily: "Titillium Web",
                color: "#447563",
                fontWeight: "bold",
            },
            states: {
                hover: {
                    fill: "rgba(37, 122, 100, 0.1)",
                    style: {
                        color: "#333",
                        border: "1px solid #333",
                    },
                },
                select: {
                    fill: "#447563",
                    style: {
                        color: "white",
                        border: "1px solid #333",
                    },
                },
                // disabled: { ... }
            },
        },
        labelStyle: {
            display: "none",
            color: "#333",
            fontWeight: "bold",
        },
    },
    xAxis: {
        type: "datetime",
        labels: {
            style: {
                color: "#eee",
            },
        },
    },
    yAxis: {
        opposite: 1,
        gridLineColor: "#333",
        title: {
            text: "P&L",
        },
        labels: {
            style: {
                color: "#eee",
            },
        },
    },
    plotOptions: {
        series: {
            animation: false,
            color: "rgb(175, 153, 3)",
            dataGrouping: { approximation: "average" },
        },
    },
};
export const highchartSimpleConfig = {
    chart: {
        style: {
            fontFamily: "roboto",
        },
        backgroundColor: "rgba(0,0,0,0)",
        animation: false,
    },
    title: { text: "" },
    credits: { enabled: false },
    navigator: {
        maskFill: "rgba(78, 78, 78, 0.15)",
        height: 35,
        animation: false,
    },
    scrollbar: {
        height: 0,
        buttonArrowColor: "rgba(0,0,0,0)",
    },
    rangeSelector: {
        buttons: [
            { type: "all", text: "ALL" },
            { type: "year", count: 3, text: "3Y" },
            { type: "year", count: 1, text: "1Y" },
            { type: "ytd", text: "YTD" },
            { type: "month", count: 6, text: "6M" },
            { type: "month", count: 3, text: "3M" },
            { type: "month", count: 1, text: "1M" },
        ],
        buttonTheme: {
            fill: "#fff0",
            style: {
                fontSize: "1.1rem",
                fontFamily: "var(--font-main)",
                color: "#aaa",
            },
            floating: true,
            states: {
                hover: {
                    fill: "#fff0",
                    style: { color: "#000" },
                },
                select: {
                    fill: "#eee",
                    style: { color: "#111" },
                },
            },
        },
        labelStyle: { display: "none" },
        inputEnabled: false,
    },
    tooltip: { animation: false, outside: true, valueDecimals: 2 },
    yAxis: { gridLineColor: "#eee" },
    plotOptions: {
        series: {
            dataGrouping: { approximation: "close" },
            animation: false,
        },
    },
};

/**
 *
 */
export const IMBUESELECTSTYLES = {
    control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? "0 0 0 1px rgb(68,117,99)" : undefined,
        borderColor: state.isFocused ? "rgb(68,117,99)" : "#ccc",
        ":hover": { borderColor: "rgb(68,117,99)" },
    }),
    valueContainer: (base) => ({
        ...base,
        fontFamily: "var(--font-main)",
        fontSize: "1.3rem",
    }),
    option: (base, state) => ({
        ...base,
        fontFamily: "var(--font-main)",
        fontSize: "1.3rem",
        background: state.isFocused ? "var(--color-main)" : "#fff",
        color: state.isFocused ? "#fff" : "var(--color-main)",
        ":hover": { background: state.isFocused ? "rgb(68,117,99)" : "#fff" },
    }),
};

/**
 *
 */
export const FEEBOOK = {
    general: {
        management_fee: 0.015,
        performance_fee: 0.18,
    },
    founders: {
        management_fee: 0.01,
        performance_fee: 0.15,
    },
    performance_only: {
        management_fee: 0.0,
        performance_fee: 0.2,
    },
};

/**
 * Default NAV weight for portfolio construction
 */
export const DEFAULTNAVWEIGHT = 3;

// ================== FINANCIAL ==================

/**
 * Ticker Sector Group
 */

export const TICKERSECTORINDEX = {
    DA: "Agriculture",
    BO: "Agriculture",
    "C ": "Agriculture",
    KW: "Agriculture",
    "O ": "Agriculture",
    RS: "Agriculture",
    "S ": "Agriculture",
    SM: "Agriculture",
    "W ": "Agriculture",
    MW: "Agriculture",
    CT: "Agriculture",
    KO: "Agriculture",
    WZ: "Agriculture",
    YW: "Agriculture",
    SB: "Agriculture",
    QW: "Agriculture",
    DF: "Agriculture",
    KC: "Agriculture",
    QC: "Agriculture",
    CA: "Agriculture",
    EP: "Agriculture",
    IJ: "Agriculture",
    CC: "Agriculture",
    JO: "Agriculture",
    FC: "Agriculture",
    LC: "Agriculture",
    LH: "Agriculture",
    LB: "Agriculture",

    CL: "Energy",
    XB: "Energy",
    DL: "Energy",
    HO: "Energy",
    NG: "Energy",
    CO: "Energy",
    QS: "Energy",

    LL: "Metal",
    LN: "Metal",
    LP: "Metal",
    LX: "Metal",
    GC: "Metal",
    HG: "Metal",
    PA: "Metal",
    PL: "Metal",
    SI: "Metal",
};

export const TICKERSECTORINDEX_L2 = {
    DA: "Ag Other",
    BO: "Ag Grains & Oilseeds",
    "C ": "Ag Grains & Oilseeds",
    KW: "Ag Grains & Oilseeds",
    "O ": "Ag Grains & Oilseeds",
    RS: "Ag Grains & Oilseeds",
    "S ": "Ag Grains & Oilseeds",
    SM: "Ag Grains & Oilseeds",
    "W ": "Ag Grains & Oilseeds",
    MW: "Ag Grains & Oilseeds",
    CT: "Ag Grains & Oilseeds",
    KO: "Ag Grains & Oilseeds",

    WZ: "Ag Grains & Oilseeds",
    YW: "Ag Grains & Oilseeds",
    SB: "Ag Grains & Oilseeds",
    QW: "Ag Grains & Oilseeds",
    DF: "Ag Grains & Oilseeds",
    KC: "Ag Grains & Oilseeds",
    QC: "Ag Grains & Oilseeds",
    CA: "Ag Grains & Oilseeds",
    EP: "Ag Grains & Oilseeds",
    IJ: "Ag Grains & Oilseeds",
    CC: "Ag Grains & Oilseeds",
    JO: "Ag Other",
    FC: "Ag Livestock",
    LC: "Ag Livestock",
    LH: "Ag Livestock",
    LB: "Ag Other",

    CL: "Energy",
    XB: "Energy",
    DL: "Energy",
    HO: "Energy",
    NG: "Energy",
    CO: "Energy",
    QS: "Energy",

    LL: "Metal",
    LN: "Metal",
    LP: "Metal",
    LX: "Metal",
    GC: "Metal",
    HG: "Metal",
    PA: "Metal",
    PL: "Metal",
    SI: "Metal",
};

export const SECTORTICKERINDEX = {
    Agriculture: [
        "DA1 Comdty",
        "BO1 Comdty",
        "C 1 Comdty",
        "KW1 Comdty",
        "O 1 Comdty",
        "RS1 Comdty",
        "S 1 Comdty",
        "SM1 Comdty",
        "W 1 Comdty",
        "MW1 Comdty",
        "CT1 Comdty",
        "KO1 Comdty",
        "WZ1 Comdty",
        "YW1 Comdty",
        "SB1 Comdty",
        "QW1 Comdty",
        "DF1 Comdty",
        "KC1 Comdty",
        "QC1 Comdty",
        "CA1 Comdty",
        "EP1 Comdty",
        "IJ1 Comdty",
        "CC1 Comdty",
        "JO1 Comdty",
        "FC1 Comdty",
        "LC1 Comdty",
        "LH1 Comdty",
        "LB1 Comdty",
    ],
    Energy: ["CL1 Comdty", "XB1 Comdty", "DL1 Comdty", "HO1 Comdty", "NG1 Comdty", "CO1 Comdty", "QS1 Comdty"],
    Metal: [
        "LL1 Comdty",
        "LN1 Comdty",
        "LP1 Comdty",
        "LX1 Comdty",
        "GC1 Comdty",
        "HG1 Comdty",
        "PA1 Comdty",
        "PL1 Comdty",
        "SI1 Comdty",
    ],
};

export const NATIONCODE = {
    CH: "CN",
    SA: "ZA",
    SK: "KR",
    BZ: "BR",
    TU: "TR",
    UK: "UA",
    GE: "DD",
    NE: "NL",
    JN: "JP",
    PD: "PL",
    TA: "TW",
    VZ: "VE",
    MA: "MY",
    BJ: "BH",
};

export const ALLSECURITIES = [
    "SM1 Comdty",
    "W 1 Comdty",
    "KW1 Comdty",
    "MW1 Comdty",
    "S 1 Comdty",
    "C 1 Comdty",
    "BO1 Comdty",
    "FC1 Comdty",
    "LH1 Comdty",
    "LC1 Comdty",
    "DA1 Comdty",
    "CL1 Comdty",
    "XB1 Comdty",
    "NG1 Comdty",
    "CO1 Comdty",
    "HO1 Comdty",
    "GC1 Comdty",
    "SI1 Comdty",
    "PL1 Comdty",
    "PA1 Comdty",
    "HG1 Comdty",
    "CT1 Comdty",
    "KC1 Comdty",
    "SB1 Comdty",
    "JO1 Comdty",
    "CC1 Comdty",
];


export const ALLSECOPTIONS = ALLSECURITIES.map((ele) => ({
    label: getTickerName(ele),
    value: ele,
}));


// #security

export const GLOBALFINANCE = {
    SECURITY_POOL: [
        "w 1 comdty",
        "sm1 comdty",
        "kw1 comdty",
        "mw1 comdty",
        "c 1 comdty",
        "bo1 comdty",
        "fc1 comdty",
        "lh1 comdty",
        "lc1 comdty",
        "da1 comdty",
        "cl1 comdty",
        "xb1 comdty",
        "ng1 comdty",
        "co1 comdty",
        "ho1 comdty",
        "gc1 comdty",
        "si1 comdty",
        "pl1 comdty",
        "pa1 comdty",
        "hg1 comdty",
        "ct1 comdty",
        "kc1 comdty",
        "cc1 comdty",
        "ad1 curncy",
        "br1 curncy",
        "dm1 index",
        "dx1 curncy",
        "ed1 comdty",
        "es1 index",
        "s 1 comdty",
        "sb1 comdty",
        "ty1 comdty",
        "ux1 index",
        "jy1 curncy",
        "jo1 comdty",
        "cd1 curncy",
        "sf1 curncy",
        "bp1 curncy",
        "pe1 curncy",
        "nv1 curncy",
        "nq1 index",
        "btc1 curncy",
        "le1 comdty",
    ],
    ROOTMAP: {
        "6B": ["British Pound", "BP1 A:00_0_R Curncy"],
        "6E": ["Euro", "EC1 A:00_0_R Curncy"],
        "6J": ["Japanese Yen", "JY1 A:00_0_R Curncy"],
        "6L": ["Brazilian Real", "BR1 A:00_0_R Curncy"],
        BLK: ["Block Cheese Futures", "BSC1 A:00_0_R Comdty"],
        CB: ["Cash-Settled Butter", "V61 A:00_0_R Comdty"],
        CC: ["Cocoa", "CC1 A:00_0_R Comdty"],
        CL: ["Crude Oil", "CL1 R:05_0_R Comdty"],
        CLT: ["Crude Oil TAS", "YGYG Comdty"],
        CSC: ["Cash-Settled Cheese", "CHE1 A:00_0_R Comdty"],
        CT: ["Cotton", "CT1 A:00_0_R Comdty"],
        DC: ["Class III Milk", "DA1 A:00_0_R Comdty"],
        DX: ["US Dollar Index", "DXY A:00_0_R Curncy"],
        DY: ["Dry Whey", "DRW1 Comdty"],
        ES: ["S&P 500 E-mini", "ES1 A:00_0_R Index"],
        GC: ["Gold", "GC1 A:00_0_R Comdty"],
        GCT: ["Gold TAS", "PVL1 A:00_0_R Comdty"],
        GDK: ["Class IV Milk", "KV1 A:00_0_R Comdty"],
        GE: ["3 Month Eurodollar", "ED1 A:00_0_R Comdty"],
        GF: ["Feeder Cattle", "FC1 A:00_0_R Comdty"],
        GNF: ["Nonfat Dry Milk", "LE1 A:00_0_R Comdty"],
        HE: ["Lean Hogs", "LH1 A:00_0_R Comdty"],
        HG: ["Copper", "HG1 A:00_0_R Comdty"],
        HO: ["Heating Oil", "HO1 A:00_0_R Comdty"],
        KC: ["Coffee", "KC1 A:00_0_R Comdty"],
        KE: ["KC HRW Wheat", "KW1 A:00_0_R Comdty"],
        LE: ["Live Cattle", "LC1 A:00_0_R Comdty"],
        MWE: ["MGEX HRS Wheat", "MW1 A:00_0_R Comdty"],
        NG: ["Natural Gas", "NG1 A:00_0_R Comdty"],
        NQ: ["NASDAQ 100 E-mini", "NQ1 A:00_0_R Comdty"],
        PA: ["Palladium", "PA1 A:00_0_R Comdty"],
        PL: ["Platinum", "PL1 A:00_0_R Comdty"],
        RB: ["RBOB Gasoline", "XB1 A:00_0_R Comdty"],
        SB: ["Sugar", "SB1 A:00_0_R Comdty"],
        SI: ["Silver", "SI1 A:00_0_R Comdty"],
        ZB: ["T-Bond Futures", "US1 A:00_0_R Comdty"],
        ZC: ["Corn", "C 1 A:00_0_R Comdty"],
        ZF: ["5-Year T-Note", "FV1 A:00_0_R Comdty"],
        ZL: ["Soybean Oil", "BO1 A:00_0_R Comdty"],
        ZM: ["Soybean Meal", "SM1 A:00_0_R Comdty"],
        ZN: ["10-Year T-Note", "TY1 A:00_0_R Comdty"],
        ZS: ["Soybean", "S 1 A:00_0_R Comdty"],
        ZT: ["2-Year T-Note", "TU1 A:00_0_R Comdty"],
        ZW: ["Wheat", "W 1 A:00_0_R Comdty"],
    },
};


// #color
export const GLOBALCOLOR = {
    seriesColorGenerator: (idx, isOpaque) => {
        const colorIndex = ["#D32F2F", "#7B1FA2", "#303F9F", "#0288D1", "#00796B", "#689F38", "#FBC02D", "#5D4037"];
        const color = colorIndex[idx % colorIndex.length] + (isOpaque ? "55" : "");
        return color;
    }
}


// #selector #options
export const GLOBALSELECTOPTIONS = {
    // Full Asset Select Options
    fullAssetsOptions: SECURITY_POOL.map((ele) => ({
        label: getTickerName(ele),
        value: ele.split("1")[0],
    })),
    // Full Commodity Select Options
    fullComdtyOptions: SECURITY_POOL.filter((ele) => ele.toLowerCase().indexOf("comdty") > -1).map((ele) => ({
        label: getTickerName(ele),
        value: ele.split("1")[0],
    })),
    // Full Contract Select Options
    fullContractOptions: [
        { label: "Generic 1st", value: "1" },
        { label: "Generic 2nd", value: "2" },
        { label: "Generic 3rd", value: "3" },
        { label: "Generic 6th", value: "6" },
        { label: "JAN1", value: "JAN1" },
        { label: "FEB1", value: "FEB1" },
        { label: "MAR1", value: "MAR1" },
        { label: "APR1", value: "APR1" },
        { label: "MAY1", value: "MAY1" },
        { label: "JUN1", value: "JUN1" },
        { label: "JUL1", value: "JUL1" },
        { label: "AUG1", value: "AUG1" },
        { label: "SEP1", value: "SEP1" },
        { label: "OCT1", value: "OCT1" },
        { label: "NOV1", value: "NOV1" },
        { label: "DEC1", value: "DEC1" },
        { label: "JAN2", value: "JAN2" },
        { label: "FEB2", value: "FEB2" },
        { label: "MAR2", value: "MAR2" },
        { label: "APR2", value: "APR2" },
        { label: "MAY2", value: "MAY2" },
        { label: "JUN2", value: "JUN2" },
        { label: "JUL2", value: "JUL2" },
        { label: "AUG2", value: "AUG2" },
        { label: "SEP2", value: "SEP2" },
        { label: "OCT2", value: "OCT2" },
        { label: "NOV2", value: "NOV2" },
        { label: "DEC2", value: "DEC2" },
    ]
}

// #styled
export const GLOBALSTYLED = {
    // Common Layout with 30px margin
    COMMONLAYOUT: styled.div`
        margin: 30px;
        box-sizing: border-box;
    `,
};


// #highchart #chart
export const GLOBALHIGHCHARTCONFIG = {
    highchartBasicConfig: {
        chart: {
            style: {
                fontFamily: "roboto",
            },
            backgroundColor: "rgba(0,0,0,0)",
            animation: false,
        },
        title: { text: "" },
        credits: { enabled: false },
        navigator: {
            maskFill: "rgba(78, 78, 78, 0.15)",
            height: 35,
            animation: false,
        },
        scrollbar: {
            height: 0,
            buttonArrowColor: "rgba(0,0,0,0)",
        },
        legend: { enabled: 1 },
        rangeSelector: {
            buttons: [
                { type: "all", text: "ALL" },
                { type: "year", count: 3, text: "3Y" },
                { type: "year", count: 1, text: "1Y" },
                { type: "ytd", text: "YTD" },
                { type: "month", count: 6, text: "6M" },
                { type: "month", count: 3, text: "3M" },
                { type: "month", count: 1, text: "1M" },
            ],
            buttonTheme: {
                fill: "#fff0",
                style: {
                    fontSize: "1.1rem",
                    fontFamily: "var(--font-main)",
                    color: "#aaa",
                },
                floating: true,
                states: {
                    hover: {
                        fill: "#fff0",
                        style: { color: "#000" },
                    },
                    select: {
                        fill: "#eee",
                        style: { color: "#111" },
                    },
                },
            },
            labelStyle: { display: "none" },
            inputEnabled: false,
        },
        tooltip: { animation: false, outside: true, valueDecimals: 2 },
        xAxis: { type: "datetime", crosshair: true },
        yAxis: { gridLineColor: "#eee" },
        plotOptions: {
            series: {
                // color: COLORMAP.minor,
                dataGrouping: { approximation: "close" },
                animation: false,
            },
        },
    }
}
