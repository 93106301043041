import React, { Component } from "react";
import styled from "styled-components";
import { GLOBALSTYLED, highchartBasicConfig, highchartIntradayBasicConfig } from "../../config";
import { api_intraday_data } from "../../helpers/API";
import ImbueSelect from "../../components/input/ImbueSelect";
import { get_datetime_by_n_day } from "../../helpers/DatetimeFunctions";
import HighStockWrapper from "../../components/chart/HighStockWrapper";


const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)`
.chartGrid {
    display: grid;
    grid-template-columns: 33% 34% 33%;
    grid-template-rows: 1fr;
}
`;

const frequencyOptions = [
    { value: "1", label: "1min" },
    { value: "5", label: "5min" },
    { value: "15", label: "15min" },
    { value: "30", label: "30min" },
    { value: "60", label: "1h" },
]
const defaultFrequencyOption = frequencyOptions[0]


class IntradayChart extends Component {
    state = {
        data: [],
        meta: {
            fields: []
        }
    }

    componentDidMount() {
        const ticker = this.props.ticker
        const fields = this.props.fields
        const datefrom = this.props.datefrom
        api_intraday_data({
            ticker,
            fields,
            datefrom
        }).then(res => {
            // console.log(res.data);
            this.setState({
                data: res.data.data,
                meta: res.data.query
            })
        })
    }


    render() {
        const getConfig = (type) => {
            if (type === "sb") {
                return ({
                    ...highchartIntradayBasicConfig,
                    yAxis: [
                        {height: "33%"},
                        {height: "33%", top: "34%"},
                        {height: "33%", top: "66%"}
                    ],
                    series: [
                        {
                            name: "price",
                            data: this.state.data.map(ele => [Date.parse(ele.datetime), ele['PX_LAST']]),
                            yAxis: 0
                        },
                        {
                            name: "normalized",
                            data: this.state.data.map(ele => [Date.parse(ele.datetime), ele['NORMALIZED']]),
                            yAxis: 1
                        },
                        {
                            name: "raw",
                            data: this.state.data.map(ele => [Date.parse(ele.datetime), ele['RSS']]),
                            yAxis: 2
                        }
                    ]
                })
            }else {
                return ({
                    ...highchartIntradayBasicConfig,
                    series: this.state.meta.fields.map(f => {
                        return ({
                            name: f,
                            data: this.state.data.map(ele => [Date.parse(ele.datetime), ele[f.toUpperCase()]] )
                        })
                    })
                })
            }
        }
        const charttype = this.props.type || "sb"
        const config = getConfig(charttype)

        return <div>
            <h3>{this.props.title}</h3>
            <HighStockWrapper height="400px" config={config}/>
        </div>
    }
}






export default class Intraday extends Component {

    state = {
        selectedFrequency: defaultFrequencyOption.value,
    }


    componentDidMount() {
    }

    render() {

        const chartlist = (f) => {

            const datefromMapping = {
                "1": get_datetime_by_n_day(30),
                "5": get_datetime_by_n_day(30),
                "15": get_datetime_by_n_day(60),
                "30": get_datetime_by_n_day(90),
                "60": get_datetime_by_n_day(90),
            }

            return [
                { title: "CL Structural Breaks", ticker: `structural_breaks_clx0_${f}t`, fields: ["PX_LAST", "RSS", "NORMALIZED"], datefrom: datefromMapping[f] },
                { title: "RB Structural Breaks", ticker: `structural_breaks_rbx0_${f}t`, fields: ["PX_LAST", "RSS", "NORMALIZED"], datefrom: datefromMapping[f] },
                { title: "HO Structural Breaks", ticker: `structural_breaks_hox0_${f}t`, fields: ["PX_LAST", "RSS", "NORMALIZED"], datefrom: datefromMapping[f] },
                { title: "Engergy Turbulence", ticker: `turbulence_energy_${f}t`, fields: ["raw turbulence", "turbulence"], datefrom: datefromMapping[f], type: "tb" },
                { title: "Engergy Systemic Risk", ticker: `systemic_risk_energy_${f}t`, fields: ["systemic risk"], datefrom: datefromMapping[f], type: "sr" },
                // { title: "CL Structural Breaks", ticker: `structural_breaks_clx0_${f}t`, fields: ["PX_LAST", "RSS", "NORMALIZED"], datefrom: datefromMapping[f] }
            ]
        }

        const list = chartlist(this.state.selectedFrequency)

        return (
            <Layout>

                <div className="ImbueSelectsWrapper">
                    <ImbueSelect options={frequencyOptions} defaultValue={defaultFrequencyOption} onChange={(e) => this.setState({selectedFrequency: e.value})} />
                </div>

                <div className="chartGrid">
                {
                    list.map((ele, idx) => {
                        return <IntradayChart  key={idx+this.state.selectedFrequency} title={ele.title} ticker={ele.ticker} fields={ele.fields} datefrom={ele.datefrom} type={ele.type} />
                    })
                }
                </div>

            </Layout>
        );
    }
}
