import React, { Component } from "react";
import MultiFunctionalChart from "../../components/chart/MultiFunctionalChart";
import { generateSeasonal } from "../../helpers/ChartFunctions";
import ClimateVariationIndex from "../../index/ClimateVariationIndex";
import styled from "styled-components";
import { BarLoader } from "react-spinners";
import { COLORMAP } from "../../config";
import moment from "moment";
import { api_blp } from "../../helpers/API";

const Layout = styled.div`
    padding: 20px;
    .gridWrapper {
        display: grid;
    }
    @media (min-width: 700px) {
        .gridWrapper {
            grid-template-columns: 100%;
        }
    }
    @media (min-width: 1400px) {
        .gridWrapper {
            grid-template-columns: 50% 50%;
        }
    }
    @media (min-width: 2000px) {
        .gridWrapper {
            grid-template-columns: 33% 33% 33%;
        }
    }

    .chart_layout {
        background: white;
        padding: 20px;
        margin: 10px;
        border-radius: 5px;
        font-family: var(--font-main);
    }
`;

const ChartLayout = styled.div``;

class ClimateVarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastDate: "---",
            chartData: {},
            isLoading: false
        };
    }

    getData(ticker) {
        this.setState(
            {
                isLoading: true
            },
            () => {
                api_blp(ticker, "PX_LAST", "1000-01-01")
                    .then(res => {
                        let data = res.data.content.data.PX_LAST;
                        // console.log(data);
                        let chartData = {
                            line: [
                                {
                                    name: ticker,
                                    data: data.map(ele => [Date.parse(ele[0]), ele[1]]),
                                    color: COLORMAP.blue
                                }
                            ],
                            seasonal: generateSeasonal(data, true),
                            table: {
                                name: ticker,
                                data: data
                                    .slice()
                                    .reverse()
                                    .map(ele => {
                                        return { date: ele[0], value: ele[1] };
                                    }),
                                columns: ["date", "value"].map(item => {
                                    return { Header: item.toUpperCase(), accessor: item };
                                })
                            }
                        };
                        let lastDate = data.slice().reverse()[0][0];
                        this.setState({
                            lastDate,
                            chartData,
                            isLoading: !this.state.isLoading
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        );
    }

    componentDidMount() {
        this.getData(this.props.ticker);
    }

    render() {
        return (
            <div className="chart_layout boxBorder">
                <div className="fontColor__imbue_green fontSize__2p3rem fontWeight__fat">{this.props.fullName}</div>
                <div className="fontColor__imbue_grey fontSize__1p8rem fontWeight__fat">{this.props.climateIndexName}</div>
                <div className=" \fontFamily__imbue_info fontSize__1p1rem" style={{ padding: "5px 0", color: "#333" }}>
                    <b>Last Updated:</b> {moment(this.state.lastDate, "YYYY-MM-DD").format("MMMM YYYY")}
                    <br />
                    <b>Description:</b> <br /> {this.props.description}
                </div>
                <div className="chart_main">
                    {this.state.isLoading ? (
                        <div style={{ textAlign: "center", padding: "100px", fontFamily: "var(--font-main)", fontSize: "1.2rem", color: "#777" }}>
                            <div style={{ marginBottom: "10px" }}>Loading Data ...</div>
                            <div style={{ background: "#eee" }}>
                                <BarLoader color="var(--color-main)" width="100%" />
                            </div>
                        </div>
                    ) : (
                        <MultiFunctionalChart data={this.state.chartData} hotReload={true} height={300} defaultZoom={3} />
                    )}
                </div>
            </div>
        );
    }
}

export default class NDVIDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // console.log(ClimateVariationIndex)
    }

    render() {
        return (
            <Layout>
                <div className="gridWrapper">
                    {ClimateVariationIndex.map(dict => {
                        return <ClimateVarChart fullName={dict.fullName} description={dict.description} climateIndexName={dict.name} ticker={dict.ticker} />;
                    })}
                </div>
            </Layout>
        );
    }
}
