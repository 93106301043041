exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.Quotes_myStyle__3FW-N {\n  background-color: white;\n}\n\n.Quotes_quote__3NsfM {\n  width: 68px;\n  height: 110px;\n  margin: -1px;\n  /* border-style: ridge; */\n  font-family: Arial;\n  text-align: left;\n  font-size: small;\n  line-height: 1.2;\n}\n\n.Quotes_row__pQZZw {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  list-style-type: none;\n}\n\n.Quotes_column__22E-T {\n  display: flex;\n  flex-direction: column;\n}\n\n.Quotes_title__2thIr {\n  margin-bottom: -10px;\n  margin-top: 2px;\n  text-align: center;\n}\n\n.Quotes_innerData__3PJJW {\n  display: block;\n  text-align: center;\n  margin-top: -10px;\n  margin-bottom: -10px;\n  margin-left: 5px;\n  margin-right: 5px;\n}", ""]);

// exports
exports.locals = {
	"myStyle": "Quotes_myStyle__3FW-N",
	"quote": "Quotes_quote__3NsfM",
	"row": "Quotes_row__pQZZw",
	"column": "Quotes_column__22E-T",
	"title": "Quotes_title__2thIr",
	"innerData": "Quotes_innerData__3PJJW"
};