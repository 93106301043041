import React, { Component } from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import { COLORMAP } from "../../config";

const Layout = styled.div`
    .price_container {
        font-family: var(--font-main);
        text-align: left;
    }

    .upper {
        display: flex;
        align-items: center;
    }

    .price_arrow,
    .price_current {
        font-weight: 800;
        font-size: 2.8rem;
    }

    .price_current {
        padding: 0 4px;
    }

    .price_change,
    .price_change_pct {
        font-size: 1rem;
    }

    .price_date {
        clear: both;
        font-family: var(--font-main);
        font-size: 1rem;
        text-align: left;
        color: #444;
    }

    .small_digit_wrapper {
    }
`;

const styleSelector = trend => {
    if (trend === "up") {
        return {
            color: "#00b894",
            icon: "fas fa-arrow-up"
        };
    } else if (trend === "down") {
        return {
            color: "#d63031",
            icon: "fas fa-arrow-down"
        };
    } else {
        return {
            color: COLORMAP.grey,
            icon: "fas fa-circle"
        };
    }
};

class PriceChangeCard extends Component {
    render() {
        let data = this.props.change;
        return (
            <Layout>
                <div className="price_container">
                    <div className="upper">
                        <div
                            style={{
                                color: styleSelector(data.trend).color
                            }}
                            className="price_arrow"
                        >
                            <i className={styleSelector(data.trend).icon} />
                        </div>
                        <div className="price_current">
                            <NumberFormat value={data.price} displayType={"text"} thousandSeparator={true} decimalScale={2} />
                        </div>
                        <div className="small_digit_wrapper" style={{ color: styleSelector(data.trend).color }}>
                            <div className="price_change">{data.change}</div>
                            <div className="price_change_pct">{data.change_pct}</div>
                        </div>
                    </div>
                    <div className="price_date">{data.date}</div>
                </div>
            </Layout>
        );
    }
}

export default PriceChangeCard;
