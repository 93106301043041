import React, { useState, useEffect } from 'react';
import RollsTable from '../../components/table/RollsTable';
import { api_roll_history } from '../../helpers/API';
import ImbueSelect from '../../components/input/ImbueSelect';
import {all_sectors} from '../../../src/universalSymbolMap';
import { LoadingPage } from "../../helpers/SimpleComponents";
import HighStockWrapper from '../../components/chart/HighStockWrapper';


const sectorOptions = Object.values(all_sectors()).map((sector) => ({label: sector, value: sector}))


const RollsHistory = () => {
    const [data, setData] = useState(null);
    const [sector, setSector] = useState({label: "Grain/Oilseed", value: "Grain/Oilseed"});
    const [loading, setLoading] = useState(false);
    const [chartSymbol, setChartSymbol] = useState(null);

    useEffect(() => {
        setData(null);
        setLoading(true);
        setChartSymbol(null);

        // GET DATA
        api_roll_history({'sector': sector.value}).then((res) => {
            console.log(res.data.results)
            setData(res.data.results);
        })
        .catch((e) => {
            console.error(e)
            setData([])
        })
        .finally(() => {
            setLoading(false);
        });

        return () => {
            // console.log("unmounting")
        }; 
    }, [sector]);


    const handleChartSymbolChange = (symbol) => { setChartSymbol(symbol) };

    const chartConfig = (symbol) => {
        if (!symbol || !data || !(symbol in data)){
            console.error("  :(    ")
            return {};
        }
        const res =  {
            chart: {
                height: (9 / 16 * 60)+'%',
            },
            title: {
                text: symbol+" Roll History",
            },
            plotOptions: {
                series: {
                    animation: false,
                }
            },
            xAixs: {
                type: "datetime",
            },
            yAxis: [
                { height: "70%", title: {text: "price"} },
                { top: "70%", height: "30%", title: {text: "adj diff"}, opposite: false },
            ],
            credits: {
                enabled: false,
            },
            legend: {
                enabled: true,
            },
            series: [
                {
                name: "Front Price (Unadjusted)",
                data: data[symbol].map((i) => [ Date.parse(i['date']), i['PX_LAST'] ]),
                color: "black",
                yAxis: 0,
                },
                {
                name: "Roll Adjusted Price",
                data: data[symbol].map((i) => [ Date.parse(i['date']), i['PX_LAST_D'] ]),
                color: "blue",
                yAxis: 0,
                },
                {
                name: "Adjustment Diff",
                data: data[symbol].map((i) => [ Date.parse(i['date']), i['px_roll'] ]),
                color: "red",
                yAxis: 1,
                },
            ]
        }
        console.log(res)
        return res
    }


    return (
        <div style={{margin: "20px"}}>
            <h1>Contract Roll History</h1>
            <div style={{width: "200px"}}>
                <ImbueSelect
                    title="sector"
                    options={sectorOptions}
                    value={sector}
                    onChange={(e) => {
                        if (!loading) {
                            setSector(e); 
                        }   
                    }}
                />
            </div>
            { loading && 
                <div>{LoadingPage()}</div>
            }
            { data && 
                <div style={{margin: "auto", width: "75%"}}>
                    <RollsTable data={data['highlights']} onChartSymbolChange={handleChartSymbolChange} />
                </div>
            }
            { chartSymbol &&
                <div>
                    <HighStockWrapper config={chartConfig(chartSymbol)}/>
                </div>
            }
        </div>
    );
}

export default RollsHistory;