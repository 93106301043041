import React, { Component } from 'react'
import GameResultViewer from '../ImbueAILab_GameResultVisual/GameResultViewer';
import styled from 'styled-components';
import ImbueSearchBox from '../../../components/input/ImbueSearchBox';

const Layout = styled.div`

    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80px calc(100% - 80px);

    .grid_header {
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .grid_header > div{
        width: 100%;
    }

    .grid_body {
        margin: 0 10px 10px 10px;
        overflow: hidden;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    .header {
        font-family: "roboto";
        padding: 10px;
        border-bottom: 1px solid #ccc;
        background: #fff;
    }

`;

export default class imbueAILab_ResultViewer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            docid: ""
        }
        this.onChangeDocId = this.onChangeDocId.bind(this)
    }

    componentDidMount() {
        const { query } = this.props
        if ("docid" in query) {
            this.setState({ docid: query.docid })
        }
    }

    onChangeDocId(newDocId) {
        this.setState({ docid: newDocId })
    }

    render() {
        const { docid } = this.state

        const afterKeyPress = (event) => {
            if (event.key === 'Enter') {
                const docid = event.target.value.trim()
                this.onChangeDocId(docid)
            }
        }

        return (
            <Layout>
                <div className="grid_header">
                    <ImbueSearchBox
                        key={docid + "--input"}
                        placeholder="docid ..."
                        defaultValue={docid}
                        afterKeyPress={afterKeyPress}
                    />
                </div>
                <div className="grid_body">
                    <GameResultViewer key={docid} docid={docid} />
                </div>
            </Layout>
        )
    }
}
