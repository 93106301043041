import React, { Component } from "react";
import { api_positioning_analysis } from "../../helpers/API";
import { highchartSimpleConfig, COLORMAP } from "../../config";
import ImbueTabs from "../tabs/ImbueTabs";
import HighChartWrapper from "./HighChartWrapper";
import HighStockWrapper from "./HighStockWrapper";
import { BarLoader } from "react-spinners";
import styled from "styled-components";

const Layout = styled.div`
    .meta {
        color: #888;
        font-size: 1rem;
        overflow: auto;
    }

    .meta .item {
        padding-left: 5px;
        border-left: 2px solid #888;
    }
`;

// ---+--- Helpers ---+---

function generatePaChartConfig(data, viewmode) {
    switch (viewmode) {
        case 0: {
            const series = [
                {
                    name: "Adjusted Price R",
                    type: "line",
                    color: COLORMAP.blue_o,
                    yAxis: 0,
                    data: data.map((ele) => [Date.parse(ele.date), ele.px_last_r]),
                    lineWidth: 1,
                },
                {
                    name: "Adjusted Price D",
                    type: "line",
                    color: COLORMAP.red_o,
                    yAxis: 0,
                    data: data.map((ele) => [Date.parse(ele.date), ele.px_last_d]),
                    lineWidth: 1,
                },
                {
                    name: "Dollar Notional Positioning F+O",
                    type: "line",
                    color: COLORMAP.main,
                    yAxis: 1,
                    data: data.map((ele) => [Date.parse(ele.date), ele.dollar_notional_fo]),
                },
                {
                    name: "Dollar Notional Positioning F",
                    type: "line",
                    color: COLORMAP.orange,
                    yAxis: 1,
                    data: data.map((ele) => [Date.parse(ele.date), ele.dollar_notional_f]),
                },
            ];
            return {
                ...highchartSimpleConfig,
                chart: {
                    ...highchartSimpleConfig.chart,
                    zoomType: "x",
                },
                tooltip: { shared: 1 },
                xAxis: { type: "datetime" },
                legend: { enabled: true },
                yAxis: [
                    { title: { text: "Adjusted Price" }, opposite: false },
                    { title: { text: "Dollar Notional Positioning" } },
                ],
                series: series,
            };
        }
        default:
            return {};
    }
}

export default class PositioningAnalysisChartExtra extends Component {
    state = {
        meta: {},
        data: [],
        viewmode: 0,
        isLoading: false,
        isError: false,
    };

    componentDidMount() {
        this.getData();
    }

    onChangeView = (viewmode) => {
        this.setState({ viewmode });
    };

    getData = () => {
        this.setState({ isLoading: true }, () => {
            const comdty = this.props.comdty || "W 1 Comdty";
            const datatype = this.props.datatype || 1;
            const charttype = this.props.charttype || 1;
            const datefrom = this.props.date || "2000-01-01";
            api_positioning_analysis({ comdty, datatype, charttype, datefrom })
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    this.setState({
                        ...data,
                        isLoading: false,
                    });
                })
                .catch((e) => {
                    this.setState({ isError: true, isLoading: false });
                });
        });
    };

    registerChart = (chartObject) => {
        this.setState({
            chartObject,
        });
    };

    render() {
        // ::props

        // ::states
        const viewmode = this.state.viewmode;
        const data = this.state.data;
        const meta = this.state.meta;

        let viewmodeOptions = [
            { label: "historical", value: 0 },
            { label: "meta", value: 1 },
        ];

        const chartConfig = generatePaChartConfig(data, viewmode);
        const chartid = JSON.stringify(this.props) + viewmode;

        const charttitle = {
            5: "Managed Money",
            6: "Producer",
            7: "Swap Dealers",
            8: "Other Reportables",
        }[this.props.datatype];

        const mainRender = () => {
            if (viewmode === 1) {
                return (
                    <div className="meta">
                        {Object.keys(this.state.meta).map((k) => {
                            return (
                                <div className="item">
                                    <b>{k}</b>: {this.state.meta[k]}
                                </div>
                            );
                        })}
                    </div>
                );
            } else {
                return <HighStockWrapper key={chartid} config={chartConfig} />;
            }
        };

        return (
            <Layout>
                <h3>{charttitle}</h3>
                <ImbueTabs options={viewmodeOptions} selectedValue={this.state.viewmode} onClick={this.onChangeView} />
                <div>
                    {this.state.isLoading ? (
                        <BarLoader width={100} widthUnit="%" height={3} color={COLORMAP.main} />
                    ) : (
                        ""
                    )}
                </div>
                <div
                    className="highchartResponsiveWrapper"
                    style={{ margin: "20px 0", width: this.props.width, height: this.props.height || "30vh" }}
                >
                    {mainRender()}
                </div>
            </Layout>
        );
    }
}
