import React, { Component } from 'react'
import styled from 'styled-components';
import { GLOBALSTYLED } from '../../config';
import S3PdfViewer from "../../components/pdfViewer/S3PdfViewer";
import ImbueTabs from "../../components/tabs/ImbueTabs";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)``;


const documentOptions = [
    { label: "Cash", value: "cash_research_chartpack.pdf" },
    { label: "Central Bank", value: "central_bank_research_chartpack.pdf" },
    { label: "China", value: "china_research_chartpack.pdf" },
    { label: "Cocoa Hogs Cattle", value: "cocoa_hogs_cattle_research_chartpack.pdf"},
    { label: "Copper and Iron", value: "copper_iron_research_chartpack.pdf"},
    { label: "Crypto", value: "crypto_research_chartpack.pdf"},
    { label: "Delta O/I", value: "delta_oi_research_chartpack.pdf"},
    { label: "DM", value: "dm_research_chartpack.pdf"},
    { label: "Economic Suprise", value: "economic_suprise_research_chartpack.pdf"},
    { label: "EM", value: "em_research_chartpack.pdf"},
	{ label: "Energy", value: "energy_research_chartpack.pdf"},
	{ label: "Equities", value: "equities_research_chartpack.pdf"},
	{ label: "ETF Flows", value: "etfs_research_chartpack.pdf"},
	{ label: "Grains", value: "grain_research_chartpack.pdf"},
	{ label: "Livestock", value: "livestock_research_chartpack.pdf"},
	{ label: "Margins", value: "margins_research_chartpack.pdf"},
	{ label: "Misc", value: "misc_research_chartpack.pdf"},
	{ label: "Nat Gas", value: "nat_gas_research_chartpack.pdf"},
	{ label: "Oil", value: "oil_research_chartpack.pdf"},
	{ label: "Oil Seed", value: "oilseed_research_chartpack.pdf"},
	{ label: "Physical", value: "physical_research_chartpack.pdf"},
	{ label: "Relative Value", value: "relative_value_research_chartpack.pdf"},
	{ label: "Softs", value: "softs_research_chartpack.pdf"},
	{ label: "Treasuries", value: "treasuries_research_chartpack.pdf"},
	{ label: "US Energy", value: "us_energy_research_chartpack.pdf"},
	{ label: "US Export", value: "us_export_research_chartpack.pdf"},
	{ label: "US Metal", value: "us_metal_research_chartpack.pdf"}
]

export default class ResearchChartpackViewer extends Component {

    state = {
        selectedItem: documentOptions[0]
    }

    render() {
        return (
            <Layout>
                <ImbueTabs options={documentOptions} selectedValue={this.state.selectedItem.value} onClickOption={(opt) => this.setState({selectedItem: opt})} />
                <h1>{this.state.selectedItem.label}</h1>
                <S3PdfViewer key={this.state.selectedItem.value} bucketname="imbuereportbucket" id={this.state.selectedItem.value} />
            </Layout>
        )
    }
}
