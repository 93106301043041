import React, { Component } from "react";
import { api_intraday_movement } from "../../helpers/API";
import HighchartWrapper from "../../components/chart/HighChartWrapper";
import { highchartBasicConfig, COLORMAP, GLOBALFINANCE } from "../../config";
import styled from "styled-components";
import { getMargin } from "../../helpers/Utils";
import ImbueTabs from "../../components/tabs/ImbueTabs";
import ImbueSelect from "../../components/input/ImbueSelect";

const Layout = styled.div`
    padding: 30px;
`;

export const heatmapConfig = (paras) => {
    let data = [];
    for (let i = 0; i < paras.data.length; i++) {
        for (let j = 0; j < paras.data[i].length; j++) {
            data.push([i, j, paras.data[i][j]]);
        }
    }
    const hasNegative = data.map((ele) => ele[2]).some((ele) => ele < 0);
    const dataMargin = getMargin(data.map((ele) => ele[2]));

    let colorAxis = null;
    if (hasNegative) {
        colorAxis = {
            min: -1 * dataMargin,
            max: dataMargin,
            stops: [
                [0, "#a10000"],
                [0.3, "#ffcfcf"],
                [0.5, "#FFFFFF"],
                [0.7, "#d6ffd6"],
                [1, "#005200"],
            ],
        };
    } else {
        colorAxis = {
            min: 0,
            max: dataMargin,
            stops: [
                [0, "#111"],
                [0.5, "rgb(84, 90, 116)"],
                [1, "#fff"],
            ],
        };
    }

    return {
        title: highchartBasicConfig.title,
        credits: highchartBasicConfig.credits,
        chart: {
            type: "heatmap",
            backgroundColor: "transparent",
            style: {
                fontFamily: "roboto",
            },
            height: 400 + data.length * 0.8,
            animation: false,
        },
        tooltip: {
            useHtml: true,
            outside: true,
            formatter: function (e) {
                let xLabel = this.series.xAxis.categories[this.point.x];
                let yLabel = this.series.yAxis.categories[this.point.y];
                let value = this.point.value;
                let textColor = value >= 0 ? COLORMAP.green : COLORMAP.red;
                const HTML = `
                <div style="font-family: Lato">
                    <span style="color: #444;">${xLabel}</span><br>
                    <span style="color: ${textColor}; font-size: 1.1rem;">${yLabel}: ${value.toFixed(
                    6
                )}</span>
                </div>
                `;
                return HTML;
            },
        },
        xAxis: {
            categories: paras.x,
        },
        yAxis: {
            categories: paras.y,
            title: null,
            reversed: true,
        },
        colorAxis: colorAxis,
        legend: {
            align: "right",
            layout: "vertical",
            margin: 0,
            verticalAlign: "top",
            y: 25,
            symbolHeight: 280,
        },
        series: [
            {
                name: "Correlation",
                borderWidth: 0,
                borderColor: "#eee",
                data: data,
            },
        ],
    };
};

export default class IntradayMovement extends Component {
    state = {
        data: {
            meta: {},
        },
        query: {
            root: "CL",
            symbol: "Z0",
            minutes: "15",
            datefrom: "2020-01-01",
            aggfunc: "sum",
        },
        isLoading: 0,
        isInit: 0,
        datatype: 0,
    };

    componentDidMount() {
        this.onUpdateData();
    }

    onUpdateData = () => {
        this.setState({ isLoading: 1 }, () => {
            api_intraday_movement(this.state.query).then((res) => {
                const ret_config = heatmapConfig(res.data.ret_df);
                const abs_config = heatmapConfig(res.data.abs_ret_df);
                const vol_config = heatmapConfig(res.data.vol_df);
                this.setState({
                    ret_config,
                    abs_config,
                    vol_config,
                    data: res.data,
                    isLoading: 0,
                    isInit: 1,
                });
            });
        });
    };

    onChangeQuery = (key, val) => {
        let current = this.state.query;
        current[key] = val;
        this.setState({
            query: current,
        });
    };

    onEnter = (e) => {
        if (e.key === "Enter") {
            this.onUpdateData();
        }
    };

    render() {
        const chartconfig = {
            0: this.state.ret_config,
            1: this.state.abs_config,
            2: this.state.vol_config,
        }[this.state.datatype];

        return (
            <Layout>
                <div className="ImbueSelectsWrapper">
                    <ImbueSelect
                        title="Root"
                        options={Object.keys(GLOBALFINANCE.ROOTMAP).map((ele) => ({
                            label: ele,
                            value: ele,
                        }))}
                        value={{
                            label: this.state.query.root,
                            value: this.state.query.root,
                        }}
                        onChange={(e) => {
                            this.onChangeQuery("root", e.value);
                        }}
                    />
                    
                    {[
                        // ["Root", "root"],
                        ["Symbol", "symbol"],
                        ["Minutes", "minutes"],
                        ["Datefrom", "datefrom"],
                    ].map((ele, idx) => {
                        return (
                            <div className="ImbueSelectItem" key={idx}>
                                <div className="label">{ele[0]}:</div>
                                <input
                                    className="ImbueInput"
                                    type="text"
                                    value={this.state.query[ele[1]]}
                                    onChange={(e) =>
                                        this.onChangeQuery(
                                            ele[1],
                                            e.target.value
                                        )
                                    }
                                    onKeyPress={this.onEnter}
                                />
                            </div>
                        );
                    })}
                    <ImbueSelect
                        title="AggFunc"
                        options={["sum", "mean"].map((ele) => ({
                            label: ele,
                            value: ele,
                        }))}
                        value={{
                            label: this.state.query.aggfunc,
                            value: this.state.query.aggfunc,
                        }}
                        onChange={(e) => {
                            this.onChangeQuery("aggfunc", e.value);
                        }}
                    />

                    <div
                        className="CircleBtn CircleBtnLg"
                        onClick={() => this.onUpdateData()}
                    >
                        <i
                            style={{ marginLeft: "3px" }}
                            className="fas fa-play"
                        ></i>
                    </div>
                </div>
                <div>
                    {this.state.isLoading ? (
                        <div className="LoadingText">Loading...</div>
                    ) : this.state.isInit ? (
                        <div>
                            <h3>
                                {this.state.data.meta.from} ~{" "}
                                {this.state.data.meta.to}
                            </h3>
                            <ImbueTabs
                                options={[
                                    { label: "Directional Returns", value: 0 },
                                    { label: "Absolute Returns", value: 1 },
                                    { label: "Volume", value: 2 },
                                ]}
                                selectedValue={this.state.datatype}
                                onClick={(val) =>
                                    this.setState({ datatype: val })
                                }
                            />
                            <HighchartWrapper config={chartconfig} />
                        </div>
                    ) : (
                        <div className="LoadingText">Press Enter to Query</div>
                    )}
                </div>
            </Layout>
        );
    }
}
