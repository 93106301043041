import React from 'react';


const percentCols = ['roll_pct'];
const truncate2Cols = ['px_roll',
    'total', '2000-2010', '2010-2020', '2024',
    '2020', '2021', '2022', '2023', '6mo', '3mo', '1mo', 
];


const RollsTableBody = ({ tableData, columns, onChartSymbolChange }) => {
    // let prevRow = null;
    return (
        <tbody>
            {tableData.map((data) => {
                let rowBorder = {};

                return (
                    <tr key={data.symbol} onClick={() => {onChartSymbolChange(data.symbol)}}>
                        {columns.map(({ accessor }) => {
                            let curVal = data[accessor];
                            
                            let cellColor = "";
                            let cellTextDec = "none";
                            let title = "";

                            if (curVal !== null && truncate2Cols.includes(accessor)) {
                                curVal = curVal.toFixed(2);
                            }
                            else if (curVal !== null && percentCols.includes(accessor)) {
                                curVal = (curVal * 100).toFixed(1) + '%';
                            }

                            const cellStyle = { background: cellColor, textDecoration: cellTextDec, borderTop: rowBorder, }
                            
                            const tData = data[accessor] ? curVal : "—";
                            return (
                                <td key={accessor} style={cellStyle} title={title}>
                                    {tData}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

export default RollsTableBody;