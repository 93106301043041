import React, { Component } from "react";
import styled from "styled-components";
import { api_interpretability } from "../../helpers/API";
import ImbueSelect from "../../components/input/ImbueSelect";
import LimeVisual from "./Interpretability/LimeVisual";

const Layout = styled.div`
    padding: 30px;

    .chart_grid {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-gap: 10px;
    }
`;

class Interpretability extends Component {
    state = {
        overview: [],
        status: "live",
        selectedSecurity: "",
    };

    componentDidMount() {
        this.getOverview();
    }

    getOverview = () => {
        api_interpretability({ query: "overview" }).then((res) => {
            this.setState({
                overview: res.data.data,
            });
        });
    };

    render() {
        const overview = this.state.overview.filter((ele) => ele.status === this.state.status);
        const selectedSecurity = this.state.selectedSecurity;

        const securityOptions = overview
            .map((ele) => ele.ticker)
            .reduce((a, b) => {
                return a.indexOf(b) < 1 ? [...a, b] : a;
            }, [])
            .map((ele) => ({ label: ele, value: ele }));
        const selected = overview.filter((ele) => ele.ticker === selectedSecurity);

        return (
            <Layout>
                <div className="ImbueSelectsWrapper">
                    <ImbueSelect title="Security" options={securityOptions} onChange={(e) => this.setState({ selectedSecurity: e.value })} />
                </div>
                <div>
                    <h3>{this.state.selectedSecurity}</h3>
                    <div className="chart_grid">
                        {selected.map((ele) => {
                            const limevisualid = ele.strategy_name + "." + ele.ticker;
                            return <LimeVisual key={ele.strategy_name} strategyname={limevisualid} />;
                        })}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Interpretability;
