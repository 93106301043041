import React, { Component } from "react";
import { api_factor_meta_search } from "../../helpers/API";
import MultiInput from "../../components/input/MultiInput";
import styled from "styled-components";
import { COLORMAP } from "../../config";
import FactorCard from "./components/FactorCard";

const Layout = styled.div`
    overflow: auto;
    font-family: var(--font-main);
    padding: 20px;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 300px calc(100% - 300px);
    grid-template-rows: 100%;

    .sidebar {
        max-height: 100%;
        overflow: auto;
        padding: 10px;
        box-sizing: border-box;
    }

    .content {
        max-height: 100%;
        overflow: auto;
    }

    .infocard_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }

    .infocard {
        background: #fff;
        padding: 10px 20px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    .title {
        font-size: 1.8rem;
        margin: 2px 0;
        color: var(--color-main);
    }

    .subtitle {
        font-size: 1.3rem;
        margin: 4px 0;
        color: #555;
    }

    .row {
        font-family: roboto;
        font-size: 1.3rem;
        /* border: 1px solid #ccc; */
        background: #fff;
        display: flex;
        align-items: center;
        padding: 6px;
        color: #333;
        margin: 5px 0;
    }
`;

export default class FactorSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kw: "",
            factorlist: [],
            factor: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.onEnter = this.onEnter.bind(this);
        this.search = this.search.bind(this);
        this.onSelectFactor = this.onSelectFactor.bind(this);
    }

    componentDidMount() {}

    search() {
        const keyword = this.state.kw;
        api_factor_meta_search(keyword).then((res) => {
            if (res.data.status) {
                this.setState({
                    factorlist: res.data.message.map((ele) => ele.factorname),
                });
            }
        });
    }

    onEnter(e) {
        if (e.key === "Enter") {
            this.search();
        }
    }

    handleChange(e) {
        this.setState({
            kw: e.target.value,
        });
    }

    onSelectFactor(factor) {
        this.setState({ factor });
    }

    render() {
        const factor = this.state.factor;
        const factorlist = this.state.factorlist;

        return (
            <div style={{ height: "100%", display: "grid", gridTemplateRows: "70px calc(100% - 70px)" }}>
                <MultiInput fields={[{ label: "Keyword", value: this.state.kw }]} handleChange={this.handleChange} keyPress={this.onEnter} />
                <Layout>
                    <div className="sidebar">
                        {factorlist.map((ele, idx) => {
                            const className = ele === factor ? "SideBarBtn SideBarBtnSelected" : "SideBarBtn";
                            return (
                                <div
                                    key={idx}
                                    className={className}
                                    onClick={() => {
                                        this.onSelectFactor(ele);
                                    }}
                                >
                                    {ele}
                                </div>
                            );
                        })}
                    </div>
                    <div className="content">
                        {factor ? <FactorCard key={factor} factor={factor} /> : <div className="LoadingText">Select a factor.</div>}
                    </div>
                </Layout>
            </div>
        );
    }
}
