import React, { Component } from "react";
import styled from "styled-components";
import { GLOBALSTYLED } from "../../config";

const Layout = styled(GLOBALSTYLED.COMMONLAYOUT)`
    .link {
        margin: 10px 0;
        a {
            color: #555;
        }
        a:hover {
            color: #000;
            text-decoration: underline;
        }
    }
`;

export default class GlossaryAndLinks extends Component {
    render() {
        return (
            <Layout>
                <h1> Machine Learning Glossary </h1>
                {[
                    "https://christophm.github.io/interpretable-ml-book/terminology.html",
                    "https://ml-cheatsheet.readthedocs.io/en/latest/glossary.html",
                    "https://developers.google.com/machine-learning/glossary",
                    "https://www.analyticsvidhya.com/glossary-of-common-statistics-and-machine-learning-terms/",
                    "https://machinelearning.wtf/",
                ].map((ele, i) => (
                    <div key={i} className="link">
                        <a target="_blank" href={ele}>
                            {ele}
                        </a>
                    </div>
                ))}
                <h1>Marco Lopez de Prado Research</h1>
                <div className="link">
                    <a target="_blank" href={"http://www.quantresearch.org/Lectures.htm"}>
                        {"http://www.quantresearch.org/Lectures.htm"}
                    </a>
                </div>
            </Layout>
        );
    }
}
