import React, { useState, useEffect } from "react";
import { api_get_latest_portfolio_trades } from "../../helpers/API";
import "./style.css";


// const PORTFOLIO_1_TICKERS = ['6A', '6J', 'GC', 'HG', 'ZW', 'ZL', 'ZB', 'ZF', 'ES', 'NQ'];
// const PORTFOLIO_2_TICKERS = PORTFOLIO_1_TICKERS.concat(['6C', 'ZC', 'SI', 'ZN', 'YM']);
const PORTFOLIO_TICKERS = ['6J', '6A', 'ES', 'NQ', 'GC', 'HG', 'ZB', 'ZN', 'ZW', 'ZL', 'ZC']

// show the latest 5 trades for the current strategy, the returns table only shows trades that have fully settled
function LatestTrades(props) {

    const [latestTrades, setLatestTrades] = useState();
    const [positionSizes, setPositionSizes] = useState();

    if (!latestTrades) {
        const api_params = {"strategy": props.strategy, "portfolio": props.portfolio, "tickers": props.tickers}
        api_get_latest_portfolio_trades(api_params).then(res => {
            console.log(res.data.trades);
            // console.log(res.data.pos)
            console.table(res.data.sizes)

            setLatestTrades(res.data.trades);
            setPositionSizes(res.data.sizes);
        }).catch(e => {
            console.error(e);
        })
    }

    // handle resetting bools when components unmounts
    useEffect(() => {
        return () => {
            setLatestTrades();
        };
    }, []);

    if (latestTrades && positionSizes) {
        let listItems = []
        // console.log(latestTrades)
        latestTrades.forEach((trade) => {
            const curPos = trade['long_short'];
            const prevPos = trade['prev_long_short'];
            const curSize = positionSizes[trade['ticker']]['size'];
            // gray = FLAT, green = LONG, red = SHORT
            const curPosColor = curPos === 0 ? 'gray' : (curPos > 0 ? 'green' : 'red');
            const prevPosColor = prevPos === 0 ? 'gray' : (prevPos > 0 ? 'green' : 'red');
            const isNewPos = trade['long_short_changed'];
            const newPosStyle = {width: "300px", backgroundColor: "#FFFF00", fontWeight: "bold"}; // highlight
            const sizeColor = Date.parse(positionSizes[trade['ticker']]['datetime']) < Date.parse(trade['datetime']) ? "red" : "black"
            listItems.push(<li key={props.strategy+trade['ticker']} >
                <div style={isNewPos ? newPosStyle : {}}>
                    <div>
                        <div className="row">
                            <p style={{width: "40px"}}>
                                {trade['ticker']}:
                            </p>
                            <p style={{color: curPosColor, width: "30px"}}>
                                {curPos}
                            </p>
                            <p style={{width: "30px", color: sizeColor}}>
                                {`[${curSize}]`}
                            </p>
                            <p> ({trade['datetime']}) </p>
                            { isNewPos && 
                            <div className="row">
                                <p>, prev: </p>
                                <p style={{color: prevPosColor}}>
                                    {`${prevPos}`}
                                </p>
                            </div>
                            }                        
                        </div>
                    </div>
                </div>
            </li>)
        })

        return (
            <div style={{marginTop: "40px"}}>
                <h3 style={{marginBottom: "-15px"}}>{`${props.portfolio} | ${props.strategy} :`}</h3>
                <ul style={{marginLeft: "-25px"}}>{listItems}</ul>
            </div>
        );
    } else {
        return (<p>loading trades...</p>)
    }
    
}


export default function LatestPortfolioTrades() {

    return (
        <div style={{marginLeft: "20px"}}>
            <h1>Current Positions:</h1>
            <p style={{marginTop: "-15px", marginLeft: "10px", marginBottom: "-10px"}}>
                ( positions on date=x are for session=x+1 )
            </p>
            <div className="row">
                <LatestTrades strategy={"XGB_2"} portfolio={"hyo"} tickers={PORTFOLIO_TICKERS}/>
                {/* <div style={{margin: "20px"}}></div> */}
                {/* <LatestTrades strategy={"XGB_2"} portfolio={"hyo2"} tickers={PORTFOLIO_2_TICKERS}/> */}
            </div>
        </div>
    )
}