import { COLORMAP, highchartBasicConfig } from "../config";
import moment from "moment";
import { toPercentage, groupBy, getAvg, getMargin, toUnique, getFixed } from "./Utils";

// ============================
// --+-- Calculate Change --+--
// ============================

export function calculateChange(data) {
    // data: [["2010-01-01", 100] ... ]

    const invalid_result = {
        price: "NaN",
        trend: "NaN",
        date: "As of NaN",
        change: "NaN",
        change_pct: "NaN%",
    };

    if (data.length < 2) {
        return invalid_result;
    }
    try {
        data = data.filter((ele) => {
            return ele[1] !== null;
        });
        let date = data.map((ele) => ele[0]);
        let price = data.map((ele) => ele[1]);
        let last_date = date.slice().reverse()[0];
        let last_price = price.slice().reverse()[0];
        let last_2nd_date = date.slice().reverse()[1];
        let last_2nd_price = price.slice().reverse()[1];
        let price_change = last_price - last_2nd_price;
        let price_change_pct = ((last_price - last_2nd_price) / Math.abs(last_2nd_price)) * 100;
        let change = {
            price: last_price !== null ? last_price.toFixed(2) : 0,
            trend: price_change > 0 ? "up" : price_change < 0 ? "down" : "still",
            date: "As of " + last_date + " (" + moment(last_date).fromNow() + ")",
            change: (price_change > 0 ? "+" : "") + price_change.toFixed(2),
            change_pct: (price_change > 0 ? "+" : "") + price_change_pct.toFixed(2) + "%",
        };
        return change;
    } catch (e) {
        return invalid_result;
    }
}

// ============================================
// --+-- Generate TickerChangeCard Config --+--
// ============================================
/**
 *
 * @param {object} timeseries | e,g, [["2000-01-01", 123]]
 */
export function generateTickerChangeCardConfig(timeseries) {
    let config = [];
    // if (timeseries) {
    //     if (timeseries.length > 2) {
    //         let lst_three_px_last = timeseries.slice(0, 3);
    //         let latest_diff = lst_three_px_last[0][default_field] - lst_three_px_last[1][default_field];
    //         let latest_diff_perc = ((lst_three_px_last[0][default_field] - lst_three_px_last[1][default_field]) / lst_three_px_last[1][default_field]) * 100;
    //         let prior_diff = lst_three_px_last[1][default_field] - lst_three_px_last[2][default_field];
    //         let prior_diff_perc = ((lst_three_px_last[1][default_field] - lst_three_px_last[2][default_field]) / lst_three_px_last[2][default_field]) * 100;
    //         config = [
    //             {
    //                 title: "Latest Release",
    //                 change: {
    //                     value: lst_three_px_last[0][default_field],
    //                     valueSuffix: lst_three_px_last[0]["BN_SURVEY_MEDIAN"] ? `surv ${lst_three_px_last[0]["BN_SURVEY_MEDIAN"]}` : "",
    //                     diff: latest_diff.toFixed(2),
    //                     perc: latest_diff_perc.toFixed(2),
    //                     label: "Actual"
    //                 },
    //                 info: [
    //                     // { label: "Survey Median", value: lst_three_px_last[0]['BN_SURVEY_MEDIAN'], large: true },
    //                     { label: "Release Date", value: lst_three_px_last[0]["ECO_RELEASE_DT"] }
    //                     // { label: "Release Time", value: lst_three_px_last[0]['ECO_RELEASE_TIME'] }
    //                 ],
    //                 style: {}
    //             },
    //             {
    //                 title: "Prior Release",
    //                 change: {
    //                     value: lst_three_px_last[1][default_field],
    //                     valueSuffix: lst_three_px_last[1]["BN_SURVEY_MEDIAN"] ? `surv ${lst_three_px_last[1]["BN_SURVEY_MEDIAN"]}` : "",
    //                     diff: prior_diff.toFixed(2),
    //                     perc: prior_diff_perc.toFixed(2),
    //                     label: "Actual"
    //                 },
    //                 info: [
    //                     // { label: "Survey Median", value: lst_three_px_last[1]['BN_SURVEY_MEDIAN'], large: true },
    //                     { label: "Release Date", value: lst_three_px_last[1]["ECO_RELEASE_DT"] }
    //                     // { label: "Release Time", value: lst_three_px_last[1]['ECO_RELEASE_TIME'] }
    //                 ],
    //                 style: {
    //                     opacity: 0.6
    //                 }
    //             }
    //         ];
    //     }else if (timeseries.length > 1) {
    //         let lst_two_px_last = timeseries.slice(0, 2);
    //         let latest_diff = lst_three_px_last[0][default_field] - lst_three_px_last[1][default_field];
    //         let latest_diff_perc = ((lst_three_px_last[0][default_field] - lst_three_px_last[1][default_field]) / lst_three_px_last[1][default_field]) * 100;
    //         let prior_diff = lst_three_px_last[1][default_field] - lst_three_px_last[2][default_field];
    //         let prior_diff_perc = ((lst_three_px_last[1][default_field] - lst_three_px_last[2][default_field]) / lst_three_px_last[2][default_field]) * 100;
    //         config = [
    //             {
    //                 title: "Latest Release",
    //                 change: {
    //                     value: lst_three_px_last[0][default_field],
    //                     valueSuffix: lst_three_px_last[0]["BN_SURVEY_MEDIAN"] ? `surv ${lst_three_px_last[0]["BN_SURVEY_MEDIAN"]}` : "",
    //                     diff: latest_diff.toFixed(2),
    //                     perc: latest_diff_perc.toFixed(2),
    //                     label: "Actual"
    //                 },
    //                 info: [
    //                     // { label: "Survey Median", value: lst_three_px_last[0]['BN_SURVEY_MEDIAN'], large: true },
    //                     { label: "Release Date", value: lst_three_px_last[0]["ECO_RELEASE_DT"] }
    //                     // { label: "Release Time", value: lst_three_px_last[0]['ECO_RELEASE_TIME'] }
    //                 ],
    //                 style: {}
    //             },
    //             {
    //                 title: "Prior Release",
    //                 change: {
    //                     value: lst_three_px_last[1][default_field],
    //                     valueSuffix: lst_three_px_last[1]["BN_SURVEY_MEDIAN"] ? `surv ${lst_three_px_last[1]["BN_SURVEY_MEDIAN"]}` : "",
    //                     diff: prior_diff.toFixed(2),
    //                     perc: prior_diff_perc.toFixed(2),
    //                     label: "Actual"
    //                 },
    //                 info: [
    //                     // { label: "Survey Median", value: lst_three_px_last[1]['BN_SURVEY_MEDIAN'], large: true },
    //                     { label: "Release Date", value: lst_three_px_last[1]["ECO_RELEASE_DT"] }
    //                     // { label: "Release Time", value: lst_three_px_last[1]['ECO_RELEASE_TIME'] }
    //                 ],
    //                 style: {
    //                     opacity: 0.6
    //                 }
    //             }
    //         ];
    //     }
    // }
    // return config;
}

// ============================
// --+--React-table Chart --+--
// ============================

export function generateTable(data) {
    // data => {dp1: [["2010-01-01", 100] ... ], dp2: [["2010-01-01", 442] ... ]}

    // Standardize key name, e.g. avoid "30DAY_IMPVOL_100.0%MNY_DF"
    let newData = {};
    for (let key of Object.keys(data)) {
        const newKey = standardiseTableColumnName(key);
        newData[newKey] = data[key];
    }
    data = newData;

    let key_value = {};
    for (let key of Object.keys(data)) {
        key_value["DATE"] = data[key].map((ele) => {
            return ele[0];
        });
        key_value[key.toUpperCase()] = data[key].map((ele) => {
            return ele[1];
        });
    }
    let table_data = [];
    key_value["DATE"].map((element, index) => {
        let one_row = {};
        for (let key_ of Object.keys(key_value)) {
            one_row[key_] = key_value[key_][index];
        }
        table_data.push(one_row);
        return null;
    });
    let columns = Object.keys(key_value).map((key) => {
        return { Header: key, accessor: key };
    });
    return {
        data: table_data.reverse(),
        columns: columns,
    };
}

// Prepare data from react-table from list of dictionary
export function generateReactTableFromDict(data) {
    // data => [{label1: xxx, label2: yyyy}, ...]

    if (data.length > 0) {
        const columns = Object.keys(data[0]).map((key) => {
            return { Header: key, accessor: key };
        });
        return {
            data: data,
            columns: columns,
        };
    } else {
        return { data: [], columns: [] };
    }
}

export function standardiseTableColumnName(column) {
    column = column.split(".").join("_");
    return column;
}

// =========================
// --+--Seasonal Chart --+--
// =========================

/**
 *
 * @param {object} data
 * @param {bool} rebase
 * @param {int} startmonth
 * @param {bool} calCum - if calculate cumulative
 *
 */
export function generateSeasonal(data, rebase, startmonth, calCum) {
    // Generate Highchart seasonal series data
    // Data format complys to Highchart Standard
    // Exp:
    // input: [["2010-01-01", 100] ... ]
    // output: [{name: "2010", data: [...]}]

    const Threshold = 8;

    if (!startmonth) {
        startmonth = 1;
    }

    if (!data || data.length < 1) {
        return {};
    }

    let seasonal = {};

    data = data.map((ele) => {
        let date = new Date(ele[0]);
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let md = (date.getMonth() + 1).toLocaleString() + "-" + date.getDate().toLocaleString();
        let group = "Since " + startmonth + "/" + (year + (month - startmonth >= 0 ? 0 : -1));
        if (!(group in seasonal)) {
            seasonal[group] = [];
        }
        seasonal[group].push([Date.parse((month < startmonth ? "2001" : "2000") + "-" + md), ele[1]]);
    });

    seasonal = Object.keys(seasonal).map((key) => {
        return { year: key, data: seasonal[key] };
    });
    // -- Rebasing
    if (rebase) {
        seasonal = seasonal.map((ele) => ({
            ...ele,
            data: ele.data.map((row) => [row[0], row[1] - ele.data[0][1]]),
        }));
    }

    if (seasonal.length > Threshold) {
        seasonal = seasonal.slice(seasonal.length - Threshold, seasonal.length);
    }

    if (calCum === true) {
        for (let index = 0; index < seasonal.length; index++) {
            const data = seasonal[index]["data"];
            let cum = 0;
            for (let seq = 0; seq < data.length; seq++) {
                cum += data[seq][1];
                data[seq][1] = cum;
            }
        }
    }

    let series = seasonal.map((item, index) => {
        let isCurrentYear = index === Object.keys(seasonal).length - 1;
        if (isCurrentYear) {
            return {
                type: "area",
                name: item.year,
                data: item.data,
                fillOpacity: 0.1,
                lineWidth: 3,
                dashStyle: "Solid",
                color: COLORMAP.green,
                threshold: null,
            };
        } else {
            return {
                type: "line",
                name: item.year,
                data: item.data,
                fillOpacity: 0.1,
                lineWidth: 1.5,
                dashStyle: "ShortDash",
                color: undefined,
            };
        }
    });
    return series;
}

// ==========================================
// --+-- Generate Data for Trades Chart --+--
// ==========================================

const EXCLUDEDFIELDS = ["ECO_RELEASE_DT", "ECO_RELEASE_TIME", "ECO_FUTURE_RELEASE_DATE"];

export function generateLineSeries(data) {
    let ts_data = Object.keys(data)
        .map((key, idx) => {
            const isEmpty = data[key].filter((ele) => ele[1]).length === 0;
            const isExcluded = EXCLUDEDFIELDS.indexOf(key) >= 0;
            const hasString = data[key].map((ele) => typeof ele[1] === "string").indexOf(true) >= 0;
            if (!isEmpty && !isExcluded && !hasString) {
                return {
                    name: key,
                    data: data[key].map((ele) => [Date.parse(ele[0]), ele[1]]),
                    color: idx === 0 ? COLORMAP.minor : undefined,
                };
            }
        })
        .filter((ele) => ele);
    return ts_data;
}

export function generateBarSeries(data) {
    let ts_data = Object.keys(data)
        .map((key, idx) => {
            const isEmpty = data[key].filter((ele) => ele[1]).length === 0;
            const isExcluded = EXCLUDEDFIELDS.indexOf(key) >= 0;
            if (!isEmpty && !isExcluded) {
                return {
                    name: key,
                    type: "column",
                    data: data[key].map((ele) => [Date.parse(ele[0]), ele[1]]),
                    color: idx === 0 ? COLORMAP.minor : undefined,
                };
            }
        })
        .filter((ele) => ele);
    return ts_data;
}

/**
 *
 * @param {*} data
 * @param {*} settings
 */
export function generateTradesSeries(data, settings) {
    const px_last_len = data.px_last ? data.px_last.length : 0;
    const trades_len = data.trades ? data.trades.length : 0;
    if (px_last_len === 0 || trades_len === 0) {
        return [];
    }

    // Get Dictionary of PX_LAST (key is the datetime)
    let px_last_dict = {};
    data.px_last.map((element) => {
        px_last_dict[element[0]] = element[1];
    });

    const series_trades = [
        // 1. PX_LAST
        {
            name: "PX_LAST",
            data: data.px_last.map((element) => [Date.parse(element[0]), element[1]]),
            lineWidth: 1,
            color: "var(--color-darkgold)",
        },
        // 2. Trades
        {
            turboThreshold: 3000,
            name: "Trades",
            animation: { duration: 0 },
            color: "#777",
            lineWidth: 2,
            dashStyle: "ShortDot",
            marker: { enabled: true },
            data: data.trades
                // .filter(ele => ele.direction === "long")
                .map((ele) => {
                    // -- Enter the Trade
                    const { direction, open_date, close_date } = ele;
                    const open_price = px_last_dict[ele.open_date];
                    const close_price = px_last_dict[close_date];
                    const isSameDay = open_date === close_date;
                    const dot = [
                        {
                            name: JSON.stringify({ ...ele, is_start: true }),
                            x: Date.parse(open_date),
                            y: open_price,
                            color: direction === "short" ? COLORMAP.red_o : COLORMAP.green_o,
                            fillOpacity: 0.3,
                            marker: {
                                symbol: direction == "short" ? "triangle-down" : "triangle",
                                radius: (() => {
                                    const absProb = Math.proba;
                                    if (absProb < 0.3) {
                                        return 0.5;
                                    } else if (absProb < 0.5) {
                                        return 1;
                                    } else if (absProb < 0.7) {
                                        return 2;
                                    } else if (absProb < 0.85) {
                                        return 3;
                                    } else {
                                        return 4;
                                    }
                                })(),
                            },
                        },
                        ...(isSameDay
                            ? []
                            : [
                                  {
                                      name: "Exit",
                                      x: Date.parse(close_date),
                                      y: close_price,
                                      color: "#666",
                                      marker: {
                                          symbol: "circle",
                                          fillColor: "rgba(0,0,0,0)",
                                          lineWidth: 1,
                                          radius: 4,
                                          lineColor: "grey",
                                      },
                                  },
                              ]),
                        {
                            x: Date.parse(close_date),
                            y: null,
                        },
                    ];
                    return dot;
                })
                .reduce((a, b) => [...a, ...b]),
            legendIndex: 2,
        },
    ];

    return series_trades;
}

/**
 *
 * @param {*} data
 *
 * data
 * ----
 *
 * {
 *   "transactions": [
 * {
 *      "amount": ,
 *      "date": ,
 *      "price": ,
 *      "sid": ,
 *      "symbol": ,
 * },
 * ...],
 *   "px_last":
 * [
 *      [str, number],
 *      ...
 * ]
 * }
 *
 */
export function generateTransactionSeries(data) {
    const px_last_len = data.px_last ? data.px_last.length : 0;
    const transactions_len = data.transactions ? data.transactions.length : 0;
    if (px_last_len === 0 || transactions_len === 0) {
        return [];
    }

    // -- get Dictionary of PX_LAST (key is the datetime)
    let px_last_dict = {};
    data.px_last.map((element) => {
        px_last_dict[element[0]] = element[1];
    });

    const series = [
        {
            name: "PX_LAST",
            data: data.px_last.map((element) => [Date.parse(element[0]), element[1]]),
            lineWidth: 1,
            color: "var(--color-darkgold)",
        },
        {
            turboThreshold: 3000,
            name: "Transactions",
            animation: { duration: 0 },
            color: "rgba(0,0,0,0)",
            lineWidth: 0,
            marker: { enabled: true },
            data: data.transactions.map((ele) => {
                return {
                    name: `${JSON.stringify(ele)}`,
                    x: Date.parse(ele.date),
                    y: px_last_dict[ele.date],
                    color: ele.amount < 0 ? COLORMAP.red_o : COLORMAP.green_o,
                    marker: {
                        symbol: ele.amount < 0 ? "triangle-down" : "triangle",
                        radius: 5,
                    },
                };
            }),
            legendIndex: 2,
        },
    ];

    // --

    return series;
}

/**
 * Tooltip HTML content generator for Trade Chart
 */

const labelValueGenerater = (label, value, isPerc, color, hasCaret) => {
    if (value !== undefined) {
        const caret = `<span style="font-size: 1.2rem">${hasCaret ? (value >= 0 ? "▲" : "▼") : ""}</span>`;

        if (isPerc) {
            value = value.toFixed(2) + "%";
        } else {
            value = value.toFixed(2);
        }

        return `
            <div style="margin-right: 10px; font-family: Lato; color: ${color};">
                <div style="font-size: 0.9rem">${label}</div>
                <div style="font-weight: 800; font-size: 1.4rem;">${value} ${caret}</div>
            </div>
        `;
    } else {
        return "";
    }
};

export const tradeChartTooltipFormatter = function () {
    const px_last = this.points.filter((ele) => ele.series.name === "PX_LAST")[0] || {};
    const pnl = this.points.filter((ele) => ele.series.name === "Cumulative P&L")[0] || {};
    const trade = this.points.filter((ele) => ele.series.name === "Trades")[0];
    const transactions = this.points.filter((ele) => ele.series.name === "Transactions")[0];

    if (pnl && pnl.series) {
        const cutDataStart = pnl.series.processedYData[0];
        pnl.y = pnl.y - cutDataStart;
    }

    let HTML = `
        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px; font-family: Lato;">
            <div style="font-size: 1rem; color: #888">${moment(this.x).format("MMM DD YYYY")}</div>
        </div>
        <div style="display: flex; justify-content: space-between; align-items: center;">
            ${[
                {
                    label: "Cumulative P&L",
                    value: pnl.y,
                    isPerc: true,
                    color: pnl.y >= 0 ? COLORMAP.green : COLORMAP.red,
                },
                { label: "PX_LAST", value: px_last.y, color: "var(--color-darkgold)" },
            ]
                .map((ele) => labelValueGenerater(ele.label, ele.value, ele.isPerc, ele.color))
                .join("")}
        </div>
    `;
    if (trade) {
        const tradePoint = trade.point;
        try {
            const {
                direction,
                open_date,
                close_date,
                is_start,
                open_px,
                close_px,
                pnl,
                raw_pnl,
                proba,
                days_held,
                comment,
            } = JSON.parse(tradePoint.name);
            if (is_start) {
                const price_change = pnl;
                const open_price = Array.isArray(open_px)
                    ? open_px.map((ele) => ele.toFixed(2)).join(" | ")
                    : open_px.toFixed(2);
                const close_price = Array.isArray(close_px)
                    ? close_px.map((ele) => ele.toFixed(2)).join(" | ")
                    : close_px.toFixed(2);
                const text_color = price_change >= 0 ? COLORMAP.green : COLORMAP.red;
                const direction_color = direction === "short" ? COLORMAP.red : COLORMAP.green;
                HTML += `
                <hr>
                <div style='font-family: Lato'>
                    <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                        <div style="font-size: 1rem; color: #555555">${
                            open_date === close_date ? open_date : open_date + " ~ " + close_date
                        } (${days_held} days)</div>
                    </div>
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <div style="margin-right: 10px; font-family: Lato">
                            <div style="font-size: 0.9rem">P&L</div>
                            <div style="color: ${text_color}; font-weight: 800; font-size: 2rem;">${(
                    price_change * 100
                ).toFixed(2)}%<span style="font-size: 1.2rem"> ${price_change >= 0 ? "▲" : "▼"}</span></div>
                        </div>
                        <div style="margin-right: 10px; font-family: Lato">
                            <div style="font-size: 0.9rem">Confidence</div>
                            <div style="font-weight: 800; font-size: 2rem;">${Math.abs(proba).toFixed(2)}</div>
                        </div>
                        <div>
                            <div style="background: ${direction_color}; color: white; font-size: 1.1rem; padding: 2px 6px">${direction.toUpperCase()}</div>
                            <div style="font-size: 1.1rem">${
                                comment in { exit: null, "contract end": null, sl: null }
                                    ? "<i class='fas fa-circle' style='color: #999999'></i> Exit"
                                    : "<i class='fas fa-circle' style='color:" +
                                      COLORMAP.green +
                                      "'></i> Active(" +
                                      comment +
                                      ")"
                            }</div>
                        </div>
                    </div>
                    <hr>
                    <div style="display: flex; justify-content: space-between; align-items: center; font-size: 1.1rem; font-family: Lato;">
                        <div style="margin-right: 10px;">Open: ${open_price}</div>
                        <div>Close: ${close_price}</div>
                    </div>
                </div>
            `;
            }
        } catch (e) {}
    }

    if (transactions) {
        const parsedData = JSON.parse(transactions.point.name);
        // console.log(parsedData);
        const { date, amount, price, symbol, cumAmount } = parsedData;
        const direction = amount >= 0 ? "long" : "short";
        const direction_color = amount >= 0 ? COLORMAP.green : COLORMAP.red;
        HTML += `
            <hr>
            <div style='font-family: Lato'>
                <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                    <div style="font-size: 1rem; color: #555555">${date}</div>
                </div>
                <div>
                <div style="margin-right: 10px; font-family: Lato">
                    <div style="font-size: 0.9rem">Holding Contracts</div>
                    <div style="font-weight: 800; font-size: 2.3rem;">${cumAmount}<span style="font-size: 1.2rem"></span></div>
                </div>
                </div>
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div style="margin-right: 10px; font-family: Lato">
                        <div style="font-size: 0.9rem">Amount</div>
                        <div style="font-weight: 800; color: ${direction_color}; font-size: 2rem;">${amount}<span style="font-size: 1.2rem"></span></div>
                    </div>
                    <div style="margin-right: 10px; font-family: Lato">
                        <div style="font-size: 0.9rem">price</div>
                        <div style="font-weight: 800; font-size: 2rem;">${price}</div>
                    </div>
                    <div>
                        <div style="background: ${direction_color}; color: white; font-size: 1.1rem; padding: 2px 6px">${direction.toUpperCase()}</div>
                    </div>
                </div>
            </div>
        `;
    }

    return HTML;
};

/**
 *
 * @param {}
 */
export const pnlChartTooltipFormatter = function (e) {
    const pnl = this.points.filter((ele) => ele.series.name === "Cumulative P&L")[0] || {};
    const net_pnl = this.points.filter((ele) => ele.series.name === "P&L")[0] || {};
    const gexpo = this.points.filter((ele) => ele.series.name === "Gross Exposure")[0] || {};
    const nexpo = this.points.filter((ele) => ele.series.name === "Net Exposure")[0] || {};

    if (pnl && pnl.series) {
        const cutDataStart = pnl.series.processedYData[0];
        pnl.y = pnl.y - cutDataStart;
    }

    let HTML = `
        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px; font-family: var(--font-main);">
            <div style="font-size: 1rem; color: #888">${moment(this.x).format("MMM DD YYYY")}</div>
        </div>
        <div style="display: flex; justify-content: space-between; align-items: center;">
            ${[
                {
                    label: "Cumulative P&L",
                    value: pnl.y,
                    isPerc: true,
                    color: pnl.y >= 0 ? COLORMAP.green : COLORMAP.red,
                },
                {
                    label: "Net P&L",
                    value: net_pnl.y,
                    isPerc: true,
                    color: net_pnl.y >= 0 ? COLORMAP.green : COLORMAP.red,
                    hasCaret: true,
                },
            ]
                .map((ele) => labelValueGenerater(ele.label, ele.value, ele.isPerc, ele.color, ele.hasCaret))
                .join("")}
        </div>
    `;

    /**
     *     
     * <hr/>
        <div style="display: flex; justify-content: space-between; align-items: center;">
            ${[
                {
                    label: "Gross Exposure",
                    value: gexpo.y,
                    color: COLORMAP.blue
                },
                {
                    label: "Net Exposure",
                    value: nexpo.y,
                    color: "var(--color-darkgold)"
                }
            ]
                .map(ele => labelValueGenerater(ele.label, ele.value, ele.isPerc, ele.color, ele.hasCaret))
                .join("")}
        </div>
     * 
     */

    return HTML;
};

export const multilinePercTooltipFormatter = function (e) {
    let data = this.points
        .map((ele) => ({
            label: ele.series.name,
            value: ele.y,
            color: ele.color,
        }))
        .sort((a, b) => b.value - a.value);

    if (data.length > 16) {
        data = [...data.slice(0, 8), ...data.slice(data.length - 8, data.length)];
    }

    let HTML = `
        <div style="font-family: Arial;">
            <div style="font-size: 1rem; color: #888">${moment(this.x).format("MMM DD YYYY")}</div>
            ${data
                .map((ele) => {
                    return (
                        "<div><b style='color:" +
                        ele.color +
                        "'>● </b>" +
                        ele.label +
                        ": <span style='color:" +
                        (ele.value >= 0 ? COLORMAP.green : COLORMAP.red) +
                        "'> " +
                        toPercentage(ele.value) +
                        "</span></div>"
                    );
                })
                .join("")}
        </div>
    `;

    return HTML;
};

export const multilineTooltipFormatter = function (e) {
    let data = this.points
        .map((ele) => ({
            label: ele.series.name,
            value: ele.y,
            color: ele.color,
        }))
        .sort((a, b) => b.value - a.value);

    if (data.length > 16) {
        data = [...data.slice(0, 8), ...data.slice(data.length - 8, data.length)];
    }

    let HTML = `
        <div style="font-family: Arial;">
            <div style="font-size: 1rem; color: #888">${moment(this.x).format("MMM DD YYYY")}</div>
            ${data
                .map((ele) => {
                    return (
                        "<div><b style='color:" +
                        ele.color +
                        "'>● </b>" +
                        ele.label +
                        ": <span style='color:" +
                        (ele.value >= 0 ? COLORMAP.green : COLORMAP.red) +
                        "'> " +
                        ele.value.toFixed(3) +
                        "</span></div>"
                    );
                })
                .join("")}
        </div>
    `;

    return HTML;
};

// =========================
// --+-- Candle Series --+--
// =========================
export function generateCandleSeries(data) {
    if (Object.keys(data).length < 1) {
        return [];
    }
    let date = data.date;
    let high = data.high;
    let low = data.low;
    let open = data.open;
    let close = data.close;
    let bb = data.bb || [];
    let candleSeries = date.map((ele, idx) => {
        return [Date.parse(date[idx]), open[idx], high[idx], low[idx], close[idx]];
    });
    return [
        {
            type: "candlestick",
            data: candleSeries,
            color: COLORMAP.red,
            upColor: COLORMAP.green,
        },
    ];
}

export function cumulativeToDaily(timeseries) {
    // inputTimeseries: [["2010-01-01", 0], ["2010-01-02", 1.2], ["2010-01-03", 1.3]]
    // --->
    // outputTimeseries: [["2010-01-01", 0], ["2010-01-02", 1.2], ["2010-01-03", 0.1]]
    let outputTimeseries = timeseries.map((item, index) => {
        if (index === 0) {
            return [item[0], 0];
        } else {
            return [item[0], timeseries[index][1] - timeseries[index - 1][1]];
        }
    });
    return outputTimeseries;
}

/**
 *
 * [["2010-01-03", 2], ["2010-01-04", 34], ... ]
 * ==>
 * [["2010-01", 304], ["2010-02", -34], ... ]
 *
 * @param {array} timeseries
 */
export function cumulativeToMonthly(timeseries) {
    const daily = cumulativeToDaily(timeseries);
    let monthlyDict = {};
    for (let d of daily) {
        let key = d[0].slice(0, 7);
        monthlyDict[key] = (monthlyDict[key] || 0) + d[1];
    }
    const monthly = Object.keys(monthlyDict)
        .sort((a, b) => moment(a) - moment(b))
        .map((key) => [key, monthlyDict[key]]);
    return monthly;
}

export function dailyToCumulative(timeseries) {
    if ((timeseries || []).length === 0) {
        return [];
    }
    let data = [];
    timeseries
        .map((ele) => ele[1])
        .reduce(function (a, b, i) {
            return (data[i] = a + b);
        }, 0);
    data = data.map((item, idx) => [timeseries[idx][0], item]);
    return data;
}

export function calculateMovingAverage(timeseries, windowSize) {
    // inputTimeseries: [["2010-01-01", 0], ["2010-01-02", 1.2], ["2010-01-03", 1.3]]
    // --->
    // outputTimeseries: [["2010-01-01", 0], ["2010-01-02", 0.6], ["2010-01-03", .83]]
    if ((timeseries || []).length === 0) {
        return [];
    }
    timeseries = timeseries.slice(timeseries.length - windowSize, timeseries.length);
    let avg = timeseries.map((item) => item[1]).reduce((a, b) => a + b) / timeseries.length;
    return avg;
}

/**
 *
 * [["2010-01-03", 2], ["2010-01-04", 34], ... ]
 * >>>
 * [["2010-01", 3.0], ["2010-02", 5.9], ...]
 *
 * @param {array} timeseries
 */
export function timeseriesToMonthlyPct(timeseries, withTrailingAvg) {
    let monthlyDict = {};
    for (let d of timeseries) {
        let key = d[0].slice(0, 7);
        if (!monthlyDict[key]) {
            monthlyDict[key] = [];
        }
        monthlyDict[key].push(d);
    }
    let monthly = Object.keys(monthlyDict)
        .sort((a, b) => moment(a) - moment(b))
        .map((key) => {
            const lst = monthlyDict[key];
            let chg = 0;
            let pct = 0;
            if (lst.length > 2) {
                chg = lst[lst.length - 1][1] - lst[0][1];
                pct = ((lst[lst.length - 1][1] - lst[0][1]) / lst[0][1]) * 100;
            }
            return {
                key: key,
                year: key.slice(0, 4),
                month: key.slice(5, 7),
                change: chg,
                pct: pct,
            };
        });

    monthly = monthly.filter((ele) => ele.pct && isFinite(ele.pct));

    if (withTrailingAvg) {
        let grouped = groupBy(monthly, "month");
        grouped = grouped.map((ele) => ({
            key: "TrMean-" + ele.group,
            year: "TrMean",
            month: ele.group,
            pct: getAvg(ele.list.map((ele) => ele.pct)),
        }));
        monthly = grouped.concat(monthly);
    }
    return monthly;
}

/**
 *
 * @param {*} data
 * @param {*} step
 */
export function histogram(data, step) {
    var histo = {},
        x,
        i,
        arr = [];

    // Group down
    for (i = 0; i < data.length; i++) {
        x = Math.floor(data[i][0] / step) * step;
        if (!histo[x]) {
            histo[x] = 0;
        }
        histo[x]++;
    }

    // Make the histo group into an array
    for (x in histo) {
        if (histo.hasOwnProperty(x)) {
            arr.push([parseFloat(x), histo[x]]);
        }
    }

    // Finally, sort the array
    arr.sort(function (a, b) {
        return a[0] - b[0];
    });

    return arr;
}

// HIGHCHART CALLBACK ---------------------------------------------------------
/**
 * Highchart "labels" callback function to rebase when user zoom
 *
 * put this function at config.yAxis.laebels.formatter
 * @param {[[string, number]]} data -- datetime,number format timeseries
 */
export function hc_rebase(decimals_count, base) {
    let BASE = base || 1;
    return function () {
        const ymin = this.axis.chart.yAxis[0].min * BASE;
        const ymax = this.axis.chart.yAxis[0].max * BASE;

        const decimals =
            decimals_count ||
            (ymax - ymin > 8
                ? 0
                : ymax - ymin > 1
                ? 1
                : ymax - ymin > 0.1
                ? 2
                : ymax - ymin > 0.01
                ? 3
                : ymax - ymin > 0.001
                ? 4
                : ymax - ymin > 0.0001
                ? 5
                : 6);

        const reseted = this.axis.series[0].processedYData;
        if (reseted.length > 0) {
            return ((this.value - reseted[0]) * BASE).toFixed(decimals) + "%";
        } else {
            return (this.value * BASE).toFixed(decimals) + "%";
        }
    };
}

export function hc_onchangeperiod_xaxis(onChangePeriod) {
    return function (e) {
        try {
            const startDate = moment(new Date(e.min).toISOString()).format("YYYY-MM-DD");
            const endDate = moment(new Date(e.max).toISOString()).format("YYYY-MM-DD");
            const dateRange = { startDate, endDate };
            onChangePeriod(dateRange);
        } catch (error) {
            console.log(error);
        }
    };
}

/**
 *
 * Display percentage on YAxis
 * e.g. 0.3 -> "30%"
 * yAxis -> Labels -> formatter
 */
export function hc_yaxis_percentage_formatter() {
    return function () {
        return (this.value * 100).toFixed(2) + "%";
    };
}

export function generateMonthlyHeatmapConfig(data) {
    let title = "PX_LAST";
    if (!data[title]) {
        title = Object.keys(data)[0];
    }

    let monthly = timeseriesToMonthlyPct(data[title], true);

    const margin = getMargin(monthly.map((ele) => ele.pct));
    const yearList = toUnique(monthly.map((ele) => ele.year));
    const monthList = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const seriesData = monthly.map((ele) => [
        monthList.indexOf(ele.month),
        yearList.indexOf(ele.year),
        getFixed(ele.pct, 2),
    ]);

    const xlist = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const ylist = yearList;

    return {
        chart: {
            type: "heatmap",
            marginTop: 40,
            marginBottom: 80,
            plotBorderWidth: 1,
            backgroundColor: "transparent",
            style: {
                fontFamily: "roboto",
            },
        },
        title: { text: title },
        xAxis: {
            categories: xlist,
        },
        yAxis: {
            categories: ylist,
            title: null,
            reversed: true,
        },
        colorAxis: {
            min: margin * -1,
            max: margin,
            stops: [
                [0, "#a10000"],
                [0.5001, "#ffcfcf"],
                [0.5, "#FFFFFF"],
                [0.4999, "#d6ffd6"],
                [1, "#005200"],
            ],
        },
        credits: highchartBasicConfig.credits,
        legend: {
            align: "right",
            layout: "vertical",
            margin: 0,
            verticalAlign: "top",
            y: 25,
            symbolHeight: 280,
        },
        series: [
            {
                name: "Percentage Change",
                borderWidth: 1,
                data: seriesData,
                dataLabels: {
                    enabled: true,
                    color: "#000000",
                },
            },
        ],
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: "{point.value} %",
                },
            },
        },
    };
}
