// Claude Chen | claude.chen@imbuecapital.com
// Created at: 6 Spet 2018
// Display interface for Imbue AI Lab Game

import React, { Component, Fragment } from "react";
import TradesChart from "../../../components/chart/TradesChart";
import ReactHighchart from "react-highcharts";
import { generateTradesSeries, calculateMovingAverage } from "../../../helpers/ChartFunctions";
import { AuthGet } from "../../../helpers/AuthFetch";
import moment from "moment";
import styled from "styled-components";

import { URL, WS, highchartBasicConfig } from "../../../config";
import { TickerDict } from "../../../index/TickerDictonary";
import ReactTooltip from "react-tooltip";

import { GridLoader } from "react-spinners";
import { emailToName, toAusTime } from "../../../helpers/Utils";

import { _GameDisplay, _StatisticsWrapper } from "../ImbueAILabStyle";

const CURRENTUSER = localStorage.getItem("_email");

// --+-- CSS --+--

const Layout = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 200px calc(100% - 200px);
    grid-template-columns: 100%;

    .grid__topbar {
        padding: 15px;
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        /* justify-content: space-evenly; */
    }

    .grid__charts {
        height: 100%;
        background: #fff;
        padding: 20px;
        box-sizing: border-box;

        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 65% 35%;
    }

    .chart_wrapper {
        position: relative;
        height: 100%;
    }

    .chart_wrapper div {
        height: 100%;
        width: 100%;
    }

    .section_title {
        font-family: var(--font-main);
        font-size: 1.3rem;
        color: #666;
    }
`;

// -- Popup info box for viewing the user rank profile
const _Popup = styled.div`
    padding: 10px;
    font-size: 1.1rem;
    td {
        padding: 5px 10px;
        border-bottom: 1px solid #aaa;
    }
    .grid-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3px;
    }
    .grid-3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3px;
    }
`;

// -- Progress Bar Style
const _ProgressBar = styled.div`
    width: ${props => props.progress};
    white-space: nowrap;
    color: #fff;
    background: var(--color-main);
    animation: progressEffect 0.3s;
    border-radius: inherit;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @keyframes progressEffect {
        0% {
            opacity: 0.8;
        }
        100% {
            opacity: 1;
        }
    }
`;

const commonHighchartConfig = {
    chart: {
        // height: CHART_HEIGHT_SML,
        style: { fontFamily: "var(--font-main)" },
        zoomType: "x",
        backgroundColor: "rgba(0,0,0,0)"
    },
    tooltip: { animation: false, valueSuffix: "%" },
    navigator: { enabled: false },
    rangeSelector: highchartBasicConfig.rangeSelector,
    credits: { enabled: false },
    plotOptions: { series: { animation: false, marker: { enabled: false }, lineWidth: 1 } },
    legend: {
        floating: 1,
        layout: "vertical",
        align: "left",
        verticalAlign: "top",
        backgroundColor: "white",
        borderRadius: "4",
        borderWidth: "1",
        borderColor: "#ccc"
    }
};

function configForPnlChart(data, title) {
    return {
        ...commonHighchartConfig,
        title: { text: title || "", style: { fontSize: "1.5rem" } },
        xAxis: { title: { text: "Episodes" } },
        yAxis: { title: { text: "Sharpe" }, opposite: true, labels: { format: "{value}" } },
        series: [
            {
                name: "Sharpe",
                type: "column",
                data: data.pnl,
                color: "rgb(28, 140, 111)",
                negativeColor: "#D50000",
                yAxis: 0
            },
            {
                name: "MOV_10D",
                type: "line",
                data: data.avgPnl,
                lineWidth: 2,
                color: "rgb(203, 175, 135)",
                yAxis: 0
            }
        ]
    };
}

function configForCumPnlChart(data, title) {
    return {
        ...commonHighchartConfig,
        title: { text: title || "", style: { fontSize: "1.5rem" } },
        xAxis: { title: { text: "Date" }, type: "datetime" },
        yAxis: { title: { text: "Cumulative Profit & Loss" }, opposite: true, labels: { format: "{value}%" } },
        series: [
            {
                name: "Cum P&L",
                type: "areaspline",
                data: data.cumPnl,
                color: "rgb(28, 140, 111)",
                negativeColor: "#D50000",
                fillOpacity: 0.1,
                yAxis: 0
            }
        ]
    };
}

// =====================
// ---+--- CLASS ---+---
// =====================
class GameRank extends Component {
    render() {
        const { rankInfo } = this.props;
        return (
            <div style={{ display: "flex", alignItems: "center", fontFamily: "Roboto", margin: "8px 0 5px" }}>
                {rankInfo ? (
                    <Fragment>
                        <div style={{ margin: "0 10px 0 0" }}>
                            <div className="fontSize__1p3rem fontFamily__imbue_digit_condensed">{TickerDict[rankInfo.security] || rankInfo.security}</div>
                        </div>
                        {rankInfo.rank.length > 0 ? (
                            rankInfo.rank.slice(0, 3).map((row, idx) => {
                                const tooltipId = "tooltip_" + idx;
                                const isCurrentUser = row.user === CURRENTUSER;
                                const majorColor = ["#C98910", "#A8A8A8", "#965A38"][idx] || "rgb(23, 160, 133)";
                                const minorColor = ["rgba(201, 137, 16, .4)", "rgba(168, 168, 168, .4)", "rgba(150, 90, 56, .4)"][idx];
                                return (
                                    <Fragment key={"rank_" + idx}>
                                        <div
                                            className="onHover"
                                            style={{
                                                fontSize: "1.3rem",
                                                color: isCurrentUser ? "#222" : "#666",
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "0 10px",
                                                padding: "6px",
                                                borderRadius: "20px",
                                                background: minorColor,
                                                animation: "textFloat .3s"
                                            }}
                                            data-tip
                                            data-for={tooltipId}
                                            data-event="click focus"
                                        >
                                            {/* Rank */}
                                            <div className="fontFamily__imbue fontSize__1p5rem" style={{ marginRight: "10px", display: "flex", justifyContent: "center", alignItems: "center", width: "20px", height: "20px", borderRadius: "50%", background: "#fff" }}>
                                                {idx + 1}
                                            </div>
                                            {/* User */}
                                            <div style={{ marginRight: "10px" }}>{emailToName(row.user)}</div>
                                            {/* Sharpe */}
                                            <div className="fontSize__1p5rem" style={{ marginRight: "10px" }}>
                                                {row.sharpe}
                                            </div>
                                        </div>
                                        {/* Popup */}
                                        <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid">
                                            <_Popup>
                                                <tbody>
                                                    <tr>
                                                        <td>AGENT</td>
                                                        <td>
                                                            <i className="fas fa-rocket" style={{ marginRight: "10px" }}></i> {row.agent}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>TIMESTAMP</td>
                                                        <td>
                                                            <i className="fas fa-clock" style={{ marginRight: "10px" }}></i> {toAusTime(row.ts, 1)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>FACTORS</td>
                                                        <td>
                                                            <div className="grid-3">
                                                                {(row.factors || []).map(f => (
                                                                    <div>
                                                                        <i className="fas fa-hashtag" style={{ marginRight: "2px" }}></i> {f}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>OUT-OF-SAMPLE</td>
                                                        <td>
                                                            {row.out_stats.map(s => (
                                                                <div>{s.value + " --- " + s.label}</div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </_Popup>
                                        </ReactTooltip>
                                    </Fragment>
                                );
                            })
                        ) : (
                            <div className="fontColor__imbue_grey">
                                <i>No Record</i>
                            </div>
                        )}
                        <div>
                            {rankInfo.isNewRecord === true ? (
                                <div style={{ color: "#c70000", fontSize: "1.5rem", animation: "heartbeat 1s infinite", display: "flex", justifyContent: "center", alignItems: "center", padding: "0 15px" }}>
                                    <i style={{ fontSize: "2rem", marginRight: "5px" }} className="fas fa-certificate"></i> <i>New Rank !</i>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </Fragment>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

class InfoBox extends Component {
    render() {
        const { isError } = this.props;
        return (
            <div style={{ padding: "40px 0", fontFamily: "Open Sans", fontSize: "1.5rem", color: isError ? "rgb(196, 23, 63)" : "rgb(68,117,99)", display: "flex", flexDirection: "column", alignItems: "center" }}>
                {isError ? (
                    <Fragment>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ fontSize: "2rem" }}>Oops!</div>
                            We can't play this game! Maybe try other parameters.
                        </div>
                        <div
                            style={{
                                padding: "10px 0",
                                fontSize: "8rem"
                            }}
                        >
                            <i className="fas fa-exclamation-circle"></i>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div>Result will come back soon ...</div>
                        <div
                            style={{
                                padding: "50px 0"
                            }}
                        >
                            <GridLoader color="rgb(68,117,99)" size={20} />
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default class GameDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gamemode: 0, // GameMode: 0 -> Single Mode | 1 -> Batch Mode
            error: 0,
            numberOfAgents: 0,
            gameinput: {},
            data: {
                inSample: {
                    stats: [],
                    pnl: [],
                    avgPnl: [],
                    trades: []
                },
                outOfSample: {
                    stats: [],
                    pnl: [],
                    avgPnl: [],
                    trades: []
                }
            },
            episodes: 0,
            config: {
                display_in_sample: localStorage.getItem("config__display_in_sample") === "true"
            },
            rankInfo: null
        };
        this.WS_HOST = WS + "agent/";
        this.START = moment();
        this.startGame = this.startGame.bind(this);
        this.getCurrentUserRank = this.getCurrentUserRank.bind(this);
    }

    // When the component mounts, create a WS connection with the server
    UNSAFE_componentWillMount() {
        const { gameinput } = this.props;
        this.setState({
            gameinput
        });
    }

    componentDidMount() {
        this.getCurrentUserRank(false);
        this.startGame();
    }

    // close WebSocket connection after unmounting component
    componentWillUnmount() {
        if (this.state.ws) {
            this.state.ws.close();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.isRunning) {
            if (this.state.ws) {
                this.state.ws.send(
                    JSON.stringify({
                        close: true
                    })
                );
                setTimeout(this.state.ws.close(), 1000);
            }
        }
    }

    getCurrentUserRank(isCompare) {
        let ticker = this.state.gameinput.security;
        const API = `${URL}/ailab_secrank/?ticker=${ticker}&`;
        AuthGet(API)
            .then(res => res.json())
            .then(result => {
                const rank = result.content.sec_rank;
                const username = CURRENTUSER;

                let securityRank = [];
                let pos = -1; // Current User Position (-1 means not in the rank)
                if (rank.length > 0) {
                    securityRank = rank
                        .sort((x, y) => y.sharpe - x.sharpe)
                        .map(ele => {
                            return {
                                user: ele.user,
                                sharpe: ele.sharpe,
                                docid: ele.docid,
                                agent: ele.agent,
                                ts: ele.timestamp,
                                factors: ele.factors,
                                in_stats: ele.reduced[0].statistics,
                                out_stats: ele.reduced[1].statistics,
                                isCurrentUser: CURRENTUSER === ele.user
                            };
                        })
                        .reduce((a, b) => (Array.isArray(a) ? (a.map(ele => ele.user).indexOf(b.user) < 0 ? [...a, ...[b]] : a) : a.user !== b.user ? [a, b] : [a]));
                    pos = securityRank.map(e => e.user).indexOf(username);
                }

                let rankInfo = {
                    security: ticker,
                    position: pos,
                    rank: securityRank
                };

                if (isCompare) {
                    let oldPosition = this.state.rankInfo.position;
                    let newPosition = rankInfo.position;
                    let newRankInfo = {
                        ...rankInfo,
                        isNewRecord: (oldPosition === -1 && newPosition >= 0 && newPosition <= 2) || (newPosition < oldPosition && newPosition >= 0 && newPosition <= 2)
                    };
                    this.setState({
                        rankInfo: newRankInfo
                    });
                } else {
                    this.setState({
                        rankInfo
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    startGame() {
        let ws = new WebSocket(this.WS_HOST);

        // --+-- on open --+--
        ws.onopen = () => {
            // Sending initial message to Websocket to start game
            let initMsg = {
                ...this.state.gameinput,
                user: localStorage.getItem("_email")
            };
            ws.send(JSON.stringify(initMsg));
        };

        // --+-- on message --+--
        ws.onmessage = event => {
            let dataJsonString = event.data;
            let data = {};
            let isDataValid = true;
            try {
                data = JSON.parse(dataJsonString);
            } catch (e) {
                isDataValid = false;
            }

            if (isDataValid === true) {
                let inSample = data.in_sample;
                let outOfSample = data.out_of_sample;

                // INFORMATION RECEIVED FROM AGENT SERVER
                // 1. Current running agents count
                if ("agents" in data) {
                    this.setState({
                        numberOfAgents: data.agents
                    });
                }

                // 2. All episodes are finished, Game over
                else if ("status" in data) {
                    if (data["status"] === "ok") {
                        if (data.msg === "finished") {
                            // Game Finished
                            console.log("Game Finished Successfully!");
                        }
                    } else {
                        // Status: Fail
                        this.setState({
                            ...this.state,
                            error: 1
                        });
                        console.log(`Loading Game Failed! (${data.msg})`);
                    }
                    this.gameFinished();
                }

                // 3. Game data
                else if ("in_sample" in data) {
                    const WINDOWSIZE = 10; // Window Size for Moving Average

                    // Preprocessing Data for Visualization
                    let inSampleStats = inSample.statistics;
                    let inSamplePnl = this.state.data.inSample.pnl;

                    // --- In-sample
                    // 1,
                    let newInSamplePnl = parseFloat(inSampleStats.filter(ele => ele.label === "Sharpe").display);
                    inSamplePnl.push([this.state.episodes + 1, newInSamplePnl]); // --> Push New PNL
                    let inSamplePnlAvg = this.state.data.inSample.avgPnl;
                    inSamplePnlAvg.push([this.state.episodes + 1, calculateMovingAverage(inSamplePnl, WINDOWSIZE)]); // Push new PnL Moving Average
                    // 2,
                    let inSampleTrades = generateTradesSeries(inSample, { noAnimation: true });
                    // 3,
                    let inSampleCumPnl = inSample.pnl.map(ele => [Date.parse(ele[0]), ele[1] * 100]);

                    // --- Out-sample
                    let outOfSampleStats = outOfSample.statistics;
                    let outOfSamplePnl = this.state.data.outOfSample.pnl;
                    let newOutOfSamplePnl = parseFloat(outOfSampleStats.filter(ele => ele.label === "Sharpe").display);
                    outOfSamplePnl.push([this.state.episodes + 1, newOutOfSamplePnl]); // --> Push New PNL
                    let outOfSamplePnlAvg = this.state.data.outOfSample.avgPnl;
                    outOfSamplePnlAvg.push([this.state.episodes + 1, calculateMovingAverage(outOfSamplePnl, WINDOWSIZE)]); // Push new PnL Moving Average
                    let outOfSampleTrades = generateTradesSeries(outOfSample, { noAnimation: true });
                    let outOfSampleCumPnl = outOfSample.pnl.map(ele => [Date.parse(ele[0]), ele[1] * 100]);

                    let formatData = {
                        inSample: {
                            stats: inSampleStats,
                            pnl: inSamplePnl,
                            cumPnl: inSampleCumPnl,
                            avgPnl: inSamplePnlAvg,
                            trades: inSampleTrades
                        },
                        outOfSample: {
                            stats: outOfSampleStats,
                            pnl: outOfSamplePnl,
                            cumPnl: outOfSampleCumPnl,
                            avgPnl: outOfSamplePnlAvg,
                            trades: outOfSampleTrades
                        }
                    };

                    this.setState({
                        ...this.state,
                        data: formatData,
                        episodes: this.state.episodes + 1
                    });
                } else {
                    // Other Message
                }
            }
        };
        // set state for WebSocket connection
        this.setState({
            ws
        });
    }

    gameFinished() {
        this.getCurrentUserRank(true);
        this.props.onSubmit(this.state);
    }

    render() {
        const { rankInfo } = this.state;

        const inSampleData = this.state.data.inSample;
        const outOfSampleData = this.state.data.outOfSample;
        // --+-- Metrics --+--
        const startTime = this.START.format("YYYY-MM-DD HH:mm:ss");
        const timeElapsed = (moment() - this.START) / 1000;
        const playedEpisodes = this.state.episodes;
        const totalEpisodes = this.state.gameinput.episodes;
        const speed = parseInt((playedEpisodes / timeElapsed) * 60);
        const estimatedTime = totalEpisodes ? (totalEpisodes - playedEpisodes) / speed : "";
        const progress = ((playedEpisodes / totalEpisodes) * 100).toFixed(0) + "%";

        const timeStat = [
            { label: "Progress", value: progress },
            { label: "Start Time", value: startTime },
            { label: "Time Elapsed", value: timeElapsed.toFixed(2) + " s" },
            { label: "Episodes", value: playedEpisodes + "/" + totalEpisodes },
            { label: "Speed", value: speed + " episodes/min" },
            { label: "Estimated Time", value: estimatedTime ? estimatedTime.toFixed(2) + " min" : "" },
            { label: "Number of Agents Running", value: this.state.numberOfAgents }
        ];

        const displayList = [
            { name: "inSample", data: inSampleData, label: "In-sample Statistics" },
            { name: "outOfSample", data: outOfSampleData, label: "Out-of-sample Statistics" }
        ];

        // -- Is Data Loaded
        const isDataLoaded = inSampleData.stats.length > 0;

        // -- Display Mode (0 -> full;  1 -> out-of-sample only)
        const mode = this.state.config.display_in_sample ? 0 : 1;

        return (
            <Layout>
                <div className="grid__topbar">
                    {/* --2-- Game Progress Information */}
                    <div key="topbar__progress">
                        <div style={{ borderRadius: "5px", background: "rgb(245,245,245)" }}>
                            <div
                                style={{
                                    position: "relative",
                                    height: "5px",
                                    borderRadius: "20px",
                                    padding: "2px",
                                    // background: "rgb(210,210,210)",
                                    border: "1px solid #ccc"
                                }}
                            >
                                <_ProgressBar progress={progress}>{/* <b>{progress}</b> */}</_ProgressBar>
                            </div>
                        </div>
                    </div>

                    {/* --1-- Game Time Information */}
                    <div key="topbar__timestatus" className="flexBox_horizon" style={{ fontFamily: "Roboto", fontSize: "1.2rem", color: "#444", justifyContent: "space-between", marginTop: "3px" }}>
                        {timeStat.map(item => {
                            return (
                                <div style={{ margin: "0 5px", padding: "2px 0" }}>
                                    <b>{item.label}</b>
                                    {": " + item.value}
                                </div>
                            );
                        })}
                    </div>

                    {/* --3-- Game RankInfo Information */}
                    {/* <GameRank rankInfo={rankInfo} /> */}

                    {/* --4-- Game Stats */}
                    <div key="topbar__status" style={{ display: "grid", gridTemplateColumns: "50% 50%", fontFamily: "Roboto" }}>
                        {displayList.map(item => {
                            let name = item.name;
                            let data = item.data;
                            let isDisplay = item.isDisplay;
                            return (
                                <div>
                                    {/* 1. Title */}
                                    <div className="section_title">{item.label}</div>

                                    {/* 2. Statistics Cards */}
                                    <_StatisticsWrapper isGrid={data.stats}>
                                        <div className="stat_wrapper">
                                            {data.stats.map(item => {
                                                return (
                                                    <div className="stat_card">
                                                        <div className="stat_card_title">{item.label}</div>
                                                        <div className="stat_card_number fontFamily__imbue_digit_condensed">{item.display}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </_StatisticsWrapper>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="grid__charts">
                    {isDataLoaded ? (
                        mode === 0 ? ( // -> 0: display in-sample and out-sample 1: only in-sample
                            <Fragment>
                                <div style={{ position: "relative", display: "grid", gridTemplateColumns: "50% 50%", gridTemplateRows: "100%", height: "100%" }}>
                                    <div className="chart_wrapper" style={{ marginTop: "15px" }}>
                                        <div className="trades-display">{inSampleData.trades.length > 0 ? <TradesChart data={inSampleData.trades} title="In-Sample Trades" /> : ""}</div>
                                    </div>
                                    <div className="chart_wrapper" style={{ marginTop: "15px" }}>
                                        <div className="trades-display">{outOfSampleData.trades.length > 0 ? <TradesChart data={outOfSampleData.trades} title="Out-of-Sample Trades" /> : ""}</div>
                                    </div>
                                </div>
                                <div style={{ position: "relative", display: "grid", gridTemplateColumns: "50% 50%", gridTemplateRows: "100%", height: "100%" }}>
                                    <div className="chart_wrapper">
                                        <div className="episodes-pnl-display">{inSampleData.pnl.length > 1 ? <ReactHighchart ref="in_pnl_chart" config={configForPnlChart(inSampleData, "In-Sample Sharpe Evolution")} /> : ""}</div>
                                    </div>
                                    <div className="chart_wrapper">
                                        <div className="episodes-pnl-display">{outOfSampleData.pnl.length > 1 ? <ReactHighchart ref="out_pnl_chart" config={configForPnlChart(outOfSampleData, "Out-of-Sample Sharpe Evolution")} /> : ""}</div>
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div style={{ height: "100%", position: "relative" }}>
                                    <div className="chart_wrapper">{outOfSampleData.trades && outOfSampleData.trades.length > 0 ? <TradesChart data={outOfSampleData.trades} title="Trades" /> : ""}</div>
                                </div>
                                <div style={{ position: "relative", display: "grid", gridTemplateColumns: "50% 50%", gridTemplateRows: "100%", height: "100%" }}>
                                    <div className="chart_wrapper">
                                        <div className="episodes-pnl-display">{outOfSampleData.pnl && outOfSampleData.pnl.length > 1 ? <ReactHighchart ref="pnl_chart" config={configForPnlChart(outOfSampleData, "Out-of-Sample Sharpe Evolution")} /> : ""}</div>
                                    </div>
                                    <div className="chart_wrapper">
                                        <div className="episodes-pnl-display">{outOfSampleData.cumPnl && outOfSampleData.cumPnl.length > 1 ? <ReactHighchart ref="cum_pnl_chart" config={configForCumPnlChart(outOfSampleData, "Out-of-Sample Cumulative P&L")} /> : ""}</div>
                                    </div>
                                </div>
                            </Fragment>
                        )
                    ) : (
                        <InfoBox isError={this.state.error} />
                    )}
                </div>
            </Layout>
        );
    }
}
