import React, { Component } from "react";
import { ChartWrapper } from "../styled/main";
import HeaderWithDownload from "../../text/HeaderWithDownload";
import TabPerformanceBySector from "./TabPerformanceBySector";
import TabSecurityPnlBySector from "./TabSecurityPnlBySector";

export default class TabBySector extends Component {
    constructor(p) {
        super(p);
        this.state = {
            page: 0
        };
        this.onSwitchPage = this.onSwitchPage.bind(this);
    }

    onSwitchPage(index) {
        this.setState({ page: index });
    }

    render() {
        // === state ===
        const page = this.state.page;

        // === props ===
        const data = this.props.data;
        const totalBySectorData = data.totalBySectorData;
        const secBySectorData = data.secBySectorData;
        const showStats = this.props.showStats;
        const title = this.props.title;
        const isPerc = this.props.isPerc;
        const rebase = this.props.rebase;

        // === validate data ===
        const totalBySectorData_hasdata = totalBySectorData.length > 0;
        const secBySectorData_hasdata = secBySectorData.length > 0;

        return (
            <ChartWrapper>
                <div>
                    <HeaderWithDownload title={title} />
                    <div className="tab_wrapper">
                        <div
                            className={"tab" + (page === 0 ? " tab-selected" : "")}
                            onClick={() => {
                                this.onSwitchPage(0);
                            }}
                        >
                            Total
                        </div>
                        <div
                            className={"tab" + (page === 1 ? " tab-selected" : "")}
                            onClick={() => {
                                this.onSwitchPage(1);
                            }}
                        >
                            By Security
                        </div>
                    </div>
                </div>
                {page === 0 ? (
                    totalBySectorData_hasdata ? (
                        <TabPerformanceBySector
                            series={totalBySectorData}
                            config={{
                                showStats: showStats,
                                isPerc: isPerc,
                                rebase: rebase
                            }}
                        />
                    ) : (
                        ""
                    )
                ) : secBySectorData_hasdata ? (
                    <TabSecurityPnlBySector
                        series={secBySectorData}
                        config={{
                            isPerc: isPerc,
                            rebase: rebase
                        }}
                    />
                ) : (
                    ""
                )}
            </ChartWrapper>
        );
    }
}
